import { axiosPrivate, buildHeaderObject } from "../apis/axios"
import useAuth from "../hooks/useAuth"

async function ValidateNotifications(customerID) {
    const { auth } = useAuth() as any
    const result = await axiosPrivate.get(`Notification/ValidateNotifications?CustomerID=${customerID}`, buildHeaderObject(auth))
    return result.data
}

export const validateNotificationPayload = async (postPayload, code, customerID) => {
    const notificationSettings = await ValidateNotifications(customerID)

    postPayload.messageBody[0].bitCustomerNotificationEmail = false
    postPayload.messageBody[0].numCustomerNotificationEmailSequence = 0

    postPayload.messageBody[0].bitCustomerNotificationMobilePhoneSMS = false
    postPayload.messageBody[0].numCustomerNotificationMobilePhoneSMSSequence = 0

    postPayload.messageBody[0].bitCustomerNotificationMobilePhoneCall = false
    postPayload.messageBody[0].numCustomerNotificationMobilePhoneCallSequence = 0

    postPayload.messageBody[0].bitCustomerNotificationHomePhone = false
    postPayload.messageBody[0].numCustomerNotificationHomePhoneSequence = 0

    postPayload.messageBody[0].bitCustomerNotificationWorkPhone = false
    postPayload.messageBody[0].numCustomerNotificationWorkPhoneSequence = 0

    let sequenceCounter = 1

    const emailNotification = notificationSettings.E.find((item) => item.Code === code)
    if (emailNotification) {
        postPayload.messageBody[0].bitCustomerNotificationEmail = true
        postPayload.messageBody[0].numCustomerNotificationEmailSequence = sequenceCounter
        sequenceCounter++
    }

    const smsNotification = notificationSettings.S.find((item) => item.Code === code)
    if (smsNotification) {
        postPayload.messageBody[0].bitCustomerNotificationMobilePhoneSMS = true
        postPayload.messageBody[0].numCustomerNotificationMobilePhoneSMSSequence = sequenceCounter
        sequenceCounter++
    }

    const mobileNotification = notificationSettings.C?.find((item) => item.Code === code)
    if (mobileNotification) {
        postPayload.messageBody[0].bitCustomerNotificationMobilePhoneCall = true
        postPayload.messageBody[0].numCustomerNotificationMobilePhoneCallSequence = sequenceCounter
        sequenceCounter++
    }

    const homePhoneNotification = notificationSettings.H?.find((item) => item.Code === code)
    if (homePhoneNotification) {
        postPayload.messageBody[0].bitCustomerNotificationHomePhone = true
        postPayload.messageBody[0].numCustomerNotificationHomePhoneSequence = sequenceCounter
        sequenceCounter++
    }

    const workPhoneNotification = notificationSettings.W?.find((item) => item.Code === code)
    if (workPhoneNotification) {
        postPayload.messageBody[0].bitCustomerNotificationWorkPhone = true
        postPayload.messageBody[0].numCustomerNotificationWorkPhoneSequence = sequenceCounter
        sequenceCounter++
    }

    return postPayload
}

export const resetNotificationSequences = (validatedPayload, originalPayload) => {
    const messageBody = validatedPayload.messageBody[0]

    const activeMethods: any = []

    if (originalPayload.numCustomerNotificationEmailSequence) {
        activeMethods.push({ type: "email", sequence: originalPayload.numCustomerNotificationEmailSequence })
    }
    if (originalPayload.numCustomerNotificationMobilePhoneCallSequence) {
        activeMethods.push({ type: "call", sequence: originalPayload.numCustomerNotificationMobilePhoneCallSequence })
    }
    if (originalPayload.numCustomerNotificationMobilePhoneSMSSequence) {
        activeMethods.push({ type: "sms", sequence: originalPayload.numCustomerNotificationMobilePhoneSMSSequence })
    }
    if (originalPayload.numCustomerNotificationWorkPhoneSequence) {
        activeMethods.push({ type: "work", sequence: originalPayload.numCustomerNotificationWorkPhoneSequence })
    }
    if (originalPayload.numCustomerNotificationHomePhoneSequence) {
        activeMethods.push({ type: "home", sequence: originalPayload.numCustomerNotificationHomePhoneSequence })
    }

    activeMethods.sort((a, b) => a.sequence - b.sequence)

    let sequenceCounter = 1

    for (const method of activeMethods) {
        if (method.type === "email" && messageBody.bitCustomerNotificationEmail) {
            messageBody.numCustomerNotificationEmailSequence = sequenceCounter++
        }
        if (method.type === "sms" && messageBody.bitCustomerNotificationMobilePhoneSMS) {
            messageBody.numCustomerNotificationMobilePhoneSMSSequence = sequenceCounter++
        }
        if (method.type === "call" && messageBody.bitCustomerNotificationMobilePhoneCall) {
            messageBody.numCustomerNotificationMobilePhoneCallSequence = sequenceCounter++
        }
        if (method.type === "home" && messageBody.bitCustomerNotificationHomePhone) {
            messageBody.numCustomerNotificationHomePhoneSequence = sequenceCounter++
        }
        if (method.type === "work" && messageBody.bitCustomerNotificationWorkPhone) {
            messageBody.numCustomerNotificationWorkPhoneSequence = sequenceCounter++
        }
    }

    if (messageBody.numCustomerNotificationEmailSequence === 1) {
        messageBody.communicationMethod = "E"
    } else if (messageBody.numCustomerNotificationMobilePhoneSMSSequence === 1) {
        messageBody.communicationMethod = "S"
    } else if (messageBody.numCustomerNotificationMobilePhoneCallSequence === 1) {
        messageBody.communicationMethod = "C"
    } else if (messageBody.numCustomerNotificationHomePhoneSequence === 1) {
        messageBody.communicationMethod = "H"
    } else if (messageBody.numCustomerNotificationWorkPhoneSequence === 1) {
        messageBody.communicationMethod = "W"
    }

    return validatedPayload
}
