import { toast } from "react-toastify"
import { axiosText } from "../apis/axios"
import { removeUnnecessaryWhitespace } from "./HtmlValidation"
import { formatTemplate } from "./MessagingDecoder"
import { resetNotificationSequences, validateNotificationPayload } from "./ValidateMessaging"

const getTemplateByID = async (templateID) => {
    try {
        const response = await axiosText.get(`Template?TemplateID=${templateID}`, {})
        return response.data
    } catch (error) {
        console.error(error)
        throw error
    }
}

const getAppointmentInfo = async (appID) => {
    try {
        const response = await axiosText.get(`UpdateMessages/GetAppointmnetDetailsByAppintmentID?AppointmentID=${appID}`, {})
        return response.data
    } catch (error) {
        console.error(error)
        throw error
    }
}

const getAppointmentDynamicValues = async (entity, id) => {
    try {
        const response = await axiosText.post(`Template/GetDynamicParameterDataByEntityAndID?EntityName=${entity}&ID=${id}`, {})
        return response.data
    } catch (error) {
        console.error(error)
        throw error
    }
}

async function sendMessagePost(data) {
    await axiosText
        .post(`UpdateMessages/UpdateMessagesPool`, JSON.stringify(data), {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
        .then((result) => {
            if (result.status === 200) {
                console.log("Message sent to pool")
            }
        })
        .catch((err) => {
            console.log("Messaging -Err-", err)
        })
}

async function sendNotification(data) {
    await axiosText
        .post(`Notification/PopulateNotifications`, JSON.stringify(data), {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
        .then((result) => {
            if (result.status === 200) {
                console.log("Notification sent to the recipient")
            }
        })
        .catch((err) => {
            console.log("Messaging -Err-", err)
        })
}

export const sendOutNotification = (payload) => {
    sendNotification(payload)
        .then((data) => {})
        .catch((error) => {
            console.log("error", error)
        })
}

export function sendAppointmentStaticMessage(id, entity, templateID, method, files, code) {
    //Email
    getTemplateByID(templateID)
        .then((templateData) => {
            if (templateData[0].isActive) {
                console.log("entitiy", entity, id)
                getAppointmentDynamicValues(entity, id)
                    .then((DynamicData) => {
                        console.log("enditty data", DynamicData)

                        let dynamicD = DynamicData
                        let template = templateData[0]

                        const templateDDD = template.emailMessage
                        const templateSMS = template.smsMessage
                        const formattedEmail = formatTemplate(templateDDD, dynamicD)
                        const formattedSms = formatTemplate(templateSMS, dynamicD)
                        console.log("Femail", formattedEmail, DynamicData)
                        console.log("Femail", formattedEmail, DynamicData)
                        console.log("FSMS", formattedSms)

                        getAppointmentInfo(id)
                            .then((appData) => {
                                console.log("APPDATA", appData[0])
                                let payload = {
                                    messageBody: [
                                        {
                                            messageID: 1,
                                            recipientID: appData[0]?.CustomerID,
                                            recipientType: "C",
                                            senderID: appData[0]?.EmployeeID,
                                            senderLocationID: appData[0]?.numLocationID,
                                            subject: template?.subject + " Ref#:" + id,
                                            smsBody: formattedSms,
                                            emailBody: removeUnnecessaryWhitespace(formattedEmail),
                                            communicationMethod: method,
                                            sender: appData[0].customerName,
                                            senderLocation: appData[0]?.varLocationName,
                                            contactNumber: appData[0]?.CustomerMobilePhone,
                                            emailAddress: appData[0]?.CustomerEmail,
                                            appointmentID: appData[0]?.AppointmentID.toString(),
                                            option: appData[0]?.CustomerEmail === null ? 1 : 2,
                                            sendDate: new Date().toISOString().split("T")[0],
                                            messageType: "I",
                                            workPhoneNumber: appData[0]?.varCustomerWorkPhone,
                                            homePhoneNumber: appData[0]?.varCustomerHomePhone,
                                            bitCustomerNotificationEmail: appData[0]?.bitCustomerNotificationEmail,
                                            numCustomerNotificationEmailSequence: appData[0]?.numCustomerNotificationEmailSequence,
                                            bitCustomerNotificationHomePhone: appData[0]?.bitCustomerNotificationHomePhone,
                                            numCustomerNotificationHomePhoneSequence: appData[0]?.numCustomerNotificationHomePhoneSequence,
                                            bitCustomerNotificationMobilePhoneCall: appData[0]?.bitCustomerNotificationMobilePhoneCall,
                                            numCustomerNotificationMobilePhoneCallSequence: appData[0]?.numCustomerNotificationMobilePhoneCallSequence,
                                            bitCustomerNotificationMobilePhoneSMS: appData[0]?.bitCustomerNotificationMobilePhoneSMS,
                                            numCustomerNotificationMobilePhoneSMSSequence: appData[0]?.numCustomerNotificationMobilePhoneSMSSequence,
                                            bitCustomerNotificationWorkPhone: appData[0]?.bitCustomerNotificationWorkPhone,
                                            numCustomerNotificationWorkPhoneSequence: appData[0]?.numCustomerNotificationWorkPhoneSequence,
                                            messageTemplateID: templateID,
                                            attachmentModel: files.length > 0 ? files : [],
                                        },
                                    ],
                                }

                                console.log("sent-payload", payload)

                                ValidatePayload(payload, code, appData[0]?.CustomerID, appData[0])
                            })
                            .catch((error) => {
                                console.log("error", error)
                            })
                    })
                    .catch((error) => {
                        console.log("error", error)
                    })
            } else {
                toast.warning(`Message did not sent, template ${templateData[0]?.templateReference} have been deactivated.`)
            }
        })
        .catch((error) => {
            console.log("error", error)
        })
}

async function ValidatePayload(payload, code, cusID, Sequence) {
    console.log("b4-OG", Sequence)
    const updatedPayload = await validateNotificationPayload(payload, code, cusID)
    const finalPayload = resetNotificationSequences(updatedPayload, Sequence)
    console.log("after-final", finalPayload)
    sendMessagePost(finalPayload)
}
