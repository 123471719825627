import { useEffect, useRef, useState } from "react"
import AdvancedDropDown from "../../../../AdvancedDropDown/AdvancedDropDown"
import { axiosPrivate, buildHeaderObject } from "../../../../../apis/axios"
import styles from "./Packages.module.scss"
import { Checkbox, TextField } from "@mui/material"
import { DragIndicator } from "@mui/icons-material"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { Button } from "../../../../components"
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import useAuth from "../../../../../hooks/useAuth"

function Packages() {
    const { auth } = useAuth() as any

    const [allLocations, setAllLocations] = useState<any>([])
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingSub, setIsLoadingSub] = useState(false)
    const [location, setLocation] = useState(1)
    const locationsRef = useRef<any>()
    const [packages, setPackages] = useState<any>([])
    const [selectedPackage, setSelectedPackage] = useState("")
    const [subServiceList, setSubServiceList] = useState<any>([])
    const [filteredSubServiceList, setFilteredSubServiceList] = useState([]) as any

    async function getLocations(signal) {
        setIsLoading(true)
        const result = await axiosPrivate.get(`Location/GetAll`, buildHeaderObject(auth, signal))
        return result.data.location
    }

    async function getMainPackageByLocation(location) {
        setIsLoading(true)
        const result = await axiosPrivate.get(`Schedule/GetMainPackagesbyLocationID?LocationID=${location}`, buildHeaderObject(auth))
        return result.data
    }

    async function getSubServiceByLocationAndPackage(location, packageID) {
        setIsLoadingSub(true)
        const result = await axiosPrivate.get(`Schedule/GetSubServicesByLocationIDAndMainServiceID?LocationID=${location}&ServiceID=${packageID}`, buildHeaderObject(auth))
        return result.data
    }

    const fetchLocationData = (signal) => {
        getLocations(signal)
            .then((res) => {
                setAllLocations(res)
            })
            .catch((err) => {
                if (err.message != "canceled") {
                    console.log(err)
                }
            })
    }

    useEffect(() => {
        const controller = new AbortController()
        fetchLocationData(controller.signal)
    }, [])

    useEffect(() => {
        getMainPackageByLocation(location)
            .then((res) => {
                setPackages(res)
                setSubServiceList([])
                setIsLoading(false)
                setSelectedPackage("")
            })
            .catch((err) => {
                console.log(err)
                setIsLoading(false)
            })
    }, [location])

    useEffect(() => {
        getSubServiceData()
    }, [selectedPackage])

    const getSubServiceData = () => {
        if (selectedPackage !== "") {
            let packageID = packages[selectedPackage]?.numServiceItemID
            getSubServiceByLocationAndPackage(location, packageID)
                .then((res) => {
                    setSubServiceList(res)
                    setIsLoadingSub(false)
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoadingSub(false)
                })
        }
    }

    const handleCheckboxChange = (serviceID: number) => {
        const updatedList = subServiceList.map((item) => {
            if (item.ServiceID === serviceID) {
                const newIsChecked = !item.IsChecked
                return {
                    ...item,
                    IsChecked: newIsChecked,
                    ServiceOrder: newIsChecked ? item.ServiceOrder : null,
                }
            }
            return item
        })

        console.log("updated", updatedList)

        const checkedItems = updatedList.filter((item) => item.IsChecked).sort((a, b) => (a.ServiceOrder ?? 0) - (b.ServiceOrder ?? 0))

        const uncheckedItems = updatedList.filter((item) => !item.IsChecked)

        const newlyCheckedItems = updatedList.filter((item) => item.IsChecked && item.ServiceOrder === null)
        const otherCheckedItems = checkedItems.filter((item) => item.ServiceOrder !== null)

        const reorderedList = [...otherCheckedItems, ...newlyCheckedItems, ...uncheckedItems]

        const updatedOrderedList = reorderedList.map((item, index) => {
            if (item.IsChecked) {
                return {
                    ...item,
                    ServiceOrder: index + 1,
                }
            }
            return item
        })

        setSubServiceList(updatedOrderedList)
        setSearchQuery("")
    }

    interface SubService {
        ServiceID: number
        IsChecked: boolean
        ServiceOrder: number | null
        varServiceItemName: string
        varServiceItemDescription: string
    }
    const onDragEnd = (result) => {
        if (!result.destination) {
            return
        }

        const reorderedList: SubService[] = Array.from(subServiceList)

        const [removed] = reorderedList.splice(result.source.index, 1)
        if (!removed.IsChecked) {
            console.log("Attempted to move an unchecked item")
            return
        }

        reorderedList.splice(result.destination.index, 0, removed)

        const updatedList: SubService[] = reorderedList.map((item, index) => ({
            ...item,
            ServiceOrder: item.IsChecked ? index + 1 : item.ServiceOrder,
        }))

        setSubServiceList(updatedList)
    }

    useEffect(() => {
        const filteredList = subServiceList.filter((sub) => sub.IsChecked)
        setFilteredSubServiceList(filteredList)
    }, [subServiceList])

    const saveChanges = () => {
        let payload: any = []

        filteredSubServiceList.forEach((item, index) => {
            payload.push({
                subServiceID: 0,
                serviceID: packages[selectedPackage]?.numServiceItemID,
                serviceLibraryID: item?.ServiceID,
                subServiceOrder: item?.ServiceOrder,
                name: item?.varServiceItemName,
            })
        })

        const bathIndex = payload.findIndex((item) => item.name === "Bath")

        if (payload.length === 0) {
            // If payload is empty, call deleteSubs() endpoint
            console.log("remove all")
            deleteSubs()
                .then((response) => {
                    console.log(response)
                    if (response.status === 200) {
                        toast.success("Changes saved successfully!")
                        getSubServiceData()
                    }
                })
                .catch((err) => {
                    console.log(err)
                    toast.error("Something went wrong!")
                })
        } else if (bathIndex === -1) {
            // If "Bath" is missing and payload is not empty
            Swal.fire({
                title: "Missing Sub-Service",
                text: 'The sub-service "Bath" is required and not found. Please add it before proceeding.',
                icon: "error",
                confirmButtonText: "Okay",
            }).then(() => {
                // Optionally handle user acknowledgment if needed
            })
        } else if (payload[bathIndex].subServiceOrder !== 1) {
            // If "Bath" exists but is not at the first index
            Swal.fire({
                title: "Correct Order",
                text: 'The sub-service "Bath" should have an order of 1. Do you want to correct the order?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, correct it before saving!",
                cancelButtonText: "No, reorder manually",
            }).then((result) => {
                if (result.isConfirmed) {
                    let reorderedPayload: any = []

                    reorderedPayload.push({
                        ...payload[bathIndex],
                        subServiceOrder: 1,
                    })

                    let order = 2
                    payload.forEach((item, index) => {
                        if (index !== bathIndex) {
                            reorderedPayload.push({
                                ...item,
                                subServiceOrder: order++,
                            })
                        }
                    })

                    payload = reorderedPayload
                    console.log("corrected - payload - API call", payload)
                    savePackageData(payload)
                } else {
                    console.log("unchanged - payload - no API call", payload)
                }
            })
        } else {
            // If "Bath" is present and at the first index
            console.log("no change needed - payload - API call", payload)
            savePackageData(payload)
        }

        // const bathIndex = payload.findIndex(item => item.name === "Bath");

        // if (bathIndex !== -1 && payload[bathIndex].subServiceOrder !== 1) {
        //     Swal.fire({
        //         title: 'Correct Order',
        //         text: 'The sub-service "Bath" should have an order of 1. Do you want to correct the order?',
        //         icon: 'warning',
        //         showCancelButton: true,
        //         confirmButtonText: 'Yes, correct it before saving!',
        //         cancelButtonText: 'No, reorder manually'
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             let reorderedPayload: any[] = [];

        //             reorderedPayload.push({
        //                 ...payload[bathIndex],
        //                 subServiceOrder: 1
        //             });

        //             let order = 2;
        //             payload.forEach((item, index) => {
        //                 if (index !== bathIndex) {
        //                     reorderedPayload.push({
        //                         ...item,
        //                         subServiceOrder: order++
        //                     });
        //                 }
        //             });

        //             payload = reorderedPayload;
        //             console.log("corrected - payload - API call", payload);
        //             savePackageData(payload)
        //         } else {
        //             console.log("unchanged - payload - no API call", payload);
        //         }
        //     });
        // } else {

        //     if(payload.length > 0){
        //         console.log("no change needed - payload - API call", payload);
        //         savePackageData(payload)
        //     }else{
        //         console.log("remove all")
        //         deleteSubs()
        //         .then((response) => {
        //             console.log(response)
        //             if (response.status === 200) {
        //                 toast.success("Changes saved successfully!")
        //                 getSubServiceData()
        //             }
        //         })
        //         .catch((err) => {
        //             console.log(err)
        //             toast.error("Something went wrong!")
        //         })
        //     }

        // }
    }

    async function deleteSubs() {
        const result = await axiosPrivate.delete(`Schedule/DeleteSubServicesByMainServiceID?MainServiceID=${packages[selectedPackage]?.numServiceItemID}`, buildHeaderObject(auth))
        return result
    }

    async function savePackageData(data) {
        await axiosPrivate
            .post(`Schedule/PopulateSubServices`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                if (result.status == 200) {
                    toast.success("Changes saved successfully!")
                    getSubServiceData()
                }
            })
            .catch((error: any) => {
                console.log("Error:", error)
            })
    }

    const [searchQuery, setSearchQuery] = useState("")

    const handleSearch = (event) => {
        setSearchQuery(event.target.value.toLowerCase())
    }

    const filteredList = subServiceList.filter((sub) => sub.varServiceItemName.toLowerCase().includes(searchQuery)).sort((a, b) => b.IsChecked - a.IsChecked)

    return (
        <div>
            <div style={{ width: "30%" }}>
                {allLocations !== undefined && allLocations !== null ? (
                    <AdvancedDropDown
                        data={allLocations.sort((a, b) => a.numLocationID - b.numLocationID).map((i) => ({ label: i.varLocationName, value: i.numLocationID }))}
                        onChange={(e) => {
                            setLocation(e?.value)
                        }}
                        ref={locationsRef}
                        placeHolder={"Locations"}
                        passByID={true}
                        value={location}
                    />
                ) : null}
            </div>
            <div style={{ marginTop: "10px", display: "flex", gap: "10px", width: "100%" }}>
                <div style={{ border: "1px solid #b3b3b3", borderRadius: "8px", padding: "10px", width: "100%" }}>
                    <p style={{ fontSize: "14px", fontWeight: "600", borderBottom: "1px solid #b3b3b3", paddingBottom: "10px" }}>Packages</p>
                    <div style={{ display: "flex", flexDirection: "column", height: "600px", overflowX: "scroll", marginBottom: "10px" }}>
                        {isLoading ? (
                            <p style={{ color: "gray" }}>Loading...</p>
                        ) : packages.length > 0 ? (
                            packages.map((pack, index) => {
                                return (
                                    <div
                                        className={selectedPackage === index ? styles.package_selected : styles.package}
                                        onClick={() => {
                                            setSelectedPackage(index)
                                        }}
                                    >
                                        <p style={{ fontWeight: "500", fontSize: "13px" }}>{pack?.varServiceItemName}</p>
                                        <p style={{ color: "gray" }}>{pack?.varServiceItemDescription}</p>
                                    </div>
                                )
                            })
                        ) : (
                            <p style={{ color: "gray" }}>No packages found for the selected location</p>
                        )}
                    </div>
                </div>

                <div style={{ border: "1px solid #b3b3b3", borderRadius: "8px", padding: "10px", width: "100%" }}>
                    <p style={{ fontSize: "14px", fontWeight: "600", borderBottom: "1px solid #b3b3b3", paddingBottom: "10px" }}>Services</p>
                    <TextField label="Search Services" variant="outlined" fullWidth onChange={handleSearch} style={{ marginBottom: "10px", marginTop: "10px" }} value={searchQuery} />
                    <div style={{ display: "flex", flexDirection: "column", height: "600px", overflowX: "scroll", marginBottom: "10px" }}>
                        <div>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable-subservice">
                                    {(provided) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef}>
                                            {isLoadingSub ? (
                                                <p style={{ color: "gray" }}>Loading...</p>
                                            ) : filteredList.length > 0 ? (
                                                filteredList.map((sub, index) =>
                                                    sub.IsChecked ? (
                                                        <Draggable key={sub.ServiceID} draggableId={sub.ServiceID.toString()} index={index}>
                                                            {(provided) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    className={styles.sub_package}
                                                                    style={{
                                                                        backgroundColor: sub.IsChecked ? "lightblue" : undefined,
                                                                        boxShadow: sub.IsChecked ? "7px 15px 85px 4px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1)" : undefined,
                                                                        ...provided.draggableProps.style,
                                                                    }}
                                                                >
                                                                    <Checkbox
                                                                        checked={sub.IsChecked}
                                                                        onChange={() => handleCheckboxChange(sub.ServiceID)}
                                                                        inputProps={{ "aria-label": "primary checkbox" }}
                                                                    />
                                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                                                        <div>
                                                                            <p style={{ fontWeight: "500", fontSize: "13px" }}>
                                                                                {sub.ServiceOrder !== null ? sub.ServiceOrder + ". " : null}
                                                                                {sub.varServiceItemName}
                                                                            </p>
                                                                            <p style={{ color: "gray" }}>{sub.varServiceItemDescription}</p>
                                                                        </div>
                                                                        {sub.IsChecked && <DragIndicator style={{ color: "#9CA3AF" }} />}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ) : (
                                                        // Render unchecked items without dragging functionality
                                                        <div key={sub.ServiceID} className={styles.sub_package}>
                                                            <Checkbox checked={sub.IsChecked} onChange={() => handleCheckboxChange(sub.ServiceID)} inputProps={{ "aria-label": "primary checkbox" }} />
                                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                                                <div>
                                                                    <p style={{ fontWeight: "500", fontSize: "13px" }}>
                                                                        {sub.ServiceOrder !== null ? sub.ServiceOrder + ". " : null}
                                                                        {sub.varServiceItemName}
                                                                    </p>
                                                                    <p style={{ color: "gray" }}>{sub.varServiceItemDescription}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                            ) : (
                                                <p style={{ color: "gray" }}>No sub-services found for the selected location and package or you haven't picked a package.</p>
                                            )}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px", gap: "10px" }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        saveChanges()
                    }}
                    text="Save Changes"
                    disabled={false}
                />
            </div>
        </div>
    )
}

export default Packages
