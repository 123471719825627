import axios from "axios"

const BASE_URL = process.env.REACT_APP_API_URL
const TEXT_URL = process.env.REACT_APP_TEXT_API_URL

export const axiosText = axios.create({
    baseURL: TEXT_URL,
})

export const axiosOpen = axios.create({
    baseURL: BASE_URL,
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    withCredentials: true,
})

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
})

export const buildHeaderObject = (authObject, signal, responseType, isMultiPartData, params) => {
    console.log("authObject", authObject)

    const axiosObject = {
        headers: { "Content-Type": "application/json", Accept: "application/json", Authorization: `Bearer ${authObject?.accessToken}` },
    }

    if (isMultiPartData) {
        axiosObject["headers"]["Content-Type"] = "multipart/form-data"
    }

    if (params) {
        axiosObject["params"] = params
    }

    if (signal) {
        axiosObject["signal"] = signal
    }

    if (responseType) {
        axiosObject["responseType"] = responseType
    }

    // console.log("axiosObject", axiosObject)
    return axiosObject
}
