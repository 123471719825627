import React, { useCallback, useEffect } from "react"
import "@mobiscroll/react/dist/css/mobiscroll.min.css"
import { Eventcalendar, getJson, setOptions, CalendarNav, SegmentedGroup, SegmentedItem, CalendarPrev, CalendarToday, CalendarNext, Popup, Button } from "@mobiscroll/react"
import { axiosPrivate, buildHeaderObject } from "../../../../apis/axios"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { addMinutes, calculateWeeklyTiming, formatDateDisplay } from "../../../../utils/TimeHandler"

import useAuth from "../../../../hooks/useAuth"

const ViewBookings = (props: { viewSummary: CallableFunction }) => {
    setOptions({
        theme: "ios",
        themeVariant: "light",
    })

    const { auth, setAuth } = useAuth() as any

    const [view, setView] = React.useState("month")
    const [myEvents, setEvents] = React.useState([]) as any

    useEffect(() => {
        // getJson('https://trial.mobiscroll.com/events/?vers=5', (events) => {
        //     console.log(events)
        //     setEvents([
        //         {start: '2023-01-30T08:00:00.000Z', end: '2023-02-02T17:00:00.000Z', title: 'Business of Software Conference', color: '#ff6d42', id: 'mbsc_149'}
        //     ]);
        // }, 'jsonp');

        getAppointments()
    }, [])

    const [calView, setCalView] = React.useState({
        calendar: { labels: true },
    })

    useEffect(() => {
        console.log("myE", myEvents)
    }, [myEvents])

    async function getAllAppointments() {
        await axiosPrivate
            .get(`Appointment/GetAllScheduleAppointmnets`, buildHeaderObject(auth))
            .then((result) => {
                if (result.status === 200) {
                    console.log("All-Appoinments - RAW", result)

                    let allAppointments = [] as any

                    result?.data.Appointments.map((app) => {
                        let startTming = calculateWeeklyTiming(app.dtApptStartTime)
                        let endTming = addMinutes(startTming, app.dtApptEndTime)

                        let objPure = {
                            start: app.dtApptStartTime,
                            end: app.dtApptEndTime,
                            title: `#${app.bookingId}: ${app.PetName} - ${app.varCustomerFirstName + " " + app.varCustomerLastName} - ${app.Services[0].varServiceItemName} | ${formatDateDisplay(
                                app.dtApptStartTime
                            )} - ${formatDateDisplay(app.dtApptEndTime)} `,
                            color: app?.varColour,
                            id: app.numAppointmentID,
                            resource: {
                                petName: app.PetName,
                                customerName: app.varCustomerFirstName + " " + app.varCustomerLastName,
                                serviceItemsLibraries: app.Services,
                                date: app.date,
                                Groomer: app.varEmployeeFirstName + " " + app.varEmployeeLastName,
                            },
                        }
                        allAppointments.push(objPure)
                    })
                    setEvents(allAppointments)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getAppointments = () => {
        getAllAppointments()
    }

    const showBooking = (id) => {
        console.log("Clicked", { id: id, status: true })
        props.viewSummary({ id: id, status: true })
    }

    const changeView = (event) => {
        let calView

        switch (event.target.value) {
            case "year":
                calView = {
                    calendar: { type: "year" },
                }
                break
            case "month":
                calView = {
                    calendar: { labels: true },
                }
                break
            case "week":
                calView = {
                    schedule: { type: "week" },
                }
                break
            case "day":
                calView = {
                    schedule: { type: "day" },
                }
                break
        }

        setView(event.target.value)
        setCalView(calView)
    }

    const customWithNavButtons = () => {
        return (
            <React.Fragment>
                <CalendarNav className="cal-header-nav" />
                <div className="cal-header-picker">
                    <SegmentedGroup value={view} onChange={changeView}>
                        <SegmentedItem value="year">Year</SegmentedItem>
                        <SegmentedItem value="month">Month</SegmentedItem>
                        <SegmentedItem value="week">Week</SegmentedItem>
                        <SegmentedItem value="day">Day</SegmentedItem>
                    </SegmentedGroup>
                </div>
                <CalendarPrev className="cal-header-prev" />
                <CalendarToday className="cal-header-today" />
                <CalendarNext className="cal-header-next" />
            </React.Fragment>
        )
    }

    const [isOpen, setOpen] = React.useState(false)
    const [anchor, setAnchor] = React.useState<any>(null)
    const [closeOnOverlay, setCloseOnOverlay] = React.useState(false)
    const [currentEvent, setCurrentEvent] = React.useState(null)
    const timerRef = React.useRef<any>(null)

    const [time, setTime] = React.useState("") as any
    const [serviceName, setServiceName] = React.useState("") as any
    const [petName, setPetName] = React.useState("") as any
    const [customer, setCustomer] = React.useState([]) as any
    const [employee, setEmployee] = React.useState([]) as any
    const [services, setServices] = React.useState([]) as any
    const [appID, setAppID] = React.useState("") as any
    const [startTime, setStartTime] = React.useState("") as any
    const [endTime, setEndTime] = React.useState("") as any

    const openTooltip = React.useCallback(
        (args, closeOption) => {
            const event = args.event

            const resource = myEvents.find((evt) => evt.id === event.id)
            // const time = formatDate('hh:mm A', new Date(event.start)) + ' - ' + formatDate('hh:mm A', new Date(event.end));

            setCurrentEvent(event)
            setTime(resource?.resource?.date)
            // console.log("timeee", resource?.resource?.serviceItemsLibraries[0])
            setServiceName(resource?.resource?.serviceItemsLibraries.length > 0 ? resource?.resource?.serviceItemsLibraries[0]?.varServiceItemName : "Not Set")
            setPetName(resource?.resource?.petName)
            setStartTime(resource?.start)
            setEndTime(resource?.end)
            setCustomer(resource?.resource?.customerName)
            setEmployee(resource?.resource?.Groomer)
            setServices(resource?.resource?.serviceItemsLibraries)
            setAppID(resource?.id)
            // if (event.confirmed) {
            //     setStatus('Confirmed');
            //     setButtonText('Cancel appointment');
            //     setButtonType('warning');
            // } else {
            //     setStatus('Canceled');
            //     setButtonText('Confirm appointment');
            //     setButtonType('success');
            // }

            // setBgColor('resource.color');
            // setInfo('event.title' + ', Age: ' + 'event.age');

            // setReason('event.reason');
            // setLocation('event.location');

            if (timerRef.current) {
                clearTimeout(timerRef.current)
            }

            setAnchor(args.domEvent.currentTarget || args.domEvent.target)
            setCloseOnOverlay(closeOption)
            setOpen(true)
        },
        [myEvents]
    )

    const onEventHoverIn = React.useCallback(
        (args, closeOption) => {
            openTooltip(args, false)
        },
        [openTooltip]
    )

    const onEventHoverOut = React.useCallback(() => {
        timerRef.current = setTimeout(() => {
            setOpen(false)
        }, 200)
    }, [])

    const onMouseEnter = React.useCallback(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
    }, [])

    const onMouseLeave = React.useCallback(() => {
        timerRef.current = setTimeout(() => {
            setOpen(false)
        }, 200)
    }, [])

    return (
        <div style={{ borderRadius: "10px" }}>
            <Eventcalendar
                renderHeader={customWithNavButtons}
                height={700}
                view={calView}
                data={myEvents}
                cssClass="md-switching-view-cont"
                onEventHoverIn={onEventHoverIn}
                onEventHoverOut={onEventHoverOut}
            />

            <Popup display="anchored" isOpen={isOpen} anchor={anchor} touchUi={false} showOverlay={false} contentPadding={false} closeOnOverlayClick={closeOnOverlay} width={350} cssClass="md-tooltip">
                <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                    <div className="md-tooltip-header" style={{ backgroundColor: "white", padding: "10px 15px", display: "flex", justifyContent: "space-between" }}>
                        <span className="md-tooltip-name-age"> {serviceName} </span>
                        <span className="md-tooltip-time" style={{ color: "#000" }}>
                            {time}
                        </span>
                    </div>
                    <div className="md-tooltip-info" style={{ padding: "10px 15px" }}>
                        <div className="md-tooltip-title">
                            Pet: <span className="md-tooltip-reason md-tooltip-text">{petName}</span>
                        </div>
                        <div className="md-tooltip-title">
                            Customer: <span className="md-tooltip-location md-tooltip-text">{customer}</span>
                        </div>
                        <div>
                            List of services
                            <ul style={{ listStyle: "none", paddingLeft: "0px", display: "flex", flexWrap: "wrap", gap: "6px", margin: "5px 0" }}>
                                {services.map((service) => {
                                    return <li style={{ padding: "4px 8px", borderRadius: "50px", backgroundColor: "#cdeefd", fontWeight: "500" }}>{service.varServiceItemName}</li>
                                })}
                            </ul>
                        </div>
                        <div style={{ padding: "10px", border: "1px solid #c9c9c9", borderRadius: "8px" }}>
                            <div className="md-tooltip-title">
                                Groomer: <span className="md-tooltip-location md-tooltip-text">{employee}</span>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <p style={{ color: "#343434", fontWeight: "600" }}>Start: {formatDateDisplay(startTime)}</p>
                                <p style={{ color: "#343434", fontWeight: "600" }}>End: {formatDateDisplay(endTime)}</p>
                            </div>
                        </div>

                        <Button color="primary" variant="outline" onClick={() => showBooking(appID)}>
                            View booking
                        </Button>
                    </div>
                </div>
            </Popup>
        </div>
    )
}

export default ViewBookings
