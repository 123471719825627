import { CameraAltOutlined, Cancel, CheckCircle, ContentCut, FileUploadOutlined, HighlightOff, Info, Mic, NoteAltOutlined, Place, ShoppingCart, ThumbUpAltOutlined } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Switch, TextField } from "@mui/material"
import { useState, useEffect } from "react"
import { Avatar, Button, CloseButton, Chip } from "../../components"
import { Modal, Chip as LocationChip } from "../../components/components"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { FileUploader } from "react-drag-drop-files"
import { axiosPrivate, buildHeaderObject } from "../../apis/axios"
import { toast } from "react-toastify"

import moment from "moment"
import AdvancedDropDown from "../AdvancedDropDown/AdvancedDropDown"
import { Truncate } from "../../utils/Validation"
import outline from "../../images/dog_outline.png"
import ImageMapper from "react-img-mapper"
import useAuth from "../../hooks/useAuth"

const WellnessModal = (props: { isOpenWellnessCheckModal; setIsOpenWellnessCheckModal: CallableFunction; bookingDetails; checkInDetails }) => {
    const [showConcernDetails, setShowConcernDetails] = useState(false)

    const { auth } = useAuth() as any

    const [referenceImage, setReferenceImage] = useState(false)
    const [isOpenUploadImage, setIsOpenUploadImage] = useState(false)
    const [loading, setLoading] = useState(false)
    const label = { inputProps: { "aria-label": "Checkbox demo" } }
    const [maxFail, setMaxFail] = useState<any>([])
    const [importedData, setImportedData] = useState<any>([])
    const fileTypes = ["JPEG", "jpg", "WEBP"]

    const [isLoading, setIsLoading] = useState(false)
    const [wellnessBaseDataList, setWellnessBaseDataList] = useState<any>([])
    const [currDate, setCurrDate] = useState<any>(moment().format("MM/DD/YYYY"))
    const [serviceList, setServiceList] = useState<any>([])
    const [productList, setProductList] = useState<any>([])

    const MAP = {
        name: "my-map",
        areas: [
            // EARS
            { id: "0", shape: "circle", coords: [165, 40, 10], fillColor: "rgba(255, 255, 255, 0.5)", preFillColor: "rgba(0, 255, 0, 0.5)", toggleHighlighted: true },
            // EYES
            { id: "1", shape: "circle", coords: [15, 60, 10], fillColor: "rgba(0, 255, 0, 0.5)", preFillColor: "rgba(255, 255, 255, 0.5)", toggleHighlighted: true },
            // MOUTH
            { id: "2", shape: "circle", coords: [15, 200, 10], fillColor: "rgba(0, 255, 0, 0.5)", preFillColor: "rgba(255, 255, 255, 0.5)", toggleHighlighted: true },
            // NOSE
            { id: "3", shape: "circle", coords: [15, 98, 10], fillColor: "rgba(0, 255, 0, 0.5)", preFillColor: "rgba(255, 255, 255, 0.5)", toggleHighlighted: true },
            // FEET - PAWS
            { id: "4", shape: "circle", coords: [50, 395, 10], fillColor: "rgba(0, 255, 0, 0.5)", preFillColor: "rgba(255, 255, 255, 0.5)", toggleHighlighted: true },
            // FEET - NAILS
            { id: "5", shape: "circle", coords: [70, 336, 10], fillColor: "rgba(0, 255, 0, 0.5)", preFillColor: "rgba(255, 255, 255, 0.5)", toggleHighlighted: true },
            // COAT / HAIR
            { id: "6", shape: "circle", coords: [241, 117, 10], fillColor: "rgba(0, 255, 0, 0.5)", preFillColor: "rgba(255, 255, 255, 0.5)", toggleHighlighted: true },
            // SKIN
            { id: "7", shape: "circle", coords: [290, 298, 10], fillColor: "rgba(0, 255, 0, 0.5)", preFillColor: "rgba(255, 255, 255, 0.5)", toggleHighlighted: true },
            // ANAL
            { id: "8", shape: "circle", coords: [410, 84, 10], fillColor: "rgba(0, 255, 0, 0.5)", preFillColor: "rgba(255, 255, 255, 0.5)", toggleHighlighted: true },
        ],
    }

    const [showBodyPart, setShowBodyPart] = useState(false)
    const [currBodyPart, setCurrBodyPart] = useState(0)

    const handleClick = (area, _, event) => {
        // const coords = {
        //     x: event.nativeEvent.offsetX,
        //     y: event.nativeEvent.offsetY,
        // } as any

        // console.log(`Clicked at coordinates:`, coords)

        // setCurrBodyPart()
        setShowBodyPart(true)
        setCurrBodyPart(area?.id)
    }

    const readFile = (files) => {
        const newImages = [...importedData]
        const fails = [...maxFail]

        for (let i = 0; i < files.length; i++) {
            if (files[i].size > 3000000) {
                setMaxFail([fails, files[i].name + " File too large ⛔"])
            } else {
                newImages?.push(files[i])
                setImportedData(newImages)
            }
        }
        setIsOpenUploadImage(false)
    }

    const deleteImage = (index) => {
        const afterRemove = [...importedData]
        afterRemove.splice(index, 1)
        setImportedData(afterRemove)
    }
    const handleSubmit = () => {
        setIsLoading(true)

        let servicesArr: any = []
        wellnessBaseDataList.RecommendedServices.map((item, index) => {
            servicesArr.push({
                checkInID: props.checkInDetails?.CheckInID,
                serviceOrProductID: item?.ServiceID,
                type: "S",
            })
        })

        let productsArr: any = []
        wellnessBaseDataList.RecommendedProducts.map((item, index) => {
            productsArr.push({
                checkInID: props.checkInDetails?.CheckInID,
                serviceOrProductID: item?.ProductID,
                type: "P",
            })
        })

        const combinedArr = [...servicesArr, ...productsArr]

        if (importedData.length > 0) {
            const formData = new FormData()

            formData.append("folderpath", "wellness")
            formData.append("method", "N")
            formData.append("deletingFileName", "-")
            formData.append("renameFileNames", "1")

            for (let index = 0; index < importedData.length; index++) {
                if (importedData[index].file !== null && importedData[index].file !== "") {
                    const file = importedData[index]
                    const fileExtension = file.name.split(".").pop()
                    const renamedFile = new File([file], `${index}.${fileExtension}`, { type: file.type })
                    formData.append("files", renamedFile)
                }
            }
            axiosPrivate
                .post(`FileUpload/UploadFile`, formData, buildHeaderObject(auth))
                .then((response) => {
                    console.log("Upload-Res", response.data.files)

                    const modifiedArray = response.data.files.map((item) => {
                        return {
                            varImagePath: item.renamedFileName,
                        }
                    })
                    const updatedImageArray = wellnessBaseDataList.checkImageList.concat(modifiedArray)

                    console.log("updated", updatedImageArray)

                    console.log("ok", wellnessBaseDataList)
                    const mainArray = wellnessBaseDataList.Wellness
                    const concernsCheckList: any = []
                    const statusCheckList: any = []

                    for (const item of mainArray) {
                        const { BodyPartID, isAbnormal } = item
                        statusCheckList.push({ BodyPartID, isAbnormal })

                        const checkedConcerns = item.Concerns.filter((concern) => concern.checked)
                        checkedConcerns.forEach((concern) => {
                            const { ConcernID, bitWellnessCheckConcernsActive } = concern
                            concernsCheckList.push({ ConcernID, isActive: bitWellnessCheckConcernsActive })
                        })
                    }

                    console.log("concernsCheckList:", concernsCheckList)
                    console.log("statusCheckList:", statusCheckList)

                    GetWellnessByCheckInID(props.checkInDetails?.CheckInID)
                        .then((response_app_wellness) => {
                            if (response_app_wellness.data.length > 0) {
                                let putBody = {
                                    checkInID: props.checkInDetails?.CheckInID,
                                    notesForClient: wellnessBaseDataList.NotesForClient,
                                    suggestions: wellnessBaseDataList.Suggestions,
                                    evaluatorID: wellnessBaseDataList.EvaluatorID,
                                    numCreatedBy: 1,
                                    checkImageList: updatedImageArray,
                                    concernsCheckList: concernsCheckList,
                                    statusCheckList: statusCheckList,
                                    wellnessRecommendedServicesOrProducts: combinedArr,
                                }
                                putWellness(putBody)
                                console.log("put-sent", putBody)
                            } else {
                                let postBody = {
                                    checkInID: props.checkInDetails?.CheckInID,
                                    notesForClient: wellnessBaseDataList.NotesForClient,
                                    suggestions: wellnessBaseDataList.Suggestions,
                                    evaluatorID: wellnessBaseDataList.EvaluatorID,
                                    numCreatedBy: 1,
                                    checkImageList: updatedImageArray,
                                    concernsCheckList: concernsCheckList,
                                    statusCheckList: statusCheckList,
                                    wellnessRecommendedServicesOrProducts: combinedArr,
                                }
                                postWellness(postBody)
                                console.log("post-sent", postBody)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                            setIsLoading(false)
                        })
                })
                .catch((error) => {
                    console.error(error)
                    setIsLoading(false)
                })
        } else {
            console.log("ok", wellnessBaseDataList)
            const mainArray = wellnessBaseDataList.Wellness
            const concernsCheckList: any = []
            const statusCheckList: any = []

            for (const item of mainArray) {
                const { BodyPartID, isAbnormal } = item
                statusCheckList.push({ BodyPartID, isAbnormal })

                const checkedConcerns = item.Concerns.filter((concern) => concern.checked)
                checkedConcerns.forEach((concern) => {
                    const { ConcernID, bitWellnessCheckConcernsActive } = concern
                    concernsCheckList.push({ ConcernID, isActive: bitWellnessCheckConcernsActive })
                })
            }

            console.log("concernsCheckList:", concernsCheckList)
            console.log("statusCheckList:", statusCheckList)

            GetWellnessByCheckInID(props.checkInDetails?.CheckInID)
                .then((response_app_wellness) => {
                    if (response_app_wellness.data.length > 0) {
                        let putBody = {
                            checkInID: props.checkInDetails?.CheckInID,
                            notesForClient: wellnessBaseDataList.NotesForClient,
                            suggestions: wellnessBaseDataList.Suggestions,
                            evaluatorID: wellnessBaseDataList.EvaluatorID,
                            numCreatedBy: 1,
                            checkImageList: wellnessBaseDataList.checkImageList,
                            concernsCheckList: concernsCheckList,
                            statusCheckList: statusCheckList,
                            wellnessRecommendedServicesOrProducts: combinedArr,
                        }
                        putWellness(putBody)
                        console.log("put-sent", putBody)
                    } else {
                        let postBody = {
                            checkInID: props.checkInDetails?.CheckInID,
                            notesForClient: wellnessBaseDataList.NotesForClient,
                            suggestions: wellnessBaseDataList.Suggestions,
                            evaluatorID: wellnessBaseDataList.EvaluatorID,
                            numCreatedBy: 1,
                            checkImageList: wellnessBaseDataList.checkImageList,
                            concernsCheckList: concernsCheckList,
                            statusCheckList: statusCheckList,
                            wellnessRecommendedServicesOrProducts: combinedArr,
                        }
                        postWellness(postBody)
                        console.log("post-sent", postBody)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                })
        }
    }

    useEffect(() => {
        console.log("impD", importedData)
    }, [importedData])
    async function postWellness(data) {
        await axiosPrivate
            .post(`Wellness/PopulatePetWellness`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                if (result.status === 200) {
                    setIsLoading(false)
                    setImportedData([])
                    toast.success(`Wellness details saved!`)
                    props.setIsOpenWellnessCheckModal(false)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    async function putWellness(data) {
        await axiosPrivate
            .put(`Wellness/UpdatePetWellness`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                if (result.status === 200) {
                    setIsLoading(false)
                    setImportedData([])
                    toast.success(`Wellness details updated!`)
                    props.setIsOpenWellnessCheckModal(false)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    async function GetWellnessByPetTypeID(id) {
        setIsLoading(true)
        const result: any = await axiosPrivate.get(`Wellness/GetWelnessByPetTypeID?PetTypeID=${id}`, buildHeaderObject(auth))
        return result
    }
    async function GetWellnessByCheckInID(id) {
        setIsLoading(true)
        const result: any = await axiosPrivate.get(`Wellness/GetWellnessByWelnessCheckID?WellnessCheckInID=${id}`, buildHeaderObject(auth))
        return result
    }

    const getLocationServiceItemsPriceList = async (locationID, dateOfService, petBreedID, petSizeID, employeeID, employeeName, petID, petTypeID) => {
        console.log("all service fetch - sent", locationID, dateOfService, petBreedID, petSizeID, employeeID, employeeName, petID, petTypeID)
        const apiUrl = `Payment/GetLocationServiceItemsPriceList`

        try {
            const response = await axiosPrivate.get(
                apiUrl,
                buildHeaderObject(auth, false, false, false, {
                    numLocationID: locationID,
                    dtDate_of_service: dateOfService,
                    numPetBreedID: petBreedID,
                    numPetSizeID: petSizeID,
                    numEmployeeID: employeeID,
                    varEmployeeName: employeeName,
                    numPetID: petID,
                    numPetTypeID: petTypeID,
                })
            )
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    const getLocationProductItemsPriceList = async (locationID, dateOfService, employeeID, employeeName, petID) => {
        console.log("all product fetch - sent", locationID, dateOfService, employeeID, employeeName, petID)
        const apiUrl = `Payment/GetLocationProductItemsPriceList`

        try {
            const response = await axiosPrivate.get(
                apiUrl,
                buildHeaderObject(auth, false, false, false, {
                    numLocationID: locationID,
                    dtDate_of_service: dateOfService,
                    numEmployeeID: employeeID,
                    varEmployeeName: employeeName,
                    numPetID: petID,
                })
            )
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    useEffect(() => {
        console.log("cjk", props.checkInDetails)
        if (props.isOpenWellnessCheckModal === true) {
            GetWellnessByPetTypeID(props.bookingDetails?.Pet?.PetTypeID)
                .then((response) => {
                    GetWellnessByCheckInID(props.checkInDetails?.CheckInID)
                        .then((response_app_wellness) => {
                            if (response_app_wellness.data.length > 0) {
                                console.log("well", response_app_wellness)
                                let firstArray = response.data.Wellness
                                let secondArray = response_app_wellness.data[0].Wellness

                                for (let i = 0; i < firstArray.length; i++) {
                                    const concerns1 = firstArray[i].Concerns
                                    const bodyPartID = firstArray[i].BodyPartID

                                    const matchingObj = secondArray.find((item) => item.BodyPartID === bodyPartID)

                                    if (matchingObj) {
                                        firstArray[i].isAbnormal = matchingObj.IsAbnormal

                                        for (let j = 0; j < concerns1.length; j++) {
                                            const concernID = concerns1[j].ConcernID

                                            const isMatchingConcern = matchingObj.Concerns.some((concern) => concern.ConcernID === concernID)
                                            concerns1[j].checked = isMatchingConcern
                                        }
                                    } else {
                                        firstArray[i].isAbnormal = false

                                        for (let j = 0; j < concerns1.length; j++) {
                                            concerns1[j].checked = false
                                        }
                                    }
                                }

                                console.log("sorted", firstArray)

                                const checkImageList = response_app_wellness.data[0].checkImageList.map((item) => {
                                    return {
                                        varImagePath: item.WellnessCheckImagePath,
                                    }
                                })

                                let wellObj = {
                                    WellnessCheckID: response_app_wellness.data[0].WellnessCheckID,
                                    NotesForClient: response_app_wellness.data[0].NotesForClient,
                                    Suggestions: response_app_wellness.data[0].Suggestions,
                                    EvaluatorID: response_app_wellness.data[0].EvaluatorID,
                                    NumCreatedBy: response_app_wellness.data[0].NumCreatedBy,
                                    dtCreatedDate: response_app_wellness.data[0].dtCreatedDate,
                                    Wellness: firstArray,
                                    checkImageList: checkImageList,
                                    RecommendedServices: response_app_wellness.data[0].RecommendedServices,
                                    RecommendedProducts: response_app_wellness.data[0].RecommendedProducts,
                                    appointmentDetails: {
                                        appointmentID: props.checkInDetails?.Appointment?.numAppointmentID,
                                        location: props.checkInDetails?.Appointment?.varLocationName,
                                        startDate: props.checkInDetails?.Appointment?.dtApptStartTime,
                                        petName: props.checkInDetails?.Pet?.PetName,
                                    },
                                }
                                setWellnessBaseDataList(wellObj)
                                console.log("on-Open-Obj", wellObj)
                            } else {
                                setWellnessBaseDataList({
                                    WellnessCheckID: null,
                                    NotesForClient: null,
                                    Suggestions: null,
                                    EvaluatorID: null,
                                    NumCreatedBy: null,
                                    dtCreatedDate: null,
                                    Wellness: response.data.Wellness,
                                    checkImageList: [],
                                    RecommendedServices: [],
                                    RecommendedProducts: [],
                                    appointmentDetails: {
                                        appointmentID: null,
                                        location: null,
                                        startDate: null,
                                        petName: null,
                                    },
                                })
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                            setIsLoading(false)
                        })
                })
                .catch((err) => {
                    console.log(err)
                    toast.error("Something went wrong!")
                    setIsLoading(false)
                })

            getLocationServiceItemsPriceList(
                props.checkInDetails?.Appointment?.numLocationID,
                currDate,
                props.checkInDetails?.Pet?.PetBreedID,
                props.checkInDetails?.Pet?.numPetSizeID,
                1,
                "Ravin",
                props.checkInDetails?.Pet?.PetID,
                props.checkInDetails?.Pet?.PetTypeID
            )
                .then((data) => {
                    console.log("service-Fetch", data)
                    if (data.Services !== undefined) {
                        console.log("res - All services with SP-selected services", data)
                        setServiceList(data.Services)
                    } else {
                        setIsLoading(false)
                        setServiceList([])
                    }
                })
                .catch((error) => {
                    console.log("error", error)
                })

            getLocationProductItemsPriceList(props.checkInDetails?.Appointment?.numLocationID, currDate, 1, "Ravin", props.checkInDetails?.Pet?.PetID)
                .then((data) => {
                    console.log("res - All products list", data)
                    setProductList(data)
                    setIsLoading(false)
                })
                .catch((error) => {
                    console.log("error", error)
                })
        }
    }, [props.isOpenWellnessCheckModal])

    const unCheckConcernsIfNotNormal = (index) => {
        if (index >= 0 && index < wellnessBaseDataList.Wellness.length) {
            const updatedWellnessData = [...wellnessBaseDataList.Wellness]
            const bodyPart = updatedWellnessData[index]

            if (bodyPart.isAbnormal === false) {
                const updatedConcerns = bodyPart.Concerns.map((concern) => ({
                    ...concern,
                    checked: false,
                }))
                bodyPart.Concerns = updatedConcerns
            }
            updatedWellnessData[index] = bodyPart
            setWellnessBaseDataList({
                ...wellnessBaseDataList,
                Wellness: updatedWellnessData,
            })
        }
    }

    const selectService = (e) => {
        console.log("eee", e)

        const wellness: any = { ...wellnessBaseDataList }
        const isServiceAlreadySelected = wellness?.RecommendedServices.some((service) => service.ServiceID === e.value)
        if (isServiceAlreadySelected) {
            console.log("Service already selected.")
        } else {
            wellness.RecommendedServices.push({
                checkInID: props.checkInDetails?.CheckInID,
                serviceOrProductID: e?.value,
                type: "S",
                ServiceID: e?.value,
                varServiceItemDescription: "",
                varServiceItemName: e?.label,
            })

            setWellnessBaseDataList(wellness)
        }
    }

    const selectProduct = (e) => {
        console.log("eee", e)

        const wellness: any = { ...wellnessBaseDataList }
        const isProdAlreadySelected = wellness?.RecommendedProducts.some((product) => product.ProductID === e.value)
        if (isProdAlreadySelected) {
            console.log("product already selected.")
        } else {
            wellness.RecommendedProducts.push({
                checkInID: props.checkInDetails?.CheckInID,
                serviceOrProductID: e?.value,
                type: "P",
                ProductID: e?.value,
                varProductDescription: "",
                varProductName: e?.label,
            })

            setWellnessBaseDataList(wellness)
        }
    }

    const removeSelectedProduct = (productToRemove) => {
        console.log(productToRemove)
        const updatedWellness = { ...wellnessBaseDataList }
        const indexOfProduct = updatedWellness.RecommendedProducts.findIndex((product) => product.ProductID === productToRemove.ProductID)

        if (indexOfProduct !== -1) {
            updatedWellness.RecommendedProducts.splice(indexOfProduct, 1)

            setWellnessBaseDataList(updatedWellness)
        }
    }

    const removeSelectedService = (serviceToRemove) => {
        console.log(serviceToRemove)
        const updatedWellness = { ...wellnessBaseDataList }
        const indexOfService = updatedWellness.RecommendedServices.findIndex((service) => service.ServiceID === serviceToRemove.ServiceID)

        if (indexOfService !== -1) {
            updatedWellness.RecommendedServices.splice(indexOfService, 1)

            setWellnessBaseDataList(updatedWellness)
        }
    }

    async function getRecommendedProducts(BodyPartID, ConcernID) {
        // console.log("payload sales", BodyPartID, ConcernID)
        const result: any = await axiosPrivate.get(`SalesItemsV/GetRecommendedProductsByLocIDBodyPartIDConcernID?LocationID=1&BodyPartID=${BodyPartID}&ConcernID=${ConcernID}`, buildHeaderObject(auth))
        return result
    }

    async function getRecommendedServices(BodyPartID, ConcernID) {
        // console.log("payload services", BodyPartID, ConcernID)
        const result: any = await axiosPrivate.get(
            `ServiceItems/GetRecommendedServicesByLocIDBodyPartIDConcernID?LocationID=1&BodyPartID=${BodyPartID}&ConcernID=${ConcernID}`,
            buildHeaderObject(auth)
        )
        return result
    }

    async function getConcernDetails(BodyPartID, ConcernID) {
        const result: any = await axiosPrivate.get(`Wellness/GetConcernDetailsByLocIDBodyPartIDConcernID?LocationID=1&BodyPartID=${BodyPartID}&ConcernID=${ConcernID}`, buildHeaderObject(auth))
        return result
    }

    const [loadingProducts, setLoadingProducts] = useState(false)
    const [loadingServices, setLoadingServices] = useState(false)

    const [recommendedProductList, setRecommendedProductList] = useState([]) as any
    const [recommendedServiceList, setRecommendedServiceList] = useState([]) as any

    useEffect(() => {
        console.log("wellBase-USE", wellnessBaseDataList)

        if (wellnessBaseDataList !== undefined && wellnessBaseDataList.Wellness !== undefined && wellnessBaseDataList.Wellness.length > 0) {
            if (wellnessBaseDataList.Wellness[currBodyPart]?.Concerns.some((concern) => concern.checked)) {
                const filteredConcerns = wellnessBaseDataList.Wellness[currBodyPart]?.Concerns.filter((concern) => concern.checked)

                if (filteredConcerns.length > 0) {
                    // console.log("filteredConcerns", wellnessBaseDataList.Wellness[currBodyPart])
                    setLoadingProducts(true)

                    getRecommendedProducts(wellnessBaseDataList.Wellness[currBodyPart]?.BodyPartID, filteredConcerns[filteredConcerns.length - 1]?.ConcernID)
                        .then((res) => {
                            // console.log("prods", res)

                            setRecommendedProductList(res?.data)
                            setLoadingProducts(false)
                        })
                        .catch((err) => {
                            setRecommendedProductList([])
                            setLoadingProducts(false)
                        })

                    getRecommendedServices(wellnessBaseDataList.Wellness[currBodyPart]?.BodyPartID, filteredConcerns[filteredConcerns.length - 1]?.ConcernID)
                        .then((res) => {
                            console.log("sers", res)

                            setRecommendedServiceList(res?.data)
                            setLoadingServices(false)
                        })
                        .catch((err) => {
                            setRecommendedServiceList([])
                            setLoadingServices(false)
                        })
                }
            }
        }
    }, [wellnessBaseDataList])

    const [concernDetails, setConcernDetails] = useState([]) as any

    const viewConcernInfo = (bodyPartID, concernID) => {
        console.log("deets", wellnessBaseDataList.Wellness[currBodyPart]?.BodyPartID, concernID)

        getConcernDetails(wellnessBaseDataList.Wellness[currBodyPart]?.BodyPartID, concernID)
            .then((res) => {
                console.log("details", res)

                if (res?.data.length > 0) {
                    setConcernDetails(res?.data)
                } else {
                    setConcernDetails([])
                }
                setShowConcernDetails(true)
            })
            .catch((err) => {
                setRecommendedServiceList([])
                setLoadingServices(false)
            })
    }

    return (
        <div>
            <Modal open={props.isOpenWellnessCheckModal} backgroundColor={"#F9FAFB"}>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: ".8rem", width: "700px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                            <p style={{ fontSize: "28px", fontWeight: "600", color: "#192252" }}>Wellness Check-In</p>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "1rem" }}>
                                <div
                                    onClick={() => {
                                        setReferenceImage(true)
                                    }}
                                    style={{
                                        width: "250px",
                                        height: "52px",
                                        backgroundColor: "#111827",
                                        borderRadius: "52px",
                                        boxShadow: "0px 25px 50px -12px rgba(16, 24, 40, 0.25)",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        cursor: "pointer",
                                    }}
                                >
                                    <p style={{ color: "#EEF5FF", fontWeight: "600" }}>Reference Image</p>
                                </div>
                                <CloseButton onClick={() => props.setIsOpenWellnessCheckModal(false)} />
                            </div>
                        </div>
                    </div>

                    <div style={{ width: "100%", display: "flex", gap: "1rem" }}>
                        <div style={{ backgroundColor: "#FFFF", padding: "1rem", borderRadius: "4px", width: "100%" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", gap: "25rem" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", gap: ".6rem" }}>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Avatar
                                            isVip={props.bookingDetails?.Customer?.bitIsVip}
                                            img={
                                                props.bookingDetails?.Customer?.varCustomerImagePath == null
                                                    ? `${process.env.REACT_APP_MEDIA_URL}/directories/customers/${props.bookingDetails?.Customer?.varCustomerImagePath}`
                                                    : `${process.env.REACT_APP_MEDIA_URL}/directories/customers/${props.bookingDetails?.Customer?.varCustomerImagePath}`
                                            }
                                            className={"medium"}
                                        />
                                    </div>

                                    <div style={{ gap: "5px", width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between" }}>
                                        <div style={{ fontWeight: 500 }}>
                                            {props.bookingDetails?.Customer?.varCustomerFirstName} {props.bookingDetails?.Customer?.varCustomerLastName}
                                        </div>
                                        <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                                            <LocationChip
                                                label={props.bookingDetails?.Customer?.varCustomerState}
                                                shape="square"
                                                icon={<Place style={{ fontSize: "15px" }} />}
                                                customStyle="BlueFilled"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* <div>
                                    <Button color="info" variant="text" text="View Profile" />
                                </div> */}
                            </div>
                        </div>

                        <div style={{ backgroundColor: "#FFFF", padding: "1rem", borderRadius: "4px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "1rem" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", gap: ".6rem" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Avatar
                                            shape="rounded"
                                            img={
                                                props.bookingDetails?.Pet?.PetImagePath == null
                                                    ? `${process.env.REACT_APP_MEDIA_URL}/directories/pets/${props.bookingDetails?.Pet?.PetImagePath}`
                                                    : `${process.env.REACT_APP_MEDIA_URL}/directories/pets/${props.bookingDetails?.Pet?.PetImagePath}`
                                            }
                                            isVip={false}
                                            className={"medium"}
                                        />
                                    </div>

                                    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between" }}>
                                        <div style={{ fontWeight: 600, fontSize: "16px", color: "#002867" }}>{props.bookingDetails?.Pet?.PetName}</div>
                                        <div style={{ display: "flex", gap: "12px", alignItems: "center", marginTop: ".2rem" }}>
                                            <div style={{ fontWeight: 400, fontSize: 14, color: "#848FAC" }}>{props.bookingDetails.Pet?.PetBreed}</div>
                                        </div>
                                        <div style={{ display: "flex", marginTop: ".5rem", gap: ".3rem", alignItems: "center" }}>
                                            <Chip
                                                size="small"
                                                customBackgroundColor={"#EFF3FA"}
                                                customBorder={"#EFF3FA"}
                                                customBorderRadius={"16px"}
                                                fontColor={"#192252"}
                                                label={props.bookingDetails.Pet?.varPetSizeName}
                                            />
                                            <Chip
                                                size="small"
                                                customBackgroundColor={"#EFF3FA"}
                                                customBorder={"#EFF3FA"}
                                                customBorderRadius={"16px"}
                                                fontColor={"#192252"}
                                                label={props.bookingDetails.Pet?.PetSex === "M" ? "Male" : "Female"}
                                            />
                                            <Chip
                                                size="small"
                                                customBackgroundColor={"#EFF3FA"}
                                                customBorder={"#EFF3FA"}
                                                customBorderRadius={"16px"}
                                                fontColor={"#192252"}
                                                label={props.bookingDetails.Pet?.PetType}
                                            />
                                            <Chip
                                                size="small"
                                                customBackgroundColor={"#EFF3FA"}
                                                customBorder={"#EFF3FA"}
                                                customBorderRadius={"16px"}
                                                fontColor={"#192252"}
                                                label={props.bookingDetails.Pet?.PetColor}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>{/* <Button color="info" variant="text" text="View Profile" /> */}</div>
                            </div>
                        </div>
                    </div>

                    <div style={{ width: "100%" }}>
                        <div style={{ height: "600px", overflowY: "scroll" }}>
                            <div style={{ textAlign: "center", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <ImageMapper src={outline} map={MAP} onClick={handleClick} width={560} height={460} />
                                {wellnessBaseDataList === undefined ? (
                                    "Loading..."
                                ) : wellnessBaseDataList.Wellness === undefined ? (
                                    "Loading..."
                                ) : wellnessBaseDataList.Wellness.length > 0 ? (
                                    <Modal open={showBodyPart} backgroundColor={"#F9FAFB"}>
                                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: ".8rem", width: "750px" }}>
                                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                                    <p style={{ fontSize: "28px", fontWeight: "600", color: "#192252" }}>{wellnessBaseDataList.Wellness[currBodyPart]?.WellnessCheckBodyPartName}</p>
                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "1rem" }}>
                                                        <CloseButton onClick={() => setShowBodyPart(false)} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={4}>
                                                        <div>
                                                            <p style={{ color: "#192252", fontSize: "14px", fontWeight: "600" }}>Status</p>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <RadioGroup
                                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                                            name="controlled-radio-buttons-group"
                                                            value={
                                                                wellnessBaseDataList.Wellness[currBodyPart].isAbnormal !== undefined
                                                                    ? wellnessBaseDataList.Wellness[currBodyPart].isAbnormal !== null
                                                                        ? wellnessBaseDataList.Wellness[currBodyPart].isAbnormal.toString()
                                                                        : "false"
                                                                    : "false"
                                                            }
                                                            onChange={(e) => {
                                                                console.log("concern Id", wellnessBaseDataList.Wellness[currBodyPart])

                                                                const wellBaseList: any = { ...wellnessBaseDataList }
                                                                wellBaseList.Wellness[currBodyPart].isAbnormal = !wellBaseList.Wellness[currBodyPart].isAbnormal
                                                                setWellnessBaseDataList(wellBaseList)
                                                                unCheckConcernsIfNotNormal(currBodyPart)
                                                            }}
                                                        >
                                                            <div style={{ display: "flex", gap: "8px" }}>
                                                                <FormControlLabel
                                                                    value={false}
                                                                    control={<Radio style={{ padding: "0 5px 0 0" }} size={"small"} />}
                                                                    label={<p style={{ fontSize: "16px" }}>Normal</p>}
                                                                />
                                                                <FormControlLabel
                                                                    value={true}
                                                                    control={<Radio style={{ padding: "0 5px 0 0" }} size={"small"} />}
                                                                    label={<p style={{ fontSize: "16px" }}>Abnormal</p>}
                                                                />
                                                            </div>
                                                        </RadioGroup>
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                                                    <Grid item xs={4}>
                                                        <div>
                                                            <p style={{ color: "#192252", fontSize: "14px", fontWeight: "600" }}>Concerns</p>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridColumnGap: ".5rem", rowGap: ".5rem", width: "min-content" }}>
                                                            {wellnessBaseDataList.Wellness[currBodyPart].Concerns.map((con, con_index) => {
                                                                if (con.checked === true) {
                                                                    return (
                                                                        <Chip
                                                                            onDelete={() => viewConcernInfo(currBodyPart, con?.ConcernID)}
                                                                            deleteIcon={<Info />}
                                                                            label={con.ConcernName}
                                                                            shape="round"
                                                                            fontColor={"#FFFF"}
                                                                            customBackgroundColor={"#005DF1"}
                                                                            fontWeight={400}
                                                                            fontSize={"14px"}
                                                                            size={"medium"}
                                                                            onClick={() => {
                                                                                console.log("concern Id", con)

                                                                                const wellBaseList: any = { ...wellnessBaseDataList }
                                                                                wellBaseList.Wellness[currBodyPart].Concerns[con_index].checked =
                                                                                    !wellBaseList.Wellness[currBodyPart].Concerns[con_index].checked
                                                                                setWellnessBaseDataList(wellBaseList)
                                                                            }}
                                                                            icon={<CheckCircle style={{ color: "#FFFF", fontSize: "16px" }} />}
                                                                            customBorderRadius={"16px"}
                                                                        />
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <Chip
                                                                            onDelete={() => viewConcernInfo(currBodyPart, con?.ConcernID)}
                                                                            deleteIcon={<Info />}
                                                                            label={con.ConcernName}
                                                                            shape="round"
                                                                            fontColor={"#000000"}
                                                                            customBackgroundColor={"#D1D5DB"}
                                                                            fontWeight={400}
                                                                            fontSize={"14px"}
                                                                            size={"medium"}
                                                                            isDisabled={!wellnessBaseDataList.Wellness[currBodyPart].isAbnormal}
                                                                            onClick={() => {
                                                                                const wellBaseList: any = { ...wellnessBaseDataList }
                                                                                wellBaseList.Wellness[currBodyPart].Concerns[con_index].checked =
                                                                                    !wellBaseList.Wellness[currBodyPart].Concerns[con_index].checked
                                                                                setWellnessBaseDataList(wellBaseList)
                                                                            }}
                                                                            customBorderRadius={"16px"}
                                                                        />
                                                                    )
                                                                }
                                                            })}
                                                        </div>
                                                    </Grid>
                                                </Grid>

                                                <div>
                                                    <div>
                                                        {loadingProducts ? (
                                                            <CircularProgress />
                                                        ) : (
                                                            <>
                                                                <p style={{ color: "#192252", fontSize: "14px", fontWeight: "600" }}>Recommended Products</p>
                                                                {wellnessBaseDataList.Wellness[currBodyPart].Concerns.some((concern) => concern.checked) ? (
                                                                    <div style={{ width: "100%", display: "flex", gap: "10px", overflowX: "scroll", padding: "10px 0" }}>
                                                                        {recommendedProductList.length > 0
                                                                            ? recommendedProductList.map((product) => {
                                                                                  return (
                                                                                      <div
                                                                                          style={{
                                                                                              width: "200px",
                                                                                              border: "1px solid #e9e9e9",
                                                                                              borderRadius: "8px",
                                                                                              backgroundColor: "#fff",
                                                                                              padding: "10px",
                                                                                              boxShadow: "5px 5px 5px 0px rgba(235,235,235,1)",
                                                                                              textAlign: "center",
                                                                                          }}
                                                                                      >
                                                                                          <img
                                                                                              alt=""
                                                                                              src={
                                                                                                  product?.varImagePath === "" || product?.varImagePath === null
                                                                                                      ? undefined
                                                                                                      : `${process.env.REACT_APP_MEDIA_URL}products/` + product?.ImagePath
                                                                                              }
                                                                                              style={{
                                                                                                  objectFit: "scale-down",
                                                                                                  borderRadius: "5px",
                                                                                                  marginTop: "10px",
                                                                                                  marginBottom: ".3rem",
                                                                                                  width: "160px",
                                                                                                  height: "160px",
                                                                                              }}
                                                                                          />
                                                                                          <p style={{ fontWeight: "600", textAlign: "center", fontSize: "15px", color: "#005df1" }}>
                                                                                              {product?.varProductName.split("-")[0]}
                                                                                          </p>
                                                                                          <p style={{ fontWeight: "500", textAlign: "center" }}>{product?.varProductName.split("-")[1]}</p>
                                                                                          <p style={{ fontWeight: "800", textAlign: "center", fontSize: "18px", paddingBottom: "8px" }}>
                                                                                              ${product?.numSalesPrice}
                                                                                          </p>
                                                                                      </div>
                                                                                  )
                                                                              })
                                                                            : null}
                                                                    </div>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>

                                                <div>
                                                    <div>
                                                        {loadingServices ? (
                                                            <CircularProgress />
                                                        ) : (
                                                            <>
                                                                <p style={{ color: "#192252", fontSize: "14px", fontWeight: "600" }}>Recommended Services</p>
                                                                {wellnessBaseDataList.Wellness[currBodyPart].Concerns.some((concern) => concern.checked) ? (
                                                                    <div style={{ width: "100%", display: "flex", gap: "10px", overflowX: "scroll", padding: "10px 0" }}>
                                                                        {recommendedServiceList.length > 0
                                                                            ? recommendedServiceList.map((service) => {
                                                                                  return (
                                                                                      <div
                                                                                          style={{
                                                                                              width: "200px",
                                                                                              border: "1px solid #e9e9e9",
                                                                                              borderRadius: "8px",
                                                                                              backgroundColor: "#fff",
                                                                                              padding: "10px",
                                                                                              boxShadow: "5px 5px 5px 0px rgba(235,235,235,1)",
                                                                                              textAlign: "center",
                                                                                          }}
                                                                                      >
                                                                                          <p style={{ fontWeight: "600", textAlign: "center", fontSize: "15px", color: "#005df1" }}>
                                                                                              {service?.varServiceItemName}
                                                                                          </p>
                                                                                          <p style={{ fontWeight: "800", textAlign: "center", fontSize: "18px", paddingBottom: "8px" }}>
                                                                                              ${service?.numServiceItemSalesPrice}
                                                                                          </p>
                                                                                      </div>
                                                                                  )
                                                                              })
                                                                            : null}
                                                                    </div>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal>
                                ) : null}
                            </div>
                            {/*  showConcernDetails */}
                            <Modal open={showConcernDetails} backgroundColor={"#F9FAFB"}>
                                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: ".8rem", width: "750px" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                            <p style={{ fontSize: "28px", fontWeight: "600", color: "#192252" }}>Concern Details</p>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "1rem" }}>
                                                <CloseButton onClick={() => setShowConcernDetails(false)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                        {concernDetails.map((detail) => {
                                            return (
                                                <div>
                                                    <p style={{ fontSize: "18px", fontWeight: 600 }}>Concern</p>
                                                    <p style={{ fontSize: "16px", lineHeight: "1.6em", marginBottom: "15px" }}>{detail?.ConcernName}</p>

                                                    <p style={{ fontSize: "18px", fontWeight: 600 }}>Description</p>
                                                    <p style={{ fontSize: "16px", lineHeight: "1.6em", marginBottom: "15px" }}>{detail?.Description}</p>

                                                    <p style={{ fontSize: "18px", fontWeight: 600 }}>Reference Images</p>
                                                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                                                        <img
                                                            alt=""
                                                            src={
                                                                detail?.ImagePath1 === "" || detail?.ImagePath1 === null
                                                                    ? undefined
                                                                    : `${process.env.REACT_APP_MEDIA_URL}concerns/` + detail?.ImagePath1
                                                            }
                                                            style={{
                                                                objectFit: "scale-down",
                                                                borderRadius: "5px",
                                                                marginTop: "10px",
                                                                marginBottom: ".3rem",
                                                                width: "100%",
                                                                height: "160px",
                                                            }}
                                                        />
                                                        <img
                                                            alt=""
                                                            src={
                                                                detail?.ImagePath2 === "" || detail?.ImagePath2 === null
                                                                    ? undefined
                                                                    : `${process.env.REACT_APP_MEDIA_URL}concerns/` + detail?.ImagePath2
                                                            }
                                                            style={{
                                                                objectFit: "scale-down",
                                                                borderRadius: "5px",
                                                                marginTop: "10px",
                                                                marginBottom: ".3rem",
                                                                width: "100%",
                                                                height: "160px",
                                                            }}
                                                        />
                                                        <img
                                                            alt=""
                                                            src={
                                                                detail?.ImagePath3 === "" || detail?.ImagePath3 === null
                                                                    ? undefined
                                                                    : `${process.env.REACT_APP_MEDIA_URL}concerns/` + detail?.ImagePath3
                                                            }
                                                            style={{
                                                                objectFit: "scale-down",
                                                                borderRadius: "5px",
                                                                marginTop: "10px",
                                                                marginBottom: ".3rem",
                                                                width: "100%",
                                                                height: "160px",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </Modal>
                            {wellnessBaseDataList === undefined ? (
                                "Loading."
                            ) : wellnessBaseDataList.Wellness === undefined ? (
                                "Loading.."
                            ) : wellnessBaseDataList.Wellness.length > 0 ? (
                                wellnessBaseDataList.Wellness.map((item, index) => {
                                    return (
                                        <div style={{ width: "100%", marginBottom: "15px" }}>
                                            <Accordion>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" style={{ backgroundColor: "#FFFF", margin: "0" }}>
                                                    <div style={{ width: "95%", display: "flex", justifyContent: "space-between" }}>
                                                        <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                                            <p style={{ margin: "0", color: "#192252", fontSize: "16px", fontWeight: "600" }}>{item.WellnessCheckBodyPartName}</p>
                                                        </div>
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={4}>
                                                            <div>
                                                                <p style={{ color: "#192252", fontSize: "14px", fontWeight: "600" }}>Status</p>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <RadioGroup
                                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                                name="controlled-radio-buttons-group"
                                                                value={item.isAbnormal !== undefined ? (item.isAbnormal !== null ? item.isAbnormal.toString() : "false") : "false"}
                                                                onChange={(e) => {
                                                                    console.log("concern Id", item)

                                                                    const wellBaseList: any = { ...wellnessBaseDataList }
                                                                    wellBaseList.Wellness[index].isAbnormal = !wellBaseList.Wellness[index].isAbnormal
                                                                    setWellnessBaseDataList(wellBaseList)
                                                                    unCheckConcernsIfNotNormal(index)
                                                                }}
                                                            >
                                                                <div style={{ display: "flex", gap: "8px" }}>
                                                                    <FormControlLabel
                                                                        value={false}
                                                                        control={<Radio style={{ padding: "0 5px 0 0" }} size={"small"} />}
                                                                        label={<p style={{ fontSize: "12px" }}>Normal</p>}
                                                                    />
                                                                    <FormControlLabel
                                                                        value={true}
                                                                        control={<Radio style={{ padding: "0 5px 0 0" }} size={"small"} />}
                                                                        label={<p style={{ fontSize: "12px" }}>Notes</p>}
                                                                    />
                                                                </div>
                                                            </RadioGroup>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={2} style={{ marginTop: "1rem" }}>
                                                        <Grid item xs={4}>
                                                            <div>
                                                                <p style={{ color: "#192252", fontSize: "14px", fontWeight: "600" }}>Concerns</p>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridColumnGap: ".5rem", rowGap: ".5rem", width: "min-content" }}>
                                                                {item.Concerns.map((con, con_index) => {
                                                                    if (con.checked === true) {
                                                                        return (
                                                                            <Chip
                                                                                label={con.ConcernName}
                                                                                shape="round"
                                                                                fontColor={"#FFFF"}
                                                                                customBackgroundColor={"#005DF1"}
                                                                                fontWeight={400}
                                                                                fontSize={"12px"}
                                                                                size={"medium"}
                                                                                onClick={() => {
                                                                                    console.log("concern Id", con)

                                                                                    const wellBaseList: any = { ...wellnessBaseDataList }
                                                                                    wellBaseList.Wellness[index].Concerns[con_index].checked = !wellBaseList.Wellness[index].Concerns[con_index].checked
                                                                                    setWellnessBaseDataList(wellBaseList)
                                                                                }}
                                                                                icon={<CheckCircle style={{ color: "#FFFF", fontSize: "16px" }} />}
                                                                                customBorderRadius={"16px"}
                                                                            />
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <Chip
                                                                                label={con.ConcernName}
                                                                                shape="round"
                                                                                fontColor={"#000000"}
                                                                                customBackgroundColor={"#D1D5DB"}
                                                                                fontWeight={400}
                                                                                fontSize={"12px"}
                                                                                size={"medium"}
                                                                                isDisabled={!wellnessBaseDataList.Wellness[index].isAbnormal}
                                                                                onClick={() => {
                                                                                    const wellBaseList: any = { ...wellnessBaseDataList }
                                                                                    wellBaseList.Wellness[index].Concerns[con_index].checked = !wellBaseList.Wellness[index].Concerns[con_index].checked
                                                                                    setWellnessBaseDataList(wellBaseList)
                                                                                }}
                                                                                customBorderRadius={"16px"}
                                                                            />
                                                                        )
                                                                    }
                                                                })}
                                                            </div>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={2} style={{ paddingTop: "5px" }}>
                                                        <Grid item xs={4}></Grid>
                                                        {/* <Grid item xs={8}>
                                                    <p style={{ color: '#2076FF', fontSize: "14px", fontWeight: "400", cursor: "pointer", paddingLeft:"10px" }}>Select all</p>
                                                </Grid> */}
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                    )
                                })
                            ) : (
                                <p>Loading...</p>
                            )}
                            <Modal open={isOpenUploadImage}>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem", flexDirection: "column" }}>
                                    <div style={{ display: "flex", justifyContent: "end", width: "100%" }}>
                                        <CloseButton onClick={() => setIsOpenUploadImage(!isOpenUploadImage)} />
                                    </div>
                                    <div style={{ backgroundColor: "#fff", width: "100%" }}>
                                        <div
                                            style={{
                                                border: "2px dashed #5f9cfe",
                                                borderRadius: "8px",
                                                padding: ".8rem",
                                                backgroundColor: "#fff",
                                            }}
                                        >
                                            {
                                                <FileUploader
                                                    handleChange={readFile}
                                                    onDrop={readFile}
                                                    disabled={loading ? true : false}
                                                    name="file"
                                                    types={fileTypes}
                                                    multiple={true}
                                                    label=""
                                                    children={
                                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                            <FileUploadOutlined style={{ color: "#2076FF" }} />
                                                            <p style={{ color: "#2076FF", fontWeight: "500" }}>Drag and drop or browse to choose a file</p>
                                                        </div>
                                                    }
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                            <div style={{ display: "flex", flexDirection: "column", gap: "1rem", marginTop: "1rem", backgroundColor: "#F9FAFB" }}>
                                <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "1rem", backgroundColor: "#FFFF", paddingBottom: "1rem", borderRadius: "0px 0px 8px 8px" }}>
                                    <div style={{ borderBottom: "1px solid #EFF3FA", borderRadius: "8px 8px 0px 0px", padding: "1rem" }}>
                                        <p style={{ margin: "0", color: "#192252", fontSize: "16px", fontWeight: "600" }}>Problem area pictures</p>
                                    </div>
                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(5, 1fr)", gridColumnGap: "1rem", rowGap: "0ch", paddingLeft: "1rem" }}>
                                        {wellnessBaseDataList === undefined
                                            ? "Loading.."
                                            : wellnessBaseDataList.checkImageList === undefined
                                            ? "Loading.."
                                            : wellnessBaseDataList.checkImageList.length < 0
                                            ? null
                                            : wellnessBaseDataList.checkImageList.map((files, index) => {
                                                  return (
                                                      <img
                                                          alt=""
                                                          src={files.varImagePath == "" || files.varImagePath == null ? undefined : `${process.env.REACT_APP_MEDIA_URL}wellness/` + files.varImagePath}
                                                          style={{
                                                              objectFit: "cover",
                                                              borderRadius: "5px",
                                                              marginBottom: ".3rem",
                                                              width: "100px",
                                                              height: "96px",
                                                              border: "1px solid #e9e9e9",
                                                              boxShadow: "5px 5px 5px 0px rgba(235,235,235,1)",
                                                          }}
                                                      />
                                                  )
                                              })}
                                    </div>
                                    <div style={{ display: "flex", gap: "2rem", justifyContent: "space-between" }}>
                                        <div style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gridColumnGap: "1rem", rowGap: "0ch", paddingLeft: "1rem" }}>
                                            {Array.from<Blob>(importedData).map((files, index) => {
                                                return (
                                                    <div>
                                                        <div style={{ textAlign: "end" }}>
                                                            <IconButton onClick={() => deleteImage(index)}>
                                                                <HighlightOff style={{ color: "#1976d2" }} />
                                                            </IconButton>
                                                        </div>

                                                        <img
                                                            alt=""
                                                            src={URL.createObjectURL(files)}
                                                            style={{
                                                                objectFit: "cover",
                                                                borderRadius: "5px",
                                                                marginBottom: ".3rem",
                                                                width: "100px",
                                                                height: "96px",
                                                                border: "1px solid #bfbfbf",
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                children={
                                                    <>
                                                        <CameraAltOutlined />
                                                        <p style={{ color: "#FFFF" }}> &nbsp;Take Pictures</p>
                                                    </>
                                                }
                                                onClick={() => {
                                                    setIsOpenUploadImage(!isOpenUploadImage)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "1rem", backgroundColor: "#FFFF", paddingBottom: "1rem", borderRadius: "0px 0px 8px 8px" }}>
                                    <div style={{ borderBottom: "1px solid #EFF3FA", borderRadius: "8px 8px 0px 0px", padding: "1rem" }}>
                                        <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                            <ContentCut style={{ color: "#848FAC" }} />
                                            <p style={{ fontWeight: "500", color: "#000000" }}>Recommended Services</p>
                                        </div>
                                    </div>
                                    {serviceList !== undefined && serviceList !== null ? (
                                        <div style={{ width: "300px" }}>
                                            <AdvancedDropDown
                                                data={serviceList.map((i) => ({
                                                    label: i.ServiceType === "Main Service" ? `(M) ${i.varServiceItemName}` : `(A) ${i.varServiceItemName}`,
                                                    value: i.numServiceItemID,
                                                }))}
                                                ref={null}
                                                placeHolder={"Select a service"}
                                                onChange={(e) => {
                                                    selectService(e)
                                                }}
                                                isDisabled={serviceList.length > 0 ? undefined : true}
                                                passByID={true}
                                                value={0}
                                            />
                                        </div>
                                    ) : null}
                                    <div style={{ overflowX: "hidden", overflowY: "hidden", width: "calc(100%)", display: "flex", gap: "5px", paddingLeft: "10px" }}>
                                        {wellnessBaseDataList?.RecommendedServices?.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <div
                                                        style={{
                                                            backgroundColor: "#DCFCE7",
                                                            border: "1px solid #86EFAC",
                                                            borderRadius: "16px",
                                                            color: "#14532D",
                                                            fontSize: "13px",
                                                            fontWeight: "500",
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                            padding: "2px 10px 2px 10px",
                                                            gap: ".2rem",
                                                            width: "max-content",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            removeSelectedService(item)
                                                        }}
                                                    >
                                                        {Truncate(item.varServiceItemName, 20)}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>

                                <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "1rem", backgroundColor: "#FFFF", paddingBottom: "1rem", borderRadius: "0px 0px 8px 8px" }}>
                                    <div style={{ borderBottom: "1px solid #EFF3FA", borderRadius: "8px 8px 0px 0px", padding: "1rem" }}>
                                        <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                            <ShoppingCart style={{ color: "#848FAC" }} />
                                            <p style={{ fontWeight: "500", color: "#000000" }}>Recommended Products</p>
                                        </div>
                                    </div>
                                    {serviceList !== undefined && serviceList !== null ? (
                                        <div style={{ width: "300px" }}>
                                            <AdvancedDropDown
                                                data={productList.map((i) => ({ label: i.varProductName, value: i.numProductID }))}
                                                ref={null}
                                                placeHolder={"Select products"}
                                                onChange={(e) => {
                                                    selectProduct(e)
                                                }}
                                                isDisabled={productList.length > 0 ? undefined : true}
                                                passByID={true}
                                                value={0}
                                            />
                                        </div>
                                    ) : null}
                                    <div style={{ overflowX: "hidden", overflowY: "hidden", width: "calc(100%)", display: "flex", gap: "5px", paddingLeft: "10px" }}>
                                        {wellnessBaseDataList?.RecommendedProducts?.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <div
                                                        style={{
                                                            backgroundColor: "#DCFCE7",
                                                            border: "1px solid #86EFAC",
                                                            borderRadius: "16px",
                                                            color: "#14532D",
                                                            fontSize: "13px",
                                                            fontWeight: "500",
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                            padding: "2px 10px 2px 10px",
                                                            gap: ".2rem",
                                                            width: "max-content",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            removeSelectedProduct(item)
                                                        }}
                                                    >
                                                        {Truncate(item.varProductName, 20)}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>

                                <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "1rem", backgroundColor: "#FFFF", paddingBottom: "1rem", borderRadius: "0px 0px 8px 8px" }}>
                                    <div style={{ borderBottom: "1px solid #EFF3FA", borderRadius: "8px 8px 0px 0px", padding: "1rem" }}>
                                        <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                            <NoteAltOutlined style={{ color: "#848FAC" }} />
                                            <p style={{ fontWeight: "500", color: "#000000" }}>Notes for client</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                            {
                                                <TextField
                                                    inputProps={{
                                                        pattern: "^[a-zA-Z ]*$",
                                                    }}
                                                    style={{ width: "100%" }}
                                                    label={<div style={{ display: "flex", fontSize: "14px" }}>Add your notes here.</div>}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    type={"text"}
                                                    variant="filled"
                                                    multiline={true}
                                                    value={wellnessBaseDataList.NotesForClient}
                                                    onChange={(e) => {
                                                        const wellBaseList: any = { ...wellnessBaseDataList }
                                                        wellBaseList.NotesForClient = e.target.value
                                                        setWellnessBaseDataList(wellBaseList)
                                                    }}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "1rem", backgroundColor: "#FFFF", paddingBottom: "1rem", borderRadius: "0px 0px 8px 8px" }}>
                                    <div style={{ borderBottom: "1px solid #EFF3FA", borderRadius: "8px 8px 0px 0px", padding: "1rem" }}>
                                        <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                            <NoteAltOutlined style={{ color: "#848FAC" }} />
                                            <p style={{ fontWeight: "500", color: "#000000" }}>Suggestions for the home</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                            {
                                                <TextField
                                                    inputProps={{
                                                        pattern: "^[a-zA-Z ]*$",
                                                    }}
                                                    style={{ width: "100%" }}
                                                    label={<div style={{ display: "flex", fontSize: "14px" }}>Add your notes here.</div>}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    type={"text"}
                                                    variant="filled"
                                                    multiline={true}
                                                    value={wellnessBaseDataList.Suggestions}
                                                    onChange={(e) => {
                                                        const wellBaseList: any = { ...wellnessBaseDataList }
                                                        wellBaseList.Suggestions = e.target.value
                                                        setWellnessBaseDataList(wellBaseList)
                                                    }}
                                                />
                                            }
                                        </div>
                                        <div style={{ textAlign: "end" }}></div>
                                    </div>
                                </div>

                                <div style={{ width: "100%" }}>
                                    <p style={{ color: "#192252", fontWeight: "600", fontSize: "14" }}>DISCLAIMER: THIS FACILITY DOES NOT PROVIDE MEDICAL ADVICE. </p>
                                    <p style={{ color: "#6B7280", fontWeight: "400", fontSize: "14" }}>The information provided is for informational purposes ONLY</p>
                                    <p style={{ color: "#6B7280", fontWeight: "400", fontSize: "14" }}>LUCKY PAWS PET GROOMING (888) 256-3048 www.luckypawsusa.com</p>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", backgroundColor: "#192252", padding: "15px 0", borderRadius: "0 0 12px 12px" }}>
                            <div style={{ paddingRight: "15px" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    loading={loading}
                                    onClick={() => {
                                        handleSubmit()
                                    }}
                                >
                                    Save Changes
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal open={referenceImage}>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem", padding: "0 1rem 0 1rem" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                        <div style={{ color: "red", fontSize: "24", fontWeight: "600" }}></div>
                        <CloseButton onClick={() => setReferenceImage(false)} />
                    </div>

                    <img src={require("../../images/Wellness_reference_Image.PNG")} />
                </div>
            </Modal>
        </div>
    )
}

export default WellnessModal
