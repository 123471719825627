import {
    AddCircleOutline,
    AddOutlined,
    ArrowBack,
    CameraAltOutlined,
    Cancel,
    CheckCircle,
    DeleteOutline,
    FileUploadOutlined,
    InfoOutlined,
    Mic,
    NoteAlt,
    NoteAltOutlined,
    PhotoOutlined,
    Place,
    RemoveCircleOutline,
    ThumbUpAltOutlined,
    Warning,
} from "@mui/icons-material"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Tooltip,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { Avatar, Button, CloseButton, Chip } from "../../components"
import { Modal } from "../../components/components"
import { AvatarLabel } from "../../components/Table/components/AvatarLabel/AvatarLabel"
import { Truncate } from "../../utils/Validation"
import { axiosPrivate, buildHeaderObject } from "../../apis/axios"
import { toast } from "react-toastify"
import useAuth from "../../hooks/useAuth"

const CancelModal = (props: { isOpenCancelModal; setIsOpenCancelModal: CallableFunction; bookingData }) => {
    const { auth } = useAuth() as any

    const label = { inputProps: { "aria-label": "Checkbox demo" } }
    const [isLoading, setIsLoading] = useState(false)
    const [cancelReasonList, setCancelReasonList] = useState<any>([])

    async function GetAllScheduleCancellationReason() {
        setIsLoading(true)
        const result = await axiosPrivate.get(`Schedule/GetAllScheduleCancellationReason`, buildHeaderObject(auth))
        return result
    }

    useEffect(() => {
        GetAllScheduleCancellationReason()
            .then((res) => {
                setCancelReasonList(res.data.cancellationReason)
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setIsLoading(false)
                // toast.error("Something went wrong!");
            })
    }, [])

    return (
        <div>
            <Modal open={props.isOpenCancelModal}>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem", padding: "0 1rem 0 1rem" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center", padding: "1rem" }}>
                        <div style={{ color: "red", fontSize: "24", fontWeight: "600" }}>Cancel Booking</div>
                        <CloseButton onClick={() => props.setIsOpenCancelModal(false)} />
                    </div>

                    <div style={{ display: "flex" }}>
                        <div style={{ paddingLeft: "1rem" }}>
                            <p style={{ color: "#002867", fontSize: "16", fontWeight: "400", marginBottom: "1rem" }}>
                                Please tell us why you want to cancel{" "}
                                <span style={{ color: "#2076FF", fontSize: "16", fontWeight: "400" }}>{props.bookingData.Pet !== undefined ? props.bookingData.Pet.PetName : ""}</span>
                                <span style={{ color: "#2076FF", fontSize: "16", fontWeight: "400" }}>’s</span> booking
                            </p>
                            <div style={{ height: "250px", overflowY: "scroll" }}>
                                {cancelReasonList.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            style={{
                                                border: "1px solid #F3F4F6",
                                                borderRadius: "4px",
                                                backgroundColor: "#F9FAFB",
                                                display: "flex",
                                                gap: ".5rem",
                                                alignItems: "center",
                                                marginBottom: ".5rem",
                                                width: "390px",
                                            }}
                                        >
                                            <Checkbox {...label} />
                                            <p style={{ color: "#002867", fontSize: "14px", fontWeight: "400" }}>{item.varCancelReason}</p>
                                            <Tooltip title={item.varCancelReason}>
                                                <InfoOutlined style={{ fontSize: "20px", color: "gray" }} />
                                            </Tooltip>
                                        </div>
                                    )
                                })}
                            </div>
                            <TextField
                                variant="filled"
                                style={{ width: "100%" }}
                                type={"text"}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                multiline={true}
                                label={"Write down if there are any additional details"}
                            />
                        </div>
                    </div>
                    <div style={{ textAlign: "end", width: "100%", backgroundColor: "#F3F4F6", padding: "1rem", borderRadius: "0 0 12px 12px" }}>
                        <Button variant="contained" color="danger">
                            Cancel Booking
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default CancelModal
