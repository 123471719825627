import { useEffect, useMemo, useRef, useState } from "react"
import { useTable, usePagination, useAsyncDebounce, useGlobalFilter, useFilters, useSortBy } from "react-table"
import {
    Add,
    AppsOutlined,
    FormatListBulletedOutlined,
    ArrowRightAlt,
    ChevronLeft,
    ChevronRight,
    FilterAltOutlined,
    SortByAlpha,
    ColorLensOutlined,
    Image,
    FileUploadOutlined,
} from "@mui/icons-material"
import { Button, Text, Input, SearchBox, Modal, ImportExportHandler, Avatar } from "../components"
import { downloadWithData } from "../../utils/ExcelHandler"
import { PDFHandler } from "../../utils/PDFHandler"
import styles from "./Table.module.scss"
import CloseButton from "../Modal/CloseButton/CloseButton"
import GridLayout from "./components/GridLayout/GridLayout"
import loadingImage from "../../images/loading.gif"
import { inputAdornmentClasses, Switch, TextField } from "@mui/material"
import { PdfHeaders } from "../../utils/Validation"
import { axiosPrivate, buildHeaderObject } from "../../apis/axios"
import { toast } from "react-toastify"
import InputMask from "react-input-mask"
import { AdvancedInput } from "../AdvancedInput/AdvancedInput"
import { shortDate } from "../../utils/DateFormatter"
import { SketchPicker } from "react-color"
import AdvancedDropDown from "../AdvancedDropDown/AdvancedDropDown"
import Loading from "./components/Loading/Loading"
import BreedPrices from "../ExtModals/BreedPrices"
import { FileUploader } from "react-drag-drop-files"
import imageCompression from "browser-image-compression"
import Cropper from "react-cropper"
import "cropperjs/dist/cropper.css"
import useAuth from "../../hooks/useAuth"

const genName = (objectName) => {
    return objectName.replace(/([a-z])([A-Z])/g, "$1 $2")
}

const RenderTable = ({
    columns,
    data,
    activateGrid,
    tableName,
    hiddenColumns,
    filters,
    downloadPDF,
    newAction,
    loadingData,
    objectName,
    rowClicked,
    boldHeaders,
    pdfColumns,
    hideSort,
    postFields,
    saveSuccess,
    importExport,
    canEdit,
}) => {
    const [isGrid, setIsGrid] = useState(false)
    const [openImportExportModal, setOpenImportExportModal] = useState(false)
    const [openUploadImageModal, setOpenUploadImageModal] = useState(false)
    const [showFiltering, setShowFiltering] = useState(false)
    const [pageList, setPageList] = useState([10, 20, 30, 40, 50])
    const [searchVal, setSearchVal] = useState("")
    const [filteredRows, setFilteredRows] = useState([])
    const [openAddNewModal, setOpenAddNewModal] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [postDataSet, setPostDataSet] = useState<any>([])
    const [showColorPicker, setShowColorPicker] = useState(false)
    let exportData: any = []
    const [isOpenBreedPricesModal, setIsBreedPricesModal] = useState(false)
    const [breedPriceID, setBreedPriceID] = useState("")

    const [productImage, setProductImage] = useState("")
    const [productName, setProductName] = useState("")

    const { auth, setAuth } = useAuth() as any

    const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => {
        return (
            <input
                className={styles.searchFilter}
                value={filterValue || ""}
                onChange={(e) => {
                    setFilter(e.target.value || undefined)
                }}
                placeholder={`Search`}
            />
        )
    }
    useEffect(() => {
        setPostDataSet(postFields)
    }, [postFields])

    useEffect(() => {
        if (!showFiltering) {
            setAllFilters([])
        }
    }, [showFiltering])

    const defaultColumn = useMemo(
        () => ({
            Filter: DefaultColumnFilter,
        }),
        []
    )

    // IMAGE UPLOAD
    const fileTypes = ["JPEG", "jpg", "WEBP"]
    const [loading, setLoading] = useState(false)
    const [fileSelected, setFileSelected] = useState(false)
    const [importedData, setImportedData] = useState<any>(productImage)
    const [showCropper, setShowCropper] = useState(false)
    const cropperRef = useRef<HTMLImageElement>(null)
    const [cropper, setCropper] = useState<any>()
    const [imageName, setImageName] = useState<any>("")

    const readFile = (files) => {
        if (files.size > 1000000) {
            toast.error(files.name + " File too large")
        } else {
            setImportedData(files)
            setShowCropper(true)
            setFileSelected(true)
            setImageName(files.name)
        }
    }

    const blobToFile = (theBlob: Blob, fileName: string) => {
        var b: any = theBlob
        b.lastModifiedDate = new Date()
        b.name = fileName
        return theBlob
    }

    const getCropData = async () => {
        if (typeof cropper !== "undefined") {
            cropper
                .getCroppedCanvas({
                    imageSmoothingEnabled: false,
                })
                .toBlob((blob) => {
                    let toFile = blobToFile(blob, imageName)
                    let imageFile: any = toFile
                    let options = {
                        maxSizeMB: 0.5,
                        fileType: "image/png",
                        maxWidthOrHeight: 200,
                        useWebWorker: true,
                        onProgress: (progress) => {
                            setLoading(true)
                            if (progress == 100) {
                                setLoading(false)
                            }
                        },
                    }
                    imageCompression(imageFile, options)
                        .then(function (compressedFile) {
                            setImportedData(compressedFile)
                            setShowCropper(false)
                        })
                        .catch(function (error) {
                            console.log(error.message)
                        })
                })
        }
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        rows,
        previousPage,
        setPageSize,
        setFilter,
        state: { pageIndex, pageSize },
        setGlobalFilter,
        setAllFilters,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: {
                pageIndex: 0,
                hiddenColumns: hiddenColumns,
                pageSize: 10,
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    useEffect(() => {
        if (isGrid) {
            setPageSize(9)
            setPageList([9, 18, 27, 36, 45])
        } else {
            setPageSize(10)
            setPageList([10, 20, 30, 40, 50])
        }
    }, [isGrid])

    useEffect(() => {
        filterGlobalData(searchVal)
    }, [searchVal])

    useEffect(() => {
        filters?.map((filter) => {
            setFilter(filter?.column, filter?.value)
        })
    }, [filters])

    useEffect(() => {
        setFilteredRows(rows)
    }, [rows])

    const filterGlobalData = useAsyncDebounce((filter) => {
        setGlobalFilter(filter)
    }, 200)

    const appendForExport = () => {
        exportData = []
        let appended = new Promise((resolve, reject) => {
            filteredRows.map((row: any) => {
                let obj = {}
                Object.keys(row.original).map((header) => {
                    if (pdfColumns.includes(header.toString())) {
                        obj[header] = row.original[header]
                    }
                })

                exportData.push(obj)
                // exportData.push(row.original)
            })
            resolve(true)
        })

        appended
            .then((result) => {
                if (result) {
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const exportExcel = () => {
        appendForExport()
        downloadWithData(tableName, exportData)
    }

    const exportPDF = () => {
        appendForExport()
        PDFHandler(exportData, tableName)
    }

    const saveNewLibrary = () => {
        var Form: any = document.getElementById("editForm")

        if (Form.checkValidity() == false) {
            console.log("invalid")
        } else {
            let dataSet = {}
            //console.log("PDSET", postDataSet)
            const objectN = objectName.charAt(0).toLowerCase() + objectName.slice(1)
            postDataSet.map((item, index) => {
                if (item.inputType == "number") {
                    dataSet[item.fieldName] = parseFloat(item.value)
                } else if (item.inputType == "hidden") {
                    dataSet[item.fieldName] = 0
                } else {
                    dataSet[item.fieldName] = item.value
                    console.log("val", item.value)
                }

                if (item.type === "sub-boolean") {
                    dataSet[item.fieldName] = item.value
                    if (item.inputType === "number") {
                        if (item.value === null) {
                            dataSet[item.subFields.fieldName] = 0
                        } else if (item.subFields.value === "") {
                            dataSet[item.subFields.fieldName] = 0
                        } else {
                            dataSet[item.subFields.fieldName] = parseFloat(item.subFields.value)
                        }
                    } else {
                        dataSet[item.subFields.fieldName] = item.subFields.value
                    }
                }
            })
            let sentData = {
                [objectN]: dataSet,
            }
            saveData(sentData)
            console.log("sentData", sentData)
        }
    }

    async function saveData(data) {
        console.log("passedin", data)
        await axiosPrivate
            .post(`${objectName}`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                console.log("result", result)
                if (result.status == 200) {
                    if (result.data?.bitSuccess === false) {
                        toast.info(result.data?.varResponseMessage)
                    } else {
                        toast.success(`New ${objectName} successfully saved!`)
                        saveSuccess(true)
                        if (objectName === "Breed") {
                            setBreedPriceID(result.data?.numResponseID)
                            setIsBreedPricesModal(true)
                        }
                        setOpenAddNewModal(false)
                        setShowColorPicker(false)
                    }
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    async function updateData(data) {
        await axiosPrivate
            .put(`${objectName}`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                if (result.status == 200) {
                    toast.success(`Changes saved successfully!`)
                    saveSuccess(true)
                    setOpenEditModal(false)
                    setShowColorPicker(false)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const fetchData = (id) => {
        let dataIndex = rows.findIndex((x) => x.id === id)
        rows[dataIndex].allCells.map((item, index) => {
            postDataSet.map((PostItem, index) => {
                if (PostItem.fieldName === item.column.id) {
                    const newValue: any = [...postDataSet]
                    newValue[index].value = item.value
                    setPostDataSet(newValue)
                    if (PostItem.type === "date") {
                        if (PostItem.value !== null && PostItem.value !== undefined) {
                            const newValue: any = [...postDataSet]
                            newValue[index].value = item.value.split("T")[0]
                            setPostDataSet(newValue)
                        } else {
                            const newValue: any = [...postDataSet]
                            newValue[index].value = ""
                            setPostDataSet(newValue)
                        }
                    }
                } else if (PostItem.type === "sub-boolean") {
                    if (PostItem.subFields.fieldName === item.column.id) {
                        const newValue: any = [...postDataSet]
                        newValue[index].subFields.value = item.value
                        setPostDataSet(newValue)
                    }
                }
            })
        })
        setOpenEditModal(true)
    }

    const uploadProduct = () => {
        const formData = new FormData()
        formData.append("folderpath", `products`)
        formData.append("method", "N")
        formData.append("deletingFileName", "-")
        formData.append("renameFileNames", "0")

        const file = importedData
        const fileExtension = file.name.split(".").pop()
        const renamedFile = new File([file], `${productName}.png`, { type: "image/png" })
        formData.append("files", renamedFile)

        axiosPrivate
            .post(`FileUpload/UploadFile`, formData, buildHeaderObject(auth))
            .then((response) => {
                console.log(response)
                if (response.data.files.length > 0) {
                    console.log("Product Image Upload Complete!")
                    toast.success("Product Image Upload Complete!")
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const saveUpdate = () => {
        var Form: any = document.getElementById("editForm")

        if (Form.checkValidity() == false) {
            console.log("invalid")
        } else {
            let dataSet = {}
            postDataSet.map((item, index) => {
                if (item.inputType == "number") {
                    dataSet[item.fieldName] = parseFloat(item.value)
                } else {
                    dataSet[item.fieldName] = item.value
                }

                if (item.type === "sub-boolean") {
                    dataSet[item.fieldName] = item.value
                    if (item.inputType === "number") {
                        if (item.value === null) {
                            dataSet[item.subFields.fieldName] = 0
                        } else if (item.subFields.value === "") {
                            dataSet[item.subFields.fieldName] = 0
                        } else {
                            dataSet[item.subFields.fieldName] = parseFloat(item.subFields.value)
                        }
                    } else {
                        dataSet[item.subFields.fieldName] = item.subFields.value
                    }
                }
            })

            updateData(dataSet)
        }
    }
    const addNewLibrary = () => {
        postDataSet.map((PostItem, index) => {
            if (PostItem.type !== "bit" && PostItem.type !== "sub-boolean" && PostItem.type !== "boolean") {
                const newValue: any = [...postDataSet]
                newValue[index].value = null
                setPostDataSet(newValue)
            }
        })
        setOpenAddNewModal(true)
    }

    const LoadPriceList = (id) => {
        let dataIndex = rows.findIndex((x) => x.id === id)
        console.log(rows[dataIndex]?.allCells[5].value)
        setIsBreedPricesModal(true)
        setBreedPriceID(rows[dataIndex]?.allCells[5].value)
    }

    return loadingData ? (
        <Loading isGrid={isGrid} activateGrid={activateGrid} downloadPDF={downloadPDF} headerGroups={headerGroups} hideSort={hideSort} newAction={newAction} objectName={objectName} rowHeight={50} />
    ) : (
        // <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        //     <img alt="loading" src={loadingImage} />
        // </div>
        <>
            <div className={styles.headerContainer}>
                <div className={styles.actionContainer}>
                    <div className={styles.optionsContainer}>
                        {newAction ? (
                            <Button
                                color="default"
                                variant="outlined"
                                onClick={() => {
                                    addNewLibrary()
                                }}
                            >
                                <Add style={{ fontSize: "20px" }} />
                                Add New {genName(objectName)}
                            </Button>
                        ) : null}

                        {activateGrid ? (
                            <div className={styles.toggleContainer}>
                                <Button
                                    color="primary"
                                    iconLeft={<AppsOutlined className={styles.buttonIcon} />}
                                    variant="outlined"
                                    onClick={() => setIsGrid(true)}
                                    className={isGrid ? styles.btnActive : styles.btnToggle}
                                >
                                    Grid
                                </Button>

                                <Button
                                    iconLeft={<FormatListBulletedOutlined className={styles.buttonIcon} />}
                                    variant="outlined"
                                    onClick={() => setIsGrid(false)}
                                    className={!isGrid ? styles.btnActive : styles.btnToggle}
                                    color="primary"
                                >
                                    List
                                </Button>
                            </div>
                        ) : null}
                        {importExport ? (
                            <Button
                                color="primary"
                                variant="contained"
                                // onClick={() => exportExcel()}
                                onClick={() => setOpenImportExportModal(!openImportExportModal)}
                            >
                                Import/Export
                            </Button>
                        ) : null}

                        <BreedPrices isOpenBreedPricesModal={isOpenBreedPricesModal} setIsBreedPricesModal={setIsBreedPricesModal} breedPriceID={breedPriceID} />

                        <Modal open={openImportExportModal}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem", flexDirection: "column" }}>
                                <CloseButton onClick={() => setOpenImportExportModal(!openImportExportModal)} />
                                <div style={{ backgroundColor: "#fff", width: "100%" }}>
                                    <ImportExportHandler objectName={objectName} requestType="Library" />
                                </div>
                            </div>
                        </Modal>
                        <Modal open={openAddNewModal}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem", flexDirection: "column" }}>
                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <h1 style={{ width: "100%" }}>Add New {genName(objectName)}</h1>
                                    <CloseButton onClick={() => setOpenAddNewModal(!openAddNewModal)} />
                                </div>
                                <div style={{ backgroundColor: "#fff", width: "100%", maxWidth: "600px" }}>
                                    <form id="editForm" onSubmit={(e) => e.preventDefault()}>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "20px" }}>
                                            <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
                                                {postDataSet !== undefined
                                                    ? postDataSet.map((item, index) => {
                                                          if (item.type === "text") {
                                                              return (
                                                                  <TextField
                                                                      style={{ width: "100%", marginBottom: "1.5rem" }}
                                                                      label={
                                                                          <div style={{ display: "flex", fontSize: "14px" }}>
                                                                              {PdfHeaders(item.fieldName)}
                                                                              {item.required ? (
                                                                                  <span
                                                                                      style={{
                                                                                          fontWeight: 500,
                                                                                          backgroundColor: "#FDEDED",
                                                                                          color: "#EF4444",
                                                                                          fontSize: "12px",
                                                                                          padding: "0 5px",
                                                                                          marginLeft: "5px",
                                                                                      }}
                                                                                  >
                                                                                      Required
                                                                                  </span>
                                                                              ) : null}
                                                                          </div>
                                                                      }
                                                                      required={item.required}
                                                                      onChange={(e) => {
                                                                          const newValue: any = [...postDataSet]
                                                                          newValue[index].value = e.target.value
                                                                          setPostDataSet(newValue)
                                                                      }}
                                                                      type={item.inputType}
                                                                      variant="filled"
                                                                      value={postDataSet[index].value}
                                                                  />
                                                              )
                                                          }
                                                          if (item.type === "text-area") {
                                                              return (
                                                                  <TextField
                                                                      style={{ width: "100%", marginBottom: "1.5rem" }}
                                                                      label={
                                                                          <div style={{ display: "flex", fontSize: "14px" }}>
                                                                              {PdfHeaders(item.fieldName)}
                                                                              {item.required ? (
                                                                                  <span
                                                                                      style={{
                                                                                          fontWeight: 500,
                                                                                          backgroundColor: "#FDEDED",
                                                                                          color: "#EF4444",
                                                                                          fontSize: "12px",
                                                                                          padding: "0 5px",
                                                                                          marginLeft: "5px",
                                                                                      }}
                                                                                  >
                                                                                      Required
                                                                                  </span>
                                                                              ) : null}
                                                                          </div>
                                                                      }
                                                                      required={item.required}
                                                                      onChange={(e) => {
                                                                          const newValue: any = [...postDataSet]
                                                                          newValue[index].value = e.target.value
                                                                          setPostDataSet(newValue)
                                                                      }}
                                                                      type={item.inputType}
                                                                      variant="filled"
                                                                      multiline={true}
                                                                      value={postDataSet[index].value}
                                                                  />
                                                              )
                                                          }
                                                          if (item.type === "boolean") {
                                                              return (
                                                                  <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                                                      <p style={{ fontWeight: "500" }}>{PdfHeaders(item.fieldName)}</p>
                                                                      <Switch
                                                                          onChange={(e) => {
                                                                              const newValue = [...postDataSet]
                                                                              newValue[index].value = !newValue[index].value
                                                                              setPostDataSet(newValue)
                                                                          }}
                                                                          checked={postDataSet[index].value}
                                                                          inputProps={{ "aria-label": "controlled" }}
                                                                      />
                                                                  </div>
                                                              )
                                                          }
                                                          if (item.type === "tel") {
                                                              return (
                                                                  <div>
                                                                      <InputMask
                                                                          alwaysShowMask="true"
                                                                          mask="\(999\)999\-9999"
                                                                          maskChar="_"
                                                                          value={postDataSet[index].value}
                                                                          onChange={(e) => {
                                                                              const newValue: any = [...postDataSet]
                                                                              newValue[index].value = e.target.value
                                                                              setPostDataSet(newValue)
                                                                          }}
                                                                      >
                                                                          {(inputProps) => (
                                                                              <TextField
                                                                                  inputProps={{
                                                                                      pattern: item.required
                                                                                          ? "^[0-9()-]+$"
                                                                                          : postDataSet[index].value === "" || postDataSet[index].value === "(___)___-____"
                                                                                          ? null
                                                                                          : "^[0-9()-]+$",
                                                                                  }}
                                                                                  {...inputProps}
                                                                                  type="tel"
                                                                                  required={item.required}
                                                                                  style={{ width: "100%" }}
                                                                                  label={
                                                                                      <div style={{ display: "flex", fontSize: "14px" }}>
                                                                                          {PdfHeaders(item.fieldName)}
                                                                                          {item.required ? (
                                                                                              <span
                                                                                                  style={{
                                                                                                      fontWeight: 500,
                                                                                                      backgroundColor: "#FDEDED",
                                                                                                      color: "#EF4444",
                                                                                                      fontSize: "12px",
                                                                                                      padding: "0 5px",
                                                                                                      marginLeft: "5px",
                                                                                                  }}
                                                                                              >
                                                                                                  Required
                                                                                              </span>
                                                                                          ) : null}
                                                                                      </div>
                                                                                  }
                                                                                  variant="standard"
                                                                              />
                                                                          )}
                                                                      </InputMask>
                                                                  </div>
                                                              )
                                                          }
                                                          if (item.type === "advanced") {
                                                              return (
                                                                  <div style={{ marginBottom: "20px" }}>
                                                                      <AdvancedInput
                                                                          required={item.required}
                                                                          isButton={false}
                                                                          label={PdfHeaders(item.altName)}
                                                                          onChange={(e) => {
                                                                              const newValue: any = [...postDataSet]
                                                                              newValue[index].value = e
                                                                              setPostDataSet(newValue)
                                                                          }}
                                                                          ObjectName={item.objectName}
                                                                          isCustom={false}
                                                                          Sequence={""}
                                                                          value={postDataSet[index].value}
                                                                          idCustomFields={item.isCustomFields}
                                                                          customID={item.customFieldID}
                                                                          customName={item.customFieldName}
                                                                      />
                                                                  </div>
                                                              )
                                                          }
                                                          if (item.type === "sub-boolean") {
                                                              return (
                                                                  <div style={{ display: "flex", flexDirection: "column" }}>
                                                                      <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                                                          <p style={{ fontWeight: "500" }}>{PdfHeaders(item.fieldName)}</p>
                                                                          <Switch
                                                                              onChange={(e) => {
                                                                                  const newValue = [...postDataSet]
                                                                                  newValue[index].value = !newValue[index].value
                                                                                  setPostDataSet(newValue)
                                                                                  if (newValue[index].value === false) {
                                                                                      const newValue: any = [...postDataSet]
                                                                                      newValue[index].subFields.value = ""
                                                                                      setPostDataSet(newValue)
                                                                                  }
                                                                              }}
                                                                              checked={postDataSet[index].value}
                                                                              inputProps={{ "aria-label": "controlled" }}
                                                                          />
                                                                      </div>
                                                                      <TextField
                                                                          style={{ width: "100%", marginBottom: "1.5rem" }}
                                                                          label={
                                                                              <div style={{ display: "flex", fontSize: "14px" }}>
                                                                                  {PdfHeaders(item.subFields.fieldName)}
                                                                                  {postDataSet[index].value ? (
                                                                                      <span
                                                                                          style={{
                                                                                              fontWeight: 500,
                                                                                              backgroundColor: "#FDEDED",
                                                                                              color: "#EF4444",
                                                                                              fontSize: "12px",
                                                                                              padding: "0 5px",
                                                                                              marginLeft: "5px",
                                                                                          }}
                                                                                      >
                                                                                          Required
                                                                                      </span>
                                                                                  ) : null}
                                                                              </div>
                                                                          }
                                                                          required={postDataSet[index].value}
                                                                          onChange={(e) => {
                                                                              const newValue: any = [...postDataSet]
                                                                              newValue[index].subFields.value = e.target.value
                                                                              setPostDataSet(newValue)
                                                                          }}
                                                                          type={item.subFields.inputType}
                                                                          variant="filled"
                                                                          value={postDataSet[index].subFields.value}
                                                                      />
                                                                  </div>
                                                              )
                                                          }
                                                          if (item.type === "date") {
                                                              return (
                                                                  <div style={{ marginBottom: "20px" }}>
                                                                      <TextField
                                                                          style={{ width: "100%" }}
                                                                          label={
                                                                              <div style={{ display: "flex", fontSize: "14px" }}>
                                                                                  {PdfHeaders(item.fieldName)}
                                                                                  {item.required ? (
                                                                                      <span
                                                                                          style={{
                                                                                              fontWeight: 500,
                                                                                              backgroundColor: "#FDEDED",
                                                                                              color: "#EF4444",
                                                                                              fontSize: "12px",
                                                                                              padding: "0 5px",
                                                                                              marginLeft: "5px",
                                                                                          }}
                                                                                      >
                                                                                          Required
                                                                                      </span>
                                                                                  ) : null}
                                                                              </div>
                                                                          }
                                                                          required={item.required}
                                                                          onChange={(e) => {
                                                                              const newValue: any = [...postDataSet]
                                                                              newValue[index].value = e.target.value
                                                                              setPostDataSet(newValue)
                                                                          }}
                                                                          type={item.inputType}
                                                                          focused={true}
                                                                          variant="filled"
                                                                          value={postDataSet[index].value}
                                                                      />
                                                                  </div>
                                                              )
                                                          }
                                                          if (item.type === "colorPicker") {
                                                              return (
                                                                  <>
                                                                      <div style={{ width: "100%" }}>
                                                                          <div style={{ display: "flex", gap: "1rem" }}>
                                                                              <Button
                                                                                  variant="outlined"
                                                                                  color="transparent"
                                                                                  onClick={() => {
                                                                                      setShowColorPicker(!showColorPicker)
                                                                                  }}
                                                                              >
                                                                                  <ColorLensOutlined />
                                                                                  &nbsp;Pick a color
                                                                              </Button>
                                                                              <div style={{ backgroundColor: `${postDataSet[index].value}`, width: "100px", borderRadius: "50px" }}></div>
                                                                          </div>
                                                                      </div>
                                                                      {showColorPicker ? (
                                                                          <div style={{ position: "absolute", zIndex: "9999", top: "66%", right: "52%" }}>
                                                                              {" "}
                                                                              <SketchPicker
                                                                                  color={"#FFFFFF"}
                                                                                  onChange={(e) => {
                                                                                      const newValue: any = [...postDataSet]
                                                                                      newValue[index].value = e.hex
                                                                                      setPostDataSet(newValue)
                                                                                  }}
                                                                              />
                                                                          </div>
                                                                      ) : null}
                                                                  </>
                                                              )
                                                          }
                                                          if (item.type === "customDropdown") {
                                                              console.log(item.type)
                                                              return (
                                                                  <>
                                                                      <AdvancedDropDown
                                                                          data={[
                                                                              { label: "PURE", value: "PURE" },
                                                                              { label: "MIX", value: "MIX" },
                                                                          ]}
                                                                          placeHolder={"Type"}
                                                                          value={postDataSet[index].value}
                                                                          passByID={true}
                                                                          onChange={(e) => {
                                                                              const newValue: any = [...postDataSet]
                                                                              newValue[index].value = e.value
                                                                              setPostDataSet(newValue)
                                                                          }}
                                                                      />
                                                                  </>
                                                              )
                                                          }
                                                      })
                                                    : ""}
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Button color="primary" variant="contained" onClick={() => saveNewLibrary()} type={"submit"}>
                                                    Save New {genName(objectName)}
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Modal>

                        <Modal open={openUploadImageModal}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem", flexDirection: "column" }}>
                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <h1 style={{ width: "100%" }}>Upload Image</h1>
                                    <CloseButton onClick={() => setOpenUploadImageModal(!openUploadImageModal)} />
                                </div>
                                <div style={{ backgroundColor: "#fff", width: "100%", maxWidth: "600px" }}>
                                    <form id="editForm" onSubmit={(e) => e.preventDefault()}>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "20px" }}>
                                            <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
                                                <div style={{ backgroundColor: "#F9FAFB" }}>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", color: "#F9FAFB", padding: "16px 22px 16px 22px" }}>
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "column" }}>
                                                            <div>
                                                                <Avatar
                                                                    shape={"rounded"}
                                                                    img={fileSelected ? URL.createObjectURL(importedData) : `${process.env.REACT_APP_MEDIA_URL}directories/locations/${importedData}`}
                                                                    isVip={false}
                                                                    className={"large"}
                                                                    labelType={"largeLabel"}
                                                                />
                                                            </div>
                                                            {productImage != importedData ? (
                                                                <p
                                                                    style={{ color: "#005DF1", fontSize: "14px", fontWeight: "500", textAlign: "center", cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        {
                                                                            setImportedData(productImage)
                                                                        }
                                                                        {
                                                                            setFileSelected(false)
                                                                        }
                                                                    }}
                                                                >
                                                                    Reset photo
                                                                </p>
                                                            ) : null}
                                                        </div>
                                                        <div style={{ border: "2px dashed #5f9cfe", borderRadius: "8px", padding: "2rem", backgroundColor: "#fff" }}>
                                                            <FileUploader
                                                                handleChange={readFile}
                                                                onDrop={readFile}
                                                                disabled={loading ? true : false}
                                                                name="file"
                                                                types={fileTypes}
                                                                multiple={false}
                                                                label=""
                                                                children={
                                                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                                                        <FileUploadOutlined style={{ color: "#2076FF" }} />
                                                                        <p className={styles.uploadText}>Drag and drop or browse to choose a image</p>
                                                                    </div>
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <Modal open={showCropper}>
                                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                                                        <div style={{ textAlign: "end", width: "100%" }}>
                                                            <CloseButton
                                                                onClick={() => {
                                                                    {
                                                                        setShowCropper(false)
                                                                    }
                                                                    {
                                                                        setFileSelected(false)
                                                                    }
                                                                    setImportedData(productImage)
                                                                }}
                                                            />
                                                        </div>

                                                        <div style={{ backgroundColor: "#fff" }}>
                                                            <Cropper
                                                                style={{ maxWidth: "500px", maxHeight: "500px" }}
                                                                modal={true}
                                                                src={fileSelected ? URL.createObjectURL(importedData) : ""}
                                                                cropBoxResizable={true}
                                                                initialAspectRatio={1}
                                                                aspectRatio={1}
                                                                guides={true}
                                                                minCropBoxHeight={200}
                                                                scalable={false}
                                                                minCropBoxWidth={200}
                                                                highlight={true}
                                                                zoomable={true}
                                                                movable={true}
                                                                onInitialized={(instance) => {
                                                                    setCropper(instance)
                                                                }}
                                                                ref={cropperRef}
                                                            />
                                                        </div>
                                                        <div style={{ textAlign: "end", width: "100%" }}>
                                                            <Button variant="contained" color="primary" onClick={() => getCropData()}>
                                                                Crop & Fix
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Modal>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Button color="primary" variant="contained" onClick={() => uploadProduct()} type={"submit"}>
                                                    Upload Image
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Modal>

                        <Modal open={openEditModal}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem", flexDirection: "column" }}>
                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <h1 style={{ width: "100%" }}>Edit {genName(objectName)}</h1>
                                    <CloseButton onClick={() => setOpenEditModal(!openEditModal)} />
                                </div>
                                <div style={{ backgroundColor: "#fff", width: "100%", maxWidth: "600px" }}>
                                    <form id="editForm" onSubmit={(e) => e.preventDefault()}>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "20px" }}>
                                            <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
                                                {postDataSet !== undefined
                                                    ? postDataSet.map((item, index) => {
                                                          if (item.type === "text") {
                                                              return (
                                                                  <TextField
                                                                      style={{ width: "100%", marginBottom: "1.5rem" }}
                                                                      label={
                                                                          <div style={{ display: "flex", fontSize: "14px" }}>
                                                                              {PdfHeaders(item.fieldName)}
                                                                              {item.required ? (
                                                                                  <span
                                                                                      style={{
                                                                                          fontWeight: 500,
                                                                                          backgroundColor: "#FDEDED",
                                                                                          color: "#EF4444",
                                                                                          fontSize: "12px",
                                                                                          padding: "0 5px",
                                                                                          marginLeft: "5px",
                                                                                      }}
                                                                                  >
                                                                                      Required
                                                                                  </span>
                                                                              ) : null}
                                                                          </div>
                                                                      }
                                                                      required={item.required}
                                                                      onChange={(e) => {
                                                                          const newValue: any = [...postDataSet]
                                                                          newValue[index].value = e.target.value
                                                                          setPostDataSet(newValue)
                                                                      }}
                                                                      type={item.inputType}
                                                                      variant="filled"
                                                                      value={postDataSet[index].value}
                                                                  />
                                                              )
                                                          }
                                                          if (item.type === "text-area") {
                                                              return (
                                                                  <TextField
                                                                      style={{ width: "100%", marginBottom: "1.5rem" }}
                                                                      label={
                                                                          <div style={{ display: "flex", fontSize: "14px" }}>
                                                                              {PdfHeaders(item.fieldName)}
                                                                              {item.required ? (
                                                                                  <span
                                                                                      style={{
                                                                                          fontWeight: 500,
                                                                                          backgroundColor: "#FDEDED",
                                                                                          color: "#EF4444",
                                                                                          fontSize: "12px",
                                                                                          padding: "0 5px",
                                                                                          marginLeft: "5px",
                                                                                      }}
                                                                                  >
                                                                                      Required
                                                                                  </span>
                                                                              ) : null}
                                                                          </div>
                                                                      }
                                                                      required={item.required}
                                                                      onChange={(e) => {
                                                                          const newValue: any = [...postDataSet]
                                                                          newValue[index].value = e.target.value
                                                                          setPostDataSet(newValue)
                                                                      }}
                                                                      type={item.inputType}
                                                                      variant="filled"
                                                                      multiline={true}
                                                                      value={postDataSet[index].value}
                                                                  />
                                                              )
                                                          }
                                                          if (item.type === "boolean") {
                                                              return (
                                                                  <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                                                      <p style={{ fontWeight: "500" }}>{PdfHeaders(item.fieldName)}</p>
                                                                      <Switch
                                                                          onChange={(e) => {
                                                                              const newValue = [...postDataSet]
                                                                              newValue[index].value = !newValue[index].value
                                                                              setPostDataSet(newValue)
                                                                          }}
                                                                          checked={postDataSet[index].value}
                                                                          inputProps={{ "aria-label": "controlled" }}
                                                                      />
                                                                  </div>
                                                              )
                                                          }
                                                          if (item.type === "tel") {
                                                              return (
                                                                  <div>
                                                                      <InputMask
                                                                          alwaysShowMask="true"
                                                                          mask="\(999\)999\-9999"
                                                                          maskChar="_"
                                                                          value={postDataSet[index].value}
                                                                          onChange={(e) => {
                                                                              const newValue: any = [...postDataSet]
                                                                              newValue[index].value = e.target.value
                                                                              setPostDataSet(newValue)
                                                                          }}
                                                                      >
                                                                          {(inputProps) => (
                                                                              <TextField
                                                                                  inputProps={{
                                                                                      pattern: item.required
                                                                                          ? "^[0-9()-]+$"
                                                                                          : postDataSet[index].value === "" || postDataSet[index].value === "(___)___-____"
                                                                                          ? null
                                                                                          : "^[0-9()-]+$",
                                                                                  }}
                                                                                  {...inputProps}
                                                                                  type="tel"
                                                                                  required={item.required}
                                                                                  style={{ width: "100%" }}
                                                                                  label={
                                                                                      <div style={{ display: "flex", fontSize: "14px" }}>
                                                                                          {PdfHeaders(item.fieldName)}
                                                                                          {item.required ? (
                                                                                              <span
                                                                                                  style={{
                                                                                                      fontWeight: 500,
                                                                                                      backgroundColor: "#FDEDED",
                                                                                                      color: "#EF4444",
                                                                                                      fontSize: "12px",
                                                                                                      padding: "0 5px",
                                                                                                      marginLeft: "5px",
                                                                                                  }}
                                                                                              >
                                                                                                  Required
                                                                                              </span>
                                                                                          ) : null}
                                                                                      </div>
                                                                                  }
                                                                                  variant="standard"
                                                                              />
                                                                          )}
                                                                      </InputMask>
                                                                  </div>
                                                              )
                                                          }
                                                          if (item.type === "advanced") {
                                                              return (
                                                                  <div style={{ marginBottom: "20px" }}>
                                                                      <AdvancedInput
                                                                          required={item.required}
                                                                          isButton={false}
                                                                          label={PdfHeaders(item.altName)}
                                                                          onChange={(e) => {
                                                                              const newValue: any = [...postDataSet]
                                                                              newValue[index].value = e
                                                                              setPostDataSet(newValue)
                                                                          }}
                                                                          ObjectName={item.objectName}
                                                                          isCustom={false}
                                                                          Sequence={""}
                                                                          value={postDataSet[index].value}
                                                                          selectedValue={postDataSet[index].value}
                                                                          idCustomFields={item.isCustomFields}
                                                                          customID={item.customFieldID}
                                                                          customName={item.customFieldName}
                                                                      />
                                                                  </div>
                                                              )
                                                          }
                                                          if (item.type === "sub-boolean") {
                                                              return (
                                                                  <div style={{ display: "flex", flexDirection: "column" }}>
                                                                      <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                                                          <p style={{ fontWeight: "500" }}>{PdfHeaders(item.fieldName)}</p>
                                                                          <Switch
                                                                              onChange={(e) => {
                                                                                  const newValue = [...postDataSet]
                                                                                  newValue[index].value = !newValue[index].value
                                                                                  setPostDataSet(newValue)
                                                                                  if (newValue[index].value === false) {
                                                                                      const newValue: any = [...postDataSet]
                                                                                      newValue[index].subFields.value = ""
                                                                                      setPostDataSet(newValue)
                                                                                  }
                                                                              }}
                                                                              checked={postDataSet[index].value}
                                                                              inputProps={{ "aria-label": "controlled" }}
                                                                          />
                                                                      </div>
                                                                      <TextField
                                                                          style={{ width: "100%", marginBottom: "1.5rem" }}
                                                                          label={
                                                                              <div style={{ display: "flex", fontSize: "14px" }}>
                                                                                  {PdfHeaders(item.subFields.fieldName)}
                                                                                  {postDataSet[index].value ? (
                                                                                      <span
                                                                                          style={{
                                                                                              fontWeight: 500,
                                                                                              backgroundColor: "#FDEDED",
                                                                                              color: "#EF4444",
                                                                                              fontSize: "12px",
                                                                                              padding: "0 5px",
                                                                                              marginLeft: "5px",
                                                                                          }}
                                                                                      >
                                                                                          Required
                                                                                      </span>
                                                                                  ) : null}
                                                                              </div>
                                                                          }
                                                                          required={postDataSet[index].value}
                                                                          onChange={(e) => {
                                                                              const newValue: any = [...postDataSet]
                                                                              newValue[index].subFields.value = e.target.value
                                                                              setPostDataSet(newValue)
                                                                          }}
                                                                          type={item.subFields.inputType}
                                                                          variant="filled"
                                                                          value={postDataSet[index].subFields.value}
                                                                      />
                                                                  </div>
                                                              )
                                                          }
                                                          if (item.type === "date") {
                                                              return (
                                                                  <div style={{ marginBottom: "20px" }}>
                                                                      <TextField
                                                                          style={{ width: "100%" }}
                                                                          label={
                                                                              <div style={{ display: "flex", fontSize: "14px" }}>
                                                                                  {PdfHeaders(item.fieldName)}
                                                                                  {item.required ? (
                                                                                      <span
                                                                                          style={{
                                                                                              fontWeight: 500,
                                                                                              backgroundColor: "#FDEDED",
                                                                                              color: "#EF4444",
                                                                                              fontSize: "12px",
                                                                                              padding: "0 5px",
                                                                                              marginLeft: "5px",
                                                                                          }}
                                                                                      >
                                                                                          Required
                                                                                      </span>
                                                                                  ) : null}
                                                                              </div>
                                                                          }
                                                                          required={item.required}
                                                                          onChange={(e) => {
                                                                              const newValue: any = [...postDataSet]
                                                                              newValue[index].value = e.target.value
                                                                              setPostDataSet(newValue)
                                                                          }}
                                                                          type={item.inputType}
                                                                          focused={true}
                                                                          variant="filled"
                                                                          value={postDataSet[index].value}
                                                                      />
                                                                  </div>
                                                              )
                                                          }
                                                          if (item.type === "colorPicker") {
                                                              return (
                                                                  <>
                                                                      <div style={{ width: "100%" }}>
                                                                          <div style={{ display: "flex", gap: "1rem" }}>
                                                                              <Button
                                                                                  variant="outlined"
                                                                                  color="transparent"
                                                                                  onClick={() => {
                                                                                      setShowColorPicker(!showColorPicker)
                                                                                  }}
                                                                              >
                                                                                  <ColorLensOutlined />
                                                                                  &nbsp;Pick a color
                                                                              </Button>
                                                                              <div style={{ backgroundColor: `${postDataSet[index].value}`, width: "100px", borderRadius: "50px" }}></div>
                                                                          </div>
                                                                      </div>
                                                                      {showColorPicker ? (
                                                                          <div style={{ position: "absolute", zIndex: "9999", top: "66%", right: "52%" }}>
                                                                              {" "}
                                                                              <SketchPicker
                                                                                  color={"#FFFFFF"}
                                                                                  onChange={(e) => {
                                                                                      const newValue: any = [...postDataSet]
                                                                                      newValue[index].value = e.hex
                                                                                      setPostDataSet(newValue)
                                                                                  }}
                                                                              />
                                                                          </div>
                                                                      ) : null}
                                                                  </>
                                                              )
                                                          }
                                                          if (item.type === "customDropdown") {
                                                              console.log(item.type)
                                                              return (
                                                                  <>
                                                                      <AdvancedDropDown
                                                                          data={[
                                                                              { label: "PURE", value: "PURE" },
                                                                              { label: "MIX", value: "MIX" },
                                                                          ]}
                                                                          placeHolder={"Type"}
                                                                          value={postDataSet[index].value}
                                                                          passByID={true}
                                                                          onChange={(e) => {
                                                                              const newValue: any = [...postDataSet]
                                                                              newValue[index].value = e.value
                                                                              setPostDataSet(newValue)
                                                                          }}
                                                                      />
                                                                  </>
                                                              )
                                                          }
                                                      })
                                                    : ""}
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Button color="primary" variant="contained" onClick={() => saveUpdate()} type={"submit"}>
                                                    Save Changes
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Modal>

                        {downloadPDF ? (
                            <Button color="default" variant="outlined" onClick={() => exportPDF()}>
                                Download PDF
                            </Button>
                        ) : null}
                    </div>

                    <div className={styles.filtersContainer}>
                        <div>
                            <Text color="n_500" weight="fw_400">
                                {`${rows.length} ${rows.length > 1 ? "results" : "result"}`} found
                            </Text>
                        </div>
                        <div className={styles.searchContainer}>
                            <SearchBox onChange={(e) => setSearchVal(e)} value={searchVal} />
                            {isGrid ? null : (
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                        setShowFiltering(!showFiltering)
                                    }}
                                >
                                    <FilterAltOutlined />
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {isGrid ? (
                <GridLayout onSelect={(v) => console.log("")} template={tableName} page={page} prepareRow={prepareRow} />
            ) : (
                <table className={`${styles.table} ${hideSort ? styles.hideSort : null}`} {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, index) => (
                                    <th className={`${boldHeaders ? styles.boldHeader : null}`}>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                {column.render("Header")}
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <SortByAlpha {...column.getHeaderProps(column.getSortByToggleProps())} style={{ height: "16px", cursor: "pointer", color: "#005df1" }} />
                                                    {column.isSorted ? column.isSortedDesc ? <ArrowRightAlt className={styles.sortDesc} /> : <ArrowRightAlt className={styles.sortAsc} /> : ""}
                                                </div>
                                            </div>
                                        </div>
                                        {showFiltering ? <div style={{ padding: ".5rem 0" }}>{column.canFilter ? column.render("Filter") : null}</div> : null}
                                    </th>
                                ))}
                            </tr>
                        ))}
                        {tableName === "5.1 - Sales Items Library" ? <th></th> : null}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)

                            return (
                                <tr onClick={() => {}} className={styles.tableRow} {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td className={styles.tableData} {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </td>
                                        )
                                    })}
                                    {objectName === "Breed" ? (
                                        <td>
                                            <Button
                                                color="success"
                                                variant="contained"
                                                onClick={() => {
                                                    LoadPriceList(row?.allCells[row?.allCells.length - 1].row.id)
                                                }}
                                                type={"submit"}
                                            >
                                                Price List
                                            </Button>
                                        </td>
                                    ) : null}
                                    {canEdit ? (
                                        <td className={styles.tableData}>
                                            <Button
                                                color="transparent"
                                                variant="outlined"
                                                onClick={() => {
                                                    fetchData(row?.allCells[row?.allCells.length - 1].row.id)
                                                }}
                                                type={"submit"}
                                            >
                                                Edit
                                            </Button>
                                        </td>
                                    ) : null}
                                    {tableName === "5.1 - Sales Items Library" ? (
                                        <td className={styles.tableData}>
                                            <Button
                                                color="transparent"
                                                variant="outlined"
                                                onClick={() => {
                                                    setProductName(row?.allCells[1]?.value)
                                                    setOpenUploadImageModal(true)
                                                }}
                                                type={"submit"}
                                            >
                                                <Image />
                                            </Button>
                                        </td>
                                    ) : null}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            )}

            <div className={styles.pagination}>
                <div className={styles.rowPages}>
                    <Text color="n_500" weight="fw_400">
                        Rows per page:
                    </Text>
                    <Input
                        items={pageList.map((pageSize) => ({
                            value: pageSize.toString(),
                            text: pageSize,
                        }))}
                        itemSize={12}
                        value={pageSize}
                        onChange={(pageRows) => {
                            setPageSize(Number(pageRows))
                        }}
                    />
                </div>

                <span>
                    <Text color="n_500" weight="fw_400">
                        Viewing Page: {pageIndex + 1} of {pageOptions.length}
                    </Text>
                </span>

                <div style={{ display: "flex", gap: ".5rem" }}>
                    <Button onClick={() => previousPage()} disabled={!canPreviousPage} className={styles.paginationButton} iconLeft={<ChevronLeft />} color="pagination" variant="contained"></Button>

                    <Button onClick={() => nextPage()} disabled={!canNextPage} className={styles.paginationButton} iconLeft={<ChevronRight />} color="pagination" variant="contained"></Button>
                </div>
            </div>
        </>
    )
}

export const Table = (props: {
    tableData
    activateGrid: boolean
    canEdit: boolean
    downloadPDF: boolean
    importExport: boolean
    template
    customFilters?
    newAction?
    loadingData?: boolean
    objectName: string
    rowClicked?: CallableFunction
    boldHeaders?
    saveSuccess?: CallableFunction
}) => {
    const data = useMemo(() => props.tableData, [props.tableData])

    console.log("table data", data)
    console.log("table template", data)

    return (
        <div style={{ width: "100%", overflowX: "scroll", margin: "1rem 0" }}>
            <RenderTable
                downloadPDF={props.downloadPDF}
                filters={props.customFilters}
                tableName={props.template.tableName}
                hiddenColumns={props.template.hiddenColumns}
                activateGrid={props.activateGrid}
                columns={props.template.template}
                data={data}
                loadingData={props?.loadingData}
                newAction={props.newAction}
                objectName={props?.objectName}
                rowClicked={props?.rowClicked}
                boldHeaders={props?.boldHeaders}
                pdfColumns={props.template.pdfTemplate}
                hideSort={props?.template.hideSort}
                postFields={props.template.postFields}
                saveSuccess={props?.saveSuccess}
                importExport={props?.importExport}
                canEdit={props?.canEdit}
            />
        </div>
    )
}
