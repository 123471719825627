import { useState } from "react"
import { FileUploadOutlined } from "@mui/icons-material"
import { FileUploader } from "react-drag-drop-files"
import { Button } from "../../components/components"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import * as XLSX from "xlsx"

import styles from "./FileUpload.module.scss"
import { axiosPrivate, buildHeaderObject } from "../../apis/axios"
import useAuth from "../../hooks/useAuth"

const FileUpload = (props: { text: string; objectType: string; type: string; requestType?: string; isSuccess? }) => {
    const { auth } = useAuth() as any

    const [importedData, setImportedData] = useState<any>([])
    const [uploading, setUploading] = useState(false)

    const [uploadReady, setUploadReady] = useState({})

    const [file, setFile] = useState(null)

    const fileTypes = ["xlsx", "xls"]

    async function getHeaders(objectName) {
        if (props?.requestType === "Library") {
            const result = await axiosPrivate.get(`Library/${objectName}/GetHeaders`, buildHeaderObject(auth))
            return result.data
        } else {
            const result = await axiosPrivate.get(`${objectName}/GetHeaders`, buildHeaderObject(auth))
            return result.data
        }
    }

    const uploadData = async () => {
        setUploading(true)

        const newUploadReady = uploadReady

        let finalObjectType
        let finalObjectTypeLink

        if (props?.requestType === "Library") {
            finalObjectType = [props?.objectType.charAt(0).toLowerCase() + props?.objectType.slice(1)]
            finalObjectTypeLink = `Library/${props.objectType}/ImportAll/`
        } else if (props?.requestType === "Custom") {
            finalObjectType = props?.objectType.charAt(0).toLowerCase() + props?.objectType.slice(1)
            finalObjectTypeLink = `${props.objectType}/ImportAll?numUserID=${auth?.employee?.numEmployeeID}`
        } else {
            finalObjectType = props?.objectType?.toLowerCase()
            finalObjectTypeLink = `${props.objectType}/ImportAll/`
        }

        if (props?.requestType === "Custom") {
            if (props?.objectType === "GeneralPrice") {
                if (!file) {
                    toast.warning("Please select a file")
                    return
                }

                const formData = new FormData()
                formData.append("excelFile", file)
                formData.append("numUserID", auth?.employee?.numEmployeeID)

                axiosPrivate
                    .post(`GeneralPrice/ImportExcel`, formData, buildHeaderObject(auth, false, false, true))
                    .then((response) => {
                        console.log("File uploaded successfully", response)
                        if (response.status == 200) {
                            if (response?.data === "Success") {
                                toast.success("Changes have been made!")
                                props.isSuccess(true)
                            } else {
                                downloadDataAsTxt(response?.data)
                                toast.warning("Changes have been made validation errors found please refer the downloaded txt file")
                                props.isSuccess(true)
                            }
                            setUploading(false)
                        }
                    })
                    .catch((error: any) => {
                        setUploading(false)

                        if (error.response && error.response.status === 400) {
                            if (typeof error.response?.data === "string") {
                                toast.error(error.response?.data)
                            } else {
                                console.log("Error Response Data:", error.response.data?.errors)

                                const keys = Object.keys(error.response.data?.errors)
                                const values: string[] = []

                                keys.forEach((key) => {
                                    if (Array.isArray(error.response.data?.errors[key])) {
                                        values.push(...error.response.data?.errors[key])
                                    }
                                })

                                displayErrorToast(values)
                            }
                        } else {
                            console.log("Error:", error)
                            toast.error("An error occurred. Please try again later.")
                        }
                    })
            } else if (props?.objectType === "SpecialPriceList") {
                if (!file) {
                    toast.warning("Please select a file")
                    return
                }

                const formData = new FormData()
                formData.append("excelFile", file)
                axiosPrivate
                    .post(`SpecialPriceList/ImportSpecialPriceListFromExcel?numUserID=${auth?.employee?.numEmployeeID}`, formData, buildHeaderObject(auth, false, false, true))
                    .then((response) => {
                        console.log("File uploaded successfully", response)
                        if (response.status == 200) {
                            if (response?.data === "Success") {
                                toast.success("Changes have been made!")
                                props.isSuccess(true)
                            } else {
                                downloadDataAsTxt(response?.data)
                                toast.warning("Changes have been made validation errors found please refer the downloaded txt file")
                                props.isSuccess(true)
                            }
                            setUploading(false)
                        }
                    })
                    .catch((error: any) => {
                        setUploading(false)

                        if (error.response && error.response.status === 400) {
                            if (typeof error.response?.data === "string") {
                                toast.error(error.response?.data)
                            } else {
                                console.log("Error Response Data:", error.response.data?.errors)

                                const keys = Object.keys(error.response.data?.errors)
                                const values: string[] = []

                                keys.forEach((key) => {
                                    if (Array.isArray(error.response.data?.errors[key])) {
                                        values.push(...error.response.data?.errors[key])
                                    }
                                })

                                displayErrorToast(values)
                            }
                        } else {
                            console.log("Error:", error.message)
                            toast.error("An error occurred. Please try again later.")
                        }
                    })
            }
        } else {
            newUploadReady[finalObjectType] = importedData
            setUploadReady(newUploadReady)

            await axiosPrivate
                .post(finalObjectTypeLink, JSON.stringify(uploadReady), buildHeaderObject(auth))
                .then((result) => {
                    if (result.status == 200) {
                        setUploading(false)
                        toast.success("Changes have been made!")
                    }
                })
                .catch((err) => {
                    setUploading(false)
                    console.log(err)
                    toast.error("Something went wrong!")
                })
        }
        console.log("upsent-", uploadReady)
        setImportedData([])
    }

    function downloadDataAsTxt(data) {
        const jsonData = JSON.stringify(data, null, 4)
        const blob = new Blob([jsonData], { type: "text/plain" })
        const url = URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.download = "Invalid_list.txt"
        link.href = url
        link.click()
        URL.revokeObjectURL(url)
    }

    const displayErrorToast = (errorMessages) => {
        const errorMessage = errorMessages.join(" ") // Concatenate error messages into one string
        toast.error(errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000, // Adjust the autoClose time as needed
        })
    }

    const readExcel = (file) => {
        if (props?.requestType === "Custom") {
            readExcelCustom(file)
        } else {
            readExcelMain(file)
        }
    }

    const readExcelMain = (file) => {
        setUploading(true)
        const myTimeout = setTimeout(() => {
            const promise = new Promise((resolve, reject) => {
                const fileReader = new FileReader()
                fileReader.readAsArrayBuffer(file)

                fileReader.onload = (e) => {
                    const bufferArray = e.target?.result
                    const wb = XLSX.read(bufferArray, { type: "buffer" })
                    const wsname = wb.SheetNames[0]
                    const ws: any = wb.Sheets[wsname]

                    var range = XLSX.utils.decode_range(ws["!ref"])

                    getHeaders(props.objectType)
                        .then((result) => {
                            let newHeaders
                            if (props?.requestType === "Library") {
                                newHeaders = Object.keys(result?.[props?.objectType.charAt(0).toLowerCase() + props?.objectType.slice(1)])
                            } else if (props?.requestType === "Custom") {
                                newHeaders = Object.keys(result[props?.objectType.charAt(0).toLowerCase() + props?.objectType.slice(1)])
                            } else {
                                newHeaders = Object.keys(result?.[props.objectType.toLowerCase()])
                            }

                            let uploadedFileHeaderCount = range.e.c + 1

                            if (uploadedFileHeaderCount == newHeaders.length) {
                                for (var C = range.s.c; C <= range.e.c; ++C) {
                                    var address = XLSX.utils.encode_col(C) + "1"
                                    if (!ws[address]) continue

                                    ws[address].v = newHeaders[C]
                                    ws[address].h = newHeaders[C]
                                    ws[address].w = newHeaders[C]
                                }

                                const data = XLSX.utils.sheet_to_json(ws, { defval: "" })
                                console.log("resolve", data)
                                resolve(data)
                                setUploading(false)
                            } else {
                                toast.error("Invalid Header Length. Try again!")
                                setUploading(false)
                            }
                        })
                        .catch((error) => {
                            toast.error("Something went wrong!")
                            setUploading(false)
                            reject(error)
                        })
                }

                fileReader.onerror = (error) => {
                    reject(error)
                }
            })

            promise.then((d) => {
                setImportedData(d)
                setUploading(false)
                clearTimeout(myTimeout)
            })
        }, 1000)
    }

    const readExcelCustom = (file) => {
        setFile(file)
        setImportedData(file)
        setUploading(false)
    }

    //ACTUAL FUNCTION FOR DATA VALIDATION
    const processData = (data) => {
        const dateKeys = [
            "dtEffectiveDate",
            "dtExpiryDate",
            "dtLocationSpecificAdjustmentEffectiveDate",
            "dtLocationSpecificAdjustmentExpiryDate",
            "dtCreatedDate",
            "dtDateSpecificAdjustmentExpiryDate",
            "dtDateSpecificAdjustmentEffectiveDate",
            "dtEditedDate",
        ]
        const bitKeys = [
            "bitActive",
            "bitActivePromotionFlag",
            "bitIsAdditional",
            "bitLocationSpecificAdjustment",
            "bitTaxableItem",
            "btDateSpecificAdjustment",
            "btDateSpecificAdjustmentAmount",
            "btDateSpecificAdjustmentPercent",
            "btLocationSpecificAdjustmentAmount",
            "btLocationSpecificAdjustmentPercent",
            "btSalesCommissionAmount",
            "btSalesCommissionPercent",
        ] // Add your bit keys here

        return data.map((item) => {
            const newItem = { ...item }

            dateKeys.forEach((key) => {
                if (newItem[key]) {
                    const date = excelDateToJSDate(newItem[key])
                    newItem[key] = date.toISOString()
                }
            })

            bitKeys.forEach((key) => {
                if (typeof newItem[key] === "string") {
                    const lowerCaseValue = newItem[key].toLowerCase()
                    if (lowerCaseValue === "true" || lowerCaseValue === "1") {
                        newItem[key] = true
                    } else if (lowerCaseValue === "false" || lowerCaseValue === "0") {
                        newItem[key] = false
                    }
                }
            })

            return newItem
        })
    }

    //TEST FUNCTION FOR DATA VALIDATION
    const validateData = (data) => {
        const dateKeys = [
            "dtEffectiveDate",
            "dtExpiryDate",
            "dtLocationSpecificAdjustmentEffectiveDate",
            "dtLocationSpecificAdjustmentExpiryDate",
            "dtCreatedDate",
            "dtDateSpecificAdjustmentExpiryDate",
            "dtDateSpecificAdjustmentEffectiveDate",
            "dtEditedDate",
        ]

        return data.map((item) => {
            const newItem = { ...item }

            dateKeys.forEach((key) => {
                if (newItem[key]) {
                    const date = excelDateToJSDate(newItem[key])
                    newItem[key] = date.toISOString()
                }
            })

            return newItem
        })
    }

    const excelDateToJSDate = (serial) => {
        const utc_days = Math.floor(serial - 25569)
        const utc_value = utc_days * 86400
        const date_info = new Date(utc_value * 1000)

        if (serial === 60) {
            date_info.setUTCDate(date_info.getUTCDate() + 1)
        }

        const fractional_day = serial - Math.floor(serial) + 0.0000001
        let total_seconds = Math.floor(86400 * fractional_day)

        const seconds = total_seconds % 60
        total_seconds -= seconds

        const hours = Math.floor(total_seconds / (60 * 60))
        const minutes = Math.floor(total_seconds / 60) % 60

        date_info.setUTCHours(hours, minutes, seconds)

        return date_info
    }

    return (
        <div className={uploading ? styles.uploadContainerUploading : styles.uploadContainer}>
            {uploading ? (
                <p>Uploading...</p>
            ) : importedData.length < 1 ? (
                <FileUploader
                    handleChange={readExcel}
                    onDrop={readExcel}
                    name="file"
                    types={fileTypes}
                    multiple={false}
                    label="asdsdsd"
                    children={
                        <div className={styles.flex}>
                            <FileUploadOutlined style={{ color: "#002867" }} />
                            <p className={styles.uploadText}>{props.text}</p>
                        </div>
                    }
                />
            ) : (
                <div style={{ display: "flex", gap: "1rem" }}>
                    <Button onClick={() => uploadData()} variant="contained" color="success" className={styles.buttonText}>
                        Upload
                    </Button>
                    <Button onClick={() => setImportedData([])} variant="contained" color="danger" className={styles.buttonText}>
                        Cancel
                    </Button>
                </div>
            )}
        </div>
    )
}

export default FileUpload
