import styles from "../PersonalDetails.module.scss"
import { Chip } from "../../../../Chip/Chip"
import { CircularProgress, InputAdornment, TextField } from "@mui/material"
import { useEffect, useMemo, useRef, useState } from "react"
import Switch from "@mui/material/Switch"
import { Button, Avatar, Modal } from "../../../../components"
import { AdvancedInput } from "../../../../AdvancedInput/AdvancedInput"
import { toast } from "react-toastify"
import debouce from "lodash.debounce"
import InputMask from "react-input-mask"

import Cropper from "react-cropper"
import "cropperjs/dist/cropper.css"
import imageCompression from "browser-image-compression"
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3"
import { FileUploader } from "react-drag-drop-files"
import { HighlightOff, FileUploadOutlined } from "@mui/icons-material"
import { CloseButton } from "../../../../CloseButton/CloseButton"
import { axiosPrivate, buildHeaderObject } from "../../../../../apis/axios"
import { checkPermission, isActive } from "../../../../../utils/permissions"
import useAuth from "../../../../../hooks/useAuth"

const EditCustomer = (props: { customerTemplate; data }) => {
    const { auth } = useAuth() as any

    const [editData, setEditData] = useState({
        customer: props?.data,
    })

    const [customerLoading, setCustomerLoading] = useState(false)

    async function saveData() {
        const payload = {
            customer: editData.customer,
            customerAddress: editData.customer.customerAddress,
        }

        console.log(payload, "payladddd")
        const result = await axiosPrivate.put("Customer", JSON.stringify(payload), buildHeaderObject(auth))
        return result
    }

    const saveChanges = () => {
        setCustomerLoading(true)

        var Form: any = document.getElementById("editForm")

        if (Form.checkValidity() == false) {
            console.log("invalid")
            setCustomerLoading(false)
        } else {
            upload()
        }

        console.log(editData)
    }

    const [zipStates, setZipState] = useState([])

    useEffect(() => {
        if (editData.customer.customerAddress.length === 1) {
            const newValue = { ...editData }
            newValue.customer.customerAddress[0].bitActive = true
            newValue.customer.customerAddress[0].bitIsPrimaryDefault = true
            newValue.customer.customerAddress[0].bitIsPrimary = true
            newValue.customer.customerAddress[0].bitIsBillingDefault = true
            newValue.customer.customerAddress[0].bitIsBilling = true

            setEditData(newValue)
        }
    }, [editData.customer.customerAddress[0]])

    async function getStateCity(zipCode) {
        const result = await axiosPrivate.get(`ZipCode/GetCityStateByZipCode/${zipCode}`, buildHeaderObject(auth))
        return result
    }

    const handleZipSearch = (value, index) => {
        getStateCity(value)
            .then((responseCustomer) => {
                const resultZip = responseCustomer?.data

                const newValue = { ...editData }
                newValue.customer.customerAddress[index].varCustomerState = resultZip?.varState
                newValue.customer.customerAddress[index].varCustomerCity = resultZip?.varCity
                setEditData(newValue)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const debouceSearch = useMemo(() => {
        return debouce(handleZipSearch, 500)
    }, [])

    // Set the AWS Region.
    const REGION = "us-east-1"

    // Create an Amazon S3 service client object.
    const creds = {
        accessKeyId: "AKIAXQN6N4QUDD47RQQB",
        secretAccessKey: "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",
    }

    const s3Client = new S3Client({
        region: REGION,
        credentials: creds,
    })

    const fileTypes = ["JPEG", "jpg", "WEBP"]
    const [loading, setLoading] = useState(false)
    const [fileSelected, setFileSelected] = useState(false)
    const [importedData, setImportedData] = useState<any>(editData.customer.varCustomerImagePath)
    const [showCropper, setShowCropper] = useState(false)
    const cropperRef = useRef<HTMLImageElement>(null)
    const [cropper, setCropper] = useState<any>()
    const [imageName, setImageName] = useState<any>("")

    //propsID
    const uploadID = editData.customer.numCustomerID

    const readFile = (files) => {
        if (files.size > 1000000) {
            toast.error(files.name + " File too large")
        } else {
            setImportedData(files)
            setShowCropper(true)
            setFileSelected(true)
            setImageName(files.name)
        }
    }

    const blobToFile = (theBlob: Blob, fileName: string) => {
        var b: any = theBlob
        b.lastModifiedDate = new Date()
        b.name = fileName
        return theBlob
    }

    const getCropData = async () => {
        if (typeof cropper !== "undefined") {
            cropper
                .getCroppedCanvas({
                    imageSmoothingEnabled: false,
                })
                .toBlob((blob) => {
                    let toFile = blobToFile(blob, imageName)
                    let imageFile: any = toFile
                    let options = {
                        maxSizeMB: 0.5,
                        fileType: "image/jpeg",
                        maxWidthOrHeight: 200,
                        useWebWorker: true,
                        onProgress: (progress) => {
                            setLoading(true)
                            if (progress == 100) {
                                setLoading(false)
                            }
                        },
                    }
                    imageCompression(imageFile, options)
                        .then(function (compressedFile) {
                            setImportedData(compressedFile)
                            setShowCropper(false)
                        })
                        .catch(function (error) {
                            console.log(error.message)
                        })
                })
        }
    }

    const upload = () => {
        if (!fileSelected) {
            saveData()
                .then((res) => {
                    console.log(res)
                    toast.success("Customer was updated successfully!")

                    setCustomerLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    setCustomerLoading(false)

                    toast.error("Something went wrong when saving new customer!")
                })
        } else {
            const formData = new FormData()
            formData.append("folderpath", `directories/customers`)
            formData.append("method", "N")
            formData.append("deletingFileName", "-")
            formData.append("renameFileNames", "0")

            const file = importedData
            const fileExtension = file.name.split(".").pop()
            const renamedFile = new File([file], `${editData.customer.numCustomerID}.${fileExtension}`, { type: file.type })
            formData.append("files", renamedFile)

            axiosPrivate
                .post(`FileUpload/UploadFile`, formData, buildHeaderObject(auth))
                .then((response) => {
                    console.log(response)
                    if (response.data.files.length > 0) {
                        const newValue = { ...editData }
                        newValue.customer.varCustomerImagePath = newValue.customer.numCustomerID + "." + fileExtension
                        setEditData(newValue)
                        saveData()
                            .then((res) => {
                                console.log(res)
                                toast.success("Customer was updated successfully!")

                                setCustomerLoading(false)
                            })
                            .catch((err) => {
                                console.log(err)
                                setCustomerLoading(false)

                                toast.error("Something went wrong when saving new customer!")
                            })
                    }
                    setCustomerLoading(false)
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    const removeAddress = (index) => {
        console.log(index)

        const newValue = { ...editData }
        let array = newValue.customer.customerAddress

        console.log(array)

        console.log(array.splice(index, 1))
        console.log(array)

        newValue.customer.customerAddress = array
        setEditData(newValue)
    }

    const updateDefaultStatus = (index) => {
        const newValue = { ...editData }

        newValue.customer.customerAddress.map((addresss, currentIndex) => {
            if (currentIndex === index) {
                newValue.customer.customerAddress[currentIndex].bitIsPrimaryDefault = true
            } else {
                newValue.customer.customerAddress[currentIndex].bitIsPrimaryDefault = false
            }
        })

        setEditData(newValue)
    }

    const updateBillingStatus = (index) => {
        const newValue = { ...editData }

        newValue.customer.customerAddress.map((addresss, currentIndex) => {
            if (currentIndex === index) {
                newValue.customer.customerAddress[currentIndex].bitIsBillingDefault = true
                newValue.customer.customerAddress[currentIndex].bitIsBilling = true
            } else {
                newValue.customer.customerAddress[currentIndex].bitIsBillingDefault = false
                newValue.customer.customerAddress[currentIndex].bitIsBilling = false
            }
        })

        setEditData(newValue)
    }

    const addNewAddress = () => {
        const newValue = { ...editData }

        const newAddress = {
            bitActive: true,
            bitIsBilling: false,
            bitIsBillingDefault: false,
            bitIsPrimary: true,
            bitIsPrimaryDefault: false,
            numAddressTypeID: null,
            numCustomerAddressID: 0,
            varAddressType: 1,
            varCustomerAddress1: "",
            varCustomerAddress2: "",
            varCustomerAddressNotes: null,
            varCustomerCity: "",
            varCustomerState: "",
            varCustomerZip: "",
        }

        newValue.customer.customerAddress.push(newAddress)
        setEditData(newValue)
    }

    let numOfList = 1

    return (
        <form id="editForm" onSubmit={(e) => e.preventDefault()}>
            {props.customerTemplate.map((section) => {
                return (
                    <>
                        {section.heading != undefined ? (
                            section.heading === "Basic details" ? (
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <p className={styles.heading}>{section.heading}</p>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <p>VIP</p>
                                        <Switch
                                            onChange={(e) => {
                                                const newValue = { ...editData }
                                                newValue.customer.bitIsVip = !newValue.customer.bitIsVip
                                                setEditData(newValue)
                                            }}
                                            checked={editData.customer.bitIsVip}
                                            inputProps={{ "aria-label": "controlled" }}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <p className={styles.heading}>{section.heading}</p>
                            )
                        ) : null}

                        {section.layout == "imageUpload" ? (
                            <>
                                <div style={{ backgroundColor: "#F9FAFB" }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", color: "#F9FAFB", padding: "16px 22px 16px 22px" }}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "column" }}>
                                            <div style={{ border: "2px solid #0062FF", borderRadius: "50%" }}>
                                                <Avatar
                                                    img={fileSelected ? URL.createObjectURL(importedData) : `${process.env.REACT_APP_MEDIA_URL}directories/customers/` + importedData}
                                                    isVip={false}
                                                    className={"large"}
                                                    labelType={"largeLabel"}
                                                />
                                            </div>
                                            {editData.customer.varCustomerImagePath != importedData ? (
                                                <p
                                                    style={{ color: "#005DF1", fontSize: "14px", fontWeight: "500", textAlign: "center", cursor: "pointer" }}
                                                    onClick={() => {
                                                        {
                                                            setImportedData(editData.customer.varCustomerImagePath)
                                                        }
                                                        {
                                                            setFileSelected(false)
                                                        }
                                                    }}
                                                >
                                                    Reset photo
                                                </p>
                                            ) : null}
                                        </div>
                                        <div style={{ border: "2px dashed #5f9cfe", borderRadius: "8px", padding: "2rem", backgroundColor: "#fff" }}>
                                            <FileUploader
                                                handleChange={readFile}
                                                onDrop={readFile}
                                                disabled={loading ? true : false}
                                                name="file"
                                                types={fileTypes}
                                                multiple={false}
                                                label=""
                                                children={
                                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                                        <FileUploadOutlined style={{ color: "#2076FF" }} />
                                                        <p className={styles.uploadText}>Drag and drop or browse to choose a image</p>
                                                    </div>
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                <Modal open={showCropper}>
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                                        <div style={{ textAlign: "end", width: "100%" }}>
                                            <CloseButton
                                                onClick={() => {
                                                    {
                                                        setShowCropper(false)
                                                    }
                                                    {
                                                        setFileSelected(false)
                                                    }
                                                    setImportedData(editData.customer.varCustomerImagePath)
                                                }}
                                            />
                                        </div>

                                        <div style={{ backgroundColor: "#fff" }}>
                                            <Cropper
                                                style={{ maxWidth: "500px", maxHeight: "500px" }}
                                                modal={true}
                                                src={fileSelected ? URL.createObjectURL(importedData) : ""}
                                                cropBoxResizable={true}
                                                initialAspectRatio={1}
                                                aspectRatio={1}
                                                guides={true}
                                                minCropBoxHeight={200}
                                                scalable={false}
                                                minCropBoxWidth={200}
                                                highlight={true}
                                                zoomable={true}
                                                movable={true}
                                                onInitialized={(instance) => {
                                                    setCropper(instance)
                                                }}
                                                ref={cropperRef}
                                            />
                                        </div>
                                        <div style={{ textAlign: "end", width: "100%" }}>
                                            <Button variant="contained" color="primary" onClick={() => getCropData()}>
                                                Crop & Fix
                                            </Button>
                                        </div>
                                    </div>
                                </Modal>
                            </>
                        ) : section.layout == "basic" ? (
                            <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "2rem", rowGap: "2ch" }}>
                                {section.data.map((rows) => {
                                    return rows.row.map((input) => {
                                        return input.type === "advanced" ? (
                                            <AdvancedInput
                                                required={input.isRequired}
                                                isButton={input.isButton}
                                                label={input.label}
                                                onChange={(e) => {
                                                    const newValue = { ...editData }
                                                    newValue.customer[input.key] = e
                                                    setEditData(newValue)
                                                }}
                                                ObjectName={input.objectName}
                                                isCustom={input.isCustom}
                                                Sequence={input.sequence}
                                                selectedValue={input.value}
                                            />
                                        ) : input.type === "text" ? (
                                            input.inputType === "tel" ? (
                                                <div>
                                                    <InputMask
                                                        alwaysShowMask="true"
                                                        mask="\(999\)999\-9999"
                                                        maskChar="_"
                                                        value={editData.customer[input.key]}
                                                        onChange={(e) => {
                                                            const newValue = { ...editData }
                                                            newValue.customer[input.key] = e.target.value
                                                            setEditData(newValue)
                                                        }}
                                                    >
                                                        {(inputProps) => (
                                                            <TextField
                                                                inputProps={{
                                                                    pattern: input.isRequired
                                                                        ? "^[0-9()-]+$"
                                                                        : editData.customer[input.key] === "" || editData.customer[input.key] === "(___)___-____"
                                                                        ? null
                                                                        : "^[0-9()-]+$",
                                                                }}
                                                                {...inputProps}
                                                                type="tel"
                                                                required={input.isRequired}
                                                                style={{ width: "100%" }}
                                                                label={
                                                                    <div style={{ display: "flex", fontSize: "14px" }}>
                                                                        {input.label}
                                                                        {input.isRequired ? (
                                                                            <span
                                                                                style={{
                                                                                    fontWeight: 500,
                                                                                    backgroundColor: "#FDEDED",
                                                                                    color: "#EF4444",
                                                                                    fontSize: "12px",
                                                                                    padding: "0 5px",
                                                                                    marginLeft: "5px",
                                                                                }}
                                                                            >
                                                                                Required
                                                                            </span>
                                                                        ) : null}
                                                                    </div>
                                                                }
                                                                variant="standard"
                                                            />
                                                        )}
                                                    </InputMask>
                                                </div>
                                            ) : (
                                                <div>
                                                    <TextField
                                                        inputProps={{
                                                            pattern: input.key === "varCustomerFirstName" || input.key === "varCustomerLastName" ? "^[a-zA-Z ]*$" : null,
                                                        }}
                                                        variant="filled"
                                                        style={{ width: "100%" }}
                                                        label={
                                                            <div style={{ display: "flex", fontSize: "14px" }}>
                                                                {input.label}
                                                                {input.isRequired ? (
                                                                    <span
                                                                        style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}
                                                                    >
                                                                        Required
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        }
                                                        required={input.isRequired}
                                                        onChange={(e) => {
                                                            const newValue = { ...editData }
                                                            newValue.customer[input.key] = e.target.value
                                                            setEditData(newValue)
                                                        }}
                                                        type={input.inputType}
                                                        value={editData.customer[input.key]}
                                                    />
                                                </div>
                                            )
                                        ) : input.type === "blank" ? (
                                            <div></div>
                                        ) : null
                                    })
                                })}
                            </div>
                        ) : section.layout == "address" ? (
                            <>
                                {section.data.map((address, index) => {
                                    return (
                                        <>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "1rem" }}>
                                                <div style={{ display: "flex", alignItems: "center", gap: ".6rem" }}>
                                                    <p>{`Address ${index + 1}`}</p>
                                                    {address.bitIsPrimaryDefault ? (
                                                        <Chip color={"success"} label="Default" size="small" variant="filled" />
                                                    ) : (
                                                        <Chip onClick={() => updateDefaultStatus(index)} color={"info"} label="Set as Default" size="small" variant="outlined" />
                                                    )}

                                                    {address.bitIsBillingDefault ? (
                                                        <Chip onClick={() => updateBillingStatus(index)} color={"success"} label="Default Billing Address" size="small" variant="filled" />
                                                    ) : (
                                                        <Chip onClick={() => updateBillingStatus(index)} color={"info"} label="Set as Default Billing" size="small" variant="outlined" />
                                                    )}
                                                </div>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <p>Active</p>
                                                    <Switch
                                                        onChange={(e) => {
                                                            const newValue = { ...editData }
                                                            newValue.customer.customerAddress[index].bitActive = !newValue.customer.customerAddress[index].bitActive
                                                            setEditData(newValue)
                                                        }}
                                                        checked={editData.customer.customerAddress[index].bitActive != undefined ? editData.customer.customerAddress[index].bitActive : null}
                                                        inputProps={{ "aria-label": "controlled" }}
                                                    />
                                                    {/* <div style={{ cursor: 'pointer', display: 'flex' }} onClick={() => removeAddress(index)}>
                                        {
                                            (section.data.length != 1) ?
                                                <HighlightOff style={{ color: 'red' }} />
                                            : null
                                        }
                                        
                                    </div> */}
                                                </div>
                                            </div>

                                            <div>
                                                <div style={{ display: "flex", justifyContent: "space-between", gap: "2rem" }}>
                                                    <div style={{ marginBottom: "1.5rem", width: "100%" }}>
                                                        <AdvancedInput
                                                            isButton={isActive(16)}
                                                            label={"Type"}
                                                            required={true}
                                                            onChange={(e) => {
                                                                const newValue = { ...editData }
                                                                newValue.customer.customerAddress[index].numAddressTypeID = e
                                                                setEditData(newValue)
                                                            }}
                                                            ObjectName={"AddressType"}
                                                            isCustom={false}
                                                            Sequence={""}
                                                            selectedValue={editData.customer.customerAddress[index].numAddressTypeID}
                                                        />
                                                    </div>

                                                    <TextField
                                                        style={{ width: "100%", marginBottom: "1.5rem" }}
                                                        label={
                                                            <div style={{ display: "flex", fontSize: "14px" }}>
                                                                {"Street address"}
                                                                {true ? (
                                                                    <span
                                                                        style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}
                                                                    >
                                                                        Required
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        }
                                                        required={true}
                                                        onChange={(e) => {
                                                            const newValue = { ...editData }
                                                            newValue.customer.customerAddress[index].varCustomerAddress1 = e.target.value
                                                            setEditData(newValue)
                                                        }}
                                                        type={"text"}
                                                        variant="filled"
                                                        value={editData.customer.customerAddress[index].varCustomerAddress1}
                                                    />
                                                </div>

                                                <div style={{ display: "flex", justifyContent: "space-between", gap: "2rem" }}>
                                                    <TextField
                                                        style={{ width: "100%", marginBottom: "1.5rem" }}
                                                        label={
                                                            <div style={{ display: "flex", fontSize: "14px" }}>
                                                                {"Address line 2"}
                                                                {false ? (
                                                                    <span
                                                                        style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}
                                                                    >
                                                                        Required
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        }
                                                        required={false}
                                                        onChange={(e) => {
                                                            const newValue = { ...editData }
                                                            newValue.customer.customerAddress[index].varCustomerAddress2 = e.target.value
                                                            setEditData(newValue)
                                                        }}
                                                        type={"text"}
                                                        variant="filled"
                                                        value={editData.customer.customerAddress[index].varCustomerAddress2}
                                                    />

                                                    <TextField
                                                        inputProps={{
                                                            pattern: "^[a-zA-Z ]*$",
                                                        }}
                                                        style={{ width: "100%", marginBottom: "1.5rem" }}
                                                        label={
                                                            <div style={{ display: "flex", fontSize: "14px" }}>
                                                                {"City"}
                                                                {true ? (
                                                                    <span
                                                                        style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}
                                                                    >
                                                                        Required
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        }
                                                        required={true}
                                                        onChange={(e) => {
                                                            const newValue = { ...editData }
                                                            newValue.customer.customerAddress[index].varCustomerCity = e.target.value
                                                            setEditData(newValue)
                                                        }}
                                                        type={"text"}
                                                        variant="filled"
                                                        value={editData.customer.customerAddress[index].varCustomerCity}
                                                    />
                                                </div>

                                                <div style={{ display: "flex", justifyContent: "space-between", gap: "2rem" }}>
                                                    <TextField
                                                        inputProps={{
                                                            pattern: "^[a-zA-Z ]*$",
                                                        }}
                                                        style={{ width: "100%", marginBottom: "1.5rem" }}
                                                        label={
                                                            <div style={{ display: "flex", fontSize: "14px" }}>
                                                                {"State"}
                                                                {true ? (
                                                                    <span
                                                                        style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}
                                                                    >
                                                                        Required
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        }
                                                        required={true}
                                                        onChange={(e) => {
                                                            const newValue = { ...editData }
                                                            newValue.customer.customerAddress[index].varCustomerState = e.target.value
                                                            setEditData(newValue)
                                                        }}
                                                        type={"text"}
                                                        variant="filled"
                                                        value={editData.customer.customerAddress[index].varCustomerState}
                                                    />

                                                    <InputMask
                                                        alwaysShowMask="true"
                                                        mask="99999"
                                                        maskChar="_"
                                                        value={editData.customer.customerAddress[index].varCustomerZip}
                                                        onChange={(e) => {
                                                            debouceSearch(e.target.value, index)

                                                            const newValue = { ...editData }
                                                            newValue.customer.customerAddress[index].varCustomerZip = e.target.value
                                                            setEditData(newValue)
                                                        }}
                                                    >
                                                        {(inputProps) => (
                                                            <TextField
                                                                {...inputProps}
                                                                type="tel"
                                                                required={true}
                                                                style={{ width: "100%" }}
                                                                label={
                                                                    <div style={{ display: "flex", fontSize: "14px" }}>
                                                                        {"Postal/Zip Code"}
                                                                        {true ? (
                                                                            <span
                                                                                style={{
                                                                                    fontWeight: 500,
                                                                                    backgroundColor: "#FDEDED",
                                                                                    color: "#EF4444",
                                                                                    fontSize: "12px",
                                                                                    padding: "0 5px",
                                                                                    marginLeft: "5px",
                                                                                }}
                                                                            >
                                                                                Required
                                                                            </span>
                                                                        ) : null}
                                                                    </div>
                                                                }
                                                                variant="filled"
                                                            />
                                                        )}
                                                    </InputMask>

                                                    {/* <TextField                                         
                                        style={{ width: '100%', marginBottom: '1.5rem' }}
                                        label= {
                                            <div style={{display: 'flex', fontSize: '14px'}}>
                                                {'Postal/Zip Code'}
                                                {(true) ? 
                                                <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                : null} 
                                            </div>
                                        }
                                        required={true}
                                        onChange={(e) => {                                                
                                            debouceSearch(e.target.value, index)        
                                            
                                            const newValue = {...editData}                                                
                                            newValue.customer.customerAddress[index].varCustomerZip = e.target.value
                                            setEditData(newValue)  
                                        }}
                                        type={'text'}
                                        variant="filled"
                                        value={editData.customer.customerAddress[index].varCustomerZip}
                                    />                           */}
                                                </div>
                                            </div>

                                            <div style={{ marginBottom: "1.5rem" }}>
                                                <TextField
                                                    multiline={true}
                                                    style={{ width: "100%", marginBottom: "1.8rem" }}
                                                    label={
                                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                                            {"Notes"}
                                                            {false ? (
                                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                    Required
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    }
                                                    required={false}
                                                    onChange={(e) => {
                                                        const newValue = { ...editData }
                                                        newValue.customer.customerAddress[index].varCustomerAddressNotes = e.target.value
                                                        setEditData(newValue)
                                                    }}
                                                    type={"text"}
                                                    variant="filled"
                                                    value={editData.customer.customerAddress[index].varCustomerAddressNotes}
                                                />
                                            </div>
                                        </>
                                    )
                                })}
                                {checkPermission(<Button color="default" variant="outlined" text="Add new address" onClick={() => addNewAddress()} />, 17)}
                            </>
                        ) : section.layout == "dynamic" ? (
                            <>
                                {console.log(section.data)}
                                <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "2rem", rowGap: "2ch" }}>
                                    {section.data.map((input) => {
                                        return input.type === "String" ? (
                                            <>
                                                <TextField
                                                    style={{ width: "100%" }}
                                                    label={
                                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                                            {input.key}
                                                            {input.required ? (
                                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                    Required
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    }
                                                    required={input.required}
                                                    onChange={(e) => {
                                                        const newValue = { ...editData }
                                                        newValue.customer[input.fieldName] = e.target.value
                                                        setEditData(newValue)
                                                    }}
                                                    type={"text"}
                                                    variant="filled"
                                                    value={editData.customer[input.fieldName]}
                                                />
                                            </>
                                        ) : input.type === "DateTime" ? (
                                            <>
                                                <TextField
                                                    // inputProps={{
                                                    //     pattern: "[a-z]{1,15}"
                                                    // }}
                                                    style={{ width: "100%" }}
                                                    label={
                                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                                            {input.key}
                                                            {input.required ? (
                                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                    Required
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    }
                                                    required={input.required}
                                                    onChange={(e) => {
                                                        const newValue = { ...editData }
                                                        newValue.customer[input.fieldName] = e.target.value
                                                        setEditData(newValue)
                                                    }}
                                                    focused={true}
                                                    type={"date"}
                                                    variant="filled"
                                                    value={editData.customer[input.fieldName]}
                                                />
                                            </>
                                        ) : input.type === "Int32" && numOfList++ ? (
                                            <>
                                                <AdvancedInput
                                                    isButton={true}
                                                    label={
                                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                                            {input.key}
                                                            {input.required ? (
                                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                    Required
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    }
                                                    required={input.required}
                                                    onChange={(e) => {
                                                        const newValue = { ...editData }
                                                        newValue.customer[input.fieldName] = e
                                                        setEditData(newValue)
                                                    }}
                                                    ObjectName={"Customer"}
                                                    isCustom={true}
                                                    Sequence={numOfList}
                                                    selectedValue={editData.customer[input.fieldName]}
                                                />
                                            </>
                                        ) : input.type === "Boolean" ? (
                                            <>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                    <p>{input.key}</p>
                                                    <Switch
                                                        onChange={(e) => {
                                                            const newValue = { ...editData }
                                                            newValue.customer[input.fieldName] = !newValue.customer[input.fieldName]
                                                            setEditData(newValue)
                                                        }}
                                                        checked={editData.customer[input.fieldName]}
                                                        inputProps={{ "aria-label": "controlled" }}
                                                    />
                                                </div>
                                            </>
                                        ) : null
                                    })}
                                </div>
                            </>
                        ) : null}
                        <div style={{ paddingTop: "1rem", marginBottom: "1.5rem" }}></div>
                    </>
                )
            })}
            {checkPermission(
                <div style={{ backgroundColor: "#EFF5FF", padding: "2rem 3rem", textAlign: "end" }}>
                    <div style={{ display: "flex", gap: "2rem", justifyContent: "flex-end" }}>
                        <Button onClick={() => saveChanges()} loading={customerLoading} variant="contained" color="primary" text="Save" type={"submit"} />
                    </div>
                </div>,
                18
            )}
        </form>
    )
}

export default EditCustomer
