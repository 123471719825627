import { useEffect, useMemo, useState } from "react"
import { axiosPrivate, buildHeaderObject } from "../../../../apis/axios"
import { toast } from "react-toastify"
import { Avatar, Button, Modal, SearchBox, Input } from "../../../components"
import { Chip as LocationChip } from "../../../Chip/Chip"
import { MoreHoriz, Place, RefreshOutlined } from "@mui/icons-material"
import { CircularProgress, FormControlLabel, IconButton, Switch, TextField } from "@mui/material"
import { CloseButton } from "../../../CloseButton/CloseButton"
import debouce from "lodash.debounce"
import styles from "./OwnershipTransfer.module.scss"
import { CustomerCard } from "../../../CustomerCard/CustomerCard"
import InputMask from "react-input-mask"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { Truncate } from "../../../../utils/Validation"
import { removeFormatPhone, formatPhone } from "../../../../utils/PhoneFormatter"
import useAuth from "../../../../hooks/useAuth"

function OwnershipTransfer(props: { data?; mode?; type?; index? }) {
    const { auth } = useAuth() as any

    const [isLoading, setIsLoading] = useState(false)
    const [currOwner, setCurrOwner] = useState<any>([])
    const [linkedIsOpen, setLinkedIsOpen] = useState(false)
    const [advancedSearch, setAdvancedSearch] = useState(false)
    const [fSearch, setFSearch] = useState("")
    const [lSearch, setLSearch] = useState("")
    const [petSearch, setPetSearch] = useState("")
    const [contactSearch, setContactSearch] = useState("")
    const [searchValue, setSearchValue] = useState("")
    const [searchData, setSearchData] = useState([] as any)
    const [selectedList, setSelectedList] = useState<any>([])
    const [currentOwner, setCurrentOwner] = useState<any>(
        props.type === "petProfile" ? props.data.numCustomerID : props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numCustomerID : ""
    )
    const [currentOwnerOrg, setCurrentOwnerOrg] = useState<any>(
        props.type === "petProfile" ? props.data.numCustomerID : props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numCustomerID : ""
    )
    const [prevOwners, setPrevOwners] = useState<any>([])

    async function getOwner(id) {
        if (id != null) {
            setIsLoading(true)
            const result = await axiosPrivate.get(`Customer/GetByID/${id}`, buildHeaderObject(auth))
            return result
        }
    }

    async function getPetByID() {
        setIsLoading(true)
        const result: any = await axiosPrivate.get(
            `Pet/GetByID/${
                props.type === "petProfile" ? props.data.numPetID : props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetID : ""
            }`,
            buildHeaderObject(auth)
        )
        return result
    }

    async function getOwnerList() {
        if (props.data.numCustomerID != null) {
            setIsLoading(true)
            const result = await axiosPrivate.get(
                `Pet/GetPreviousOwnersByID/${
                    props.type === "petProfile" ? props.data.numPetID : props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetID : ""
                }`,
                buildHeaderObject(auth)
            )
            return result
        }
    }

    useEffect(() => {
        getOwnerList()
            .then((response) => {
                setPrevOwners(response?.data?.petPreviousOwner)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })

        getPetByID()
            .then((response) => {
                let customer = response.data.pet.numCustomerID

                getOwner(customer)
                    .then((response) => {
                        setCurrOwner(response?.data?.customer)
                        setCurrentOwnerOrg(response?.data?.customer)
                        setIsLoading(false)
                    })
                    .catch((err) => {
                        console.log(err)
                        toast.error("Something went wrong!")
                        setIsLoading(false)
                    })
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
                setIsLoading(false)
            })
    }, [props.data, props.index])

    const handleModalClose = () => {
        setLinkedIsOpen(!linkedIsOpen)
        setSearchValue("")
        setLinkedIsOpen(false)
    }

    const handleSearch = (value) => {
        setSearchValue(value)
    }

    const debouceSearch = useMemo(() => {
        return debouce(handleSearch, 500)
    }, [])

    const toggleAdvanceSearch = () => {
        if (advancedSearch) {
            setAdvancedSearch(false)
        } else {
            setAdvancedSearch(true)
        }
    }
    useEffect(() => {
        if (advancedSearch == false) {
            setFSearch("")
            setLSearch("")
            setContactSearch("")
            setPetSearch("")
        }
    }, [advancedSearch])

    useEffect(() => {
        if (searchValue != "") {
            fetchData(searchValue)
        }
    }, [searchValue])

    const fetchData = (searchQuery) => {
        setIsLoading(true)
        getData(searchQuery)
            .then((res) => {
                setSearchData(res.customer)
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setIsLoading(false)

                toast.error("Something went wrong!")
                setSearchData([])
            })
    }

    async function getData(searchQuery) {
        const result = await axiosPrivate.post(
            "Customer/Search",
            JSON.stringify({
                page: 0,
                pageSize: 0,
                searchText: searchQuery,
            }),
            buildHeaderObject(auth)
        )
        return result.data
    }

    const selectAccount = (user) => {
        setSelectedList(user)
    }

    const handleSave = () => {
        if (selectedList == "") {
            toast.info("Please select a customer to transfer the ownership!")
        } else {
            let obj = {
                numPetID: props.type === "petProfile" ? props.data.numPetID : props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetID : "",
                numPrevCustomerID:
                    props.type === "petProfile"
                        ? props.data.numCustomerID
                        : props.data?.customerPet.length > 0
                        ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numCustomerID
                        : "",
                numNewCustomerID: selectedList.numCustomerID,
            }
            linkSubmit(obj)
        }
    }

    async function linkSubmit(data) {
        await axiosPrivate
            .post(`Pet/PostPreviousOwner`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                if (result.status == 200) {
                    toast.success(`Ownership transfer successful!`)
                    setSearchValue("")
                    setSearchData([])
                    setLinkedIsOpen(false)
                    getPetByID()
                        .then((response) => {
                            let customer = response.data.pet.numCustomerID
                            getOwner(customer)
                                .then((response) => {
                                    setCurrOwner(response?.data?.customer)
                                    setCurrentOwnerOrg(response?.data?.customer)
                                    setIsLoading(false)
                                })
                                .catch((err) => {
                                    console.log(err)
                                    toast.error("Something went wrong!")
                                    setIsLoading(false)
                                })
                        })
                        .catch((err) => {
                            console.log(err)
                            toast.error("Something went wrong!")
                            setIsLoading(false)
                        })
                    getOwnerList()
                        .then((response) => {
                            setPrevOwners(response?.data?.petPreviousOwner)
                        })
                        .catch((err) => {
                            console.log(err)
                            toast.error("Something went wrong!")
                        })
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    function createData(date: string, owner: number, location: number, duration: number, options: number) {
        return { date, owner, location, duration, options }
    }

    const getDate = (date) => {
        let CurrDate = new Date(date).toDateString()
        return CurrDate
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (fSearch != "" || lSearch != "" || contactSearch != "" || petSearch != "") {
                getAdvancedData()
                    .then((res) => {
                        setSearchData(res.customer)
                        setIsLoading(false)
                    })
                    .catch((err) => {
                        console.log(err)
                        setIsLoading(false)
                        setSearchData([])
                        toast.error("Something went wrong!")
                    })
            }
        }, 900)
        return () => clearTimeout(delayDebounceFn)
    }, [fSearch, lSearch, contactSearch, petSearch])

    const validateString = (input) => {
        return input === "" ? null : input
    }

    async function getAdvancedData() {
        setIsLoading(true)

        const result = await axiosPrivate.post(
            "Customer/AdvanceSearch",
            JSON.stringify({
                varLocation: null,
                varFirstName: validateString(fSearch),
                varLastName: validateString(lSearch),
                varCity: null,
                varState: null,
                varZip: null,
                bitActiveStatus: null,
                bitPriorityVIP: null,
                varPetName: validateString(petSearch),
                numPetTypeID: null,
                varPetBreed: null,
                varContactNumber: validateString(contactSearch) == null ? null : removeFormatPhone(validateString(contactSearch)),
            }),
            buildHeaderObject(auth)
        )

        return result.data
    }

    const discardChanges = () => {
        setSelectedList("")
        setLinkedIsOpen(false)
        setFSearch("")
        setLSearch("")
        setContactSearch("")
        setPetSearch("")
        setAdvancedSearch(false)
        setSearchData([])
        if (currentOwnerOrg == null) {
            setCurrOwner(null)
        } else {
            setCurrOwner(currentOwnerOrg)
        }
    }
    const handleAdd = () => {
        if (Object.keys(selectedList).length > 0) {
            if (currOwner == null) {
                let obj = {
                    numCustomerID: selectedList.numCustomerID,
                    varCustomerImagePath: selectedList.varCustomerImagePath,
                    varEmployeeFirstName: selectedList.bitIsVip,
                    varCustomerFirstName: selectedList.varCustomerFirstName,
                    varCustomerLastName: selectedList.varCustomerLastName,
                    varCustomerDefaultLocation: selectedList.varCustomerDefaultLocation,
                }
                setCurrOwner(obj)
            } else {
                const ownerEdit: any = { ...currOwner }
                ownerEdit.numCustomerID = selectedList.numCustomerID
                ownerEdit.varCustomerImagePath = selectedList.varCustomerImagePath
                ownerEdit.bitIsVip = selectedList.bitIsVip
                ownerEdit.varCustomerFirstName = selectedList.varCustomerFirstName
                ownerEdit.varCustomerLastName = selectedList.varCustomerLastName
                ownerEdit.varCustomerDefaultLocation = selectedList.varCustomerDefaultLocation
                setCurrOwner(ownerEdit)
            }
            setLinkedIsOpen(false)
        } else {
            toast.info("Please select a user to transfer the ownership!")
        }
    }

    return props.data.numCustomerID == null ? (
        <p style={{ fontSize: "12px", fontWeight: "500", color: "#6B7280" }}>Owner not found!</p>
    ) : (
        <div>
            <div style={{ display: "flex", gap: "20px" }}>
                {isLoading ? (
                    "Loading..."
                ) : (
                    <>
                        <div key={currOwner.numCustomerID} style={props.mode ? { margin: "0 .5rem .5rem 0", width: "100%" } : { margin: "0 .5rem .5rem 0", width: "406px" }}>
                            <div style={{ border: "1px solid #E5E7EB", borderRadius: 8, padding: "12px", backgroundColor: "#ffffff" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "1rem" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between", gap: ".6rem" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Avatar
                                                img={currOwner.varCustomerImagePath == null ? "" : "https://pet-tech-api.s3.amazonaws.com/directories/Customer/" + currOwner.varCustomerImagePath}
                                                isVip={currOwner.bitIsVip}
                                                className={"medium"}
                                            />
                                        </div>
                                        <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between", gap: "10px" }}>
                                            <div style={{ fontWeight: 600, fontSize: "16px" }}>
                                                {currOwner.varCustomerFirstName} {currOwner.varCustomerLastName}
                                            </div>
                                            <div style={{ display: "flex", gap: "5px" }}>
                                                <LocationChip label={currOwner.varCustomerDefaultLocation} shape="square" icon={<Place style={{ fontSize: "15px" }} />} customStyle="BlueFilled" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ padding: "5px 0", display: "flex", justifyContent: "space-between", borderTop: "1px solid #F3F4F6" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                        <div style={{ fontWeight: 600, fontSize: 14, color: "#002867" }}>{formatPhone(currOwner.varCustomerMobilePhone)}</div>
                                        <div style={{ fontWeight: 600, fontSize: 14, color: "#002867" }}>{formatPhone(currOwner.varCustomerHomePhone)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {props.mode ? (
                            <>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <RefreshOutlined style={{ color: "#6B7280" }} />
                                </div>
                                <div style={{ margin: "0 .5rem .5rem 0", width: "100%" }}>
                                    <div
                                        style={{
                                            border: "1px solid #E5E7EB",
                                            borderRadius: 8,
                                            backgroundColor: "#ffffff",
                                            height: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Button
                                            color="primary"
                                            onClick={() => {
                                                setLinkedIsOpen(true)
                                            }}
                                            variant="contained"
                                        >
                                            Transfer
                                        </Button>
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </>
                )}
                {linkedIsOpen ? (
                    <Modal open={linkedIsOpen}>
                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem", width: "700px" }}>
                            <div style={{ textAlign: "end", width: "100%" }}>
                                <CloseButton
                                    onClick={() => {
                                        handleModalClose()
                                    }}
                                />
                            </div>
                            <div style={{ backgroundColor: "#fff", display: "flex", justifyContent: "space-between", width: "100%" }}>
                                <div style={{ paddingLeft: "1rem", width: "216px" }}>
                                    <SearchBox value={searchValue} onChange={(e) => debouceSearch(e)} />
                                </div>
                                <div style={{ paddingRight: "1rem" }}>
                                    <FormControlLabel
                                        value="start"
                                        control={
                                            <Switch
                                                color="primary"
                                                onChange={() => {
                                                    toggleAdvanceSearch()
                                                }}
                                            />
                                        }
                                        label="Advanced Search"
                                        labelPlacement="start"
                                        checked={advancedSearch}
                                        style={{ color: "#002867", fontSize: "14px" }}
                                    />
                                </div>
                            </div>
                            {advancedSearch ? (
                                <div style={{ backgroundColor: "#F9FAFB", display: "flex", justifyContent: "space-between", width: "100%" }}>
                                    <div style={{ padding: "0px 1rem", width: "100%", display: "flex", justifyContent: "space-around" }}>
                                        <div>
                                            <Input
                                                onChange={(val) => {
                                                    setFSearch(val)
                                                }}
                                                className={styles.appInput}
                                                label={"First Name"}
                                                value={fSearch}
                                            />
                                            <Input
                                                onChange={(val) => {
                                                    setLSearch(val)
                                                }}
                                                className={styles.appInput}
                                                label={"Last Name"}
                                                value={lSearch}
                                            />
                                        </div>
                                        <div>
                                            <Input
                                                onChange={(val) => {
                                                    setPetSearch(val)
                                                }}
                                                className={styles.appInput}
                                                label={"Pets Name"}
                                                value={petSearch}
                                            />
                                            <InputMask alwaysShowMask="true" mask="\(999\)999\-9999" maskChar="_" value={contactSearch} onChange={(e) => setContactSearch(e.target.value)}>
                                                {(inputProps) => <TextField {...inputProps} type="tel" required={false} label={<p>Mobile</p>} variant="standard" />}
                                            </InputMask>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            <div
                                className={styles.gridContainer}
                                style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "2rem", rowGap: "2ch", marginTop: "0.6rem", paddingBottom: "1rem" }}
                            >
                                {isLoading ? (
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <CircularProgress />
                                    </div>
                                ) : searchData.length > 0 ? (
                                    searchData.map((user, index) => {
                                        const selectedUser = selectedList
                                        let isIn
                                        if (selectedUser.numCustomerID == user.numCustomerID.toString()) {
                                            isIn = true
                                        } else {
                                            isIn = false
                                        }
                                        if (user.numCustomerID != currentOwner) {
                                            return (
                                                <div
                                                    onClick={() => {
                                                        selectAccount(user)
                                                    }}
                                                    className={isIn ? styles.customerCardChecked : styles.customerCard}
                                                >
                                                    <CustomerCard
                                                        key={index}
                                                        CustomerImageURL={
                                                            user.varCustomerImagePath == null ? "" : `https://pet-tech-api.s3.amazonaws.com/directories/Customer/${user?.varCustomerImagePath}`
                                                        }
                                                        Home={user.varCustomerHomePhone}
                                                        Location={Truncate(user.varCustomerDefaultLocation, 27)}
                                                        Mobile={user.varCustomerMobilePhone}
                                                        isVIP={user.bitIsVip}
                                                        Name={user.varCustomerFirstName + " " + user.varCustomerLastName}
                                                        OnOpenInNew={true}
                                                    ></CustomerCard>
                                                </div>
                                            )
                                        } else {
                                            return <p style={{ marginRight: "11rem", color: "grey", fontWeight: "500" }}>No customer found</p>
                                        }
                                    })
                                ) : (
                                    <p style={{ marginRight: "11rem", color: "grey", fontWeight: "500" }}>No customer found</p>
                                )}
                            </div>
                            <div style={{ textAlign: "end", width: "100%", backgroundColor: "#EFF5FF", padding: "1rem" }}>
                                <Button
                                    variant="text"
                                    color="transparent"
                                    onClick={() => {
                                        discardChanges()
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                        handleAdd()
                                    }}
                                    className={styles.buttonText}
                                >
                                    Select Customer
                                </Button>
                            </div>
                        </div>
                    </Modal>
                ) : null}
            </div>
            {isLoading ? null : (
                <div style={{ marginTop: "3rem" }}>
                    <p style={{ color: "#23262F", fontWeight: "600", fontSize: "14px" }}>Previous Owners</p>

                    {prevOwners.length > 0 ? (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ color: "#002867", fontWeight: "600" }}> Transfer Date</TableCell>
                                        <TableCell style={{ color: "#002867", fontWeight: "600" }} align="left">
                                            Owner
                                        </TableCell>
                                        <TableCell style={{ color: "#002867", fontWeight: "600" }} align="center">
                                            Options
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {prevOwners.map((row) => (
                                        <TableRow
                                            key={row.numPetPreviousOwnerID}
                                            sx={{
                                                "&:last-child td, &:last-child th": { border: 0 },
                                                "& .MuiTableRow-root:hover": {
                                                    backgroundColor: "primary.light",
                                                },
                                            }}
                                        >
                                            <TableCell align="left">{getDate(row.dtTransferredDate)}</TableCell>
                                            <TableCell align="left">
                                                {row.varCustomerFirstName} {row.varCustomerLastName}
                                            </TableCell>
                                            <TableCell align="center">
                                                <a href="#">View</a>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <p style={{ fontSize: "12px", fontWeight: "400", color: "rgb(107, 114, 128)" }}>No previous owners found</p>
                    )}
                </div>
            )}
            {props.mode ? (
                <div style={{ textAlign: "end", width: "100%", backgroundColor: "#EFF5FF", padding: "1rem", marginTop: "3rem" }}>
                    <Button
                        variant="text"
                        color="transparent"
                        onClick={() => {
                            discardChanges()
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        loading={isLoading}
                        onClick={() => {
                            handleSave()
                        }}
                    >
                        Save
                    </Button>
                </div>
            ) : null}
        </div>
    )
}

export default OwnershipTransfer
