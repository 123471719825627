import { Checkbox, TextField } from "@mui/material"
import { useState } from "react"
import { CloseButton } from "../../../CloseButton/CloseButton"
import { Button, Chip, Avatar, Modal } from "../../../components"
import { toast } from "react-toastify"
import { axiosPrivate, buildHeaderObject } from "../../../../apis/axios"
import useAuth from "../../../../hooks/useAuth"

function AlertsAndComments(props: { data; mode }) {
    const [alertData, setAlertData] = useState({
        bitCustomerAlertAlwaysLate: props.data.bitCustomerAlertAlwaysLate,
        bitCustomerAlertDifficultToHandle: props.data.bitCustomerAlertDifficultToHandle,
        bitCustomerAlertDiscountOnFile: props.data.bitCustomerAlertDiscountOnFile,
        bitCustomerAlertNoShow: props.data.bitCustomerAlertNoShow,
        bitCustomerAlertOutstandingBill: props.data.bitCustomerAlertOutstandingBill,
        bitCustomerAlertVerbalConfirmationNeeded: props.data.bitCustomerAlertVerbalConfirmationNeeded,
        varCustomerComments: props.data.varCustomerComments,
        numCustomerID: props.data.numCustomerID,
    })

    const [showAdd, setShowAdd] = useState(false)
    const [comment, setComment] = useState("")
    const [customerLoading, setCustomerLoading] = useState(false)

    const handleSubmit = () => {
        const editState: any = { ...alertData }
        editState.varCustomerComments = comment
        setAlertData(editState)
        setShowAdd(false)
        setComment("")
    }

    const submitChanges = () => {
        saveData()
            .then((res) => {
                toast.success("Customer details updated successfully!")

                props.data.bitCustomerAlertAlwaysLate = alertData.bitCustomerAlertAlwaysLate
                props.data.bitCustomerAlertDifficultToHandle = alertData.bitCustomerAlertDifficultToHandle
                props.data.bitCustomerAlertDiscountOnFile = alertData.bitCustomerAlertDiscountOnFile
                props.data.bitCustomerAlertNoShow = alertData.bitCustomerAlertNoShow
                props.data.bitCustomerAlertOutstandingBill = alertData.bitCustomerAlertOutstandingBill
                props.data.bitCustomerAlertVerbalConfirmationNeeded = alertData.bitCustomerAlertVerbalConfirmationNeeded
                props.data.varCustomerComments = alertData.varCustomerComments

                setCustomerLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setCustomerLoading(false)
                toast.error("Something went wrong when saving new customer!")
            })
    }

    const { auth } = useAuth() as any
    async function saveData() {
        let payload = {
            customer: alertData,
        }
        const result = await axiosPrivate.put("Customer", JSON.stringify(payload), buildHeaderObject(auth))
        return result
    }
    const resetChanges = () => {
        setAlertData({
            bitCustomerAlertAlwaysLate: props.data.bitCustomerAlertAlwaysLate,
            bitCustomerAlertDifficultToHandle: props.data.bitCustomerAlertDifficultToHandle,
            bitCustomerAlertDiscountOnFile: props.data.bitCustomerAlertDiscountOnFile,
            bitCustomerAlertNoShow: props.data.bitCustomerAlertNoShow,
            bitCustomerAlertOutstandingBill: props.data.bitCustomerAlertOutstandingBill,
            bitCustomerAlertVerbalConfirmationNeeded: props.data.bitCustomerAlertVerbalConfirmationNeeded,
            varCustomerComments: props.data.varCustomerComments,
            numCustomerID: props.data.numCustomerID,
        })
    }

    return props.mode ? (
        <div>
            <p style={{ color: "#23262F", fontWeight: "600", fontSize: "14px" }}>Customer alerts</p>
            <p style={{ color: "#777E90", fontWeight: "400", fontSize: "12px", width: "600px" }}>
                Use this feature to show the warning sign ⚠ next to the customer profile. This feature is only visible to internal staff.{" "}
            </p>
            <div>
                <div style={{ display: "flex" }}>
                    <Checkbox
                        style={{ padding: "0 .2rem 0 0" }}
                        onChange={() => {
                            const editState: any = { ...alertData }
                            editState.bitCustomerAlertAlwaysLate = !editState.bitCustomerAlertAlwaysLate
                            setAlertData(editState)
                        }}
                        checked={alertData.bitCustomerAlertAlwaysLate}
                    />
                    <p>Always Late</p>
                </div>
                <div style={{ display: "flex" }}>
                    <Checkbox
                        style={{ padding: "0 .2rem 0 0" }}
                        onChange={() => {
                            const editState: any = { ...alertData }
                            editState.bitCustomerAlertDifficultToHandle = !editState.bitCustomerAlertDifficultToHandle
                            setAlertData(editState)
                        }}
                        checked={alertData.bitCustomerAlertDifficultToHandle}
                    />
                    <p>Difficult To Handle</p>
                </div>

                <div style={{ display: "flex" }}>
                    <Checkbox
                        style={{ padding: "0 .2rem 0 0" }}
                        onChange={() => {
                            const editState: any = { ...alertData }
                            editState.bitCustomerAlertDiscountOnFile = !editState.bitCustomerAlertDiscountOnFile
                            setAlertData(editState)
                        }}
                        checked={alertData.bitCustomerAlertDiscountOnFile}
                    />
                    <p>Discount On File</p>
                </div>
                <div style={{ display: "flex" }}>
                    <Checkbox
                        style={{ padding: "0 .2rem 0 0" }}
                        onChange={() => {
                            const editState: any = { ...alertData }
                            editState.bitCustomerAlertNoShow = !editState.bitCustomerAlertNoShow
                            setAlertData(editState)
                        }}
                        checked={alertData.bitCustomerAlertNoShow}
                    />
                    <p>No Shows</p>
                </div>

                <div style={{ display: "flex" }}>
                    <Checkbox
                        style={{ padding: "0 .2rem 0 0" }}
                        onChange={() => {
                            const editState: any = { ...alertData }
                            editState.bitCustomerAlertOutstandingBill = !editState.bitCustomerAlertOutstandingBill
                            setAlertData(editState)
                        }}
                        checked={alertData.bitCustomerAlertOutstandingBill}
                    />
                    <p>Outstanding Bill</p>
                </div>
                <div style={{ display: "flex" }}>
                    <Checkbox
                        style={{ padding: "0 .2rem 0 0" }}
                        onChange={() => {
                            const editState: any = { ...alertData }
                            editState.bitCustomerAlertVerbalConfirmationNeeded = !editState.bitCustomerAlertVerbalConfirmationNeeded
                            setAlertData(editState)
                        }}
                        checked={alertData.bitCustomerAlertVerbalConfirmationNeeded}
                    />
                    <p>Verbal Confirmation Needed</p>
                </div>
            </div>

            <div style={{ paddingTop: "1rem", marginBottom: "1.5rem", borderBottom: "1px solid #F3F4F6" }}></div>

            <p style={{ color: "#23262F", fontWeight: "600", fontSize: "14px" }}>Customer comments</p>
            <p style={{ color: "#777E90", fontWeight: "400", fontSize: "12px", width: "600px" }}>This feature is only visible to the internal staff.</p>

            {alertData.varCustomerComments === null ? (
                <p>No comments found.</p>
            ) : (
                <div style={{ padding: "1rem 1.5rem", border: "1px solid #F3F4F6", borderRadius: "4px", gap: ".5rem", display: "flex", flexDirection: "column" }}>
                    <p style={{ fontWeight: "500", fontSize: "14px", lineHeight: "20px" }}>{alertData.varCustomerComments}</p>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
                            <Avatar
                                img={props.data?.varCustomerImagePath === null ? "" : `https://pet-tech-api.s3.amazonaws.com/directories/Customer/${props.data?.varCustomerImagePath}`}
                                className={"small"}
                                isVip={props.data?.bitIsVip}
                            />
                            <p>
                                {props.data?.varCustomerFirstName} {props.data?.varCustomerLastName}
                            </p>
                        </div>
                        <p
                            style={{ color: "#EF4444", fontSize: "14px", fontWeight: "400", textDecoration: "underline", cursor: "pointer" }}
                            onClick={() => {
                                const editState: any = { ...alertData }
                                editState.varCustomerComments = null
                                setAlertData(editState)
                            }}
                        >
                            Remove
                        </p>
                    </div>
                </div>
            )}
            {alertData.varCustomerComments === null ? (
                <p
                    style={{ color: "#005DF1", fontSize: "14px", fontWeight: "400", textDecoration: "underline", cursor: "pointer", marginTop: "2rem" }}
                    onClick={() => {
                        setShowAdd(true)
                    }}
                >
                    Add New Comment
                </p>
            ) : null}

            <div style={{ textAlign: "end", width: "100%", backgroundColor: "#EFF5FF", padding: "1rem", marginTop: "3rem" }}>
                <Button variant="text" color="transparent" onClick={resetChanges}>
                    Cancel
                </Button>
                <Button variant="contained" color="primary" loading={customerLoading} onClick={submitChanges}>
                    Save
                </Button>
            </div>
            {showAdd ? (
                <Modal open={showAdd}>
                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem", padding: ".5rem 1.5rem" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                            <p style={{ color: "#002867", fontSize: "18px", fontWeight: "600" }}>Add new comment</p>
                            <CloseButton onClick={() => setShowAdd(false)} />
                        </div>
                        <div style={{ backgroundColor: "#fff", width: "400px" }}>
                            <div style={{ display: "flex", gap: "1.5rem", flexDirection: "column", padding: "0 0 .8rem 0" }}>
                                <TextField
                                    multiline={true}
                                    placeholder="Comment"
                                    style={{ width: "100%" }}
                                    onChange={(e) => {
                                        setComment(e.target.value)
                                    }}
                                    variant={"filled"}
                                />
                            </div>
                        </div>
                        <div style={{ textAlign: "end", width: "100%" }}>
                            <Button variant="contained" onClick={handleSubmit} color="primary">
                                Save
                            </Button>
                        </div>
                    </div>
                </Modal>
            ) : null}
        </div>
    ) : (
        <div>
            <p style={{ color: "#23262F", fontWeight: "600", fontSize: "14px" }}>Customer alerts</p>
            <p style={{ color: "#777E90", fontWeight: "400", fontSize: "12px", width: "600px" }}>
                Use this feature to show the warning sign ⚠ next to the customer profile. This feature is only visible to internal staff.{" "}
            </p>

            <div style={{ display: "flex", gap: "0.8rem", flexWrap: "wrap", paddingTop: "10px" }}>
                {alertData.bitCustomerAlertAlwaysLate ? (
                    <div>
                        <Chip size="small" color={"error"} label={"Always Late"} />
                    </div>
                ) : null}
                {alertData.bitCustomerAlertDifficultToHandle ? (
                    <div>
                        <Chip size="small" color={"error"} label={"Difficult To Handle"} />
                    </div>
                ) : null}
                {alertData.bitCustomerAlertDiscountOnFile ? (
                    <div>
                        <Chip size="small" color={"error"} label={"Discount On File"} />
                    </div>
                ) : null}
                {alertData.bitCustomerAlertNoShow ? (
                    <div>
                        <Chip size="small" color={"error"} label={"No Shows"} />
                    </div>
                ) : null}
                {alertData.bitCustomerAlertOutstandingBill ? (
                    <div>
                        <Chip size="small" color={"error"} label={"Outstanding Bill"} />
                    </div>
                ) : null}
                {alertData.bitCustomerAlertVerbalConfirmationNeeded ? (
                    <div>
                        <Chip size="small" color={"error"} label={"Verbal Confirmation Needed"} />
                    </div>
                ) : null}
            </div>

            <div style={{ paddingTop: "1rem", marginBottom: "1.5rem", borderBottom: "1px solid #F3F4F6" }}></div>

            <p style={{ color: "#23262F", fontWeight: "600", fontSize: "14px" }}>Customer comments</p>
            <p style={{ color: "#777E90", fontWeight: "400", fontSize: "12px", width: "600px" }}>This feature is only visible to the internal staff.</p>

            {alertData.varCustomerComments === null ? (
                <p>No comments found.</p>
            ) : (
                <div style={{ padding: "1rem 1.5rem", border: "1px solid #F3F4F6", borderRadius: "4px", gap: ".5rem", display: "flex", flexDirection: "column" }}>
                    <p style={{ fontWeight: "500", fontSize: "14px", lineHeight: "20px" }}>{alertData.varCustomerComments}</p>
                    <div style={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
                        <Avatar
                            img={props.data?.varCustomerImagePath === null ? "" : `https://pet-tech-api.s3.amazonaws.com/directories/Customer/${props.data?.varCustomerImagePath}`}
                            className={"small"}
                            isVip={props.data?.bitIsVip}
                        />
                        <p>
                            {props.data?.varCustomerFirstName} {props.data?.varCustomerLastName}
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AlertsAndComments
