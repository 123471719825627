import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
  TextField,
  Button,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import Swal from "sweetalert2";
import { axiosPrivate, buildHeaderObject } from "../../apis/axios";
import useAuth from "../../hooks/useAuth";
import { toast } from "react-toastify";

const ActiveUserSessions = () => {
  const [data, setData] = useState([
  ]) as any;

  const { auth, setAuth } = useAuth() as any
  const getActiveUsers= async () => {
      try {
          const response = await axiosPrivate.get(`Auth/users-sessions`, buildHeaderObject(auth))
          return response.data
      } catch (error) {
          console.error(error)
          throw error
      }
  }

  useEffect(()=>{
    getActiveUsers()
    .then((appData) => {
        console.log("ActiveUsers", appData)
        setData(appData)

    })
    .catch((error) => {
        console.log("error", error)
    })

  },[])

  const [openRows, setOpenRows] = useState({});
  const [search, setSearch] = useState("");

  const handleToggleRow = (userId) => {
    setOpenRows((prev) => ({
      ...prev,
      [userId]: !prev[userId],
    }));
  };

  const handleLogoutAll = async (userId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "This will log out all sessions for this user.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, log out all!",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {

      removeUserfromAll({userId : userId}, userId)
    }
  };

      async function removeUserfromAll(data, id) {
          await axiosPrivate
              .post(`Auth/logout-all`, JSON.stringify(data), buildHeaderObject(auth))
              .then((result) => {
                  if (result.status == 200) {
                    setData((prevData) =>
                      prevData.map((user) =>
                        user.userId === id ? { ...user, activeSessions: [] } : user
                      )
                    );
                    Swal.fire("Logged Out", "All sessions have been logged out.", "success");
                  }
              })
              .catch((err) => {
                  console.log(err)
                  toast.error("Something went wrong!")
              })
      }

  const handleLogoutSession = async (userId, tokenId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: `This will log out session ${tokenId}.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, log out!",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      setData((prevData) =>
        prevData.map((user) =>
          user.userId === userId
            ? {
                ...user,
                activeSessions: user.activeSessions.filter(
                  (session) => session.tokenId !== tokenId
                ),
              }
            : user
        )
      );
      Swal.fire("Logged Out", `Session ${tokenId} has been logged out.`, "success");
    }
  };

  const filteredData = data.filter((user) =>
    user.userName.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Paper elevation={2} 
    style={{
      padding: 16,
      marginBottom: 16,
      boxShadow: "none", 
    }}>
      <Typography style={{ marginBottom: 30, fontSize:"1.5rem", fontWeight:"600" }}>
        User Active Sessions
      </Typography>

      <Box display="flex" alignItems="center" marginBottom={2}>
        <TextField
          label="Search by Username"
          variant="outlined"
          fullWidth
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ marginRight: 16, width:"500px" }}
        />
      </Box>

      <TableContainer>
        <Table style={{ borderCollapse: "separate", borderSpacing: 0 }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ borderBottom: "none" }} />
              <TableCell style={{ borderBottom: "none" }}>Username</TableCell>
              <TableCell style={{ borderBottom: "none" }}>
                Number of Active Sessions
              </TableCell>
              <TableCell style={{ borderBottom: "none" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((user) => (
              <React.Fragment key={user.userId}>
                <TableRow>
                  <TableCell style={{ borderBottom: "none" }}>
                    {user.activeSessions.length > 0 && (
                      <IconButton
                        onClick={() => handleToggleRow(user.userId)}
                      >
                        {openRows[user.userId] ? (
                          <KeyboardArrowUp />
                        ) : (
                          <KeyboardArrowDown />
                        )}
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }}>
                    {user.userName}
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }}>
                    {user.activeSessions.length}
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleLogoutAll(user.userId)}
                      disabled={user.activeSessions.length === 0}
                    >
                      Logout from All
                    </Button>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={4} style={{ padding: 0, borderBottom: "none" }}>
                    <Collapse
                      in={openRows[user.userId]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Table size="small">
                        <TableBody>
                          {user.activeSessions.map((session) => (
                            <TableRow key={session.tokenId}>
                              <TableCell>{session.tokenId}</TableCell>
                              <TableCell>{session.created}</TableCell>
                              <TableCell>{session.expires}</TableCell>
                              <TableCell>{session.platform}</TableCell>
                              <TableCell>{session.browser}</TableCell>
                              <TableCell>
                                <Button
                                  variant="outlined"
                                  color="error"
                                  disabled={true}
                                  onClick={() =>
                                    handleLogoutSession(user.userId, session.tokenId)
                                  }
                                >
                                  Logout
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ActiveUserSessions;
