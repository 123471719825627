import { Button } from "../../../components"
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import useAuth from "../../../hooks/useAuth"
import { Avatar } from "../../../components"
import AdvancedDropDown from "../../../components/AdvancedDropDown/AdvancedDropDown"
import styles from "../Login.module.scss"
import { axiosPrivate, buildHeaderObject } from "../../../apis/axios"
import { ToastContainer, toast } from "react-toastify"
import { CircularProgress } from "@mui/material"

const Welcome = (props) => {
    const navigate = useNavigate()

    const [visited, setVisited] = useState(false)
    const [loading, setLoading] = useState(false)

    const { auth, setAuth } = useAuth() as any

    const [locationList, setLocationList] = useState([]) as any
    const locationRef = useRef<any>()

    const [roleList, setRoleList] = useState([]) as any
    const roleRef = useRef<any>()

    const [location, setLocation] = useState(0)
    const [role, setRole] = useState(0)
    const [rolesSetup, setRolesSetup] = useState(false)

    const handleNavigate = () => {
        if (location > 0) {
            if (rolesSetup) {
                navigate("/", { replace: true })
            } else {
                toast.error("You do not have Roles assigned to you yet for this location! Contact Administrator.")
            }
        } else {
            toast.info("Please select a location to continue.")
        }
    }

    useEffect(() => {
        if (locationList?.length === 1 && locationList[0]?.location?.LocationID > 0) {
            setAuth((prev) => {
                return { ...prev, currentLocation: { label: locationList[0]?.location?.Name, value: locationList[0]?.location?.LocationID } }
            })
            setLocation(locationList[0]?.location?.LocationID)
        }
    }, [locationList])

    // Construct Tree V2 - Returns ALL OBJs
    const constructTree = (unsortedList) => {
        const transformedData = unsortedList.reduce((acc, curr) => {
            if (!acc[curr.varLocationName]) {
                acc[curr.varLocationName] = {
                    location: {
                        LocationID: curr.LocationID,
                        Name: curr.varLocationName,
                    },
                    groups: {},
                }
            }

            if (!acc[curr.varLocationName].groups[curr.numUserRoleGroupID]) {
                acc[curr.varLocationName].groups[curr.numUserRoleGroupID] = {
                    numUserRoleGroupID: curr.numUserRoleGroupID,
                    varUserRoleGroupName: curr.varUserRoleGroupName,
                    roles: {},
                }
            }

            if (!acc[curr.varLocationName].groups[curr.numUserRoleGroupID].roles[curr.numUserRoleID]) {
                acc[curr.varLocationName].groups[curr.numUserRoleGroupID].roles[curr.numUserRoleID] = {
                    numUserRoleID: curr.numUserRoleID,
                    varUserRoleName: curr.varUserRoleName,
                    IsActive: curr.IsActive,
                    modules: {},
                }
            }

            if (!acc[curr.varLocationName].groups[curr.numUserRoleGroupID].roles[curr.numUserRoleID].modules[curr.ModuleID]) {
                acc[curr.varLocationName].groups[curr.numUserRoleGroupID].roles[curr.numUserRoleID].modules[curr.ModuleID] = {
                    ModuleID: curr.ModuleID,
                    ModuleName: curr.ModuleName,
                    systemmodules: {},
                }
            }

            acc[curr.varLocationName].groups[curr.numUserRoleGroupID].roles[curr.numUserRoleID].modules[curr.ModuleID].systemmodules[curr.SystemModuleListID] = {
                SystemModuleListID: curr.SystemModuleListID,
                SystemModuleListName: curr.SystemModuleListName,
                settings: {
                    ID: curr.ID,
                    IsView: curr.IsView,
                    IsUpdate: curr.IsUpdate,
                    IsDelete: curr.IsDelete,
                    varEmployeeFirstName: curr.varEmployeeFirstName,
                    varEmployeeLastName: curr.varEmployeeLastName,
                },
            }

            return acc
        }, {})

        return transformedData
    }

    useEffect(() => {
        const hasVisited = localStorage.getItem("hasVisitedWelcome")

        if (hasVisited) {
            navigate("/booking", { replace: true })
        } else {
            localStorage.setItem("hasVisitedWelcome", "true")
            setVisited(true)
        }
    }, [navigate])

    async function getPermissions(employeeID) {
        console.log("emp id", employeeID)

        const result = await axiosPrivate.get(`User/GetAllUserPermissionsByEmpIDandLocID?numLocationID=&numEmployeeID=${employeeID}`, buildHeaderObject(auth))
        console.log("superuser", result)
        return result
    }

    const fetchPermissionObject = (employeeID) => {
        getPermissions(employeeID)
            .then((response) => {
                if (response?.data.length > 0) {
                    let permissionTree = constructTree(response?.data)
                    let constructLocations = [] as any

                    Object.keys(permissionTree)?.map((permission) => {
                        constructLocations.push(permissionTree[permission])
                    })

                    console.log("constructLocations", constructLocations)

                    setLocationList(constructLocations)
                    setRolesSetup(true)
                } else {
                    setRolesSetup(false)
                    toast.error("You do not have Roles assigned to you yet! Contact Administrator.")
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        setLoading(true)

        if (auth?.employee?.numEmployeeID === parseInt(`${process.env.REACT_APP_SUPERADMIN_ID}`)) {
            setLocationList([
                {
                    location: {
                        LocationID: 1,
                        Name: "Houston - ESyPet Pet Grooming",
                    },
                    groups: {
                        "16": {
                            numUserRoleGroupID: 16,
                            varUserRoleGroupName: "Administrators",
                            roles: {
                                "1": {
                                    numUserRoleID: 1,
                                    varUserRoleName: "System Admins",
                                    IsActive: true,
                                    modules: {
                                        "1": {
                                            ModuleID: 1,
                                            ModuleName: "01 - Dashboard",
                                            systemmodules: {
                                                "1": {
                                                    SystemModuleListID: 1,
                                                    SystemModuleListName: "Dashboard",
                                                    settings: {
                                                        ID: 1,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "10": {
                                            ModuleID: 10,
                                            ModuleName: "02 - Schedule",
                                            systemmodules: {
                                                "2": {
                                                    SystemModuleListID: 2,
                                                    SystemModuleListName: "Add New Customer",
                                                    settings: {
                                                        ID: 298,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "3": {
                                                    SystemModuleListID: 3,
                                                    SystemModuleListName: "Add New Pet",
                                                    settings: {
                                                        ID: 299,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "11": {
                                            ModuleID: 11,
                                            ModuleName: "05 - Groomer",
                                            systemmodules: {
                                                "57": {
                                                    SystemModuleListID: 57,
                                                    SystemModuleListName: "Groomer",
                                                    settings: {
                                                        ID: 43,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "13": {
                                            ModuleID: 13,
                                            ModuleName: "06 - Reporting",
                                            systemmodules: {
                                                "58": {
                                                    SystemModuleListID: 58,
                                                    SystemModuleListName: "Reporting",
                                                    settings: {
                                                        ID: 44,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "14": {
                                            ModuleID: 14,
                                            ModuleName: "07.01 - Settings / Label Configuration",
                                            systemmodules: {
                                                "51": {
                                                    SystemModuleListID: 51,
                                                    SystemModuleListName: "Salon",
                                                    settings: {
                                                        ID: 49,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "52": {
                                                    SystemModuleListID: 52,
                                                    SystemModuleListName: "Pet",
                                                    settings: {
                                                        ID: 48,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "53": {
                                                    SystemModuleListID: 53,
                                                    SystemModuleListName: "Customer",
                                                    settings: {
                                                        ID: 46,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "54": {
                                                    SystemModuleListID: 54,
                                                    SystemModuleListName: "Employee",
                                                    settings: {
                                                        ID: 47,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "15": {
                                            ModuleID: 15,
                                            ModuleName: "03.01 - Directory / Customer",
                                            systemmodules: {
                                                "22": {
                                                    SystemModuleListID: 22,
                                                    SystemModuleListName: "Download XLSX",
                                                    settings: {
                                                        ID: 24,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "23": {
                                                    SystemModuleListID: 23,
                                                    SystemModuleListName: "Quick Message",
                                                    settings: {
                                                        ID: 25,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "24": {
                                                    SystemModuleListID: 24,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 23,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "16": {
                                            ModuleID: 16,
                                            ModuleName: "03.02 - Directory / Pet",
                                            systemmodules: {
                                                "25": {
                                                    SystemModuleListID: 25,
                                                    SystemModuleListName: "Download XLSX",
                                                    settings: {
                                                        ID: 27,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "26": {
                                                    SystemModuleListID: 26,
                                                    SystemModuleListName: "Quick Message",
                                                    settings: {
                                                        ID: 28,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "27": {
                                                    SystemModuleListID: 27,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 26,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "17": {
                                            ModuleID: 17,
                                            ModuleName: "03.03 - Directory / Salon",
                                            systemmodules: {
                                                "28": {
                                                    SystemModuleListID: 28,
                                                    SystemModuleListName: "Download XLSX",
                                                    settings: {
                                                        ID: 30,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "29": {
                                                    SystemModuleListID: 29,
                                                    SystemModuleListName: "Quick Message",
                                                    settings: {
                                                        ID: 32,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "30": {
                                                    SystemModuleListID: 30,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 29,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "31": {
                                                    SystemModuleListID: 31,
                                                    SystemModuleListName: "Map View",
                                                    settings: {
                                                        ID: 31,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "211": {
                                                    SystemModuleListID: 211,
                                                    SystemModuleListName: "New Salon",
                                                    settings: {
                                                        ID: 1298,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "18": {
                                            ModuleID: 18,
                                            ModuleName: "03.04 - Directory / Employee",
                                            systemmodules: {
                                                "32": {
                                                    SystemModuleListID: 32,
                                                    SystemModuleListName: "Download XLSX",
                                                    settings: {
                                                        ID: 34,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "33": {
                                                    SystemModuleListID: 33,
                                                    SystemModuleListName: "Quick Message",
                                                    settings: {
                                                        ID: 36,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "34": {
                                                    SystemModuleListID: 34,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 33,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "35": {
                                                    SystemModuleListID: 35,
                                                    SystemModuleListName: "New Employee",
                                                    settings: {
                                                        ID: 35,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "19": {
                                            ModuleID: 19,
                                            ModuleName: "03.05 - Directory / Appointments",
                                            systemmodules: {
                                                "36": {
                                                    SystemModuleListID: 36,
                                                    SystemModuleListName: "Download XLSX",
                                                    settings: {
                                                        ID: 38,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "37": {
                                                    SystemModuleListID: 37,
                                                    SystemModuleListName: "Quick Message",
                                                    settings: {
                                                        ID: 39,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "38": {
                                                    SystemModuleListID: 38,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 37,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "20": {
                                            ModuleID: 20,
                                            ModuleName: "03.06 - Directory / Booking Inquiry",
                                            systemmodules: {
                                                "56": {
                                                    SystemModuleListID: 56,
                                                    SystemModuleListName: "Calendar",
                                                    settings: {
                                                        ID: 40,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "21": {
                                            ModuleID: 21,
                                            ModuleName: "04 - Inquiry",
                                            systemmodules: {
                                                "40": {
                                                    SystemModuleListID: 40,
                                                    SystemModuleListName: "Inquiry",
                                                    settings: {
                                                        ID: 42,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "22": {
                                            ModuleID: 22,
                                            ModuleName: "02.01 - Schedule / New Booking",
                                            systemmodules: {
                                                "4": {
                                                    SystemModuleListID: 4,
                                                    SystemModuleListName: "View Map",
                                                    settings: {
                                                        ID: 8,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "5": {
                                                    SystemModuleListID: 5,
                                                    SystemModuleListName: "Add/Edit Services",
                                                    settings: {
                                                        ID: 5,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "6": {
                                                    SystemModuleListID: 6,
                                                    SystemModuleListName: "Add/Edit Products",
                                                    settings: {
                                                        ID: 4,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "7": {
                                                    SystemModuleListID: 7,
                                                    SystemModuleListName: "Customer Invoices",
                                                    settings: {
                                                        ID: 6,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "8": {
                                                    SystemModuleListID: 8,
                                                    SystemModuleListName: "Make Reservation",
                                                    settings: {
                                                        ID: 7,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "23": {
                                            ModuleID: 23,
                                            ModuleName: "02.02 - Schedule / Customer Profile",
                                            systemmodules: {
                                                "9": {
                                                    SystemModuleListID: 9,
                                                    SystemModuleListName: "Edit Customer",
                                                    settings: {
                                                        ID: 11,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "10": {
                                                    SystemModuleListID: 10,
                                                    SystemModuleListName: "Personal Details",
                                                    settings: {
                                                        ID: 16,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "11": {
                                                    SystemModuleListID: 11,
                                                    SystemModuleListName: "Alerts and comments",
                                                    settings: {
                                                        ID: 10,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "12": {
                                                    SystemModuleListID: 12,
                                                    SystemModuleListName: "Linked Accounts",
                                                    settings: {
                                                        ID: 13,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "13": {
                                                    SystemModuleListID: 13,
                                                    SystemModuleListName: "Payment Details",
                                                    settings: {
                                                        ID: 15,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "14": {
                                                    SystemModuleListID: 14,
                                                    SystemModuleListName: "Account Settings",
                                                    settings: {
                                                        ID: 9,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "15": {
                                                    SystemModuleListID: 15,
                                                    SystemModuleListName: "Notifications",
                                                    settings: {
                                                        ID: 14,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "16": {
                                                    SystemModuleListID: 16,
                                                    SystemModuleListName: "Invoices",
                                                    settings: {
                                                        ID: 12,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "24": {
                                            ModuleID: 24,
                                            ModuleName: "02.03 - Schedule / Pet Profile",
                                            systemmodules: {
                                                "17": {
                                                    SystemModuleListID: 17,
                                                    SystemModuleListName: "Edit Pet",
                                                    settings: {
                                                        ID: 17,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "18": {
                                                    SystemModuleListID: 18,
                                                    SystemModuleListName: "Personal Details",
                                                    settings: {
                                                        ID: 20,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "19": {
                                                    SystemModuleListID: 19,
                                                    SystemModuleListName: "Medical / Vaccination",
                                                    settings: {
                                                        ID: 18,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "20": {
                                                    SystemModuleListID: 20,
                                                    SystemModuleListName: "Ownership Transfer",
                                                    settings: {
                                                        ID: 19,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "21": {
                                                    SystemModuleListID: 21,
                                                    SystemModuleListName: "Preferences",
                                                    settings: {
                                                        ID: 21,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "25": {
                                            ModuleID: 25,
                                            ModuleName: "07.02 - Settings / Import - Export Data",
                                            systemmodules: {
                                                "41": {
                                                    SystemModuleListID: 41,
                                                    SystemModuleListName: "Salon",
                                                    settings: {
                                                        ID: 53,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "42": {
                                                    SystemModuleListID: 42,
                                                    SystemModuleListName: "Pet",
                                                    settings: {
                                                        ID: 52,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "43": {
                                                    SystemModuleListID: 43,
                                                    SystemModuleListName: "Customer",
                                                    settings: {
                                                        ID: 50,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "44": {
                                                    SystemModuleListID: 44,
                                                    SystemModuleListName: "Employee",
                                                    settings: {
                                                        ID: 51,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "26": {
                                            ModuleID: 26,
                                            ModuleName: "07.03 - Settings / Dir - Lib Data Reset",
                                            systemmodules: {
                                                "45": {
                                                    SystemModuleListID: 45,
                                                    SystemModuleListName: "Directories",
                                                    settings: {
                                                        ID: 54,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "46": {
                                                    SystemModuleListID: 46,
                                                    SystemModuleListName: "Libraries",
                                                    settings: {
                                                        ID: 55,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "27": {
                                            ModuleID: 27,
                                            ModuleName: "07.04 - Settings / Library",
                                            systemmodules: {
                                                "60": {
                                                    SystemModuleListID: 60,
                                                    SystemModuleListName: "Library",
                                                    settings: {
                                                        ID: 60,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "28": {
                                            ModuleID: 28,
                                            ModuleName: "07.05 - Settings / User Roles",
                                            systemmodules: {
                                                "61": {
                                                    SystemModuleListID: 61,
                                                    SystemModuleListName: "User Roles",
                                                    settings: {
                                                        ID: 61,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "29": {
                                            ModuleID: 29,
                                            ModuleName: "07.06 - Settings / Scheduling",
                                            systemmodules: {
                                                "47": {
                                                    SystemModuleListID: 47,
                                                    SystemModuleListName: "Events",
                                                    settings: {
                                                        ID: 56,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "48": {
                                                    SystemModuleListID: 48,
                                                    SystemModuleListName: "Schedule",
                                                    settings: {
                                                        ID: 57,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "30": {
                                            ModuleID: 30,
                                            ModuleName: "07.07 - Settings / Messaging",
                                            systemmodules: {
                                                "49": {
                                                    SystemModuleListID: 49,
                                                    SystemModuleListName: "Create New Template",
                                                    settings: {
                                                        ID: 58,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "50": {
                                                    SystemModuleListID: 50,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 59,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "34": {
                                            ModuleID: 34,
                                            ModuleName: "03 - Directory",
                                            systemmodules: {
                                                "55": {
                                                    SystemModuleListID: 55,
                                                    SystemModuleListName: "Directory",
                                                    settings: {
                                                        ID: 22,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "35": {
                                            ModuleID: 35,
                                            ModuleName: "07 - Setting",
                                            systemmodules: {
                                                "59": {
                                                    SystemModuleListID: 59,
                                                    SystemModuleListName: "Setting",
                                                    settings: {
                                                        ID: 45,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "36": {
                                            ModuleID: 36,
                                            ModuleName: "03.07 - Directory / New Booking Inquiry",
                                            systemmodules: {
                                                "39": {
                                                    SystemModuleListID: 39,
                                                    SystemModuleListName: "Map View",
                                                    settings: {
                                                        ID: 41,
                                                        IsView: true,
                                                        IsUpdate: true,
                                                        IsDelete: true,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "37": {
                                            ModuleID: 37,
                                            ModuleName: "07.04.01 - Settings / Library / 1.1 - Pet Types",
                                            systemmodules: {
                                                "91": {
                                                    SystemModuleListID: 91,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 99,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "92": {
                                                    SystemModuleListID: 92,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 101,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "93": {
                                                    SystemModuleListID: 93,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 102,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "94": {
                                                    SystemModuleListID: 94,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 100,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "38": {
                                            ModuleID: 38,
                                            ModuleName: "07.04.02 - Settings / Library / 1.2 - Pet Sizes",
                                            systemmodules: {
                                                "95": {
                                                    SystemModuleListID: 95,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 103,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "96": {
                                                    SystemModuleListID: 96,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 105,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "97": {
                                                    SystemModuleListID: 97,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 106,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "98": {
                                                    SystemModuleListID: 98,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 104,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "39": {
                                            ModuleID: 39,
                                            ModuleName: "07.04.03 - Settings / Library / 1.3 - Pet Behaviors",
                                            systemmodules: {
                                                "99": {
                                                    SystemModuleListID: 99,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 107,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "100": {
                                                    SystemModuleListID: 100,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 109,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "101": {
                                                    SystemModuleListID: 101,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 110,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "102": {
                                                    SystemModuleListID: 102,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 108,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "40": {
                                            ModuleID: 40,
                                            ModuleName: "07.04.04 - Settings / Library / 1.4 - Pet Colors",
                                            systemmodules: {
                                                "103": {
                                                    SystemModuleListID: 103,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 111,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "104": {
                                                    SystemModuleListID: 104,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 113,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "105": {
                                                    SystemModuleListID: 105,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 114,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "106": {
                                                    SystemModuleListID: 106,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 112,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "41": {
                                            ModuleID: 41,
                                            ModuleName: "07.04.05 - Settings / Library / 1.5 - Pet Health Concerns",
                                            systemmodules: {
                                                "107": {
                                                    SystemModuleListID: 107,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 115,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "108": {
                                                    SystemModuleListID: 108,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 117,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "109": {
                                                    SystemModuleListID: 109,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 118,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "110": {
                                                    SystemModuleListID: 110,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 116,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "42": {
                                            ModuleID: 42,
                                            ModuleName: "07.04.06 - Settings / Library / 1.6 - Pet Grooming Styles",
                                            systemmodules: {
                                                "111": {
                                                    SystemModuleListID: 111,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 131,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "112": {
                                                    SystemModuleListID: 112,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 133,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "113": {
                                                    SystemModuleListID: 113,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 134,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "114": {
                                                    SystemModuleListID: 114,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 132,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "43": {
                                            ModuleID: 43,
                                            ModuleName: "07.04.07 - Settings / Library / 1.7 - Pure Breeds",
                                            systemmodules: {
                                                "115": {
                                                    SystemModuleListID: 115,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 135,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "116": {
                                                    SystemModuleListID: 116,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 137,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "117": {
                                                    SystemModuleListID: 117,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 138,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "118": {
                                                    SystemModuleListID: 118,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 136,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "44": {
                                            ModuleID: 44,
                                            ModuleName: "07.04.08 - Settings / Library / 1.8 - All Breeds (Pure + Mixed)",
                                            systemmodules: {
                                                "119": {
                                                    SystemModuleListID: 119,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 139,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "120": {
                                                    SystemModuleListID: 120,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 141,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "121": {
                                                    SystemModuleListID: 121,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 142,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "122": {
                                                    SystemModuleListID: 122,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 140,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "45": {
                                            ModuleID: 45,
                                            ModuleName: "07.04.09 - Settings / Library / 2.1 - Address Types",
                                            systemmodules: {
                                                "123": {
                                                    SystemModuleListID: 123,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 143,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "124": {
                                                    SystemModuleListID: 124,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 145,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "125": {
                                                    SystemModuleListID: 125,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 146,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "126": {
                                                    SystemModuleListID: 126,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 144,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "46": {
                                            ModuleID: 46,
                                            ModuleName: "07.04.10 - Settings / Library / 2.2 - Contact Types",
                                            systemmodules: {
                                                "127": {
                                                    SystemModuleListID: 127,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 147,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "128": {
                                                    SystemModuleListID: 128,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 149,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "129": {
                                                    SystemModuleListID: 129,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 150,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "130": {
                                                    SystemModuleListID: 130,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 148,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "47": {
                                            ModuleID: 47,
                                            ModuleName: "07.04.11 - Settings / Library / 2.3 - Salutations",
                                            systemmodules: {
                                                "131": {
                                                    SystemModuleListID: 131,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 151,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "132": {
                                                    SystemModuleListID: 132,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 153,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "133": {
                                                    SystemModuleListID: 133,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 154,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "134": {
                                                    SystemModuleListID: 134,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 152,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "48": {
                                            ModuleID: 48,
                                            ModuleName: "07.04.12 - Settings / Library / 2.4 - Contact Person Type",
                                            systemmodules: {
                                                "135": {
                                                    SystemModuleListID: 135,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 155,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "136": {
                                                    SystemModuleListID: 136,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 157,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "137": {
                                                    SystemModuleListID: 137,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 158,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "138": {
                                                    SystemModuleListID: 138,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 156,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "49": {
                                            ModuleID: 49,
                                            ModuleName: "07.04.13 - Settings / Library / 2.5 - Security Questions",
                                            systemmodules: {
                                                "139": {
                                                    SystemModuleListID: 139,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 159,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "140": {
                                                    SystemModuleListID: 140,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 161,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "141": {
                                                    SystemModuleListID: 141,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 162,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "142": {
                                                    SystemModuleListID: 142,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 160,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "50": {
                                            ModuleID: 50,
                                            ModuleName: "07.04.14 - Settings / Library / 2.6 - Zip Codes",
                                            systemmodules: {
                                                "143": {
                                                    SystemModuleListID: 143,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 163,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "144": {
                                                    SystemModuleListID: 144,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 165,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "145": {
                                                    SystemModuleListID: 145,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 166,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "146": {
                                                    SystemModuleListID: 146,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 164,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "51": {
                                            ModuleID: 51,
                                            ModuleName: "07.04.15 - Settings / Library / 3.1 - Departments",
                                            systemmodules: {
                                                "147": {
                                                    SystemModuleListID: 147,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 167,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "148": {
                                                    SystemModuleListID: 148,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 169,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "149": {
                                                    SystemModuleListID: 149,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 170,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "150": {
                                                    SystemModuleListID: 150,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 168,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "52": {
                                            ModuleID: 52,
                                            ModuleName: "07.04.16 - Settings / Library / 3.2 - Job Roles",
                                            systemmodules: {
                                                "151": {
                                                    SystemModuleListID: 151,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 171,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "152": {
                                                    SystemModuleListID: 152,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 173,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "153": {
                                                    SystemModuleListID: 153,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 174,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "154": {
                                                    SystemModuleListID: 154,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 172,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "53": {
                                            ModuleID: 53,
                                            ModuleName: "07.04.17 - Settings / Library / 3.3 - Groomer Specializations",
                                            systemmodules: {
                                                "155": {
                                                    SystemModuleListID: 155,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 175,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "156": {
                                                    SystemModuleListID: 156,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 177,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "157": {
                                                    SystemModuleListID: 157,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 178,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "158": {
                                                    SystemModuleListID: 158,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 176,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "54": {
                                            ModuleID: 54,
                                            ModuleName: "07.04.18 - Settings / Library / 4.1 - Brush Types",
                                            systemmodules: {
                                                "159": {
                                                    SystemModuleListID: 159,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 179,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "160": {
                                                    SystemModuleListID: 160,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 181,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "161": {
                                                    SystemModuleListID: 161,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 182,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "162": {
                                                    SystemModuleListID: 162,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 180,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "55": {
                                            ModuleID: 55,
                                            ModuleName: "07.04.19 - Settings / Library / 4.2 - Blade Sizes",
                                            systemmodules: {
                                                "163": {
                                                    SystemModuleListID: 163,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 183,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "164": {
                                                    SystemModuleListID: 164,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 185,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "165": {
                                                    SystemModuleListID: 165,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 186,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "166": {
                                                    SystemModuleListID: 166,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 184,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "56": {
                                            ModuleID: 56,
                                            ModuleName: "07.04.20 - Settings / Library / 4.3 - Comb Attachment Sizes",
                                            systemmodules: {
                                                "167": {
                                                    SystemModuleListID: 167,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 187,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "168": {
                                                    SystemModuleListID: 168,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 189,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "169": {
                                                    SystemModuleListID: 169,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 190,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "170": {
                                                    SystemModuleListID: 170,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 188,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "57": {
                                            ModuleID: 57,
                                            ModuleName: "07.04.21 - Settings / Library / 4.4 - Shampoo Types",
                                            systemmodules: {
                                                "171": {
                                                    SystemModuleListID: 171,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 191,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "172": {
                                                    SystemModuleListID: 172,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 193,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "173": {
                                                    SystemModuleListID: 173,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 194,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "174": {
                                                    SystemModuleListID: 174,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 192,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "58": {
                                            ModuleID: 58,
                                            ModuleName: "07.04.22 - Settings / Library / 5.1 - Sales Items Library",
                                            systemmodules: {
                                                "175": {
                                                    SystemModuleListID: 175,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 195,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "176": {
                                                    SystemModuleListID: 176,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 197,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "177": {
                                                    SystemModuleListID: 177,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 198,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "178": {
                                                    SystemModuleListID: 178,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 196,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "59": {
                                            ModuleID: 59,
                                            ModuleName: "07.04.23 - Settings / Library / 5.2 - Sales Items",
                                            systemmodules: {
                                                "179": {
                                                    SystemModuleListID: 179,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 199,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "180": {
                                                    SystemModuleListID: 180,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 201,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "181": {
                                                    SystemModuleListID: 181,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 202,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "182": {
                                                    SystemModuleListID: 182,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 200,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "60": {
                                            ModuleID: 60,
                                            ModuleName: "07.04.24 - Settings / Library / 5.3 - Service Items Library",
                                            systemmodules: {
                                                "183": {
                                                    SystemModuleListID: 183,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 203,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "184": {
                                                    SystemModuleListID: 184,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 205,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "185": {
                                                    SystemModuleListID: 185,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 206,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "186": {
                                                    SystemModuleListID: 186,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 204,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "61": {
                                            ModuleID: 61,
                                            ModuleName: "07.04.25 - Settings / Library / 5.4 - Time of Day",
                                            systemmodules: {
                                                "187": {
                                                    SystemModuleListID: 187,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 207,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "188": {
                                                    SystemModuleListID: 188,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 209,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "189": {
                                                    SystemModuleListID: 189,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 210,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "190": {
                                                    SystemModuleListID: 190,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 208,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "62": {
                                            ModuleID: 62,
                                            ModuleName: "07.04.26 - Settings / Library / 5.5 - General Price List",
                                            systemmodules: {
                                                "191": {
                                                    SystemModuleListID: 191,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 211,
                                                        IsView: false,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "192": {
                                                    SystemModuleListID: 192,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 213,
                                                        IsView: false,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "193": {
                                                    SystemModuleListID: 193,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 214,
                                                        IsView: false,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "194": {
                                                    SystemModuleListID: 194,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 212,
                                                        IsView: false,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "63": {
                                            ModuleID: 63,
                                            ModuleName: "07.04.27 - Settings / Library / 6.1 - Vaccinations",
                                            systemmodules: {
                                                "195": {
                                                    SystemModuleListID: 195,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 215,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "196": {
                                                    SystemModuleListID: 196,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 217,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "197": {
                                                    SystemModuleListID: 197,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 218,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "198": {
                                                    SystemModuleListID: 198,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 216,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "64": {
                                            ModuleID: 64,
                                            ModuleName: "07.04.28 - Settings / Library / 6.2 - Vets",
                                            systemmodules: {
                                                "199": {
                                                    SystemModuleListID: 199,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 127,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "200": {
                                                    SystemModuleListID: 200,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 129,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "201": {
                                                    SystemModuleListID: 201,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 130,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "202": {
                                                    SystemModuleListID: 202,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 128,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "65": {
                                            ModuleID: 65,
                                            ModuleName: "07.04.29 - Settings / Library / 6.3 - Waiver Documents",
                                            systemmodules: {
                                                "203": {
                                                    SystemModuleListID: 203,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 119,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "204": {
                                                    SystemModuleListID: 204,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 121,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "205": {
                                                    SystemModuleListID: 205,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 122,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "206": {
                                                    SystemModuleListID: 206,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 120,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "66": {
                                            ModuleID: 66,
                                            ModuleName: "07.04.30 - Settings / Library / 5.6 - Special Price List",
                                            systemmodules: {
                                                "207": {
                                                    SystemModuleListID: 207,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 294,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "208": {
                                                    SystemModuleListID: 208,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 296,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "209": {
                                                    SystemModuleListID: 209,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 297,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "210": {
                                                    SystemModuleListID: 210,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 295,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                        "67": {
                                            ModuleID: 67,
                                            ModuleName: "07.04.31 - Settings / Library / 5.7 - Packages",
                                            systemmodules: {
                                                "212": {
                                                    SystemModuleListID: 212,
                                                    SystemModuleListName: "Add New",
                                                    settings: {
                                                        ID: 1299,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "213": {
                                                    SystemModuleListID: 213,
                                                    SystemModuleListName: "Edit",
                                                    settings: {
                                                        ID: 1301,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "214": {
                                                    SystemModuleListID: 214,
                                                    SystemModuleListName: "Import / Export",
                                                    settings: {
                                                        ID: 1302,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                                "215": {
                                                    SystemModuleListID: 215,
                                                    SystemModuleListName: "Download PDF",
                                                    settings: {
                                                        ID: 1300,
                                                        IsView: true,
                                                        IsUpdate: false,
                                                        IsDelete: false,
                                                        varEmployeeFirstName: "Super",
                                                        varEmployeeLastName: "Admin",
                                                    },
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            ])
            setRolesSetup(true)
        } else {
            fetchPermissionObject(auth?.employee?.numEmployeeID)
        }
    }, [])

    useEffect(() => {
        roleRef.current?.handleClear()

        let makeRoles = [] as any
        let constructSettings = {} as any

        Object.keys(locationList).map((loc) => {
            if (locationList[loc]?.location?.LocationID === location) {
                let firstKey = Object.keys(locationList[loc]?.groups)[0]

                Object.keys(locationList[loc]?.groups[firstKey]?.roles).map((role) => {
                    Object.keys(locationList[loc]?.groups[firstKey]?.roles[role]?.modules).map((mod) => {
                        // console.log("testtt", locationList[loc]?.groups[firstKey]?.roles[role])

                        if (locationList[loc]?.groups[firstKey]?.roles[role]?.IsActive) {
                            let moduleObj = locationList[loc]?.groups[firstKey]?.roles[role]?.modules[mod]

                            if (constructSettings.hasOwnProperty(moduleObj?.ModuleName)) {
                                constructSettings[moduleObj?.ModuleName].push(moduleObj?.systemmodules)
                            } else {
                                constructSettings[moduleObj?.ModuleName] = [moduleObj?.systemmodules]
                            }
                        }
                    })

                    if (locationList[loc]?.groups[firstKey]?.roles[role]?.IsActive) {
                        makeRoles.push({
                            varUserRoleName: locationList[loc]?.groups[firstKey]?.roles[role]?.varUserRoleName,
                            numUserRoleID: locationList[loc]?.groups[firstKey]?.roles[role]?.numUserRoleID,
                        })
                    }
                })
            }
        })

        setRoleList(makeRoles)

        // console.log("settings", constructSettings)
        // console.log("makeRoles", makeRoles)
        let finalSettings = {} as any

        const calculateSetting = (lastObj) => {
            let finalSet = {} as any
            Object.keys(lastObj).map((setting, index) => {
                let lastSetting = {} as any
                Object.keys(lastObj[setting]).map((set) => {
                    // console.log(lastObj[setting][set], index)

                    if (Object.keys(lastSetting).length > 0) {
                        const isView = lastObj[setting][set]["IsView"]
                        const isUpdate = lastObj[setting][set]["IsUpdate"]
                        const isDelete = lastObj[setting][set]["IsDelete"]

                        lastSetting["IsView"] = lastSetting["IsView"] || isView
                        lastSetting["IsUpdate"] = lastSetting["IsUpdate"] || isUpdate
                        lastSetting["IsDelete"] = lastSetting["IsDelete"] || isDelete
                    } else {
                        lastSetting = lastObj[setting][set]
                    }
                })

                finalSet[Object.keys(lastObj)[index]] = lastSetting
            })
            return finalSet
        }

        Object.keys(constructSettings).map((mods, index) => {
            let mainComp = {} as any

            Object.keys(constructSettings[mods]).map((settting) => {
                Object.keys(constructSettings[mods][settting]).map((setKey) => {
                    let settingKey = constructSettings[mods][settting][setKey]

                    if (mainComp.hasOwnProperty(settingKey?.SystemModuleListName)) {
                        mainComp[settingKey?.SystemModuleListName].push(settingKey?.settings)
                    } else {
                        mainComp[settingKey?.SystemModuleListName] = [settingKey?.settings]
                    }
                })
            })

            finalSettings[Object.keys(constructSettings)[index]] = calculateSetting(mainComp)

            // console.log("settings_last", Object.keys(constructSettings)[index], calculateSetting(mainComp))
        })

        console.log("finalSettings", finalSettings)

        if (auth?.employee?.numEmployeeID === parseInt(`${process.env.REACT_APP_SUPERADMIN_ID}`)) {
            const tmpFinalSettings = {
                "01 - Dashboard": {
                    Dashboard: {
                        ID: 1,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "02 - Schedule": {
                    "Add New Customer": {
                        ID: 298,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Add New Pet": {
                        ID: 299,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "05 - Groomer": {
                    Groomer: {
                        ID: 43,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "06 - Reporting": {
                    Reporting: {
                        ID: 44,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.01 - Settings / Label Configuration": {
                    Salon: {
                        ID: 49,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Pet: {
                        ID: 48,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Customer: {
                        ID: 46,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Employee: {
                        ID: 47,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "03.01 - Directory / Customer": {
                    "Download XLSX": {
                        ID: 24,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Quick Message": {
                        ID: 25,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 23,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "03.02 - Directory / Pet": {
                    "Download XLSX": {
                        ID: 27,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Quick Message": {
                        ID: 28,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 26,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "03.03 - Directory / Salon": {
                    "Download XLSX": {
                        ID: 30,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Quick Message": {
                        ID: 32,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 29,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Map View": {
                        ID: 31,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "New Salon": {
                        ID: 1298,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "03.04 - Directory / Employee": {
                    "Download XLSX": {
                        ID: 34,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Quick Message": {
                        ID: 36,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 33,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "New Employee": {
                        ID: 35,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "03.05 - Directory / Appointments": {
                    "Download XLSX": {
                        ID: 38,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Quick Message": {
                        ID: 39,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 37,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "03.06 - Directory / Booking Inquiry": {
                    Calendar: {
                        ID: 40,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "04 - Inquiry": {
                    Inquiry: {
                        ID: 42,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "02.01 - Schedule / New Booking": {
                    "View Map": {
                        ID: 8,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Add/Edit Services": {
                        ID: 5,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Add/Edit Products": {
                        ID: 4,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Customer Invoices": {
                        ID: 6,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Make Reservation": {
                        ID: 7,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "02.02 - Schedule / Customer Profile": {
                    "Edit Customer": {
                        ID: 11,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Personal Details": {
                        ID: 16,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Alerts and comments": {
                        ID: 10,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Linked Accounts": {
                        ID: 13,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Payment Details": {
                        ID: 15,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Account Settings": {
                        ID: 9,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Notifications: {
                        ID: 14,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Invoices: {
                        ID: 12,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "02.03 - Schedule / Pet Profile": {
                    "Edit Pet": {
                        ID: 17,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Personal Details": {
                        ID: 20,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Medical / Vaccination": {
                        ID: 18,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Ownership Transfer": {
                        ID: 19,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Preferences: {
                        ID: 21,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.02 - Settings / Import - Export Data": {
                    Salon: {
                        ID: 53,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Pet: {
                        ID: 52,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Customer: {
                        ID: 50,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Employee: {
                        ID: 51,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.03 - Settings / Dir - Lib Data Reset": {
                    Directories: {
                        ID: 54,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Libraries: {
                        ID: 55,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04 - Settings / Library": {
                    Library: {
                        ID: 60,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.05 - Settings / User Roles": {
                    "User Roles": {
                        ID: 61,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.06 - Settings / Scheduling": {
                    Events: {
                        ID: 56,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Schedule: {
                        ID: 57,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.07 - Settings / Messaging": {
                    "Create New Template": {
                        ID: 58,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 59,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "03 - Directory": {
                    Directory: {
                        ID: 22,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07 - Setting": {
                    Setting: {
                        ID: 45,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "03.07 - Directory / New Booking Inquiry": {
                    "Map View": {
                        ID: 41,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.01 - Settings / Library / 1.1 - Pet Types": {
                    "Add New": {
                        ID: 99,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 101,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 102,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 100,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.02 - Settings / Library / 1.2 - Pet Sizes": {
                    "Add New": {
                        ID: 103,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 105,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 106,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 104,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.03 - Settings / Library / 1.3 - Pet Behaviors": {
                    "Add New": {
                        ID: 107,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 109,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 110,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 108,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.04 - Settings / Library / 1.4 - Pet Colors": {
                    "Add New": {
                        ID: 111,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 113,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 114,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 112,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.05 - Settings / Library / 1.5 - Pet Health Concerns": {
                    "Add New": {
                        ID: 115,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 117,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 118,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 116,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.06 - Settings / Library / 1.6 - Pet Grooming Styles": {
                    "Add New": {
                        ID: 131,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 133,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 134,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 132,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.07 - Settings / Library / 1.7 - Pure Breeds": {
                    "Add New": {
                        ID: 135,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 137,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 138,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 136,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.08 - Settings / Library / 1.8 - All Breeds (Pure + Mixed)": {
                    "Add New": {
                        ID: 139,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 141,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 142,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 140,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.09 - Settings / Library / 2.1 - Address Types": {
                    "Add New": {
                        ID: 143,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 145,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 146,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 144,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.10 - Settings / Library / 2.2 - Contact Types": {
                    "Add New": {
                        ID: 147,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 149,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 150,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 148,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.11 - Settings / Library / 2.3 - Salutations": {
                    "Add New": {
                        ID: 151,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 153,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 154,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 152,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.12 - Settings / Library / 2.4 - Contact Person Type": {
                    "Add New": {
                        ID: 155,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 157,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 158,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 156,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.13 - Settings / Library / 2.5 - Security Questions": {
                    "Add New": {
                        ID: 159,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 161,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 162,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 160,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.14 - Settings / Library / 2.6 - Zip Codes": {
                    "Add New": {
                        ID: 163,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 165,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 166,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 164,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.15 - Settings / Library / 3.1 - Departments": {
                    "Add New": {
                        ID: 167,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 169,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 170,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 168,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.16 - Settings / Library / 3.2 - Job Roles": {
                    "Add New": {
                        ID: 171,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 173,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 174,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 172,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.17 - Settings / Library / 3.3 - Groomer Specializations": {
                    "Add New": {
                        ID: 175,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 177,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 178,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 176,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.18 - Settings / Library / 4.1 - Brush Types": {
                    "Add New": {
                        ID: 179,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 181,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 182,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 180,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.19 - Settings / Library / 4.2 - Blade Sizes": {
                    "Add New": {
                        ID: 183,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 185,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 186,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 184,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.20 - Settings / Library / 4.3 - Comb Attachment Sizes": {
                    "Add New": {
                        ID: 187,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 189,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 190,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 188,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.21 - Settings / Library / 4.4 - Shampoo Types": {
                    "Add New": {
                        ID: 191,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 193,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 194,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 192,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.22 - Settings / Library / 5.1 - Sales Items Library": {
                    "Add New": {
                        ID: 195,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 197,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 198,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 196,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.23 - Settings / Library / 5.2 - Sales Items": {
                    "Add New": {
                        ID: 199,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 201,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 202,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 200,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.24 - Settings / Library / 5.3 - Service Items Library": {
                    "Add New": {
                        ID: 203,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 205,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 206,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 204,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.25 - Settings / Library / 5.4 - Time of Day": {
                    "Add New": {
                        ID: 207,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 209,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 210,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 208,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.26 - Settings / Library / 5.5 - General Price List": {
                    "Add New": {
                        ID: 211,
                        IsView: false,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 213,
                        IsView: false,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 214,
                        IsView: false,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 212,
                        IsView: false,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.27 - Settings / Library / 6.1 - Vaccinations": {
                    "Add New": {
                        ID: 215,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 217,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 218,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 216,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.28 - Settings / Library / 6.2 - Vets": {
                    "Add New": {
                        ID: 127,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 129,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 130,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 128,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.29 - Settings / Library / 6.3 - Waiver Documents": {
                    "Add New": {
                        ID: 119,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 121,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 122,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 120,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.30 - Settings / Library / 5.6 - Special Price List": {
                    "Add New": {
                        ID: 294,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 296,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 297,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 295,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
                "07.04.31 - Settings / Library / 5.7 - Packages": {
                    "Add New": {
                        ID: 1299,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    Edit: {
                        ID: 1301,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Import / Export": {
                        ID: 1302,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                    "Download PDF": {
                        ID: 1300,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                    },
                },
            }
            setAuth((prev) => {
                return { ...prev, permissions: finalSettings, permissionModules: Object.keys(tmpFinalSettings) }
            })
        } else {
            setAuth((prev) => {
                return { ...prev, permissions: finalSettings, permissionModules: Object.keys(finalSettings) }
            })
        }

        if (locationList?.length === 1 && locationList[0]?.location?.LocationID > 0) {
            handleNavigate()
        }
    }, [location])

    const handleEmailClick = () => {
        const recipient = "administrator@esypet.net"
        const subject = "Role Assignment Request"
        const body = "Body Here"
        const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
        window.location.href = mailtoLink
    }

    return (
        <>
            <ToastContainer />
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <div style={{ border: "4px solid #6767672b", borderRadius: "50%" }}>
                    <Avatar
                        img={auth?.employee?.varEmployeeImagePath === null ? "" : `${process.env.REACT_APP_MEDIA_URL}directories/employees/${auth?.employee?.varEmployeeImagePath}`}
                        className={"large"}
                        isVip={props.data?.bitIsVip}
                        labelType={"largeLabel"}
                    />
                </div>
                <h1 style={{ color: "#004BC3", textAlign: "center" }}>
                    {auth?.employee?.varEmployeeFirstName} {auth?.employee?.varEmployeeLastName}
                    <p style={{ fontSize: "15px", fontWeight: "500", textAlign: "center", width: "100%" }}>
                        {!!roleList?.length
                            ? roleList?.map((role, ind) => {
                                  return (
                                      <span>
                                          {role?.varUserRoleName}
                                          {roleList.length - 1 === ind ? "" : " | "}
                                      </span>
                                  )
                              })
                            : "-"}
                    </p>
                </h1>

                {loading ? (
                    <CircularProgress />
                ) : rolesSetup ? (
                    <div style={{ width: "33%", display: "flex", gap: "15px", flexDirection: "column" }}>
                        <div>
                            <p style={{ fontSize: "15px", fontWeight: "500", display: "flex", alignItems: "center" }}>Select Location</p>
                            <div style={{ width: "100%" }}>
                                <AdvancedDropDown
                                    data={locationList.map((i) => ({
                                        label: i?.location?.Name,
                                        value: i?.location?.LocationID,
                                    }))}
                                    onChange={(e) => {
                                        setLocation(e.value)
                                        setAuth((prev) => {
                                            return { ...prev, currentLocation: { label: e.label, value: e.value } }
                                        })
                                    }}
                                    passByID={true}
                                    value={location}
                                    ref={locationRef}
                                    placeHolder={"Select Location"}
                                />
                            </div>
                        </div>
                        {visited && (
                            <Button className={styles.loginBtn} variant="contained" color="primary" onClick={() => handleNavigate()}>
                                Continue to Profile
                            </Button>
                        )}
                    </div>
                ) : (
                    <div style={{ width: "33%", display: "flex", gap: "15px", flexDirection: "column" }}>
                        <p style={{ textAlign: "center", margin: 0, padding: 0, fontWeight: 500, fontSize: "16px" }}>You do not have Roles assigned to you yet!</p>
                        <p style={{ textAlign: "center", margin: 0, padding: 0, fontWeight: 500, fontSize: "16px" }}> Contact Administrator.</p>
                        <Button className={styles.loginBtn} variant="contained" color="primary" onClick={() => handleEmailClick()}>
                            Contact Administrator
                        </Button>
                    </div>
                )}

                <div style={{ color: "#6b7280", marginTop: "24px", fontSize: "12px", textAlign: "center" }}>
                    Copyright©{" "}
                    <a href="https://www.esyntaxis.com/" target="_blank">
                        ESyntaxis Corporation
                    </a>
                    . ESyPet Version {process.env.REACT_APP_VERSION}
                </div>
            </div>
        </>
    )
}

export default Welcome
