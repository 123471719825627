import Box from "@mui/material/Box"
import MobileStepper from "@mui/material/MobileStepper"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import { Button as MUIButton } from "@mui/material"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import { useEffect, useState } from "react"
import { Checkbox, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material"
import ViewListIcon from "@mui/icons-material/ViewList"
import ViewModuleIcon from "@mui/icons-material/ViewModule"
import annyang from "annyang"
import { axiosPrivate, buildHeaderObject } from "../../apis/axios"
import { Button } from "../components"
import useAuth from "../../hooks/useAuth"
import { toast } from "react-toastify"

const StepperNotes = (props: { noteData?; type?; bookingDetails?; isSuccess: CallableFunction; listNote?; isEdit? }) => {
    const [isLoading, setIsLoading] = useState(false)
    const { auth } = useAuth() as any
    const [firstTime, setFirstTime] = useState<any>("")
    const [serviceType, setServiceType] = useState<any>("")

    const [headComb, setHeadComb] = useState<any>("")
    const [headBlade, setHeadBlade] = useState<any>("")
    const [headStyle, setHeadStyle] = useState<any>("")
    const [headScissor, setHeadScissor] = useState(false)

    const [faceComb, setFaceComb] = useState<any>("")
    const [faceBlade, setFaceBlade] = useState<any>("")
    const [faceStyle, setFaceStyle] = useState<any>("")
    const [faceScissor, setFaceScissor] = useState(false)

    const [earsComb, setEarsComb] = useState<any>("")
    const [earsBlade, setEarsBlade] = useState<any>("")
    const [earsStyle, setEarsStyle] = useState<any>("")
    const [earsScissor, setEarsScissor] = useState(false)

    const [bodyComb, setBodyComb] = useState<any>("")
    const [bodyBlade, setBodyBlade] = useState<any>("")
    const [bodyStyle, setBodyStyle] = useState<any>("")
    const [bodyScissor, setBodyScissor] = useState(false)

    const [legsComb, setLegsComb] = useState<any>("")
    const [legsBlade, setLegsBlade] = useState<any>("")
    const [legsStyle, setLegsStyle] = useState<any>("")
    const [legsScissor, setLegsScissor] = useState(false)

    const [tailComb, setTailComb] = useState<any>("")
    const [tailBlade, setTailBlade] = useState<any>("")
    const [tailStyle, setTailStyle] = useState<any>("")
    const [tailScissor, setTailScissor] = useState(false)

    const [feetComb, setFeetComb] = useState<any>("")
    const [feetBlade, setFeetBlade] = useState<any>("")
    const [feetStyle, setFeetStyle] = useState<any>("")
    const [feetScissor, setFeetScissor] = useState(false)

    const [isDifficult, setIsDifficult] = useState(false) as any
    const [shampoo, setShampoo] = useState<any>("")
    const [comment, setComment] = useState<any>("")

    const [puppyCut, setPuppyCut] = useState(false)
    const [standerdCut, setStanderdCut] = useState(false)
    const [handStrip, setHandStrip] = useState(false)
    const [shaveDown, setShaveDown] = useState(false)

    const [combList, setCombList] = useState([]) as any
    const [bladeList, setBladeList] = useState([]) as any
    const [styleList, setStyleList] = useState([]) as any
    const [shampooList, setShampooList] = useState([]) as any

    const handleChangeActiveStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstTime((event.target as HTMLInputElement).value)
    }

    const handleServiceType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServiceType((event.target as HTMLInputElement).value)
    }

    const handleHeadComb = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHeadComb((event.target as HTMLInputElement).value)
    }

    const handleHeadBlade = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHeadBlade((event.target as HTMLInputElement).value)
    }

    const handleHeadStyle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHeadStyle((event.target as HTMLInputElement).value)
    }

    const handleFaceComb = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFaceComb((event.target as HTMLInputElement).value)
    }

    const handleFaceBlade = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFaceBlade((event.target as HTMLInputElement).value)
    }

    const handleFaceStyle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFaceStyle((event.target as HTMLInputElement).value)
    }

    const handleEarsComb = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEarsComb((event.target as HTMLInputElement).value)
    }

    const handleEarsBlade = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEarsBlade((event.target as HTMLInputElement).value)
    }

    const handleEarsStyle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEarsStyle((event.target as HTMLInputElement).value)
    }

    const handleBodyComb = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBodyComb((event.target as HTMLInputElement).value)
    }

    const handleBodyBlade = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBodyBlade((event.target as HTMLInputElement).value)
    }

    const handleBodyStyle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBodyStyle((event.target as HTMLInputElement).value)
    }

    const handleLegsComb = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLegsComb((event.target as HTMLInputElement).value)
    }

    const handleLegsBlade = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLegsBlade((event.target as HTMLInputElement).value)
    }

    const handleLegsStyle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLegsStyle((event.target as HTMLInputElement).value)
    }

    const handleTailComb = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTailComb((event.target as HTMLInputElement).value)
    }

    const handleTailBlade = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTailBlade((event.target as HTMLInputElement).value)
    }

    const handleTailStyle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTailStyle((event.target as HTMLInputElement).value)
    }

    const handleFeetComb = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFeetComb((event.target as HTMLInputElement).value)
    }

    const handleFeetBlade = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFeetBlade((event.target as HTMLInputElement).value)
    }

    const handleFeetStyle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFeetStyle((event.target as HTMLInputElement).value)
    }

    const handleChangeIsDifficult = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsDifficult((event.target as HTMLInputElement).value)
    }

    const handleShampoo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShampoo((event.target as HTMLInputElement).value)
    }

    const handleFirstTime = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstTime((event.target as HTMLInputElement).value)
    }

    async function getData(objectName) {
        const result = await axiosPrivate.get(`${objectName}/GetAll`, buildHeaderObject(auth))
        return result.data
    }

    useEffect(() => {
        if (props.type === "copy" && props.noteData && props.isEdit === true) {
            setBodyScissor(!!props.noteData.bitBodyScissorUsed)
            setIsDifficult(!!props.noteData.bitDifficultDrying === true ? "Yes" : "No")
            setEarsScissor(!!props.noteData.bitEarScissorUsed)
            setFaceScissor(!!props.noteData.bitFaceScissorUsed)
            setFeetScissor(!!props.noteData.bitFeetScissorUsed)
            setFirstTime(!!props.noteData.bitFirstGroom === true ? "Yes" : "No")
            setStanderdCut(!!props.noteData.bitFullGoomBreedStandardCut)
            setHandStrip(!!props.noteData.bitFullGroomHandStrip)
            setPuppyCut(!!props.noteData.bitFullGroomPuppyCut)
            setShaveDown(!!props.noteData.bitFullGroomShaveDown)
            setHeadScissor(!!props.noteData.bitHeadScissorUsed)
            setLegsScissor(!!props.noteData.bitLegScissorUsed)
            setTailScissor(!!props.noteData.bitTailScissorUsed)
            setShampoo(props.noteData.numRecommendedShampooID || 0)
            setComment(props.noteData.varGroomerComment || "")
            setServiceType(props.noteData.varServiceType || "")

            setBodyBlade(props.noteData?.blades?.bodyBlade?.numBladeID || 0)
            setEarsBlade(props.noteData?.blades?.earBlade?.numBladeID || 0)
            setFaceBlade(props.noteData?.blades?.faceBlade?.numBladeID || 0)
            setFeetBlade(props.noteData?.blades?.feetBlade?.numBladeID || 0)
            setHeadBlade(props.noteData?.blades?.headBlade?.numBladeID || 0)
            setLegsBlade(props.noteData?.blades?.legBlade?.numBladeID || 0)
            setTailBlade(props.noteData?.blades?.tailBlade?.numBladeID || 0)

            setEarsComb(props.noteData?.combs?.earComb?.numCombID || 0)
            setFaceComb(props.noteData?.combs?.faceComb?.numCombID || 0)
            setFeetComb(props.noteData?.combs?.feetComb?.numCombID || 0)
            setHeadComb(props.noteData?.combs?.headComb?.numCombID || 0)
            setTailComb(props.noteData?.combs?.tailComb?.numCombID || 0)
            setBodyComb(props.noteData?.combs?.bodyComb?.numCombID || 0)
            setLegsComb(props.noteData?.combs?.legComb?.numCombID || 0)

            setEarsStyle(props.noteData?.styles?.earStyle?.numStyleID || 0)
            setFaceStyle(props.noteData?.styles?.faceStyle?.numStyleID || 0)
            setFeetStyle(props.noteData?.styles?.feetStyle?.numStyleID || 0)
            setHeadStyle(props.noteData?.styles?.headStyle?.numStyleID || 0)
            setTailStyle(props.noteData?.styles?.tailStyle?.numStyleID || 0)
            setBodyStyle(props.noteData?.styles?.bodyStyle?.numStyleID || 0)
            setLegsStyle(props.noteData?.styles?.legStyle?.numStyleID || 0)
        }
    }, [props.type, props.noteData])

    const [isLoadingCombs, setIsLoadingCombs] = useState(true)
    const [isLoadingBlades, setIsLoadingBlades] = useState(true)
    const [isLoadingStyles, setIsLoadingStyles] = useState(true)
    const [isLoadingShampoo, setIsLoadingShampoo] = useState(true)
    const [isReady, setIsReady] = useState(false)

    useEffect(() => {
        // Fetch comb data
        getData("Comb")
            .then((res) => {
                setCombList(res?.comb)
                setIsLoadingCombs(false) // Mark comb loading as complete
            })
            .catch((err) => console.log("errr", err))

        // Fetch blade data
        getData("Blade")
            .then((res) => {
                setBladeList(res?.blade)
                setIsLoadingBlades(false) // Mark blade loading as complete
            })
            .catch((err) => console.log("errr", err))

        // Fetch style data
        getData("Style")
            .then((res) => {
                setStyleList(res?.style)
                setIsLoadingStyles(false) // Mark style loading as complete
            })
            .catch((err) => console.log("errr", err))

        // Fetch shampoo data
        getData("Shampoo")
            .then((res) => {
                setShampooList(res?.shampoo)
                setIsLoadingShampoo(false) // Mark shampoo loading as complete
            })
            .catch((err) => console.log("errr", err))
    }, [])

    // Combine all loading states to determine if the UI is ready
    useEffect(() => {
        if (!isLoadingCombs && !isLoadingBlades && !isLoadingStyles && !isLoadingShampoo) {
            setIsReady(true) // All data is fetched, ready to render UI
        }
    }, [isLoadingCombs, isLoadingBlades, isLoadingStyles, isLoadingShampoo])

    const saveNote = () => {
        if (props.isEdit === true) {
            let payload = [
                {
                    numGroomerNoteID: props.noteData?.numGroomerNoteID,
                    numAppointmentID: parseInt(props.bookingDetails.numAppointmentID),
                    numEmployeeID: parseInt(auth?.employee?.numEmployeeID),
                    numLocationID: parseInt(props.bookingDetails.LocationID),
                    bitFirstGroom: firstTime === "Yes" ? true : false,
                    varServiceType: serviceType,
                    bitFullGroomPuppyCut: puppyCut,
                    bitFullGoomBreedStandardCut: standerdCut,
                    bitFullGroomHandStrip: handStrip,
                    bitFullGroomShaveDown: shaveDown,
                    numHeadCombID: parseInt(headComb),
                    numHeadBladeID: parseInt(headBlade),
                    numHeadStyleID: parseInt(headStyle),
                    bitHeadScissorUsed: headScissor,
                    numFaceCombID: parseInt(faceComb),
                    numFaceBladeID: parseInt(faceBlade),
                    numFaceStyleID: parseInt(faceStyle),
                    bitFaceScissorUsed: faceScissor,
                    numEarCombID: parseInt(earsComb),
                    numEarBladeID: parseInt(earsBlade),
                    numEarStyleID: parseInt(earsStyle),
                    bitEarScissorUsed: earsScissor,
                    numBodyComdID: parseInt(bodyComb),
                    numBodyBladeID: parseInt(bodyBlade),
                    bitBodyScissorUsed: bodyScissor,
                    numLegComdID: parseInt(legsComb),
                    numLegBladeID: parseInt(legsBlade),
                    bitLegScissorUsed: legsScissor,
                    numTailCombID: parseInt(tailComb),
                    numTailBladeID: parseInt(tailBlade),
                    numTailStyleID: parseInt(tailStyle),
                    bitTailScissorUsed: tailScissor,
                    numFeetCombID: parseInt(feetComb),
                    numFeetBladeID: parseInt(feetBlade),
                    numFeetStyleID: parseInt(feetStyle),
                    bitFeetScissorUsed: feetScissor,
                    bitDifficultDrying: isDifficult === "Yes" ? true : false,
                    numRecommendedShampooID: parseInt(shampoo),
                    varGroomerComment: comment,
                    groomerNoteSummary: "test",
                    numUserID: auth?.employee?.numEmployeeID,
                    CustomerPrefferedAppointmentNo: null,
                    IsCutomerPreffered: null,
                    numBodyStyleID: parseInt(bodyStyle),
                    numLegStyleID: parseInt(legsStyle),
                },
            ]
            console.log("sent-edit", payload)
            saveGroomerNote(payload)
        } else {
            let length = Object.keys(props.listNote).length

            if (length > 0) {
                let payload = [
                    {
                        numGroomerNoteID: 0,
                        numAppointmentID: parseInt(props.bookingDetails.numAppointmentID),
                        numEmployeeID: parseInt(auth?.employee?.numEmployeeID),
                        numLocationID: parseInt(props.bookingDetails.LocationID),
                        bitFirstGroom: firstTime === "Yes" ? true : false,
                        varServiceType: serviceType,
                        bitFullGroomPuppyCut: puppyCut,
                        bitFullGoomBreedStandardCut: standerdCut,
                        bitFullGroomHandStrip: handStrip,
                        bitFullGroomShaveDown: shaveDown,
                        numHeadCombID: parseInt(headComb),
                        numHeadBladeID: parseInt(headBlade),
                        numHeadStyleID: parseInt(headStyle),
                        bitHeadScissorUsed: headScissor,
                        numFaceCombID: parseInt(faceComb),
                        numFaceBladeID: parseInt(faceBlade),
                        numFaceStyleID: parseInt(faceStyle),
                        bitFaceScissorUsed: faceScissor,
                        numEarCombID: parseInt(earsComb),
                        numEarBladeID: parseInt(earsBlade),
                        numEarStyleID: parseInt(earsStyle),
                        bitEarScissorUsed: earsScissor,
                        numBodyComdID: parseInt(bodyComb),
                        numBodyBladeID: parseInt(bodyBlade),
                        bitBodyScissorUsed: bodyScissor,
                        numLegComdID: parseInt(legsComb),
                        numLegBladeID: parseInt(legsBlade),
                        bitLegScissorUsed: legsScissor,
                        numTailCombID: parseInt(tailComb),
                        numTailBladeID: parseInt(tailBlade),
                        numTailStyleID: parseInt(tailStyle),
                        bitTailScissorUsed: tailScissor,
                        numFeetCombID: parseInt(feetComb),
                        numFeetBladeID: parseInt(feetBlade),
                        numFeetStyleID: parseInt(feetStyle),
                        bitFeetScissorUsed: feetScissor,
                        bitDifficultDrying: isDifficult === "Yes" ? true : false,
                        numRecommendedShampooID: parseInt(shampoo),
                        varGroomerComment: comment,
                        groomerNoteSummary: "test",
                        numUserID: auth?.employee?.numEmployeeID,
                        CustomerPrefferedAppointmentNo: parseInt(props?.listNote?.numAppointmentID),
                        IsCutomerPreffered: props?.listNote?.IsPreferred,
                        numBodyStyleID: parseInt(bodyStyle),
                        numLegStyleID: parseInt(legsStyle),
                    },
                ]
                console.log("sent", payload)
                saveGroomerNote(payload)
            } else {
                console.log("noteData", props.noteData)
                let payload = [
                    {
                        numGroomerNoteID: 0,
                        numAppointmentID: parseInt(props.bookingDetails.numAppointmentID),
                        numEmployeeID: parseInt(auth?.employee?.numEmployeeID),
                        numLocationID: parseInt(props.bookingDetails.LocationID),
                        bitFirstGroom: firstTime === "Yes" ? true : false,
                        varServiceType: serviceType,
                        bitFullGroomPuppyCut: puppyCut,
                        bitFullGoomBreedStandardCut: standerdCut,
                        bitFullGroomHandStrip: handStrip,
                        bitFullGroomShaveDown: shaveDown,
                        numHeadCombID: parseInt(headComb),
                        numHeadBladeID: parseInt(headBlade),
                        numHeadStyleID: parseInt(headStyle),
                        bitHeadScissorUsed: headScissor,
                        numFaceCombID: parseInt(faceComb),
                        numFaceBladeID: parseInt(faceBlade),
                        numFaceStyleID: parseInt(faceStyle),
                        bitFaceScissorUsed: faceScissor,
                        numEarCombID: parseInt(earsComb),
                        numEarBladeID: parseInt(earsBlade),
                        numEarStyleID: parseInt(earsStyle),
                        bitEarScissorUsed: earsScissor,
                        numBodyComdID: parseInt(bodyComb),
                        numBodyBladeID: parseInt(bodyBlade),
                        bitBodyScissorUsed: bodyScissor,
                        numLegComdID: parseInt(legsComb),
                        numLegBladeID: parseInt(legsBlade),
                        bitLegScissorUsed: legsScissor,
                        numTailCombID: parseInt(tailComb),
                        numTailBladeID: parseInt(tailBlade),
                        numTailStyleID: parseInt(tailStyle),
                        bitTailScissorUsed: tailScissor,
                        numFeetCombID: parseInt(feetComb),
                        numFeetBladeID: parseInt(feetBlade),
                        numFeetStyleID: parseInt(feetStyle),
                        bitFeetScissorUsed: feetScissor,
                        bitDifficultDrying: isDifficult === "Yes" ? true : false,
                        numRecommendedShampooID: parseInt(shampoo),
                        varGroomerComment: comment,
                        groomerNoteSummary: "test",
                        numUserID: auth?.employee?.numEmployeeID,
                        CustomerPrefferedAppointmentNo: 0,
                        IsCutomerPreffered: false,
                        numBodyStyleID: parseInt(bodyStyle),
                        numLegStyleID: parseInt(legsStyle),
                    },
                ]
                console.log("sent", payload)
                saveGroomerNote(payload)
            }
        }
    }

    async function saveGroomerNote(data) {
        await axiosPrivate
            .post(`GroomerBatherNote/PopulateGroomerNote`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                console.log(result)
                if (result.status == 200) {
                    toast.success("Groomer note successfully saved!")
                    props.isSuccess(true)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const steps = [
        {
            label: "1. First groom at Lucky Paws",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={firstTime}
                            onChange={handleFirstTime}
                            style={{ flexDirection: "column" }}
                        >
                            <FormControlLabel value={"Yes"} control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />} label={<p style={{ fontSize: "15px" }}>Yes</p>} />
                            <FormControlLabel value={"No"} control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />} label={<p style={{ fontSize: "15px" }}>No</p>} />
                        </RadioGroup>
                    </RadioGroup>
                    ,
                </>
            ),
            prevAns: {
                label: props.noteData.bitFirstGroom === true ? "Yes" : "No",
                value: props.noteData.bitFirstGroom === true ? "Yes" : "No",
            },
            setState: setFirstTime,
            isMulti: false,
        },
        {
            label: "2. Service Type",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={serviceType}
                            onChange={handleServiceType}
                            style={{ flexDirection: "column" }}
                        >
                            <FormControlLabel
                                value={"Bath"}
                                control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                label={<p style={{ fontSize: "15px" }}>Bath Only</p>}
                            />
                            <FormControlLabel
                                value={"Full"}
                                control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                label={<p style={{ fontSize: "15px" }}>Full Groom</p>}
                            />
                            <FormControlLabel
                                value={"Mini"}
                                control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                label={<p style={{ fontSize: "15px" }}>Mini Groom</p>}
                            />
                        </RadioGroup>
                    </RadioGroup>
                    ,
                </>
            ),
            prevAns: {
                label: props.noteData.varServiceType || "No answer",
                value: props.noteData.varServiceType,
            },
            setState: setServiceType,
            isMulti: false,
        },
        {
            label: "3. Full Groom Type",
            description: (
                <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox onChange={(e) => setPuppyCut(!puppyCut)} checked={puppyCut} />
                        <p>Puppy Cut (One Length All Over)</p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox onChange={(e) => setStanderdCut(!standerdCut)} checked={standerdCut} />
                        <p>Breed Standard Cut</p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox onChange={(e) => setHandStrip(!handStrip)} checked={handStrip} />
                        <p>Hand Strip</p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox onChange={(e) => setShaveDown(!shaveDown)} checked={shaveDown} />
                        <p>Shave Down</p>
                    </div>
                </>
            ),
            prevAns: {
                puppyCut: {
                    label: !!props.noteData.bitFullGroomPuppyCut ? "Yes" : "No",
                    value: !!props.noteData.bitFullGroomPuppyCut,
                },
                standerdCut: {
                    label: !!props.noteData.bitFullGoomBreedStandardCut ? "Yes" : "No",
                    value: !!props.noteData.bitFullGoomBreedStandardCut,
                },
                handStrip: {
                    label: !!props.noteData.bitFullGroomHandStrip ? "Yes" : "No",
                    value: !!props.noteData.bitFullGroomHandStrip,
                },
                shaveDown: {
                    label: !!props.noteData.bitFullGroomShaveDown ? "Yes" : "No",
                    value: !!props.noteData.bitFullGroomShaveDown,
                },
            },

            setState: {
                puppyCut: setPuppyCut,
                standerdCut: setStanderdCut,
                handStrip: setHandStrip,
                shaveDown: setShaveDown,
            },
            isMulti: true,
        },
        {
            label: "4.1 HEAD - Comb",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={headComb}
                            onChange={handleHeadComb}
                            style={{ flexDirection: "column" }}
                        >
                            {combList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numCombID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varCombName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
            prevAns: {
                headComb: props.noteData?.combs?.headComb?.numCombID || 0,
                label: props.noteData?.combs?.headComb?.varCombName || "Not selected",
            },
            setState: {
                headComb: setHeadComb,
            },
            isMulti: false,
        },
        {
            label: "4.2 HEAD - Blade",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={headBlade}
                            onChange={handleHeadBlade}
                            style={{ flexDirection: "column" }}
                        >
                            {bladeList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numBladeID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varBladeName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
            prevAns: {
                headBlade: props.noteData?.blades?.headBlade?.numBladeID || 0,
                label: props.noteData?.blades?.headBlade?.varBladeName || "Not selected",
            },
            setState: {
                headBlade: setHeadBlade,
            },
            isMulti: false,
        },
        {
            label: "4.3 HEAD - Style",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={headStyle}
                            onChange={handleHeadStyle}
                            style={{ flexDirection: "column" }}
                        >
                            {styleList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numStyleID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varStyleName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
            prevAns: {
                headStyle: props.noteData?.styles?.headStyle?.numStyleID || 0,
                label: props.noteData?.styles?.headStyle?.varStyleName || "Not selected",
            },
            setState: {
                headStyle: setHeadStyle,
            },
            isMulti: false,
        },
        {
            label: "4.4 HEAD - I used a Scissor",
            description: (
                <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox style={{ padding: "0 5px 0 0", fontSize: "100px" }} color="primary" onChange={(e) => setHeadScissor(!headScissor)} checked={headScissor} />
                        <p style={{ fontSize: "15px" }}>Yes</p>
                    </div>
                </>
            ),
            prevAns: {
                label: !!props.noteData.bitHeadScissorUsed ? "Yes" : "No",
                headScissor: !!props.noteData.bitHeadScissorUsed,
            },
            setState: {
                headScissor: setHeadScissor,
            },
            isMulti: false,
        },
        {
            label: "5.1 FACE - Comb",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={faceComb}
                            onChange={handleFaceComb}
                            style={{ flexDirection: "column" }}
                        >
                            {combList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numCombID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varCombName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
            prevAns: {
                faceComb: props.noteData?.combs?.faceComb?.numCombID || 0,
                label: props.noteData?.combs?.faceComb?.varCombName || "Not selected",
            },
            setState: {
                faceComb: setFaceComb,
            },
            isMulti: false,
        },
        {
            label: "5.2 FACE - Blade",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={faceBlade}
                            onChange={handleFaceBlade}
                            style={{ flexDirection: "column" }}
                        >
                            {bladeList?.map((radioList, index) => (
                                <FormControlLabel
                                    key={radioList?.numBladeID} // It's good practice to add a key when mapping components
                                    value={radioList?.numBladeID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varBladeName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
            prevAns: {
                faceBlade: props.noteData?.blades?.faceBlade?.numBladeID || 0,
                label: props.noteData?.blades?.faceBlade?.varBladeName || "Not selected",
            },
            setState: {
                faceBlade: setFaceBlade,
            },
            isMulti: false,
        },

        {
            label: "5.3 FACE - Style",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={faceStyle}
                            onChange={handleFaceStyle}
                            style={{ flexDirection: "column" }}
                        >
                            {styleList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numStyleID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varStyleName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
            prevAns: {
                faceStyle: props.noteData?.styles?.faceStyle?.numStyleID || 0,
                label: props.noteData?.styles?.faceStyle?.varStyleName || "Not selected",
            },
            setState: {
                faceStyle: setFaceStyle,
            },
            isMulti: false,
        },
        {
            label: "5.4 FACE - I used a Scissor",
            description: (
                <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox style={{ padding: "0 5px 0 0", fontSize: "100px" }} color="primary" onChange={(e) => setFaceScissor(!faceScissor)} checked={faceScissor} />
                        <p style={{ fontSize: "15px" }}>Yes</p>
                    </div>
                </>
            ),
            prevAns: {
                label: !!props.noteData.bitFaceScissorUsed ? "Yes" : "No",
                faceScissor: !!props.noteData.bitFaceScissorUsed,
            },
            setState: {
                faceScissor: setFaceScissor,
            },
            isMulti: false,
        },
        {
            label: "6.1 EARS - Comb",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={earsComb}
                            onChange={handleEarsComb}
                            style={{ flexDirection: "column" }}
                        >
                            {combList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numCombID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varCombName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
            prevAns: {
                earsComb: props.noteData?.combs?.earComb?.numCombID || 0,
                label: props.noteData?.combs?.earComb?.varCombName || "Not selected",
            },
            setState: {
                earsComb: setEarsComb,
            },
            isMulti: false,
        },
        {
            label: "6.2 EARS - Blade",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={earsBlade}
                            onChange={handleEarsBlade}
                            style={{ flexDirection: "column" }}
                        >
                            {bladeList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numBladeID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varBladeName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
            prevAns: {
                earsBlade: props.noteData?.blades?.earBlade?.numBladeID || 0,
                label: props.noteData?.blades?.earBlade?.varBladeName || "Not selected",
            },
            setState: {
                earsBlade: setEarsBlade,
            },
            isMulti: false,
        },
        {
            label: "6.3 EARS - Style",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={earsStyle}
                            onChange={handleEarsStyle}
                            style={{ flexDirection: "column" }}
                        >
                            {styleList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numStyleID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varStyleName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
            prevAns: {
                earsStyle: props.noteData?.styles?.earStyle?.numStyleID || 0,
                label: props.noteData?.styles?.earStyle?.varStyleName || "Not selected",
            },
            setState: {
                earsStyle: setEarsStyle,
            },
            isMulti: false,
        },
        {
            label: "6.4 EARS - I used a Scissor",
            description: (
                <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox style={{ padding: "0 5px 0 0", fontSize: "100px" }} color="primary" onChange={(e) => setEarsScissor(!earsScissor)} checked={earsScissor} />
                        <p style={{ fontSize: "15px" }}>Yes</p>
                    </div>
                </>
            ),
            prevAns: {
                label: !!props.noteData.bitEarScissorUsed ? "Yes" : "No",
                earsScissor: !!props.noteData.bitEarScissorUsed,
            },
            setState: {
                earsScissor: setEarsScissor,
            },
            isMulti: false,
        },
        {
            label: "7.1 BODY - Comb",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={bodyComb}
                            onChange={handleBodyComb}
                            style={{ flexDirection: "column" }}
                        >
                            {combList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numCombID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varCombName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
            prevAns: {
                bodyComb: props.noteData?.combs?.bodyComb?.numCombID || 0,
                label: props.noteData?.combs?.bodyComb?.varCombName || "Not selected",
            },
            setState: {
                bodyComb: setBodyComb,
            },
            isMulti: false,
        },
        {
            label: "7.2 BODY - Blade",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={bodyBlade}
                            onChange={handleBodyBlade}
                            style={{ flexDirection: "column" }}
                        >
                            {bladeList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numBladeID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varBladeName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
            prevAns: {
                bodyBlade: props.noteData?.blades?.bodyBlade?.numBladeID || 0,
                label: props.noteData?.blades?.bodyBlade?.varBladeName || "Not selected",
            },
            setState: {
                bodyBlade: setBodyBlade,
            },
            isMulti: false,
        },
        {
            label: "7.3 BODY - Style",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={bodyStyle}
                            onChange={handleBodyStyle}
                            style={{ flexDirection: "column" }}
                        >
                            {styleList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numStyleID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varStyleName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
            prevAns: {
                bodyStyle: props.noteData?.styles?.bodyStyle?.numStyleID || 0,
                label: props.noteData?.styles?.bodyStyle?.varStyleName || "Not selected",
            },
            setState: {
                bodyStyle: setBodyStyle,
            },
            isMulti: false,
        },
        {
            label: "7.4 BODY - I used a Scissor",
            description: (
                <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox style={{ padding: "0 5px 0 0", fontSize: "100px" }} color="primary" onChange={(e) => setBodyScissor(!bodyScissor)} checked={bodyScissor} />
                        <p style={{ fontSize: "15px" }}>Yes</p>
                    </div>
                </>
            ),
            prevAns: {
                label: !!props.noteData.bitBodyScissorUsed ? "Yes" : "No",
                bodyScissor: !!props.noteData.bitBodyScissorUsed,
            },
            setState: {
                bodyScissor: setBodyScissor,
            },
            isMulti: false,
        },
        {
            label: "8.1 LEGS - Comb",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={legsComb}
                            onChange={handleLegsComb}
                            style={{ flexDirection: "column" }}
                        >
                            {combList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numCombID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varCombName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
            prevAns: {
                legsComb: props.noteData?.combs?.legComb?.numCombID || 0,
                label: props.noteData?.combs?.legComb?.varCombName || "Not selected",
            },
            setState: {
                legsComb: setLegsComb,
            },
            isMulti: false,
        },
        {
            label: "8.2 LEGS - Blade",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={legsBlade}
                            onChange={handleLegsBlade}
                            style={{ flexDirection: "column" }}
                        >
                            {bladeList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numBladeID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varBladeName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
            prevAns: {
                legsBlade: props.noteData?.blades?.legBlade?.numBladeID || 0,
                label: props.noteData?.blades?.legBlade?.varBladeName || "Not selected",
            },
            setState: {
                legsBlade: setLegsBlade,
            },
            isMulti: false,
        },
        {
            label: "8.3 LEGS - Style",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={legsStyle}
                            onChange={handleLegsStyle}
                            style={{ flexDirection: "column" }}
                        >
                            {styleList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numStyleID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varStyleName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
            prevAns: {
                legsStyle: props.noteData?.styles?.legStyle?.numStyleID || 0,
                label: props.noteData?.styles?.legStyle?.varStyleName || "Not selected",
            },
            setState: {
                legsStyle: setLegsStyle,
            },
            isMulti: false,
        },
        {
            label: "8.4 LEGS - I used a Scissor",
            description: (
                <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox style={{ padding: "0 5px 0 0", fontSize: "100px" }} color="primary" onChange={(e) => setLegsScissor(!legsScissor)} checked={legsScissor} />
                        <p style={{ fontSize: "15px" }}>Yes</p>
                    </div>
                </>
            ),
            prevAns: {
                label: !!props.noteData.bitLegScissorUsed ? "Yes" : "No",
                legsScissor: !!props.noteData.bitLegScissorUsed,
            },
            setState: {
                legsScissor: setLegsScissor,
            },
            isMulti: false,
        },
        {
            label: "9.1 TAIL - Comb",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={tailComb}
                            onChange={handleTailComb}
                            style={{ flexDirection: "column" }}
                        >
                            {combList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numCombID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varCombName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
            prevAns: {
                tailComb: props.noteData?.combs?.tailComb?.numCombID || 0,
                label: props.noteData?.combs?.tailComb?.varCombName || "Not selected",
            },
            setState: {
                tailComb: setTailComb,
            },
            isMulti: false,
        },
        {
            label: "9.2 TAIL - Blade",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={tailBlade}
                            onChange={handleTailBlade}
                            style={{ flexDirection: "column" }}
                        >
                            {bladeList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numBladeID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varBladeName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
            prevAns: {
                tailBlade: props.noteData?.blades?.tailBlade?.numBladeID || 0,
                label: props.noteData?.blades?.tailBlade?.varBladeName || "Not selected",
            },
            setState: {
                tailBlade: setTailBlade,
            },
            isMulti: false,
        },
        {
            label: "9.3 TAIL - Style",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={tailStyle}
                            onChange={handleTailStyle}
                            style={{ flexDirection: "column" }}
                        >
                            {styleList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numStyleID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varStyleName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
            prevAns: {
                tailStyle: props.noteData?.styles?.tailStyle?.numStyleID || 0,
                label: props.noteData?.styles?.tailStyle?.varStyleName || "Not selected",
            },
            setState: {
                tailStyle: setTailStyle,
            },
            isMulti: false,
        },
        {
            label: "9.4 TAIL - I used a Scissor",
            description: (
                <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox style={{ padding: "0 5px 0 0", fontSize: "100px" }} color="primary" onChange={(e) => setTailScissor(!tailScissor)} checked={tailScissor} />
                        <p style={{ fontSize: "15px" }}>Yes</p>
                    </div>
                </>
            ),
            prevAns: {
                label: !!props.noteData.bitTailScissorUsed ? "Yes" : "No",
                tailScissor: !!props.noteData.bitTailScissorUsed,
            },
            setState: {
                tailScissor: setTailScissor,
            },
            isMulti: false,
        },
        {
            label: "10.1 FEET - Comb",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={feetComb}
                            onChange={handleFeetComb}
                            style={{ flexDirection: "column" }}
                        >
                            {combList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numCombID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varCombName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
            prevAns: {
                feetComb: props.noteData?.combs?.feetComb?.numCombID || 0,
                label: props.noteData?.combs?.feetComb?.varCombName || "Not selected",
            },
            setState: {
                feetComb: setFeetComb,
            },
            isMulti: false,
        },
        {
            label: "10.2 FEET - Blade",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={feetBlade}
                            onChange={handleFeetBlade}
                            style={{ flexDirection: "column" }}
                        >
                            {bladeList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numBladeID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varBladeName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
            prevAns: {
                feetBlade: props.noteData?.blades?.feetBlade?.numBladeID || 0,
                label: props.noteData?.blades?.feetBlade?.varBladeName || "Not selected",
            },
            setState: {
                feetBlade: setFeetBlade,
            },
            isMulti: false,
        },
        {
            label: "10.3 FEET - Style",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={feetStyle}
                            onChange={handleFeetStyle}
                            style={{ flexDirection: "column" }}
                        >
                            {styleList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numStyleID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varStyleName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
            prevAns: {
                feetStyle: props.noteData?.styles?.feetStyle?.numStyleID || 0,
                label: props.noteData?.styles?.feetStyle?.varStyleName || "Not selected",
            },
            setState: {
                feetStyle: setFeetStyle,
            },
            isMulti: false,
        },
        {
            label: "10.4 FEET - I used a Scissor",
            description: (
                <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox style={{ padding: "0 5px 0 0", fontSize: "100px" }} color="primary" onChange={(e) => setFeetScissor(!feetScissor)} checked={feetScissor} />
                        <p style={{ fontSize: "15px" }}>Yes</p>
                    </div>
                </>
            ),
            prevAns: {
                label: !!props.noteData.bitFeetScissorUsed ? "Yes" : "No",
                feetScissor: !!props.noteData.bitFeetScissorUsed,
            },
            setState: {
                feetScissor: setFeetScissor,
            },
            isMulti: false,
        },
        {
            label: "11. Difficult to dry?",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={isDifficult}
                            onChange={handleChangeIsDifficult}
                            style={{ flexDirection: "column" }}
                        >
                            <FormControlLabel value={"Yes"} control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />} label={<p style={{ fontSize: "15px" }}>Yes</p>} />
                            <FormControlLabel value={"No"} control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />} label={<p style={{ fontSize: "15px" }}>No</p>} />
                        </RadioGroup>
                    </RadioGroup>
                    ,
                </>
            ),
            prevAns: {
                label: props.noteData.bitDifficultDrying === true ? "Yes" : "No",
                value: props.noteData.bitDifficultDrying === true ? "Yes" : "No",
            },
            setState: setIsDifficult,
            isMulti: false,
        },
        {
            label: "12. Recommendation for next groom",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={shampoo}
                            onChange={handleShampoo}
                            style={{ flexDirection: "column" }}
                        >
                            {shampooList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numShampooID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varShampooName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
            prevAns: {
                shampoo: props.noteData?.numRecommendedShampooID || 0,
                label: props.noteData?.varShampooName || "Not selected",
            },
            setState: {
                shampoo: setShampoo,
            },
            isMulti: false,
        },
        {
            label: "Overall Groomer Comments",
            description: (
                <TextField
                    label="Add your suggestions here."
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    value={comment}
                    onChange={(e) => {
                        setComment(e.target.value)
                    }}
                />
            ),
            prevAns: {
                comment: props.noteData?.varGroomerComment || "",
                label: props.noteData?.varGroomerComment || "Not added",
            },
            setState: {
                comment: setComment,
            },
            isMulti: false,
        },
    ]

    const [activeStep, setActiveStep] = useState(0)
    const [singleStepMode, setSingleStepMode] = useState(true)
    const maxSteps = steps.length

    useEffect(() => {
        // Initialize annyang
        annyang.start()

        // Define voice commands
        const commands = {
            "set :answer": (answer) => {
                if (activeStep === 0) {
                    if (answer.toLowerCase() === "yes") {
                        setFirstTime(true)
                    } else if (answer.toLowerCase() === "no") {
                        setFirstTime(false)
                    } else {
                    }
                } else if (activeStep === 1) {
                    if (answer.toLowerCase() === "bath" || answer.toLowerCase() === "but" || answer.toLowerCase() === "butt") {
                        setServiceType("Bath")
                    } else if (answer.toLowerCase() === "full") {
                        setServiceType("Full")
                    } else if (answer.toLowerCase() === "mini" || answer.toLowerCase() === "meaning") {
                        setServiceType("Mini")
                    } else {
                    }
                } else if (activeStep === 2) {
                    if (answer.toLowerCase() === "puppy" || answer.toLowerCase() === "bobby" || answer.toLowerCase() === "puppy") {
                        setPuppyCut(!puppyCut)
                    } else if (answer.toLowerCase() === "standard" || answer.toLowerCase() === "standard") {
                        setStanderdCut(!standerdCut)
                    } else if (answer.toLowerCase() === "hand strip" || answer.toLowerCase() === "hand") {
                        setHandStrip(!handStrip)
                    } else if (answer.toLowerCase() === "shavedown" || answer.toLowerCase() === "shaved down") {
                        setShaveDown(!shaveDown)
                    }
                } else if (activeStep === 3) {
                    if (answer.toLowerCase() === "puppy" || answer.toLowerCase() === "bobby" || answer.toLowerCase() === "puppy") {
                        setPuppyCut(!puppyCut)
                    } else if (answer.toLowerCase() === "standard" || answer.toLowerCase() === "standard") {
                        setStanderdCut(!standerdCut)
                    } else if (answer.toLowerCase() === "hand strip" || answer.toLowerCase() === "hand") {
                        setHandStrip(!handStrip)
                    } else if (answer.toLowerCase() === "shavedown" || answer.toLowerCase() === "shaved down") {
                        setShaveDown(!shaveDown)
                    }
                }
            },
            "go :option": (option) => {
                if (option === "next") {
                    handleNext()
                } else if (option === "back") {
                    handleBack()
                }
            },
        }

        // Add the commands to annyang
        annyang.addCommands(commands)

        // Specify language (optional)
        annyang.setLanguage("en-US")

        // Specify additional configuration options (optional)
        annyang.debug(true)

        // Clean up on component unmount
        return () => {
            annyang.abort()
        }
    }, [])

    useEffect(() => {}, [activeStep])

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
    const toggleMode = () => {
        setSingleStepMode((prevMode) => !prevMode)
    }

    if (!isReady) {
        // Show loading state or spinner until all data is fetched
        return <div>Loading...</div>
    }

    const copyAnswer = (index) => {
        const step = steps[index]

        if (step?.setState && step?.prevAns) {
            // Check if it's a multi-answer step by verifying if `prevAns` is an object without a direct `value`
            if (typeof step.prevAns === "object" && !step.prevAns.value) {
                // Handle multiple-answer steps (e.g., multiple checkboxes or options)
                Object.keys(step.prevAns).forEach((key) => {
                    if (step.setState[key] && typeof step.setState[key] === "function" && step.prevAns[key] !== undefined) {
                        // Set each individual state using the corresponding prevAns value
                        step.setState[key](step.prevAns[key].value !== undefined ? step.prevAns[key].value : step.prevAns[key])
                    }
                })
            } else if (typeof step.setState === "function" && step.prevAns?.value !== undefined) {
                // Handle single-answer steps (e.g., a single radio button or option)
                step.setState(step.prevAns.value)
            }
        } else {
            console.log("State setter or previous answer is missing")
        }
    }
    return (
        <>
            <div style={{ textAlign: "end" }}>
                {props.type === "copy" && props.isEdit === true ? (
                    <MUIButton onClick={toggleMode} variant="outlined">
                        {singleStepMode ? <ViewListIcon /> : <ViewModuleIcon />}
                    </MUIButton>
                ) : null}
            </div>
            <Box sx={{ width: "100%", flexGrow: 1, height: "400px", overflowY: "scroll" }}>
                {singleStepMode ? (
                    <>
                        <Paper
                            square
                            elevation={0}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                height: 50,
                                pl: 2,
                                bgcolor: "background.default",
                            }}
                        >
                            <Typography style={{ fontWeight: "600" }}>{steps[activeStep].label}</Typography>
                        </Paper>
                        <Box sx={{ height: "auto", width: "90%", p: 2 }}>{steps[activeStep].description}</Box>
                    </>
                ) : (
                    steps.map((step, index) => (
                        <Box key={index} sx={{ mb: 2 }}>
                            <Paper
                                square
                                elevation={0}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    height: 50,
                                    pl: 2,
                                    bgcolor: "background.default",
                                }}
                            >
                                <Typography style={{ fontWeight: "600" }}>{step.label}</Typography>
                            </Paper>
                            <Box sx={{ height: "auto", width: "100%", p: 2 }}>{step.description}</Box>
                        </Box>
                    ))
                )}
            </Box>

            {props.type === "copy" && props.isEdit !== true ? (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#e1f6ff",
                        fontWeight: "600",
                        padding: "10px",
                    }}
                >
                    <p style={{ fontSize: "14px", fontWeight: "600", margin: "0", color: "#0072d5" }}>Previous Selection</p>
                    {steps[activeStep].isMulti === true ? (
                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                            <p style={{ fontWeight: "600", margin: "0" }}>
                                Puppy Cut: <span>{steps[activeStep]?.prevAns?.puppyCut?.label}</span>
                            </p>{" "}
                            <p style={{ margin: "0", color: "gray" }}>|</p>
                            <p style={{ fontWeight: "600", margin: "0" }}>
                                Breed Standard Cut: <span>{steps[activeStep]?.prevAns?.standerdCut?.label}</span>
                            </p>{" "}
                            <p style={{ margin: "0", color: "gray" }}>|</p>
                            <p style={{ fontWeight: "600", margin: "0" }}>
                                Hand Strip: <span>{steps[activeStep]?.prevAns?.handStrip?.label}</span>
                            </p>{" "}
                            <p style={{ margin: "0", color: "gray" }}>|</p>
                            <p style={{ fontWeight: "600", margin: "0" }}>
                                Shave Down: <span>{steps[activeStep]?.prevAns?.shaveDown?.label}</span>
                            </p>
                        </div>
                    ) : (
                        steps[activeStep].prevAns?.label
                    )}

                    <Button variant="contained" color="success" text="COPY" onClick={() => copyAnswer(activeStep)} />
                </div>
            ) : null}
            <MobileStepper
                variant="progress"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <MUIButton size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                        Next
                        <KeyboardArrowRight />
                    </MUIButton>
                }
                backButton={
                    <MUIButton size="small" onClick={handleBack} disabled={activeStep === 0}>
                        <KeyboardArrowLeft />
                        Back
                    </MUIButton>
                }
            />

            <div style={{ backgroundColor: "#fff", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: "10px" }}>
                    <Button
                        color="primary"
                        variant="contained"
                        text={props.isEdit ? "Save Changes" : "Save Note"}
                        onClick={() => {
                            saveNote()
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default StepperNotes
