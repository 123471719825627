import { useRef } from "react"
import Cropper from "react-cropper"
import "cropperjs/dist/cropper.css"
import { ToastContainer, toast } from "react-toastify"
import imageCompression from "browser-image-compression"

import { FileUploadOutlined, ArrowBack, InfoOutlined, Close, DownloadRounded, CropOriginal, Crop } from "@mui/icons-material"
import { CircularProgress } from "@mui/material"
import { useEffect, useState } from "react"
import { FileUploader } from "react-drag-drop-files"
import { Button, Modal, Chip } from "../../../components/components"
import styles from "./BulkImageUpload.module.scss"
import CloseButton from "../../../components/Modal/CloseButton/CloseButton"
import { PDFListExport } from "../../../utils/PDFListExport"
import { getTrimmedStr } from "../../../utils/Validation"
import { axiosPrivate, buildHeaderObject } from "../../../apis/axios"
import useAuth from "../../../hooks/useAuth"

const ErrorMessage = (props: { message: string }) => {
    return (
        <div
            style={{
                display: "flex",
                gap: ".5rem",
                alignItems: "center",
                justifyContent: "center",
                maxWidth: "400px",
            }}
        >
            <InfoOutlined style={{ color: "#EB1A1A" }} />
            <p style={{ margin: "0", color: "#EB1A1A" }}>{props?.message}</p>
        </div>
    )
}

function BulkImageUpload(props: { goBack?: CallableFunction; type?: string }) {
    const cropperRef = useRef<HTMLImageElement>(null)
    const [cropData, setCropData] = useState<any>(null)
    const [maxFail, setMaxFail] = useState<any>([])
    const [editingImage, setEditingImage] = useState("")
    const [cropper, setCropper] = useState<any>()
    const [editingImageIndex, setEditingImageIndex] = useState("")
    const [editingImageName, setEditingImageName] = useState("")
    const [importedData, setImportedData] = useState<any>([])
    const [editListData, setEditListData] = useState<any>([])
    const [uploadListData, setUploadListData] = useState<any>([])
    const [uploadEditListData, setUploadEditListData] = useState<any>([])
    const [openEditModal, setOpenEditModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [uploadStatus, setUploadStatus] = useState("Save & Publish")
    const fileTypes = ["JPEG", "jpg", "WEBP"]
    const [invalidList, setInvalidList] = useState<any>([])
    const [showEdited, setShowEdited] = useState(false)

    const { auth } = useAuth() as any

    const blobToFile = (theBlob: Blob, fileName: string) => {
        var b: any = theBlob
        b.lastModifiedDate = new Date()
        b.name = fileName
        return theBlob
    }

    async function getList() {
        const result = await axiosPrivate.get(`${props.type}/GetImagesID`, buildHeaderObject(auth))
        return result.data.data
    }

    useEffect(() => {
        console.log(uploadListData, uploadEditListData)
    }, [uploadListData])

    const getCropData = async () => {
        if (typeof cropper !== "undefined") {
            console.log(editingImageName)
            cropper
                .getCroppedCanvas({
                    imageSmoothingEnabled: false,
                })
                .toBlob((blob) => {
                    let toFile = blobToFile(blob, editingImageName)
                    let imageFile: any = toFile
                    let options = {
                        maxSizeMB: 0.5,
                        fileType: "image/jpeg",
                        maxWidthOrHeight: 200,
                        useWebWorker: true,
                        onProgress: (progress) => {
                            setLoading(true)
                            if (progress == 100) {
                                setLoading(false)
                            }
                        },
                    }
                    imageCompression(imageFile, options)
                        .then(function (compressedFile) {
                            setCropData(compressedFile)

                            const newImages = [...uploadListData]
                            const uneditedImage = [...uploadEditListData]

                            if (showEdited == true) {
                                newImages[editingImageIndex] = compressedFile
                                setUploadListData(newImages)
                            } else {
                                newImages?.push(compressedFile)
                                setUploadListData(newImages)

                                uneditedImage?.push(importedData[editingImageIndex])
                                setUploadEditListData(uneditedImage)

                                const afterRemove: any = [...editListData]
                                afterRemove.splice(editingImageIndex, 1)
                                setEditListData(afterRemove)

                                const afterRemoveImport: any = [...editListData]
                                afterRemoveImport.splice(editingImageIndex, 1)
                                setImportedData(afterRemoveImport)
                            }

                            setOpenEditModal(!openEditModal)
                        })
                        .catch(function (error) {
                            console.log(error.message)
                        })
                })
        }
    }

    const setCropImageData = (file, index, name) => {
        setEditingImage(file)
        setEditingImageIndex(index)
        setEditingImageName(name.name)
    }

    const setEditCropImageData = (file, index, name) => {
        setEditingImage(URL.createObjectURL(uploadEditListData[index]))
        setEditingImageIndex(index)
        setEditingImageName(name.name)
    }

    const readFile = (files) => {
        setShowEdited(false)
        const newImages = [...importedData]
        const editImages = [...editListData]
        const fails = [...maxFail]

        for (let i = 0; i < files.length; i++) {
            if (files[i].size > 3000000) {
                setMaxFail([fails, files[i].name + " File too large ⛔"])
            } else {
                newImages?.push(files[i])
                editImages?.push(files[i])
                setImportedData(newImages)
                setEditListData(editImages)
            }
        }
    }

    useEffect(() => {
        if (maxFail.length > 0) {
            toast(Msg)
        }
    }, [maxFail])

    const getNames = (file) => {
        return file.name
    }

    async function updateList(images) {
        await axiosPrivate
            .put(`${props.type}/UploadImages`, JSON.stringify(images), buildHeaderObject(auth))
            .then((result) => {
                if (result.status == 200) {
                    setLoading(false)
                    setUploadStatus("Save & Publish")
                    setImportedData([])
                    setEditListData([])
                    setUploadListData([])
                    setUploadEditListData([])
                    toast.success(`Images successfully Uploaded!`)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const uploadAllValidFiles = (files) => {
        if (files.length > 0) {
            const formData = new FormData()
            console.log(props.type, `directories/${props.type?.toLowerCase()}s`, files)

            formData.append("folderpath", `directories/${props.type?.toLowerCase()}s`)
            formData.append("method", "N")
            formData.append("deletingFileName", "-")
            formData.append("renameFileNames", "0")

            for (let index = 0; index < files.length; index++) {
                const file = files[index]
                let ImageID = files[index].name.split(".")[0]
                const fileExtension = file.name.split(".").pop()
                const renamedFile = new File([file], `${ImageID}.${fileExtension}`, { type: file.type })
                formData.append("files", renamedFile)
                console.log("re-FIle", renamedFile)
            }

            axiosPrivate
                .post(`FileUpload/UploadFile`, formData, buildHeaderObject(auth))
                .then((response) => {
                    console.log("Upload-Res", response)

                    const uploadObjectName = `${props?.type?.toLocaleLowerCase()}Image`
                    const objMain: any = { [uploadObjectName]: [] }

                    files.map((file, index) => {
                        const obj = {}

                        const NameWtoEx = file.name.split(".")[0]
                        obj[`num${props.type}ID`] = NameWtoEx
                        obj[`var${props.type}ImagePath`] = file.name
                        objMain[uploadObjectName].push(obj)
                    })
                    updateList(objMain)
                })
                .catch((error) => {
                    console.error(error)
                    setLoading(false)
                })
        } else {
            toast.info(`No valid images to uploaded!`)
            setLoading(false)
            setUploadStatus("Save & Publish")
            setImportedData([])
            setEditListData([])
            setUploadListData([])
            setUploadEditListData([])
        }
    }

    const deleteImage = (index) => {
        const afterRemove = [...importedData]
        afterRemove.splice(index, 1)
        setImportedData(afterRemove)

        const afterRemoveEdit = [...editListData]
        afterRemoveEdit.splice(index, 1)
        setEditListData(afterRemoveEdit)
    }

    const deleteCroppedImage = (index) => {
        const afterRemove = [...uploadListData]
        afterRemove.splice(index, 1)
        setUploadListData(afterRemove)

        const afterRemoveEdit = [...uploadEditListData]
        afterRemoveEdit.splice(index, 1)
        setUploadEditListData(afterRemoveEdit)
    }
    const Msg: any = ({ closeToast, toastProps }) => (
        <div>
            {maxFail.map((msg) => {
                return <p>{msg}</p>
            })}
        </div>
    )

    const downloadInvalidPDF = () => {
        PDFListExport(invalidList, props.type)
    }

    const uploadBulk = () => {
        if (uploadListData.length <= 0) {
            toast.info("Please add and crop images to upload!")
            setShowEdited(false)
        } else {
            console.log(uploadListData.length)
            setLoading(true)
            setUploadStatus("Validating")
            getList()
                .then((x) => {
                    let validatedImageList: any = []

                    if (x == undefined || x == null) {
                        alert("No results found!")
                    } else {
                        const invList = [...invalidList]
                        uploadListData.map((file, index) => {
                            const NameWtoEx = file.name.split(".")[0]
                            if (isNaN(NameWtoEx)) {
                                invList?.push(file.name)
                                setInvalidList(invList)
                            } else {
                                console.log(x)
                                const systemImageList: any = x
                                const valid = systemImageList.toString().includes(NameWtoEx)
                                if (valid) {
                                    validatedImageList.push(file)
                                } else {
                                    invList?.push(file.name)
                                    setInvalidList(invList)
                                }
                                console.log(valid)
                            }
                        })

                        console.log("valid image list", validatedImageList)
                        console.log(invList)
                        setUploadStatus("Uploading")
                        uploadAllValidFiles(validatedImageList)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setLoading(false)
                })
        }
    }
    const discardChanges = () => {
        setImportedData([])
        setEditListData([])
        setMaxFail([])
        setLoading(false)
        setUploadStatus("Save & Publish")
        setInvalidList([])
        setUploadListData([])
        setUploadEditListData([])
    }

    return (
        <>
            <>
                <ToastContainer />
                <div style={{ margin: "0.8rem 4rem" }}>
                    <div>
                        <Button variant="text" color="default" className={styles.backButton} onClick={() => props.goBack?.(false)}>
                            <ArrowBack style={{ color: "#000" }} />
                            Back
                        </Button>
                    </div>

                    <div style={{ display: "flex", margin: ".5rem 0", width: "100%", gap: "4rem" }}>
                        <div style={{ height: "100%", backgroundColor: "#f1f5fd", borderRadius: "10px" }}>
                            <div
                                style={{
                                    padding: "2rem",
                                    borderBottom: "1px solid #E5E7EB",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: ".3rem",
                                }}
                                className={styles.instructionsConatiner}
                            >
                                <h3 className={styles.instructions}>Uploading Instructions</h3>
                                <p className={styles.instructions}>Rename the images with the corresponding</p>
                                <p className={styles.instructions}>
                                    <strong>{props.type === "Location" ? "SALON" : props.type?.toUpperCase()} REGISTRATION ID</strong>
                                </p>
                            </div>

                            <div
                                style={{
                                    padding: "2rem",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "2rem",
                                    minWidth: "270px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: ".3rem",
                                    }}
                                    className={styles.instructionsConatiner}
                                >
                                    <p className={styles.instructions}>Upload {props.type == "Location" ? "Salon" : props.type?.toUpperCase()} imagess</p>
                                    <p className={styles.instructionsLight}>Only JPG and WEBP files are allowed.</p>
                                    <p className={styles.instructionsLight}>Minimum image dimensions: 200 X 200 px.</p>
                                </div>

                                <div
                                    style={{
                                        border: "2px dashed #5f9cfe",
                                        borderRadius: "8px",
                                        padding: ".8rem",
                                        backgroundColor: "#fff",
                                    }}
                                >
                                    {
                                        <FileUploader
                                            handleChange={readFile}
                                            onDrop={readFile}
                                            disabled={loading ? true : false}
                                            name="file"
                                            types={fileTypes}
                                            multiple={true}
                                            label=""
                                            children={
                                                <div className={styles.flex}>
                                                    <FileUploadOutlined style={{ color: "#2076FF" }} />
                                                    <p className={styles.uploadText}>Drag and drop or browse to choose a file</p>
                                                </div>
                                            }
                                        />
                                    }
                                </div>

                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    {invalidList.length > 0 ? (
                                        <>
                                            <ErrorMessage message={`${invalidList.length} mages with invalid ${props.type?.toUpperCase() == "Location" ? "Salon" : props.type?.toUpperCase()} IDs`} />
                                            <Button variant="contained" color="primary" className={styles.backButton} onClick={() => downloadInvalidPDF()}>
                                                <DownloadRounded style={{ color: "#ffff" }} />
                                            </Button>{" "}
                                        </>
                                    ) : null}
                                </div>

                                <div style={{ width: "100%", display: "flex", gap: ".5rem", flexDirection: "column" }}>
                                    <Button
                                        disabled={loading}
                                        text={uploadStatus}
                                        variant="contained"
                                        color={loading ? "success" : "primary"}
                                        onClick={() => uploadBulk()}
                                        className={styles.publishButton}
                                    >
                                        {loading ? (
                                            <>
                                                &nbsp;
                                                <CircularProgress size={20} sx={{ color: "#fff" }} />
                                            </>
                                        ) : null}
                                    </Button>
                                    <Button variant="text" color="default" disabled={loading} onClick={() => discardChanges()} className={styles.discardButton}>
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div style={{ width: "100%" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    {!showEdited ? (
                                        <p style={{ color: "#6B7280" }}>
                                            <span style={{ color: "#2076FF" }}>{editListData.length} images </span>
                                            loaded
                                        </p>
                                    ) : (
                                        <p style={{ color: "#6B7280" }}>
                                            <span style={{ color: "#2076FF" }}>{uploadListData.length} images </span>
                                            cropped
                                        </p>
                                    )}
                                </div>
                                <div className={styles.toggleContainer}>
                                    <Button
                                        iconLeft={<CropOriginal className={styles.buttonIcon} />}
                                        variant="outlined"
                                        onClick={() => setShowEdited(false)}
                                        className={!showEdited ? styles.btnActive : styles.btnToggle}
                                        color="primary"
                                    >
                                        Uncropped
                                        <div style={{ marginLeft: "5px" }}>
                                            <Chip label={editListData.length} shape="round" customStyle="Primary" />
                                        </div>
                                    </Button>
                                    <Button
                                        color="primary"
                                        iconLeft={<Crop className={styles.buttonIcon} />}
                                        variant="outlined"
                                        onClick={() => setShowEdited(true)}
                                        className={showEdited ? styles.btnActive : styles.btnToggle}
                                    >
                                        Cropped
                                        <div style={{ marginLeft: "5px" }}>
                                            <Chip label={uploadListData.length} shape="round" customStyle="Primary" />
                                        </div>
                                    </Button>
                                </div>
                            </div>

                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(4, 1fr)",
                                    gridColumnGap: "2rem",
                                    rowGap: "2ch",
                                    marginTop: "0.6rem",
                                }}
                            >
                                {showEdited
                                    ? Array.from<Blob>(uploadListData).map((files, index) => {
                                          const fileName = getNames(files)
                                          const NameWtoEx = fileName.split(".")[0]

                                          return (
                                              <div>
                                                  <div style={{ textAlign: "end" }}>
                                                      <Button variant="text" color="default" onClick={() => deleteCroppedImage(index)} className={styles.closeButton}>
                                                          <Close />
                                                      </Button>
                                                  </div>

                                                  <img
                                                      src={URL.createObjectURL(files)}
                                                      style={{
                                                          objectFit: "cover",
                                                          borderRadius: "5px",
                                                          marginBottom: ".3rem",
                                                          width: "100%",
                                                          height: "200px",
                                                          border: "1px solid #bfbfbf",
                                                      }}
                                                  />
                                                  <div
                                                      style={{
                                                          display: "flex",
                                                          justifyContent: "space-between",
                                                          alignItems: "center",
                                                      }}
                                                  >
                                                      <div>
                                                          <p style={{ margin: "0", color: "#4B5563" }}></p>
                                                      </div>
                                                      <div>
                                                          <p style={{ margin: "0", color: "#4B5563" }}>(ID: {getTrimmedStr(NameWtoEx, 10)})</p>
                                                      </div>
                                                      <div>
                                                          {!loading ? (
                                                              <Button
                                                                  className={styles.cropButton}
                                                                  variant="contained"
                                                                  color="primary"
                                                                  onClick={() => {
                                                                      {
                                                                          setOpenEditModal(!openEditModal)
                                                                      }
                                                                      {
                                                                          setEditCropImageData(URL.createObjectURL(files), index, files)
                                                                      }
                                                                  }}
                                                              >
                                                                  Crop & Fix
                                                              </Button>
                                                          ) : null}
                                                      </div>
                                                  </div>
                                              </div>
                                          )
                                      })
                                    : Array.from<Blob>(editListData).map((files, index) => {
                                          const fileName = getNames(files)
                                          const NameWtoEx = fileName.split(".")[0]

                                          return (
                                              <div>
                                                  <div style={{ textAlign: "end" }}>
                                                      <Button variant="text" color="default" onClick={() => deleteImage(index)} className={styles.closeButton}>
                                                          <Close />
                                                      </Button>
                                                  </div>

                                                  <img
                                                      src={URL.createObjectURL(files)}
                                                      style={{
                                                          objectFit: "cover",
                                                          borderRadius: "5px",
                                                          marginBottom: ".3rem",
                                                          width: "100%",
                                                          height: "200px",
                                                          border: "1px solid #bfbfbf",
                                                      }}
                                                  />
                                                  <div
                                                      style={{
                                                          display: "flex",
                                                          justifyContent: "space-between",
                                                          alignItems: "center",
                                                      }}
                                                  >
                                                      <div>
                                                          <p style={{ margin: "0", color: "#4B5563" }}></p>
                                                      </div>
                                                      <div>
                                                          <p style={{ margin: "0", color: "#4B5563" }}>(ID: {getTrimmedStr(NameWtoEx, 10)})</p>
                                                      </div>
                                                      <div>
                                                          {!loading ? (
                                                              <Button
                                                                  className={styles.cropButton}
                                                                  variant="contained"
                                                                  color="primary"
                                                                  onClick={() => {
                                                                      {
                                                                          setOpenEditModal(!openEditModal)
                                                                      }
                                                                      {
                                                                          setCropImageData(URL.createObjectURL(files), index, files)
                                                                      }
                                                                  }}
                                                              >
                                                                  Crop & Fix
                                                              </Button>
                                                          ) : null}
                                                      </div>
                                                  </div>
                                              </div>
                                          )
                                      })}
                            </div>
                        </div>
                    </div>
                </div>
            </>

            <Modal open={openEditModal}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "1rem",
                    }}
                >
                    <div style={{ textAlign: "end", width: "100%" }}>
                        <CloseButton onClick={() => setOpenEditModal(!openEditModal)} />
                    </div>

                    <div style={{ backgroundColor: "#fff" }}>
                        <Cropper
                            style={{ maxWidth: "500px", maxHeight: "500px" }}
                            modal={true}
                            src={editingImage}
                            cropBoxResizable={true}
                            initialAspectRatio={1}
                            aspectRatio={1}
                            guides={true}
                            minCropBoxHeight={200}
                            scalable={false}
                            minCropBoxWidth={200}
                            highlight={true}
                            zoomable={true}
                            movable={true}
                            onInitialized={(instance) => {
                                setCropper(instance)
                            }}
                            ref={cropperRef}
                        />
                    </div>

                    <div style={{ textAlign: "end", width: "100%" }}>
                        <Button variant="outlined" color="primary" className={styles.buttonText} onClick={() => getCropData()}>
                            Crop & Fix
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default BulkImageUpload
