import { AddCircleOutline, AddOutlined, Cancel, DeleteOutline, FileUploadOutlined, Mic, RemoveCircleOutline, Warning } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, IconButton, MenuItem, Switch, TextField } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import { Avatar, Button, CloseButton, Chip } from "../../components"
import { Modal } from "../../components/components"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import WellnessModal from "./WellnessModal"
import { axiosPrivate, buildHeaderObject } from "../../apis/axios"
import { toast } from "react-toastify"
import { fullDate, shortDate } from "../../utils/DateFormatter"
import { FileUploader } from "react-drag-drop-files"
import AdvancedDropDown from "../AdvancedDropDown/AdvancedDropDown"
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3"
import placeholderImage from "../../images/pet_placeholder.png"

import Summery from "./Summery"
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition"
import Report from "./Report"
import InputMask from "react-input-mask"
import { sendAppointmentStaticMessage, sendOutNotification } from "../../utils/Messaging"
import useAuth from "../../hooks/useAuth"

const CheckInModal = (props: { bookingDetails; isOpenCheckInModal; setIsOpenCheckInModal: CallableFunction; appProducts?; appServices?; isSuccess? }) => {
    const [isOpenReportInModal, setIsOpenReportInModal] = useState(false)
    const label = { inputProps: { "aria-label": "Checkbox demo" } }
    const [isOpenWellnessCheckModal, setIsOpenWellnessCheckModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isOpenEditDetails, setIsOpenEditDetails] = useState(false)
    const [knowAboutUsList, setKnowAboutUsList] = useState<any>([])
    const [showFieldsDropOff, setShowFieldsDropOff] = useState(0)
    const [checkInDetails, setCheckInDetails] = useState<any>([])
    const [previousWellnessData, setPreviousWellnessData] = useState<any>([])

    const { auth } = useAuth() as any

    const [showFieldsPickup, setShowFieldsPickup] = useState(0)
    const [waverDocuments, setWaverDocuments] = useState<any>([])
    const [isOpenWaverSelect, setIsOpenWaverSelect] = useState(false)
    const [loading, setLoading] = useState(false)
    const fileTypes = ["JPEG", "jpg", "WEBP", "PDF", "DOC", "DOCX", "png", "PNG"]
    const [selectedWaver, setSelectedWaver] = useState<any>("")
    const [pickupType, setPickupType] = useState<any>("")
    const [linkedCustomers, setLinkedCustomers] = useState<any>([])
    const linkedRef = useRef<any>()

    const linkedPickupRef = useRef<any>()
    const [linkedCustomerPickUp, setLinkedCustomerPickUp] = useState(0)
    const [isFragrance, setIsFragrance] = useState(false)
    const [isAllergies, setIsAllergies] = useState(false)
    const [relationshipList, setRelationshipList] = useState<any>([])
    const relationshipPickUpRef = useRef<any>()
    const relationshipDropOffRef = useRef<any>()
    const [Note, setNote] = useState("")
    const [cageID, setCageID] = useState("")

    const [location, setLocation] = useState("1")
    const [cagesList, setCagesList] = useState<any>([])

    // Set the AWS Region.
    const REGION = "us-east-1" //e.g. "us-east-1"
    // Create an Amazon S3 service client object.
    const creds = {
        accessKeyId: "AKIAXQN6N4QUDD47RQQB",
        secretAccessKey: "3WAeWPzq0MA5MGBNsHhPz+HfEOmXJSNwJpldxwSN",
    }

    const s3Client = new S3Client({
        region: REGION,
        credentials: creds,
    })

    const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition()

    async function getLinkedUsersByCustomerID(id) {
        setIsLoading(true)
        const result: any = await axiosPrivate.get(`Customer/GetLinkedAccountsByID/${id}`, buildHeaderObject(auth))
        return result
    }

    const setDropOffTypeChanged = (e) => {
        if (e.target.value === "Third Party") {
            setShowFieldsDropOff(3)
            const checkInData = { ...checkInDetails } as any
            checkInData.DropOffPersonType = "Third Party"
            setCheckInDetails(checkInData)
        } else if (e.target.value === "Linked Customer") {
            setShowFieldsDropOff(2)
            const checkInData = { ...checkInDetails } as any
            checkInData.DropOffPersonType = "Linked Customer"
            setCheckInDetails(checkInData)
        } else {
            setShowFieldsDropOff(1)
            const checkInData = { ...checkInDetails } as any
            checkInData.DropOffPersonType = "Customer"
            setCheckInDetails(checkInData)
        }
    }

    const setPickupTypeChanged = (e) => {
        if (e.target.value === "Third Party") {
            setShowFieldsPickup(3)
            const checkInData = { ...checkInDetails } as any
            checkInData.PickUpPersonType = "Third Party"
            setCheckInDetails(checkInData)
        } else if (e.target.value === "Linked Customer") {
            setShowFieldsPickup(2)
            const checkInData = { ...checkInDetails } as any
            checkInData.PickUpPersonType = "Linked Customer"
            setCheckInDetails(checkInData)
        } else {
            setShowFieldsPickup(1)
            const checkInData = { ...checkInDetails } as any
            checkInData.PickUpPersonType = "Customer"
            setCheckInDetails(checkInData)
        }
    }

    async function KnowAboutUsGetAll() {
        setIsLoading(true)
        const result = await axiosPrivate.get(`KnowAboutUs/GetAll`, buildHeaderObject(auth))
        return result
    }
    async function ContactPersonGetAll() {
        setIsLoading(true)
        const result = await axiosPrivate.get(`ContactPerson/GetAll`, buildHeaderObject(auth))
        return result
    }

    useEffect(() => {
        if (props.isOpenCheckInModal === true) {
            ContactPersonGetAll()
                .then((res) => {
                    setRelationshipList(res.data.contactPerson)
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                    toast.error("Something went wrong!")
                })
            getLinkedUsersByCustomerID(props.bookingDetails.Customer.numCustomerID)
                .then((response) => {
                    setLinkedCustomers(response.data.customer)
                })
                .catch((err) => {
                    console.log(err)
                    toast.error("Something went wrong!")
                    setIsLoading(false)
                })
        }
    }, [props.isOpenCheckInModal])

    async function getWaiverDocumentByPetTypeID(id) {
        const result = await axiosPrivate.get(`WaiverDocument/GetByPetTypeID?numPetTypeID=${id}`, buildHeaderObject(auth))
        return result.data.waiverDocument
    }
    const selectWaverFile = (index) => {
        setSelectedWaver(index)
        setIsOpenWaverSelect(true)
    }

    const readFile = (file) => {
        const waver = [...waverDocuments]
        waver[selectedWaver].file = file
        waver[selectedWaver].fileName = "Esy-Pet-temp-23/7/18:9.10"
        setWaverDocuments(waver)
    }

    const removeWaverFile = (index) => {
        const waver = [...waverDocuments]
        waver[index].file = null
        waver[selectedWaver].fileName = null
        setWaverDocuments(waver)
    }

    async function getCheckinDetails(bookingID) {
        setLoading(true)
        await axiosPrivate
            .get(`CheckIn/getCheckInByAppointmentID?AppointmentID=${bookingID}`, buildHeaderObject(auth))
            .then((result) => {
                if (result.status === 200) {
                    console.log("checkIn", result.data.CheckIn)
                    setCageID(result.data.CheckIn?.Pet?.CageID)
                    setCheckInDetails(result.data.CheckIn)
                    KnowAboutUsGetAll()
                        .then((res) => {
                            let newSpec: any = []
                            setKnowAboutUsList(res.data.knowAboutUs)
                            res.data.knowAboutUs.map((item, index) => {
                                let matching = result.data.CheckIn.HowDoYouKnowAboutUs.some((x) => x.numKnowAboutUsID === item.numKnowAboutUsID)
                                if (matching) {
                                    newSpec.push({
                                        numCheckInID: result.data.CheckIn.CheckInID,
                                        numKnowAboutUsID: item.numKnowAboutUsID,
                                        name: item.varKnowAboutUsMethod,
                                        checked: true,
                                        bitActive: true,
                                    })
                                } else {
                                    newSpec.push({
                                        numCheckInID: result.data.CheckIn.CheckInID,
                                        numKnowAboutUsID: item.numKnowAboutUsID,
                                        name: item.varKnowAboutUsMethod,
                                        checked: false,
                                        bitActive: true,
                                    })
                                }
                            })
                            setKnowAboutUsList(newSpec)

                            getWaiverDocumentByPetTypeID(props.bookingDetails.Pet.PetTypeID)
                                .then((response) => {
                                    console.log("W-Main", response, result.data.CheckIn.Waivers)
                                    const wavers: any = []
                                    response.map((item, index) => {
                                        let matching = result.data.CheckIn.Waivers.some((x) => x.numWaiverDocumentID === item.numWaiverDocumentID)
                                        let AvailIndex = result.data.CheckIn.Waivers.findIndex((x) => x.numWaiverDocumentID === item.numWaiverDocumentID)
                                        if (matching) {
                                            wavers.push({
                                                id: item.numWaiverDocumentID,
                                                petID: props.bookingDetails.Pet.PetID,
                                                name: item.varWaiverDocumentName,
                                                desc: item.varWaiverDocumentDescription,
                                                fileName: result.data.CheckIn.Waivers[AvailIndex].varAppWaiverDocumentPath,
                                                file: null,
                                            })
                                        } else {
                                            wavers.push({
                                                id: item.numWaiverDocumentID,
                                                petID: props.bookingDetails.Pet.PetID,
                                                name: item.varWaiverDocumentName,
                                                desc: item.varWaiverDocumentDescription,
                                                fileName: null,
                                                file: null,
                                            })
                                        }
                                    })
                                    setWaverDocuments(wavers)
                                })
                                .catch((err) => {
                                    console.log(err)
                                    toast.error("Something went wrong!")
                                })

                            setIsLoading(false)
                        })
                        .catch((err) => {
                            console.log(err)
                            setIsLoading(false)
                            toast.error("Something went wrong!")
                        })
                    if (result.data.CheckIn?.DropOffPersonType === "Third Party") {
                        setShowFieldsDropOff(3)
                    } else if (result.data.CheckIn?.DropOffPersonType === "Linked Customer") {
                        setShowFieldsDropOff(2)
                    } else {
                        setShowFieldsDropOff(1)
                    }

                    if (result.data.CheckIn?.PickUpPersonType === "Third Party") {
                        setShowFieldsPickup(3)
                    } else if (result.data.CheckIn?.PickUpPersonType === "Linked Customer") {
                        setShowFieldsPickup(2)
                    } else {
                        setShowFieldsPickup(1)
                    }
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    useEffect(() => {
        console.log(":Cage", cageID)
    }, [cageID])

    async function GetPreviousWellnessInspectionByPetID(PetID) {
        await axiosPrivate
            .get(`CheckIn/GetPreviousWellnessInspectionByPetID?numPetID=${PetID}`, buildHeaderObject(auth))
            .then((result) => {
                if (result.status === 200) {
                    console.log("pre-wellness", result.data[0])
                    setPreviousWellnessData(result.data)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    async function GetCagesByLocationID(LocationID) {
        await axiosPrivate
            .get(`CheckIn/GetCagesByLocationID?numLocationID=${LocationID}`, buildHeaderObject(auth))
            .then((result) => {
                if (result.status === 200) {
                    console.log("cages", result)
                    setCagesList(result.data)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    useEffect(() => {
        if (props.isOpenCheckInModal === true) {
            getCheckinDetails(props.bookingDetails.numAppointmentID)
            GetPreviousWellnessInspectionByPetID(props.bookingDetails.Pet.PetID)
            GetCagesByLocationID(location)
        }
    }, [props.isOpenCheckInModal])

    const checkIfNull = (value, type) => {
        if (value === null || value === "") {
            if (type === "bool") {
                return false
            } else {
                return ""
            }
        } else {
            return value
        }
    }

    async function putCheckIn(data) {
        console.log("putt", data, props.bookingDetails)
        await axiosPrivate
            .put(`CheckIn/UpdateCheckIn`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                if (result.status === 200) {
                    if (result.data?.status === "1") {
                        let code = "CC"
                        sendAppointmentStaticMessage(props.bookingDetails.numAppointmentID, "Appointment", 2, "E", [], code)

                        let notificationPostBody = {
                            notificationType: "success",
                            subject: "Confirmation of Check-In Process Ref#:" + props.bookingDetails.numAppointmentID,
                            notificationMessage: `Check-in process for your beloved pet ${props.bookingDetails?.Pet?.PetName}, has been successfully completed at our ${props.bookingDetails?.varLocationName} facility`,
                            locationID: 1,
                            locationName: props.bookingDetails?.varLocationName,
                            recipientType: "C",
                            recipientID: props.bookingDetails?.Customer?.numCustomerID,
                            recipientName: props.bookingDetails?.Customer?.varCustomerFirstName + " " + props.bookingDetails?.Customer?.varCustomerLastName,
                            isSeen: false,
                            isPushed: false,
                        }
                        sendOutNotification(notificationPostBody)
                    } else {
                    }
                    //MESSAGECHECKINDONE
                    toast.success(`Check-In details updated!`)
                    props?.isSuccess(true)
                    props?.setIsOpenCheckInModal(false)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const handleCheckin = () => {
        let checkedKnowAboutUs: any = []
        knowAboutUsList
            .filter((x) => x.checked === true)
            .map((tab, index) => {
                checkedKnowAboutUs.push(tab)
            })

        let payload = {
            checkInID: checkInDetails.CheckInID,
            appointmentID: checkInDetails?.Appointment?.numAppointmentID,
            checkInStatus: checkInDetails?.CheckInStatus,
            dropOffPersonType: checkInDetails?.DropOffPersonType,
            dropOffPersonID:
                checkInDetails?.DropOffPersonType === "Customer"
                    ? checkInDetails?.Customer?.numCustomerID
                    : checkInDetails?.DropOffPersonType === "Linked Customer"
                    ? checkInDetails?.DropOffPersonID
                    : checkInDetails?.DropOffPersonType === "Third Party"
                    ? null
                    : null,
            dropOffThirdPartyName: checkInDetails?.DropOffPersonType === "Third Party" ? checkInDetails?.DropOffThirdPartyName : null,
            dropOffThirdPartyRelationshipID: checkInDetails?.DropOffPersonType === "Third Party" ? checkInDetails?.DropOffThirdPartyRelationshipID : null,
            dropOffThirdPartyContactNo: checkInDetails?.DropOffPersonType === "Third Party" ? checkInDetails?.DropOffThirdPartyContactNo : null,
            pickUpPersonType: checkInDetails?.PickUpPersonType,
            pickUpPersonID:
                checkInDetails?.PickUpPersonType === "Customer"
                    ? checkInDetails?.Customer?.numCustomerID
                    : checkInDetails?.PickUpPersonType === "Linked Customer"
                    ? checkInDetails?.PickUpPersonID
                    : checkInDetails?.PickUpPersonType === "Third Party"
                    ? null
                    : null,
            pickUpThirdPartyName: checkInDetails?.PickUpPersonType === "Third Party" ? checkInDetails?.PickUpThirdPartyName : null,
            pickUpThirdPartyRelationshipID: checkInDetails?.PickUpPersonType === "Third Party" ? checkInDetails?.PickUpThirdPartyRelationshipID : null,
            pickUpThirdPartyContactNo: checkInDetails?.PickUpPersonType === "Third Party" ? checkInDetails?.PickUpThirdPartyContactNo : null,
            isPetHasAllergies: checkInDetails?.IsPetHasAllergies,
            isFragrance: checkInDetails?.IsFragrance,
            isAdditionalCharges: checkInDetails?.IsPetHasAllergies,
            cageID: cageID,
            notes: checkInDetails?.Notes,
            numEditedBy: 1,
            numLocationID: 1,
            knowAboutUsList: checkedKnowAboutUs,
        }
        console.log("sent-", payload)
        putCheckIn(payload)
    }

    useEffect(() => {
        const checkInData = { ...checkInDetails }
        checkInData.Notes = checkInData.Notes + " " + transcript
        setCheckInDetails(checkInData)
    }, [transcript])

    const handleUpload = (e) => {
        setIsLoading(true)
        e.preventDefault()
        const formData = new FormData()

        formData.append("folderpath", "waiver")
        formData.append("method", "N")
        formData.append("deletingFileName", "-")
        formData.append("renameFileNames", "1")

        for (let index = 0; index < waverDocuments.length; index++) {
            if (waverDocuments[index].file !== null && waverDocuments[index].file !== "") {
                const file = waverDocuments[index].file
                let waiverID = waverDocuments[index].id
                const fileExtension = file.name.split(".").pop()
                const renamedFile = new File([file], `${waiverID}.${fileExtension}`, { type: file.type })
                formData.append("files", renamedFile)
            }
        }
        axiosPrivate
            .post(`FileUpload/UploadFile`, formData, buildHeaderObject(auth))
            .then((response) => {
                console.log("Upload-Res", response.data.files[0])

                axiosPrivate
                    .get(`CheckIn/GetAppWaiversByCheckInID?numCheckInID=${checkInDetails.CheckInID}`, buildHeaderObject(auth))
                    .then((result) => {
                        if (result.status === 200) {
                            let checkInWaivers = result.data
                            checkInWaivers.push({
                                checkInID: checkInDetails.CheckInID,
                                numWaiverDocumentID: parseInt(response.data.files[0].id),
                                varWaiverDocumentPath: response.data.files[0].renamedFileName,
                                dtExpireDate: "2023-07-19T14:10:10.810Z",
                                bitActive: true,
                            })
                            console.log("ckW-updated", checkInWaivers)
                            axiosPrivate
                                .post(`CheckIn/PopulateAppWaiverDocuments`, JSON.stringify(checkInWaivers), buildHeaderObject(auth))
                                .then((result) => {
                                    if (result.status === 200) {
                                        getWaiverDocumentByPetTypeID(props.bookingDetails.Pet.PetTypeID)
                                            .then((response) => {
                                                const wavers: any = []
                                                response.map((item, index) => {
                                                    let matching = checkInWaivers.some((x) => x.numWaiverDocumentID === item.numWaiverDocumentID)
                                                    let AvailIndex = checkInWaivers.findIndex((x) => x.numWaiverDocumentID === item.numWaiverDocumentID)
                                                    if (matching) {
                                                        wavers.push({
                                                            id: item.numWaiverDocumentID,
                                                            petID: props.bookingDetails.Pet.PetID,
                                                            name: item.varWaiverDocumentName,
                                                            desc: item.varWaiverDocumentDescription,
                                                            fileName: checkInWaivers[AvailIndex].varWaiverDocumentPath,
                                                            file: null,
                                                        })
                                                    } else {
                                                        wavers.push({
                                                            id: item.numWaiverDocumentID,
                                                            petID: props.bookingDetails.Pet.PetID,
                                                            name: item.varWaiverDocumentName,
                                                            desc: item.varWaiverDocumentDescription,
                                                            fileName: null,
                                                            file: null,
                                                        })
                                                    }
                                                })
                                                setWaverDocuments(wavers)
                                                setIsOpenWaverSelect(false)
                                                setIsLoading(false)
                                            })
                                            .catch((err) => {
                                                console.log(err)
                                                toast.error("Something went wrong!")
                                                setIsLoading(false)
                                            })
                                    }
                                })
                                .catch((err) => {
                                    console.log(err)
                                    toast.error("Something went wrong!")
                                    setIsLoading(false)
                                })
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        toast.error("Something went wrong!")
                        setIsLoading(false)
                    })
            })
            .catch((error) => {
                console.error(error)
                setIsLoading(false)
            })
    }

    useEffect(() => {
        console.log("wwE", waverDocuments)
    }, [waverDocuments])
    return (
        <div>
            <Modal open={props.isOpenCheckInModal} backgroundColor={"#F9FAFB"}>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem", width: "100%" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                        <p style={{ fontSize: "28px", fontWeight: "600", color: "#192252" }}>Check In List</p>
                        <CloseButton onClick={() => props.setIsOpenCheckInModal(false)} />
                    </div>

                    <div style={{ padding: "1rem", width: "100%" }}>
                        <div
                            style={{ display: "flex", justifyContent: "space-around", border: "1px solid #F3F4F6", padding: "10px 0", borderRadius: "8px", backgroundColor: "#FFFFFF", width: "100%" }}
                        >
                            <div>
                                <p style={{ fontSize: "12px", fontWeight: "400", color: "#606D93" }}>Booking ID #</p>
                                <p style={{ fontSize: "14px", fontWeight: "500", color: "#192252" }}>#{props?.bookingDetails?.numAppointmentID}</p>
                            </div>
                            <div>
                                <p style={{ fontSize: "12px", fontWeight: "400", color: "#606D93" }}>Date/Time</p>
                                <p style={{ fontSize: "14px", fontWeight: "500", color: "#192252" }}>{fullDate(props?.bookingDetails?.dtApptStartTime)}</p>
                            </div>
                            <div>
                                <p style={{ fontSize: "12px", fontWeight: "400", color: "#606D93" }}>Center</p>
                                <p style={{ fontSize: "14px", fontWeight: "500", color: "#192252" }}>{props?.bookingDetails?.varLocationName}</p>
                            </div>
                            <div style={{ borderRight: "1px solid #E5E7EB" }}></div>
                            <div>
                                <p style={{ fontSize: "12px", fontWeight: "400", color: "#606D93" }}>Customer</p>
                                <p style={{ fontSize: "14px", fontWeight: "500", color: "#192252" }}>
                                    {props?.bookingDetails?.Customer?.varCustomerFirstName} {props?.bookingDetails?.Customer?.varCustomerLastName}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div style={{ height: "500px", overflowY: "scroll" }}>
                        <div style={{ width: "100%" }}>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" style={{ backgroundColor: "#F9FAFB", margin: "0" }}>
                                    <div style={{ width: "95%", display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                            <div style={{ backgroundColor: "#EFF3FA", borderRadius: "4px", padding: "5px 10px 5px 10px", color: "#192252", fontSize: "16px", fontWeight: "600" }}>
                                                1
                                            </div>
                                            <p style={{ margin: "0", color: "#192252", fontSize: "16px", fontWeight: "600" }}>Pet details</p>
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ width: "95%", gap: "3rem", padding: "1rem", borderRadius: "8px", backgroundColor: "#FFFFFF" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: ".5rem" }}>
                                            <div style={{ display: "flex", justifyContent: "space-between", gap: ".6rem" }}>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Avatar
                                                        shape="rounded"
                                                        img={
                                                            props.bookingDetails.Pet?.PetImagePath == "" || props.bookingDetails.Pet?.PetImagePath == null
                                                                ? placeholderImage
                                                                : `${process.env.REACT_APP_MEDIA_URL}directories/pets/` + props.bookingDetails.Pet?.PetImagePath
                                                        }
                                                        isVip={false}
                                                        className={"medium"}
                                                    />
                                                </div>

                                                <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between" }}>
                                                    <div style={{ fontWeight: 600, fontSize: "16px", color: "#002867" }}>{props.bookingDetails.Pet?.PetName}</div>
                                                    <div style={{ display: "flex", gap: "12px", alignItems: "center", marginTop: ".2rem" }}>
                                                        <div style={{ fontWeight: 400, fontSize: 14, color: "#848FAC" }}>{props.bookingDetails.Pet?.PetBreed}</div>
                                                    </div>
                                                    <div style={{ display: "flex", marginTop: ".5rem", gap: ".3rem", alignItems: "center" }}>
                                                        <Chip
                                                            size="small"
                                                            customBackgroundColor={"#EFF3FA"}
                                                            customBorder={"#EFF3FA"}
                                                            customBorderRadius={"16px"}
                                                            fontColor={"#192252"}
                                                            label={props.bookingDetails.Pet?.varPetSizeName}
                                                        />
                                                        <Chip
                                                            size="small"
                                                            customBackgroundColor={"#EFF3FA"}
                                                            customBorder={"#EFF3FA"}
                                                            customBorderRadius={"16px"}
                                                            fontColor={"#192252"}
                                                            label={props.bookingDetails.Pet?.PetSex === "M" ? "Male" : "Female"}
                                                        />
                                                        <Chip
                                                            size="small"
                                                            customBackgroundColor={"#EFF3FA"}
                                                            customBorder={"#EFF3FA"}
                                                            customBorderRadius={"16px"}
                                                            fontColor={"#192252"}
                                                            label={props.bookingDetails.Pet?.PetType}
                                                        />
                                                        <Chip
                                                            size="small"
                                                            customBackgroundColor={"#EFF3FA"}
                                                            customBorder={"#EFF3FA"}
                                                            customBorderRadius={"16px"}
                                                            fontColor={"#192252"}
                                                            label={props.bookingDetails.Pet?.PetColor}
                                                        />
                                                        {/* <Warning style={{ color: "#EB1A1A", fontSize: "20px" }} /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <Button
                                                    color="info"
                                                    variant="text"
                                                    text="Edit Details"
                                                    onClick={() => {
                                                        setIsOpenEditDetails(!isOpenEditDetails)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {}
                                        <div style={{ backgroundColor: "#FDEDED", borderRadius: "8px", padding: ".5rem 1rem 0.5rem 1rem", width: "83%", marginLeft: "60px" }}>
                                            <p style={{ color: "#720A0A", fontWeight: "500" }}>Medical condition</p>
                                            <div style={{ display: "flex", gap: ".2rem" }}>
                                                {props.bookingDetails.Pet !== undefined
                                                    ? props.bookingDetails.Pet.Medical.length > 0
                                                        ? props.bookingDetails.Pet?.Medical.map((medical) => {
                                                              if (medical.varMedicalConditionName !== null) {
                                                                  return (
                                                                      <Chip
                                                                          size="small"
                                                                          customBackgroundColor={"#EB1A1A"}
                                                                          customBorderRadius={"12px"}
                                                                          fontColor={"#FFFF"}
                                                                          label={medical.varMedicalConditionName}
                                                                      />
                                                                  )
                                                              } else return <p style={{ margin: "0", color: "#720a0a" }}>--</p>
                                                          })
                                                        : "--"
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div style={{ width: "100%" }}>
                            <Accordion defaultExpanded={checkIfNull(!checkInDetails?.IsToggleDropOffDetails, "bool")}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" style={{ backgroundColor: "#F9FAFB", margin: "0" }}>
                                    <div style={{ width: "95%", display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                            <div style={{ backgroundColor: "#EFF3FA", borderRadius: "4px", padding: "5px 10px 5px 10px", color: "#192252", fontSize: "16px", fontWeight: "600" }}>
                                                2
                                            </div>
                                            <p style={{ margin: "0", color: "#192252", fontSize: "16px", fontWeight: "600" }}>Drop off details</p>
                                        </div>
                                        <Switch disabled={true} checked={checkIfNull(checkInDetails?.IsToggleDropOffDetails, "bool")} />
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "1rem", rowGap: "2ch", alignItems: "center" }}>
                                        <div>
                                            {
                                                <TextField
                                                    onChange={(e) => setDropOffTypeChanged(e)}
                                                    select={true}
                                                    variant={"filled"}
                                                    fullWidth
                                                    label={
                                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                                            Dropoff person
                                                            {/* {(true) ?
                                                                <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                : null} */}
                                                        </div>
                                                    }
                                                    required={true}
                                                    inputProps={{
                                                        pattern: "^[a-zA-Z ]*$",
                                                    }}
                                                    value={checkInDetails.DropOffPersonType !== undefined ? checkInDetails.DropOffPersonType : ""}
                                                >
                                                    <MenuItem value={"Customer"}>Customer</MenuItem>
                                                    <MenuItem value={"Linked Customer"}>Linked Customer</MenuItem>
                                                    <MenuItem value={"Third Party"}>Third Party</MenuItem>
                                                </TextField>
                                            }
                                        </div>
                                        {showFieldsDropOff === 3 ? (
                                            <>
                                                <div>
                                                    {
                                                        <TextField
                                                            inputProps={{
                                                                pattern: "^[a-zA-Z ]*$",
                                                            }}
                                                            style={{ width: "100%" }}
                                                            label={
                                                                <div style={{ display: "flex", fontSize: "14px" }}>
                                                                    Name
                                                                    {/* {(true) ?
                                                                    <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                    : null} */}
                                                                </div>
                                                            }
                                                            required={true}
                                                            type={"text"}
                                                            variant="filled"
                                                            onChange={(e) => {
                                                                const checkInData = { ...checkInDetails }
                                                                checkInData.DropOffThirdPartyName = e.target.value
                                                                setCheckInDetails(checkInData)
                                                            }}
                                                            value={checkInDetails.DropOffThirdPartyName}
                                                        />
                                                    }
                                                </div>

                                                <div style={{ width: "100%" }}>
                                                    {relationshipList !== undefined && relationshipList !== null ? (
                                                        <AdvancedDropDown
                                                            data={relationshipList.map((i) => ({ label: i.varContactPersonName, value: i.numContactPersonID }))}
                                                            ref={relationshipDropOffRef}
                                                            placeHolder={"Relationship"}
                                                            value={checkInDetails.DropOffThirdPartyRelationshipID}
                                                            passByID={true}
                                                            onChange={(e) => {
                                                                const checkInData = { ...checkInDetails }
                                                                checkInData.DropOffThirdPartyRelationshipID = e.value
                                                                setCheckInDetails(checkInData)
                                                            }}
                                                        />
                                                    ) : null}
                                                </div>

                                                <div>
                                                    {
                                                        <InputMask
                                                            alwaysShowMask="true"
                                                            mask="\(999\)999\-9999"
                                                            maskChar="_"
                                                            onChange={(e) => {
                                                                const checkInData = { ...checkInDetails }
                                                                checkInData.DropOffThirdPartyContactNo = e.target.value
                                                                setCheckInDetails(checkInData)
                                                            }}
                                                            value={checkInDetails.DropOffThirdPartyContactNo}
                                                        >
                                                            {(inputProps) => (
                                                                <TextField
                                                                    inputProps={{
                                                                        pattern: true ? "^[0-9()-]+$" : "^[0-9()-]+$",
                                                                    }}
                                                                    {...inputProps}
                                                                    type="tel"
                                                                    required={true}
                                                                    style={{ width: "100%" }}
                                                                    label={
                                                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                                                            Contact number
                                                                            {/* {(true) ?
                                                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                        : null} */}
                                                                        </div>
                                                                    }
                                                                    variant="standard"
                                                                />
                                                            )}
                                                        </InputMask>
                                                    }
                                                </div>
                                            </>
                                        ) : showFieldsDropOff === 2 ? (
                                            <div style={{ width: "100%" }}>
                                                {linkedCustomers !== undefined && linkedCustomers !== null ? (
                                                    <AdvancedDropDown
                                                        data={linkedCustomers.map((i) => ({ label: i.varCustomerFirstName + " " + i.varCustomerLastName, value: i.numCustomerID }))}
                                                        ref={linkedRef}
                                                        placeHolder={"Linked customers"}
                                                        value={checkInDetails.DropOffPersonID}
                                                        passByID={true}
                                                        onChange={(e) => {
                                                            const checkInData = { ...checkInDetails }
                                                            checkInData.DropOffPersonID = e.value
                                                            setCheckInDetails(checkInData)
                                                        }}
                                                    />
                                                ) : null}
                                            </div>
                                        ) : showFieldsDropOff === 1 ? null : null}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div style={{ width: "100%" }}>
                            <Accordion defaultExpanded={checkIfNull(!checkInDetails?.IsTogglePickUpDetails, "bool")}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" style={{ backgroundColor: "#F9FAFB", margin: "0" }}>
                                    <div style={{ width: "95%", display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                            <div style={{ backgroundColor: "#EFF3FA", borderRadius: "4px", padding: "5px 10px 5px 10px", color: "#192252", fontSize: "16px", fontWeight: "600" }}>
                                                3
                                            </div>
                                            <p style={{ margin: "0", color: "#192252", fontSize: "16px", fontWeight: "600" }}>Pickup details</p>
                                        </div>
                                        <Switch disabled={true} checked={checkIfNull(checkInDetails?.IsTogglePickUpDetails, "bool")} />
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "1rem", rowGap: "2ch", alignItems: "center" }}>
                                        <div>
                                            {
                                                <TextField
                                                    onChange={(e) => setPickupTypeChanged(e)}
                                                    select={true}
                                                    variant={"filled"}
                                                    fullWidth
                                                    label={
                                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                                            Pickup person
                                                            {/* {(true) ?
                                                                    <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                    : null} */}
                                                        </div>
                                                    }
                                                    required={true}
                                                    inputProps={{
                                                        pattern: "^[a-zA-Z ]*$",
                                                    }}
                                                    value={checkInDetails.PickUpPersonType !== undefined ? checkInDetails.PickUpPersonType : ""}
                                                >
                                                    <MenuItem value={"Customer"}>Customer</MenuItem>
                                                    <MenuItem value={"Linked Customer"}>Linked Customer</MenuItem>
                                                    <MenuItem value={"Third Party"}>Third Party</MenuItem>
                                                </TextField>
                                            }
                                        </div>

                                        {showFieldsPickup === 3 ? (
                                            <>
                                                <div>
                                                    {
                                                        <TextField
                                                            inputProps={{
                                                                pattern: "^[a-zA-Z ]*$",
                                                            }}
                                                            style={{ width: "100%" }}
                                                            label={
                                                                <div style={{ display: "flex", fontSize: "14px" }}>
                                                                    Name
                                                                    {/* {(true) ?
                                                                    <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                    : null} */}
                                                                </div>
                                                            }
                                                            required={true}
                                                            type={"text"}
                                                            variant="filled"
                                                            onChange={(e) => {
                                                                const checkInData = { ...checkInDetails }
                                                                checkInData.PickUpThirdPartyName = e.target.value
                                                                setCheckInDetails(checkInData)
                                                            }}
                                                            value={checkInDetails.PickUpThirdPartyName}
                                                        />
                                                    }
                                                </div>

                                                <div style={{ width: "100%" }}>
                                                    {relationshipList !== undefined && relationshipList !== null ? (
                                                        <AdvancedDropDown
                                                            data={relationshipList.map((i) => ({ label: i.varContactPersonName, value: i.numContactPersonID }))}
                                                            ref={relationshipDropOffRef}
                                                            placeHolder={"Relationship"}
                                                            value={checkInDetails.PickUpThirdPartyRelationshipID}
                                                            passByID={true}
                                                            onChange={(e) => {
                                                                const checkInData = { ...checkInDetails }
                                                                checkInData.PickUpThirdPartyRelationshipID = e.value
                                                                setCheckInDetails(checkInData)
                                                            }}
                                                        />
                                                    ) : null}
                                                </div>

                                                <div>
                                                    {
                                                        <InputMask
                                                            alwaysShowMask="true"
                                                            mask="\(999\)999\-9999"
                                                            maskChar="_"
                                                            onChange={(e) => {
                                                                const checkInData = { ...checkInDetails }
                                                                checkInData.PickUpThirdPartyContactNo = e.target.value
                                                                setCheckInDetails(checkInData)
                                                            }}
                                                            value={checkInDetails.PickUpThirdPartyContactNo}
                                                        >
                                                            {(inputProps) => (
                                                                <TextField
                                                                    inputProps={{
                                                                        pattern: true ? "^[0-9()-]+$" : "^[0-9()-]+$",
                                                                    }}
                                                                    {...inputProps}
                                                                    type="tel"
                                                                    required={true}
                                                                    style={{ width: "100%" }}
                                                                    label={
                                                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                                                            Contact number
                                                                            {/* {(true) ?
                                                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                        : null} */}
                                                                        </div>
                                                                    }
                                                                    variant="standard"
                                                                />
                                                            )}
                                                        </InputMask>
                                                    }
                                                </div>
                                            </>
                                        ) : showFieldsPickup === 2 ? (
                                            <div style={{ width: "100%" }}>
                                                {linkedCustomers !== undefined && linkedCustomers !== null ? (
                                                    <AdvancedDropDown
                                                        data={linkedCustomers.map((i) => ({ label: i.varCustomerFirstName + " " + i.varCustomerLastName, value: i.numCustomerID }))}
                                                        ref={linkedPickupRef}
                                                        placeHolder={"Linked customers"}
                                                        value={checkInDetails.PickUpPersonID}
                                                        passByID={true}
                                                        onChange={(e) => {
                                                            const checkInData = { ...checkInDetails }
                                                            checkInData.PickUpPersonID = e.value
                                                            setCheckInDetails(checkInData)
                                                        }}
                                                    />
                                                ) : null}
                                            </div>
                                        ) : showFieldsPickup === 1 ? null : null}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>

                        <div style={{ width: "100%" }}>
                            <Accordion defaultExpanded={checkIfNull(!checkInDetails?.IsToggleHowYouKnowAboutUs, "bool")}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" style={{ backgroundColor: "#F9FAFB", margin: "0" }}>
                                    <div style={{ width: "95%", display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                            <div style={{ backgroundColor: "#EFF3FA", borderRadius: "4px", padding: "5px 10px 5px 10px", color: "#192252", fontSize: "16px", fontWeight: "600" }}>
                                                4
                                            </div>
                                            <p style={{ margin: "0", color: "#192252", fontSize: "16px", fontWeight: "600" }}>How you know about us</p>
                                        </div>
                                        <Switch disabled={true} checked={checkIfNull(checkInDetails?.IsToggleHowYouKnowAboutUs, "bool")} />
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", columnGap: "1rem", alignItems: "center" }}>
                                        {knowAboutUsList.map((item, index) => {
                                            return (
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <p>{item.name}</p>
                                                    <Checkbox
                                                        checked={item.checked}
                                                        onChange={(e) => {
                                                            const triggerCheck = [...knowAboutUsList]
                                                            triggerCheck[index].checked = !triggerCheck[index].checked
                                                            setKnowAboutUsList(triggerCheck)
                                                        }}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <Modal open={isOpenEditDetails}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem", flexDirection: "column" }}>
                                <CloseButton onClick={() => setIsOpenEditDetails(!isOpenEditDetails)} />
                                <div style={{ backgroundColor: "#fff", width: "100%" }}></div>
                            </div>
                        </Modal>
                        <div style={{ width: "100%" }}>
                            <Accordion defaultExpanded={checkIfNull(!checkInDetails?.IsTogglePetWellness, "bool")}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" style={{ backgroundColor: "#F9FAFB", margin: "0" }}>
                                    <div style={{ width: "95%", display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                            <div style={{ backgroundColor: "#EFF3FA", borderRadius: "4px", padding: "5px 10px 5px 10px", color: "#192252", fontSize: "16px", fontWeight: "600" }}>
                                                5
                                            </div>
                                            <p style={{ margin: "0", color: "#192252", fontSize: "16px", fontWeight: "600" }}>Pet Wellness</p>
                                        </div>
                                        <Switch disabled={true} checked={checkIfNull(checkInDetails?.IsTogglePetWellness, "bool")} />
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ display: "flex", gap: "5rem", width: "100%" }}>
                                        <div style={{ borderRight: "1px solid #DFE8F6", paddingLeft: "1rem", paddingRight: "5rem", flex: "1" }}>
                                            <p style={{ color: "#192252", fontWeight: "600" }}>Previous inspection</p>
                                            <p style={{ color: "#848FAC", fontWeight: "500" }}>Date</p>
                                            <p style={{ color: "#192252", fontWeight: "400" }}>{previousWellnessData.length > 0 ? shortDate(previousWellnessData[0].LogDate) : "--"}</p>
                                            <p style={{ color: "#848FAC", fontWeight: "500" }}>Evaluator</p>
                                            <p style={{ color: "#2076FF", fontWeight: "600" }}>
                                                {previousWellnessData.length > 0 ? previousWellnessData[0].varEmployeeFirstName + " " + previousWellnessData[0].varEmployeeLastName : "--"}
                                            </p>
                                            <div style={{ marginTop: "1rem" }}>
                                                <Button
                                                    color="info"
                                                    variant="text"
                                                    disabled={previousWellnessData.length > 0 ? false : true}
                                                    text="View Report"
                                                    onClick={() => {
                                                        setIsOpenReportInModal(true)
                                                    }}
                                                />
                                                <Report
                                                    isOpenReportInModal={isOpenReportInModal}
                                                    setIsOpenReportInModal={(value) => setIsOpenReportInModal(value)}
                                                    WellnessCheckInID={previousWellnessData.length > 0 ? previousWellnessData[0].CheckInID : 0}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ flex: "2" }}>
                                            <p style={{ color: "#192252", fontWeight: "600" }}>Wellness evaluation</p>
                                            <p style={{ color: "#848FAC", fontWeight: "500" }}>Date</p>
                                            <p style={{ color: "#192252", fontWeight: "400" }}>
                                                {checkInDetails.CurrentWellnessEvaluation !== undefined ? shortDate(checkInDetails.CurrentWellnessEvaluation.WellnessLogDate) : "--"}
                                            </p>
                                            <p style={{ color: "#848FAC", fontWeight: "500" }}>Evaluator</p>
                                            <p style={{ color: "#2076FF", fontWeight: "600" }}>
                                                {checkInDetails.CurrentWellnessEvaluation !== undefined
                                                    ? checkInDetails.CurrentWellnessEvaluation.varWellnessEmployeeFirstName !== null
                                                        ? checkInDetails.CurrentWellnessEvaluation.varWellnessEmployeeFirstName
                                                        : "-" + " " + (checkInDetails.CurrentWellnessEvaluation.varWellnessEmployeeLastName !== null)
                                                        ? checkInDetails.CurrentWellnessEvaluation.varWellnessEmployeeLastName
                                                        : "-"
                                                    : "-"}
                                            </p>
                                            <div style={{ display: "flex", gap: ".5rem" }}>
                                                <Button onClick={() => setIsOpenWellnessCheckModal(true)} color="primary" variant="contained" text="Start evaluation" />
                                                <WellnessModal
                                                    checkInDetails={checkInDetails}
                                                    isOpenWellnessCheckModal={isOpenWellnessCheckModal}
                                                    bookingDetails={props.bookingDetails}
                                                    setIsOpenWellnessCheckModal={(value) => setIsOpenWellnessCheckModal(value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div style={{ width: "100%" }}>
                            <Accordion defaultExpanded={checkIfNull(!checkInDetails?.IsToggleCageLocation, "bool")}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" style={{ backgroundColor: "#F9FAFB", margin: "0" }}>
                                    <div style={{ width: "95%", display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                            <div style={{ backgroundColor: "#EFF3FA", borderRadius: "4px", padding: "5px 10px 5px 10px", color: "#192252", fontSize: "16px", fontWeight: "600" }}>
                                                6
                                            </div>
                                            <p style={{ margin: "0", color: "#192252", fontSize: "16px", fontWeight: "600" }}>Cage Selection</p>
                                        </div>
                                        <Switch disabled={true} checked={checkIfNull(checkInDetails?.IsToggleCageLocation, "bool")} />
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(6, 1fr)", gridColumnGap: ".5rem", rowGap: ".5rem", paddingLeft: "1rem" }}>
                                        {cagesList !== undefined || cagesList !== null
                                            ? cagesList.map((cage) => {
                                                  return (
                                                      <div
                                                          onClick={() => {
                                                              if (cage?.CageID === props.bookingDetails?.CageID) {
                                                                  setCageID(cage.CageID)
                                                              } else if (!cage?.IsBooked && cage?.CageID !== props.bookingDetails?.CageID) {
                                                                  setCageID(cage.CageID)
                                                              }
                                                          }}
                                                          style={
                                                              cage?.IsBooked && cage?.CageID !== props.bookingDetails?.CageID
                                                                  ? {
                                                                        padding: "2rem",
                                                                        borderRadius: "8px",
                                                                        backgroundSize: "auto auto",
                                                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                                                        backgroundImage:
                                                                            "repeating-linear-gradient(135deg, transparent, transparent 3px, rgba(222, 221, 211, 1) 3px, rgba(222, 221, 211, 1) 21px )",
                                                                        border: "1px solid transparent",
                                                                        cursor: "not-allowed",
                                                                    }
                                                                  : cage?.CageID === cageID
                                                                  ? { backgroundColor: "#F3F4F6", padding: "2rem", borderRadius: "8px", border: "1px solid blue", cursor: "pointer" }
                                                                  : { backgroundColor: "#F3F4F6", padding: "2rem", borderRadius: "8px", border: "1px solid transparent", cursor: "pointer" }
                                                          }
                                                      >
                                                          <p style={{ margin: "0", color: "#848FAC", fontSize: "16px", fontWeight: "600", textAlign: "center" }}>
                                                              #{cage?.CageID !== undefined ? cage?.CageID : "-"}
                                                          </p>
                                                          <p style={{ margin: "0", color: "#848FAC", fontSize: "14px", fontWeight: "400", textAlign: "center" }}>
                                                              {cage?.CageName !== undefined ? cage?.CageName : "-"}
                                                          </p>
                                                      </div>
                                                  )
                                              })
                                            : null}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div style={{ width: "100%" }}>
                            <Accordion defaultExpanded={checkIfNull(!checkInDetails?.IsToggleServices, "bool")}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" style={{ backgroundColor: "#F9FAFB", margin: "0" }}>
                                    <div style={{ width: "95%", display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                            <div style={{ backgroundColor: "#EFF3FA", borderRadius: "4px", padding: "5px 10px 5px 10px", color: "#192252", fontSize: "16px", fontWeight: "600" }}>
                                                7
                                            </div>
                                            <p style={{ margin: "0", color: "#192252", fontSize: "16px", fontWeight: "600" }}>Services</p>
                                        </div>
                                        <Switch disabled={true} checked={checkIfNull(checkInDetails?.IsToggleServices, "bool")} />
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ marginTop: "0.6rem" }}>
                                        <p style={{ color: "#192252", fontSize: "14px", fontWeight: "600" }}>Services</p>
                                        <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 0fr)", gridColumnGap: "5px", rowGap: "5px" }}>
                                            {props.appServices.length > 0 ? (
                                                props.appServices.map((service) => {
                                                    return (
                                                        <Chip
                                                            size="small"
                                                            customBackgroundColor={"#C3DAFF"}
                                                            customBorderRadius={"12px"}
                                                            fontColor={"#192252"}
                                                            label={`${service.varServiceItemName === undefined ? service.varServiceOrProductName : service.varServiceItemName} $${
                                                                service.decChargeAmount
                                                            }`}
                                                        />
                                                    )
                                                })
                                            ) : (
                                                <p>No services selected</p>
                                            )}
                                        </div>
                                    </div>
                                    <div style={{ textAlign: "end", marginTop: "10px" }}>
                                        <Button color="primary" variant="contained" text={"Add / Edit Services"} />
                                    </div>

                                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1rem" }}>
                                        <div></div>
                                        <div style={{ display: "flex", gap: ".5rem" }}></div>
                                    </div>

                                    <div style={{ borderTop: "1px solid #DFE8F6", margin: "1rem 0 1rem 0" }}></div>

                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "1rem", rowGap: "15px" }}>
                                        <div>
                                            <p style={{ color: "#192252", fontSize: "14px", fontWeight: "600", marginLeft: "1rem" }}>Fragrance</p>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <div style={{ display: "flex", alignItems: "flex-start" }}>
                                                <Switch
                                                    onChange={(e) => {
                                                        const checkInData = { ...checkInDetails }
                                                        checkInData.IsFragrance = !checkInData.IsFragrance
                                                        setCheckInDetails(checkInData)
                                                    }}
                                                    checked={checkInDetails.IsFragrance}
                                                    inputProps={{ "aria-label": "controlled" }}
                                                />
                                                <div>
                                                    <p style={{ color: "#192252", fontSize: "14px", fontWeight: "500" }}>Fragrance</p>
                                                    <p style={{ color: "#606D93", fontSize: "14px", fontWeight: "400" }}>Turn this on to put a fragrance on the pet.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ borderTop: "1px solid #DFE8F6", margin: "1rem 0 1rem 0" }}></div>

                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "1rem", rowGap: "15px" }}>
                                        <div>
                                            <p style={{ color: "#192252", fontSize: "14px", fontWeight: "600", marginLeft: "1rem" }}>Pet Has Allergies</p>
                                        </div>
                                        <div style={{ display: "flex", alignItems: "flex-start" }}>
                                            <Switch
                                                onChange={(e) => {
                                                    const checkInData = { ...checkInDetails }
                                                    checkInData.IsPetHasAllergies = !checkInData.IsPetHasAllergies
                                                    setCheckInDetails(checkInData)
                                                }}
                                                checked={checkInDetails.IsPetHasAllergies}
                                                inputProps={{ "aria-label": "controlled" }}
                                            />
                                            <div>
                                                <p style={{ color: "#192252", fontSize: "14px", fontWeight: "500" }}>Pre-approval for conditional services</p>
                                                <p style={{ color: "#606D93", fontSize: "14px", fontWeight: "400" }}>Turn this on if pet have any past allergies records</p>
                                            </div>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div style={{ width: "100%" }}>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" style={{ backgroundColor: "#F9FAFB", margin: "0" }}>
                                    <div style={{ width: "95%", display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                            <div style={{ backgroundColor: "#EFF3FA", borderRadius: "4px", padding: "5px 10px 5px 10px", color: "#192252", fontSize: "16px", fontWeight: "600" }}>
                                                8
                                            </div>
                                            <p style={{ margin: "0", color: "#192252", fontSize: "16px", fontWeight: "600" }}>Products</p>
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div>
                                        {props.appProducts.length > 0 ? (
                                            <table>
                                                {props.appProducts.map((item) => {
                                                    return (
                                                        <tr>
                                                            <td style={{ padding: "1rem", width: "100%" }}>
                                                                <p style={{ margin: "0", color: "#192252", fontSize: "14px", fontWeight: "600" }}>{item.varProductName}</p>
                                                                <p style={{ margin: "0", color: "#606D93", fontSize: "12px", fontWeight: "400" }}>
                                                                    Unit Price: ${item.decUnit_Price} | Quantity: {item.numQuantity} | {item.decTaxAmt !== null ? `Tax: $${item.decTaxAmt}` : ""}{" "}
                                                                </p>
                                                            </td>
                                                            <td style={{ padding: "1rem", borderRadius: "6px" }}>
                                                                <p style={{ margin: "0", color: "#192252", fontSize: "20px", fontWeight: "700", textAlign: "end" }}>
                                                                    ${item.decChargeAmount !== null ? parseFloat(item.decChargeAmount) * item.numQuantity : 0}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </table>
                                        ) : (
                                            <p>No products selected</p>
                                        )}

                                        <div style={{ display: "flex", marginTop: "1rem", justifyContent: "space-between" }}>
                                            <div></div>
                                            <Button color="primary" variant="contained" text={"Add / Edit Products"} />
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div style={{ width: "100%" }}>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" style={{ backgroundColor: "#F9FAFB", margin: "0" }}>
                                    <div style={{ width: "95%", display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                            <div style={{ backgroundColor: "#EFF3FA", borderRadius: "4px", padding: "5px 10px 5px 10px", color: "#192252", fontSize: "16px", fontWeight: "600" }}>
                                                9
                                            </div>
                                            <p style={{ margin: "0", color: "#192252", fontSize: "16px", fontWeight: "600" }}>Billing Summary</p>
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Summery appDetails={{ numAppointmentID: props.bookingDetails.numAppointmentID }} isPassedInvoice={false} />
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div style={{ width: "100%" }}>
                            <Accordion defaultExpanded={checkIfNull(!checkInDetails?.IsTogglePickUpDetails, "bool")}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" style={{ backgroundColor: "#F9FAFB", margin: "0" }}>
                                    <div style={{ width: "95%", display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                            <div style={{ backgroundColor: "#EFF3FA", borderRadius: "4px", padding: "5px 10px 5px 10px", color: "#192252", fontSize: "16px", fontWeight: "600" }}>
                                                10
                                            </div>
                                            <p style={{ margin: "0", color: "#192252", fontSize: "16px", fontWeight: "600" }}>Waivers</p>
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
                                        {waverDocuments.length > 0 ? (
                                            waverDocuments.map((item, index) => {
                                                return (
                                                    <div style={{ display: "flex", justifyContent: "space-between", border: "1px solid #EFF3FA", padding: ".5rem" }}>
                                                        <div>
                                                            <p style={{ margin: "0", color: "#192252", fontSize: "14px", fontWeight: "500" }}>{item.name}</p>
                                                            <p style={{ margin: "0", color: "#606D93", fontSize: "12px", fontWeight: "400" }}>{item.desc}</p>
                                                        </div>
                                                        <div style={{ display: "flex", gap: ".5rem" }}>
                                                            {item.fileName === null ? (
                                                                <Button
                                                                    color="info"
                                                                    variant="text"
                                                                    text={"Select File"}
                                                                    onClick={() => {
                                                                        selectWaverFile(index)
                                                                    }}
                                                                />
                                                            ) : item.fileName === "Esy-Pet-temp-23/7/18:9.10" ? (
                                                                <div style={{ display: "flex", gap: "5px" }}>
                                                                    <Button
                                                                        color="danger"
                                                                        variant="contained"
                                                                        text={"Remove"}
                                                                        onClick={() => {
                                                                            removeWaverFile(index)
                                                                        }}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div style={{ display: "flex", gap: "5px" }}>
                                                                    <Button
                                                                        color="default"
                                                                        variant="outlined"
                                                                        text={"Download"}
                                                                        disabled={item.fileName === "Esy-Pet-temp-23/7/18:9.10" ? true : false}
                                                                        onClick={() => {
                                                                            window.open(`${process.env.REACT_APP_MEDIA_URL}waiver/${item.fileName}`)
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        ) : (
                                            <p style={{ margin: "0", color: "#606D93", fontSize: "12px", fontWeight: "400" }}>No waiver documents found for the selected pet type</p>
                                        )}
                                    </div>
                                    <Modal open={isOpenWaverSelect}>
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem", flexDirection: "column" }}>
                                            <div style={{ display: "flex", justifyContent: "end", width: "100%" }}>
                                                <CloseButton onClick={() => setIsOpenWaverSelect(!isOpenWaverSelect)} />
                                            </div>
                                            <form onSubmit={handleUpload}>
                                                <div style={{ backgroundColor: "#fff", width: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>
                                                    <div
                                                        style={{
                                                            border: "2px dashed #5f9cfe",
                                                            borderRadius: "8px",
                                                            padding: ".8rem",
                                                            backgroundColor: "#fff",
                                                        }}
                                                    >
                                                        {
                                                            <FileUploader
                                                                handleChange={readFile}
                                                                onDrop={readFile}
                                                                disabled={loading ? true : false}
                                                                name="file"
                                                                types={fileTypes}
                                                                multiple={false}
                                                                label=""
                                                                children={
                                                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                        <FileUploadOutlined style={{ color: "#2076FF" }} />
                                                                        <p style={{ color: "#2076FF", fontWeight: "500" }}>Drag and drop or browse to choose a file</p>
                                                                    </div>
                                                                }
                                                            />
                                                        }
                                                    </div>
                                                    <Button color="primary" variant="contained" type="submit" disabled={isLoading ? true : false} loading={isLoading} text={"Upload"} />
                                                </div>
                                            </form>
                                        </div>
                                    </Modal>
                                    <div style={{ backgroundColor: "#FDEDED", display: "flex", justifyContent: "space-between", padding: "1rem", marginTop: "1rem", borderRadius: "0px 0px 8px 8px" }}>
                                        <div>
                                            <p style={{ margin: "0", color: "#7F1D1D", fontSize: "14px", fontWeight: "600" }}>Is the client refusing to sign?</p>
                                            <p style={{ margin: "0", color: "#720A0A", fontSize: "12px", fontWeight: "400" }}>Get approval from Merry Jo to complete this section</p>
                                        </div>
                                        <div>
                                            <Button color="danger" variant="contained" text={"Notify Manager"} />
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div style={{ width: "100%" }}>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" style={{ backgroundColor: "#F9FAFB", margin: "0" }}>
                                    <div style={{ width: "95%", display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                            <div style={{ backgroundColor: "#EFF3FA", borderRadius: "4px", padding: "5px 10px 5px 10px", color: "#192252", fontSize: "16px", fontWeight: "600" }}>
                                                11
                                            </div>
                                            <p style={{ margin: "0", color: "#192252", fontSize: "16px", fontWeight: "600" }}>Notes (Optional)</p>
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ width: "100%" }}>
                                        {
                                            <>
                                                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                    <TextField
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        style={{ width: "100%" }}
                                                        label={<div style={{ display: "flex", fontSize: "14px" }}>Add your notes here.</div>}
                                                        type={"text"}
                                                        variant="filled"
                                                        multiline={true}
                                                        onChange={(e) => {
                                                            const checkInData = { ...checkInDetails }
                                                            checkInData.Notes = e.target.value
                                                            setCheckInDetails(checkInData)
                                                        }}
                                                        value={checkInDetails.Notes}
                                                    />
                                                    <IconButton>
                                                        <Mic />
                                                    </IconButton>
                                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                        <p>Microphone: {listening ? "on" : "off"}</p>
                                                        <button style={{ borderRadius: "10px", border: "none", outline: "none", padding: "10px" }} onClick={SpeechRecognition.startListening}>
                                                            Start
                                                        </button>
                                                        <button style={{ borderRadius: "10px", border: "none", outline: "none", padding: "10px" }} onClick={SpeechRecognition.stopListening}>
                                                            Stop
                                                        </button>
                                                        <button style={{ borderRadius: "10px", border: "none", outline: "none", padding: "10px" }} onClick={resetTranscript}>
                                                            Reset
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end", width: "95%", backgroundColor: "#192252", padding: "1rem", borderRadius: "0 0 12px 12px" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                handleCheckin()
                            }}
                            disabled={props.bookingDetails?.IsCheckedOut === true ? true : false}
                        >
                            Check In
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default CheckInModal
