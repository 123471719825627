import React, { useEffect, useRef, useState } from "react"
import AdvancedDropDown from "../../../../components/AdvancedDropDown/AdvancedDropDown"
import { axiosPrivate, buildHeaderObject } from "../../../../apis/axios"
import { toast } from "react-toastify"
import { Checkbox, IconButton, Radio, TextField, Tooltip } from "@mui/material"
import { Avatar, Button, Chip } from "../../../../components"
import { Close, Edit, Fullscreen, FullscreenExit, InfoOutlined, Visibility, WarningAmber } from "@mui/icons-material"
import styles from "./AssignUsers.module.scss"
import CloseButton from "../../../../components/Modal/CloseButton/CloseButton"
import { Modal } from "../../../../components/components"
import Tree from "react-d3-tree"
import DialogBox from "../../../../components/DialogBox/DialogBox"
import useAuth from "../../../../hooks/useAuth"

const AssignUsers = (props) => {
    const [roleGroupList, setRoleGroupList] = useState([]) as any
    const [employeeList, setEmployeeList] = useState([]) as any
    const [selectedEmployees, setSelectedEmployees] = useState([]) as any

    const [selectedRoleGroup, setSelectedRoleGroup] = useState({}) as any
    const [tempSelectedRoleGroup, setTempSelectedRoleGroup] = useState({}) as any
    const [selectedRole, setSelectedRole] = useState({}) as any

    const [moduleSettingList, setModuleSettingList] = useState([]) as any

    const [selectedModule, setSelectedModule] = useState(0) as any
    const [selectedEmployee, setSelectedEmployee] = useState(0) as any

    const [moduleList, setModuleList] = useState([]) as any

    const [rolesList, setRolesList] = useState([]) as any
    const [showPermissions, setShowPermissions] = useState(false)

    const [openDialog, setOpenDialog] = useState(false)

    const { auth } = useAuth() as any

    async function deleteMainPermission(location, employee) {
        const result = await axiosPrivate.post(`User/DeleteUserPermissionsByLocIDEmpID?numLocationID=${location}&numEmployeeID=${employee}`, buildHeaderObject(auth))
        return result
    }

    const deleteMainPermissions = () => {
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    const deleteMainPermissionsConfirm = () => {
        if (location > 0) {
            if (selectedEmployee > 0) {
                // console.log("location, selectedEmployee", location, selectedEmployee)
                deleteMainPermission(location, selectedEmployee)
                    .then((response) => {
                        console.log("permission main", response.data)
                        populatePermissions(selectedEmployee)
                        toast.success("Permissions were reset successfully!")

                        setOpenDialog(false)
                    })
                    .catch((err) => {
                        console.log(err)
                        toast.error("Something went wrong when resetting permissions!")
                    })
            } else {
                toast.info("Please select an employee!")
            }
        } else {
            toast.info("Please select a location!")
        }
    }

    async function getRoleGroups(location) {
        const result = await axiosPrivate.get(`User/GetAllUserRoleGroups`, buildHeaderObject(auth))
        return result
    }

    useEffect(() => {
        if (selectedModule != 0) {
            getModuleListByModule(selectedModule)
                .then((response) => {
                    console.log("listtttttt", response.data)
                    setModuleSettingList(response.data)
                })
                .catch((err) => {
                    console.log(err)
                    toast.error("Something went wrong!")
                })
        }
    }, [selectedModule])

    async function getSystemModulesByMapping(mappingID) {
        const result = await axiosPrivate.get(`User/GetAllSystemModules`, buildHeaderObject(auth))
        return result
    }

    const getModules = (mappingID) => {
        getSystemModulesByMapping(mappingID)
            .then((response) => {
                setModuleList(response.data)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    async function getEmployees() {
        const result = await axiosPrivate.get(`Employee/GetAll`, buildHeaderObject(auth))
        return result
    }

    const getRoles = (location) => {
        getRoleGroups(location)
            .then((response) => {
                setRoleGroupList(response.data)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    async function getRolesList(group_id) {
        const result = await axiosPrivate.get(`User/GetAllUserRolesByGroupID?RoleGroupID=${group_id}`, buildHeaderObject(auth))
        return result
    }

    const getAllRoles = (group_id) => {
        getRolesList(group_id)
            .then((response) => {
                let selected_items = response?.data.filter((item) => item.IsSelected)

                // console.log("modified roles", selected_items)

                let extractedRoles = [] as any
                if (selectedRoleGroup?.roles != null) {
                    let firstKey = Object.keys(selectedRoleGroup?.roles?.location?.groups)[0]
                    if (Object.keys(selectedRoleGroup?.roles?.location?.groups[firstKey]?.roles).length > 0) {
                        console.log("permission roles", selectedRoleGroup?.roles?.location?.groups)

                        let userRoles = selectedRoleGroup?.roles?.location?.groups[firstKey]?.roles

                        for (let key in userRoles) {
                            if (userRoles.hasOwnProperty(key)) {
                                const userRole = userRoles[key]

                                extractedRoles.push({
                                    numUserRoleID: userRole.numUserRoleID,
                                    varUserRoleName: userRole.varUserRoleName,
                                    IsActive: userRole.IsActive,
                                })
                            }
                        }

                        let newRoles = selected_items?.map((role) => {
                            return {
                                ...role,
                                IsSelected: extractedRoles.some((item) => item.numUserRoleID === role.numUserRoleID && item.IsActive === true),
                            }
                        })

                        setRolesList(newRoles)
                    }
                } else {
                    let newRoles = selected_items?.map((role) => {
                        return {
                            ...role,
                            IsSelected: false,
                        }
                    })

                    setRolesList(newRoles)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const sortByProperty = (arr, prop) => {
        return arr.sort((a, b) => a[prop].localeCompare(b[prop]))
    }

    async function getModuleListByModule(modID) {
        const result = await axiosPrivate.get(`User/GetAllSystemModuleListByModuleID?ModuleID=${modID}`, buildHeaderObject(auth))
        return result
    }

    async function getExistingPermissions(employeeID, locationID) {
        const result = await axiosPrivate.get(`User/GetAllUserPermissionsByEmpIDandLocID?numLocationID=${locationID === 0 ? "" : locationID}&numEmployeeID=${employeeID}`, buildHeaderObject(auth))
        return result
    }

    async function getExistingLocations(employeeID) {
        const result = await axiosPrivate.get(`Auth/GetLocationsAndRolesByEmpID?numEmployeeID=${employeeID}`, buildHeaderObject(auth))
        return result
    }

    async function createPermissions() {
        let finalList = [] as any

        console.log("roles list", rolesList)

        if (rolesList.length > 0) {
            rolesList.map((role) => [
                finalList.push({
                    id: 0,
                    numEmployeeID: selectedEmployee,
                    locationID: location,
                    roleGroupID: selectedRoleGroup?.numUserRoleGroupID,
                    roleID: role?.numUserRoleID,
                    isActive: role?.IsSelected,
                    createdBy: 0,
                }),
            ])
        }

        console.log(finalList)

        await axiosPrivate
            .post(`User/PopulateUserPermissions`, JSON.stringify(finalList), buildHeaderObject(auth))
            .then((result) => {
                if (result.status === 200) {
                    toast.success(`Permissions Assigned!`)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const validateCircle = (str) => {
        if (typeof str === "string" && str.toLowerCase().includes("true")) {
            return styles.circle_true
        } else if (typeof str === "string" && str.toLowerCase().includes("false")) {
            return styles.circle_false
        } else {
            return styles.circle
        }
    }

    const renderRectSvgNode = ({ nodeDatum, toggleNode }) => (
        <g>
            <circle className={`${validateCircle(nodeDatum.name)}`} r="15" onClick={toggleNode}></circle>
            <text className={styles.textC} fill="black" text-anchor="middle" x="12" y="-22">
                {nodeDatum.name}
            </text>
        </g>
    )

    const [permissionDetails, setPermissionDetails] = useState({}) as any

    const constructTreeList = (unsortedList) => {
        const transformedData = unsortedList.reduce((acc, curr) => {
            let location = acc.find((item) => item.name === curr.varLocationName)
            if (!location) {
                location = { name: curr.varLocationName, children: [] }
                acc.push(location)
            }

            let group = location.children.find((item) => item.name === curr.varUserRoleGroupName)
            if (!group) {
                group = { name: curr.varUserRoleGroupName, children: [] }
                location.children.push(group)
            }

            let role = group.children.find((item) => item.name === curr.varUserRoleName)
            if (!role) {
                role = { name: curr.varUserRoleName, children: [] }
                group.children.push(role)
            }

            let module = role.children.find((item) => item.name === curr.ModuleName)
            if (!module) {
                module = { name: curr.ModuleName, children: [] }
                role.children.push(module)
            }

            let systemModule = module.children.find((item) => item.name === curr.SystemModuleListName)
            if (!systemModule) {
                systemModule = { name: curr.SystemModuleListName, children: [] }
                module.children.push(systemModule)
            }

            systemModule.children.push(
                { name: `View - ${curr.IsView ? "True" : "False"}`, children: [] },
                { name: `Update - ${curr.IsUpdate ? "True" : "False"}`, children: [] },
                { name: `Delete - ${curr.IsDelete ? "True" : "False"}`, children: [] }
            )

            return acc
        }, [])

        return transformedData
    }

    // Construct Tree V2 - Returns 1 OBJ
    const constructTree = (unsortedList) => {
        const transformedData = unsortedList.reduce((acc, curr) => {
            if (!acc.location) {
                acc.location = {
                    LocationID: curr.LocationID,
                    groups: {},
                }
            }

            if (!acc.location.groups[curr.numUserRoleGroupID]) {
                acc.location.groups[curr.numUserRoleGroupID] = {
                    numUserRoleGroupID: curr.numUserRoleGroupID,
                    varUserRoleGroupName: curr.varUserRoleGroupName,
                    roles: {},
                }
            }

            if (!acc.location.groups[curr.numUserRoleGroupID].roles[curr.numUserRoleID]) {
                acc.location.groups[curr.numUserRoleGroupID].roles[curr.numUserRoleID] = {
                    numUserRoleID: curr.numUserRoleID,
                    varUserRoleName: curr.varUserRoleName,
                    IsActive: curr.IsActive,
                    modules: {},
                }
            }

            if (!acc.location.groups[curr.numUserRoleGroupID].roles[curr.numUserRoleID].modules[curr.ModuleID]) {
                acc.location.groups[curr.numUserRoleGroupID].roles[curr.numUserRoleID].modules[curr.ModuleID] = {
                    ModuleID: curr.ModuleID,
                    ModuleName: curr.ModuleName,
                    systemmodules: {},
                }
            }

            acc.location.groups[curr.numUserRoleGroupID].roles[curr.numUserRoleID].modules[curr.ModuleID].systemmodules[curr.SystemModuleListID] = {
                SystemModuleListID: curr.SystemModuleListID,
                SystemModuleListName: curr.SystemModuleListName,
                settings: {
                    ID: curr.ID,
                    IsView: curr.IsView,
                    IsUpdate: curr.IsUpdate,
                    IsDelete: curr.IsDelete,
                    varEmployeeFirstName: curr.varEmployeeFirstName,
                    varEmployeeLastName: curr.varEmployeeLastName,
                },
            }

            return acc
        }, {})

        return transformedData
    }

    const populatePermissions = (employeeID) => {
        getExistingLocations(employeeID)
            .then((response) => {
                console.log("locs", response?.data?.data)

                if (response?.data?.data?.[0]?.LocationList?.length > 0) {
                    let setLocs = [] as any
                    response?.data?.data?.[0]?.LocationList.map((loc) => setLocs.push(loc?.numLocationID))

                    const prevLocationList = [...locationList]
                    const updatedLocationList = prevLocationList.map((prevLoc) => ({
                        ...prevLoc,
                        isAssigned: setLocs.includes(prevLoc?.numLocationID) ? true : false,
                    }))

                    setLocationList(updatedLocationList)
                    // setLocation(locationList?.[0]?.numLocationID)

                    getExistingPermissions(employeeID, location)
                        .then((response) => {
                            console.log("existing", response?.data)

                            if (response?.data.length > 0) {
                                setHasPermissions(true)
                                console.log("all ", response?.data)

                                let tree = constructTree(response?.data)
                                console.log("constructed ", tree)

                                setTempSelectedRoleGroup({
                                    numUserRoleGroupID: response?.data[0]?.numUserRoleGroupID,
                                    varUserRoleGroupName: response?.data[0]?.varUserRoleGroupName,
                                    varUserRoleGroupDescription: "",
                                    roles: tree,
                                })

                                setSelectedRoleGroup({
                                    numUserRoleGroupID: response?.data[0]?.numUserRoleGroupID,
                                    varUserRoleGroupName: response?.data[0]?.varUserRoleGroupName,
                                    varUserRoleGroupDescription: "",
                                    roles: tree,
                                })
                            } else {
                                setTempSelectedRoleGroup(false)
                                setTempSelectedRoleGroup({})
                                setSelectedRoleGroup({})
                            }

                            getExistingPermissions(employeeID, "")
                                .then((response) => {
                                    // console.log("alll locsss", response?.data)
                                    // console.log("constructed tree view", { name: "Permissions", children: constructTreeList(response?.data) })
                                    // console.log("alll locsss tree", constructTreeList(response?.data))

                                    setPermissionDetails({ name: "Permissions", children: constructTreeList(response?.data) })
                                })
                                .catch((err) => {
                                    console.log(err)
                                    toast.error("Something went wrong!")
                                })
                        })
                        .catch((err) => {
                            console.log(err)
                            toast.error("Something went wrong!")
                        })
                } else {
                    setHasPermissions(false)
                    setSelectedRoleGroup({})
                    getAllRoles("")
                    const prevLocationList = [...locationList]

                    const updatedLocationList = prevLocationList.map((prevLoc) => ({
                        ...prevLoc,
                        isAssigned: false,
                    }))

                    setLocationList(updatedLocationList)
                    // setLocation(locationList?.[0]?.numLocationID)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const [fullScreenNet, setFullScreenNet] = useState(false)
    const [depth, setDepth] = useState(20)
    const [hasPermissions, setHasPermissions] = useState(false)
    const [showLocDelete, setShowLocDelete] = useState(false)

    const constructPermissions = (employeeID) => {
        setShowPermissions(true)
    }

    const assignPermissions = () => {
        if (location === 0) {
            toast.info("Please select a location!")
        } else if (selectedEmployee === 0) {
            toast.info("Please select an employee!")
        } else {
            createPermissions()
        }
    }

    useEffect(() => {
        console.log("roleGroupList", roleGroupList)
    }, [roleGroupList])

    useEffect(() => {
        if (Object.keys(selectedRoleGroup).length > 0) {
            getAllRoles(selectedRoleGroup?.numUserRoleGroupID)
        }
    }, [selectedRoleGroup])

    const [locationList, setLocationList] = useState([]) as any
    const locationRef = useRef()

    const [location, setLocation] = useState(0)

    useEffect(() => {
        if (selectedEmployee !== 0) {
            let getloc = locationList.find((loc) => loc.numLocationID === location)
            populatePermissions(selectedEmployee)
            // console.log("ccc", getloc?.isAssigned, location)
            if (getloc?.isAssigned) {
                setShowLocDelete(true)
            } else {
                setShowLocDelete(false)
            }
        }
    }, [location])

    async function getLocations() {
        console.log("getLocations")

        const result = await axiosPrivate.get(`Location/GetAll`, buildHeaderObject(auth))
        return result.data.location
    }

    useEffect(() => {
        getLocations()
            .then((response) => {
                setLocationList(response)

                console.info(response)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })

        console.log("location inital")

        getRoles("")
        getAllRoles("")
        getModules("")

        getEmployees()
            .then((response) => {
                console.log("all emp", response.data)

                let modifiedArray = response.data?.employee.map((obj) => {
                    return { ...obj, IsSelected: false }
                })

                setEmployeeList(modifiedArray)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }, [])

    return (
        <div style={{ width: "100%", paddingBottom: "10px" }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <p style={{ fontWeight: "600", fontSize: "20px", color: "#5e5e5e" }}>Assign Users</p>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "15px",
                    marginTop: "20px",
                    height: "564px",
                }}
            >
                <div
                    style={{
                        border: "1px solid #8eb4f1",
                        borderRadius: "8px",
                        padding: "8px 15px",
                        width: "100%",
                        height: "100%",
                        boxShadow: "rgb(0 99 255 / 21%) 0px 0 8px 0px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderBottom: "1px solid #d5d5d5",
                            paddingBottom: "8px",
                            marginBottom: "4px",
                        }}
                    >
                        <p style={{ fontSize: "16px", fontWeight: "500" }}>
                            Assign Users&nbsp;
                            {hasPermissions ? <Chip customStyle="GreenOutlined" size="small" label="Permissions Set" /> : <Chip customStyle="RedOutlined" size="small" label="Permissions Not Set" />}
                        </p>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}></div>
                    </div>

                    <div>
                        <div style={{ width: "100%", marginBottom: "8px" }}>
                            <div style={{ width: "100%", marginBottom: "2px", display: "flex" }}>
                                <div style={{ display: "flex", gap: "10px", width: "100%" }}>
                                    <TextField
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton disabled style={{ borderRadius: "5px" }}>
                                                    <Close style={{ fontSize: "16px", color: "#fff" }} />
                                                </IconButton>
                                            ),
                                        }}
                                        value={""}
                                        inputProps={{ style: { fontSize: 14 } }}
                                        placeholder="Filter by Name"
                                        size="small"
                                        variant="standard"
                                        style={{ width: "100%", fontSize: "14px" }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div style={{ overflowY: "auto", height: "440px" }}>
                            {employeeList?.map((emp, index) => (
                                <div style={{ display: "flex", marginBottom: "10px", marginTop: "15px" }} onClick={() => {}}>
                                    <Radio
                                        onChange={(e) => {
                                            e.stopPropagation()
                                            const tempEmpList = employeeList.map((empItem, idx) => ({
                                                ...empItem,
                                                IsSelected: idx === index ? !empItem.IsSelected : false,
                                            }))
                                            setEmployeeList(tempEmpList)
                                            populatePermissions(emp?.numEmployeeID)
                                            setSelectedEmployee(emp?.numEmployeeID)
                                        }}
                                        size="small"
                                        checked={emp?.IsSelected}
                                    />
                                    <div
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div
                                            style={{
                                                gap: "10px",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Avatar
                                                isVip={false}
                                                img={`${process.env.REACT_APP_MEDIA_URL}directories/employees/${emp?.varEmployeeImagePath != undefined ? emp?.varEmployeeImagePath : null}`}
                                                className={"Medium"}
                                            />
                                            <p>{`${emp?.varEmployeeFirstName} ${emp?.varEmployeeLastName}`}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <Modal open={showPermissions}>
                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        alignItems: "center",
                                        backgroundColor: "#f5f5f5",
                                        padding: "16px",
                                        marginTop: "-16px",
                                        borderTopRightRadius: "12px",
                                        borderTopLeftRadius: "12px",
                                    }}
                                >
                                    <p style={{ color: "#002867", fontSize: "18px", fontWeight: "600", width: "100%" }}>View Permissions</p>
                                    <CloseButton onClick={() => setShowPermissions(false)} />
                                </div>

                                <div className={fullScreenNet ? styles.fullScreenNet : styles.notfullScreen}>
                                    <div style={{ display: "flex", gap: "10px" }}>
                                        <div
                                            style={
                                                fullScreenNet
                                                    ? { flex: 1, border: "1px solid rgb(213, 213, 213)", borderRadius: "8px", padding: "6px 12px", width: "100%", height: "960px" }
                                                    : { flex: 1, border: "1px solid rgb(213, 213, 213)", borderRadius: "8px", padding: "6px 12px", width: "1000px", height: "100%" }
                                            }
                                        >
                                            <p style={{ fontSize: "16px", fontWeight: 500, borderBottom: "1px solid rgb(213, 213, 213)", paddingBottom: "8px" }}>Click and Drag to interact</p>
                                            <IconButton color="primary" onClick={() => setFullScreenNet(!fullScreenNet)}>
                                                {fullScreenNet ? <FullscreenExit /> : <Fullscreen />}
                                            </IconButton>
                                            <div style={fullScreenNet ? { height: "883px" } : { height: "500px" }}>
                                                <div id="treeWrapper" style={fullScreenNet ? { height: "883px", width: "100%" } : { height: "500px", width: "100%" }}>
                                                    {Object.keys(permissionDetails).length > 0 ? (
                                                        <Tree
                                                            initialDepth={depth}
                                                            translate={{ x: 550, y: 250 }}
                                                            scaleExtent={{ max: 2, min: 1 }}
                                                            nodeSize={{ x: 200, y: 80 }}
                                                            data={permissionDetails}
                                                            dimensions={{ width: 1100, height: 500 }}
                                                            renderCustomNodeElement={renderRectSvgNode}
                                                        />
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>

                <div
                    style={{
                        border: "1px solid #8eb4f1",
                        borderRadius: "8px",
                        padding: "8px 15px",
                        width: "100%",
                        height: "100%",
                        boxShadow: "rgb(0 99 255 / 21%) 0px 0 8px 0px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderBottom: "1px solid #d5d5d5",
                            paddingBottom: "8px",
                            marginBottom: "4px",
                            gap: "15px",
                        }}
                    >
                        <p style={{ fontSize: "15px", fontWeight: "500", display: "flex", alignItems: "center" }}>Departments</p>
                        <div style={{ display: "flex", width: "100%", gap: "6px" }}>
                            <div style={{ width: "100%" }}>
                                <AdvancedDropDown
                                    data={locationList.map((i) => ({
                                        label: (
                                            <span>
                                                {i?.isAssigned ? <span style={{ width: "5px", height: "5px", color: "red", fontWeight: 600, fontSize: "20px" }}>• </span> : null}
                                                {i.varLocationName}
                                            </span>
                                        ),
                                        value: i.numLocationID,
                                    }))}
                                    onChange={(e) => {
                                        setLocation(e.value)
                                    }}
                                    passByID={true}
                                    value={location}
                                    ref={locationRef}
                                    placeHolder={"Select Location"}
                                />
                            </div>

                            <DialogBox
                                title={
                                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                        <WarningAmber color="warning"></WarningAmber> Delete Permissions for Location?
                                    </div>
                                }
                                actions={[
                                    { label: "Cancel", onClick: handleCloseDialog, color: "primary" },
                                    { label: "Yes", onClick: deleteMainPermissionsConfirm, color: "primary", variant: "contained" },
                                ]}
                                open={openDialog}
                                onClose={null}
                                content={
                                    <>
                                        This will delete permissions set for the selected location. <br /> Are you sure?
                                    </>
                                }
                            ></DialogBox>

                            <Button onClick={() => deleteMainPermissions()} color={showLocDelete ? "danger" : "discard"} variant="contained" text="Remove" disabled={!showLocDelete} />
                        </div>
                    </div>

                    <div>
                        <div style={{ width: "100%", marginBottom: "8px" }}>
                            <TextField
                                InputProps={{
                                    endAdornment: (
                                        <IconButton disabled style={{ borderRadius: "5px" }}>
                                            <Close style={{ fontSize: "16px", color: "#fff" }} />
                                        </IconButton>
                                    ),
                                }}
                                inputProps={{ style: { fontSize: 14 } }}
                                placeholder="Filter here"
                                size="small"
                                variant="standard"
                                style={{ width: "100%", fontSize: "14px" }}
                            />
                        </div>

                        <div style={{ overflowY: "auto", height: "440px" }}>
                            {sortByProperty(roleGroupList, "varUserRoleGroupName")?.map((roleGroup) => (
                                <div
                                    style={{ padding: "3px 0" }}
                                    onClick={() => {
                                        if (tempSelectedRoleGroup?.numUserRoleGroupID === roleGroup?.numUserRoleGroupID) {
                                            populatePermissions(selectedEmployee)
                                        } else {
                                            setSelectedRoleGroup({
                                                numUserRoleGroupID: roleGroup?.numUserRoleGroupID,
                                                varUserRoleGroupName: roleGroup?.varUserRoleGroupName,
                                                varUserRoleGroupDescription: roleGroup?.varUserRoleGroupDescription,
                                                roles: null,
                                            })
                                        }
                                    }}
                                    className={`styles.listItem ${selectedRoleGroup?.numUserRoleGroupID === roleGroup?.numUserRoleGroupID ? styles.selectedHighlight : styles.listItem}`}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "5px",
                                        }}
                                    >
                                        <Tooltip
                                            title={
                                                <div>
                                                    <p style={{ color: "#fff", margin: 0 }}>
                                                        <b>Description: </b>
                                                        {roleGroup?.varUserRoleGroupDescription}
                                                    </p>
                                                    <p style={{ color: "#fff", margin: 0 }}>
                                                        <b>Is Active: </b>
                                                        {roleGroup?.bitActive ? "Active" : "Inactive"}
                                                    </p>
                                                </div>
                                            }
                                        >
                                            <InfoOutlined style={{ fontSize: "20px", color: "gray" }} />
                                        </Tooltip>
                                        <p
                                            style={{
                                                background: `${roleGroup?.varColor === "" ? "gray" : roleGroup?.varColor}`,
                                                height: "12px",
                                                width: "12px",
                                                borderRadius: "50px",
                                            }}
                                        ></p>
                                        <p
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                userSelect: "none",
                                            }}
                                        >
                                            {roleGroup?.varUserRoleGroupName}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        border: "1px solid #8eb4f1",
                        borderRadius: "8px",
                        padding: "8px 15px",
                        width: "100%",
                        height: "100%",
                        boxShadow: "rgb(0 99 255 / 21%) 0px 0 8px 0px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderBottom: "1px solid #d5d5d5",
                            paddingBottom: "8px",
                            marginBottom: "4px",
                        }}
                    >
                        <p style={{ fontSize: "16px", fontWeight: "500" }}>Roles</p>
                        <div>
                            <IconButton style={{ borderRadius: "5px" }} onClick={(e) => constructPermissions(selectedEmployee)}>
                                <Visibility style={{ fontSize: "24px", color: "#6B7280" }} />
                            </IconButton>
                            <Button variant="contained" color="primary" text="Assign Permissions" onClick={() => assignPermissions()} />
                        </div>
                    </div>

                    <div>
                        <div style={{ width: "100%", marginBottom: "8px" }}>
                            <div style={{ width: "100%", marginBottom: "2px", display: "flex" }}>
                                <TextField
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton disabled style={{ borderRadius: "5px" }}>
                                                <Close style={{ fontSize: "16px", color: "#fff" }} />
                                            </IconButton>
                                        ),
                                    }}
                                    value={""}
                                    inputProps={{ style: { fontSize: 14 } }}
                                    placeholder="Filter here"
                                    size="small"
                                    variant="standard"
                                    style={{ width: "100%", fontSize: "14px" }}
                                />
                            </div>
                        </div>

                        <div style={{ overflowY: "auto", height: "440px" }}>
                            {rolesList?.map((roles, index) => (
                                <div
                                    style={{ display: "flex" }}
                                    onClick={() => {
                                        setSelectedRole({
                                            RoleGroupMappingID: roles?.RoleGroupMappingID,
                                            numUserRoleID: roles?.numUserRoleID,
                                            varUserRoleName: roles?.varUserRoleName,
                                        })
                                    }}
                                >
                                    <Checkbox
                                        onChange={(e) => {
                                            e.stopPropagation()
                                            const tempRolesList = [...rolesList]
                                            tempRolesList[index].IsSelected = !tempRolesList[index]?.IsSelected
                                            setRolesList(tempRolesList)
                                        }}
                                        size="small"
                                        checked={roles?.IsSelected}
                                    />
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "5px",
                                        }}
                                    >
                                        <p>{roles?.varUserRoleName}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssignUsers
