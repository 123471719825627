import { CameraAltOutlined, Check, Close, FileUploadOutlined, ImageOutlined, OpenInNewOutlined, Place, UndoOutlined, Warning } from "@mui/icons-material"
import {
    Box,
    FormControl,
    MenuItem,
    Select,
    Step,
    StepLabel,
    Stepper,
    Tab,
    Tabs,
    Button as MUIButton,
    Grid,
    Modal,
    Radio,
    FormControlLabel,
    RadioGroup,
    FormGroup,
    Checkbox,
    TextField,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { flattenDiagnosticMessageText } from "typescript"
import { axiosPrivate, buildHeaderObject } from "../../../apis/axios"
import { Avatar, Button, Chip, CloseButton } from "../../../components"
import { AdvancedInput } from "../../../components/AdvancedInput/AdvancedInput"
import { toast } from "react-toastify"
import useAuth from "../../../hooks/useAuth"

function GroomingCard(props) {
    const { auth } = useAuth() as any

    const steps = ["Check in", "Bathing", "Grooming", "Complete", "Ready for pickup", "Check out"]

    const [tabIndex, setTabIndex] = useState(0)
    const [isOpenGroomerNote, setIsOpenGroomerNote] = useState(false)
    const [overallComments, setOverallComments] = useState("")
    const [tabIndexNotes, setTabIndexNotes] = useState(0)
    const [firstTime, setFirstTime] = useState<any>(false)
    const [serviceType, setServiceType] = useState<any>("")
    const [puppyCut, setPuppyCut] = useState(false)
    const [standerdCut, setStanderdCut] = useState(false)
    const [handStrip, setHandStrip] = useState(false)
    const [shaveDown, setShaveDown] = useState(false)
    const [headComb, setHeadComb] = useState(0)
    const [headClade, setHeadBlade] = useState(0)
    const [headStyle, setHeadStyle] = useState(0)
    const [headScissorUsed, setHeadScissorUsed] = useState(false)
    const [faceComb, setFaceComb] = useState(0)
    const [faceBlade, setFaceBlade] = useState(0)
    const [faceStyle, setFaceStyle] = useState(0)
    const [faceScissorUsed, setFaceScissorUsed] = useState(false)
    const [earComb, setEarComb] = useState(0)
    const [earBlade, setEarBlade] = useState(0)
    const [earStyle, setEarStyle] = useState(0)
    const [earScissorUsed, setEarScissorUsed] = useState(false)
    const [bodyComb, setBodyComb] = useState(0)
    const [bodyBlade, setBodyBlade] = useState(0)
    const [bodyStyle, setBodyStyle] = useState(0)
    const [bodyScissorUsed, setBodyScissorUsed] = useState(false)
    const [legsComb, setLegsComb] = useState(0)
    const [legsBlade, setLegsBlade] = useState(0)
    const [legsStyle, setLegsStyle] = useState(0)
    const [legsScissorUsed, setLegsScissorUsed] = useState(false)
    const [tailComb, setTailComb] = useState(0)
    const [tailBlade, setTailBlade] = useState(0)
    const [tailStyle, setTailStyle] = useState(0)
    const [tailScissorUsed, setTailScissorUsed] = useState(false)
    const [feetComb, setFeetComb] = useState(0)
    const [feetBlade, setFeetBlade] = useState(0)
    const [feetStyle, setFeetStyle] = useState(0)
    const [feetScissorUsed, setFeetScissorUsed] = useState(false)
    const [dryingDiff, setDryingDiff] = useState<any>(false)
    const [recShampoo, setRecShampoo] = useState(0)

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex)
    }

    const handleTabChangeNotes = (event, newTabIndex) => {
        setTabIndex(newTabIndex)
    }

    const handleChangeActiveStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstTime((event.target as HTMLInputElement).value)
    }
    const handleServiceType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServiceType((event.target as HTMLInputElement).value)
    }
    const handleDryingStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDryingDiff((event.target as HTMLInputElement).value)
    }

    useEffect(() => {
        console.log("comb", headComb)
    }, [headComb])

    const saveNote = () => {
        let obj = {
            groomerNote: {
                bitActive: true,
                numGroomerNoteID: 0,
                numAppointmentID: 1,
                numEmployeeID: 1,
                numLocationID: 1,
                bitFirstGroom: firstTime === "true" ? true : false,
                varServiceType: serviceType,
                bitFullGroomPuppyCut: puppyCut,
                bitFullGoomBreedStandardCut: standerdCut,
                bitFullGroomHandStrip: handStrip,
                bitFullGroomShaveDown: shaveDown,
                numHeadCombID: headComb,
                numHeadBladeID: headClade,
                numHeadStyleID: headStyle,
                bitHeadScissorUsed: headScissorUsed,
                numFaceCombID: faceComb,
                numFaceBladeID: faceBlade,
                numFaceStyleID: faceStyle,
                bitFaceScissorUsed: faceScissorUsed,
                numEarCombID: earComb,
                numEarBladeID: earBlade,
                numEarStyleID: earStyle,
                bitEarScissorUsed: earScissorUsed,
                numBodyComdID: bodyComb,
                numBodyBladeID: bodyBlade,
                bitBodyScissorUsed: bodyScissorUsed,
                numLegComdID: legsComb,
                numLegBladeID: legsBlade,
                bitLegScissorUsed: legsScissorUsed,
                numTailCombID: tailComb,
                numTailBladeID: tailBlade,
                numTailStyleID: tailStyle,
                bitTailScissorUsed: tailScissorUsed,
                numFeetCombID: feetComb,
                numFeetBladeID: feetBlade,
                numFeetStyleID: feetStyle,
                bitFeetScissorUsed: feetScissorUsed,
                bitDifficultDrying: dryingDiff === "true" ? true : false,
                numRecommendedShampooID: recShampoo,
                varGroomerComment: overallComments,
            },
        }
        console.log("sent", obj)
        saveGroomerNote(obj)
    }

    async function saveGroomerNote(data) {
        await axiosPrivate
            .post(`GroomerBatherNote/PostGroomerNote`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                if (result.status == 200) {
                    toast.success("Groomer note successfully saved!")
                    setIsOpenGroomerNote(false)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    useEffect(() => {
        console.log("serType", serviceType)
    }, [serviceType])

    return (
        <div style={{ maxWidth: "1200px", width: "100%", margin: "100px 20px 130px 20px", display: "flex", flexDirection: "column", gap: "10px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: "30px" }}>
                <p style={{ fontSize: "28px", fontWeight: "600", color: "#192252" }}>Groomer Card</p>
                {/* <div>
                    <Button
                        color="primary"
                        variant='contained'
                        onClick={() => setIsOpenGroomerNote(true)}
                    >
                        Groomer Note
                    </Button>
                </div> */}
            </div>

            <div style={{ paddingTop: "20px", paddingBottom: "20px", backgroundColor: "#FFFF", borderRadius: "8px", border: "1px solid #EFF3FA", marginTop: "20px", width: "100%" }}>
                <Stepper alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </div>

            <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "100%", gap: "3rem", border: "1px solid #F3F4F6", padding: "1rem", borderRadius: "8px 0 0 8px", backgroundColor: "#FFFFFF" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "1rem" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", gap: ".6rem" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <Avatar shape="rounded" img={"https://pet-tech-api.s3.amazonaws.com/directories/Pet/50.jpg"} isVip={false} className={"medium"} />
                            </div>
                            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between" }}>
                                <div style={{ fontWeight: 600, fontSize: "16px", color: "#002867" }}>Bolt</div>
                                <div style={{ display: "flex", gap: "12px", alignItems: "center", marginTop: ".2rem" }}>
                                    <div style={{ fontWeight: 400, fontSize: 14, color: "#848FAC" }}>Pomeranian</div>
                                </div>
                                <div style={{ display: "flex", marginTop: ".5rem", gap: ".3rem", alignItems: "center" }}>
                                    <Chip size="small" customBackgroundColor={"#EFF3FA"} customBorder={"#EFF3FA"} customBorderRadius={"16px"} fontColor={"#192252"} label={"Large"} />
                                    <Chip size="small" customBackgroundColor={"#EFF3FA"} customBorder={"#EFF3FA"} customBorderRadius={"16px"} fontColor={"#192252"} label={"Male"} />
                                    <Chip size="small" customBackgroundColor={"#EFF3FA"} customBorder={"#EFF3FA"} customBorderRadius={"16px"} fontColor={"#192252"} label={"Dog"} />
                                    <Chip size="small" customBackgroundColor={"#EFF3FA"} customBorder={"#EFF3FA"} customBorderRadius={"16px"} fontColor={"#192252"} label={"MIX-Breed"} />
                                    <Warning style={{ color: "#EB1A1A", fontSize: "20px" }} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <Button color="info" variant="text" text="View Profile" />
                        </div>
                    </div>
                </div>
                <div style={{ borderRadius: "0 8px 8px 0", backgroundColor: "#DFE8F6", textAlign: "center", padding: "10px 20px 10px 20px" }}>
                    <p style={{ color: "#606D93" }}>cage</p>
                    <p style={{ color: "#192252", fontWeight: "600", fontSize: "24px" }}>A1</p>
                </div>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", gap: "10px" }}>
                <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>
                    <div style={{ backgroundColor: "#FFFF", border: "1px solid #F3F4F6", borderRadius: "8px", padding: "10px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #d6d6d7", paddingBottom: "10px" }}>
                            <p style={{ fontWeight: "600", fontSize: "14px", color: "#192252" }}>Notes</p>
                            <div>
                                <Button color="info" variant="text" text="Add New" />
                            </div>
                        </div>
                        <Box>
                            <Box>
                                <Tabs value={tabIndex} onChange={handleTabChange}>
                                    <Tab label="Before" />
                                    <Tab label="After" />
                                </Tabs>
                            </Box>
                            <Box sx={{ padding: 2 }}>
                                {tabIndex === 0 && (
                                    <div style={{ backgroundColor: "#F3F4F6", borderRadius: "8px", height: "310px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <ImageOutlined style={{ color: "#FFFF", fontSize: "100px" }} />
                                    </div>
                                )}
                                {tabIndex === 1 && (
                                    <div style={{ backgroundColor: "#F3F4F6", borderRadius: "8px", height: "310px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <ImageOutlined style={{ color: "#FFFF", fontSize: "100px" }} />
                                    </div>
                                )}
                            </Box>
                        </Box>
                        <div style={{ display: "flex", gap: "10px", marginLeft: "10px", width: "100%" }}>
                            <Button color="default" variant="outlined" text="Upload Images" iconLeft={<FileUploadOutlined style={{ color: "#2076FF" }} />} />
                            <Button color="primary" variant="contained" text="Take Pictures" iconLeft={<CameraAltOutlined style={{ color: "#FFFF" }} />} />
                        </div>
                    </div>
                    <div style={{ border: "1px solid #F3F4F6", backgroundColor: "#FFFFFF", borderRadius: "8px", padding: "10px" }}>
                        <p style={{ fontWeight: "600", fontSize: "14px", color: "#192252" }}>Wellness Evaluation</p>
                        <div style={{ marginTop: "10px", display: "flex", flexDirection: "column", gap: "10px", padding: "10px" }}>
                            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                <div>
                                    <p style={{ fontWeight: "500", fontSize: "14px", color: "#848FAC" }}>Date</p>
                                    <p style={{ fontWeight: "400", fontSize: "14px", color: "#192252" }}>SEP 15, 2022</p>
                                </div>
                                <div>
                                    <p style={{ fontWeight: "500", fontSize: "14px", color: "#848FAC" }}>Evaluator</p>
                                    <p style={{ fontWeight: "600", fontSize: "14px", color: "#2076FF" }}>Kristine Jo</p>
                                </div>
                            </div>
                            <div>
                                <Button color="info" variant="text" text="View Report" />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <div
                        style={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#DFE8F6", borderRadius: "8px 8px 0px 0px", padding: "15px 10px 10px 10px" }}
                    >
                        <p style={{ fontWeight: "600", fontSize: "14px", color: "#192252" }}>My Task List</p>
                        <div>
                            <Button color="primary" variant="contained" text="Add/Remove" />
                        </div>
                    </div>
                    <div style={{ backgroundColor: "#EFF3FA", border: "1px solid #F3F4F6", borderRadius: "0 0 8px 8px", padding: "10px" }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px" }}>
                                <div>
                                    <p style={{ fontWeight: "500", fontSize: "14px", color: "#192252" }}>Main service</p>
                                    <div style={{ display: "flex", justifyContent: "space-between", backgroundColor: "#FFFF", borderRadius: "8px", padding: "10px", alignItems: "center" }}>
                                        <div>
                                            <p style={{ fontWeight: "400", fontSize: "14px", color: "#424F7B" }}>Mini grooming</p>
                                        </div>
                                        <div style={{ display: "flex", gap: "10px" }}>
                                            <MUIButton
                                                style={{
                                                    borderRadius: 4,
                                                    backgroundColor: "#EF4444",
                                                    fontSize: "14px",
                                                }}
                                                variant="contained"
                                            >
                                                <Close style={{ color: "#FFFF" }} />
                                            </MUIButton>
                                            <MUIButton
                                                style={{
                                                    borderRadius: 4,
                                                    backgroundColor: "#22C55E",
                                                    fontSize: "14px",
                                                }}
                                                variant="contained"
                                            >
                                                <Check style={{ color: "#FFFF" }} />
                                            </MUIButton>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p style={{ fontWeight: "500", fontSize: "14px", color: "#192252" }}>Additional services</p>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "10px", height: "327px", overflowY: "scroll" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", backgroundColor: "#F0FDF4", borderRadius: "8px", padding: "10px", alignItems: "center" }}>
                                            <div>
                                                <p style={{ fontWeight: "400", fontSize: "14px", color: "#14532D" }}>Teeth brushing</p>
                                            </div>
                                            <div>
                                                <Button color="transparent" variant="text">
                                                    <UndoOutlined style={{ color: "#16A34A", fontSize: "30px" }} />
                                                </Button>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", backgroundColor: "#FDEDED", borderRadius: "8px", padding: "10px", alignItems: "center" }}>
                                            <div>
                                                <p style={{ fontWeight: "400", fontSize: "14px", color: "#720A0A" }}>Teeth brushing</p>
                                            </div>
                                            <div>
                                                <Button color="transparent" variant="text">
                                                    <UndoOutlined style={{ color: "#EF4444", fontSize: "30px" }} />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ borderTop: "1px solid #DFE8F6", width: "100%" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", padding: "10px" }}>
                                    <p style={{ fontWeight: "500", fontSize: "14px", color: "#424F7B" }}>Total estimated time</p>
                                    <p style={{ fontWeight: "500", fontSize: "14px", color: "#424F7B" }}>60 minutes</p>
                                </div>
                                <div style={{ display: "flex", gap: "10px", flexDirection: "column", padding: "10px" }}>
                                    <MUIButton
                                        style={{
                                            borderRadius: 4,
                                            backgroundColor: "#22C55E",
                                            fontSize: "14px",
                                            padding: "15px",
                                        }}
                                        variant="contained"
                                    >
                                        Start Grooming
                                    </MUIButton>
                                    <MUIButton
                                        style={{
                                            borderRadius: 4,
                                            backgroundColor: "#EFF3FA",
                                            fontSize: "14px",
                                            color: "#606D93",
                                            border: "1px solid #606D93",
                                            padding: "15px",
                                        }}
                                        variant="outlined"
                                    >
                                        Service Delayed
                                    </MUIButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ width: "100%", display: "flex", gap: "10px", justifyContent: "space-between" }}>
                <div style={{ width: "100%", border: "1px solid #F3F4F6", borderRadius: "8px" }}>
                    <div style={{ backgroundColor: "#FFFF", borderRadius: "8px", padding: "10px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #d6d6d7", paddingBottom: "10px" }}>
                            <p style={{ fontWeight: "600", fontSize: "14px", color: "#192252" }}>Notes</p>
                            <div>
                                <Button color="info" variant="text" text="Add New" />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px" }}>
                        <Box>
                            <Box>
                                <Tabs value={tabIndexNotes} onChange={handleTabChangeNotes}>
                                    <Tab label="Grooming" />
                                    <Tab label="General" />
                                </Tabs>
                            </Box>
                            <Box sx={{ padding: 2 }}>
                                {tabIndexNotes === 0 && (
                                    <div
                                        style={{
                                            backgroundColor: "#EFF5FF",
                                            border: "1px dashed #2076FF",
                                            borderRadius: "8px",
                                            display: "flex",
                                            padding: "20px",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            setIsOpenGroomerNote(true)
                                        }}
                                    >
                                        <p style={{ fontWeight: "600", fontSize: "14px", color: "#2076FF" }}>Add New Groomer Note</p>
                                    </div>
                                )}
                                {tabIndexNotes === 1 && (
                                    <div
                                        style={{
                                            backgroundColor: "#EFF5FF",
                                            border: "1px dashed #2076FF",
                                            borderRadius: "8px",
                                            display: "flex",
                                            padding: "20px",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <p style={{ fontWeight: "600", fontSize: "14px", color: "#2076FF" }}>Add New General Note</p>
                                    </div>
                                )}
                            </Box>
                        </Box>
                        <div>
                            <p style={{ fontWeight: "500", fontSize: "14px", color: "#606D93" }}>History</p>
                            <div style={{ display: "flex", flexDirection: "column", gap: "10px", height: "200px", overflowY: "scroll" }}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        backgroundColor: "#F9FAFB",
                                        border: "1px solid #EFF3FA",
                                        borderRadius: "8px",
                                        padding: "10px",
                                        alignItems: "center",
                                    }}
                                >
                                    <div>
                                        <p style={{ fontWeight: "600", fontSize: "14px", color: "#192252" }}>18 AUG 2022</p>
                                        <p style={{ fontWeight: "500", fontSize: "14px", color: "#606D93" }}>Esther Howard</p>
                                    </div>
                                    <div>
                                        <Button color="transparent" variant="text">
                                            <OpenInNewOutlined style={{ color: "#2076FF", fontSize: "30px" }} />
                                        </Button>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        backgroundColor: "#F9FAFB",
                                        border: "1px solid #EFF3FA",
                                        borderRadius: "8px",
                                        padding: "10px",
                                        alignItems: "center",
                                    }}
                                >
                                    <div>
                                        <p style={{ fontWeight: "600", fontSize: "14px", color: "#192252" }}>3 JAN 2022</p>
                                        <p style={{ fontWeight: "500", fontSize: "14px", color: "#606D93" }}>Esther Howard</p>
                                    </div>
                                    <div>
                                        <Button color="transparent" variant="text">
                                            <OpenInNewOutlined style={{ color: "#2076FF", fontSize: "30px" }} />
                                        </Button>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        backgroundColor: "#F9FAFB",
                                        border: "1px solid #EFF3FA",
                                        borderRadius: "8px",
                                        padding: "10px",
                                        alignItems: "center",
                                    }}
                                >
                                    <div>
                                        <p style={{ fontWeight: "600", fontSize: "14px", color: "#192252" }}>4 JAN 2022</p>
                                        <p style={{ fontWeight: "500", fontSize: "14px", color: "#606D93" }}>Esther Howard</p>
                                    </div>
                                    <div>
                                        <Button color="transparent" variant="text">
                                            <OpenInNewOutlined style={{ color: "#2076FF", fontSize: "30px" }} />
                                        </Button>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        backgroundColor: "#F9FAFB",
                                        border: "1px solid #EFF3FA",
                                        borderRadius: "8px",
                                        padding: "10px",
                                        alignItems: "center",
                                    }}
                                >
                                    <div>
                                        <p style={{ fontWeight: "600", fontSize: "14px", color: "#192252" }}>5 JAN 2022</p>
                                        <p style={{ fontWeight: "500", fontSize: "14px", color: "#606D93" }}>Esther Howard</p>
                                    </div>
                                    <div>
                                        <Button color="transparent" variant="text">
                                            <OpenInNewOutlined style={{ color: "#2076FF", fontSize: "30px" }} />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "100%" }}>
                    <div style={{ backgroundColor: "#FFFFFF", borderRadius: "8px", padding: "10px", border: "1px solid #F3F4F6" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #d6d6d7", paddingBottom: "10px" }}>
                            <p style={{ fontWeight: "600", fontSize: "14px", color: "#192252" }}>Pet owner details</p>
                            <div>
                                <Button color="info" variant="text" text="View" />
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", gap: ".6rem" }}>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Avatar isVip={true} img={"https://pet-tech-api.s3.amazonaws.com/directories/Customer/2.jpg"} className={"medium"} />
                                </div>

                                <div style={{ gap: "5px", width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between" }}>
                                    <div style={{ fontWeight: 500 }}>{"Miss. Simina Villa"}</div>
                                    <Chip label={"New york"} shape="square" icon={<Place style={{ fontSize: "15px" }} />} customStyle="BlueFilled" />
                                </div>
                            </div>
                            <div>
                                <Grid container spacing={2} style={{ marginTop: "1px" }}>
                                    <Grid item xs={4}>
                                        <p style={{ color: "#848FAC" }}>Contact #</p>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <p style={{ color: "#192252" }}>516-333-5897 (Mobile)</p>
                                        <p style={{ color: "#192252" }}>516-333-5897 (Work)</p>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} style={{ marginTop: "1px" }}>
                                    <Grid item xs={4}>
                                        <p style={{ color: "#848FAC" }}>Warning</p>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Chip size="small" customBackgroundColor={"#EB1A1A"} customBorderRadius={"12px"} fontColor={"#FFFF"} label={"Outstanding Bill"} />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal open={isOpenGroomerNote}>
                    <div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem", flexDirection: "column" }}>
                        <div style={{ backgroundColor: "#fff", maxWidth: "800px", width: "100%", borderRadius: "10px" }}>
                            <div style={{ padding: "20px" }}>
                                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <p style={{ fontWeight: 600, fontSize: "20px" }}>Groomer Note</p>
                                    <CloseButton onClick={() => setIsOpenGroomerNote(!isOpenGroomerNote)} />
                                </div>
                            </div>

                            <div style={{ padding: "20px", backgroundColor: "#F9FAFB", height: "500px", overflowY: "scroll" }}>
                                <div style={{ width: "100%", marginBottom: ".6rem" }}>
                                    <p style={{ fontWeight: "600", fontSize: "14px" }}>1. First groom at Lucky Paws</p>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={firstTime}
                                        onChange={handleChangeActiveStatus}
                                        style={{ flexDirection: "column" }}
                                    >
                                        <FormControlLabel value={true} control={<Radio style={{ padding: "0 5px 0 0" }} size={"small"} />} label={<p style={{ fontSize: "12px" }}>Yes</p>} />
                                        <FormControlLabel value={false} control={<Radio style={{ padding: "0 5px 0 0" }} size={"small"} />} label={<p style={{ fontSize: "12px" }}>No</p>} />
                                    </RadioGroup>
                                </div>

                                <div style={{ width: "100%", marginBottom: ".6rem" }}>
                                    <p style={{ fontWeight: "600", fontSize: "14px" }}>2. Service Type</p>
                                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={serviceType}
                                            onChange={handleServiceType}
                                            style={{ flexDirection: "column" }}
                                        >
                                            <FormControlLabel
                                                value={"Bath"}
                                                control={<Radio style={{ padding: "0 5px 0 0" }} size={"small"} />}
                                                label={<p style={{ fontSize: "12px" }}>Bath Only</p>}
                                            />
                                            <FormControlLabel
                                                value={"Full"}
                                                control={<Radio style={{ padding: "0 5px 0 0" }} size={"small"} />}
                                                label={<p style={{ fontSize: "12px" }}>Full Groom</p>}
                                            />
                                            <FormControlLabel
                                                value={"Mini"}
                                                control={<Radio style={{ padding: "0 5px 0 0" }} size={"small"} />}
                                                label={<p style={{ fontSize: "12px" }}>Mini Groom</p>}
                                            />
                                        </RadioGroup>
                                    </RadioGroup>
                                </div>

                                <div style={{ width: "100%", marginBottom: ".6rem" }}>
                                    <p style={{ fontWeight: "600", fontSize: "14px" }}>3. Full groom Type</p>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setPuppyCut(!puppyCut)} checked={puppyCut} />
                                        <p>Puppy Cut ( One Length All Over)</p>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setStanderdCut(!standerdCut)} checked={standerdCut} />
                                        <p>Breed Standard Cut</p>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setHandStrip(!handStrip)} checked={handStrip} />
                                        <p>Hand Strip</p>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setShaveDown(!shaveDown)} checked={shaveDown} />
                                        <p>Shave Down</p>
                                    </div>
                                </div>

                                <div style={{ width: "100%", marginBottom: ".6rem" }}>
                                    <p style={{ fontWeight: "600", fontSize: "14px" }}>4. Head</p>
                                    <div style={{ width: "100%", display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridColumnGap: "1rem", rowGap: "1rem", marginLeft: "8px" }}>
                                        <AdvancedInput
                                            required={false}
                                            isButton={false}
                                            label={"Select comb"}
                                            ObjectName={"Comb"}
                                            isCustom={false}
                                            Sequence={""}
                                            onChange={(e) => {
                                                setHeadComb(e)
                                            }}
                                        />
                                        <AdvancedInput
                                            required={false}
                                            isButton={false}
                                            label={"Select blade"}
                                            ObjectName={"Blade"}
                                            isCustom={false}
                                            Sequence={""}
                                            onChange={(e) => {
                                                setHeadBlade(e)
                                            }}
                                        />

                                        <AdvancedInput
                                            required={false}
                                            isButton={false}
                                            label={"Select style"}
                                            ObjectName={"Style"}
                                            isCustom={false}
                                            Sequence={""}
                                            onChange={(e) => {
                                                setHeadStyle(e)
                                            }}
                                        />

                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setHeadScissorUsed(!headScissorUsed)} checked={headScissorUsed} />
                                            <p>I used a Scissor</p>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ width: "100%", marginBottom: ".6rem" }}>
                                    <p style={{ fontWeight: "600", fontSize: "14px" }}>5. Face</p>
                                    <div style={{ width: "100%", display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridColumnGap: "1rem", rowGap: "1rem", marginLeft: "8px" }}>
                                        <AdvancedInput
                                            required={false}
                                            isButton={false}
                                            label={"Select comb"}
                                            ObjectName={"Comb"}
                                            isCustom={false}
                                            Sequence={""}
                                            onChange={(e) => {
                                                setFaceComb(e)
                                            }}
                                        />
                                        <AdvancedInput
                                            required={false}
                                            isButton={false}
                                            label={"Select blade"}
                                            ObjectName={"Blade"}
                                            isCustom={false}
                                            Sequence={""}
                                            onChange={(e) => {
                                                setFaceBlade(e)
                                            }}
                                        />

                                        <AdvancedInput
                                            required={false}
                                            isButton={false}
                                            label={"Select style"}
                                            ObjectName={"Style"}
                                            isCustom={false}
                                            Sequence={""}
                                            onChange={(e) => {
                                                setFaceStyle(e)
                                            }}
                                        />

                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setFaceScissorUsed(!faceScissorUsed)} checked={faceScissorUsed} />
                                            <p>I used a Scissor</p>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ width: "100%", marginBottom: ".6rem" }}>
                                    <p style={{ fontWeight: "600", fontSize: "14px" }}>6. Ears</p>
                                    <div style={{ width: "100%", display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridColumnGap: "1rem", rowGap: "1rem", marginLeft: "8px" }}>
                                        <AdvancedInput
                                            required={false}
                                            isButton={false}
                                            label={"Select comb"}
                                            ObjectName={"Comb"}
                                            isCustom={false}
                                            Sequence={""}
                                            onChange={(e) => {
                                                setEarComb(e)
                                            }}
                                        />
                                        <AdvancedInput
                                            required={false}
                                            isButton={false}
                                            label={"Select blade"}
                                            ObjectName={"Blade"}
                                            isCustom={false}
                                            Sequence={""}
                                            onChange={(e) => {
                                                setEarBlade(e)
                                            }}
                                        />

                                        <AdvancedInput
                                            required={false}
                                            isButton={false}
                                            label={"Select style"}
                                            ObjectName={"Style"}
                                            isCustom={false}
                                            Sequence={""}
                                            onChange={(e) => {
                                                setEarStyle(e)
                                            }}
                                        />

                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setEarScissorUsed(!earScissorUsed)} checked={earScissorUsed} />
                                            <p>I used a Scissor</p>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ width: "100%", marginBottom: ".6rem" }}>
                                    <p style={{ fontWeight: "600", fontSize: "14px" }}>7. Body</p>
                                    <div style={{ width: "100%", display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridColumnGap: "1rem", rowGap: "1rem", marginLeft: "8px" }}>
                                        <AdvancedInput
                                            required={false}
                                            isButton={false}
                                            label={"Select comb"}
                                            ObjectName={"Comb"}
                                            isCustom={false}
                                            Sequence={""}
                                            onChange={(e) => {
                                                setBodyComb(e)
                                            }}
                                        />
                                        <AdvancedInput
                                            required={false}
                                            isButton={false}
                                            label={"Select blade"}
                                            ObjectName={"Blade"}
                                            isCustom={false}
                                            Sequence={""}
                                            onChange={(e) => {
                                                setBodyBlade(e)
                                            }}
                                        />

                                        <AdvancedInput
                                            required={false}
                                            isButton={false}
                                            label={"Select style"}
                                            ObjectName={"Style"}
                                            isCustom={false}
                                            Sequence={""}
                                            onChange={(e) => {
                                                setBodyStyle(e)
                                            }}
                                        />

                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setBodyScissorUsed(!bodyScissorUsed)} checked={bodyScissorUsed} />
                                            <p>I used a Scissor</p>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ width: "100%", marginBottom: ".6rem" }}>
                                    <p style={{ fontWeight: "600", fontSize: "14px" }}>8. Legs</p>
                                    <div style={{ width: "100%", display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridColumnGap: "1rem", rowGap: "1rem", marginLeft: "8px" }}>
                                        <AdvancedInput
                                            required={false}
                                            isButton={false}
                                            label={"Select comb"}
                                            ObjectName={"Comb"}
                                            isCustom={false}
                                            Sequence={""}
                                            onChange={(e) => {
                                                setLegsComb(e)
                                            }}
                                        />
                                        <AdvancedInput
                                            required={false}
                                            isButton={false}
                                            label={"Select blade"}
                                            ObjectName={"Blade"}
                                            isCustom={false}
                                            Sequence={""}
                                            onChange={(e) => {
                                                setLegsBlade(e)
                                            }}
                                        />

                                        <AdvancedInput
                                            required={false}
                                            isButton={false}
                                            label={"Select style"}
                                            ObjectName={"Style"}
                                            isCustom={false}
                                            Sequence={""}
                                            onChange={(e) => {
                                                setLegsStyle(e)
                                            }}
                                        />

                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setLegsScissorUsed(!legsScissorUsed)} checked={legsScissorUsed} />
                                            <p>I used a Scissor</p>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ width: "100%", marginBottom: ".6rem" }}>
                                    <p style={{ fontWeight: "600", fontSize: "14px" }}>9. Tail</p>
                                    <div style={{ width: "100%", display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridColumnGap: "1rem", rowGap: "1rem", marginLeft: "8px" }}>
                                        <AdvancedInput
                                            required={false}
                                            isButton={false}
                                            label={"Select comb"}
                                            ObjectName={"Comb"}
                                            isCustom={false}
                                            Sequence={""}
                                            onChange={(e) => {
                                                setTailComb(e)
                                            }}
                                        />
                                        <AdvancedInput
                                            required={false}
                                            isButton={false}
                                            label={"Select blade"}
                                            ObjectName={"Blade"}
                                            isCustom={false}
                                            Sequence={""}
                                            onChange={(e) => {
                                                setTailBlade(e)
                                            }}
                                        />

                                        <AdvancedInput
                                            required={false}
                                            isButton={false}
                                            label={"Select style"}
                                            ObjectName={"Style"}
                                            isCustom={false}
                                            Sequence={""}
                                            onChange={(e) => {
                                                setTailStyle(e)
                                            }}
                                        />

                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setTailScissorUsed(!tailScissorUsed)} checked={tailScissorUsed} />
                                            <p>I used a Scissor</p>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ width: "100%", marginBottom: ".6rem" }}>
                                    <p style={{ fontWeight: "600", fontSize: "14px" }}>10. Feet</p>
                                    <div style={{ width: "100%", display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridColumnGap: "1rem", rowGap: "1rem", marginLeft: "8px" }}>
                                        <AdvancedInput
                                            required={false}
                                            isButton={false}
                                            label={"Select comb"}
                                            ObjectName={"Comb"}
                                            isCustom={false}
                                            Sequence={""}
                                            onChange={(e) => {
                                                setFeetComb(e)
                                            }}
                                        />
                                        <AdvancedInput
                                            required={false}
                                            isButton={false}
                                            label={"Select blade"}
                                            ObjectName={"Blade"}
                                            isCustom={false}
                                            Sequence={""}
                                            onChange={(e) => {
                                                setFeetBlade(e)
                                            }}
                                        />

                                        <AdvancedInput
                                            required={false}
                                            isButton={false}
                                            label={"Select style"}
                                            ObjectName={"Style"}
                                            isCustom={false}
                                            Sequence={""}
                                            onChange={(e) => {
                                                setFeetStyle(e)
                                            }}
                                        />

                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setFeetScissorUsed(!feetScissorUsed)} checked={feetScissorUsed} />
                                            <p>I used a Scissor</p>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ width: "100%", marginBottom: ".6rem" }}>
                                    <p style={{ fontWeight: "600", fontSize: "14px" }}>11. Difficult to dry?</p>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={dryingDiff}
                                        onChange={handleDryingStatus}
                                        style={{ flexDirection: "column" }}
                                    >
                                        <FormControlLabel value={true} control={<Radio style={{ padding: "0 5px 0 0" }} size={"small"} />} label={<p style={{ fontSize: "12px" }}>Yes</p>} />
                                        <FormControlLabel value={false} control={<Radio style={{ padding: "0 5px 0 0" }} size={"small"} />} label={<p style={{ fontSize: "12px" }}>No</p>} />
                                    </RadioGroup>
                                </div>

                                <div style={{ width: "100%", marginBottom: ".6rem" }}>
                                    <p style={{ fontWeight: "600", fontSize: "14px" }}>12. Recommendation for next groom</p>
                                    <AdvancedInput
                                        required={false}
                                        isButton={false}
                                        label={"Select Shampoo"}
                                        ObjectName={"Shampoo"}
                                        isCustom={false}
                                        Sequence={""}
                                        onChange={(e) => {
                                            setRecShampoo(e)
                                        }}
                                    />
                                </div>

                                <div style={{ width: "100%", marginBottom: ".6rem" }}>
                                    <p style={{ fontWeight: "600", fontSize: "14px" }}>Overall Groomer Comments</p>
                                    <TextField
                                        style={{ width: "100%", marginTop: "1rem" }}
                                        label={<div style={{ display: "flex", fontSize: "14px" }}>{`Add your suggestions here.`}</div>}
                                        required={true}
                                        onChange={(e) => {
                                            setOverallComments(e.target.value)
                                        }}
                                        type={"text"}
                                        variant="outlined"
                                        multiline={true}
                                        value={overallComments}
                                    />
                                </div>
                            </div>

                            <div style={{ padding: "20px", backgroundColor: "#fff", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        text="Save Note"
                                        onClick={() => {
                                            saveNote()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default GroomingCard
