import { ArrowBack, Edit, OpenInNewOutlined, Verified } from "@mui/icons-material"
import { Avatar, Button, Chip } from "../../../../components"
import Steps from "../Steps/Steps"
import { useEffect, useState } from "react"
import { Box, CircularProgress, Grid, Tab, Tabs } from "@mui/material"
import CheckInModal from "../../../../components/ExtModals/CheckInModal"
import { axiosPrivate, buildHeaderObject } from "../../../../apis/axios"
import { toast } from "react-toastify"
import { Link, useNavigate, useLocation } from "react-router-dom"

import styles from "./InquiryTab.module.scss"
import { fullDate, shortDate } from "../../../../utils/DateFormatter"
import GroomerNote from "../../../../components/ExtModals/GroomerNote"
import { formatDateDisplay } from "../../../../utils/TimeHandler"
import ViewGroomerNote from "../../../../components/ExtModals/ViewGroomerNote"
import AddGroomerNote from "../../../../components/ExtModals/AddGroomerNote"
import useAuth from "../../../../hooks/useAuth"

const InquiryTab = (props: { setViewAppointmentDetails: CallableFunction; bookingID; setWorking: CallableFunction }) => {
    const navigate = useNavigate()
    const location = useLocation() as any
    const { auth, setAuth } = useAuth() as any

    const [isOpenCheckInOutModal, setIsOpenCheckInOutModal] = useState(false)
    const [summaryDetails, setSummaryDetails] = useState({}) as any
    const [statusInfo, setStatusInfo] = useState({}) as any
    const [isOpenAddGroomerNoteShow, setIsOpenAddGroomerNoteShow] = useState(false)
    const [isOpenStepper, setIsOpenStepper] = useState(false)

    const [tabIndex, setTabIndex] = useState(0)
    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex)
    }

    /* GROOMER NOTES */
    const [isOpenGroomerNote, setIsOpenGroomerNote] = useState(false)
    const [appointmentServices, setAppointmentServices] = useState([]) as any
    const [appointmentProducts, setAppointmentProducts] = useState([]) as any
    const [groomerNote, setGroomerNote] = useState<any>({})
    const [isOpenGroomerNoteShow, setIsOpenGroomerNoteShow] = useState(false)

    const [isOpenViewModal, setIsOpenViewModal] = useState(false)
    const [groomerNotes, setGroomerNotes] = useState([]) as any
    const [isLoading, setIsLoading] = useState(false)

    /* GROOMER NOTES */

    async function getAppointmentInfo(bookingID) {
        setIsLoading(true)
        await axiosPrivate
            .get(`Appointment/GetAppointmnetByID?AppointmentID=${bookingID}`, buildHeaderObject(auth))
            .then((result) => {
                if (result.status === 200) {
                    console.log(result.data.Appointment)
                    setSummaryDetails(result.data.Appointment)
                    console.log("summ", result.data.Appointment.Customer.numCustomerID)
                    setIsLoading(false)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
                setIsLoading(false)
            })
    }

    useEffect(() => {
        getAppointmentInfo(props?.bookingID)
        getAppointmentServicesProducts(props?.bookingID)
        console.log(props?.bookingID)
    }, [props?.bookingID])

    const navigateWorking = () => {
        const from = location.state?.from?.pathname || `working`
        navigate(from, { state: { appData: summaryDetails?.numAppointmentID }, replace: true })
    }

    async function getAppointmentServicesProducts(bookingID) {
        await axiosPrivate
            .get(`Appointment/GetProductOrServicesByAppointmentID?AppointmentID=${bookingID}`, buildHeaderObject(auth))
            .then((result) => {
                if (result.status === 200) {
                    setAppointmentProducts(result.data.Appointment.Products)
                    setAppointmentServices(result.data.Appointment.Services)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const openGroomerNote = (index) => {
        console.log("note", summaryDetails.GroomerNotes[index])
        setIsOpenGroomerNoteShow(true)
    }

    const viewGroomerNote = () => {
        setGroomerNotes(summaryDetails?.GroomerNotes)
        setIsOpenViewModal(true)
    }

    async function getAppointmentGroomerNotes(bookingID) {
        await axiosPrivate
            .get(`Appointment/GetAppointmnetByID?AppointmentID=${bookingID}`, buildHeaderObject(auth))
            .then((result) => {
                if (result.status === 200) {
                    console.log("notes", result.data.Appointment.GroomerNotes)
                    const summary = { ...summaryDetails }
                    summary.GroomerNotes = result.data.Appointment.GroomerNotes
                    setSummaryDetails(summary)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const EditNote = (index) => {
        setGroomerNote(summaryDetails.GroomerNotes[index])
        setIsOpenStepper(true)
    }

    return (
        <>
            {!isLoading ? (
                <>
                    <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                        <div className={styles.appointment}>
                            <Button color="info" variant="text" onClick={() => props.setViewAppointmentDetails(false)}>
                                <ArrowBack style={{ marginRight: "5px", fontSize: "20px" }}></ArrowBack>My Appointments
                            </Button>
                        </div>

                        <div className={styles.appointment}>
                            <Steps statusSummary={summaryDetails} generalType={false} />
                        </div>

                        <div style={{ display: "flex", width: "100%" }}>
                            <div style={{ flex: 8, gap: "3rem", border: "1px solid #F3F4F6", padding: "1rem", borderRadius: "8px 0 0 8px", backgroundColor: "#FFFFFF" }}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between", gap: ".6rem" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", marginRight: "10px" }}>
                                            <Avatar
                                                shape="rounded"
                                                img={`${process.env.REACT_APP_MEDIA_URL}directories/pets/${summaryDetails.Pet != undefined ? summaryDetails.Pet.PetImagePath : null}`}
                                                isVip={false}
                                                className={"auto"}
                                            />
                                        </div>
                                        <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between" }}>
                                            <div style={{ fontWeight: 600, fontSize: "16px", color: "#002867" }}>{summaryDetails.Pet != undefined ? summaryDetails.Pet.PetName : null}</div>
                                            <div style={{ fontWeight: 400, fontSize: 14, color: "#848FAC" }}>{summaryDetails.Pet != undefined ? summaryDetails.Pet.PetBreed : null}</div>

                                            <div style={{ display: "flex", marginTop: "15px", gap: ".3rem", alignItems: "center" }}>
                                                <Chip
                                                    size="small"
                                                    customBackgroundColor={"#EFF3FA"}
                                                    customBorder={"#EFF3FA"}
                                                    customBorderRadius={"16px"}
                                                    fontColor={"#192252"}
                                                    label={summaryDetails.Pet != undefined ? summaryDetails.Pet.varPetSizeName : null}
                                                />
                                                <Chip
                                                    size="small"
                                                    customBackgroundColor={"#EFF3FA"}
                                                    customBorder={"#EFF3FA"}
                                                    customBorderRadius={"16px"}
                                                    fontColor={"#192252"}
                                                    label={summaryDetails.Pet != undefined ? (summaryDetails.Pet.PetColor != "" ? summaryDetails.Pet.PetColor : "-") : "-"}
                                                />
                                                <Chip
                                                    size="small"
                                                    customBackgroundColor={"#EFF3FA"}
                                                    customBorder={"#EFF3FA"}
                                                    customBorderRadius={"16px"}
                                                    fontColor={"#192252"}
                                                    label={summaryDetails.Pet != undefined ? summaryDetails.Pet.PetSex : null}
                                                />
                                                <Chip
                                                    size="small"
                                                    customBackgroundColor={"#EFF3FA"}
                                                    customBorder={"#EFF3FA"}
                                                    customBorderRadius={"16px"}
                                                    fontColor={"#192252"}
                                                    label={summaryDetails.Pet != undefined ? summaryDetails.Pet.PetType : null}
                                                />
                                                {/* <Warning style={{ color: "#EB1A1A", fontSize: "20px" }} /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: 1,
                                    borderRadius: "0 8px 8px 0",
                                    backgroundColor: "#DFE8F6",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "10px 20px 10px 20px",
                                }}
                            >
                                <p style={{ color: "#606D93", fontSize: "14px", fontWeight: "600" }}>CAGE</p>
                                <p style={{ color: "#192252", fontWeight: "600", fontSize: "32px" }}>{summaryDetails?.CageID === 0 ? "-" : `#${summaryDetails?.CageID}`}</p>
                            </div>
                        </div>

                        {Object.keys(summaryDetails).length !== 0 ? (
                            <GroomerNote
                                bookingDetails={summaryDetails}
                                isOpenGroomerNoteModal={isOpenStepper}
                                setIsOpenGroomerNoteModal={(value) => setIsOpenStepper(value)}
                                isEdit={true}
                                type={"copy"}
                                NoteDetails={groomerNote}
                                isSuccess={(e) => {
                                    if (e === true) {
                                        setIsOpenStepper(false)
                                        getAppointmentGroomerNotes(summaryDetails.numAppointmentID)
                                    }
                                }}
                            />
                        ) : null}

                        <ViewGroomerNote isOpenViewModal={isOpenViewModal} setIsOpenViewModal={(value) => setIsOpenViewModal(value)} notes={groomerNotes} mode={"groomerView"} user={"groomer"} />

                        <div style={{ display: "flex", gap: "15px", width: "100%", justifyContent: "space-between" }}>
                            <div style={{ width: "100%", backgroundColor: "#FFFF", border: "1px solid #F3F4F6", borderRadius: "8px", padding: "10px" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #d6d6d7", padding: "0 10px 10px 10px" }}>
                                    <p style={{ fontWeight: "600", fontSize: "16px", color: "#192252", padding: "10px 0", margin: 0 }}>Medical records</p>
                                </div>
                                <Grid container spacing={2} style={{ marginTop: "1px", padding: "0 10px 10px 10px" }}>
                                    <Grid item xs={4}>
                                        <div>
                                            <p style={{ color: "#848FAC", fontSize: "14px", fontWeight: "400" }}>Conditions</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                                            {summaryDetails.Pet != undefined
                                                ? summaryDetails.Pet?.Medical.map((medical) => {
                                                      return (
                                                          <Chip
                                                              size="small"
                                                              customBackgroundColor={"#bbf7d0"}
                                                              customBorderRadius={"12px"}
                                                              fontColor={"#14532d"}
                                                              label={medical?.varMedicalConditionName}
                                                          />
                                                      )
                                                  })
                                                : null}
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} style={{ marginTop: "1px", padding: "0 10px 0 10px" }}>
                                    <Grid item xs={4}>
                                        <div>
                                            <p style={{ color: "#848FAC", fontSize: "14px", fontWeight: "400" }}>Vaccination</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                                            {summaryDetails.Pet != undefined
                                                ? summaryDetails.Pet?.Vaccine.map((vaccine) => {
                                                      return <Chip size="small" customBackgroundColor={"#bbf7d0"} customBorderRadius={"12px"} fontColor={"#14532d"} label={vaccine?.varVaccineName} />
                                                  })
                                                : null}
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>

                            <div style={{ width: "100%", backgroundColor: "#EFF3FA", border: "1px solid #EFF3FA", borderRadius: "8px", padding: "10px" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #d6d6d7", padding: "0 10px 10px 10px" }}>
                                    <p style={{ fontWeight: "600", fontSize: "16px", color: "#192252", padding: "10px 0", margin: 0 }}>Booking Details</p>
                                </div>

                                <Grid container spacing={1} style={{ marginTop: "1px", padding: "0 10px" }}>
                                    <Grid item xs={4}>
                                        <p style={{ color: "#848FAC", fontSize: "14px" }}>Booking ID</p>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <p style={{ color: "#192252", fontSize: "14px" }}>#{summaryDetails.numAppointmentID}</p>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} style={{ marginTop: "1px", padding: "0 10px" }}>
                                    <Grid item xs={4}>
                                        <p style={{ color: "#848FAC", fontSize: "14px" }}>Source</p>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <p style={{ color: "#192252", fontSize: "14px" }}>{summaryDetails.varLocationName}</p>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} style={{ marginTop: "1px", padding: "0 10px" }}>
                                    <Grid item xs={4}>
                                        <p style={{ color: "#848FAC", fontSize: "14px" }}>Date/Time</p>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <p style={{ color: "#192252", fontSize: "14px" }}>{fullDate(summaryDetails.dtApptStartTime)}</p>
                                    </Grid>
                                </Grid>

                                <div style={{ width: "100%", display: "flex", margin: "10px", justifyContent: "center", gap: "10px" }}>
                                    {Object.keys(summaryDetails).length !== 0 ? (
                                        summaryDetails?.IsApptCheckedInStatus === false ? (
                                            <>
                                                <Button onClick={() => setIsOpenCheckInOutModal(true)} color="primary" variant="contained" text="Check In" />
                                                <CheckInModal
                                                    bookingDetails={summaryDetails}
                                                    isOpenCheckInModal={isOpenCheckInOutModal}
                                                    setIsOpenCheckInModal={(value) => setIsOpenCheckInOutModal(value)}
                                                    appProducts={appointmentProducts}
                                                    appServices={appointmentServices}
                                                    isSuccess={(e) => {
                                                        if (e === true) {
                                                            getAppointmentInfo(props?.bookingID)
                                                        }
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            <Button onClick={() => navigateWorking()} color="primary" variant="contained" text="GO TO SERVICE AREA" />
                                        )
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <div style={{ display: "flex", gap: "15px", width: "100%", justifyContent: "space-between" }}>
                            <div style={{ width: "100%", backgroundColor: "#FFFF", border: "1px solid #F3F4F6", borderRadius: "8px", padding: "10px" }}>
                                <div style={{ padding: "0 10px 10px 10px", borderBottom: "1px solid #d6d6d7", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <p style={{ fontWeight: "600", fontSize: "16px", color: "#192252", padding: "10px 0", margin: 0 }}>Notes</p>
                                    {summaryDetails === undefined ? (
                                        "Loading..."
                                    ) : summaryDetails.GroomerNotes?.length > 0 ? null : (
                                        <Button
                                            color="info"
                                            variant="text"
                                            onClick={() => {
                                                setIsOpenAddGroomerNoteShow(true)
                                            }}
                                        >
                                            Groomer Note Templates
                                        </Button>
                                    )}

                                    {Object.keys(summaryDetails).length !== 0 ? (
                                        <AddGroomerNote
                                            bookingDetails={summaryDetails}
                                            isOpenAddGroomerNoteModal={isOpenAddGroomerNoteShow}
                                            setIsOpenAddGroomerNoteModal={(value) => setIsOpenAddGroomerNoteShow(value)}
                                            type={"add"}
                                            isSuccess={(e) => {
                                                if (e === true) {
                                                    setIsOpenAddGroomerNoteShow(false)
                                                    getAppointmentGroomerNotes(summaryDetails.numAppointmentID)
                                                }
                                            }}
                                        />
                                    ) : null}
                                </div>

                                {Object.keys(summaryDetails).length !== 0 ? (
                                    <GroomerNote
                                        bookingDetails={summaryDetails}
                                        isOpenGroomerNoteModal={isOpenGroomerNote}
                                        setIsOpenGroomerNoteModal={(value) => setIsOpenGroomerNote(value)}
                                        type={"add"}
                                        isSuccess={(e) => {
                                            if (e === true) {
                                                getAppointmentInfo(props?.bookingID)
                                            }
                                        }}
                                    />
                                ) : null}

                                <div>
                                    <Box>
                                        <Box>
                                            <Tabs value={tabIndex} onChange={handleTabChange}>
                                                <Tab label="Grooming" />
                                                <Tab label="General" />
                                            </Tabs>
                                        </Box>
                                        <Box sx={{ paddingTop: 1 }}>
                                            {tabIndex === 0 && (
                                                <>
                                                    <div style={{ display: "flex", flexDirection: "column", gap: "10px", height: "200px", overflowY: "scroll" }}>
                                                        {summaryDetails?.GroomerNotes !== undefined ? (
                                                            summaryDetails?.GroomerNotes.length > 0 ? (
                                                                summaryDetails?.GroomerNotes.map((note, index) => {
                                                                    if (note.numGroomerNoteID !== 0) {
                                                                        return (
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "space-between",
                                                                                    backgroundColor: "#F9FAFB",
                                                                                    border: "1px solid #EFF3FA",
                                                                                    borderRadius: "8px",
                                                                                    padding: "10px",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <div>
                                                                                    <p style={{ fontWeight: "600", fontSize: "14px", color: "#192252" }}>{shortDate(note.dtCreatedDate)}</p>
                                                                                    <p
                                                                                        style={{
                                                                                            fontWeight: "500",
                                                                                            fontSize: "14px",
                                                                                            color: "#606D93",
                                                                                            wordWrap: "break-word",
                                                                                            whiteSpace: "pre-wrap",
                                                                                            lineHeight: "1.5",
                                                                                            padding: "10px",
                                                                                            maxWidth: "100%",
                                                                                        }}
                                                                                    >
                                                                                        {note.varGroomerComment}
                                                                                    </p>
                                                                                    <p style={{ fontWeight: "500", fontSize: "12px", color: "#606D93" }}>
                                                                                        {note.varCreatedEmployeeFirstName} {note.varCreatedEmployeeLastName}
                                                                                    </p>
                                                                                    {note?.IsCutomerPreffered ? (
                                                                                        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                                                            <Verified style={{ color: "#edb80b", fontSize: "20px" }} />
                                                                                            <p style={{ color: "#d3a71a", fontWeight: "600", margin: "0" }}>Customer's Pick</p>
                                                                                        </div>
                                                                                    ) : null}
                                                                                </div>
                                                                                <div
                                                                                    style={{ display: "flex", flexDirection: "column", gap: "10px", border: "1px solid #e0e0e0", borderRadius: "8px" }}
                                                                                >
                                                                                    <Button
                                                                                        color="transparent"
                                                                                        variant="text"
                                                                                        onClick={() => {
                                                                                            viewGroomerNote()
                                                                                        }}
                                                                                    >
                                                                                        <OpenInNewOutlined style={{ color: "#2076FF", fontSize: "30px" }} />
                                                                                    </Button>
                                                                                    {summaryDetails?.apptStatus === "CHECKED-OUT" || summaryDetails?.apptStatus === "PICKUP-READY" ? null : (
                                                                                        <Button
                                                                                            color="transparent"
                                                                                            variant="text"
                                                                                            onClick={() => {
                                                                                                EditNote(index)
                                                                                            }}
                                                                                        >
                                                                                            <Edit style={{ color: "#2076FF", fontSize: "30px" }} />
                                                                                        </Button>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    } else {
                                                                        return <p style={{ fontWeight: "500", fontSize: "12px", color: "#606D93" }}>No groomer notes found</p>
                                                                    }
                                                                })
                                                            ) : (
                                                                <p style={{ fontWeight: "500", fontSize: "12px", color: "#606D93" }}>No groomer notes found</p>
                                                            )
                                                        ) : null}
                                                    </div>
                                                </>
                                            )}
                                            {tabIndex === 1 && <div style={{ border: "1px solid #EFF3FA", borderRadius: "8px" }}></div>}
                                        </Box>
                                    </Box>
                                </div>
                            </div>

                            {/* <StepperNotes noteData={noteData} type={"copy"} bookingDetails={summaryDetails}      
                            isSuccess={(e) => {
                                if (e === true) {
                                    // props.isSuccess(true)
                                    // props.setIsOpenGroomerNoteModal(false)
                                }
                            }} /> */}
                            {Object.keys(summaryDetails).length !== 0 ? (
                                <GroomerNote
                                    bookingDetails={summaryDetails}
                                    isOpenGroomerNoteModal={isOpenGroomerNoteShow}
                                    setIsOpenGroomerNoteModal={(value) => setIsOpenGroomerNoteShow(value)}
                                    type={"show"}
                                    NoteDetails={groomerNote}
                                    isSuccess={() => {}}
                                />
                            ) : null}

                            <div style={{ width: "100%", backgroundColor: "#FFFF", border: "1px solid #F3F4F6", borderRadius: "8px", padding: "10px" }}>
                                <div style={{ padding: "0 10px 10px 10px", borderBottom: "1px solid #d6d6d7", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <p style={{ fontWeight: "600", fontSize: "16px", color: "#192252", padding: "10px 0", margin: 0 }}>Pet Owner Details</p>
                                </div>

                                <div style={{ padding: "15px 10px 20px 10px" }}>
                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                        <Avatar
                                            isVip={false}
                                            img={`${process.env.REACT_APP_MEDIA_URL}directories/customers/${
                                                summaryDetails.Customer != undefined ? summaryDetails.Customer.varCustomerImagePath : null
                                            }`}
                                            className={"Medium"}
                                        />
                                        <div>
                                            <p style={{ color: "#192252", fontWeight: "600", fontSize: "14px" }}>
                                                {summaryDetails.Customer != undefined ? summaryDetails.Customer.varCustomerFirstName + " " + summaryDetails.Customer.varCustomerLastName : null}
                                            </p>
                                        </div>
                                    </div>
                                    <Grid container spacing={1} style={{ marginTop: "1px" }}>
                                        <Grid item xs={4}>
                                            <p style={{ color: "#848FAC", fontSize: "14px" }}>Contact #</p>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <p style={{ color: "#192252", fontSize: "14px" }}>
                                                {summaryDetails.Customer != undefined ? summaryDetails.Customer.varCustomerMobilePhone : null} (Mobile)
                                            </p>
                                            <p style={{ color: "#192252", fontSize: "14px" }}>{summaryDetails.Customer != undefined ? summaryDetails.Customer.varCustomerWorkPhone : null} (Work)</p>
                                            <p style={{ color: "#192252", fontSize: "14px" }}>{summaryDetails.Customer != undefined ? summaryDetails.Customer.varCustomerHomePhone : null} (Home)</p>
                                            {/* <p style={{color:"#192252"}}>516-333-5897 (Work)</p> */}
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1} style={{ marginTop: "1px" }}>
                                        <Grid item xs={4}>
                                            <p style={{ color: "#848FAC", fontSize: "14px" }}>Email</p>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <p style={{ color: "#192252", fontSize: "14px" }}>{summaryDetails.Customer != undefined ? summaryDetails.Customer.varCustomerEmail : null}</p>
                                        </Grid>
                                    </Grid>

                                    {/* <Grid container spacing={1} style={{marginTop:"5px"}}>
                                    <Grid item xs={4}>
                                        <p style={{color:"#848FAC", fontSize: "14px"}}>Warning</p>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Chip size='small' customBackgroundColor={"#EB1A1A"} customBorderRadius={"12px"} fontColor={"#FFFF"} label={"Outstanding Bill"} />
                                    </Grid>
                                </Grid> */}
                                </div>

                                <Box
                                    sx={{
                                        marginTop: "10px",
                                    }}
                                >
                                    <div style={{ padding: "20px", backgroundColor: "#EFF3FA", borderRadius: "8px", display: "flex", flexDirection: "column" }}>
                                        <p style={{ fontWeight: "600", fontSize: "16px", color: "#192252", margin: 0 }}>Assigned staff</p>
                                        {summaryDetails?.Employee?.EmployeeID === summaryDetails?.Employee?.DealerID ? (
                                            <div>
                                                <div style={{ display: "flex", alignItems: "center", gap: "20px", padding: "15px 0 0 0" }}>
                                                    <p style={{ color: "#6B7280", fontSize: "14px", margin: "0" }}>Groomer</p>
                                                    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "10px" }}>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                gap: "10px",
                                                                alignItems: "center",
                                                                backgroundColor: "#fff",
                                                                padding: "10px",
                                                                borderRadius: "8px",
                                                                flex: "1 1 0px",
                                                                minWidth: "250px",
                                                                boxSizing: "border-box",
                                                            }}
                                                        >
                                                            <Avatar
                                                                isVip={false}
                                                                img={`${process.env.REACT_APP_MEDIA_URL}directories/employees/${
                                                                    summaryDetails.Employee != undefined ? summaryDetails.Employee.varEmployeeImagePath : null
                                                                }`}
                                                                className={"Medium"}
                                                            />
                                                            <div>
                                                                <p style={{ color: "#192252", fontWeight: "600", fontSize: "14px" }}>
                                                                    {summaryDetails.Employee != undefined
                                                                        ? `${summaryDetails?.Employee?.varEmployeeFirstName} ${summaryDetails.Employee.varEmployeeLastName}`
                                                                        : null}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                gap: "10px",
                                                                alignItems: "center",
                                                                backgroundColor: "#fff",
                                                                padding: "10px",
                                                                borderRadius: "8px",
                                                                flex: "1 1 0px",
                                                                minWidth: "250px",
                                                                boxSizing: "border-box",
                                                            }}
                                                        >
                                                            <p style={{ fontSize: "13px", fontWeight: "500" }}>
                                                                Start: {formatDateDisplay(summaryDetails?.Employee?.DealerStartTime)} - End:{" "}
                                                                {formatDateDisplay(summaryDetails?.Employee?.EmployeeEndTime)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", gap: "20px", padding: "15px 0 0 0" }}>
                                                    <p style={{ color: "#6B7280", fontSize: "14px", margin: "0" }}>Bather</p>

                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            flexWrap: "wrap",
                                                            gap: "10px",
                                                            alignItems: "center",
                                                            backgroundColor: "#fff",
                                                            padding: "10px",
                                                            borderRadius: "8px",
                                                            flex: "1 1 0px",
                                                            minWidth: "250px",
                                                            boxSizing: "border-box",
                                                        }}
                                                    >
                                                        <Avatar
                                                            isVip={false}
                                                            img={`${process.env.REACT_APP_MEDIA_URL}directories/employees/${
                                                                summaryDetails.Employee != undefined ? summaryDetails.Employee.DealerImagePath : null
                                                            }`}
                                                            className={"Medium"}
                                                        />
                                                        <div>
                                                            <p style={{ color: "#192252", fontWeight: "600", fontSize: "14px" }}>
                                                                {summaryDetails.Employee != undefined
                                                                    ? `${summaryDetails?.Employee?.varBatherFirstName} ${summaryDetails.Employee.varBatherLastName}`
                                                                    : null}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            flexWrap: "wrap",
                                                            gap: "10px",
                                                            alignItems: "center",
                                                            backgroundColor: "#fff",
                                                            padding: "10px",
                                                            borderRadius: "8px",
                                                            flex: "1 1 0px",
                                                            minWidth: "250px",
                                                            boxSizing: "border-box",
                                                        }}
                                                    >
                                                        <p style={{ fontSize: "13px", fontWeight: "500" }}>
                                                            Start: {formatDateDisplay(summaryDetails?.Employee?.DealerStartTime)} - End: {formatDateDisplay(summaryDetails?.Employee?.DealerEndTime)}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", gap: "20px", padding: "15px 0 0 0" }}>
                                                    <p style={{ color: "#6B7280", fontSize: "14px", margin: "0" }}>Groomer</p>

                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            flexWrap: "wrap",
                                                            gap: "10px",
                                                            alignItems: "center",
                                                            backgroundColor: "#fff",
                                                            padding: "10px",
                                                            borderRadius: "8px",
                                                            flex: "1 1 0px",
                                                            minWidth: "250px",
                                                            boxSizing: "border-box",
                                                        }}
                                                    >
                                                        <Avatar
                                                            isVip={false}
                                                            img={`${process.env.REACT_APP_MEDIA_URL}directories/employees/${
                                                                summaryDetails.Employee != undefined ? summaryDetails.Employee.varEmployeeImagePath : null
                                                            }`}
                                                            className={"Medium"}
                                                        />
                                                        <div>
                                                            <p style={{ color: "#192252", fontWeight: "600", fontSize: "14px" }}>
                                                                {summaryDetails.Employee != undefined
                                                                    ? `${summaryDetails?.Employee?.varEmployeeFirstName} ${summaryDetails.Employee.varEmployeeLastName}`
                                                                    : null}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            flexWrap: "wrap",
                                                            gap: "10px",
                                                            alignItems: "center",
                                                            backgroundColor: "#fff",
                                                            padding: "10px",
                                                            borderRadius: "8px",
                                                            flex: "1 1 0px",
                                                            minWidth: "250px",
                                                            boxSizing: "border-box",
                                                        }}
                                                    >
                                                        <p style={{ fontSize: "13px", fontWeight: "500" }}>
                                                            Start: {formatDateDisplay(summaryDetails?.Employee?.EmployeeStartTime)} - End:{" "}
                                                            {formatDateDisplay(summaryDetails?.Employee?.EmployeeEndTime)}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Box>

                                {/* <div style={{ padding: '20px',backgroundColor: '#EFF3FA', borderRadius:"8px" }}>
                                <p style={{ fontWeight: "600", fontSize: "16px", color: "#192252", margin: 0}}>Assigned staff</p>
                                
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '20px', padding: '15px 0 0 0' }}>
                                        <p style={{color:"#6B7280", fontSize: "14px", margin: '0' }}>Groomer</p>

                                        <div style={{display:"flex", gap:"10px", alignItems:"center", backgroundColor: '#fff', padding: '10px', borderRadius:"8px"}}>
                                            <Avatar isVip={false} img={`${process.env.REACT_APP_MEDIA_URL}directories/employees/${(summaryDetails.Employee != undefined) ? summaryDetails.Employee.varEmployeeImagePath : null}`} className={'Medium'} />
                                            <div>
                                                <p style={{color:"#192252", fontWeight:"600", fontSize: "14px"}}>{(summaryDetails.Employee != undefined) ? summaryDetails.Employee.varEmployeeFirstName + " " + summaryDetails.Employee.varEmployeeLastName : null}</p>
                                            </div>
                                        </div>
                                    </div>                                                                         
                            </div> */}
                            </div>
                        </div>

                        <div style={{ display: "flex", gap: "15px", width: "100%", justifyContent: "space-between" }}>
                            <div style={{ width: "100%", backgroundColor: "#FFFF", border: "1px solid #F3F4F6", borderRadius: "8px", padding: "10px" }}>
                                <div style={{ padding: "0 10px 10px 10px", borderBottom: "1px solid #d6d6d7", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <p style={{ fontWeight: "600", fontSize: "16px", color: "#192252", padding: "10px 0", margin: 0 }}>List of Services</p>
                                </div>

                                <div style={{ padding: "10px" }}>
                                    <div style={{ display: "flex", gap: "15px" }}>
                                        <div style={{ width: "100%", flex: 5 }}>
                                            <p style={{ fontWeight: "600", fontSize: "16px", color: "#192252", padding: "10px 0", marginBottom: "10px" }}>Main Service</p>

                                            <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                                                {summaryDetails.Services !== undefined
                                                    ? summaryDetails.Services.map((service) => {
                                                          return !service.bitIsAdditional ? (
                                                              <Chip
                                                                  label={service?.varServiceItemName}
                                                                  shape="round"
                                                                  fontColor={"#005DF1"}
                                                                  customBackgroundColor={"#EFF5FF"}
                                                                  fontWeight={400}
                                                                  fontSize={"16px"}
                                                                  size={"medium"}
                                                              />
                                                          ) : null
                                                      })
                                                    : null}
                                            </div>
                                        </div>

                                        <div style={{ width: "100%", flex: 5 }}>
                                            <p style={{ fontWeight: "600", fontSize: "16px", color: "#192252", padding: "10px 0", marginBottom: "10px" }}>Additional Services</p>

                                            <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                                                {summaryDetails.Services !== undefined
                                                    ? summaryDetails.Services.map((service) => {
                                                          return service.bitIsAdditional ? (
                                                              <Chip
                                                                  label={service?.varServiceItemName}
                                                                  shape="round"
                                                                  fontColor={"#005DF1"}
                                                                  customBackgroundColor={"#EFF5FF"}
                                                                  fontWeight={400}
                                                                  fontSize={"16px"}
                                                                  size={"medium"}
                                                              />
                                                          ) : null
                                                      })
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div style={{ flex: 1, overflowY: "scroll", margin: "0 0 20px 0", scrollbarGutter: "stable both-ends" }}>
                        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", margin: "50px 0", gap: "20px" }}>
                            <CircularProgress style={{ fontSize: "50px" }} />
                            <p style={{ fontWeight: "600", fontSize: "18px" }}>Loading Appointment...</p>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default InquiryTab
