import { Button, Chip, Modal } from "../components"
import { CloseButton } from "../CloseButton/CloseButton"
import { useEffect, useState } from "react"
import { axiosPrivate, buildHeaderObject } from "../../apis/axios"
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, IconButton, Tooltip } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { daysPassedOrInFuture, formatCash } from "../../utils/Validation"
import { shortDate } from "../../utils/DateFormatter"
import styles from "./Invoice.module.scss"
import { ExpandLess, ExpandMore, InfoOutlined, PaidOutlined, TuneOutlined } from "@mui/icons-material"
import { toast } from "react-toastify"
import Payment from "./Payment"
import useAuth from "../../hooks/useAuth"

function CustomerInvoicesBulk(props: { isOpenCustomerInvoicesBulkModal; setIsOpenCustomerInvoicesBulkModal: CallableFunction; invoiceList; isSuccess }) {
    const [InvList, setInvList] = useState<any>([])
    const [selectedList, setSelectedList] = useState<any>([])
    const [msg, setMsg] = useState<any>("")
    const [paymentItemList, setPaymentItemList] = useState<any>([])
    const [payCustomerID, setPayCustomerID] = useState(0)
    const [isOpenPaymentsModal, setIsOpenPaymentsModal] = useState(false)
    const [remaining, setRemaining] = useState(0)

    const { auth, setAuth } = useAuth() as any

    useEffect(() => {
        if (props.isOpenCustomerInvoicesBulkModal === true) {
            if (props.invoiceList !== undefined) {
                if (props.invoiceList.length > 0) {
                    getCustomerBulkInvoiceList(props.invoiceList)
                        .then((data) => {
                            console.log("res - Customer bulk Invoices", data)
                            setInvList(data)
                            handleCheckAll(data)
                            setPayCustomerID(data[0].numCustomerID)
                        })
                        .catch((error) => {
                            console.log("error", error)
                        })
                }
            }
        }
    }, [props.invoiceList, props.isOpenCustomerInvoicesBulkModal])

    const getCustomerBulkInvoiceList = async (data) => {
        console.log("bulk payload", data)
        const apiUrl = `Payment/GetBulkInvoiceDetails`
        try {
            const response = await axiosPrivate.post(apiUrl, data, buildHeaderObject(auth))
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    useEffect(() => {
        if (InvList !== undefined) {
            if (InvList > 0) {
                setMsg("")
            } else {
                setMsg("Loading...")
            }
        } else {
            setMsg("loading..")
        }
    }, [InvList])

    const checkIfNull = (value, type) => {
        if (value === null || value === "") {
            if (type === "bool") {
                return false
            } else {
                return ""
            }
        } else {
            return value
        }
    }

    const handleMainCheckboxToggle = (invoiceID: number) => {
        setSelectedList((prevSelectedList) => {
            const isSelected = prevSelectedList.some((selectedInvoice) => selectedInvoice.numInvoiceID === invoiceID)

            if (isSelected) {
                return prevSelectedList.filter((selectedInvoice) => selectedInvoice.numInvoiceID !== invoiceID)
            } else {
                const selectedInvoice = InvList.find((invoice) => invoice.numInvoiceID === invoiceID && invoice.charges !== undefined)
                if (selectedInvoice) {
                    const chargesWithRemaining = selectedInvoice.charges.filter((charge) => charge.remaining > 0)
                    return [...prevSelectedList, ...chargesWithRemaining]
                }
            }
            return prevSelectedList
        })
    }

    const handleCheckAll = (invoiceList) => {
        setSelectedList(() => {
            const selectedCharges: any = []

            invoiceList.forEach((invoice) => {
                if (invoice.charges) {
                    let chargesWithRemaining = invoice.charges.filter((charge) => charge.remaining > 0)
                    selectedCharges.push(...chargesWithRemaining)
                }
            })

            return selectedCharges
        })
    }

    const selectItem = (charge) => {
        const selectedCh = [...selectedList]
        const isIn = selectedCh.find((chargeItem) => chargeItem.numChargeID === charge.numChargeID)
        if (!isIn) {
            selectedCh?.push(charge)
            setSelectedList(selectedCh)
        } else {
            const afterRemove = [...selectedList]
            afterRemove.splice(isIn, 1)
            setSelectedList(afterRemove)
        }
    }
    useEffect(() => {
        console.log("selected", selectedList)
    }, [selectedList])

    function areAllChargesSelected(invoiceID) {
        const chargesForInvoice = InvList.find((invoice) => invoice.numInvoiceID === invoiceID)?.charges || []
        const chargesWithRemaining = chargesForInvoice.filter((charge) => charge.remaining > 0)
        const allChargesSelected = chargesWithRemaining.every((charge) => selectedList.some((selectedCharge) => selectedCharge.numChargeID === charge.numChargeID))
        return allChargesSelected
    }

    const makePayment = () => {
        if (selectedList.length > 0) {
            let selectedArr: any = []
            selectedList.map((item, index) => {
                selectedArr.push({
                    numChargeID: item.numChargeID,
                    remaining: item.remaining,
                    varTitle: item.numMainInvoiceID,
                    numInvoiceID: item.numInvoiceID,
                    type: "Tvp",
                    numOrderID: item.numOrderID,
                })
            })
            setPaymentItemList(selectedArr)
            console.log("sentToPayments - Bulk", selectedArr)
            setIsOpenPaymentsModal(true)
        } else {
            toast.info("Please select orders to proceed to payments")
        }
    }

    const handlePayment = (invoiceIndex, chargeIndex) => {
        setPaymentItemList([
            {
                numChargeID: InvList[invoiceIndex].charges[chargeIndex].numChargeID,
                varTitle: InvList[invoiceIndex].charges[chargeIndex].varServiceOrProductName,
                remaining: InvList[invoiceIndex].charges[chargeIndex].remaining,
                type: "Order",
                numInvoiceID: InvList[invoiceIndex].charges[chargeIndex].numInvoiceID,
                numOrderID: InvList[invoiceIndex].charges[chargeIndex].numOrderID,
            },
        ])
        console.log("sentToPayments - Order", {
            numChargeID: InvList[invoiceIndex].charges[chargeIndex].numChargeID,
            varTitle: InvList[invoiceIndex].charges[chargeIndex].varServiceOrProductName,
            remaining: InvList[invoiceIndex].charges[chargeIndex].remaining,
            type: "Order",
            numInvoiceID: InvList[invoiceIndex].charges[chargeIndex].numInvoiceID,
            numOrderID: InvList[invoiceIndex].charges[chargeIndex].numOrderID,
        })
        setIsOpenPaymentsModal(true)
    }

    useEffect(() => {
        if (selectedList.length > 0) {
            setRemaining(sumRemainingValues(selectedList))
        } else {
            setRemaining(0)
        }
    }, [selectedList])

    const sumRemainingValues = (data) => {
        let totalRemaining = 0

        for (let item of data) {
            if (item.hasOwnProperty("remaining")) {
                totalRemaining += item.remaining
            }
        }

        return totalRemaining
    }

    return (
        <Modal open={props.isOpenCustomerInvoicesBulkModal}>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px", width: "1200px" }}>
                <div
                    style={{
                        margin: "-15px -15px 0 -15px",
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#f3f4f6",
                        padding: "1rem 1rem 1rem 25px",
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                    }}
                >
                    <div>
                        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
                            <div style={{ color: "#002867", fontSize: "24px", fontWeight: "600" }}>View Invoice</div>
                        </div>
                    </div>
                    <div style={{ color: "#F9FAFB", borderRadius: "8px" }}>
                        <div style={{ height: "40px", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                            <CloseButton onClick={() => props.setIsOpenCustomerInvoicesBulkModal(false)} />
                        </div>
                    </div>
                </div>
                <div style={{ overflowY: "scroll", minHeight: "100px", maxHeight: "800px", width: "100%" }}>
                    {InvList === undefined ? (
                        <p style={{ color: "gray" }}>{msg}</p>
                    ) : InvList.length > 0 ? (
                        InvList.sort((a, b) => b.numInvoiceID - a.numInvoiceID).map((inv, indexInv) => {
                            return (
                                <Accordion style={{ margin: "0", padding: "0", width: "100%" }} key={indexInv}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{ backgroundColor: "#F9FAFB", margin: "0", padding: "0", width: "100%" }}
                                    >
                                        <div style={{ width: "100%", display: "flex", gap: "10px", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
                                                <div
                                                    style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#f5f5f5", borderRadius: "8px", padding: "5px" }}
                                                >
                                                    <p style={{ margin: "0", color: "#192252", fontSize: "16px", fontWeight: "600" }}>{inv.numMainInvoiceID}</p>
                                                    <p style={{ margin: "0", color: "#399ec7", fontSize: "14px", fontWeight: "600" }}>Invoice ID</p>
                                                </div>
                                                <div
                                                    style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#f5f5f5", borderRadius: "8px", padding: "5px" }}
                                                >
                                                    <p style={{ margin: "0", color: "#192252", fontSize: "16px", fontWeight: "600" }}>${formatCash(inv.numInvoiceTotalCharges)}</p>
                                                    <p style={{ margin: "0", color: "#192252", fontSize: "14px", fontWeight: "500" }}>Total Charges</p>
                                                </div>
                                                <div
                                                    style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#e8ffed", borderRadius: "8px", padding: "5px" }}
                                                >
                                                    <p style={{ margin: "0", color: "#192252", fontSize: "16px", fontWeight: "600" }}>${formatCash(inv.numInvoiceTotalPayments)}</p>
                                                    <p style={{ margin: "0", color: "#192252", fontSize: "14px", fontWeight: "500" }}>Payments</p>
                                                </div>
                                                <div
                                                    style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#fff4f4", borderRadius: "8px", padding: "5px" }}
                                                >
                                                    <p style={{ margin: "0", color: "#192252", fontSize: "16px", fontWeight: "600" }}>${formatCash(inv.numInvoiceTotalRemaining)}</p>
                                                    <p style={{ margin: "0", color: "#192252", fontSize: "14px", fontWeight: "500" }}>Remaining</p>
                                                </div>
                                            </div>
                                            <div style={{ border: "1px solid #d5d5d5", width: "fit-content", borderRadius: "8px", cursor: "pointer", display: "flex", alignItems: "center" }}>
                                                <Checkbox
                                                    key={inv.numInvoiceID}
                                                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                                                    onClick={(event) => {
                                                        event.stopPropagation()
                                                        handleMainCheckboxToggle(inv.numInvoiceID)
                                                    }}
                                                    checked={areAllChargesSelected(inv.numInvoiceID)}
                                                />
                                            </div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div style={{ width: "97%", gap: "3rem", padding: "10px", borderRadius: "8px", backgroundColor: "#FFFFFF" }}>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div>
                                                    <p style={{ fontSize: "16px" }}>
                                                        <span style={{ fontWeight: "600" }}>Billing Address: </span>
                                                        {checkIfNull(inv.varBillToAddress1, "string") +
                                                            " " +
                                                            checkIfNull(inv.varBillToAddress2, "string") +
                                                            " " +
                                                            checkIfNull(inv.varBillToCity, "string") +
                                                            " " +
                                                            checkIfNull(inv.varBillToState, "string") +
                                                            " " +
                                                            checkIfNull(inv.varBillToZip, "string")}
                                                    </p>
                                                    <Chip
                                                        size="small"
                                                        customBackgroundColor={"#399ec7"}
                                                        customBorder={"#399ec7"}
                                                        customBorderRadius={"16px"}
                                                        fontColor={"#ffffff"}
                                                        label={inv.varLocationName}
                                                    />
                                                </div>
                                                <div>
                                                    <p style={{ fontSize: "16px" }}>
                                                        <span style={{ fontWeight: "600" }}>Date:</span>{" "}
                                                        {shortDate(inv.dtInvoiceCreatedDate) + " (" + daysPassedOrInFuture(inv.dtInvoiceCreatedDate) + ")"}
                                                    </p>
                                                    <p style={{ fontSize: "16px" }}>
                                                        <span style={{ fontWeight: "600" }}>Date Due:</span> {shortDate(inv.dtInvoiceDueDate) + " (" + daysPassedOrInFuture(inv.dtInvoiceDueDate) + ")"}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ marginRight: "16px" }}>
                                            <table className={styles.invTable} style={{ width: "100%", paddingRight: "14px" }}>
                                                <tr>
                                                    <th style={{ whiteSpace: "nowrap" }}>
                                                        <div style={{ display: "flex", flexDirection: "column", margin: "0", alignItems: "center", marginRight: "43px" }}></div>
                                                    </th>
                                                    <th style={{ whiteSpace: "nowrap" }}>Order ID</th>
                                                    <th style={{ width: "100%", textAlign: "left", marginRight: "100px" }}>Description</th>
                                                    <th style={{ whiteSpace: "nowrap" }}>Unit Chrg</th>
                                                    <th style={{ whiteSpace: "nowrap" }}>Qty.</th>
                                                    <th style={{ whiteSpace: "nowrap" }}>Charge</th>
                                                    <th style={{ whiteSpace: "nowrap" }}>Adjustments</th>
                                                    <th style={{ whiteSpace: "nowrap" }}>Tax</th>
                                                    <th style={{ whiteSpace: "nowrap" }}>Payments</th>
                                                    <th style={{ whiteSpace: "nowrap" }}>Balance</th>
                                                    <th style={{ whiteSpace: "nowrap" }}>
                                                        <p style={{ width: "65px" }}>
                                                            <strong>Action</strong>
                                                        </p>
                                                    </th>
                                                </tr>
                                                <tr style={{ visibility: "collapse" }}>
                                                    <th style={{ whiteSpace: "nowrap" }}></th>
                                                    <th></th>
                                                    <th style={{ width: "100%", textAlign: "left" }}></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th>
                                                        <Button color="info" variant="outlined" text="PAY"></Button>
                                                    </th>
                                                </tr>
                                            </table>
                                        </div>

                                        <div style={{ overflowY: "scroll", minHeight: "100px", maxHeight: "400px" }}>
                                            <table className={styles.invTable} style={{ width: "100%" }}>
                                                <tr style={{ visibility: "collapse" }}>
                                                    <th style={{ whiteSpace: "nowrap" }}>Select</th>
                                                    <th style={{ whiteSpace: "nowrap" }}>Order ID</th>
                                                    <th style={{ width: "100%", textAlign: "left" }}>Description</th>
                                                    <th style={{ whiteSpace: "nowrap" }}>Unit Chrg</th>
                                                    <th style={{ whiteSpace: "nowrap" }}>Qty.</th>
                                                    <th style={{ whiteSpace: "nowrap" }}>Charge</th>
                                                    <th style={{ whiteSpace: "nowrap" }}>Adjustments</th>
                                                    <th>Tax</th>
                                                    <th>Payments</th>
                                                    <th>Balance</th>
                                                    <th>
                                                        <Button color="info" variant="outlined" text="PAY"></Button>
                                                    </th>
                                                </tr>
                                                {inv.charges.map((charge, index) => {
                                                    let isIn = selectedList.some((selectedCharge) => selectedCharge.numChargeID === charge.numChargeID)

                                                    return (
                                                        <>
                                                            <tr key={index} className={styles.mainRow} style={{ borderBottom: "2px solid #dbdbdb" }}>
                                                                <td>
                                                                    <Checkbox
                                                                        disabled={parseFloat(charge.remaining) > 0 ? false : true}
                                                                        checked={isIn}
                                                                        onChange={() => {
                                                                            selectItem(charge)
                                                                        }}
                                                                    ></Checkbox>
                                                                </td>
                                                                <td>
                                                                    {charge.adjustments.length > 0 || charge.payments.length > 0 ? (
                                                                        <IconButton
                                                                            onClick={() => {
                                                                                let collapseCharges = [...InvList]
                                                                                collapseCharges[indexInv].charges[index].collapsed = !collapseCharges[indexInv].charges[index].collapsed
                                                                                setInvList(collapseCharges)
                                                                            }}
                                                                            style={{ padding: "0 2px 2px 1px" }}
                                                                        >
                                                                            {InvList[indexInv].charges[index].collapsed ? (
                                                                                <ExpandLess style={{ fontSize: "15px" }}></ExpandLess>
                                                                            ) : (
                                                                                <ExpandMore style={{ fontSize: "15px" }}></ExpandMore>
                                                                            )}
                                                                        </IconButton>
                                                                    ) : null}

                                                                    <strong>{charge.numOrderID}</strong>
                                                                </td>
                                                                <td style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                    <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                                                                        <div
                                                                            style={
                                                                                charge.varServiceOrProduct === "S" ? { color: "#5178bb", fontWeight: "600" } : { color: "#88bb51", fontWeight: "600" }
                                                                            }
                                                                        >
                                                                            ({charge.varServiceOrProduct})
                                                                        </div>
                                                                        <p>
                                                                            {charge.varServiceOrProductName}{" "}
                                                                            {charge.numPetID === -1 ? null : charge.numPetID === null ? null : (
                                                                                <span>
                                                                                    Pet: (<span>{charge.varPetName}</span>)
                                                                                </span>
                                                                            )}
                                                                            {charge.numAppointmentID !== null && charge.numAppointmentID !== 0 ? (
                                                                                <>
                                                                                    <span style={{ fontWeight: "500" }}>{" App:" + charge.numAppointmentID}</span>
                                                                                </>
                                                                            ) : null}
                                                                        </p>
                                                                    </div>
                                                                    <Tooltip
                                                                        title={
                                                                            <>
                                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>{charge.varServiceOrProduct} - Description</p>
                                                                                <p style={{ fontSize: "12px", color: "#fff" }}>{charge.varServiceProductDescription}</p>
                                                                            </>
                                                                        }
                                                                    >
                                                                        <InfoOutlined style={{ fontSize: "17px", color: "#979797" }} />
                                                                    </Tooltip>
                                                                </td>
                                                                <td>${charge.decChargeAmount}</td>
                                                                <td style={{ textAlign: "center" }}>{charge.numQuantity}</td>
                                                                <td>${charge.decChargeAmt}</td>
                                                                <td>(${charge.totalAdjustments})</td>
                                                                <td>${charge.decTaxAmt}</td>
                                                                <td>(${charge.totalPayments})</td>
                                                                <td>${parseFloat(charge.remaining).toFixed(2)}</td>
                                                                <td>
                                                                    <Button
                                                                        disabled={parseFloat(charge.remaining) > 0 ? false : true}
                                                                        color="info"
                                                                        variant="outlined"
                                                                        text="PAY"
                                                                        onClick={() => {
                                                                            handlePayment(indexInv, index)
                                                                        }}
                                                                    ></Button>
                                                                </td>
                                                            </tr>
                                                            {charge.adjustments.length > 0
                                                                ? charge.adjustments.map((adj, adjIndex) => {
                                                                      return (
                                                                          <tr
                                                                              style={{ backgroundColor: "#f7dddd73" }}
                                                                              className={InvList[indexInv].charges[index].collapsed ? undefined : styles.rowhidden}
                                                                          >
                                                                              <td></td>
                                                                              <td></td>
                                                                              <td>
                                                                                  <div style={{ display: "flex", alignItems: "center", paddingLeft: "15px", gap: "5px" }}>
                                                                                      <TuneOutlined style={{ color: "orange", fontSize: "18px" }} />
                                                                                      <p style={{ fontSize: "12px", margin: "0" }}>{adj.varPromotionDescription}</p>
                                                                                  </div>
                                                                              </td>
                                                                              <td></td>
                                                                              <td></td>
                                                                              <td></td>
                                                                              <td>
                                                                                  <p style={{ paddingLeft: "15px", margin: "0" }}>${adj.decAdjustmentAmount}</p>
                                                                              </td>
                                                                              <td></td>
                                                                              <td></td>
                                                                              <td></td>
                                                                              <td></td>
                                                                          </tr>
                                                                      )
                                                                  })
                                                                : null}

                                                            {charge.payments.length > 0
                                                                ? charge.payments.map((pay, adjIndex) => {
                                                                      return (
                                                                          <tr
                                                                              style={{ backgroundColor: "#ddf7ec73" }}
                                                                              className={InvList[indexInv].charges[index].collapsed ? undefined : styles.rowhidden}
                                                                          >
                                                                              <td></td>
                                                                              <td></td>
                                                                              <td>
                                                                                  <div style={{ display: "flex", alignItems: "center", paddingLeft: "15px", gap: "5px" }}>
                                                                                      <PaidOutlined style={{ color: "green", fontSize: "18px" }} />
                                                                                      <p style={{ fontSize: "12px", margin: "0" }}>{pay.varPmtReasonCodeDescription1}</p>
                                                                                  </div>
                                                                              </td>
                                                                              <td></td>
                                                                              <td></td>
                                                                              <td></td>
                                                                              <td></td>
                                                                              <td></td>
                                                                              <td>
                                                                                  <p style={{ paddingLeft: "15px", margin: "0" }}>${pay.decPaymentAmount}</p>
                                                                              </td>
                                                                              <td></td>
                                                                              <td></td>
                                                                          </tr>
                                                                      )
                                                                  })
                                                                : null}
                                                        </>
                                                    )
                                                })}
                                            </table>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })
                    ) : (
                        <p style={{ color: "gray" }}>{msg}</p>
                    )}
                </div>

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        {remaining > 0 ? (
                            <p style={{ fontWeight: "600", color: "#4f4f58", fontSize: "15px", backgroundColor: "aliceblue", padding: "4px", borderRadius: "9px" }}>
                                Selected remaining: ${formatCash(remaining)}
                            </p>
                        ) : null}
                    </div>

                    <div style={{ display: "flex", justifyContent: "flex-end", margin: "0 20px 10px 0", gap: "10px" }}>
                        <Button color="default" variant="text" disabled={false} onClick={() => setSelectedList([])}>
                            Reset
                        </Button>
                        <Button color="primary" variant="contained" disabled={false} onClick={() => makePayment()}>
                            Make Payment ({selectedList.length})
                        </Button>
                        <Payment
                            customerID={payCustomerID}
                            data={paymentItemList}
                            isOpenPaymentsModal={isOpenPaymentsModal}
                            setIsOpenPaymentsModal={(value) => setIsOpenPaymentsModal(value)}
                            clearSelected={(value) => {
                                if (value === true) {
                                    setSelectedList([])
                                }
                            }}
                            onSuccess={(value) => {
                                if (value === true) {
                                    setSelectedList([])
                                    props.setIsOpenCustomerInvoicesBulkModal(false)
                                    props.isSuccess(true)
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default CustomerInvoicesBulk
