import { useEffect, useState } from "react"
import { Modal } from "../components"
import { CloseButton } from "../../components"
import { ImgComparisonSlider } from "@img-comparison-slider/react"
import { axiosPrivate, buildHeaderObject } from "../../apis/axios"
import { shortDate } from "../../utils/DateFormatter"
import { Edit, Verified } from "@mui/icons-material"
import { Button, Tab, Tabs } from "@mui/material"
import useAuth from "../../hooks/useAuth"

function ViewGroomerNote(props: { isOpenViewModal; setIsOpenViewModal: CallableFunction; notes?; mode?; user? }) {
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState({}) as any

    const { auth, setAuth } = useAuth() as any

    const getGroomerNoteByID = async (id) => {
        const apiUrl = `Appointment/GetGroomerNotesByNoteID`

        try {
            const response = await axiosPrivate.get(
                apiUrl,
                buildHeaderObject(auth, false, false, false, {
                    NoteID: id,
                })
            )
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    useEffect(() => {
        if (props.isOpenViewModal === true) {
            getGroomerNoteByID(props?.notes[0]?.numGroomerNoteID)
                .then((data) => {
                    console.log("note", data)
                    setData(data)
                    setIsLoading(false)
                })
                .catch((error) => {
                    console.log("error", error)
                })
        }
    }, [props.isOpenViewModal])

    const sectionTitleStyle = {
        fontSize: "18px",
        fontWeight: "600",
        color: "#3A3A3A",
        marginBottom: "10px",
        borderBottom: "2px solid #E0E0E0",
        paddingBottom: "5px",
    }

    const infoLabelStyle = {
        fontWeight: "bold",
        color: "#192252",
    }

    const infoTextStyle = {
        color: "#555555",
        marginBottom: "5px",
    }

    const [tabValue, setTabValue] = useState(0)

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue)
    }

    const isPreferredImagesAvailable = data?.prefferedCutBeforeImgPath && data?.prefferedCutAfterImagePath
    const isCurrentImagesAvailable = data?.beforeImgPath && data?.afterImagePath
    return (
        <Modal open={props.isOpenViewModal} backgroundColor={"#F9FAFB"}>
            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                    <h2 style={{ fontSize: "24px", fontWeight: "700", color: "#192252", textAlign: "center", margin: "0px" }}>Groomer Note</h2>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <CloseButton onClick={() => props.setIsOpenViewModal(false)} />
                    </div>
                </div>

                {isLoading ? (
                    <p style={{ color: "gray" }}>Loading...</p>
                ) : (
                    <>
                        {/* {
            (props.mode === "groomerView")?
                <ImgComparisonSlider style={{ width: '30%', height: '300px' }}>
                <img
                    style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                    alt="404"
                    slot="first"
                    src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${data?.prefferedCutBeforeImgPath}`}
                />
                <img
                    style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                    alt="404"
                    slot="second"
                    src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${data?.prefferedCutAfterImagePath}`}
                />
            </ImgComparisonSlider> :
            <ImgComparisonSlider style={{ width: '30%', height: '300px' }}>
            <img
                style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                alt="404"
                slot="first"
                src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${data?.beforeImgPath}`}
            />
            <img
                style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                alt="404"
                slot="second"
                src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${data?.afterImagePath}`}
            />
        </ImgComparisonSlider>
          } */}

                        <>
                            {props.mode === "groomerView" ? (
                                // Check if both pairs of images are not available, show "Images have not been uploaded"
                                !isPreferredImagesAvailable && !isCurrentImagesAvailable ? (
                                    <p>Images have not been uploaded.</p>
                                ) : // If both image pairs are available, show tabs
                                isPreferredImagesAvailable && isCurrentImagesAvailable ? (
                                    <>
                                        <Tabs value={tabValue} onChange={handleTabChange}>
                                            <Tab label="Preferred" />
                                            <Tab label="Current" />
                                        </Tabs>
                                        {tabValue === 0 && (
                                            <ImgComparisonSlider style={{ width: "30%", height: "300px" }}>
                                                <img
                                                    style={{ height: "100%", width: "100%", objectFit: "cover" }}
                                                    alt="404"
                                                    slot="first"
                                                    src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${data?.prefferedCutBeforeImgPath}`}
                                                />
                                                <img
                                                    style={{ height: "100%", width: "100%", objectFit: "cover" }}
                                                    alt="404"
                                                    slot="second"
                                                    src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${data?.prefferedCutAfterImagePath}`}
                                                />
                                            </ImgComparisonSlider>
                                        )}
                                        {tabValue === 1 && (
                                            <ImgComparisonSlider style={{ width: "30%", height: "300px" }}>
                                                <img
                                                    style={{ height: "100%", width: "100%", objectFit: "cover" }}
                                                    alt="404"
                                                    slot="first"
                                                    src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${data?.beforeImgPath}`}
                                                />
                                                <img
                                                    style={{ height: "100%", width: "100%", objectFit: "cover" }}
                                                    alt="404"
                                                    slot="second"
                                                    src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${data?.afterImagePath}`}
                                                />
                                            </ImgComparisonSlider>
                                        )}
                                    </>
                                ) : (
                                    // If customer preferred is false or only current images are available
                                    <>
                                        <ImgComparisonSlider style={{ width: "30%", height: "300px" }}>
                                            <img
                                                style={{ height: "100%", width: "100%", objectFit: "cover" }}
                                                alt="404"
                                                slot="first"
                                                src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${isPreferredImagesAvailable ? data?.prefferedCutBeforeImgPath : data?.beforeImgPath}`}
                                            />
                                            <img
                                                style={{ height: "100%", width: "100%", objectFit: "cover" }}
                                                alt="404"
                                                slot="second"
                                                src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${
                                                    isPreferredImagesAvailable ? data?.prefferedCutAfterImagePath : data?.afterImagePath
                                                }`}
                                            />
                                        </ImgComparisonSlider>
                                    </>
                                )
                            ) : (
                                // Non-groomer view logic (just show before/after images)
                                <ImgComparisonSlider style={{ width: "30%", height: "300px" }}>
                                    <img
                                        style={{ height: "100%", width: "100%", objectFit: "cover" }}
                                        alt="404"
                                        slot="first"
                                        src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${data?.beforeImgPath}`}
                                    />
                                    <img
                                        style={{ height: "100%", width: "100%", objectFit: "cover" }}
                                        alt="404"
                                        slot="second"
                                        src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${data?.afterImagePath}`}
                                    />
                                </ImgComparisonSlider>
                            )}
                        </>

                        {data?.isCutomerPreffered ? (
                            <div style={{ display: "flex", gap: "5px", alignItems: "center", backgroundColor: "#ffffdd", padding: "0px 10px", borderRadius: "8px" }}>
                                <Verified style={{ color: "#edb80b" }} />
                                <p style={{ color: "#d3a71a", fontWeight: "600", margin: "0" }}>Customer's Pick</p>
                            </div>
                        ) : null}

                        <div style={{ backgroundColor: "#F9FAFB", padding: "20px", borderRadius: "8px", boxShadow: "0 4px 12px rgba(0,0,0,0.1)" }}>
                            <div style={{ display: "flex", flexDirection: "column", gap: "20px", maxHeight: "400px", overflowY: "scroll" }}>
                                <div>
                                    <h3 style={sectionTitleStyle}>Location Information</h3>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <p style={infoLabelStyle}>Location:</p> <p style={infoTextStyle}>{data?.varLocationName}</p>
                                        <p style={infoLabelStyle}>Phone:</p> <p style={infoTextStyle}>{data?.varLocationPhone}</p>
                                        <p style={infoLabelStyle}>Email:</p> <p style={infoTextStyle}>{data?.varLocationEmail}</p>
                                        <p style={infoLabelStyle}>Address:</p>{" "}
                                        <p style={infoTextStyle}>
                                            {data?.varLocationAddress1}, {data?.varLocationCity}, {data?.varLocationState} {data?.varLocationZip}
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <h3 style={sectionTitleStyle}>Groomer Information</h3>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <p style={infoLabelStyle}>Name:</p>{" "}
                                        <p style={infoTextStyle}>
                                            {data?.varCreatedEmployeeFirstName} {data?.varCreatedEmployeeLastName}
                                        </p>
                                        <p style={infoLabelStyle}>Groomer's Comment:</p> <p style={infoTextStyle}>{data?.varGroomerComment}</p>
                                        <p style={infoLabelStyle}>Added Date:</p> <p style={infoTextStyle}>{shortDate(data?.dtCreatedDate)}</p>
                                    </div>
                                </div>

                                <div>
                                    <h3 style={sectionTitleStyle}>Service Information</h3>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <p style={infoLabelStyle}>Service Type:</p> <p style={infoTextStyle}>{data?.varServiceType}</p>
                                        <p style={infoLabelStyle}>First Groom:</p> <p style={infoTextStyle}>{data?.bitFirstGroom ? "Yes" : "No"}</p>
                                        <p style={infoLabelStyle}>Puppy Cut:</p> <p style={infoTextStyle}>{data?.bitFullGroomPuppyCut ? "Yes" : "No"}</p>
                                        <p style={infoLabelStyle}>Breed Standard Cut:</p> <p style={infoTextStyle}>{data?.bitFullGoomBreedStandardCut ? "Yes" : "No"}</p>
                                        <p style={infoLabelStyle}>Hand Strip:</p> <p style={infoTextStyle}>{data?.bitFullGroomHandStrip ? "Yes" : "No"}</p>
                                        <p style={infoLabelStyle}>Shave Down:</p> <p style={infoTextStyle}>{data?.bitFullGroomShaveDown ? "Yes" : "No"}</p>
                                        <p style={infoLabelStyle}>Difficult Drying:</p> <p style={infoTextStyle}>{data?.bitDifficultDrying ? "Yes" : "No"}</p>
                                    </div>
                                </div>

                                <div>
                                    <h3 style={sectionTitleStyle}>Recommended Shampoo</h3>
                                    <p style={infoTextStyle}>{data?.varShampooName}</p>
                                </div>

                                {data?.blades && (
                                    <div>
                                        <h3 style={sectionTitleStyle}>Blades Used</h3>
                                        {Object.entries<any>(data.blades).map(([key, blade]) => (
                                            <div key={key} style={{ marginBottom: "10px" }}>
                                                <p style={infoLabelStyle}>{key.replace(/Blade$/, "")}:</p>
                                                <p style={infoTextStyle}>Blade Name: {blade?.varBladeName}</p>
                                                <p style={infoTextStyle}>Description: {blade?.varBladeDescription}</p>
                                            </div>
                                        ))}
                                    </div>
                                )}

                                {data?.combs && (
                                    <div>
                                        <h3 style={sectionTitleStyle}>Combs Used</h3>
                                        {Object.entries<any>(data.combs).map(([key, comb]) => (
                                            <div key={key} style={{ marginBottom: "10px" }}>
                                                <p style={infoLabelStyle}>{key.replace(/Comb$/, "")}:</p>
                                                <p style={infoTextStyle}>Comb Name: {comb?.varCombName}</p>
                                                <p style={infoTextStyle}>Description: {comb?.varCombDescription}</p>
                                            </div>
                                        ))}
                                    </div>
                                )}

                                {data?.styles && (
                                    <div>
                                        <h3 style={sectionTitleStyle}>Styles Used</h3>
                                        {Object.entries<any>(data.styles).map(([key, style]) => (
                                            <div key={key} style={{ marginBottom: "10px" }}>
                                                <p style={infoLabelStyle}>{key.replace(/Style$/, "")}:</p>
                                                <p style={infoTextStyle}>Style Name: {style?.varStyleName}</p>
                                                <p style={infoTextStyle}>Description: {style?.varStyleDescription}</p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    )
}

export default ViewGroomerNote
