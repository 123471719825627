import { Add, Search, AddOutlined } from "@mui/icons-material"
import { IconButton, MenuItem, TextareaAutosize, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { CloseButton } from "../CloseButton/CloseButton"
import { Button, Modal, Input } from "../components"
import styles from "./AdvancedInput.module.scss"
import { axiosPrivate, buildHeaderObject } from "../../apis/axios"
import { toast } from "react-toastify"
import useAuth from "../../hooks/useAuth"

export interface AppInputItemValue {
    value: string
    text: any
}

export const AdvancedInput = (props: {
    onChange?: CallableFunction
    onChangeValue?: CallableFunction
    onFocus?: CallableFunction
    onBlur?: CallableFunction
    autoFocus?: boolean
    disabled?: boolean
    value?: string
    isButton?
    onAddClick?
    children?
    label?
    required?
    size?
    variant?
    inputClassName?
    ref?
    itemSize?
    ObjectName
    isCustom
    Sequence?
    selectedValue?
    inputProps?
    customID?
    customName?
    idCustomFields?: boolean
}) => {
    const [showAddModal, setShowAddModal] = useState(false)
    const [newItem, setNewItem] = useState("")
    const [newItemDesc, setNewItemDesc] = useState("")
    const [data, setData] = useState<any>([])
    let objName = props?.ObjectName.charAt(0).toLowerCase() + props?.ObjectName.slice(1)
    const [selected, setSelected] = useState(props.selectedValue)

    useEffect(() => {
        fetchData()
    }, [])

    const { auth } = useAuth() as any

    let apiUrl = ""
    if (props.isCustom == true) {
        apiUrl = `CustomList/GetByTableID/${props.ObjectName}/${props.Sequence}`
    } else {
        apiUrl = `${props.ObjectName}/GetAll`
    }

    async function getData() {
        const result = await axiosPrivate.get(apiUrl, buildHeaderObject(auth))
        return result.data
    }

    useEffect(() => {
        // Update the 'selected' state whenever 'props.selectedValue' changes
        setSelected(props.selectedValue)
    }, [props.selectedValue])

    const fetchData = () => {
        getData()
            .then((res) => {
                const newItem = [data]
                res[props.isCustom ? "customList" : objName].map((item) => {
                    let obj = {}
                    obj["value"] = item[props.isCustom ? "numCustomListID" : props.idCustomFields ? props.customID : `num${props.ObjectName}ID`]
                    obj["text"] = item[props.isCustom ? "varCustomValueName" : props.idCustomFields ? props.customName : `var${props.ObjectName}Name`]
                    newItem.push(obj)
                })
                setData(newItem)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleSubmit = () => {
        if (props.isCustom == true) {
            const obj = {}
            const uploadObjectName = "customList"
            const objMain: any = { [uploadObjectName]: {} }
            obj["varCustomValueName"] = newItem
            obj[`varCustomValueDescription`] = newItemDesc
            obj[`varTableName`] = props.ObjectName
            obj[`numListSequence`] = props?.Sequence
            obj[`bitActive`] = true
            objMain[uploadObjectName] = obj
            SubmitData(objMain)
        } else {
            const obj = {}
            const uploadObjectName = `${objName}`
            const objMain: any = { [uploadObjectName]: {} }
            obj[`var${props.ObjectName}Name`] = newItem
            obj[`var${props.ObjectName}Description`] = newItemDesc
            objMain[uploadObjectName] = obj
            SubmitData(objMain)
        }
    }

    async function SubmitData(data) {
        console.log(data)
        let postUrl = ""
        if (props.isCustom == true) {
            postUrl = "CustomList"
        } else {
            postUrl = `${props.ObjectName}`
        }
        await axiosPrivate
            .post(postUrl, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                if (result.status == 200) {
                    setData([])
                    toast.success(`New ${props.isCustom ? "custom list " : props.ObjectName.toLowerCase()} saved!`)
                    setShowAddModal(false)
                    setNewItem("")
                    setNewItemDesc("")
                    fetchData()
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <>
            <div>
                <div
                    style={
                        props.isButton
                            ? { display: "flex", alignItems: "center", justifyContent: "space-between", gap: "1rem", backgroundColor: "#F9FAFB", paddingRight: "1rem" }
                            : { display: "flex", alignItems: "center", justifyContent: "space-between", backgroundColor: "#F9FAFB" }
                    }
                >
                    <div style={{ display: "flex", alignItems: "center", gap: "0.3rem !important", width: "100%" }}>
                        <TextField
                            inputProps={props.inputProps}
                            variant={props.variant != undefined ? props.variant : "filled"}
                            label={
                                <div style={{ display: "flex", fontSize: "14px" }}>
                                    {props.label}
                                    {props.required ? (
                                        <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>Required</span>
                                    ) : null}
                                </div>
                            }
                            select={!!data}
                            fullWidth
                            size={props.size}
                            disabled={props.disabled}
                            onChange={(e) => {
                                props.onChange?.(e.target.value)
                                data.some((value) => {
                                    if (value.value === e.target.value) {
                                        props.onChangeValue?.({
                                            value: value.value,
                                            text: value.text,
                                        })
                                    }
                                })

                                setSelected(e.target.value)
                            }}
                            autoComplete={"on"}
                            ref={props.ref}
                            value={selected}
                            required={props.required}
                        >
                            {data?.map((item, i) => {
                                return (
                                    <MenuItem key={i} value={item.value} style={{ fontSize: props.itemSize == undefined ? `medium` : `${props.itemSize}px` }}>
                                        {item.text}
                                    </MenuItem>
                                )
                            })}
                        </TextField>
                    </div>
                    <div>
                        {props.isButton ? (
                            <IconButton
                                onClick={() => {
                                    setShowAddModal(true)
                                }}
                                style={{ background: "#FFFF", borderRadius: "4px", border: "1px solid #005DF1", paddingLeft: "10px" }}
                            >
                                <Add fontSize="small" style={{ color: "#005DF1" }} />
                            </IconButton>
                        ) : null}
                    </div>
                </div>
            </div>
            {showAddModal ? (
                <Modal open={showAddModal}>
                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem", padding: ".5rem 1.5rem" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                            <p style={{ color: "#002867", fontSize: "18px", fontWeight: "600" }}>Add new {props.isCustom ? "custom list " : props.ObjectName.toLowerCase()}</p>
                            <CloseButton onClick={() => setShowAddModal(false)} />
                        </div>
                        <div style={{ backgroundColor: "#fff", width: "400px" }}>
                            <div style={{ display: "flex", gap: "1.5rem", flexDirection: "column", padding: "0 0 .8rem 0" }}>
                                <Input
                                    variant={"filled"}
                                    label={"Enter the new value here"}
                                    value={newItem}
                                    onChange={(e) => {
                                        setNewItem(e)
                                    }}
                                />
                                <TextField
                                    multiline={true}
                                    placeholder="Enter Description here"
                                    style={{ width: "100%" }}
                                    onChange={(e) => {
                                        setNewItemDesc(e.target.value)
                                    }}
                                    variant={"filled"}
                                />
                            </div>
                        </div>
                        <div style={{ textAlign: "end", width: "100%" }}>
                            <Button variant="outlined" onClick={handleSubmit} color="primary" className={styles.buttonText}>
                                Create New
                            </Button>
                        </div>
                    </div>
                </Modal>
            ) : null}
        </>
    )
}
