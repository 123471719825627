import styles from "../PersonalDetails.module.scss"
import { Checkbox, FormControlLabel, IconButton, MenuItem, Radio, RadioGroup, TextField } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import Switch from "@mui/material/Switch"
import { Button, Avatar, Modal, Input } from "../../../../components"
import { AdvancedInput } from "../../../../AdvancedInput/AdvancedInput"
import { toast } from "react-toastify"

import Cropper from "react-cropper"
import "cropperjs/dist/cropper.css"
import imageCompression from "browser-image-compression"
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3"
import { FileUploader } from "react-drag-drop-files"
import { FileUploadOutlined, Warning, Add } from "@mui/icons-material"
import { CloseButton } from "../../../../CloseButton/CloseButton"
import { axiosPrivate, buildHeaderObject } from "../../../../../apis/axios"
import { shortDate } from "../../../../../utils/DateFormatter"
import placeholderImage from "../../../../../images/pet_placeholder.png"
import { generateYearAge } from "../../../../../utils/GenerateJoinedDate"
import { checkPermission, isActive } from "../../../../../utils/permissions"

import useAuth from "../../../../../hooks/useAuth"

const EditPetProfile = (props: { petProfileTemplate; data }) => {
    const { auth } = useAuth() as any
    const [editData, setEditData] = useState({
        pet: props?.data,
    })

    useEffect(() => {
        setEditData({
            pet: props?.data,
        })
        resetImage()
    }, [props.data])

    const fileTypes = ["JPEG", "jpg", "WEBP"]
    const [loading, setLoading] = useState(false)
    const [addPersonality, setAddPersonality] = useState(false)
    const [fileSelected, setFileSelected] = useState(false)
    const [importedData, setImportedData] = useState<any>("")
    const [showCropper, setShowCropper] = useState(false)
    const cropperRef = useRef<HTMLImageElement>(null)
    const [cropper, setCropper] = useState<any>()
    const [imageName, setImageName] = useState<any>("")

    const [personalityList, setPersonalityList] = useState([] as any)

    const [customerLoading, setCustomerLoading] = useState(false)

    const [petIsMix, setPetIsMix] = useState(editData.pet.varPetBreedType == "MIX" ? true : false)
    const [MixBreedData, setMixBreedData] = useState([] as any)
    const [PureBreedData, setPureBreedData] = useState([] as any)
    const [petDescription, setPetDescription] = useState("")

    const [PersonalityName, setPersonalityName] = useState("")
    const [PersonalityWarning, setPersonalityWarning] = useState("")

    const [primaryBreed, setPrimaryBreed] = useState(editData.pet?.varPrimaryBreed)
    const [secondaryBreed, setSecondaryBreed] = useState(editData.pet?.varSecondaryBreed)

    const [breedMainData, setBreedMainData] = useState([] as any)
    const [noBreedData, setNoBreedData] = useState(false)

    const [addNewMixBreed, setAddNewMixBreed] = useState(false)
    const [addNewPureBreed, setAddNewPureBreed] = useState(false)

    const [newMixBreedName, setNewMixBreedName] = useState("")
    const [newMixBreedDesc, setNewMixBreedDesc] = useState("")

    const [newPureBreedName, setNewPureBreedName] = useState("")
    const [newPureBreedDesc, setNewPureBreedDesc] = useState("")

    const [newMixName, setNewMixName] = useState("")
    const [newSecondName, setSecondName] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [newMissingBreed, setNewMissingBreed] = useState({
        bitActive: true,
        numCreatedBy: 0,
        varCreatedBy: "",
        dtCreatedDate: "2022-10-26T10:41:38.949Z",
        numEditedBy: 0,
        varEditedBy: "",
        dtEditedDate: "2022-10-26T10:41:38.949Z",
        numDeletedBy: 0,
        varDeletedBy: "",
        dtDeletedDate: "2022-10-26T10:41:38.949Z",
        numPetBreedID: 0,
        varPetBreedType: "MIX",
        varPetBreedName: "",
        varPetBreedDescription: "",
        numPrimaryPetBreedID: 0,
        varPrimaryPetBreed: "",
        numSecondaryPetBreedID: 0,
        varSecondaryPetBreed: "",
        numPetTypeID: 0,
        varPetTypeName: "string",
    })

    async function saveData() {
        let personList = [] as any

        personalityList.map((p) => {
            if (p.bitActive) {
                personList.push(p?.numPersonalityID)
            }
        })

        const payload = {
            pet: editData.pet,
            petPersonality: personList,
        }

        console.log("res-sent", payload)

        const result = await axiosPrivate.put("Pet", JSON.stringify(payload), buildHeaderObject(auth))
        return result
    }

    const saveChanges = () => {
        setCustomerLoading(true)

        var Form: any = document.getElementById("editForm")

        if (Form.checkValidity() == false) {
            console.log("invalid")
            setCustomerLoading(false)
        } else {
            const newValue = { ...editData }

            let nObj = {
                varPetVetName: vetName,
                varPetVetPhone1: vetWork,
                varPetVetPhone2: vetMobile,
                varPetVetEmail: vetEmail,
            }

            newValue.pet.petVet = nObj
            newValue.pet.bitActive = true
            props.data.petVet = nObj

            setEditData(newValue)

            upload()
        }

        console.log(editData)
    }

    const resetImage = () => {
        setShowCropper(false)
        setFileSelected(false)
        setImportedData(editData.pet.varPetImagePath)
    }

    async function getPersonalities() {
        setIsLoading(true)
        const result = await axiosPrivate.get(`Personality/GetAll`, buildHeaderObject(auth))
        return result
    }

    const getPersonalityList = () => {
        getPersonalities()
            .then((personality) => {
                const list = personality?.data.personality
                let checkboxList = [] as any
                list.map((personalityObject) => {
                    let matching = false
                    if (editData.pet.petPersonality != null || editData.pet.petPersonality != undefined) {
                        matching = editData.pet.petPersonality.some((t) => t.numPersonalityID === personalityObject.numPersonalityID)
                    }

                    checkboxList.push({
                        bitActive: matching ? true : false,
                        numPersonalityID: personalityObject.numPersonalityID,
                        label: personalityObject.varPersonalityName,
                        varPersonalityDescription: personalityObject.varPersonalityDescription,
                        bitIsWarning: personalityObject.bitIsWarning,
                    })
                })

                setPersonalityList(checkboxList)
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    async function getBreeds() {
        const result = await axiosPrivate.get("PetBreed/GetAll", buildHeaderObject(auth))
        return result.data
    }

    async function getBreedsMainList() {
        const result = await axiosPrivate.get("Breed/GetAll", buildHeaderObject(auth))
        return result.data
    }

    const getBreedData = () => {
        getBreeds()
            .then((res) => {
                let pureBreeds = [] as any
                let mixBreeds = [] as any

                res?.petBreed.map((breed) => {
                    let objPure = {
                        numPetBreedID: breed?.numPetBreedID,
                        varPetBreedType: breed?.varPetBreedType,
                        varPetBreedName: breed?.varPetBreedName,
                        varPetBreedDescription: breed?.varPetBreedDescription,
                        numPrimaryPetBreedID: breed?.numPrimaryPetBreedID,
                        varPrimaryPetBreed: breed?.varPrimaryPetBreed,
                        numSecondaryPetBreedID: breed?.numSecondaryPetBreedID,
                        varSecondaryPetBreed: breed?.varSecondaryPetBreed,
                        bitActive: breed?.bitActive,
                    }

                    if (breed.varPetBreedType === "PURE") {
                        pureBreeds.push(objPure)
                    } else {
                        mixBreeds.push(objPure)
                    }
                })

                setPureBreedData(pureBreeds)
                setMixBreedData(mixBreeds)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    useEffect(() => {
        selectPetDescription()
    }, [MixBreedData])

    useEffect(() => {
        let months = generateYearAge(editData.pet?.varPetBirthDate)
        var years = Math.floor(months / 12)
        var months_dis = months % 12
        let petAge = parseFloat(years + "." + months_dis)
        const newValue = { ...editData }
        newValue.pet.varPetAge = petAge === null ? 0 : petAge
        setEditData(newValue)
    }, [editData.pet?.varPetBirthDate])

    const getBreedMainData = () => {
        getBreedsMainList()
            .then((res) => {
                let allBreeds = [] as any

                res?.breed.map((breed) => {
                    let objPure = {
                        numBreedID: breed?.numBreedID,
                        varBreedName: breed?.varBreedName,
                    }

                    allBreeds.push(objPure)
                })

                setBreedMainData(allBreeds)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const selectPetDescription = () => {
        if (editData.pet?.varPetBreedType === "PURE") {
            PureBreedData.some((breed) => {
                if (breed.numPetBreedID === editData.pet?.numPetBreedID) {
                    setPetDescription(breed.varPetBreedDescription)
                }
            })
        } else {
            MixBreedData.some((breed) => {
                if (breed.numPetBreedID === editData.pet?.numPetBreedID) {
                    setPetDescription(breed.varPetBreedDescription)
                    setPrimaryBreed(breed.numPrimaryPetBreedID)
                    setSecondaryBreed(breed.numSecondaryPetBreedID)
                }
            })
        }
    }

    useEffect(() => {
        getPersonalityList()
        getBreedData()
        getBreedMainData()
        if (props?.data.numPetVetID != null) {
            getVetList(props?.data.numPetVetID)
        }
        console.log("vet id", props?.data.numPetVetID)
    }, [])

    useEffect(() => {
        if (props?.data.numPetVetID != null) {
            getVetList(props?.data.numPetVetID)
        }
        console.log("vet id on change", props?.data.numPetVetID)
    }, [props.data])

    useEffect(() => {
        getPersonalityList()
    }, [editData.pet.petPersonality])

    async function getPetBreedByMix() {
        const result = await axiosPrivate.get(`PetBreed/GetByID/${primaryBreed}/${secondaryBreed}`, buildHeaderObject(auth))
        return result
    }

    async function getVetDetails(id) {
        const result = await axiosPrivate.get(`PetVet/GetPetVetByID/${id}`, buildHeaderObject(auth))
        return result
    }

    const [vetName, setVetName] = useState("")
    const [vetWork, setVetWork] = useState("")
    const [vetMobile, setVetMobile] = useState("")
    const [vetEmail, setVetEmail] = useState("")

    const getVetList = (id) => {
        console.log("vetID", id)
        getVetDetails(id)
            .then((res) => {
                setVetName(res?.data?.petVet?.varPetVetName)
                setVetWork(res?.data?.petVet?.varPetVetPhone1)
                setVetMobile(res?.data?.petVet?.varPetVetPhone2)
                setVetEmail(res?.data?.petVet?.varPetVetEmail)
                console.log("ressssVet", res)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        console.log("breed change", primaryBreed, secondaryBreed)

        getPetBreedByMix()
            .then((res) => {
                console.log(res.data.petBreed)

                if (res.data.petBreed === null) {
                    setNewMissingBreed({
                        ...newMissingBreed,
                        varPrimaryPetBreed: newMixName,
                        numPrimaryPetBreedID: primaryBreed,
                        numSecondaryPetBreedID: secondaryBreed,
                        numPetTypeID: editData.pet.numPetTypeID,
                        varPetBreedName: newMixName,
                        varSecondaryPetBreed: newSecondName,
                        varPetBreedDescription: newMixName,
                    })

                    setNoBreedData(true)
                } else {
                    setNoBreedData(false)
                    const newValue = { ...editData }
                    newValue.pet.numPetBreedID = res.data.petBreed.numPetBreedID
                    setEditData(newValue)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [primaryBreed, secondaryBreed])

    useEffect(() => {
        console.log(newMissingBreed)
    }, [newMissingBreed])

    useEffect(() => {
        selectPetDescription()
    }, [editData.pet?.numPetBreedID])

    const uploadID = editData.pet.numPetID

    const readFile = (files) => {
        if (files.size > 1000000) {
            toast.error(files.name + " File too large")
        } else {
            setImportedData(files)
            setShowCropper(true)
            setFileSelected(true)
            setImageName(files.name)
        }
    }

    const blobToFile = (theBlob: Blob, fileName: string) => {
        var b: any = theBlob
        b.lastModifiedDate = new Date()
        b.name = fileName
        return theBlob
    }

    const getCropData = async () => {
        if (typeof cropper !== "undefined") {
            cropper
                .getCroppedCanvas({
                    imageSmoothingEnabled: false,
                })
                .toBlob((blob) => {
                    let toFile = blobToFile(blob, imageName)
                    let imageFile: any = toFile
                    let options = {
                        maxSizeMB: 0.5,
                        fileType: "image/jpeg",
                        maxWidthOrHeight: 200,
                        useWebWorker: true,
                        onProgress: (progress) => {
                            setLoading(true)
                            if (progress == 100) {
                                setLoading(false)
                            }
                        },
                    }
                    imageCompression(imageFile, options)
                        .then(function (compressedFile) {
                            setImportedData(compressedFile)
                            setShowCropper(false)
                        })
                        .catch(function (error) {
                            console.log(error.message)
                        })
                })
        }
    }

    const upload = () => {
        if (!fileSelected) {
            if (noBreedData) {
                let objectBreed = {
                    petBreed: newMissingBreed,
                }
                saveMixBreed(objectBreed)
            } else {
                saveData()
                    .then((res) => {
                        console.log(res)

                        toast.success("Pet profile was updated successfully!")

                        setCustomerLoading(false)
                    })
                    .catch((err) => {
                        console.log(err)
                        setCustomerLoading(false)

                        toast.error("Something went wrong when saving new pet!")
                    })
            }
        } else {
            const formData = new FormData()
            formData.append("folderpath", `directories/pets`)
            formData.append("method", "N")
            formData.append("deletingFileName", "-")
            formData.append("renameFileNames", "0")

            const file = importedData
            const fileExtension = file.name.split(".").pop()
            const renamedFile = new File([file], `${editData.pet.numPetID}.${fileExtension}`, { type: file.type })
            formData.append("files", renamedFile)
            console.log("img-obj", formData)

            axiosPrivate
                .post(`FileUpload/UploadFile`, formData, buildHeaderObject(auth))
                .then((response) => {
                    console.log(response)
                    if (response.data.files.length > 0) {
                        const newValue = { ...editData }
                        newValue.pet.varPetImagePath = newValue.pet.numPetID + "." + imageName.split(".").pop()
                        setEditData(newValue)

                        saveData()
                            .then((res) => {
                                console.log(res)
                                toast.success("Pet profile was updated successfully!")

                                setCustomerLoading(false)
                            })
                            .catch((err) => {
                                console.log(err)
                                setCustomerLoading(false)

                                toast.error("Something went wrong when saving new location!")
                            })
                    }
                    setCustomerLoading(false)
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    const createNewPureBreed = () => {
        let obj = {
            petBreed: {
                bitActive: true,
                numPetBreedID: 0,
                varPetBreedType: "PURE",
                varPetBreedName: newPureBreedName,
                varPetBreedDescription: newPureBreedDesc,
                numPrimaryPetBreed: newPureBreedName,
                numPetTypeID: editData.pet.numPetTypeID,
            },
        }

        saveNewPureBreed(obj)
    }

    async function saveNewPureBreed(data) {
        await axiosPrivate
            .post(`PetBreed`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                if (result.status == 200) {
                    toast.success(`New Pure Breed successfully saved!`)
                    setNewPureBreedName("")
                    setNewPureBreedDesc("")
                    setAddNewPureBreed(false)
                    getBreedData()
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const createNewMixBreed = () => {
        let obj = {
            petBreed: {
                bitActive: true,
                numPetBreedID: 0,
                varPetBreedType: "MIX",
                varPetBreedName: newMixBreedName,
                varPetBreedDescription: newMixBreedDesc,
                numPrimaryPetBreedID: primaryBreed,
                numSecondaryPetBreedID: secondaryBreed,
                numPetTypeID: editData.pet.numPetTypeID,
            },
        }

        saveNewMixBreed(obj)
    }

    async function saveNewMixBreed(data) {
        await axiosPrivate
            .post(`PetBreed`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                if (result.status == 200) {
                    toast.success(`New Mixed Breed successfully saved!`)
                    setNewMixBreedName("")
                    setNewMixBreedDesc("")
                    setAddNewMixBreed(false)
                    getBreedData()
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    async function saveMixBreed(data) {
        await axiosPrivate
            .post(`PetBreed`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                if (result.status === 200) {
                    const newValue = { ...editData }

                    newValue.pet.numPetBreedID = result.data.numResponseID

                    newValue.pet.varPrimaryBreed = newMixName
                    newValue.pet.varSecondaryBreed = newSecondName

                    setEditData(newValue)

                    toast.success(`New Mixed Breed successfully saved!`)
                    console.log("result saved", result)

                    saveData()
                        .then((res) => {
                            console.log(res)

                            toast.success("Pet profile was updated successfully!")

                            setCustomerLoading(false)
                        })
                        .catch((err) => {
                            console.log(err)
                            setCustomerLoading(false)

                            toast.error("Something went wrong when saving new pet!")
                        })
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const createPersonality = () => {
        let obj = {
            personality: {
                bitActive: true,
                numCreatedBy: 0,
                varCreatedBy: "string",
                dtCreatedDate: "2022-09-20T10:50:13.004Z",
                numEditedBy: 0,
                varEditedBy: "string",
                dtEditedDate: "2022-09-20T10:50:13.004Z",
                numDeletedBy: 0,
                varDeletedBy: "string",
                dtDeletedDate: "2022-09-20T10:50:13.004Z",
                numPersonalityID: 0,
                varPersonalityName: PersonalityName,
                varPersonalityDescription: PersonalityName,
                bitIsWarning: PersonalityWarning == "1" ? true : false,
            },
        }
        savePersonality(obj)
    }

    async function savePersonality(data) {
        await axiosPrivate
            .post(`Personality`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                if (result.status == 200) {
                    toast.success(`New personality successfully saved!`)
                    setPersonalityName("")
                    setPersonalityWarning("")
                    setAddPersonality(false)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    return (
        <form id="editForm" onSubmit={(e) => e.preventDefault()}>
            {props.petProfileTemplate.map((section) => {
                return (
                    <>
                        {section.heading != undefined ? (
                            section.heading === "Personality" ? (
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <p className={styles.heading}>{section.heading}</p>
                                    {checkPermission(
                                        <p style={{ color: "#2076FF", fontWeight: "600", cursor: "pointer" }} onClick={() => setAddPersonality(true)}>
                                            Add new personality
                                        </p>,
                                        36
                                    )}

                                    {addPersonality ? (
                                        <Modal open={addPersonality}>
                                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem", width: "500px" }}>
                                                <div style={{ textAlign: "end", width: "100%" }}>
                                                    <CloseButton
                                                        onClick={() => {
                                                            setAddPersonality(false)
                                                        }}
                                                    />
                                                </div>
                                                <div style={{ backgroundColor: "#fff", display: "flex", flexDirection: "column", width: "100%", gap: "2rem" }}>
                                                    <p style={{ color: "#002867", fontSize: "18px", fontWeight: "600" }}>Add new personality</p>
                                                    <Input
                                                        onChange={(val) => {
                                                            setPersonalityName(val)
                                                        }}
                                                        className={styles.appInput}
                                                        label={"Type the personality here"}
                                                        value={PersonalityName}
                                                        required={true}
                                                    />
                                                    <Input
                                                        label={"Is Warning"}
                                                        value={PersonalityWarning}
                                                        onChange={(e) => {
                                                            setPersonalityWarning(e)
                                                        }}
                                                        items={[
                                                            {
                                                                text: "True",
                                                                value: "1",
                                                            },
                                                            {
                                                                text: "False",
                                                                value: "2",
                                                            },
                                                        ]}
                                                    />
                                                </div>

                                                <div style={{ textAlign: "end", width: "100%", backgroundColor: "#EFF5FF", padding: "1rem" }}>
                                                    <Button variant="text" color="transparent">
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className={styles.buttonText}
                                                        onClick={() => {
                                                            createPersonality()
                                                        }}
                                                    >
                                                        Create New
                                                    </Button>
                                                </div>
                                            </div>
                                        </Modal>
                                    ) : null}
                                </div>
                            ) : (
                                <p className={styles.heading}>{section.heading}</p>
                            )
                        ) : null}

                        {section.layout == "imageUpload" ? (
                            <>
                                <div style={{ backgroundColor: "#F9FAFB" }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", color: "#F9FAFB", padding: "16px 22px 16px 22px" }}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "column" }}>
                                            <div>
                                                <Avatar
                                                    shape={"circular"}
                                                    img={
                                                        fileSelected
                                                            ? URL.createObjectURL(importedData)
                                                            : editData.pet.varPetImagePath == null
                                                            ? placeholderImage
                                                            : `${process.env.REACT_APP_MEDIA_URL}directories/pets/${editData.pet.varPetImagePath}`
                                                    }
                                                    isVip={false}
                                                    className={"large"}
                                                    labelType={"largeLabel"}
                                                />
                                            </div>
                                            {editData.pet.varPetImagePath !== importedData ? (
                                                <p
                                                    style={{ color: "#005DF1", fontSize: "14px", fontWeight: "500", textAlign: "center", cursor: "pointer" }}
                                                    onClick={() => {
                                                        resetImage()
                                                    }}
                                                >
                                                    Reset photo
                                                </p>
                                            ) : null}
                                        </div>
                                        <div style={{ border: "2px dashed #5f9cfe", borderRadius: "8px", padding: "2rem", backgroundColor: "#fff" }}>
                                            <FileUploader
                                                handleChange={readFile}
                                                onDrop={readFile}
                                                disabled={loading ? true : false}
                                                name="file"
                                                types={fileTypes}
                                                multiple={false}
                                                label=""
                                                children={
                                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                                        <FileUploadOutlined style={{ color: "#2076FF" }} />
                                                        <p className={styles.uploadText}>Drag and drop or browse to choose a image</p>
                                                    </div>
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                <Modal open={showCropper}>
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                                        <div style={{ textAlign: "end", width: "100%" }}>
                                            <CloseButton
                                                onClick={() => {
                                                    resetImage()
                                                }}
                                            />
                                        </div>

                                        <div style={{ backgroundColor: "#fff" }}>
                                            <Cropper
                                                style={{ maxWidth: "500px", maxHeight: "500px" }}
                                                modal={true}
                                                src={fileSelected ? URL.createObjectURL(importedData) : ""}
                                                cropBoxResizable={true}
                                                initialAspectRatio={1}
                                                aspectRatio={1}
                                                guides={true}
                                                minCropBoxHeight={200}
                                                scalable={false}
                                                minCropBoxWidth={200}
                                                highlight={true}
                                                zoomable={true}
                                                movable={true}
                                                onInitialized={(instance) => {
                                                    setCropper(instance)
                                                }}
                                                ref={cropperRef}
                                            />
                                        </div>
                                        <div style={{ textAlign: "end", width: "100%" }}>
                                            <Button variant="contained" color="primary" onClick={() => getCropData()}>
                                                Crop & Fix
                                            </Button>
                                        </div>
                                    </div>
                                </Modal>
                            </>
                        ) : section.layout == "basic" ? (
                            <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "2rem", rowGap: "2ch" }}>
                                {section.data.map((rows) => {
                                    return rows.row.map((input) => {
                                        return input.type === "advancedCustom" ? (
                                            <TextField
                                                variant={"filled"}
                                                label={
                                                    <div style={{ display: "flex", fontSize: "14px" }}>
                                                        {input.label}
                                                        {input.isRequired ? (
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                }
                                                select={!!input.dataList}
                                                fullWidth
                                                onChange={(e) => {
                                                    const newValue = { ...editData }
                                                    newValue.pet[input.key] = e.target.value
                                                    setEditData(newValue)
                                                }}
                                                autoComplete={"on"}
                                                value={editData.pet[input.key]}
                                                required={input.isRequired}
                                            >
                                                {input.dataList?.map((item, i) => {
                                                    return (
                                                        <MenuItem key={i} value={item.value} style={{ fontSize: `medium` }}>
                                                            {item.text}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </TextField>
                                        ) : input.type === "advanced" ? (
                                            <AdvancedInput
                                                isButton={input.isButton}
                                                label={input.label}
                                                onChange={(e) => {
                                                    const newValue = { ...editData }
                                                    newValue.pet[input.key] = e
                                                    setEditData(newValue)
                                                }}
                                                ObjectName={input.objectName}
                                                isCustom={input.isCustom}
                                                Sequence={input.sequence}
                                                selectedValue={input.value}
                                                required={input.isRequired}
                                            />
                                        ) : input.type === "text" ? (
                                            <div>
                                                <TextField
                                                    // inputProps={{
                                                    //     pattern: "[a-z]{1,15}"
                                                    // }}
                                                    variant="filled"
                                                    style={{ width: "100%" }}
                                                    label={
                                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                                            {input.label}
                                                            {input.isRequired ? (
                                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                    Required
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    }
                                                    required={input.isRequired}
                                                    onChange={(e) => {
                                                        const newValue = { ...editData }
                                                        newValue.pet[input.key] = e.target.value
                                                        setEditData(newValue)
                                                    }}
                                                    type={input.inputType}
                                                    InputLabelProps={{
                                                        shrink: input.key === "varPetAge" || input.key === "numPetWeight" ? true : false,
                                                    }}
                                                    value={editData.pet[input.key]}
                                                />
                                            </div>
                                        ) : input.type === "date" ? (
                                            <div>
                                                <TextField
                                                    inputProps={{
                                                        max: input.key === "varPetBirthDate" ? shortDate(new Date()) : null,
                                                    }}
                                                    variant="filled"
                                                    style={{ width: "100%" }}
                                                    label={
                                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                                            {input.label}
                                                            {input.isRequired ? (
                                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                    Required
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    }
                                                    InputLabelProps={{ shrink: true }}
                                                    required={input.isRequired}
                                                    onChange={(e) => {
                                                        const newValue = { ...editData }
                                                        newValue.pet[input.key] = e.target.value
                                                        setEditData(newValue)
                                                    }}
                                                    type={input.inputType}
                                                    value={editData.pet[input.key]}
                                                />
                                            </div>
                                        ) : input.type === "customText" ? (
                                            <div>
                                                <TextField
                                                    // inputProps={{
                                                    //     pattern: "[a-z]{1,15}"
                                                    // }}
                                                    variant="filled"
                                                    style={{ width: "100%" }}
                                                    label={
                                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                                            {input.label}
                                                            {input.isRequired ? (
                                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                    Required
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    }
                                                    required={input.isRequired}
                                                    disabled={true}
                                                    type={input.inputType}
                                                    value={input.value}
                                                />
                                            </div>
                                        ) : input.type === "blank" ? (
                                            <div></div>
                                        ) : null
                                    })
                                })}
                            </div>
                        ) : section.layout == "main" ? (
                            <>
                                <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "2rem", rowGap: "2ch" }}>
                                    <div>
                                        {
                                            <TextField
                                                inputProps={{
                                                    pattern: "^[a-zA-Z ]*$",
                                                }}
                                                style={{ width: "100%" }}
                                                label={
                                                    <div style={{ display: "flex", fontSize: "14px" }}>
                                                        {section.data[0].label}
                                                        {section.data[0].isRequired ? (
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                }
                                                required={section.data[0].isRequired}
                                                onChange={(e) => {
                                                    const newValue = { ...editData }
                                                    newValue.pet[section.data[0].key] = e.target.value
                                                    setEditData(newValue)
                                                }}
                                                type={"text"}
                                                variant="filled"
                                                value={editData.pet[section.data[0].key]}
                                            />
                                        }
                                    </div>

                                    <div></div>
                                </div>

                                <div style={{ marginTop: "1.5rem" }}>
                                    <div>
                                        {
                                            <TextField
                                                multiline={true}
                                                style={{ width: "100%" }}
                                                label={
                                                    <div style={{ display: "flex", fontSize: "14px" }}>
                                                        {section.data[1].label}
                                                        {section.data[1].isRequired ? (
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                }
                                                required={false}
                                                onChange={(e) => {
                                                    const newValue = { ...editData }
                                                    newValue.pet[section.data[1].key] = e.target.value
                                                    setEditData(newValue)
                                                }}
                                                type={"text"}
                                                variant="filled"
                                                value={editData.pet[section.data[1].key]}
                                            />
                                        }
                                    </div>
                                </div>
                            </>
                        ) : section.layout === "vet" ? (
                            <div>
                                <div style={{ display: "flex", gap: "2rem" }}>
                                    <div style={{ width: "100%" }}>
                                        <AdvancedInput
                                            isButton={isActive(37)}
                                            label={section.data.main.label}
                                            onChange={(e) => {
                                                const newValue = { ...editData }
                                                newValue.pet[section.data.main.key] = e
                                                setEditData(newValue)
                                                getVetList(e)
                                                props.data.numPetVetID = e
                                            }}
                                            ObjectName={section.data.main.objectName}
                                            isCustom={section.data.main.isCustom}
                                            Sequence={section.data.main.sequence}
                                            selectedValue={section.data.main.value}
                                            required={section.data.main.isRequired}
                                        />
                                    </div>

                                    <TextField
                                        disabled={true}
                                        style={{ width: "100%" }}
                                        label={
                                            <div style={{ display: "flex", fontSize: "14px" }}>
                                                {section.data.sub[0].label}
                                                {section.data.sub[0].isRequired ? (
                                                    <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                        Required
                                                    </span>
                                                ) : null}
                                            </div>
                                        }
                                        required={section.data.sub[0].isRequired}
                                        type={"text"}
                                        variant="filled"
                                        value={vetWork}
                                    />
                                </div>

                                <div style={{ display: "flex", gap: "2rem", margin: "1rem 0" }}>
                                    <TextField
                                        disabled={true}
                                        style={{ width: "100%" }}
                                        label={
                                            <div style={{ display: "flex", fontSize: "14px" }}>
                                                {section.data.sub[1].label}
                                                {section.data.sub[1].isRequired ? (
                                                    <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                        Required
                                                    </span>
                                                ) : null}
                                            </div>
                                        }
                                        required={section.data.sub[1].isRequired}
                                        type={"text"}
                                        variant="filled"
                                        value={vetMobile}
                                    />

                                    <TextField
                                        disabled={true}
                                        style={{ width: "100%" }}
                                        label={
                                            <div style={{ display: "flex", fontSize: "14px" }}>
                                                {section.data.sub[2].label}
                                                {section.data.sub[2].isRequired ? (
                                                    <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                        Required
                                                    </span>
                                                ) : null}
                                            </div>
                                        }
                                        required={section.data.sub[2].isRequired}
                                        type={"text"}
                                        variant="filled"
                                        value={vetEmail}
                                    />
                                </div>
                            </div>
                        ) : section.layout == "personality" ? (
                            <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "2rem", rowGap: "2ch" }}>
                                {isLoading
                                    ? "Loading..."
                                    : personalityList.map((checkbox, ind) => {
                                          return (
                                              <div>
                                                  <div key={ind} style={{ display: "flex", alignItems: "center" }}>
                                                      <Checkbox
                                                          style={{ padding: "0 .2rem 0 0" }}
                                                          checked={checkbox.bitActive}
                                                          onChange={(e) => {
                                                              const triggerCheck = [...personalityList]
                                                              triggerCheck[ind].bitActive = !triggerCheck[ind].bitActive
                                                              setPersonalityList(triggerCheck)
                                                          }}
                                                      />
                                                      <div style={{ display: "flex", alignItems: "center", gap: ".3rem" }}>
                                                          {checkbox.label}
                                                          {checkbox.bitIsWarning ? <Warning style={{ fontSize: "15px", color: "#EB1A1A" }} /> : null}
                                                      </div>
                                                  </div>
                                              </div>
                                          )
                                      })}
                            </div>
                        ) : section.layout == "breeds" ? (
                            <>
                                <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "2rem", rowGap: "2ch" }}>
                                    <div>
                                        <div>
                                            <RadioGroup
                                                onChange={(c) => {
                                                    setPetDescription("")

                                                    let typeBreed = ""

                                                    if (c.target.value === "MIX") {
                                                        typeBreed = "MIX"
                                                        setPetIsMix(true)
                                                    } else {
                                                        typeBreed = "PURE"
                                                        setPetIsMix(false)
                                                    }
                                                    const newValue = { ...editData }

                                                    newValue.pet.varPetBreedType = typeBreed
                                                    setEditData(newValue)
                                                    console.log("check", c.target.value)
                                                }}
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="female"
                                                name="radio-buttons-group"
                                                style={{ display: "flex", alignItems: "center", flexDirection: "row" }}
                                                value={editData.pet?.varPetBreedType}
                                            >
                                                <FormControlLabel value="PURE" control={<Radio />} label="Pure breed" />
                                                <FormControlLabel value="MIX" control={<Radio />} label="Mix breed" />
                                            </RadioGroup>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "2rem", rowGap: "2ch" }}>
                                    <div>
                                        {petIsMix ? (
                                            noBreedData ? (
                                                <Input
                                                    disabled={true}
                                                    variant={"filled"}
                                                    label={
                                                        <p>
                                                            Breed Name
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        </p>
                                                    }
                                                    value={newMixName}
                                                />
                                            ) : (
                                                <Input
                                                    variant={"filled"}
                                                    items={MixBreedData.map((i) => ({ text: i.varPetBreedName, value: i.numPetBreedID }))}
                                                    label={
                                                        <p>
                                                            Breed
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        </p>
                                                    }
                                                    value={editData.pet?.numPetBreedID}
                                                    onChange={(e) => {
                                                        const newValue = { ...editData }
                                                        newValue.pet.numPetBreedID = e
                                                        setEditData(newValue)
                                                    }}
                                                />
                                            )
                                        ) : (
                                            <div className={styles.listContainer}>
                                                <div style={{ gap: "0.3rem !important", width: "100%" }}>
                                                    <Input
                                                        variant={"filled"}
                                                        items={PureBreedData.map((i) => ({ text: i.varPetBreedName, value: i.numPetBreedID }))}
                                                        label={
                                                            <p>
                                                                Breed
                                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                    Required
                                                                </span>
                                                            </p>
                                                        }
                                                        value={editData.pet?.numPetBreedID}
                                                        onChange={(e) => {
                                                            const newValue = { ...editData }
                                                            newValue.pet.numPetBreedID = e
                                                            setEditData(newValue)
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <IconButton onClick={() => setAddNewPureBreed(true)} style={{ background: "#FFFF", borderRadius: "4px", border: "1px solid #005DF1" }}>
                                                        <Add fontSize="small" style={{ color: "#005DF1" }} />
                                                    </IconButton>

                                                    {addNewPureBreed ? (
                                                        <Modal open={addNewPureBreed}>
                                                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem", width: "500px" }}>
                                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", textAlign: "end", width: "100%" }}>
                                                                    <p style={{ color: "#002867", fontSize: "18px", fontWeight: "600" }}>Add new Pure breed</p>

                                                                    <CloseButton
                                                                        onClick={() => {
                                                                            setAddNewPureBreed(false)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div style={{ backgroundColor: "#fff", display: "flex", flexDirection: "column", width: "100%", gap: "2rem" }}>
                                                                    <Input
                                                                        onChange={(val) => {
                                                                            setNewPureBreedName(val)
                                                                        }}
                                                                        className={styles.appInput}
                                                                        label={"Type the new pure breed name here"}
                                                                        value={newPureBreedName}
                                                                        required={true}
                                                                    />

                                                                    <Input
                                                                        onChange={(val) => {
                                                                            setNewPureBreedDesc(val)
                                                                        }}
                                                                        className={styles.appInput}
                                                                        label={"Type the new pure breed description"}
                                                                        value={newPureBreedDesc}
                                                                        required={true}
                                                                    />
                                                                </div>

                                                                <div style={{ textAlign: "end", width: "100%", backgroundColor: "#EFF5FF", padding: "1rem" }}>
                                                                    <Button variant="text" color="transparent">
                                                                        Cancel
                                                                    </Button>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        className={styles.buttonText}
                                                                        onClick={() => {
                                                                            createNewPureBreed()
                                                                        }}
                                                                    >
                                                                        Create New
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </Modal>
                                                    ) : null}
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div>
                                        <Input disabled={true} variant={"filled"} label={<p>Description</p>} value={petDescription} />
                                    </div>
                                </div>

                                {petIsMix ? (
                                    <>
                                        <div style={{ marginTop: "1rem", display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "2rem", rowGap: "2ch" }}>
                                            <div>
                                                <TextField
                                                    variant={"filled"}
                                                    select={!!breedMainData}
                                                    fullWidth
                                                    multiline={true}
                                                    InputLabelProps={{ shrink: true }}
                                                    label={<p>Primary Breed</p>}
                                                    value={primaryBreed}
                                                    onChange={(e) => {
                                                        setPrimaryBreed(e.target.value)
                                                    }}
                                                >
                                                    {breedMainData?.map((item, i) => {
                                                        return (
                                                            <MenuItem onClick={() => setNewMixName(item.varBreedName)} key={item.numBreedID} value={item.numBreedID}>
                                                                {item.varBreedName}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </TextField>
                                            </div>

                                            <div>
                                                <TextField
                                                    variant={"filled"}
                                                    select={!!breedMainData}
                                                    fullWidth
                                                    multiline={true}
                                                    InputLabelProps={{ shrink: true }}
                                                    label={<p>Secondary Breed</p>}
                                                    value={secondaryBreed}
                                                    onChange={(e) => {
                                                        setSecondaryBreed(e.target.value)
                                                    }}
                                                >
                                                    {breedMainData?.map((item, i) => {
                                                        return (
                                                            <MenuItem onClick={() => setSecondName(item.varBreedName)} key={item.numBreedID} value={item.numBreedID}>
                                                                {item.varBreedName}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </TextField>
                                            </div>
                                        </div>

                                        <div>
                                            {/* {
                                            (noBreedData) ?
                                                <Button  color='default' variant='outlined' onClick={() => setAddNewMixBreed(true)} text='Add New Breed' />
                                            : null
                                        } */}

                                            {addNewMixBreed ? (
                                                <Modal open={addNewMixBreed}>
                                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem", width: "500px" }}>
                                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", textAlign: "end", width: "100%" }}>
                                                            <p style={{ color: "#002867", fontSize: "18px", fontWeight: "600" }}>Add new Mix breed</p>

                                                            <CloseButton
                                                                onClick={() => {
                                                                    setAddNewMixBreed(false)
                                                                }}
                                                            />
                                                        </div>
                                                        <div style={{ backgroundColor: "#fff", display: "flex", flexDirection: "column", width: "100%", gap: "2rem" }}>
                                                            <Input
                                                                onChange={(val) => {
                                                                    setNewMixBreedName(val)
                                                                }}
                                                                className={styles.appInput}
                                                                label={"Type the new mix breed name here"}
                                                                value={newMixBreedName}
                                                                required={true}
                                                            />

                                                            <Input
                                                                onChange={(val) => {
                                                                    setNewMixBreedDesc(val)
                                                                }}
                                                                className={styles.appInput}
                                                                label={"Type the new mix breed description"}
                                                                value={newMixBreedDesc}
                                                                required={true}
                                                            />
                                                        </div>

                                                        <div style={{ textAlign: "end", width: "100%", backgroundColor: "#EFF5FF", padding: "1rem" }}>
                                                            <Button variant="text" color="transparent">
                                                                Cancel
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                className={styles.buttonText}
                                                                onClick={() => {
                                                                    createNewMixBreed()
                                                                }}
                                                            >
                                                                Create New
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Modal>
                                            ) : null}
                                        </div>
                                    </>
                                ) : null}
                            </>
                        ) : section.layout == "dynamic" ? (
                            <>
                                <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "2rem", rowGap: "2ch" }}>
                                    {section.data.map((input) => {
                                        return input.type === "String" ? (
                                            <>
                                                <TextField
                                                    // inputProps={{
                                                    //     pattern: "[a-z]{1,15}"
                                                    // }}
                                                    style={{ width: "100%" }}
                                                    label={
                                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                                            {input.key}
                                                            {input.required ? (
                                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                    Required
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    }
                                                    required={input.required}
                                                    onChange={(e) => {
                                                        const newValue = { ...editData }
                                                        newValue.pet[input.fieldName] = e.target.value
                                                        setEditData(newValue)
                                                    }}
                                                    type={"text"}
                                                    variant="filled"
                                                    value={editData.pet[input.fieldName]}
                                                />
                                            </>
                                        ) : input.type === "DateTime" ? (
                                            <>
                                                <TextField
                                                    // inputProps={{
                                                    //     pattern: "[a-z]{1,15}"
                                                    // }}
                                                    style={{ width: "100%" }}
                                                    label={
                                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                                            {input.key}
                                                            {input.required ? (
                                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                    Required
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    }
                                                    required={input.required}
                                                    onChange={(e) => {
                                                        const newValue = { ...editData }
                                                        newValue.pet[input.fieldName] = e.target.value
                                                        setEditData(newValue)
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    focused={true}
                                                    type={"date"}
                                                    variant="filled"
                                                    value={editData.pet[input.fieldName]}
                                                />
                                            </>
                                        ) : input.type === "Int32" ? (
                                            <>
                                                <AdvancedInput
                                                    isButton={true}
                                                    label={
                                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                                            {input.key}
                                                            {input.required ? (
                                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                    Required
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    }
                                                    required={input.required}
                                                    onChange={(e) => {
                                                        const newValue = { ...editData }
                                                        newValue.pet[input.fieldName] = e
                                                        setEditData(newValue)
                                                    }}
                                                    ObjectName={"Customer"}
                                                    isCustom={true}
                                                    Sequence={""}
                                                    selectedValue={editData.pet[input.fieldName]}
                                                />
                                            </>
                                        ) : input.type === "Boolean" ? (
                                            <>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                    <p>{input.key}</p>
                                                    <Switch
                                                        onChange={(e) => {
                                                            const newValue = { ...editData }
                                                            newValue.pet[input.fieldName] = !newValue.pet[input.fieldName]
                                                            setEditData(newValue)
                                                        }}
                                                        checked={editData.pet[input.fieldName]}
                                                        inputProps={{ "aria-label": "controlled" }}
                                                    />
                                                </div>
                                            </>
                                        ) : null
                                    })}
                                </div>
                            </>
                        ) : null}
                        <div style={{ paddingTop: "1rem", marginBottom: "1.5rem" }}></div>
                    </>
                )
            })}
            {checkPermission(
                <div style={{ backgroundColor: "#EFF5FF", padding: "2rem 3rem", textAlign: "end" }}>
                    <div style={{ display: "flex", gap: "2rem", justifyContent: "flex-end" }}>
                        <Button onClick={() => saveChanges()} loading={customerLoading} variant="contained" color="primary" text="Save" type={"submit"} />
                    </div>
                </div>,
                38
            )}
        </form>
    )
}

export default EditPetProfile
