import { LinkOff, MoreHoriz, Place } from "@mui/icons-material"
import { useEffect, useMemo, useState } from "react"
import { Button, Modal, Input } from "../../../components"
import { Avatar, SearchBox } from "../../../components"
import styles from "./LinkedAccounts.module.scss"
import { Chip as LocationChip } from "../../../Chip/Chip"
import { CloseButton } from "../../../CloseButton/CloseButton"
import { CircularProgress, FormControlLabel, IconButton, Menu, MenuItem, Switch, TextField } from "@mui/material"
import { CustomerCard } from "../../../CustomerCard/CustomerCard"
import { axiosPrivate, buildHeaderObject } from "../../../../apis/axios"
import { toast } from "react-toastify"
import debouce from "lodash.debounce"
import Fade from "@mui/material/Fade"
import ListItemIcon from "@mui/material/ListItemIcon"
import { Truncate } from "../../../../utils/Validation"
import { formatPhone, removeFormatPhone } from "../../../../utils/PhoneFormatter"
import InputMask from "react-input-mask"
import { checkPermission } from "../../../../utils/permissions"
import useAuth from "../../../../hooks/useAuth"

function LinkedAccounts(props: { data?; mode?: boolean }) {
    const [list, setList] = useState<any>([])
    const [isLoading, setIsLoading] = useState(false)
    const [selectedList, setSelectedList] = useState<any>([])
    const [searchValue, setSearchValue] = useState("")
    const [unlinkID, setUnlinkID] = useState("")
    const [linkedIsOpen, setLinkedIsOpen] = useState(false)
    const [advancedSearch, setAdvancedSearch] = useState(false)
    const [fSearch, setFSearch] = useState("")
    const [lSearch, setLSearch] = useState("")
    const [petSearch, setPetSearch] = useState("")
    const [contactSearch, setContactSearch] = useState("")
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const { auth } = useAuth() as any

    async function getList() {
        setIsLoading(true)
        const result = await axiosPrivate.get(`Customer/GetLinkedAccountsByID/${props.data.numCustomerID}`, buildHeaderObject(auth))
        return result
    }

    useEffect(() => {
        setIsLoading(true)
        getList()
            .then((response) => {
                setList(response?.data?.customer)
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
                setIsLoading(false)
            })
    }, [])

    const [searchData, setSearchData] = useState([] as any)

    async function getData(searchQuery) {
        const result = await axiosPrivate.post(
            "Customer/Search",
            JSON.stringify({
                page: 0,
                pageSize: 0,
                searchText: searchQuery,
            }),
            buildHeaderObject(auth)
        )
        return result.data
    }

    const validateString = (input) => {
        return input === "" ? null : input
    }

    async function getAdvancedData() {
        setIsLoading(true)
        let payload = {
            varLocation: null,
            varFirstName: validateString(fSearch),
            varLastName: validateString(lSearch),
            varCity: null,
            varState: null,
            varZip: null,
            bitActiveStatus: null,
            bitPriorityVIP: null,
            varPetName: validateString(petSearch),
            numPetTypeID: null,
            varPetBreed: null,
            varContactNumber: validateString(contactSearch) == null ? null : removeFormatPhone(validateString(contactSearch)),
        }
        console.log("payload", payload)
        const result = await axiosPrivate.post("Customer/AdvanceSearch", JSON.stringify(payload), buildHeaderObject(auth))

        return result.data
    }

    const fetchData = (searchQuery) => {
        setIsLoading(true)
        getData(searchQuery)
            .then((res) => {
                setSearchData(res.customer)
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setIsLoading(false)

                toast.error("Something went wrong!")
                setSearchData([])
            })
    }
    const handleSearch = (value) => {
        setSearchValue(value)
    }

    useEffect(() => {
        if (searchValue != "") {
            fetchData(searchValue)
        }
    }, [searchValue])

    const debouceSearch = useMemo(() => {
        return debouce(handleSearch, 500)
    }, [])

    const toggleAdvanceSearch = () => {
        if (advancedSearch) {
            setAdvancedSearch(false)
        } else {
            setAdvancedSearch(true)
        }
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (fSearch != "" || lSearch != "" || contactSearch != "" || petSearch != "") {
                getAdvancedData()
                    .then((res) => {
                        setSearchData(res.customer)
                        setIsLoading(false)
                    })
                    .catch((err) => {
                        console.log(err)
                        setIsLoading(false)
                        setSearchData([])
                        toast.error("Something went wrong!")
                    })
            }
        }, 900)
        return () => clearTimeout(delayDebounceFn)
    }, [fSearch, lSearch, contactSearch, petSearch])

    const selectAccount = (user) => {
        const selectedUser = [...selectedList]
        const isIn = selectedUser.includes(user.numCustomerID.toString())
        if (!isIn) {
            selectedUser?.push(user.numCustomerID.toString())
            setSelectedList(selectedUser)
        } else {
            var carIndex = selectedUser.indexOf(user.numCustomerID)
            const afterRemove = [...selectedList]
            afterRemove.splice(carIndex, 1)
            setSelectedList(afterRemove)
        }
    }

    const discardChanges = () => {
        setSelectedList([])
        setFSearch("")
        setLSearch("")
        setPetSearch("")
        setContactSearch("")
        setSearchValue("")
        setAdvancedSearch(false)
        setSearchData([])
    }

    const handleAdd = () => {
        if (selectedList.length > 0) {
            let length = selectedList.length
            var numberArray: any = []
            for (var i = 0; i < length; i++) {
                numberArray.push(parseInt(selectedList[i]))
            }
            const obj = {}
            obj["numCustomerID"] = props.data.numCustomerID
            obj["linkedCustomers"] = numberArray
            linkSubmit(obj)
        } else {
            toast.error("Please select at least one customer to link")
        }
    }

    async function linkSubmit(data) {
        let postLength = data.linkedCustomers.length
        await axiosPrivate
            .post(`Customer/PostLinkedAccounts`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                if (result.status == 200) {
                    toast.success(`Out of ${postLength} customer profiles ${result.data.numInsertedRowCount} have been linked`)
                    setSearchValue("")
                    setSearchData([])
                    setSelectedList([])
                    setLinkedIsOpen(false)
                    setIsLoading(true)

                    getList()
                        .then((response) => {
                            setList(response?.data?.customer)
                            setIsLoading(false)
                        })
                        .catch((err) => {
                            console.log(err)
                            toast.error("Something went wrong!")
                            setIsLoading(false)
                        })
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    async function unLinkSubmit(data) {
        await axiosPrivate
            .delete(`Customer/DeleteLinkedAccount/${data.numCustomerID}/${data.linkedCustomer}`, buildHeaderObject(auth))
            .then((result) => {
                if (result.status == 200) {
                    toast.success("Profile unlinked successfully")
                    setUnlinkID("")
                    setAnchorEl(null)
                    getList()
                        .then((response) => {
                            setList(response?.data?.customer)
                            setIsLoading(false)
                        })
                        .catch((err) => {
                            console.log(err)
                            toast.error("Something went wrong!")
                            setIsLoading(false)
                        })
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const handleModalClose = () => {
        setLinkedIsOpen(!linkedIsOpen)
        setSearchValue("")
        setSearchData([])
        setSelectedList([])
        setLinkedIsOpen(false)
        setFSearch("")
        setLSearch("")
        setPetSearch("")
        setContactSearch("")
        setAdvancedSearch(false)
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>, user) => {
        setAnchorEl(event.currentTarget)
        setUnlinkID(user)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const handleUnlink = () => {
        setAnchorEl(null)
        const obj = {}
        obj["numCustomerID"] = props.data.numCustomerID
        obj["linkedCustomer"] = unlinkID
        unLinkSubmit(obj)
    }

    return (
        <div>
            <p className={styles.heading}>
                Profiles linked to {props.data.varCustomerFirstName} {props.data.varCustomerLastName}
            </p>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 0fr)", gridColumnGap: "1rem", rowGap: "1rem", marginTop: "0.6rem" }}>
                {isLoading ? (
                    <p>Loading...</p>
                ) : list.length > 0 ? (
                    list.map((user) => {
                        return (
                            <div key={user.numCustomerID} style={{ margin: "0 .5rem .5rem 0", width: "390px" }}>
                                <div style={{ border: "1px solid #E5E7EB", borderRadius: 8, padding: "12px", backgroundColor: "#ffffff" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "1rem" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", gap: ".6rem" }}>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <Avatar img={"https://pet-tech-api.s3.amazonaws.com/directories/Customer/" + user.varCustomerImagePath} isVip={false} className={"medium"} />
                                            </div>
                                            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between", gap: "10px" }}>
                                                <div style={{ fontWeight: 600, fontSize: "16px" }}>
                                                    {user.varCustomerFirstName} {user.varCustomerLastName}
                                                </div>
                                                <div style={{ display: "flex", gap: "5px" }}>
                                                    <LocationChip
                                                        label={Truncate(user?.varCustomerDefaultLocation, 22)}
                                                        shape="square"
                                                        icon={<Place style={{ fontSize: "15px" }} />}
                                                        customStyle="BlueFilled"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {checkPermission(
                                            <div style={{ display: "flex", gap: ".2rem" }}>
                                                <div style={{ gap: ".5rem" }}>
                                                    <IconButton
                                                        onClick={(e) => {
                                                            handleClick(e, user.numCustomerID)
                                                        }}
                                                    >
                                                        <MoreHoriz style={{ transform: "rotate(90deg)", color: "#6B7280" }} />
                                                    </IconButton>
                                                </div>
                                            </div>,
                                            19
                                        )}
                                    </div>
                                    <div style={{ padding: "5px 0", display: "flex", justifyContent: "space-between", borderTop: "1px solid #F3F4F6" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                            <div style={{ fontWeight: 600, fontSize: 14, color: "#002867" }}>{formatPhone(user.varCustomerMobilePhone)}</div>
                                            <div style={{ fontWeight: 600, fontSize: 14, color: "#002867" }}>{formatPhone(user.varCustomerHomePhone)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                ) : (
                    <p style={{ color: "rgb(119, 126, 144)", fontWeight: "400", fontSize: "12px", width: "600px" }}>No linked profiles found</p>
                )}
            </div>

            {props?.mode ? (
                <div style={{ width: "201px", marginTop: "2rem" }}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            setLinkedIsOpen(!linkedIsOpen)
                        }}
                    >
                        Link new profile
                    </Button>
                </div>
            ) : null}
            {checkPermission(
                <Menu
                    id="fade-menu"
                    MenuListProps={{
                        "aria-labelledby": "fade-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                >
                    <MenuItem onClick={handleUnlink}>
                        <ListItemIcon>
                            <LinkOff fontSize="small" />
                        </ListItemIcon>
                        Unlink
                    </MenuItem>
                </Menu>,
                19
            )}

            {linkedIsOpen ? (
                <Modal open={linkedIsOpen}>
                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem", width: "700px" }}>
                        <div style={{ textAlign: "end", width: "100%" }}>
                            <CloseButton
                                onClick={() => {
                                    handleModalClose()
                                }}
                            />
                        </div>

                        <div style={{ backgroundColor: "#fff", display: "flex", justifyContent: "space-between", width: "100%" }}>
                            <div style={{ paddingLeft: "1rem", width: "216px" }}>
                                <SearchBox value={searchValue} onChange={(e) => debouceSearch(e)} />
                            </div>
                            <div style={{ paddingRight: "1rem" }}>
                                <FormControlLabel
                                    value="start"
                                    control={
                                        <Switch
                                            color="primary"
                                            onChange={() => {
                                                toggleAdvanceSearch()
                                            }}
                                        />
                                    }
                                    label="Advanced Search"
                                    labelPlacement="start"
                                    checked={advancedSearch}
                                    style={{ color: "#002867", fontSize: "14px" }}
                                />
                            </div>
                        </div>
                        {advancedSearch ? (
                            <div style={{ backgroundColor: "#F9FAFB", display: "flex", justifyContent: "space-between", width: "100%" }}>
                                <div style={{ padding: "0px 1rem", width: "100%", display: "flex", justifyContent: "space-around" }}>
                                    <div>
                                        <Input
                                            onChange={(val) => {
                                                setFSearch(val)
                                            }}
                                            className={styles.appInput}
                                            label={"First Name"}
                                            value={fSearch}
                                        />
                                        <Input
                                            onChange={(val) => {
                                                setLSearch(val)
                                            }}
                                            className={styles.appInput}
                                            label={"Last Name"}
                                            value={lSearch}
                                        />
                                    </div>
                                    <div>
                                        <Input
                                            onChange={(val) => {
                                                setPetSearch(val)
                                            }}
                                            className={styles.appInput}
                                            label={"Pets Name"}
                                            value={petSearch}
                                        />
                                        <InputMask alwaysShowMask="true" mask="\(999\)999\-9999" maskChar="_" value={contactSearch} onChange={(e) => setContactSearch(e.target.value)}>
                                            {(inputProps) => <TextField {...inputProps} type="tel" required={false} label={<p>Mobile</p>} variant="standard" />}
                                        </InputMask>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        <div
                            className={styles.gridContainer}
                            style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "2rem", rowGap: "2ch", marginTop: "0.6rem", paddingBottom: "1rem" }}
                        >
                            {isLoading ? (
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <CircularProgress />
                                </div>
                            ) : searchData.length > 0 ? (
                                searchData.map((user, index) => {
                                    const selectedUser = [...selectedList]
                                    const isIn = selectedUser.includes(user.numCustomerID.toString())

                                    return (
                                        <div
                                            onClick={() => {
                                                selectAccount(user)
                                            }}
                                            className={isIn ? styles.customerCardChecked : styles.customerCard}
                                        >
                                            <CustomerCard
                                                key={index}
                                                CustomerImageURL={user.varCustomerImagePath == null ? "" : `https://pet-tech-api.s3.amazonaws.com/directories/Customer/${user?.varCustomerImagePath}`}
                                                Home={user.varCustomerHomePhone}
                                                Location={Truncate(user.varCustomerDefaultLocation, 27)}
                                                Mobile={user.varCustomerMobilePhone}
                                                isVIP={user.bitIsVip}
                                                Name={user.varCustomerFirstName + " " + user.varCustomerLastName}
                                                OnOpenInNew={true}
                                            ></CustomerCard>
                                        </div>
                                    )
                                })
                            ) : (
                                <p style={{ marginRight: "11rem", color: "grey", fontWeight: "500" }}>No customer found</p>
                            )}
                        </div>
                        <div style={{ textAlign: "end", width: "100%", backgroundColor: "#EFF5FF", padding: "1rem" }}>
                            <Button variant="outlined" color="primary" className={styles.buttonText} onClick={handleAdd}>
                                Add profile
                            </Button>
                        </div>
                    </div>
                </Modal>
            ) : null}
        </div>
    )
}

export default LinkedAccounts
