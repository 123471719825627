import { useEffect, useState } from "react"
import { Modal } from "../components"
import { Button, CloseButton } from "../../components"
import { TextField, IconButton, InputAdornment, Switch, Tooltip } from "@mui/material"
import PercentIcon from "@mui/icons-material/Percent"
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"
import { axiosPrivate, buildHeaderObject } from "../../apis/axios"
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import useAuth from "../../hooks/useAuth"

function Tip(props: { isOpenTipModal; setIsOpenTipModal: CallableFunction; groomerName; batherName; onClose; onSave; globalGroomerPercentage; globalBatherPercentage; totalAmount; appDetails? }) {
    const [isLoading, setIsLoading] = useState(false)
    const [tipPercentage, setTipPercentage] = useState<number | null>(null)
    const [customTip, setCustomTip] = useState("")
    const [isPercentage, setIsPercentage] = useState(false)
    const [groomerPercentage, setGroomerPercentage] = useState(0)
    const [batherPercentage, setBatherPercentage] = useState(0)
    const [percentageList, setPercentageList] = useState([]) as any
    const [selectedIndex, setSelectedIndex] = useState("") as any
    const [tipDetail, setTipDetail] = useState("") as any
    const [isCustomPercentage, setIsCustomPercentage] = useState(false)
    const [locationPercentage, setLocationPercentage] = useState("") as any

    const [isOpenSetGlobalPercentage, setIsOpenSetGlobalPercentage] = useState(false)
    const [groomerGlobalPercentage, setGroomerGlobalPercentage] = useState(0)
    const [batherGlobalPercentage, setBatherGlobalPercentage] = useState(0)

    const { auth, setAuth } = useAuth() as any

    const getLocationWiseTipDetailsByLocationID = async (LocID) => {
        const apiUrl = `Tip/GetLocationWiseTipDetailsByLocationID`

        try {
            const response = await axiosPrivate.get(
                apiUrl,

                buildHeaderObject(auth, false, false, false, {
                    LocationID: LocID,
                })
            )
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    const getLocationWiseTipPercentages = async (LocID) => {
        const apiUrl = `Tip/GetLocationWiseTipPercentages`

        try {
            const response = await axiosPrivate.get(
                apiUrl,
                buildHeaderObject(auth, false, false, false, {
                    LocationID: LocID,
                })
            )
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    async function saveGlobalTipPercentage(data) {
        console.log("sent-data", data)
        await axiosPrivate
            .post(`Tip/PopulateLocationWiseTip`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                console.log("result", result)
                if (result.status === 200) {
                    toast.success("Global Percentages added successfully")
                    props.onClose()
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const getTipServiceDetails = async () => {
        const apiUrl = `Tip/GetTipServiceItemIdByName`

        try {
            const response = await axiosPrivate.get(
                apiUrl,
                buildHeaderObject(auth, false, false, false, {
                    serviceName: "Tip",
                })
            )
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    async function saveServiceItemsLibrary(data) {
        await axiosPrivate
            .post(`ServiceItemsLibrary`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                console.log("result", result)
                if (result.status == 200) {
                    if (result.data?.bitSuccess === false) {
                        toast.info(result.data?.varResponseMessage)
                    } else {
                        toast.success(`New Service Item Tip successfully saved!`)
                        props.onClose()
                    }
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    useEffect(() => {
        if (props.isOpenTipModal === true) {
            getTipServiceDetails()
                .then((data) => {
                    console.log("TipDetail", data)
                    if (data === "Service item not found.") {
                        Swal.fire({
                            title: "Caution",
                            text: 'Service Item "Tip" not found, Do you wish to add the Tip to service item library to continue?',
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonText: "Yes, Add It",
                            cancelButtonText: "No, Cancel",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                let TipObj = {
                                    serviceItemsLibrary: {
                                        numServiceItemID: 0,
                                        varServiceItemProviderID: "0000",
                                        varServiceItemBarCode: "0000",
                                        varServiceItemName: "Tip",
                                        varServiceItemDescription: "Tip for Groomer / Bather",
                                        numServiceItemCostPrice: 0,
                                        numServiceItemSalesPrice: 0,
                                        bitIsAdditional: true,
                                        btSalesCommissionPercent: false,
                                        numSalesCommissionPercent: 0,
                                        btSalesCommissionAmount: false,
                                        numSalesCommissionAmount: 0,
                                        dtEffectiveDate: "2023-01-01",
                                        dtExpiryDate: "2300-01-01",
                                        numPetTypeID: 1,
                                        varColour: "#c6c6c6",
                                        bitActive: true,
                                    },
                                }
                                saveServiceItemsLibrary(TipObj)
                            } else if (result.dismiss) {
                                props.onClose()
                            }
                        })
                    } else {
                        setTipDetail(data)
                        getLocationWiseTipDetailsByLocationID(props?.appDetails?.LocationID)
                            .then((data) => {
                                if (props.appDetails.Employee.EmployeeID !== props.appDetails.Employee.DealerID) {
                                    if (Array.isArray(data) && data.length === 0) {
                                        console.log("The result is an empty array-INSERT.")
                                        toast.warning("Global groomer/bather percentages are not setup, please add it to continue")
                                        setIsOpenSetGlobalPercentage(true)
                                    } else {
                                        if (data?.[0].BatherTipPerc === 0 && data?.[0].GroomerTipPerc === 0) {
                                            console.log("The result is an empty array- EDIT - Never Reaches.")
                                        } else {
                                            console.log("preSetPrecent", data)
                                            setBatherPercentage(data?.[0].BatherTipPerc)
                                            setGroomerPercentage(data?.[0].GroomerTipPerc)
                                            setLocationPercentage(data)
                                        }
                                        console.log("The result is not an empty array.")
                                    }
                                } else {
                                    console.log("preSetPrecent", data)
                                    setBatherPercentage(data?.[0].BatherTipPerc)
                                    setGroomerPercentage(data?.[0].GroomerTipPerc)
                                    setLocationPercentage(data)
                                }
                            })
                            .catch((error) => {
                                console.log("error", error)
                            })
                        getLocationWiseTipPercentages(props?.appDetails?.LocationID)
                            .then((data) => {
                                console.log("PrecentList", data)
                                setPercentageList(data)
                                if (data.length > 0) {
                                    setTipPercentage(data[0].TipPerc)
                                    setSelectedIndex(0)
                                }
                            })
                            .catch((error) => {
                                console.log("error", error)
                            })
                        setIsPercentage(false)
                    }
                })
                .catch((error) => {
                    console.log("error", error)
                })
        }
    }, [props.isOpenTipModal])

    useEffect(() => {
        if (isCustomPercentage === false && locationPercentage !== "") {
            setBatherPercentage(locationPercentage?.[0].BatherTipPerc)
            setGroomerPercentage(locationPercentage?.[0].GroomerTipPerc)
        }
    }, [isCustomPercentage])

    const calculateTipAmount = (percentage) => {
        return ((props.totalAmount * percentage) / 100).toFixed(2)
    }

    const handlePercentageClick = (percentage, index) => {
        setTipPercentage(percentage)
        setCustomTip("")
        setSelectedIndex(index)
        setIsPercentage(false)
    }

    const handleCustomTipChange = (e) => {
        const value = e.target.value
        setCustomTip(value)
        setTipPercentage(null)
        setSelectedIndex("")

        const isPercentageValue = /\d+%$/.test(value)
        setIsPercentage(isPercentageValue)
    }

    const toggleTipType = () => {
        setIsPercentage(!isPercentage)
    }

    const getTipAmounts = () => {
        let tipAmount: any = 0
        let groomerTip: any = 0
        let batherTip: any = 0

        if (tipPercentage) {
            tipAmount = parseFloat(calculateTipAmount(tipPercentage))
        } else if (customTip) {
            if (isPercentage) {
                const percentageValue = parseFloat(customTip)
                tipAmount = parseFloat(calculateTipAmount(percentageValue))
            } else {
                tipAmount = parseFloat(customTip)
            }
        }

        if (props.appDetails.Employee.EmployeeID === props.appDetails.Employee.DealerID) {
            groomerTip = tipAmount.toFixed(2)
        } else {
            groomerTip = ((tipAmount * groomerPercentage) / 100).toFixed(2)
            batherTip = ((tipAmount * batherPercentage) / 100).toFixed(2)
        }

        return { groomerTip, batherTip }
    }

    async function saveTip(data) {
        console.log("tips payload", data)
        await axiosPrivate
            .post(`Tip/PopulateTips`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                if (result.status == 200) {
                    console.log(result)
                    toast.success("Tip added successfully!")
                    props.onSave(groomerTip, batherTip)
                    props.onClose()
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const handleSave = () => {
        const { groomerTip, batherTip } = getTipAmounts()
        let sentArr: any = []
        if (parseFloat(groomerTip) > 0) {
            let obj = {
                numChargeID: null,
                numOrderID: 0,
                numInvoiceID: props.appDetails?.InvoiceID,
                numLocationID: props.appDetails?.LocationID,
                numSchID: null,
                numAppointmentID: props.appDetails?.numAppointmentID,
                numCustomerID: props.appDetails?.Customer?.numCustomerID,
                numPetID: props.appDetails?.Pet?.PetID,
                numPetTypeID: props.appDetails?.Pet?.PetTypeID,
                numPetSizeID: props.appDetails?.Pet?.numPetSizeID,
                numPetBreedID: props.appDetails?.Pet?.PetBreedID,
                bitAddOnService: null,
                varServiceOrProduct: "S",
                numServiceItemID: tipDetail?.serviceItemId,
                numProductID: null,
                varServiceProductDescription: tipDetail?.varServiceItemDescription,
                bitTaxableItem: null,
                numSalesTaxPercentage: null,
                numGroomerID: props.appDetails?.Employee?.EmployeeID,
                numBatherID: null,
                numQuantity: 1,
                decUnitPrice: 0,
                decChargeAmount: parseFloat(groomerTip),
                decTaxAmt: 0,
                dtBeginDateOfService: new Date().toISOString(),
                dtEndDateOfService: new Date().toISOString(),
                varStatus: "New",
                dtZeroBalDate: null,
                dtClosingDate: null,
                bitActive: true,
                numLocationServiceItemID: null,
                dtUpdatedStartTime: null,
                dtUpdatedEndTime: null,
                isTimerStarted: null,
                isSettled: null,
                numUserID: 1,
            }
            sentArr.push(obj)
        }
        if (parseFloat(batherTip) > 0) {
            let obj = {
                numChargeID: null,
                numOrderID: 0,
                numInvoiceID: props.appDetails?.InvoiceID,
                numLocationID: props.appDetails?.LocationID,
                numSchID: null,
                numAppointmentID: props.appDetails?.numAppointmentID,
                numCustomerID: props.appDetails?.Customer?.numCustomerID,
                numPetID: props.appDetails?.Pet?.PetID,
                numPetTypeID: props.appDetails?.Pet?.PetTypeID,
                numPetSizeID: props.appDetails?.Pet?.numPetSizeID,
                numPetBreedID: props.appDetails?.Pet?.PetBreedID,
                bitAddOnService: null,
                varServiceOrProduct: "S",
                numServiceItemID: tipDetail?.serviceItemId,
                numProductID: null,
                varServiceProductDescription: tipDetail?.varServiceItemDescription,
                bitTaxableItem: null,
                numSalesTaxPercentage: null,
                numGroomerID: null,
                numBatherID: props.appDetails?.Employee?.DealerID,
                numQuantity: 1,
                decUnitPrice: 0,
                decChargeAmount: parseFloat(batherTip),
                decTaxAmt: 0,
                dtBeginDateOfService: new Date().toISOString(),
                dtEndDateOfService: new Date().toISOString(),
                varStatus: "New",
                dtZeroBalDate: null,
                dtClosingDate: null,
                bitActive: true,
                numLocationServiceItemID: null,
                dtUpdatedStartTime: null,
                dtUpdatedEndTime: null,
                isTimerStarted: null,
                isSettled: null,
                numUserID: 1,
            }
            sentArr.push(obj)
        }

        console.log("Sent-", sentArr)
        saveTip(sentArr)
    }

    const { groomerTip, batherTip } = getTipAmounts()

    const handleGroomerPercentageChange = (e) => {
        const value = parseFloat(e.target.value)
        setGroomerPercentage(value)
        setBatherPercentage(100 - value)
    }

    const handleBatherPercentageChange = (e) => {
        const value = parseFloat(e.target.value)
        setBatherPercentage(value)
        setGroomerPercentage(100 - value)
    }

    const toggleCustomPercentage = () => {
        setIsCustomPercentage(!isCustomPercentage)
    }

    const saveGlobalPercentage = () => {
        if (groomerGlobalPercentage === 0 && batherGlobalPercentage === 0) {
            toast.warning("Please enter valid percentages for groomer and bather!")
        } else {
            if (groomerGlobalPercentage < 0 || groomerGlobalPercentage > 100 || batherGlobalPercentage < 0 || batherGlobalPercentage > 100) {
                toast.warning("Invalid global percentage value. Percentage should be between 0 and 100.")
                return
            }
            console.log("sent to global prec save", {
                locationID: props.appDetails?.LocationID,
                groomerTipPerc: groomerGlobalPercentage === 0 ? 0 : groomerGlobalPercentage,
                batherTipPerc: batherGlobalPercentage === 0 ? 0 : batherGlobalPercentage,
                numUserID: 1,
            })
            saveGlobalTipPercentage([
                {
                    locationID: props.appDetails?.LocationID,
                    groomerTipPerc: groomerGlobalPercentage === 0 ? 0 : groomerGlobalPercentage,
                    batherTipPerc: batherGlobalPercentage === 0 ? 0 : batherGlobalPercentage,
                    numUserID: 1,
                },
            ])
        }
    }

    return (
        <Modal open={props.isOpenTipModal} backgroundColor={"#F9FAFB"}>
            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                    <p style={{ fontSize: "24px", fontWeight: "600", color: "#192252" }}>Tips</p>
                    <CloseButton onClick={() => props.setIsOpenTipModal(false)} />
                </div>
                <div style={{ backgroundColor: "#fff", width: "100%", padding: "10px", display: "flex", flexDirection: "column", gap: "10px", height: "fit-content", overflowY: "scroll" }}>
                    <div className="modal-content">
                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                            <p style={{ fontSize: "14px", fontWeight: "600" }}>Total Amount : ${props?.totalAmount.toFixed(2)}</p>
                            <p style={{ color: "gray" }}>|</p>
                            <p style={{ fontSize: "14px", fontWeight: "600" }}>{props?.appDetails?.varLocationName}</p>
                        </div>

                        <div>
                            <p style={{ fontSize: "13px", fontWeight: "600" }}>Select Tip Percentage:</p>
                            <div style={{ display: "flex", gap: "10px", width: "600px", overflowY: "scroll" }}>
                                {percentageList.length < 1 ? (
                                    <p style={{ color: "gray", fontSize: "13px" }}>No percentages found for the appointment location </p>
                                ) : (
                                    percentageList
                                        .sort((a: any, b: any) => a.TipPerc - b.TipPerc)
                                        .map((percentage, index) => (
                                            <div
                                                key={index}
                                                onClick={() => {
                                                    handlePercentageClick(percentage?.TipPerc, index)
                                                }}
                                                style={
                                                    selectedIndex === index
                                                        ? { cursor: "pointer", padding: "10px", border: "1px solid #1976b4", borderRadius: "8px", color: "#1976b4", userSelect: "none" }
                                                        : { cursor: "pointer", padding: "10px", border: "1px solid #b7b7b7", borderRadius: "8px", userSelect: "none" }
                                                }
                                            >
                                                {percentage?.TipPerc}%
                                            </div>
                                        ))
                                )}
                            </div>
                        </div>

                        <div style={{ marginTop: "15px" }}>
                            <TextField
                                style={{ width: "60%" }}
                                label={<div style={{ display: "flex", fontSize: "13px" }}>Enter Custom Tip Amount or Percentage</div>}
                                type={"text"}
                                size="small"
                                variant="outlined"
                                multiline={false}
                                value={customTip}
                                onChange={handleCustomTipChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={toggleTipType}>
                                                {isPercentage ? <PercentIcon style={{ color: "#1976b4" }} /> : <AttachMoneyIcon style={{ color: "#1976b4" }} />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>

                        <Modal open={isOpenSetGlobalPercentage} backgroundColor={"#F9FAFB"}>
                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                    <p style={{ fontSize: "24px", fontWeight: "600", color: "#192252" }}>Setup Global Percentage</p>
                                    <CloseButton
                                        onClick={() => {
                                            props.setIsOpenTipModal(false)
                                            setIsOpenSetGlobalPercentage(false)
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        backgroundColor: "#fff",
                                        width: "100%",
                                        padding: "10px",
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "10px",
                                        height: "fit-content",
                                        overflowY: "scroll",
                                    }}
                                >
                                    <div style={{ display: "flex", gap: "10px" }}>
                                        <TextField
                                            style={{ width: "50%" }}
                                            label={<div style={{ display: "flex", fontSize: "15px", fontWeight: "500" }}>Groomer</div>}
                                            type={"text"}
                                            size="small"
                                            variant="outlined"
                                            multiline={false}
                                            value={groomerGlobalPercentage}
                                            onChange={(e) => {
                                                const value: any = e.target.value
                                                if (/^\d*\.?\d*$/.test(value)) {
                                                    setGroomerGlobalPercentage(value)
                                                    setBatherGlobalPercentage(100 - value)
                                                }
                                            }}
                                        />

                                        <TextField
                                            style={{ width: "50%" }}
                                            label={<div style={{ display: "flex", fontSize: "15px", fontWeight: "500" }}>Bather</div>}
                                            type={"text"}
                                            size="small"
                                            variant="outlined"
                                            multiline={false}
                                            value={batherGlobalPercentage}
                                            onChange={(e) => {
                                                const value: any = e.target.value
                                                if (/^\d*\.?\d*$/.test(value)) {
                                                    setBatherGlobalPercentage(value)
                                                    setGroomerGlobalPercentage(100 - value)
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "end", width: "100%", backgroundColor: "#F3F4F6", padding: "1rem", borderRadius: "0 0 12px 12px" }}>
                                    <div style={{ display: "flex", gap: "10px" }}>
                                        <Button variant="contained" color="primary" disabled={isLoading} onClick={saveGlobalPercentage}>
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        {props.appDetails.Employee.EmployeeID !== props.appDetails.Employee.DealerID ? (
                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                <p style={{ fontSize: "13px", fontWeight: "600" }}>Global Tip Percentage</p>

                                <Tooltip
                                    style={{ paddingLeft: "5px" }}
                                    title={
                                        <>
                                            <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Edit Global Tip Percentage</p>
                                        </>
                                    }
                                >
                                    <Switch checked={isCustomPercentage} onChange={toggleCustomPercentage} color="primary" />
                                </Tooltip>

                                <p style={{ fontSize: "13px", fontWeight: "600" }}>Custom Tip Percentage</p>
                            </div>
                        ) : null}
                        {props.appDetails.Employee.EmployeeID !== props.appDetails.Employee.DealerID ? (
                            isCustomPercentage ? (
                                <div style={{ display: "flex", gap: "10px" }}>
                                    <TextField
                                        style={{ width: "50%" }}
                                        label={<div style={{ display: "flex", fontSize: "15px", fontWeight: "500" }}>Groomer</div>}
                                        type={"number"}
                                        size="small"
                                        variant="outlined"
                                        multiline={false}
                                        value={groomerPercentage}
                                        onChange={handleGroomerPercentageChange}
                                    />
                                    <TextField
                                        style={{ width: "50%" }}
                                        label={<div style={{ display: "flex", fontSize: "15px", fontWeight: "500" }}>Bather</div>}
                                        type={"number"}
                                        size="small"
                                        variant="outlined"
                                        multiline={false}
                                        value={batherPercentage}
                                        onChange={handleBatherPercentageChange}
                                    />
                                </div>
                            ) : (
                                <div style={{ display: "flex", gap: "10px" }}>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px", background: "#93ffad", borderRadius: "8px", width: "50px" }}>
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <div style={{ fontWeight: "600" }}>{groomerPercentage + "%"}</div>
                                            <label style={{ fontSize: "14px", fontWeight: "400", color: "gray" }}>Groomer</label>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px", background: "#93ffad", borderRadius: "8px", width: "50px" }}>
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <div style={{ fontWeight: "600" }}>{batherPercentage + "%"}</div>
                                            <label style={{ fontSize: "14px", fontWeight: "400", color: "gray" }}>Bather</label>
                                        </div>
                                    </div>
                                </div>
                            )
                        ) : null}
                    </div>
                    <div>
                        {props.appDetails.Employee.EmployeeID === props.appDetails.Employee.DealerID ? (
                            <p style={{ fontSize: "14px", fontWeight: "500" }}>
                                Groomer: {props.appDetails.Employee.varEmployeeFirstName} {props.appDetails.Employee.varEmployeeLastName} - ${groomerTip}
                            </p>
                        ) : (
                            <>
                                <p style={{ fontSize: "14px", fontWeight: "500" }}>
                                    Groomer: {props.appDetails.Employee.varEmployeeFirstName} {props.appDetails.Employee.varEmployeeLastName} - ${groomerTip}
                                </p>
                                <p style={{ fontSize: "14px", fontWeight: "500" }}>
                                    Bather: {props.appDetails.Employee.varBatherFirstName} {props.appDetails.Employee.varBatherLastName} - ${batherTip}
                                </p>
                            </>
                        )}
                        {isPercentage ? "" : <p style={{ fontSize: "14px", fontWeight: "500" }}>Tip Amount: ${tipPercentage ? calculateTipAmount(tipPercentage) : customTip}</p>}
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "end", width: "100%", backgroundColor: "#F3F4F6", padding: "1rem", borderRadius: "0 0 12px 12px" }}>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <Button variant="outlined" color="default" disabled={isLoading} onClick={props.onClose}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" disabled={isLoading} onClick={handleSave}>
                            Save Tip
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default Tip
