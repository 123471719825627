import { Avatar, Button, Chip as LocationChip } from "../../../../../components"
import CancelModal from "../../../../../components/ExtModals/CancelModal"
import { ArrowForwardIos, CheckCircle, ContentCutOutlined, OpenInNewOutlined, Place, PrintOutlined, ReportProblem, ShoppingBasketOutlined } from "@mui/icons-material"
import CheckOutModal from "../../../../../components/ExtModals/CheckOutModal"
import GroomerNote from "../../../../../components/ExtModals/GroomerNote"
import CheckInModal from "../../../../../components/ExtModals/CheckInModal"
import VaccineModal from "../../../../../components/ExtModals/VaccineModal"
import Steps from "../../../../../TabletView/pages/Components/Steps/Steps"
import { Box, Grid, Stack, Step, StepLabel, Stepper, Tab, Tabs as MaterialTabs, Button as MatButton, Link, Tooltip, Chip } from "@mui/material"
import { useEffect, useState } from "react"

import { toast } from "react-toastify"
import CustomerInvoices from "../../../../../components/ExtModals/CustomerInvoices"
import { formatPhone } from "../../../../../utils/PhoneFormatter"
import { Truncate } from "../../../../../utils/Validation"
import { shortDate } from "../../../../../utils/DateFormatter"
import { generateSince } from "../../../../../utils/GenerateJoinedDate"
import { formatDateDisplay } from "../../../../../utils/TimeHandler"
import CheckInModalApp from "../../../../../components/ExtModals/CheckInModalApp"
import { axiosPrivate, buildHeaderObject } from "../../../../../apis/axios"
import useAuth from "../../../../../hooks/useAuth"

const ViewSummary = (props: { id: any }) => {
    const [summaryDetails, setSummaryDetails] = useState([]) as any

    const [isOpenCheckInOutModal, setIsOpenCheckInOutModal] = useState(false)
    const [isOpenCheckOutModal, setIsOpenCheckOutModal] = useState(false)
    const [isOpenCancelModal, setIsOpenCancelModal] = useState(false)
    const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false)
    const [isOpenVaccineModal, setIsOpenVaccineModal] = useState(false)
    const [appointmentServices, setAppointmentServices] = useState([]) as any
    const [appointmentProducts, setAppointmentProducts] = useState([]) as any

    const [paymentCustomerDetails, setPaymentCustomerDetails] = useState<any>({})

    const [totPaid, setTotPaid] = useState<any>(0)
    const [totTax, setTotTax] = useState(0) as any
    const [totRemain, setTotRemain] = useState<any>(0)
    const [remainingTotal, setRemainingTotal] = useState(0)

    const [viewSummary, setViewSummary] = useState(false)
    const { auth, setAuth } = useAuth() as any

    const [isOpenGroomerNote, setIsOpenGroomerNote] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [groomerNote, setGroomerNote] = useState<any>({})

    const [appointmentID, setAppointmentID] = useState("") as any
    const [appointmentCharges, setAppointmentCharges] = useState([]) as any

    const [value, setValue] = useState(0)
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    async function getAppointmentInfo(bookingID) {
        await axiosPrivate
            .get(`Appointment/GetAppointmnetByID?AppointmentID=${bookingID}`, buildHeaderObject(auth))
            .then((result) => {
                if (result.status === 200) {
                    setSummaryDetails(result.data.Appointment)
                    console.log("summary", result.data.Appointment)
                    setPaymentCustomerDetails({
                        numCustomerID: result.data.Appointment.Customer.numCustomerID,
                        varSalutation: result.data.Appointment.Customer.varSalutation,
                        varCustomerFirstName: result.data.Appointment.Customer.varCustomerFirstName,
                        varCustomerLastName: result.data.Appointment.Customer.varCustomerLastName,
                    })
                    setViewSummary(true)
                }
            })
            .catch((err) => {
                console.log("apps", err)
                toast.error("Something went wrong!")
            })
    }

    const getAppInfo = (bookingID) => {
        getAppointmentInfo(bookingID)
    }

    useEffect(() => {
        getAppInfo(props?.id)
    }, [props?.id])

    useEffect(() => {
        fetchInvoiceData(summaryDetails.numAppointmentID, "app")
    }, [summaryDetails])

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props

        return (
            <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
                {value === index && <p>{children}</p>}
            </div>
        )
    }

    const GetInvoiceDetails = async (id, type) => {
        let para: any = {}
        if (type === "inv") {
            para = {
                numInvoiceID: id,
            }
        } else {
            para = {
                numAppointmentID: id,
            }
        }
        console.log("sent params to fetch-", para)
        const apiUrl = `Payment/GetInvoiceDetails`

        try {
            const response = await axiosPrivate.get(apiUrl, buildHeaderObject(auth, false, false, false, para))
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    const fetchInvoiceData = (id, type) => {
        setIsLoading(true)
        if (id !== null && id !== undefined) {
            GetInvoiceDetails(id, type)
                .then((data) => {
                    console.log("res - summary-from-inq", data)
                    setAppointmentCharges(data)
                    setTotPaid(data[0].totalPaid)
                    setTotTax(data[0].taxTotal)
                    setIsLoading(false)
                    const appointmentServices: any = []
                    const appointmentProducts: any = []

                    data.forEach((item) => {
                        if (item.varServiceOrProduct === "S") {
                            appointmentServices.push(item)
                        } else if (item.varServiceOrProduct === "P") {
                            appointmentProducts.push(item)
                        }
                    })
                    setAppointmentProducts(appointmentProducts)
                    setAppointmentServices(appointmentServices)
                })
                .catch((error) => {
                    console.log("error", error)
                })
        } else {
            console.log("cancelled")
        }
    }

    const openGroomerNote = (index) => {
        setGroomerNote(summaryDetails.GroomerNotes[index])
        setIsOpenGroomerNote(true)
    }

    return (
        <>
            <Stack style={{ width: "90vw" }} spacing={3} p={"2rem 2rem"}>
                <div style={{ overflowY: "scroll", height: "600px" }}>
                    <Stack spacing={2}>
                        <Stack>
                            <Box
                                sx={{
                                    backgroundColor: "#f3f4f6",
                                    padding: "1rem 2rem",
                                    borderRadius: "8px 8px 0 0",
                                    border: "1px solid #F3F4F6",
                                }}
                            >
                                <Grid container>
                                    <Grid item xs>
                                        <Grid container spacing={5}>
                                            <Grid item>
                                                <Stack>
                                                    <p style={{ color: "#4B5563" }}>Booking ID #</p>
                                                    <p style={{ color: "#000", fontSize: "18px", fontWeight: "600" }}>#{summaryDetails?.numAppointmentID}</p>
                                                </Stack>
                                            </Grid>
                                            <Grid item>
                                                <Stack>
                                                    <p style={{ color: "#4B5563" }}>Salon</p>
                                                    <p style={{ color: "#000", fontSize: "18px", fontWeight: "600" }}>{summaryDetails?.varLocationName}</p>
                                                </Stack>
                                            </Grid>
                                            <Grid item>
                                                <Stack>
                                                    <p style={{ color: "#4B5563" }}>Service</p>
                                                    <p style={{ color: "#000", fontSize: "18px", fontWeight: "600" }}>
                                                        {summaryDetails?.Services !== undefined ? summaryDetails?.Services[0]?.varServiceItemName : "--"}
                                                    </p>
                                                </Stack>
                                            </Grid>
                                            <Grid item>
                                                <Stack>
                                                    <p style={{ color: "#4B5563" }}>Customer</p>
                                                    <p style={{ color: "#000", fontSize: "18px", fontWeight: "600" }}>
                                                        {summaryDetails?.Customer?.varCustomerFirstName} {summaryDetails?.Customer?.varCustomerLastName}
                                                    </p>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Box style={{ width: "100%" }}>
                                            <p style={{ color: "#4B5563" }}>Booking status</p>
                                            <p style={{ color: "#fff", backgroundColor: "#16A34A", borderRadius: "50px", width: "fit-content", padding: ".2rem .6rem" }}>
                                                {summaryDetails?.apptStatus}
                                            </p>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box
                                py={"1.5rem"}
                                px={"2rem"}
                                sx={{
                                    backgroundColor: "#fff",
                                    borderRadius: "0 0 8px 8px",
                                    border: "1px solid #F3F4F6",
                                }}
                            >
                                <Stack direction={"row"} justifyContent={"space-between"}>
                                    <Stack>
                                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <Button
                                                color="transparent"
                                                variant="outlined"
                                                disabled={summaryDetails?.apptStatus === "CHECKED-OUT" ? true : false}
                                                onClick={() => setIsOpenCancelModal(true)}
                                                text="Cancel Booking"
                                            />
                                            <CancelModal isOpenCancelModal={isOpenCancelModal} bookingData={summaryDetails} setIsOpenCancelModal={(value) => setIsOpenCancelModal(value)} />
                                            {summaryDetails.Pet !== undefined ? (
                                                summaryDetails.Pet.IsRabies === true ? (
                                                    <LocationChip
                                                        size="small"
                                                        customBackgroundColor={"#86EFAC"}
                                                        customBorder={"#86EFAC"}
                                                        customBorderRadius={"16px"}
                                                        fontColor={"#14532D"}
                                                        label={"Rabies vaccine given"}
                                                    />
                                                ) : (
                                                    <LocationChip
                                                        size="small"
                                                        customBackgroundColor={"#EB1A1A "}
                                                        customBorder={"#EB1A1A "}
                                                        customBorderRadius={"16px"}
                                                        fontColor={"#FFF"}
                                                        label={"Rabies vaccine not given or expired"}
                                                    />
                                                )
                                            ) : null}
                                        </div>
                                    </Stack>

                                    {summaryDetails.Pet !== undefined ? (
                                        summaryDetails.Pet.IsRabies === true ? (
                                            <Stack direction={"row"} spacing={2}>
                                                {summaryDetails?.IsPaymentMade === true ? (
                                                    <Tooltip
                                                        style={{ paddingLeft: "5px" }}
                                                        title={
                                                            <>
                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Payment made</p>
                                                            </>
                                                        }
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                flexDirection: "column",
                                                                padding: "1px 10px",
                                                                border: "1px solid #16a34a",
                                                                borderRadius: "8px",
                                                            }}
                                                        >
                                                            <CheckCircle style={{ color: "#16a34a", fontSize: "20px" }} />
                                                            <p style={{ margin: "0", color: "#16a34a" }}>Payment</p>
                                                        </div>
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip
                                                        style={{ paddingLeft: "5px" }}
                                                        title={
                                                            <>
                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>{`Pending balance of $${remainingTotal}`}</p>
                                                            </>
                                                        }
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                flexDirection: "column",
                                                                padding: "1px 10px",
                                                                border: "1px solid #d32f2f",
                                                                borderRadius: "8px",
                                                            }}
                                                        >
                                                            <ReportProblem style={{ color: "#d32f2f", fontSize: "20px" }} />
                                                            <p style={{ margin: "0", color: "#d32f2f" }}>Payment</p>
                                                        </div>
                                                    </Tooltip>
                                                )}

                                                {/* <Button color="info" variant="text" text="Reschedule" /> */}
                                                <Button onClick={() => setIsOpenCheckInOutModal(true)} color="primary" variant="contained" text="Check In" />
                                                <Button
                                                    onClick={() => setIsOpenCheckOutModal(true)}
                                                    disabled={summaryDetails?.IsReadyForPickUp ? false : true}
                                                    color={summaryDetails?.IsReadyForPickUp ? "primary" : "default"}
                                                    variant={summaryDetails?.IsReadyForPickUp ? "contained" : "outlined"}
                                                    text="Check Out"
                                                />
                                                <CheckOutModal
                                                    bookingDetails={summaryDetails}
                                                    isOpenCheckOutModal={isOpenCheckOutModal}
                                                    setIsOpenCheckOutModal={(value) => setIsOpenCheckOutModal(value)}
                                                    isSuccess={(e) => {
                                                        if (e === true) {
                                                            toast.success("Pet checked-out successfully")
                                                            getAppInfo(appointmentID)
                                                        }
                                                    }}
                                                    remainTotal={remainingTotal}
                                                    setIsOpenPaymentModal={(e) => {
                                                        if (e === true) {
                                                            setIsOpenPaymentModal(e)
                                                        }
                                                    }}
                                                />

                                                <CheckInModal
                                                    bookingDetails={summaryDetails}
                                                    isOpenCheckInModal={isOpenCheckInOutModal}
                                                    setIsOpenCheckInModal={(value) => setIsOpenCheckInOutModal(value)}
                                                    appProducts={appointmentProducts}
                                                    appServices={appointmentServices}
                                                    isSuccess={(e) => {}}
                                                />

                                                {Object.keys(summaryDetails).length !== 0 ? (
                                                    <GroomerNote
                                                        bookingDetails={summaryDetails}
                                                        isOpenGroomerNoteModal={isOpenGroomerNote}
                                                        setIsOpenGroomerNoteModal={(value) => setIsOpenGroomerNote(value)}
                                                        type={"show"}
                                                        NoteDetails={groomerNote}
                                                        isSuccess={() => {}}
                                                    />
                                                ) : null}
                                            </Stack>
                                        ) : (
                                            <Stack direction={"row"} spacing={2}>
                                                {summaryDetails?.IsPaymentMade === true ? (
                                                    <Tooltip
                                                        style={{ paddingLeft: "5px" }}
                                                        title={
                                                            <>
                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Payment made</p>
                                                            </>
                                                        }
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                flexDirection: "column",
                                                                padding: "1px 10px",
                                                                border: "1px solid #16a34a",
                                                                borderRadius: "8px",
                                                            }}
                                                        >
                                                            <CheckCircle style={{ color: "#16a34a", fontSize: "20px" }} />
                                                            <p style={{ margin: "0", color: "#16a34a" }}>Payment</p>
                                                        </div>
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip
                                                        style={{ paddingLeft: "5px" }}
                                                        title={
                                                            <>
                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>{`Pending balance of $${remainingTotal}`}</p>
                                                            </>
                                                        }
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                flexDirection: "column",
                                                                padding: "1px 10px",
                                                                border: "1px solid #d32f2f",
                                                                borderRadius: "8px",
                                                            }}
                                                        >
                                                            <ReportProblem style={{ color: "#d32f2f", fontSize: "20px" }} />
                                                            <p style={{ margin: "0", color: "#d32f2f" }}>Payment</p>
                                                        </div>
                                                    </Tooltip>
                                                )}

                                                {/* <Button color="info" variant="text" text="Reschedule" /> */}
                                                <Button onClick={() => setIsOpenCheckInOutModal(true)} color="primary" variant="contained" text="Check In" />
                                                <Button
                                                    onClick={() => setIsOpenCheckOutModal(true)}
                                                    disabled={summaryDetails?.IsReadyForPickUp ? false : true}
                                                    color={summaryDetails?.IsReadyForPickUp ? "primary" : "default"}
                                                    variant={summaryDetails?.IsReadyForPickUp ? "contained" : "outlined"}
                                                    text="Check Out"
                                                />
                                                <CheckOutModal
                                                    bookingDetails={summaryDetails}
                                                    isOpenCheckOutModal={isOpenCheckOutModal}
                                                    setIsOpenCheckOutModal={(value) => setIsOpenCheckOutModal(value)}
                                                    isSuccess={(e) => {
                                                        if (e === true) {
                                                            toast.success("Pet checked-out successfully")
                                                            getAppInfo(appointmentID)
                                                        }
                                                    }}
                                                    remainTotal={remainingTotal}
                                                    setIsOpenPaymentModal={(e) => {
                                                        if (e === true) {
                                                            setIsOpenPaymentModal(e)
                                                        }
                                                    }}
                                                />
                                                <Button
                                                    color="info"
                                                    variant="text"
                                                    text="Add Vaccine record"
                                                    onClick={() => {
                                                        setIsOpenVaccineModal(true)
                                                    }}
                                                />
                                                <CheckInModal
                                                    setIsOpenCheckInModal={(value) => setIsOpenCheckInOutModal(value)}
                                                    bookingDetails={summaryDetails}
                                                    isOpenCheckInModal={isOpenCheckInOutModal}
                                                    appProducts={appointmentProducts}
                                                    appServices={appointmentServices}
                                                    isSuccess={(e) => {}}
                                                />
                                                {Object.keys(summaryDetails).length !== 0 ? (
                                                    <GroomerNote
                                                        bookingDetails={summaryDetails}
                                                        isOpenGroomerNoteModal={isOpenGroomerNote}
                                                        setIsOpenGroomerNoteModal={(value) => setIsOpenGroomerNote(value)}
                                                        type={"show"}
                                                        NoteDetails={groomerNote}
                                                        isSuccess={() => {}}
                                                    />
                                                ) : null}
                                            </Stack>
                                        )
                                    ) : null}
                                </Stack>
                            </Box>
                        </Stack>
                    </Stack>

                    <VaccineModal
                        bookingDetails={summaryDetails}
                        isOpenVaccineModal={isOpenVaccineModal}
                        setIsOpenVaccineModal={(value) => setIsOpenVaccineModal(value)}
                        isSuccess={(e) => {
                            if (e === true) {
                                setIsOpenVaccineModal(false)
                            }
                        }}
                    />

                    <Stack
                        spacing={2}
                        sx={{
                            backgroundColor: "#fff",
                            padding: "1.5rem 2rem 3rem 2rem",
                            borderRadius: "8px",
                            border: "1px solid #F3F4F6",
                        }}
                    >
                        <p style={{ fontWeight: "600", fontSize: "18px" }}>Pet Tracking</p>

                        <Stack spacing={3}>
                            <Stack>
                                <Grid justifyContent={"space-between"} gap={2} container>
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "10px",
                                            boxShadow: "0px 4px 0px #EDF3F9",
                                            borderRadius: "8px 8px 0px 0px",
                                            padding: "10px",
                                            borderTop: "3px solid #2076FF",
                                        }}
                                    >
                                        <Avatar
                                            className={"medium"}
                                            shape="rounded"
                                            img={
                                                summaryDetails?.Pet?.PetImagePath == null
                                                    ? `${process.env.REACT_APP_MEDIA_URL}/directories/pets/${summaryDetails?.Pet?.PetImagePath}`
                                                    : `${process.env.REACT_APP_MEDIA_URL}/directories/pets/${summaryDetails?.Pet?.PetImagePath}`
                                            }
                                        />
                                        <div>
                                            <p style={{ fontSize: "16px", fontWeight: "600", color: "#002867" }}>{summaryDetails?.Pet?.PetName}</p>
                                            <p style={{ fontSize: "14px", fontWeight: "400", color: "#002867" }}>{summaryDetails?.Pet?.PetBreed}</p>
                                        </div>
                                    </div>

                                    <Grid item alignSelf={"center"}>
                                        <ArrowForwardIos style={{ color: "#D1D5DB", alignSelf: "center" }} />
                                    </Grid>

                                    <Grid
                                        item
                                        p={2}
                                        sx={{
                                            border: "1px solid #F9FAFB",
                                            boxShadow: "0px 4px 0px #EDF3F9",
                                            borderRadius: "12px",
                                        }}
                                        xs
                                    >
                                        <Steps statusSummary={summaryDetails} generalType={true} />
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Stack>
                    </Stack>

                    <Stack>
                        <Stack>
                            <Grid container spacing={3}>
                                <Grid item xs>
                                    <Box
                                        px={2}
                                        sx={{
                                            backgroundColor: "#fff",
                                            borderRadius: "8px",
                                            border: "1px solid #F3F4F6",
                                        }}
                                    >
                                        <MaterialTabs value={value} onChange={handleChange}>
                                            <Tab
                                                sx={{
                                                    textTransform: "capitalize",
                                                    padding: "0 3rem",
                                                }}
                                                label="Summary"
                                            />
                                            <Tab
                                                sx={{
                                                    textTransform: "capitalize",
                                                    padding: "0 3rem",
                                                }}
                                                label="Notes"
                                            />
                                        </MaterialTabs>

                                        <Box py={2}>
                                            <TabPanel value={value} index={0}>
                                                <Grid
                                                    container
                                                    justifyContent={"space-between"}
                                                    py={2}
                                                    px={4}
                                                    sx={{
                                                        backgroundColor: "#F9FAFB",
                                                        borderRadius: "4px",
                                                        border: "1px solid #F3F4F6",
                                                    }}
                                                >
                                                    <div style={{ display: "flex", gap: "10px" }}>
                                                        <Grid item>
                                                            <Link underline="hover" onClick={() => {}}>
                                                                <Stack direction={"row"} alignItems={"center"} sx={{ gap: ".5rem" }}>
                                                                    <ContentCutOutlined sx={{ color: "#4B5563", fontSize: "20px" }}></ContentCutOutlined>
                                                                    <p style={{ color: "#4B5563", fontWeight: "400", fontSize: "14px" }}>Edit services</p>
                                                                </Stack>
                                                            </Link>
                                                        </Grid>
                                                        <Grid item>
                                                            <Link underline="hover" onClick={() => {}}>
                                                                {/* // setIsOpenAddProductsModal(true) */}
                                                                <Stack direction={"row"} alignItems={"center"} sx={{ gap: ".5rem" }}>
                                                                    <ShoppingBasketOutlined sx={{ color: "#4B5563", fontSize: "24px" }}></ShoppingBasketOutlined>
                                                                    <p style={{ color: "#4B5563", fontWeight: "400", fontSize: "14px" }}>Edit products</p>
                                                                    {/* <AddProducts bookingDetails={summaryDetails} invoiceID={inv_ID} isOpenAddProductsModal={isOpenAddProductsModal} setIsOpenAddProductsModal={(value) =>setIsOpenAddProductsModal(value)}/> */}
                                                                </Stack>
                                                            </Link>
                                                        </Grid>
                                                    </div>

                                                    <Grid item>
                                                        <Grid container spacing={3}>
                                                            {/* <Grid item>
                                                                        <Link underline="hover" href="#" onClick={() => console.log('sd')}>
                                                                            <Stack direction={'row'} alignItems={'center'} sx={{ gap: '.5rem' }}>
                                                                                <AttachEmailOutlined sx={{ color: "#4B5563" }}></AttachEmailOutlined>
                                                                                <p style={{ color: '#4B5563', fontWeight: '400', fontSize: '14px' }}>Email Invoice</p>
                                                                            </Stack>
                                                                        </Link>
                                                                    </Grid> */}
                                                            <Grid item>
                                                                <Link underline="hover" href="#" onClick={() => {}}>
                                                                    <Stack direction={"row"} alignItems={"center"} sx={{ gap: ".5rem" }}>
                                                                        <PrintOutlined sx={{ color: "#4B5563" }}></PrintOutlined>
                                                                        <p style={{ color: "#4B5563", fontWeight: "400", fontSize: "14px" }}>Print Invoice</p>
                                                                    </Stack>
                                                                </Link>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <div style={{ padding: "0 15px" }}>
                                                    <p style={{ fontWeight: "600", fontSize: "14px" }}>Services</p>
                                                    <table style={{ fontSize: "14px", borderBottom: "1px solid #E5E7EB", paddingBottom: ".25rem", marginBottom: ".25rem", width: "100%" }}>
                                                        {appointmentServices !== undefined ? (
                                                            appointmentServices.length > 0 ? (
                                                                appointmentServices.map((items) => {
                                                                    return (
                                                                        <tr>
                                                                            <td style={{ width: "100%" }}>{items?.varServiceOrProductName}</td>
                                                                            <td style={{ textAlign: "right" }}>${items?.decChargeAmount}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            ) : (
                                                                <p>--</p>
                                                            )
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </table>

                                                    <p style={{ fontWeight: "600", fontSize: "14px", marginTop: "10px" }}>Products</p>
                                                    <table style={{ fontSize: "14px", borderBottom: "1px solid #E5E7EB", paddingBottom: ".25rem", marginBottom: ".25rem", width: "100%" }}>
                                                        {appointmentProducts !== undefined ? (
                                                            appointmentProducts.length > 0 ? (
                                                                appointmentProducts.map((items) => {
                                                                    return (
                                                                        <tr>
                                                                            <td style={{ width: "100%", display: "flex", alignItems: "center" }}>
                                                                                {items?.varServiceOrProductName}{" "}
                                                                                <p style={{ fontWeight: 500, color: "#6B7280", margin: "0" }}>&nbsp;({items?.numQuantity}x)</p>
                                                                            </td>
                                                                            <td style={{ textAlign: "right" }}>${items?.decChargeAmt}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            ) : (
                                                                <p>--</p>
                                                            )
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </table>
                                                    <table style={{ fontSize: "14px", width: "100%" }}>
                                                        <tr>
                                                            <td style={{ width: "100%", fontWeight: "500" }}>Tax</td>
                                                            <td style={{ width: "100%", fontSize: "14px", textAlign: "left", color: "#002867", fontWeight: "600" }}>
                                                                ${parseFloat(totTax.toString()).toFixed(2)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: "100%", fontWeight: "500" }}>Total Amount</td>
                                                            <td style={{ width: "100%", fontSize: "14px", textAlign: "left", color: "#002867", fontWeight: "600" }}>
                                                                ${parseFloat((totPaid + totRemain).toString()).toFixed(2)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: "100%", fontWeight: "500" }}>Paid Amount</td>
                                                            <td style={{ width: "100%", fontSize: "14px", textAlign: "left", color: "#002867", fontWeight: "600" }}>
                                                                ${parseFloat(totPaid.toString()).toFixed(2)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: "100%", fontWeight: "500" }}>Balance Amount</td>
                                                            <td style={{ width: "100%", fontSize: "14px", textAlign: "left", color: "#002867", fontWeight: "600" }}>
                                                                ${parseFloat(totRemain.toString()).toFixed(2)}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>

                                                <div style={{ paddingTop: "10px", display: "flex", justifyContent: "flex-end" }}>
                                                    <Button
                                                        onClick={() => setIsOpenPaymentModal(true)}
                                                        //disabled={parseFloat(totRemain) > 0 ? false : true}
                                                        color={parseFloat(totRemain) > 0 ? "primary" : "default"}
                                                        variant={parseFloat(totRemain) > 0 ? "contained" : "outlined"}
                                                        text="Make Payment"
                                                    />
                                                </div>

                                                <CustomerInvoices
                                                    isOpenCustomerInvoicesModal={isOpenPaymentModal}
                                                    setIsOpenCustomerInvoicesModal={(value) => {
                                                        console.log("ONKLOS", value)
                                                        setIsOpenPaymentModal(value)

                                                        if (value === false) {
                                                            fetchInvoiceData(summaryDetails.numAppointmentID, "app")
                                                            getAppInfo(summaryDetails.numAppointmentID)
                                                        }
                                                    }}
                                                    isPassedInvoice={false}
                                                    appointmentDetails={summaryDetails}
                                                    isSuccess={(e) => {
                                                        if (e === true) {
                                                        }
                                                    }}
                                                    isSuccessFullPayment={(e) => {}}
                                                    Refresh={(e) => {
                                                        if (e === true) {
                                                            fetchInvoiceData(summaryDetails.numAppointmentID, "app")
                                                        }
                                                    }}
                                                />
                                            </TabPanel>

                                            <TabPanel value={value} index={1}>
                                                <div style={{ padding: "0 15px" }}>
                                                    <p style={{ fontWeight: "600", fontSize: "14px" }}>Groomer Notes</p>
                                                    <div style={{ display: "flex", flexDirection: "column", gap: "10px", height: "200px", overflowY: "scroll" }}>
                                                        {summaryDetails?.GroomerNotes !== undefined ? (
                                                            summaryDetails?.GroomerNotes.length > 0 ? (
                                                                summaryDetails?.GroomerNotes.map((note, index) => {
                                                                    if (note.numGroomerNoteID !== 0) {
                                                                        return (
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "space-between",
                                                                                    backgroundColor: "#F9FAFB",
                                                                                    border: "1px solid #EFF3FA",
                                                                                    borderRadius: "8px",
                                                                                    padding: "10px",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <div>
                                                                                    <p style={{ fontWeight: "600", fontSize: "14px", color: "#192252" }}>{shortDate(note.dtCreatedDate)}</p>
                                                                                    <p style={{ fontWeight: "500", fontSize: "14px", color: "#606D93" }}>{note.varGroomerComment}</p>
                                                                                    <p style={{ fontWeight: "500", fontSize: "12px", color: "#606D93" }}>
                                                                                        {note.varCreatedEmployeeFirstName} {note.varCreatedEmployeeLastName}
                                                                                    </p>
                                                                                </div>
                                                                                <div>
                                                                                    <Button
                                                                                        color="transparent"
                                                                                        variant="text"
                                                                                        onClick={() => {
                                                                                            openGroomerNote(index)
                                                                                        }}
                                                                                    >
                                                                                        <OpenInNewOutlined style={{ color: "#2076FF", fontSize: "30px" }} />
                                                                                    </Button>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    } else {
                                                                        return <p style={{ fontWeight: "500", fontSize: "12px", color: "#606D93" }}>No groomer notes found</p>
                                                                    }
                                                                })
                                                            ) : (
                                                                <p style={{ fontWeight: "500", fontSize: "12px", color: "#606D93" }}>No groomer notes found</p>
                                                            )
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item xs={4}>
                                    <Box
                                        sx={{
                                            backgroundColor: "#fff",
                                            borderRadius: "8px",
                                            border: "1px solid #F3F4F6",
                                        }}
                                    >
                                        <div style={{ padding: "15px" }}>
                                            <div>
                                                <div style={{ display: "flex", gap: "1rem" }}>
                                                    <div>
                                                        <Avatar
                                                            className={"medium"}
                                                            img={
                                                                summaryDetails?.Customer?.varCustomerImagePath == null
                                                                    ? `${process.env.REACT_APP_MEDIA_URL}/directories/customers/${summaryDetails?.Customer?.varCustomerImagePath}`
                                                                    : `${process.env.REACT_APP_MEDIA_URL}/directories/customers/${summaryDetails?.Customer?.varCustomerImagePath}`
                                                            }
                                                            isVip={summaryDetails?.Customer?.bitIsVip}
                                                        />
                                                    </div>
                                                    <div style={{ width: "100%" }}>
                                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", paddingBottom: "5px" }}>
                                                            <p style={{ margin: "0", fontWeight: "600", fontSize: "16px" }}>
                                                                {summaryDetails?.Customer?.varCustomerFirstName} {summaryDetails?.Customer?.varCustomerLastName}
                                                            </p>
                                                            <Chip label={summaryDetails?.Customer?.bitActive} size={"small"} color="success" />
                                                        </div>
                                                        <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                                                            <LocationChip
                                                                label={summaryDetails?.varLocationName === undefined ? "" : Truncate(summaryDetails?.varLocationName, 19)}
                                                                shape="square"
                                                                icon={<Place style={{ fontSize: "13px" }} />}
                                                                customStyle="BlueFilled"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div
                                                        style={{
                                                            padding: "15px 0",
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            borderTop: "1px solid #F3F4F6",
                                                            borderBottom: "1px solid #F3F4F6",
                                                            marginBottom: "1rem",
                                                            marginTop: "1rem",
                                                        }}
                                                    >
                                                        <div>
                                                            <div style={{ fontWeight: 400, fontSize: 14, color: "#9CA3AF" }}>Mobile</div>
                                                            <div style={{ fontWeight: 600, fontSize: 14, color: "#111827" }}>{formatPhone(summaryDetails?.Customer?.varCustomerMobilePhone)}</div>
                                                        </div>

                                                        <div style={{ borderRight: "2px solid #F3F4F6" }}></div>
                                                        <div>
                                                            <div style={{ fontWeight: 400, fontSize: 14, color: "#9CA3AF" }}>Home</div>
                                                            <div style={{ fontWeight: 600, fontSize: 14, color: "#111827" }}>{formatPhone(summaryDetails?.Customer?.varCustomerWorkPhone)}</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div style={{ padding: "0 0 3px 0" }}>
                                                            <p style={{ fontWeight: 500, color: "#6B7280", margin: "0" }}>Member Since</p>
                                                            <p style={{ fontWeight: 600, color: "#000", margin: "0" }}>{generateSince(summaryDetails?.Customer?.dtCreatedDate)}</p>
                                                        </div>
                                                        <div style={{ padding: "0 0 0 0" }}>
                                                            <p style={{ fontWeight: 500, color: "#6B7280", margin: "0" }}>Customer ID</p>
                                                            <p style={{ fontWeight: 600, color: "#000", margin: "0" }}>{summaryDetails?.Customer?.numCustomerID}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Box>
                                    <Box
                                        sx={{
                                            marginTop: "10px",
                                        }}
                                    >
                                        <div style={{ padding: "20px", backgroundColor: "#EFF3FA", borderRadius: "8px", display: "flex", flexDirection: "column" }}>
                                            <p style={{ fontWeight: "600", fontSize: "16px", color: "#192252", margin: 0 }}>Assigned staff</p>
                                            {summaryDetails?.Employee?.EmployeeID === summaryDetails?.Employee?.DealerID ? (
                                                <div>
                                                    <div style={{ display: "flex", alignItems: "center", gap: "20px", padding: "15px 0 0 0" }}>
                                                        <p style={{ color: "#6B7280", fontSize: "14px", margin: "0" }}>Groomer</p>

                                                        <div style={{ display: "flex", gap: "10px", alignItems: "center", backgroundColor: "#fff", padding: "10px", borderRadius: "8px" }}>
                                                            <Avatar
                                                                isVip={false}
                                                                img={`${process.env.REACT_APP_MEDIA_URL}directories/employees/${
                                                                    summaryDetails.Employee != undefined ? summaryDetails.Employee.varEmployeeImagePath : null
                                                                }`}
                                                                className={"Medium"}
                                                            />
                                                            <div>
                                                                <p style={{ color: "#192252", fontWeight: "600", fontSize: "14px" }}>
                                                                    {summaryDetails.Employee != undefined
                                                                        ? summaryDetails?.Employee?.varEmployeeFirstName + " " + summaryDetails.Employee.varEmployeeLastName
                                                                        : null}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: "flex", gap: "10px", alignItems: "center", backgroundColor: "#fff", padding: "10px", borderRadius: "8px" }}>
                                                            <p style={{ fontSize: "13px", fontWeight: "500" }}>
                                                                Start : {formatDateDisplay(summaryDetails?.Employee?.DealerStartTime)} - End:{" "}
                                                                {formatDateDisplay(summaryDetails?.Employee?.EmployeeEndTime)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <div style={{ display: "flex", alignItems: "center", gap: "20px", padding: "15px 0 0 0" }}>
                                                        <p style={{ color: "#6B7280", fontSize: "14px", margin: "0" }}>Bather</p>

                                                        <div style={{ display: "flex", gap: "10px", alignItems: "center", backgroundColor: "#fff", padding: "10px", borderRadius: "8px" }}>
                                                            <Avatar
                                                                isVip={false}
                                                                img={`${process.env.REACT_APP_MEDIA_URL}directories/employees/${
                                                                    summaryDetails.Employee != undefined ? summaryDetails.Employee.DealerImagePath : null
                                                                }`}
                                                                className={"Medium"}
                                                            />
                                                            <div>
                                                                <p style={{ color: "#192252", fontWeight: "600", fontSize: "14px" }}>
                                                                    {summaryDetails.Employee != undefined
                                                                        ? summaryDetails?.Employee?.varBatherFirstName + " " + summaryDetails.Employee.varBatherLastName
                                                                        : null}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: "flex", gap: "10px", alignItems: "center", backgroundColor: "#fff", padding: "10px", borderRadius: "8px" }}>
                                                            <p style={{ fontSize: "13px", fontWeight: "500" }}>
                                                                Start : {formatDateDisplay(summaryDetails?.Employee?.DealerStartTime)} - End:{" "}
                                                                {formatDateDisplay(summaryDetails?.Employee?.DealerEndTime)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div style={{ display: "flex", alignItems: "center", gap: "20px", padding: "15px 0 0 0" }}>
                                                        <p style={{ color: "#6B7280", fontSize: "14px", margin: "0" }}>Groomer</p>

                                                        <div style={{ display: "flex", gap: "10px", alignItems: "center", backgroundColor: "#fff", padding: "10px", borderRadius: "8px" }}>
                                                            <Avatar
                                                                isVip={false}
                                                                img={`${process.env.REACT_APP_MEDIA_URL}directories/employees/${
                                                                    summaryDetails.Employee != undefined ? summaryDetails.Employee.varEmployeeImagePath : null
                                                                }`}
                                                                className={"Medium"}
                                                            />
                                                            <div>
                                                                <p style={{ color: "#192252", fontWeight: "600", fontSize: "14px" }}>
                                                                    {summaryDetails.Employee != undefined
                                                                        ? summaryDetails?.Employee?.varEmployeeFirstName + " " + summaryDetails.Employee.varEmployeeLastName
                                                                        : null}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: "flex", gap: "10px", alignItems: "center", backgroundColor: "#fff", padding: "10px", borderRadius: "8px" }}>
                                                            <p style={{ fontSize: "13px", fontWeight: "500" }}>
                                                                Start : {formatDateDisplay(summaryDetails?.Employee?.EmployeeStartTime)} - End:{" "}
                                                                {formatDateDisplay(summaryDetails?.Employee?.EmployeeEndTime)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Stack>
                </div>
            </Stack>
        </>
    )
}

export default ViewSummary
