import { useEffect, useState } from "react"
import { Modal } from "../components"
import { Button, CloseButton } from "../../components"
import { axiosPrivate, buildHeaderObject } from "../../apis/axios"
import { toast } from "react-toastify"
import useAuth from "../../hooks/useAuth"

function AssignBreedsToTemplates(props: { isOpenAssignBreedsToTemplatesModal; setIsAssignBreedsToTemplatesModal: CallableFunction; setIsBreedPriceParentModal: CallableFunction; payload }) {
    const { auth } = useAuth() as any

    const [eventList, setEventList] = useState<any>([])
    const [isLoading, setIsLoading] = useState(false)
    const [selectAll, setselectAll] = useState(false)

    async function fetchEvents(data) {
        setIsLoading(true)
        await axiosPrivate
            .post(`Schedule/GetEventDetailsForNewBreed`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                if (result.status == 200) {
                    if (result.data.length > 0) {
                        const uniqueTemplates = Object.values(
                            result?.data.reduce((acc, current) => {
                                if (!acc[current.TemplateID]) {
                                    acc[current.TemplateID] = {
                                        TemplateID: current.TemplateID,
                                        TemplateName: current.TemplateName,
                                        Checked: true,
                                        Events: [],
                                    }
                                }
                                acc[current.TemplateID].Events.push({
                                    EventID: current.EventID,
                                    EventName: current.EventName,
                                    EventColor: current.EventColor,
                                })
                                return acc
                            }, {})
                        )

                        console.log("temp", uniqueTemplates)

                        setEventList(uniqueTemplates)
                        setIsLoading(false)
                        //props.setIsBreedPriceParentModal(true)
                    }
                    setIsLoading(false)
                }
            })
            .catch((error: any) => {
                console.log("error", error)
                setIsLoading(false)
                //props.setIsBreedPriceParentModal(true)
            })
    }
    useEffect(() => {
        if (props.isOpenAssignBreedsToTemplatesModal === true) {
            fetchEvents(props.payload)
            console.log("payload", props.payload)
        }
    }, [props.isOpenAssignBreedsToTemplatesModal])

    // const selectAllEvents = () => {
    //     if (eventList.length > 0) {
    //         const updatedEventList = eventList.map(template => ({
    //             ...template,
    //             Checked: true
    //         }));
    //         setEventList(updatedEventList);
    //     }
    // };

    const applyChanges = () => {
        const templatesList: any = []
        const eventsList: any = []

        eventList.forEach((template) => {
            if (template.Checked) {
                templatesList.push({ templateID: template.TemplateID })
                template.Events.forEach((event) => {
                    eventsList.push({ eventID: event.EventID })
                })
            }
        })

        let sentObj = {
            locationID: props.payload?.locationID,
            numPetBreedID: props.payload?.numPetBreedID,
            eventPetTypes: props.payload?.eventPetTypes,
            eventPetSizes: props.payload?.eventPetSizes,
            eventServices: props.payload?.eventServices,
            eventList: eventsList,
            templateList: templatesList,
        }
        console.log("sent-final", sentObj)
        postTemplateEvents(sentObj)
    }

    async function postTemplateEvents(data) {
        await axiosPrivate
            .post(`Schedule/PopulateEventDetailsForNewBreed`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                if (result.status == 200) {
                    console.log(result)
                    toast.success(`Changes have been applied to selected template events!`)
                    setIsLoading(false)
                    props.setIsAssignBreedsToTemplatesModal(false)
                    props.setIsBreedPriceParentModal(true)
                }
            })
            .catch((error: any) => {
                console.log(error)
                setIsLoading(false)
            })
    }

    const closeModal = () => {
        setIsLoading(false)
        props.setIsAssignBreedsToTemplatesModal(false)
        props.setIsBreedPriceParentModal(true)
    }

    return (
        <Modal open={props?.isOpenAssignBreedsToTemplatesModal} backgroundColor={"#F9FAFB"}>
            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                <div style={{ display: "flex", justifyContent: "space-between", width: "500px", alignItems: "center" }}>
                    <p style={{ fontSize: "24px", fontWeight: "600", color: "#192252" }}>Assign Breed to Templates</p>
                    <CloseButton onClick={() => closeModal()} />
                </div>

                <div style={{ backgroundColor: "#fff", width: "100%", padding: "10px", display: "flex", flexDirection: "column", gap: "10px", height: "fit-content", overflowY: "scroll" }}>
                    <div style={{ display: "flex", flexDirection: "column", gap: "10px", minHeight: "200px", maxHeight: "300px", overflowY: "scroll" }}>
                        {isLoading ? (
                            <p style={{ fontSize: "12px", color: "gray", fontWeight: "500" }}>Loading...</p>
                        ) : eventList.length < 1 ? (
                            <p style={{ fontSize: "12px", color: "gray", fontWeight: "500" }}>No matching templates found</p>
                        ) : (
                            eventList.map((event, index) => {
                                return (
                                    <div style={{ display: "flex", flexDirection: "column", gap: "10px", border: "1px solid #d5d5d5", borderRadius: "8px", paddingBottom: "10px" }}>
                                        <div style={{ padding: "10px 10px 0 10px", display: "flex", alignItems: "center", gap: "10px" }}>
                                            <input
                                                type="checkbox"
                                                value={0}
                                                checked={event.Checked}
                                                onChange={() => {
                                                    const evList = [...eventList]
                                                    evList[index].Checked = !evList[index].Checked
                                                    setEventList(evList)
                                                }}
                                            />
                                            <p style={{ fontWeight: "500", fontSize: "14px" }}>{event.TemplateName}</p>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", gap: "10px", minHeight: "00px", maxHeight: "200px", overflowY: "scroll", marginLeft: "10px" }}>
                                            {event.Events.map((subEvent, eventIndex) => {
                                                return (
                                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                        <p style={{ margin: "0" }}>{subEvent?.EventName}</p>
                                                        <p style={{ backgroundColor: `${subEvent.EventColor}`, borderRadius: "50%", width: "16px", height: "16px", margin: "0" }}></p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })
                        )}
                    </div>
                </div>
                <div style={{ textAlign: "end", width: "100%", backgroundColor: "#F3F4F6", padding: "1rem", borderRadius: "0 0 12px 12px" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isLoading}
                        loading={isLoading}
                        onClick={() => {
                            applyChanges()
                        }}
                    >
                        Apply Changes
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default AssignBreedsToTemplates
