import { useEffect, useState } from "react"
import { Input, Button } from "../../../components"
import { toast } from "react-toastify"
import { checkPermission } from "../../../../utils/permissions"
import { axiosPrivate, axiosText, buildHeaderObject } from "../../../../apis/axios"
import { encrypt } from "../../../../utils/encrypt"
import Swal from "sweetalert2"
import { MenuItem, Select } from "@mui/material"
import useAuth from "../../../../hooks/useAuth"
function AccountSettings(props: { data?; type? }) {
    const [isCreateUser, setIsCreateUser] = useState(false)
    console.log(props.data)

    const [phoneState, setPhoneState] = useState("") as any

    const { auth, setAuth } = useAuth() as any

    const sendMessage = (typeNumber) => {
        let obj = {
            templateReference: "",
            bookingreference: 0,
            customerID: props?.data.numCustomerID,
            employeeID: 0,
            subject: "Password Reset",
            emailBody: "Please follow the following link to reset your password: https://customer.pet.esyntaxis.com/forgot-password",
            smsBody: "Please follow the following link to reset your password: https://customer.pet.esyntaxis.com/forgot-password",
            communicationMethod: typeNumber,
        }
        sendMessagePost(obj)
    }

    async function sendMessagePost(data) {
        await axiosPrivate
            .post(`Message/SendInstantMessage`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                if (result.status == 200) {
                    toast.success(`Message Sent Successfully!`)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    async function deactivateUser(data) {
        await axiosPrivate
            .put(`Auth/DeactivateUser?numUserID=${data?.id}`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                if (result.status === 200) {
                    toast.success("Customer deactivated successfully!")
                    props.data.bitActive = false
                }
            })
            .catch((err) => {
                console.log("Messaging -Err-", err)
            })
    }

    const handleDeactivate = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "Do you want to deactivate this customer?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, deactivate",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                deactivateUser({ id: props.data?.numUserID })
            }
        })
    }

    const phoneNumbers = [{ label: "Mobile", number: props?.data.varCustomerMobilePhone }].filter((phone) => phone.number && phone.number.trim() !== "")

    const sendEmail = () => {
        console.log(props.data)
        let userID = props?.data?.numUserID
        const encrypted = encrypt(userID.toString())

        let emailBody = `<!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <title>Reset Your Security Questions and Password</title>
            <style>
                body {
                    font-family: Arial, sans-serif;
                    margin: 0;
                    padding: 20px;
                    background-color: #f4f4f4;
                }
                .container {
                    background-color: #ffffff;
                    padding: 20px;
                    border-radius: 8px;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                    max-width: 600px;
                    margin: 0 auto;
                }
                .header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
                .logo {
                    max-width: 100px;
                    height: auto;
                }
                .content {
                    margin-top: 20px;
                }
                .button {
                    background-color: #28a745;
                    color: white;
                    padding: 10px 15px;
                    text-decoration: none;
                    border-radius: 5px;
                    display: inline-block;
                    margin-top: 20px;
                }
                .button:hover {
                    background-color: #218838;
                }
            </style>
        </head>
        <body>
            <div class="container">
                <div class="header">
                    <img src="https://test.esypet.net/static/media/esypet-logo.5f7c03070095ad942f20.png" alt="EsyPet Logo" class="logo" />
                </div>
                <div class="content">
                    <p><strong>Dear Valued Customer,</strong></p>
                    <p>We received a request to reset your security questions and password for your EsyPet account. If you made this request, please click the button below to update your security questions and set a new password:</p>
                    <a href="http://localhost:3000/reset-security-questions-password/${encrypted}" class="button">Reset Security Questions and Password</a>
                    <p>If you did not request to reset your security questions and password, please ignore this email or contact our support team if you have concerns.</p>
                    <p>Thank you for using EsyPet. We’re here to help keep your pet looking its best!</p>
                </div>
            </div>
        </body>
        </html>
        `

        let payload = {
            messageBody: [
                {
                    messageID: 1,
                    recipientID: props.data?.numCustomerID,
                    recipientType: "C",
                    senderID: props.data?.numCustomerID,
                    senderLocationID: props.data?.numCustomerDefaultLocationID,
                    subject: `Account activation`,
                    smsBody: emailBody,
                    emailBody: emailBody,
                    communicationMethod: "E",
                    sender: props.data?.varCustomerFirstName + "" + props.data?.varCustomerLastName,
                    senderLocation: props.data?.varCustomerDefaultLocation,
                    contactNumber: props.data?.varCustomerMobilePhone,
                    emailAddress: props.data?.varCustomerEmail,
                    appointmentID: null,
                    option: props.data?.varCustomerEmail === null ? 1 : 2,
                    sendDate: new Date().toISOString().split("T")[0],
                    messageType: "I",
                    workPhoneNumber: props.data?.varCustomerWorkPhone,
                    homePhoneNumber: props.data?.varCustomerHomePhone,
                    bitCustomerNotificationEmail: true,
                    numCustomerNotificationEmailSequence: 1,
                    bitCustomerNotificationHomePhone: false,
                    numCustomerNotificationHomePhoneSequence: 0,
                    bitCustomerNotificationMobilePhoneCall: false,
                    numCustomerNotificationMobilePhoneCallSequence: 0,
                    bitCustomerNotificationMobilePhoneSMS: false,
                    numCustomerNotificationMobilePhoneSMSSequence: 0,
                    bitCustomerNotificationWorkPhone: false,
                    numCustomerNotificationWorkPhoneSequence: 0,
                    messageTemplateID: null,
                    attachmentModel: [],
                },
            ],
        }
        console.log("payloadEMail", payload)
        sendOutMsg(payload)
    }

    const sendSMS = () => {
        if (!phoneState || phoneState.trim() === "") {
            toast.error("Please select a phone number.")
            return
        }

        console.log(props.data)
        let userID = props?.data?.numUserID
        const encrypted = encrypt(userID.toString())

        let smsBody = `Hi ${props.data?.varCustomerFirstName},\n\nWe received a request to reset your EsyPet account security questions and password. Click the link to reset: \n\nhttp://localhost:3000/reset-security-questions-password/${encrypted}\n\nIf you did not request this, please ignore this message.`

        let payload = {
            messageBody: [
                {
                    messageID: 1,
                    recipientID: props.data?.numCustomerID,
                    recipientType: "C",
                    senderID: props.data?.numCustomerID,
                    senderLocationID: props.data?.numCustomerDefaultLocationID,
                    subject: `Account reset request`,
                    smsBody: smsBody,
                    emailBody: "",
                    communicationMethod: "S",
                    sender: `${props.data?.varCustomerFirstName} ${props.data?.varCustomerLastName}`,
                    senderLocation: props.data?.varCustomerDefaultLocation,
                    contactNumber: phoneState,
                    emailAddress: "",
                    appointmentID: null,
                    option: 1,
                    sendDate: new Date().toISOString().split("T")[0],
                    messageType: "I",
                    workPhoneNumber: props.data?.varCustomerWorkPhone,
                    homePhoneNumber: props.data?.varCustomerHomePhone,
                    bitCustomerNotificationEmail: false,
                    numCustomerNotificationEmailSequence: 0,
                    bitCustomerNotificationHomePhone: false,
                    numCustomerNotificationHomePhoneSequence: 0,
                    bitCustomerNotificationMobilePhoneCall: false,
                    numCustomerNotificationMobilePhoneCallSequence: 0,
                    bitCustomerNotificationMobilePhoneSMS: true,
                    numCustomerNotificationMobilePhoneSMSSequence: 1,
                    bitCustomerNotificationWorkPhone: false,
                    numCustomerNotificationWorkPhoneSequence: 0,
                    messageTemplateID: null,
                    attachmentModel: [],
                },
            ],
        }

        console.log("payloadSMS", payload)
        sendOutMsg(payload)
    }

    async function sendOutMsg(data) {
        await axiosText
            .post(`UpdateMessages/UpdateMessagesPool`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                if (result.status === 200) {
                    console.log("Message sent to pool")
                    toast.success("Reset link sent successfully")
                }
            })
            .catch((err) => {
                console.log("Messaging -Err-", err)
            })
    }

    useEffect(() => {
        if (phoneNumbers.length > 0 && !phoneState) {
            setPhoneState(phoneNumbers[0].number)
        }
    }, [phoneNumbers, phoneState, setPhoneState])

    const [username, setUsername] = useState("username")
    return (
        <div>
            <p style={{ color: "#23262F", fontWeight: "600", fontSize: "14px" }}>Username</p>
            <div>
                <div style={{ display: "flex", gap: "1.5rem", marginBottom: "1rem" }}>
                    <div style={{ width: "320px" }}>
                        <Input
                            label={"Username"}
                            variant={"filled"}
                            value={props.type == "staff" ? props.data.varEmployeeEmail : props.data.varCustomerEmail}
                            size={"small"}
                            disabled
                            onChange={(e) => setUsername(e)}
                        />
                    </div>
                </div>
            </div>

            <p style={{ color: "#23262F", fontWeight: "600", fontSize: "14px", marginTop: "3rem" }}>Send Password Reset Link</p>
            <p style={{ color: "#777E90", fontWeight: "400", fontSize: "12px", width: "600px" }}>
                Send a link to the customer enabling the ability to change the password or create a new password if the customer is going to log in for the first time.
            </p>
            <div>
                <div style={{ display: "flex", gap: "1.5rem", marginBottom: "1rem", alignItems: "center" }}>
                    <div style={{ width: "320px" }}>
                        <Input variant={"filled"} size={"small"} label={"Email"} value={props.type == "staff" ? props.data.varEmployeeEmail : props.data.varCustomerEmail} />
                    </div>
                    <div>
                        <Button color="primary" variant="contained" onClick={() => sendEmail()}>
                            Send Via Email
                        </Button>
                    </div>
                </div>
                <div style={{ display: "flex", gap: "1.5rem", marginBottom: "1rem", alignItems: "center" }}>
                    <div style={{ width: "320px" }}>
                        <Select value={phoneState} onChange={(e) => setPhoneState(e.target.value)} variant="filled" size="small" fullWidth>
                            {phoneNumbers.map((phone, index) => (
                                <MenuItem key={index} value={phone.number}>
                                    {phone.label}: {phone.number}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div>
                        <Button color="primary" variant="contained" onClick={() => sendSMS()}>
                            Send Via SMS &nbsp;
                        </Button>
                    </div>
                </div>
            </div>
            {checkPermission(
                <>
                    <p style={{ color: "#23262F", fontWeight: "600", fontSize: "14px", marginTop: "3rem" }}>Deactivate Account</p>
                    <p style={{ color: "#777E90", fontWeight: "400", fontSize: "12px", width: "600px" }}>
                        Deactivating an account means temporarily disabling the account, so this account will not be displayed on search results or anywhere else but can be enabled anytime later.
                    </p>
                    <div>
                        <div style={{ display: "flex", gap: "1rem", marginBottom: "1rem", marginTop: "10px" }}>
                            <Button
                                color="danger"
                                variant="contained"
                                onClick={() => {
                                    handleDeactivate()
                                }}
                                disabled={props?.data.bitActive === false ? true : false}
                            >
                                Deactivate Account
                            </Button>
                        </div>
                    </div>
                </>,
                26
            )}
        </div>
    )
}

export default AccountSettings
