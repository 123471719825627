import React, { useContext, useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import styles from "./LabelConfiguration.module.scss"
import { Checkbox, TextField } from "@mui/material"

import BottomBar from "../../../components/BottomBar/BottomBar"

import { useParams } from "react-router-dom"
import { axiosPrivate, buildHeaderObject } from "../../../apis/axios"
import ValidatePermission from "../../../components/ValidatePermission/ValidatePermission"
import useAuth from "../../../hooks/useAuth"

function LabelConfiguration() {
    const { auth } = useAuth() as any

    const [standardList, setStandardList] = useState<
        | [
              {
                  numLabelID: number
                  varLabelName: string
                  bitRequired: boolean
                  varLabelType: StreamPipeOptions
              }[]
          ]
        | any
    >([])
    const [customList, setCustomList] = useState<
        | [
              {
                  numLabelID: number
                  varLabelName: string
                  bitRequired: boolean
                  bitVisible: boolean
                  varLabelType: StreamPipeOptions
              }[]
          ]
        | any
    >([])

    const [expanded, setExpanded] = useState<string | true>("panel1")
    const [loading, setLoading] = useState(false)
    const [uploading, setUploading] = useState(false)

    let { name } = useParams() as any

    const [payload, setPayload] = useState({
        labelsID: 1,
        labelsRequiredID: 1,
        varTblName: "Pet",
        standard: [],
        custom: [],
    })

    async function getList(objectName) {
        const result = await axiosPrivate.get(`Label/${objectName}`, buildHeaderObject(auth))

        return result.data
    }

    async function updateList() {
        setUploading(true)

        const newPayload = payload

        newPayload.varTblName = name
        newPayload.standard = standardList
        newPayload.custom = customList
        setPayload(newPayload)

        await axiosPrivate
            .put(`Label`, JSON.stringify(payload), buildHeaderObject(auth))
            .then((result) => {
                if (result.status == 200) {
                    setUploading(false)
                    toast.success("Changes have been made!")
                }
            })
            .catch((err) => {
                console.log(err)
                setUploading(false)
                toast.error("Something went wrong!")
            })
    }

    // useEffect(()=>{
    //   handleChange("panel1")
    // },[expanded])

    useEffect(() => {
        setLoading(true)
        setExpanded("panel1")
        if (name != undefined) {
            getList(name)
                .then((x) => {
                    if (x == undefined || x == null) {
                        alert("No results found!")
                        setStandardList([])
                        setCustomList([])
                    } else {
                        setStandardList(x.standard)
                        setCustomList(x?.custom)
                    }
                    setLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    setLoading(false)
                })
        }
        return () => {
            console.log("closing")
        }
    }, [name])

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : true)
    }

    const handleSubmit = (type) => {
        if (type == "submit") {
            updateList()
        }
    }

    return (
        <>
            <ValidatePermission allowedModules={["07.01 - Settings / Label Configuration"]} allowedSubRoutes={[name === "Location" ? "Salon" : name]}>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "100%", margin: "0 5rem 2.5rem" }}>
                        <div style={{ display: "flex" }}>
                            <div style={{ width: "84%" }}></div>

                            <div style={{ display: "flex", alignItems: "center", width: "8%" }}></div>

                            <div style={{ display: "flex", alignItems: "center", width: "8%" }}>
                                <p style={{ color: "rgba(0, 0, 0, 0.6)" }}>Required</p>
                            </div>
                        </div>

                        <Accordion style={{ boxShadow: "none", marginBottom: "5rem", borderBottom: "2px solid #D0D0D1" }} expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" className={styles.tabHeader}>
                                <Typography style={{ fontWeight: 500 }} color={"black"}>
                                    Predefined fields
                                </Typography>
                            </AccordionSummary>
                            <p style={{ textAlign: "center", fontSize: "20px", margin: 0 }}>{loading ? "LOADING..." : null}</p>
                            {standardList?.map((field, index) => {
                                return (
                                    <div key={index}>
                                        <AccordionDetails>
                                            <div style={{ display: "flex" }}>
                                                <div style={{ width: "84%" }}>
                                                    <TextField
                                                        style={{ width: "100%" }}
                                                        id="standard-search"
                                                        label={
                                                            <div style={{ display: "flex", fontSize: "14px" }}>
                                                                {field.varLabelName}
                                                                {field.bitRequired ? (
                                                                    <span
                                                                        style={{
                                                                            fontWeight: 500,
                                                                            backgroundColor: "#FDEDED",
                                                                            color: "#EF4444",
                                                                            fontSize: "12px",
                                                                            padding: "0 5px",
                                                                            marginLeft: "5px",
                                                                        }}
                                                                    >
                                                                        Required
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        }
                                                        onChange={(e) => {
                                                            const newTodos = [...standardList]
                                                            newTodos[index].varLabelValue = e.target.value
                                                            setStandardList(newTodos)
                                                        }}
                                                        focused={true}
                                                        type="text"
                                                        variant="standard"
                                                        value={field.varLabelValue}
                                                    />
                                                </div>

                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        width: "8%",
                                                    }}
                                                ></div>

                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        width: "8%",
                                                    }}
                                                >
                                                    <Checkbox
                                                        checked={field.bitRequired}
                                                        onChange={(e) => {
                                                            const newTodos = [...standardList]
                                                            newTodos[index].bitRequired = !newTodos[index].bitRequired
                                                            setStandardList(newTodos)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </div>
                                )
                            })}
                        </Accordion>

                        <div style={{ display: "flex", marginTop: ".8rem" }}>
                            <div style={{ width: "84%" }}></div>

                            <div style={{ display: "flex", alignItems: "center", width: "8%" }}>
                                <p style={{ color: "rgba(0, 0, 0, 0.6)" }}>Preview</p>
                            </div>

                            <div style={{ display: "flex", alignItems: "center", width: "8%" }}>
                                <p style={{ color: "rgba(0, 0, 0, 0.6)" }}>Required</p>
                            </div>
                        </div>

                        <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")} className={styles.accordionCustom} style={{ borderBottom: "2px solid #D0D0D1" }}>
                            <AccordionSummary className={styles.tabHeader} expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                                <Typography style={{ fontWeight: 500 }} color={"black"}>
                                    Custom fields
                                </Typography>
                            </AccordionSummary>
                            <p style={{ textAlign: "center", fontSize: "20px", margin: 0 }}>{loading ? "LOADING..." : null}</p>
                            {customList?.map((field, index) => {
                                return (
                                    <div key={index}>
                                        <AccordionDetails>
                                            <div style={{ display: "flex" }}>
                                                <div style={{ width: "84%" }}>
                                                    <TextField
                                                        style={{ width: "100%" }}
                                                        id="standard-search"
                                                        label={
                                                            <div style={{ display: "flex", fontSize: "14px" }}>
                                                                {field.varLabelName}
                                                                {field.bitRequired ? (
                                                                    <span
                                                                        style={{
                                                                            fontWeight: 500,
                                                                            backgroundColor: "#FDEDED",
                                                                            color: "#EF4444",
                                                                            fontSize: "12px",
                                                                            padding: "0 5px",
                                                                            marginLeft: "5px",
                                                                        }}
                                                                    >
                                                                        Required
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        }
                                                        onChange={(e) => {
                                                            const newTodos = [...customList]
                                                            newTodos[index].varLabelValue = e.target.value
                                                            setCustomList(newTodos)
                                                        }}
                                                        focused={true}
                                                        type="text"
                                                        variant="standard"
                                                        value={field.varLabelValue}
                                                    />
                                                </div>

                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        width: "8%",
                                                    }}
                                                >
                                                    <Checkbox
                                                        checked={field.bitVisible}
                                                        onChange={(e) => {
                                                            const newTodos = [...customList]
                                                            newTodos[index].bitVisible = !newTodos[index].bitVisible
                                                            setCustomList(newTodos)
                                                        }}
                                                    />
                                                </div>

                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        width: "8%",
                                                    }}
                                                >
                                                    <Checkbox
                                                        checked={field.bitRequired}
                                                        onChange={(e) => {
                                                            const newTodos = [...customList]
                                                            newTodos[index].bitRequired = !newTodos[index].bitRequired
                                                            setCustomList(newTodos)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </div>
                                )
                            })}
                        </Accordion>
                    </div>
                </div>

                {!loading ? <BottomBar loading={uploading} onClick={(type) => handleSubmit(type)} /> : null}
            </ValidatePermission>
        </>
    )
}

export default LabelConfiguration
