import { Cancel, MoreHoriz, RefreshOutlined, Star } from "@mui/icons-material"
import { CircularProgress, FormControlLabel, IconButton, Switch } from "@mui/material"
import { useEffect, useMemo, useRef, useState } from "react"
import { Chip } from "../../../../components/"
import { Modal, SearchBox, Button, Avatar, Input } from "../../../components"
import CloseButton from "../../../Modal/CloseButton/CloseButton"
import styles from "./Preferences.module.scss"
import debouce from "lodash.debounce"
import { toast } from "react-toastify"
import { axiosPrivate, buildHeaderObject } from "../../../../apis/axios"
import placeholder from "../../../../images/location_placeholder.png"
import { ChipGroup } from "../../../Table/components/ChipGroup/ChipGroup"
import AdvancedDropDown from "../../../AdvancedDropDown/AdvancedDropDown"
import { checkPermission, isActive } from "../../../../utils/permissions"
import useAuth from "../../../../hooks/useAuth"

export default function Preferences(props: { mode?; data?; type?; index? }) {
    const [cloneData, setCloneData] = useState({
        numPetDefaultGroomLocationID:
            props.type === "petProfile"
                ? props.data.numPetDefaultGroomLocationID
                : props.data?.customerPet.length > 0
                ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetDefaultGroomLocationID
                : "",
        varPetDefaultGroomLocation:
            props.type === "petProfile"
                ? props.data.varPetDefaultGroomLocation
                : props.data?.customerPet.length > 0
                ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetDefaultGroomLocation
                : "",
        numPetDefaultBoardLocationID:
            props.type === "petProfile"
                ? props.data.numPetDefaultBoardLocationID
                : props.data?.customerPet.length > 0
                ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetDefaultBoardLocationID
                : "",
        varPetDefaultBoardLocation:
            props.type === "petProfile"
                ? props.data.varPetDefaultBoardLocation
                : props.data?.customerPet.length > 0
                ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetDefaultBoardLocation
                : "",
        numPetDefaultDaycareLocationID:
            props.type === "petProfile"
                ? props.data.numPetDefaultDaycareLocationID
                : props.data?.customerPet.length > 0
                ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetDefaultDaycareLocationID
                : "",
        varPetDefaultDaycareLocation:
            props.type === "petProfile"
                ? props.data.varPetDefaultDaycareLocation
                : props.data?.customerPet.length > 0
                ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetDefaultDaycareLocation
                : "",
        numPetDefaultSittingLocationID:
            props.type === "petProfile"
                ? props.data.numPetDefaultSittingLocationID
                : props.data?.customerPet.length > 0
                ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetDefaultSittingLocationID
                : "",
        varPetDefaultSittingLocation:
            props.type === "petProfile"
                ? props.data.varPetDefaultSittingLocation
                : props.data?.customerPet.length > 0
                ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetDefaultSittingLocation
                : "",
        numPetDefaultTrainingLocationID:
            props.type === "petProfile"
                ? props.data.numPetDefaultTrainingLocationID
                : props.data?.customerPet.length > 0
                ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetDefaultTrainingLocationID
                : "",
        varPetDefaultTrainingLocation:
            props.type === "petProfile"
                ? props.data.varPetDefaultTrainingLocation
                : props.data?.customerPet.length > 0
                ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetDefaultTrainingLocation
                : "",
        numPetPreferredGroomerID:
            props.type === "petProfile"
                ? props.data.numPetPreferredGroomerID
                : props.data?.customerPet.length > 0
                ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetPreferredGroomerID
                : "",
        numPetPreferredBatherID:
            props.type === "petProfile"
                ? props.data.numPetPreferredBatherID
                : props.data?.customerPet.length > 0
                ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetPreferredBatherID
                : "",
        numPetID: props.type === "petProfile" ? props.data.numPetID : props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetID : "",
        bitActive: props.type === "petProfile" ? props.data.bitActive : props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].bitActive : "",
    })

    const [locationIsOpen, setLocationIsOpen] = useState(false)
    const [searchValue, setSearchValue] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [searchData, setSearchData] = useState([] as any)
    const [selectedLocation, setSelectedLocation] = useState<any>("")
    const [serviceType, setServiceType] = useState<any>("")
    const [prefGroomer, setPrefGroomer] = useState([] as any)
    const [prefBather, setPrefBather] = useState([] as any)
    const [prefGroomerOrg, setPrefGroomerOrg] = useState([] as any)
    const [prefBatherOrg, setPrefBatherOrg] = useState([] as any)
    const [openEmployee, setOpenEmployee] = useState(false)

    const [searchValueEmp, setSearchValueEmp] = useState("")
    const [advancedSearch, setAdvancedSearch] = useState(false)
    const [fSearch, setFSearch] = useState("")
    const [lSearch, setLSearch] = useState("")
    const [salon, setSalon] = useState("")
    const [empID, setEmpID] = useState("")
    const [searchDataEmp, setSearchDataEmp] = useState([] as any)
    const [selectedEmp, setSelectedEmp] = useState<any>("")
    const [employeeType, setEmployeeType] = useState<any>("")
    const [allLocations, setAllLocations] = useState<any>([])
    const myRef = useRef<any>()
    const SalonRef = useRef<any>()

    const { auth } = useAuth() as any

    const handleModalClose = () => {
        setLocationIsOpen(false)
        setOpenEmployee(false)
        setSearchData([])
        setSearchDataEmp([])
        setFSearch("")
        setLSearch("")
        setSalon("")
        setEmpID("")
        setSearchValue("")
        setAdvancedSearch(false)
        SalonRef.current?.handleClear()
    }

    const handleSearch = (value) => {
        setSearchValue(value)
    }

    const debouceSearch = useMemo(() => {
        return debouce(handleSearch, 500)
    }, [])

    useEffect(() => {
        if (searchValue != "") {
            fetchData(searchValue)
        }
    }, [searchValue])

    const fetchData = (searchQuery) => {
        setIsLoading(true)
        getData(searchQuery)
            .then((res) => {
                setSearchData(res.location)
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setIsLoading(false)

                toast.error("Something went wrong!")
                setSearchData([])
            })
    }

    const validateString = (input) => {
        return input === "" ? null : input
    }

    async function getData(searchQuery) {
        const result = await axiosPrivate.post(
            "Location/Search",
            JSON.stringify({
                page: 0,
                pageSize: 0,
                searchText: searchQuery,
            }),
            buildHeaderObject(auth)
        )
        return result.data
    }

    async function getAdvancedData() {
        setIsLoading(true)
        let payload = {
            varLocation: validateString(salon),
            occupation: [null],
            specialization: [null],
            numExpStRange: 0,
            numExpEndRange: 100,
            numAgeStRange: 0,
            numAgeEndRange: 100,
            varFirstName: validateString(fSearch),
            varLastName: validateString(lSearch),
            numEmplooyeeID: validateString(empID) == null ? null : parseInt(validateString(empID)),
        }
        console.log("payload", payload)
        const result = await axiosPrivate.post("Employee/AdvanceSearch", JSON.stringify(payload), buildHeaderObject(auth))

        return result.data
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (fSearch != "" || lSearch != "" || salon != "" || empID != "") {
                getAdvancedData()
                    .then((res) => {
                        setSearchDataEmp(res.employee)
                        setIsLoading(false)
                    })
                    .catch((err) => {
                        console.log(err)
                        setIsLoading(false)
                        setSearchData([])
                        toast.error("Something went wrong!")
                    })
            }
        }, 900)
        return () => clearTimeout(delayDebounceFn)
    }, [fSearch, lSearch, salon, empID])

    const selectAccount = (loc) => {
        setSelectedLocation(loc)
    }
    const selectAccountEmp = (emp) => {
        setSelectedEmp(emp)
    }

    const handleAdd = () => {
        if (Object.keys(selectedLocation).length < 1) {
            toast.info("Please select a location!")
            setSelectedLocation("")
            setLocationIsOpen(false)
        } else {
            if (serviceType == "Gro") {
                const editState: any = { ...cloneData }
                editState.numPetDefaultGroomLocationID = selectedLocation.numLocationID
                editState.varPetDefaultGroomLocation = selectedLocation.varLocationName
                setCloneData(editState)
            } else if (serviceType == "Bod") {
                const editState: any = { ...cloneData }
                editState.numPetDefaultBoardLocationID = selectedLocation.numLocationID
                editState.varPetDefaultBoardLocation = selectedLocation.varLocationName
                setCloneData(editState)
            } else if (serviceType == "Day") {
                const editState: any = { ...cloneData }
                editState.numPetDefaultDaycareLocationID = selectedLocation.numLocationID
                editState.varPetDefaultDaycareLocation = selectedLocation.varLocationName
                setCloneData(editState)
            } else if (serviceType == "Sit") {
                const editState: any = { ...cloneData }
                editState.numPetDefaultSittingLocationID = selectedLocation.numLocationID
                editState.varPetDefaultSittingLocation = selectedLocation.varLocationName
                setCloneData(editState)
            } else if (serviceType == "Tra") {
                const editState: any = { ...cloneData }
                editState.numPetDefaultTrainingLocationID = selectedLocation.numLocationID
                editState.varPetDefaultTrainingLocation = selectedLocation.varLocationName
                setCloneData(editState)
            }
            setSelectedLocation("")
            setLocationIsOpen(false)
        }
    }
    const handleRemove = (type) => {
        if (type == "Gro") {
            const editState: any = { ...cloneData }
            editState.numPetDefaultGroomLocationID = null
            editState.varPetDefaultGroomLocation = ""
            setCloneData(editState)
        } else if (type == "Bod") {
            const editState: any = { ...cloneData }
            editState.numPetDefaultBoardLocationID = null
            editState.varPetDefaultBoardLocation = ""
            setCloneData(editState)
        } else if (type == "Day") {
            const editState: any = { ...cloneData }
            editState.numPetDefaultDaycareLocationID = null
            editState.varPetDefaultDaycareLocation = ""
            setCloneData(editState)
        } else if (type == "Sit") {
            const editState: any = { ...cloneData }
            editState.numPetDefaultSittingLocationID = null
            editState.varPetDefaultSittingLocation = ""
            setCloneData(editState)
        } else if (type == "Tra") {
            const editState: any = { ...cloneData }
            editState.numPetDefaultTrainingLocationID = null
            editState.varPetDefaultTrainingLocation = ""
            setCloneData(editState)
        }
        setSelectedLocation("")
        setLocationIsOpen(false)
    }

    async function getPetByID() {
        setIsLoading(true)
        const result: any = await axiosPrivate.get(
            `Pet/GetByID/${
                props.type === "petProfile" ? props.data.numPetID : props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetID : ""
            }`,
            buildHeaderObject(auth)
        )
        return result
    }

    async function getPGroomer(id) {
        if (id != null) {
            setIsLoading(true)
            const result: any = await axiosPrivate.get(`Employee/GetByID/${id}`, buildHeaderObject(auth))
            return result
        } else {
            return 0
        }
    }

    async function getPBather(id) {
        if (id != null) {
            setIsLoading(true)
            const result: any = await axiosPrivate.get(`Employee/GetByID/${id}`, buildHeaderObject(auth))
            return result
        } else {
            return 0
        }
    }

    useEffect(() => {
        setCloneData({
            numPetDefaultGroomLocationID:
                props.type === "petProfile"
                    ? props.data.numPetDefaultGroomLocationID
                    : props.data?.customerPet.length > 0
                    ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetDefaultGroomLocationID
                    : "",
            varPetDefaultGroomLocation:
                props.type === "petProfile"
                    ? props.data.varPetDefaultGroomLocation
                    : props.data?.customerPet.length > 0
                    ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetDefaultGroomLocation
                    : "",
            numPetDefaultBoardLocationID:
                props.type === "petProfile"
                    ? props.data.numPetDefaultBoardLocationID
                    : props.data?.customerPet.length > 0
                    ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetDefaultBoardLocationID
                    : "",
            varPetDefaultBoardLocation:
                props.type === "petProfile"
                    ? props.data.varPetDefaultBoardLocation
                    : props.data?.customerPet.length > 0
                    ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetDefaultBoardLocation
                    : "",
            numPetDefaultDaycareLocationID:
                props.type === "petProfile"
                    ? props.data.numPetDefaultDaycareLocationID
                    : props.data?.customerPet.length > 0
                    ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetDefaultDaycareLocationID
                    : "",
            varPetDefaultDaycareLocation:
                props.type === "petProfile"
                    ? props.data.varPetDefaultDaycareLocation
                    : props.data?.customerPet.length > 0
                    ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetDefaultDaycareLocation
                    : "",
            numPetDefaultSittingLocationID:
                props.type === "petProfile"
                    ? props.data.numPetDefaultSittingLocationID
                    : props.data?.customerPet.length > 0
                    ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetDefaultSittingLocationID
                    : "",
            varPetDefaultSittingLocation:
                props.type === "petProfile"
                    ? props.data.varPetDefaultSittingLocation
                    : props.data?.customerPet.length > 0
                    ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetDefaultSittingLocation
                    : "",
            numPetDefaultTrainingLocationID:
                props.type === "petProfile"
                    ? props.data.numPetDefaultTrainingLocationID
                    : props.data?.customerPet.length > 0
                    ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetDefaultTrainingLocationID
                    : "",
            varPetDefaultTrainingLocation:
                props.type === "petProfile"
                    ? props.data.varPetDefaultTrainingLocation
                    : props.data?.customerPet.length > 0
                    ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetDefaultTrainingLocation
                    : "",
            numPetPreferredGroomerID:
                props.type === "petProfile"
                    ? props.data.numPetPreferredGroomerID
                    : props.data?.customerPet.length > 0
                    ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetPreferredGroomerID
                    : "",
            numPetPreferredBatherID:
                props.type === "petProfile"
                    ? props.data.numPetPreferredBatherID
                    : props.data?.customerPet.length > 0
                    ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetPreferredBatherID
                    : "",
            numPetID: props.type === "petProfile" ? props.data.numPetID : props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetID : "",
            bitActive: props.type === "petProfile" ? props.data.bitActive : props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].bitActive : "",
        })

        getPetByID()
            .then((response) => {
                let groomer = response.data.pet.numPetPreferredGroomerID
                let bather = response.data.pet.numPetPreferredBatherID
                getPGroomer(groomer)
                    .then((response) => {
                        if (response == 0) {
                            setPrefGroomer(null)
                            setPrefGroomerOrg(null)
                            setIsLoading(false)
                        } else {
                            setPrefGroomer(response?.data?.employee)
                            setPrefGroomerOrg(response?.data?.employee)
                            setIsLoading(false)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        toast.error("Something went wrong!")
                        setIsLoading(false)
                    })
                getPBather(bather)
                    .then((response) => {
                        if (response == 0) {
                            setPrefBather(null)
                            setPrefBatherOrg(null)
                            setIsLoading(false)
                        } else {
                            setPrefBather(response?.data?.employee)
                            setPrefBatherOrg(response?.data?.employee)
                            setIsLoading(false)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        toast.error("Something went wrong!")
                        setIsLoading(false)
                    })
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
                setIsLoading(false)
            })
        getLocations()
            .then((res) => {
                setAllLocations(res)
            })
            .catch((err) => {
                if (err.message != "canceled") {
                    console.log(err)
                }
            })
    }, [props.index, props.data])

    async function getLocations() {
        setIsLoading(true)
        const result = await axiosPrivate.get(`Location/GetAll`, buildHeaderObject(auth))
        return result.data.location
    }

    const toggleAdvanceSearch = () => {
        if (advancedSearch) {
            setAdvancedSearch(false)
        } else {
            setAdvancedSearch(true)
        }
    }
    useEffect(() => {
        if (advancedSearch == false) {
            setFSearch("")
            setLSearch("")
            setSalon("")
            setEmpID("")
        }
    }, [advancedSearch])

    const handleSearchEmployee = (value) => {
        setSearchValueEmp(value)
    }

    const debouceSearchEmployee = useMemo(() => {
        return debouce(handleSearchEmployee, 500)
    }, [])

    useEffect(() => {
        if (searchValueEmp != "") {
            fetchDataEmp(searchValueEmp)
        }
    }, [searchValueEmp])

    const fetchDataEmp = (searchQuery) => {
        setIsLoading(true)
        getDataEmp(searchQuery)
            .then((res) => {
                setSearchDataEmp(res.employee)
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setIsLoading(false)

                toast.error("Something went wrong!")
                setSearchDataEmp([])
            })
    }

    async function getDataEmp(searchQuery) {
        const result = await axiosPrivate.post(
            "Employee/Search",
            JSON.stringify({
                page: 0,
                pageSize: 0,
                searchText: searchQuery,
            }),
            buildHeaderObject(auth)
        )
        return result.data
    }

    const handleEmpAdd = () => {
        if (Object.keys(selectedEmp).length < 1) {
            toast.info("Please select a employee!")
            setSelectedEmp("")
            setOpenEmployee(false)
        } else {
            if (employeeType == "Gro") {
                const editState: any = { ...cloneData }
                editState.numPetPreferredGroomerID = selectedEmp.numEmployeeID
                setCloneData(editState)
                if (prefGroomer == null) {
                    let obj = {
                        numEmployeeID: selectedEmp.numEmployeeID,
                        varEmployeeImagePath: selectedEmp.varEmployeeImagePath,
                        varEmployeeFirstName: selectedEmp.varEmployeeFirstName,
                        varEmployeeLastName: selectedEmp.varEmployeeLastName,
                        varEmployeeCity: selectedEmp.varEmployeeCity,
                        varEmployeePerformanceCategory: selectedEmp.varEmployeePerformanceCategory,
                        varEmployeeRating: selectedEmp.varEmployeeRating,
                    }
                    setPrefGroomer(obj)
                } else {
                    const prefGroomerEdit: any = { ...prefGroomer }
                    prefGroomerEdit.numEmployeeID = selectedEmp.numEmployeeID
                    prefGroomerEdit.varEmployeeImagePath = selectedEmp.varEmployeeImagePath
                    prefGroomerEdit.varEmployeeFirstName = selectedEmp.varEmployeeFirstName
                    prefGroomerEdit.varEmployeeLastName = selectedEmp.varEmployeeLastName
                    prefGroomerEdit.varEmployeeCity = selectedEmp.varEmployeeCity
                    prefGroomerEdit.varEmployeePerformanceCategory = selectedEmp.varEmployeePerformanceCategory
                    prefGroomerEdit.varEmployeeRating = selectedEmp.varEmployeeRating
                    setPrefGroomer(prefGroomerEdit)
                }
            } else if (employeeType == "Bath") {
                const editState: any = { ...cloneData }
                editState.numPetPreferredBatherID = selectedEmp.numEmployeeID
                setCloneData(editState)
                if (prefGroomer == null) {
                    let obj = {
                        numEmployeeID: selectedEmp.numEmployeeID,
                        varEmployeeImagePath: selectedEmp.varEmployeeImagePath,
                        varEmployeeFirstName: selectedEmp.varEmployeeFirstName,
                        varEmployeeLastName: selectedEmp.varEmployeeLastName,
                        varEmployeeCity: selectedEmp.varEmployeeCity,
                        varEmployeePerformanceCategory: selectedEmp.varEmployeePerformanceCategory,
                        varEmployeeRating: selectedEmp.varEmployeeRating,
                    }
                    setPrefBather(obj)
                } else {
                    const prefBatherEdit: any = { ...prefBather }
                    prefBatherEdit.numEmployeeID = selectedEmp.numEmployeeID
                    prefBatherEdit.varEmployeeImagePath = selectedEmp.varEmployeeImagePath
                    prefBatherEdit.varEmployeeFirstName = selectedEmp.varEmployeeFirstName
                    prefBatherEdit.varEmployeeLastName = selectedEmp.varEmployeeLastName
                    prefBatherEdit.varEmployeeCity = selectedEmp.varEmployeeCity
                    prefBatherEdit.varEmployeePerformanceCategory = selectedEmp.varEmployeePerformanceCategory
                    prefBatherEdit.varEmployeeRating = selectedEmp.varEmployeeRating
                    setPrefBather(prefBatherEdit)
                }
            }

            setSelectedEmp("")
            setOpenEmployee(false)
        }
    }

    const submitData = () => {
        let obj = {
            pet: {
                numPetID: cloneData.numPetID,
                numPetPreferredBatherID: cloneData.numPetPreferredBatherID,
                numPetPreferredGroomerID: cloneData.numPetPreferredGroomerID,
                numPetDefaultTrainingLocationID: cloneData.numPetDefaultTrainingLocationID,
                numPetDefaultSittingLocationID: cloneData.numPetDefaultSittingLocationID,
                numPetDefaultDaycareLocationID: cloneData.numPetDefaultDaycareLocationID,
                numPetDefaultBoardLocationID: cloneData.numPetDefaultBoardLocationID,
                numPetDefaultGroomLocationID: cloneData.numPetDefaultGroomLocationID,
                bitActive: cloneData.bitActive,
            },
        }
        updateData(obj)
    }

    async function updateData(data) {
        await axiosPrivate
            .put(`Pet`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                if (result.status == 200) {
                    toast.success(`Preferences updated successfully!`)
                    getPetByID()
                        .then((response) => {
                            let groomer = response.data.pet.numPetPreferredGroomerID
                            let bather = response.data.pet.numPetPreferredBatherID
                            getPGroomer(groomer)
                                .then((response) => {
                                    if (response == 0) {
                                        setPrefGroomer(null)
                                        setPrefGroomerOrg(null)
                                        setIsLoading(false)
                                    } else {
                                        setPrefGroomer(response?.data?.employee)
                                        setPrefGroomerOrg(response?.data?.employee)
                                        setIsLoading(false)
                                    }
                                })
                                .catch((err) => {
                                    console.log(err)
                                    toast.error("Something went wrong!")
                                    setIsLoading(false)
                                })
                            getPBather(bather)
                                .then((response) => {
                                    if (response == 0) {
                                        setPrefBather(null)
                                        setPrefBatherOrg(null)
                                        setIsLoading(false)
                                    } else {
                                        setPrefBather(response?.data?.employee)
                                        setPrefBatherOrg(response?.data?.employee)
                                        setIsLoading(false)
                                    }
                                })
                                .catch((err) => {
                                    console.log(err)
                                    toast.error("Something went wrong!")
                                    setIsLoading(false)
                                })
                        })
                        .catch((err) => {
                            console.log(err)
                            toast.error("Something went wrong!")
                            setIsLoading(false)
                        })

                    if (props.type === "petProfile") {
                        props.data.numPetDefaultGroomLocationID = cloneData.numPetDefaultGroomLocationID
                        props.data.varPetDefaultGroomLocation = cloneData.varPetDefaultGroomLocation
                        props.data.numPetDefaultBoardLocationID = cloneData.numPetDefaultBoardLocationID
                        props.data.varPetDefaultBoardLocation = cloneData.varPetDefaultBoardLocation
                        props.data.numPetDefaultDaycareLocationID = cloneData.numPetDefaultDaycareLocationID
                        props.data.varPetDefaultDaycareLocation = cloneData.varPetDefaultDaycareLocation
                        props.data.numPetDefaultSittingLocationID = cloneData.numPetDefaultSittingLocationID
                        props.data.varPetDefaultSittingLocation = cloneData.varPetDefaultSittingLocation
                        props.data.numPetDefaultTrainingLocationID = cloneData.numPetDefaultTrainingLocationID
                        props.data.varPetDefaultTrainingLocation = cloneData.varPetDefaultTrainingLocation
                        props.data.numPetPreferredGroomerID = cloneData.numPetPreferredGroomerID
                        props.data.numPetPreferredBatherID = cloneData.numPetPreferredBatherID
                    } else {
                        props.data.customerPet[props.index].numPetDefaultGroomLocationID = cloneData.numPetDefaultGroomLocationID
                        props.data.customerPet[props.index].varPetDefaultGroomLocation = cloneData.varPetDefaultGroomLocation
                        props.data.customerPet[props.index].numPetDefaultBoardLocationID = cloneData.numPetDefaultBoardLocationID
                        props.data.customerPet[props.index].varPetDefaultBoardLocation = cloneData.varPetDefaultBoardLocation
                        props.data.customerPet[props.index].numPetDefaultDaycareLocationID = cloneData.numPetDefaultDaycareLocationID
                        props.data.customerPet[props.index].varPetDefaultDaycareLocation = cloneData.varPetDefaultDaycareLocation
                        props.data.customerPet[props.index].numPetDefaultSittingLocationID = cloneData.numPetDefaultSittingLocationID
                        props.data.customerPet[props.index].varPetDefaultSittingLocation = cloneData.varPetDefaultSittingLocation
                        props.data.customerPet[props.index].numPetDefaultTrainingLocationID = cloneData.numPetDefaultTrainingLocationID
                        props.data.customerPet[props.index].varPetDefaultTrainingLocation = cloneData.varPetDefaultTrainingLocation
                        props.data.customerPet[props.index].numPetPreferredGroomerID = cloneData.numPetPreferredGroomerID
                        props.data.customerPet[props.index].numPetPreferredBatherID = cloneData.numPetPreferredBatherID
                    }
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }
    const discardChanges = () => {
        setCloneData({
            numPetDefaultGroomLocationID:
                props.type === "petProfile"
                    ? props.data.numPetDefaultGroomLocationID
                    : props.data?.customerPet.length > 0
                    ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetDefaultGroomLocationID
                    : "",
            varPetDefaultGroomLocation:
                props.type === "petProfile"
                    ? props.data.varPetDefaultGroomLocation
                    : props.data?.customerPet.length > 0
                    ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetDefaultGroomLocation
                    : "",
            numPetDefaultBoardLocationID:
                props.type === "petProfile"
                    ? props.data.numPetDefaultBoardLocationID
                    : props.data?.customerPet.length > 0
                    ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetDefaultBoardLocationID
                    : "",
            varPetDefaultBoardLocation:
                props.type === "petProfile"
                    ? props.data.varPetDefaultBoardLocation
                    : props.data?.customerPet.length > 0
                    ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetDefaultBoardLocation
                    : "",
            numPetDefaultDaycareLocationID:
                props.type === "petProfile"
                    ? props.data.numPetDefaultDaycareLocationID
                    : props.data?.customerPet.length > 0
                    ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetDefaultDaycareLocationID
                    : "",
            varPetDefaultDaycareLocation:
                props.type === "petProfile"
                    ? props.data.varPetDefaultDaycareLocation
                    : props.data?.customerPet.length > 0
                    ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetDefaultDaycareLocation
                    : "",
            numPetDefaultSittingLocationID:
                props.type === "petProfile"
                    ? props.data.numPetDefaultSittingLocationID
                    : props.data?.customerPet.length > 0
                    ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetDefaultSittingLocationID
                    : "",
            varPetDefaultSittingLocation:
                props.type === "petProfile"
                    ? props.data.varPetDefaultSittingLocation
                    : props.data?.customerPet.length > 0
                    ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetDefaultSittingLocation
                    : "",
            numPetDefaultTrainingLocationID:
                props.type === "petProfile"
                    ? props.data.numPetDefaultTrainingLocationID
                    : props.data?.customerPet.length > 0
                    ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetDefaultTrainingLocationID
                    : "",
            varPetDefaultTrainingLocation:
                props.type === "petProfile"
                    ? props.data.varPetDefaultTrainingLocation
                    : props.data?.customerPet.length > 0
                    ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetDefaultTrainingLocation
                    : "",
            numPetPreferredGroomerID:
                props.type === "petProfile"
                    ? props.data.numPetPreferredGroomerID
                    : props.data?.customerPet.length > 0
                    ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetPreferredGroomerID
                    : "",
            numPetPreferredBatherID:
                props.type === "petProfile"
                    ? props.data.numPetPreferredBatherID
                    : props.data?.customerPet.length > 0
                    ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetPreferredBatherID
                    : "",
            numPetID: props.type === "petProfile" ? props.data.numPetID : props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetID : "",
            bitActive: props.type === "petProfile" ? props.data.bitActive : props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].bitActive : "",
        })
        setOpenEmployee(false)
        setLocationIsOpen(false)
        setSelectedEmp("")
        setSelectedLocation("")
        setSearchData([])
        setSearchDataEmp([])
        setFSearch("")
        setLSearch("")
        setSalon("")
        setEmpID("")
        setSearchValue("")
        setAdvancedSearch(false)
        SalonRef.current?.handleClear()

        if (prefGroomerOrg == null) {
            setPrefGroomer(null)
        } else {
            setPrefGroomer(prefGroomerOrg)
        }
        if (prefBatherOrg == null) {
            setPrefBather(null)
        } else {
            setPrefBather(prefBatherOrg)
        }
    }

    useEffect(() => {
        console.log("CloneData", cloneData)
    }, [cloneData])

    return (
        <div>
            <p style={{ color: "#23262F", fontWeight: "600", fontSize: "14px" }}>Preferences Locations</p>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: "6px", alignItems: props.mode ? "center" : "baseline" }}>
                <div style={{ width: "96px" }}>
                    <p style={{ color: "#6B7280", fontWeight: "400", fontSize: "14px" }}>Grooming</p>
                </div>
                {props.mode ? (
                    cloneData.numPetDefaultGroomLocationID == 0 || cloneData.numPetDefaultGroomLocationID == null ? (
                        checkPermission(
                            <p
                                style={{ color: "#2076FF", fontWeight: "500", fontSize: "14px", cursor: "pointer" }}
                                onClick={() => {
                                    {
                                        setLocationIsOpen(true)
                                    }
                                    {
                                        setServiceType("Gro")
                                    }
                                }}
                            >
                                Add Location
                            </p>,
                            43
                        )
                    ) : (
                        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                            <Chip
                                label={cloneData.varPetDefaultGroomLocation}
                                shape="round"
                                fontColor={"#ffff"}
                                customStyle="Primary"
                                fontWeight={400}
                                fontSize={"12px"}
                                size={"small"}
                                onClick={
                                    isActive(43) === true
                                        ? () => {
                                              handleRemove("Gro")
                                          }
                                        : undefined
                                }
                                icon={isActive(43) === true ? <Cancel /> : undefined}
                            />
                            {checkPermission(
                                <p
                                    style={{ color: "#2076FF", fontWeight: "500", fontSize: "14px", cursor: "pointer" }}
                                    onClick={() => {
                                        {
                                            setLocationIsOpen(true)
                                        }
                                        {
                                            setServiceType("Gro")
                                        }
                                    }}
                                >
                                    Add Location
                                </p>,
                                43
                            )}
                        </div>
                    )
                ) : cloneData.numPetDefaultGroomLocationID != 0 && cloneData.numPetDefaultGroomLocationID != null ? (
                    <div style={{ marginTop: "6px" }}>
                        <Chip label={cloneData.varPetDefaultGroomLocation} shape="round" fontColor={"#ffff"} customStyle="Primary" fontWeight={400} fontSize={"12px"} size={"small"} />
                    </div>
                ) : (
                    <p style={{ fontSize: "12px", fontWeight: "400", color: "rgb(107, 114, 128)" }}>N/A</p>
                )}
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: "6px", alignItems: props.mode ? "center" : "baseline" }}>
                <div style={{ width: "96px" }}>
                    <p style={{ color: "#6B7280", fontWeight: "400", fontSize: "14px" }}>Boarding</p>
                </div>
                {props.mode ? (
                    cloneData.numPetDefaultBoardLocationID == 0 || cloneData.numPetDefaultBoardLocationID == null ? (
                        checkPermission(
                            <p
                                style={{ color: "#2076FF", fontWeight: "500", fontSize: "14px", cursor: "pointer" }}
                                onClick={() => {
                                    {
                                        setLocationIsOpen(true)
                                    }
                                    {
                                        setServiceType("Bod")
                                    }
                                }}
                            >
                                Add Location
                            </p>,
                            44
                        )
                    ) : (
                        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                            <Chip
                                label={cloneData.varPetDefaultBoardLocation}
                                shape="round"
                                fontColor={"#ffff"}
                                customStyle="Primary"
                                fontWeight={400}
                                fontSize={"12px"}
                                size={"small"}
                                onClick={
                                    isActive(44) === true
                                        ? () => {
                                              handleRemove("Bod")
                                          }
                                        : undefined
                                }
                                icon={isActive(44) === true ? <Cancel /> : undefined}
                            />
                            {checkPermission(
                                <p
                                    style={{ color: "#2076FF", fontWeight: "500", fontSize: "14px", cursor: "pointer" }}
                                    onClick={() => {
                                        {
                                            setLocationIsOpen(true)
                                        }
                                        {
                                            setServiceType("Bod")
                                        }
                                    }}
                                >
                                    Add Location
                                </p>,
                                44
                            )}
                        </div>
                    )
                ) : cloneData.numPetDefaultBoardLocationID != 0 && cloneData.numPetDefaultBoardLocationID != null ? (
                    <div style={{ marginTop: "6px" }}>
                        <Chip label={cloneData.varPetDefaultBoardLocation} shape="round" fontColor={"#ffff"} customStyle="Primary" fontWeight={400} fontSize={"12px"} size={"small"} />
                    </div>
                ) : (
                    <p style={{ fontSize: "12px", fontWeight: "400", color: "rgb(107, 114, 128)" }}>N/A</p>
                )}
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: "6px", alignItems: props.mode ? "center" : "baseline" }}>
                <div style={{ width: "96px" }}>
                    <p style={{ color: "#6B7280", fontWeight: "400", fontSize: "14px" }}>Day Care</p>
                </div>
                {props.mode ? (
                    cloneData.numPetDefaultDaycareLocationID == 0 || cloneData.numPetDefaultDaycareLocationID == null ? (
                        checkPermission(
                            <p
                                style={{ color: "#2076FF", fontWeight: "500", fontSize: "14px", cursor: "pointer" }}
                                onClick={() => {
                                    {
                                        setLocationIsOpen(true)
                                    }
                                    {
                                        setServiceType("Day")
                                    }
                                }}
                            >
                                Add Location
                            </p>,
                            45
                        )
                    ) : (
                        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                            <Chip
                                label={cloneData.varPetDefaultDaycareLocation}
                                shape="round"
                                fontColor={"#ffff"}
                                customStyle="Primary"
                                fontWeight={400}
                                fontSize={"12px"}
                                size={"small"}
                                onClick={
                                    isActive(45) === true
                                        ? () => {
                                              handleRemove("Day")
                                          }
                                        : undefined
                                }
                                icon={isActive(45) === true ? <Cancel /> : undefined}
                            />
                            {checkPermission(
                                <p
                                    style={{ color: "#2076FF", fontWeight: "500", fontSize: "14px", cursor: "pointer" }}
                                    onClick={() => {
                                        {
                                            setLocationIsOpen(true)
                                        }
                                        {
                                            setServiceType("Day")
                                        }
                                    }}
                                >
                                    Add Location
                                </p>,
                                45
                            )}
                        </div>
                    )
                ) : cloneData.numPetDefaultDaycareLocationID != 0 && cloneData.numPetDefaultDaycareLocationID != null ? (
                    <div style={{ marginTop: "6px" }}>
                        <Chip label={cloneData.varPetDefaultDaycareLocation} shape="round" fontColor={"#ffff"} customStyle="Primary" fontWeight={400} fontSize={"12px"} size={"small"} />
                    </div>
                ) : (
                    <p style={{ fontSize: "12px", fontWeight: "400", color: "rgb(107, 114, 128)" }}>N/A</p>
                )}
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: "6px", alignItems: props.mode ? "center" : "baseline" }}>
                <div style={{ width: "96px" }}>
                    <p style={{ color: "#6B7280", fontWeight: "400", fontSize: "14px" }}>Sitting</p>
                </div>
                {props.mode ? (
                    cloneData.numPetDefaultSittingLocationID == 0 || cloneData.numPetDefaultSittingLocationID == null ? (
                        checkPermission(
                            <p
                                style={{ color: "#2076FF", fontWeight: "500", fontSize: "14px", cursor: "pointer" }}
                                onClick={() => {
                                    {
                                        setLocationIsOpen(true)
                                    }
                                    {
                                        setServiceType("Sit")
                                    }
                                }}
                            >
                                Add Location
                            </p>,
                            46
                        )
                    ) : (
                        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                            <Chip
                                label={cloneData.varPetDefaultSittingLocation}
                                shape="round"
                                fontColor={"#ffff"}
                                customStyle="Primary"
                                fontWeight={400}
                                fontSize={"12px"}
                                size={"small"}
                                onClick={
                                    isActive(46) === true
                                        ? () => {
                                              handleRemove("Sit")
                                          }
                                        : undefined
                                }
                                icon={isActive(46) === true ? <Cancel /> : undefined}
                            />
                            {checkPermission(
                                <p
                                    style={{ color: "#2076FF", fontWeight: "500", fontSize: "14px", cursor: "pointer" }}
                                    onClick={() => {
                                        {
                                            setLocationIsOpen(true)
                                        }
                                        {
                                            setServiceType("Sit")
                                        }
                                    }}
                                >
                                    Add Location
                                </p>,
                                46
                            )}
                        </div>
                    )
                ) : cloneData.numPetDefaultSittingLocationID != 0 && cloneData.numPetDefaultSittingLocationID != null ? (
                    <div style={{ marginTop: "6px" }}>
                        <Chip label={cloneData.varPetDefaultSittingLocation} shape="round" fontColor={"#ffff"} customStyle="Primary" fontWeight={400} fontSize={"12px"} size={"small"} />
                    </div>
                ) : (
                    <p style={{ fontSize: "12px", fontWeight: "400", color: "rgb(107, 114, 128)" }}>N/A</p>
                )}
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: "6px", alignItems: props.mode ? "center" : "baseline" }}>
                <div style={{ width: "96px" }}>
                    <p style={{ color: "#6B7280", fontWeight: "400", fontSize: "14px" }}>Training</p>
                </div>
                {props.mode ? (
                    cloneData.numPetDefaultTrainingLocationID == 0 || cloneData.numPetDefaultTrainingLocationID == null ? (
                        checkPermission(
                            <p
                                style={{ color: "#2076FF", fontWeight: "500", fontSize: "14px", cursor: "pointer" }}
                                onClick={() => {
                                    {
                                        setLocationIsOpen(true)
                                    }
                                    {
                                        setServiceType("Tra")
                                    }
                                }}
                            >
                                Add Location
                            </p>,
                            47
                        )
                    ) : (
                        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                            <Chip
                                label={cloneData.varPetDefaultTrainingLocation}
                                shape="round"
                                fontColor={"#ffff"}
                                customStyle="Primary"
                                fontWeight={400}
                                fontSize={"12px"}
                                size={"small"}
                                onClick={
                                    isActive(47) === true
                                        ? () => {
                                              handleRemove("Tra")
                                          }
                                        : undefined
                                }
                                icon={isActive(47) === true ? <Cancel /> : undefined}
                            />
                            {checkPermission(
                                <p
                                    style={{ color: "#2076FF", fontWeight: "500", fontSize: "14px", cursor: "pointer" }}
                                    onClick={() => {
                                        {
                                            setLocationIsOpen(true)
                                        }
                                        {
                                            setServiceType("Tra")
                                        }
                                    }}
                                >
                                    Add Location
                                </p>,
                                47
                            )}
                        </div>
                    )
                ) : cloneData.numPetDefaultTrainingLocationID != 0 && cloneData.numPetDefaultTrainingLocationID != null ? (
                    <div style={{ marginTop: "6px" }}>
                        <Chip label={cloneData.varPetDefaultTrainingLocation} shape="round" fontColor={"#ffff"} customStyle="Primary" fontWeight={400} fontSize={"12px"} size={"small"} />
                    </div>
                ) : (
                    <p style={{ fontSize: "12px", fontWeight: "400", color: "rgb(107, 114, 128)" }}>N/A</p>
                )}
            </div>
            {locationIsOpen ? (
                <Modal open={locationIsOpen}>
                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem", width: "700px" }}>
                        <div style={{ textAlign: "end", width: "100%" }}>
                            <CloseButton
                                onClick={() => {
                                    handleModalClose()
                                }}
                            />
                        </div>
                        <div style={{ backgroundColor: "#fff", display: "flex", justifyContent: "space-between", width: "100%" }}>
                            <div style={{ width: "237px" }}>
                                {allLocations !== undefined && allLocations !== null ? (
                                    <AdvancedDropDown
                                        data={allLocations.map((i) => ({ label: i.varLocationName, value: i.numLocationID }))}
                                        onChange={(e) => {
                                            setSearchValue(e?.label)
                                        }}
                                        ref={myRef}
                                        placeHolder={"Locations"}
                                    />
                                ) : null}
                            </div>
                        </div>
                        <div
                            className={styles.gridContainer}
                            style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "1rem", rowGap: "1ch", marginTop: "0.6rem", paddingBottom: "1rem" }}
                        >
                            {isLoading ? (
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <CircularProgress />
                                </div>
                            ) : searchData.length > 0 ? (
                                searchData.map((loc, index) => {
                                    const selectedUser = selectedLocation
                                    let isIn
                                    if (selectedUser.numLocationID == loc.numLocationID.toString()) {
                                        isIn = true
                                    } else {
                                        isIn = false
                                    }

                                    return (
                                        <div
                                            onClick={() => {
                                                selectAccount(loc)
                                            }}
                                            style={{ margin: "0 .5rem .5rem 0" }}
                                        >
                                            <div className={isIn ? styles.locationCardChecked : styles.locationCard}>
                                                <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                                                    <div style={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
                                                        <img
                                                            style={{ maxWidth: "120px", borderRadius: "10px" }}
                                                            src={loc.varLocationImagePath == null ? placeholder : `${process.env.REACT_APP_S3_URL}/directories/Location/${loc.varLocationImagePath}`}
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                            <div className={styles.clientContainer}>
                                                                <div className={styles.avatarContainer} style={{ borderBottom: "1px solid #e2e2e2", paddingBottom: "8px" }}>
                                                                    <div style={{ fontWeight: 500 }}>{loc.varLocationName}</div>
                                                                </div>
                                                                <div className={styles.avatarContainer} style={{ padding: "8px 0" }}>
                                                                    <ChipGroup
                                                                        values={[
                                                                            {
                                                                                label: "Gro",
                                                                                value: loc.bitGroomingService,
                                                                                color: "success",
                                                                            },
                                                                            {
                                                                                label: "Day",
                                                                                value: loc.bitDaycareService,
                                                                                color: "success",
                                                                            },
                                                                            {
                                                                                label: "Bod",
                                                                                value: loc.bitBoardingService,
                                                                                color: "success",
                                                                            },
                                                                            {
                                                                                label: "Sit",
                                                                                value: loc.bitSittingService,
                                                                                color: "success",
                                                                            },
                                                                            {
                                                                                label: "Tra",
                                                                                value: loc.bitTrainingService,
                                                                                color: "success",
                                                                            },
                                                                            {
                                                                                label: "Sto",
                                                                                value: loc.bitStoreProductService,
                                                                                color: "success",
                                                                            },
                                                                        ]}
                                                                        size={"small"}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            ) : (
                                <p style={{ marginRight: "11rem", color: "grey", fontWeight: "500" }}>No location found</p>
                            )}
                        </div>
                        <div style={{ textAlign: "end", width: "100%", backgroundColor: "#EFF5FF", padding: "1rem" }}>
                            <Button
                                variant="text"
                                color="transparent"
                                onClick={() => {
                                    discardChanges()
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    handleAdd()
                                }}
                                className={styles.buttonText}
                            >
                                Add Location
                            </Button>
                        </div>
                    </div>
                </Modal>
            ) : null}
            <div style={{ marginTop: "2rem" }}>
                <p style={{ color: "#23262F", fontWeight: "600", fontSize: "14px" }}>Preferred Groomer</p>
                <div style={{ display: "flex", gap: "20px", width: "700px" }}>
                    {isLoading ? (
                        "Loading..."
                    ) : prefGroomer == null ? (
                        props.mode ? (
                            checkPermission(
                                <>
                                    <div style={{ margin: "0 .5rem .5rem 0", width: "285px" }}>
                                        <div
                                            style={{
                                                border: "1px solid #E5E7EB",
                                                borderRadius: 8,
                                                backgroundColor: "#ffffff",
                                                height: "115px",
                                                width: "310px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Button
                                                color="primary"
                                                onClick={() => {
                                                    {
                                                        setOpenEmployee(true)
                                                    }
                                                    {
                                                        setEmployeeType("Gro")
                                                    }
                                                }}
                                                variant="contained"
                                            >
                                                Add New Groomer
                                            </Button>
                                        </div>
                                    </div>
                                </>,
                                48
                            )
                        ) : (
                            <p style={{ fontSize: "12px", fontWeight: "400", color: "#6B7280" }}>No preferred groomer selected</p>
                        )
                    ) : (
                        <>
                            <div key={prefGroomer.numEmployeeID} style={props.mode ? { margin: "0 .5rem .5rem 0", width: "285px" } : { margin: "0 .5rem .5rem 0", width: "400px" }}>
                                <div style={{ border: "1px solid #E5E7EB", borderRadius: 8, padding: "12px", backgroundColor: "#ffffff" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "1rem" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", gap: ".6rem" }}>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <Avatar img={"https://pet-tech-api.s3.amazonaws.com/directories/Employee/" + prefGroomer.varEmployeeImagePath} isVip={false} className={"medium"} />
                                            </div>
                                            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between" }}>
                                                <div style={{ fontWeight: 600, fontSize: "14px", color: "#002867" }}>
                                                    {prefGroomer.varEmployeeFirstName} {prefGroomer.varEmployeeLastName}
                                                </div>
                                                <p style={{ fontSize: "12px", fontWeight: "400", color: "#6B7280" }}>{prefGroomer.varEmployeeCity}</p>
                                                <div style={{ display: "flex", gap: "10px" }}>
                                                    <Chip
                                                        color="success"
                                                        size="small"
                                                        label={
                                                            prefGroomer.varEmployeePerformanceCategory === undefined || prefGroomer.varEmployeePerformanceCategory === null
                                                                ? "N/A"
                                                                : prefGroomer.varEmployeePerformanceCategory
                                                        }
                                                    />
                                                    <div style={{ display: "flex", alignItems: "center", gap: ".1rem" }}>
                                                        <Star style={{ color: "#F7AE32" }} />
                                                        <p style={{ margin: "0", fontWeight: "600" }}>
                                                            {prefGroomer.varEmployeeRating === undefined || prefGroomer.varEmployeeRating === null ? "N/A" : prefGroomer.varEmployeeRating}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", gap: ".2rem" }}>
                                            <div style={{ gap: ".5rem" }}>
                                                <IconButton>
                                                    <MoreHoriz style={{ transform: "rotate(90deg)", color: "#6B7280" }} />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {props.mode ? (
                                <>
                                    {checkPermission(
                                        <>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <RefreshOutlined style={{ color: "#6B7280" }} />
                                            </div>
                                            <div style={{ margin: "0 .5rem .5rem 0", width: "285px" }}>
                                                <div
                                                    style={{
                                                        border: "1px solid #E5E7EB",
                                                        borderRadius: 8,
                                                        backgroundColor: "#ffffff",
                                                        height: "100%",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <Button
                                                        color="primary"
                                                        onClick={() => {
                                                            {
                                                                setOpenEmployee(true)
                                                            }
                                                            {
                                                                setEmployeeType("Gro")
                                                            }
                                                        }}
                                                        variant="contained"
                                                    >
                                                        Add New Groomer
                                                    </Button>
                                                </div>
                                            </div>
                                        </>,
                                        48
                                    )}
                                </>
                            ) : null}
                        </>
                    )}
                </div>
            </div>
            {openEmployee ? (
                <Modal open={openEmployee}>
                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem", width: "700px" }}>
                        <div style={{ textAlign: "end", width: "100%" }}>
                            <CloseButton
                                onClick={() => {
                                    handleModalClose()
                                }}
                            />
                        </div>
                        <div style={{ backgroundColor: "#fff", display: "flex", justifyContent: "space-between", width: "100%" }}>
                            <div style={{ paddingLeft: "1rem", width: "216px" }}>
                                <SearchBox value={searchValueEmp} onChange={(e) => debouceSearchEmployee(e)} />
                            </div>
                            <div style={{ paddingRight: "1rem" }}>
                                <FormControlLabel
                                    value="start"
                                    control={
                                        <Switch
                                            color="primary"
                                            onChange={() => {
                                                toggleAdvanceSearch()
                                            }}
                                        />
                                    }
                                    label="Advanced Search"
                                    labelPlacement="start"
                                    checked={advancedSearch}
                                    style={{ color: "#002867", fontSize: "14px" }}
                                />
                            </div>
                        </div>
                        {advancedSearch ? (
                            <div style={{ backgroundColor: "#F9FAFB", display: "flex", justifyContent: "space-between", width: "100%" }}>
                                <div style={{ padding: "0px 1rem", width: "100%", display: "flex", justifyContent: "space-around" }}>
                                    <div>
                                        <Input
                                            onChange={(val) => {
                                                setFSearch(val)
                                            }}
                                            className={styles.appInput}
                                            label={"First Name"}
                                            value={fSearch}
                                        />
                                        <Input
                                            onChange={(val) => {
                                                setLSearch(val)
                                            }}
                                            className={styles.appInput}
                                            label={"Last Name"}
                                            value={lSearch}
                                        />
                                    </div>
                                    <div>
                                        {/* <Input     
                                                        onChange={(val) => {
                                                        setSalon(val)
                                                        }}  
                                                        className={styles.appInput}
                                                        label={'Salon'}
                                                        value={salon}
                                                    /> */}
                                        <div style={{ width: "237px" }}>
                                            {allLocations !== undefined && allLocations !== null ? (
                                                <AdvancedDropDown
                                                    data={allLocations.map((i) => ({ label: i.varLocationName, value: i.numLocationID }))}
                                                    onChange={(e) => {
                                                        setSalon(e?.label)
                                                    }}
                                                    ref={SalonRef}
                                                    placeHolder={"Salon"}
                                                />
                                            ) : null}
                                        </div>

                                        <Input
                                            onChange={(val) => {
                                                setEmpID(val)
                                            }}
                                            className={styles.appInput}
                                            label={"Employee ID"}
                                            value={empID}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <div
                            className={styles.gridContainerEmployee}
                            style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "1rem", rowGap: "1ch", marginTop: "0.6rem", width: "100%", height: "300px" }}
                        >
                            {isLoading ? (
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <CircularProgress />
                                </div>
                            ) : searchDataEmp.length > 0 ? (
                                searchDataEmp.map((emp, index) => {
                                    const selectedEmployee = selectedEmp
                                    let isIn
                                    if (selectedEmployee.numEmployeeID == emp.numEmployeeID.toString()) {
                                        isIn = true
                                    } else {
                                        isIn = false
                                    }

                                    return (
                                        <div
                                            key={emp.numEmployeeID}
                                            onClick={() => {
                                                selectAccountEmp(emp)
                                            }}
                                            className={isIn ? styles.empCardWrapperChecked : styles.empCardWrapper}
                                        >
                                            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "1rem" }}>
                                                <div style={{ display: "flex", justifyContent: "space-between", gap: ".6rem" }}>
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <Avatar img={"https://pet-tech-api.s3.amazonaws.com/directories/Employee/" + emp.varEmployeeImagePath} isVip={false} className={"medium"} />
                                                    </div>
                                                    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between" }}>
                                                        <div style={{ fontWeight: 600, fontSize: "14px", color: "#002867" }}>
                                                            {emp.varEmployeeFirstName} {emp.varEmployeeLastName}
                                                        </div>
                                                        <p style={{ fontSize: "12px", fontWeight: "400", color: "#6B7280" }}>{emp.varEmployeeCity}</p>
                                                        <div style={{ display: "flex", gap: "10px" }}>
                                                            <Chip
                                                                color="success"
                                                                size="small"
                                                                label={
                                                                    emp.varEmployeePerformanceCategory === undefined || emp.varEmployeePerformanceCategory === null
                                                                        ? "N/A"
                                                                        : emp.varEmployeePerformanceCategory
                                                                }
                                                            />
                                                            <div style={{ display: "flex", alignItems: "center", gap: ".1rem" }}>
                                                                <Star style={{ color: "#F7AE32" }} />
                                                                <p style={{ margin: "0", fontWeight: "600" }}>
                                                                    {emp.varEmployeeRating === undefined || emp.varEmployeeRating === null ? "N/A" : emp.varEmployeeRating}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            ) : (
                                <p style={{ marginRight: "11rem", color: "grey", fontWeight: "500" }}>No employee found</p>
                            )}
                        </div>
                        <div style={{ textAlign: "end", width: "100%", backgroundColor: "#EFF5FF", padding: "1rem" }}>
                            <Button
                                variant="text"
                                color="transparent"
                                onClick={() => {
                                    handleModalClose()
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={styles.buttonText}
                                onClick={() => {
                                    handleEmpAdd()
                                }}
                            >
                                Select Employee
                            </Button>
                        </div>
                    </div>
                </Modal>
            ) : null}
            <div style={{ marginTop: "2rem" }}>
                <p style={{ color: "#23262F", fontWeight: "600", fontSize: "14px" }}>Preferred Bather</p>
                <div style={{ display: "flex", gap: "20px", width: "700px" }}>
                    {isLoading ? (
                        "Loading..."
                    ) : prefBather == null ? (
                        props.mode ? (
                            checkPermission(
                                <>
                                    <div style={{ margin: "0 .5rem .5rem 0", width: "285px" }}>
                                        <div
                                            style={{
                                                border: "1px solid #E5E7EB",
                                                borderRadius: 8,
                                                backgroundColor: "#ffffff",
                                                height: "115px",
                                                width: "310px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Button
                                                color="primary"
                                                onClick={() => {
                                                    {
                                                        setOpenEmployee(true)
                                                    }
                                                    {
                                                        setEmployeeType("Bath")
                                                    }
                                                }}
                                                variant="contained"
                                            >
                                                Add New Bather
                                            </Button>
                                        </div>
                                    </div>
                                </>,
                                49
                            )
                        ) : (
                            <p style={{ fontSize: "12px", fontWeight: "400", color: "#6B7280" }}>No preferred bather selected</p>
                        )
                    ) : (
                        <>
                            <div key={prefBather.numEmployeeID} style={props.mode ? { margin: "0 .5rem .5rem 0", width: "285px" } : { margin: "0 .5rem .5rem 0", width: "400px" }}>
                                <div style={{ border: "1px solid #E5E7EB", borderRadius: 8, padding: "12px", backgroundColor: "#ffffff" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "1rem" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", gap: ".6rem" }}>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <Avatar img={"https://pet-tech-api.s3.amazonaws.com/directories/Employee/" + prefBather.varEmployeeImagePath} isVip={false} className={"medium"} />
                                            </div>
                                            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between" }}>
                                                <div style={{ fontWeight: 600, fontSize: "14px", color: "#002867" }}>
                                                    {prefBather.varEmployeeFirstName} {prefBather.varEmployeeLastName}
                                                </div>
                                                <p style={{ fontSize: "12px", fontWeight: "400", color: "#6B7280" }}>{prefBather.varEmployeeCity}</p>
                                                <div style={{ display: "flex", gap: "10px" }}>
                                                    <Chip
                                                        color="success"
                                                        size="small"
                                                        label={
                                                            prefBather.varEmployeePerformanceCategory === undefined || prefBather.varEmployeePerformanceCategory === null
                                                                ? "N/A"
                                                                : prefBather.varEmployeePerformanceCategory
                                                        }
                                                    />
                                                    <div style={{ display: "flex", alignItems: "center", gap: ".1rem" }}>
                                                        <Star style={{ color: "#F7AE32" }} />
                                                        <p style={{ margin: "0", fontWeight: "600" }}>
                                                            {prefBather.varEmployeeRating === undefined || prefBather.varEmployeeRating === null ? "N/A" : prefBather.varEmployeeRating}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", gap: ".2rem" }}>
                                            <div style={{ gap: ".5rem" }}>
                                                <IconButton>
                                                    <MoreHoriz style={{ transform: "rotate(90deg)", color: "#6B7280" }} />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {props.mode
                                ? checkPermission(
                                      <>
                                          <div style={{ display: "flex", alignItems: "center" }}>
                                              <RefreshOutlined style={{ color: "#6B7280" }} />
                                          </div>
                                          <div style={{ margin: "0 .5rem .5rem 0", width: "285px" }}>
                                              <div
                                                  style={{
                                                      border: "1px solid #E5E7EB",
                                                      borderRadius: 8,
                                                      backgroundColor: "#ffffff",
                                                      height: "100%",
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                  }}
                                              >
                                                  <Button
                                                      color="primary"
                                                      onClick={() => {
                                                          {
                                                              setOpenEmployee(true)
                                                          }
                                                          {
                                                              setEmployeeType("Bath")
                                                          }
                                                      }}
                                                      variant="contained"
                                                  >
                                                      Add New Bather
                                                  </Button>
                                              </div>
                                          </div>
                                      </>,
                                      49
                                  )
                                : null}
                        </>
                    )}
                </div>
            </div>
            {props.mode
                ? checkPermission(
                      <div style={{ textAlign: "end", width: "100%", backgroundColor: "#EFF5FF", padding: "1rem", marginTop: "3rem" }}>
                          <Button
                              variant="text"
                              color="transparent"
                              onClick={() => {
                                  discardChanges()
                              }}
                          >
                              Cancel
                          </Button>
                          <Button
                              variant="contained"
                              color="primary"
                              loading={isLoading}
                              onClick={() => {
                                  submitData()
                              }}
                          >
                              Save
                          </Button>
                      </div>,
                      50
                  )
                : null}
        </div>
    )
}
