import { useEffect, useMemo, useState, useRef } from "react"
import { Profile, SearchBox, Avatar } from "../../components/components"
import TopBar from "../../components/TopBar/TopBar"
import debouce from "lodash.debounce"
import InputMask from "react-input-mask"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { axiosPrivate, axiosText, buildHeaderObject } from "../../apis/axios"

import styles from "./Booking.module.scss"
import { CircularProgress, Switch, TextField, Button as MaterialButton, MenuItem, Accordion, AccordionSummary, Tooltip, IconButton } from "@mui/material"

import { Button, Chip as LocationChip, Input } from "../../components"
import { Add, ArrowBack, InfoOutlined, LocationOnOutlined, Pets, Place, Search, StickyNote2Outlined, WarningAmber } from "@mui/icons-material"
import { Truncate } from "../../utils/Validation"
import { formatPhone, removeFormatPhone } from "../../utils/PhoneFormatter"
import AdvancedDropDown from "../../components/AdvancedDropDown/AdvancedDropDown"
import { checkPermission } from "../../utils/permissions"
import placeholderImage from "../../images/pet_placeholder.png"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion"
import styled from "@emotion/styled"
import { ImgComparisonSlider } from "@img-comparison-slider/react"
import { shortDate } from "../../utils/DateFormatter"
import ShowerOutlinedIcon from "@mui/icons-material/ShowerOutlined"
import ValidatePermission from "../../components/ValidatePermission/ValidatePermission"
import CustomerAlerts from "../../components/ExtModals/CustomerAlerts"
import PetAlerts from "../../components/ExtModals/PetAlerts"
import ViewGroomerNote from "../../components/ExtModals/ViewGroomerNote"
import { encrypt } from "../../utils/encrypt"
import { formatTemplate } from "../../utils/MessagingDecoder"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import useAuth from "../../hooks/useAuth"

function Booking(props: {}) {
    const [currentLocation, setCurrentLocation] = useState("")

    const [searchValue, setSearchValue] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [newCustomer, setNewCustomer] = useState(false)

    const [createdCustomer, setCreatedCustomer] = useState({} as any)

    const [currentCustomer, setCurrentCustomer] = useState("")
    const [currentCustomerIndex, setCurrentCustomerIndex] = useState(0)
    const [addPetView, setAddPetView] = useState(false)
    const [addCustomerView, setCustomerView] = useState(false)

    const [searchData, setSearchData] = useState([] as any)

    const [MixBreedData, setMixBreedData] = useState([] as any)
    const [PureBreedData, setPureBreedData] = useState([] as any)
    const [sizeData, setSizeData] = useState([] as any)
    const [typeData, setTypeData] = useState([] as any)

    const [petList, setPetList] = useState([] as any)
    const [emailPayload, setEmailPayload] = useState({} as any)

    const [petLoading, setPetLoading] = useState(false)
    const [customerLoading, setCustomerLoading] = useState(false)
    const [showAdvanceSearch, setShowAdvanceSearch] = useState(false)

    const [isOpenGroomerNote, setIsOpenGroomerNote] = useState(false)

    const [petName, setPetName] = useState("")
    const [petType, setPetType] = useState("")
    const [petBreed, setPetBreed] = useState("")
    const [petWeight, setPetWeight] = useState("")
    const [petSize, setPetSize] = useState("")
    const [petGender, setPetGender] = useState("")
    const [petIsMix, setPetIsMix] = useState(false)

    const [cusFName, setCusFName] = useState("")
    const [cusLName, setCusLName] = useState("")
    const [cusMobile, setCusMobile] = useState("")
    const [cusHome, setCusHome] = useState("")
    const [cusEmail, setCusEmail] = useState("")
    const [cusZip, setCusZip] = useState("")
    const [cusCity, setCusCity] = useState("")
    const [cusState, setCusState] = useState("")

    const [seFName, setSeFName] = useState("")
    const [seLName, setSeLName] = useState("")
    const [sePetName, setSePetName] = useState("")
    const [seLocation, setSeLocation] = useState("")
    const [seContact, setSeContact] = useState("")
    const [allLocations, setAllLocations] = useState<any>([])

    const [groomerNotes, setGroomerNotes] = useState<any>([])
    const myRef = useRef<any>()

    const { auth } = useAuth() as any

    const searchBoxRef = useRef<HTMLInputElement | null>(null)
    const addNewCustomerRef = useRef<HTMLInputElement | null>(null)
    const addNewPetRef = useRef<HTMLInputElement | null>(null)

    const [selectedPastAppointment, setSelectedPastAppointment] = useState("") as any
    const [pastAppointmentList, setPastAppointmentList] = useState([]) as any
    const [uniqueParam] = useState(new Date().getTime())

    const [petPer, setPetPer] = useState<any>([])
    const [isOpenPetAlertsModal, setIsOpenPetAlertsModal] = useState(false)

    const [isOpenAlertsModal, setIsAlertsModal] = useState(false)
    const emailInputRef = useRef(null) as any

    useEffect(() => {
        if (addCustomerView === true) {
            if (addNewCustomerRef.current) {
                addNewCustomerRef.current.focus()
            }
        } else {
            if (searchBoxRef.current) {
                searchBoxRef.current.focus()
            }
        }
    }, [addCustomerView])

    async function getSizeByWeight(weight, type) {
        let num = parseInt(weight.replace(/\D/g, ""), 10)
        console.log("payy", num, type)
        const result = await axiosPrivate.get(`PetSize/GetPetSizeByWeightAndPetTypeID?Weight=${num}&PetTypeID=${type}`, buildHeaderObject(auth))
        return result.data
    }

    useEffect(() => {
        console.log("petWeight", petWeight)
        if (petWeight === "__lbs" || petWeight === "") {
        } else {
            getSizeByWeight(petWeight, petType)
                .then((res) => {
                    console.log("sizeeee", res)
                    setPetSize(res?.[0]?.numPetSizeID)
                })
                .catch((err) => {
                    console.log(err)
                    toast.error("Something went wrong!")
                })
        }
    }, [petWeight])

    useEffect(() => {
        if (addPetView === true) {
            if (addNewPetRef.current) {
                addNewPetRef.current.focus()
            }
        }
    }, [addPetView])
    /* START BREED CODE */

    const primaryNameRef = useRef()
    const secondaryNameRef = useRef()
    const BreedRef = useRef()

    const [noBreedData, setNoBreedData] = useState(false)

    const [primaryName, setPrimaryName] = useState("")
    const [primaryNameDesc, setPrimaryNameDesc] = useState("")
    const [secondName, setSecondName] = useState("")

    const [primaryBreed, setPrimaryBreed] = useState("")
    const [secondaryBreed, setSecondaryBreed] = useState("")

    const [primaryBreedNameX, setPrimaryBreedNameX] = useState("")
    const [secondaryBreedNameX, setSecondaryBreedNameX] = useState("")

    const [newMixId, setNewMixId] = useState(0)

    const [breedMainData, setBreedMainData] = useState([] as any)

    const [newMissingBreed, setNewMissingBreed] = useState({
        bitActive: true,
        numCreatedBy: 0,
        varCreatedBy: "",
        dtCreatedDate: "2022-10-26T10:41:38.949Z",
        numEditedBy: 0,
        varEditedBy: "",
        dtEditedDate: "2022-10-26T10:41:38.949Z",
        numDeletedBy: 0,
        varDeletedBy: "",
        dtDeletedDate: "2022-10-26T10:41:38.949Z",
        numPetBreedID: 0,
        varPetBreedType: "MIX",
        varPetBreedName: "",
        varPetBreedDescription: "",
        numPrimaryPetBreedID: 0,
        varPrimaryPetBreed: "",
        numSecondaryPetBreedID: 0,
        varSecondaryPetBreed: "",
        numPetTypeID: 0,
        varPetTypeName: "string",
    })

    const [petDescription, setPetDescription] = useState("")

    const selectPetDescription = () => {
        if (!petIsMix) {
            PureBreedData.some((breed) => {
                if (breed.numPetBreedID === parseInt(petBreed)) {
                    setPetDescription(breed.varPetBreedDescription)
                }
            })
        } else {
            MixBreedData.some((breed) => {
                if (breed.numPetBreedID === parseInt(petBreed)) {
                    setPetDescription(breed.varPetBreedDescription)
                    setPrimaryBreed(breed.numPrimaryPetBreedID)
                    setSecondaryBreed(breed.numSecondaryPetBreedID)
                }
            })
        }
    }

    useEffect(() => {
        selectPetDescription()
    }, [MixBreedData])

    useEffect(() => {
        selectPetDescription()
    }, [petBreed])

    async function getBreedsMainList() {
        const result = await axiosPrivate.get("Breed/GetAll", buildHeaderObject(auth))
        return result.data
    }

    async function getPetBreedByMix() {
        console.log("pet ids", primaryBreed, secondaryBreed)
        const result = await axiosPrivate.get(`PetBreed/GetByID/${primaryBreed}/${secondaryBreed}`, buildHeaderObject(auth))
        return result
    }

    useEffect(() => {
        getPetBreedByMix()
            .then((res) => {
                console.log(res.data, "debug")

                if (res.data.petBreed === null) {
                    setNewMissingBreed({
                        ...newMissingBreed,
                        varPrimaryPetBreed: primaryName,
                        numPrimaryPetBreedID: parseInt(primaryBreed),
                        numSecondaryPetBreedID: parseInt(secondaryBreed),
                        numPetTypeID: parseInt(petType),
                        varPetBreedName: primaryName,
                        varSecondaryPetBreed: secondName,
                        varPetBreedDescription: primaryName,
                    })

                    setPrimaryName(primaryBreedNameX + " + " + secondaryBreedNameX)
                    setPrimaryNameDesc(res?.data?.petBreed?.varPetBreedDescription)

                    setNoBreedData(true)
                } else {
                    setPrimaryName(res?.data?.petBreed?.varPetBreedName)
                    setPrimaryNameDesc(res?.data?.petBreed?.varPetBreedDescription)
                    setPetBreed(res?.data?.petBreed?.numPetBreedID)
                    setNoBreedData(false)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [primaryBreed, secondaryBreed])

    const getBreedMainData = () => {
        getBreedsMainList()
            .then((res) => {
                let allBreeds = [] as any

                res?.breed.map((breed) => {
                    let objPure = {
                        numBreedID: breed?.numBreedID,
                        varBreedName: breed?.varBreedName,
                    }

                    allBreeds.push(objPure)
                })

                setBreedMainData(allBreeds)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    /* END BREED CODE */

    const removePetFromList = (petID) => {
        const list: any = [...petList]
        console.log("asasas", petID)

        list.some((petA, index) => {
            if (petA.id === petID) {
                list.splice(index, 1)
                return index
            }
        })

        setPetList(list)
    }

    async function getData(searchQuery) {
        const result = await axiosPrivate.post(
            "Customer/Search",
            JSON.stringify({
                page: 0,
                pageSize: 0,
                searchText: searchQuery,
            }),
            buildHeaderObject(auth)
        )
        return result.data
    }

    async function getTypes() {
        const result = await axiosPrivate.get("PetType/GetAll", buildHeaderObject(auth))
        return result.data
    }

    async function getBreeds() {
        const result = await axiosPrivate.get(`PetBreed/GetAllByPetTypeID/${petType}`, buildHeaderObject(auth))
        return result.data
    }

    async function getSizes(id) {
        const result = await axiosPrivate.get(`PetSize/GetAllByPetTypeID/${id}`, buildHeaderObject(auth))
        return result.data
    }

    async function getLocations() {
        const result = await axiosPrivate.get(`Location/GetAll`, buildHeaderObject(auth))
        return result.data.location
    }

    const fetchData = (searchQuery) => {
        setIsLoading(true)

        getData(searchQuery)
            .then((res) => {
                setSearchData(res.customer)
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setIsLoading(false)

                toast.error("Something went wrong!")
                setSearchData([])
            })
    }

    async function getStateCity(zipCode) {
        const result = await axiosPrivate.get(`ZipCode/GetCityStateByZipCode/${zipCode}`, buildHeaderObject(auth))
        return result
    }

    const handleZipSearch = (value, index) => {
        getStateCity(value)
            .then((responseCustomer) => {
                const resultZip = responseCustomer?.data

                setCusCity(resultZip?.varCity)
                setCusState(resultZip?.varState)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const cancelForm = () => {
        setCusState("")
        setCusCity("")
        setCusZip("")
        setCusMobile("")
        setCusHome("")
        setCusEmail("")
        setCusLName("")
        setCusFName("")

        setCustomerView(false)
    }

    const debouceZipSearch = useMemo(() => {
        return debouce(handleZipSearch, 500)
    }, [])

    const getTypesData = () => {
        getTypes()
            .then((res) => {
                setTypeData(res?.petType)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const getSizeData = (id) => {
        getSizes(id)
            .then((res) => {
                setSizeData(res?.petSize)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    useEffect(() => {
        async function getData() {
            const result = await axiosPrivate.post(
                "Pet",
                JSON.stringify({
                    pet: {
                        numPetID: 0,
                        varPetNumber: "",
                        varPetBC: "",
                        varPetRFID: "",
                        numCustomerID: currentCustomer,
                        numPetDefaultGroomLocationID: 0,
                        numPetDefaultBoardLocationID: 0,
                        numPetDefaultDaycareLocationID: 0,
                        numPetDefaultSittingLocationID: 0,
                        numPetDefaultTrainingLocationID: 0,
                        numPetPreferredGroomerID: 0,
                        numPetPreferredBatherID: 0,
                        varPetName: petName,
                        varPetDescription: "",
                        numPetTypeID: parseInt(petType),
                        numPetBreedID: newMixId,
                        numPetColorID: 0,
                        bitIsFilledByCatalog: true,
                        numPetCatalogID: 0,
                        numPetWeight: parseInt(petWeight),
                        numPetSizeID: parseInt(petSize),
                        varPetSex: petGender,
                        varPetBirthDate: "",
                        varPetAge: "",
                        varPetSpay: "",
                        varPetImagePath: "",
                        varPetLicenseNo: "",
                        varPetChipID: "",
                        varPetChipMaker: "",
                        varPetWarningAlert: "",
                        varPetWarningAlertGroom: "",
                        varPetWarningAlertBoard: "",
                        varPetWarningAlertDaycare: "",
                        varPetWarningAlertSitting: "",
                        varPetWarningAlertTraining: "",
                        varPetWarningAlertDriver: "",
                        numActiveLocationID: 0,
                        varPetLocationStatus: "",
                        dtPetLocationLastScanDateTime: "2022-09-15T13:17:07.693Z",
                        numPetVetID: 0,
                        varPetComments: "",
                        dtPurgeDate: "2022-09-15T13:17:07.693Z",
                        varTemperamentTestStatus: "",
                        varTemperamentTestedLocation: "",
                        dtTemperamentTestedDate: "2022-09-15T13:17:07.693Z",
                        bitDaycareApproved: true,
                        dtDayCareApprovedDate: "2022-09-15T13:17:07.693Z",
                        numDayCareApprovedBy: 0,
                        varPetCustomText1: "",
                        varPetCustomText2: "",
                        varPetCustomText3: "",
                        varPetCustomText4: "",
                        varPetCustomText5: "",
                        dtPetCustomDate1: "2022-09-15T13:17:07.693Z",
                        dtPetCustomDate2: "2022-09-15T13:17:07.693Z",
                        dtPetCustomDate3: "2022-09-15T13:17:07.693Z",
                        dtPetCustomDate4: "2022-09-15T13:17:07.693Z",
                        dtPetCustomDate5: "2022-09-15T13:17:07.693Z",
                        numPetCustomNumber1: 0,
                        numPetCustomNumber2: 0,
                        numPetCustomNumber3: 0,
                        numPetCustomNumber4: 0,
                        numPetCustomNumber5: 0,
                        numPetCustomFloatNumber1: 0,
                        numPetCustomFloatNumber2: 0,
                        numPetCustomFloatNumber3: 0,
                        numPetCustomFloatNumber4: 0,
                        numPetCustomFloatNumber5: 0,
                        numPetCustomList1ID: 0,
                        numPetCustomList2ID: 0,
                        numPetCustomList3ID: 0,
                        numPetCustomList4ID: 0,
                        numPetCustomList5ID: 0,
                        numPetCustomList6ID: 0,
                        numPetCustomList7ID: 0,
                        numPetCustomList8ID: 0,
                        numPetCustomList9ID: 0,
                        numPetCustomList10ID: 0,
                        bitPetCustomBit1: true,
                        bitPetCustomBit2: true,
                        bitPetCustomBit3: true,
                        bitPetCustomBit4: true,
                        bitPetCustomBit5: true,
                        bitActive: true,
                        numCreatedBy: 0,
                        varCreatedBy: "",
                        dtCreatedDate: "2022-09-15T13:17:07.693Z",
                        numEditedBy: 0,
                        varEditedBy: "",
                        dtEditedDate: "2022-09-15T13:17:07.693Z",
                        numDeletedBy: 0,
                        varDeletedBy: "",
                        dtDeletedDate: "2022-09-15T13:17:07.693Z",
                        varPetDeletedReason: "",
                    },
                }),
                buildHeaderObject(auth)
            )
            return result.data
        }

        if (newMixId != 0) {
            //setIsLoading(true)
            getData()
                .then((res) => {
                    toast.success("Pet saved successfully!")

                    getNewCustomer(currentCustomer)
                        .then((res) => {
                            setCreatedCustomer(res.data.customer)
                            setIsLoading(false)
                            setPetName("")
                            setPetType("")
                            setPetBreed("")
                            setPetWeight("")
                            setPetSize("")
                            setPetGender("")
                            setPetIsMix(false)

                            setAddPetView(false)
                        })
                        .catch((err) => {
                            console.log("cannot get customer", err)
                        })

                    setPetLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    setPetLoading(false)

                    toast.error("Something went wrong when saving new pet!")
                })
        }
    }, [newMixId])

    const getBreedData = () => {
        getBreeds()
            .then((res) => {
                console.log("res", res)
                let pureBreeds = [] as any
                let mixBreeds = [] as any

                res?.petBreed.map((breed) => {
                    let objPure = {
                        numPetBreedID: breed?.numPetBreedID,
                        varPetBreedType: breed?.varPetBreedType,
                        varPetBreedName: breed?.varPetBreedName,
                        varPetBreedDescription: breed?.varPetBreedDescription,
                        numPrimaryPetBreedID: breed?.numPrimaryPetBreedID,
                        varPrimaryPetBreed: breed?.varPrimaryPetBreed,
                        numSecondaryPetBreedID: breed?.numSecondaryPetBreedID,
                        varSecondaryPetBreed: breed?.varSecondaryPetBreed,
                        bitActive: breed?.bitActive,
                    }

                    if (breed.varPetBreedType === "PURE") {
                        pureBreeds.push(objPure)
                    } else {
                        mixBreeds.push(objPure)
                    }
                })

                setPureBreedData(pureBreeds)
                setMixBreedData(mixBreeds)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const validateString = (input) => {
        return input === "" ? null : input
    }

    async function getAdvancedData() {
        setIsLoading(true)

        const result = await axiosPrivate.post(
            "Customer/AdvanceSearch",
            JSON.stringify({
                varLocation: validateString(seLocation),
                varFirstName: validateString(seFName),
                varLastName: validateString(seLName),
                varCity: null,
                varState: null,
                varZip: null,
                bitActiveStatus: null,
                bitPriorityVIP: null,
                varPetName: validateString(sePetName),
                numPetTypeID: null,
                varPetBreed: null,
                varContactNumber: validateString(seContact) == null ? null : removeFormatPhone(validateString(seContact)),
            }),
            buildHeaderObject(auth)
        )

        return result.data
    }

    async function getUpdatedHeaders(objectName) {
        const result = await axiosPrivate.get(`Label/${objectName}`, buildHeaderObject(auth))
        return result
    }

    const [customerLabels, setCustomerLabels] = useState({})
    const [petLabels, setPetLabels] = useState({})
    const [customerReady, setCustomerReady] = useState(false)

    useEffect(() => {
        setCurrentLocation("1")

        getUpdatedHeaders("Customer")
            .then((responseCustomer) => {
                // console.log(responseCustomer?.data?.standard)
                setCustomerLabels(responseCustomer?.data?.standard)

                getUpdatedHeaders("Pet")
                    .then((responsePet) => {
                        // console.log(responsePet?.data?.standard)
                        setPetLabels(responsePet?.data?.standard)
                        setCustomerReady(true)
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            })
            .catch((err) => {
                console.log(err)
            })

        getBreedMainData()

        getLocations()
            .then((res) => {
                setAllLocations(res)
            })
            .catch((err) => {
                if (err.message != "canceled") {
                    console.log(err)
                }
            })

        if (searchBoxRef.current) {
            searchBoxRef.current.focus()
        }
    }, [])

    useEffect(() => {
        console.log("curLo", currentLocation)
    }, [currentLocation])

    useEffect(() => {
        setPetType("1")
        getSizeData("1")
    }, [typeData])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (seFName != "" || seLName != "" || seLocation != "" || sePetName != "" || seContact != "") {
                getAdvancedData()
                    .then((res) => {
                        console.log("adv", res)
                        setSearchData([])
                        setSearchData(res.customer)
                        setIsLoading(false)
                    })
                    .catch((err) => {
                        console.log(err)
                        setIsLoading(false)
                        setSearchData([])
                        toast.error("Something went wrong!")
                    })
            }
        }, 900)
        return () => clearTimeout(delayDebounceFn)
    }, [seFName, seLName, seLocation, sePetName, seContact])

    const handleSearch = (value) => {
        setSearchValue(value)
    }

    const goBack = () => {
        setCurrentCustomer("")
    }

    useEffect(() => {
        if (searchValue != "") {
            fetchData(searchValue)
        } else {
            setSearchData([])
        }
    }, [searchValue])

    const debouceSearch = useMemo(() => {
        return debouce(handleSearch, 500)
    }, [])

    const selectCustomer = (id, index) => {
        setCurrentCustomer(id)
        setCurrentCustomerIndex(index)
        console.log(id)
    }

    async function getNewCustomer(customerID) {
        const result = await axiosPrivate.get(`Customer/GetByID/${customerID}`, buildHeaderObject(auth))
        return result
    }

    useEffect(() => {
        // setIsLoading(true)
        if (currentCustomer != "") {
            console.log("current customer", currentCustomer)

            getNewCustomer(currentCustomer)
                .then((res) => {
                    console.log(res.data.customer)
                    setCreatedCustomer(res.data.customer)
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log("cannot get customer", err)
                })
        } else {
            setPetList([])
        }
    }, [currentCustomer])

    function formatPhoneNumber(phone) {
        if (!phone) {
            return phone
        }

        let formattedPhone = phone.replace(/(?!^\+)\D/g, "")

        if (!formattedPhone.startsWith("+")) {
            formattedPhone = `+${formattedPhone}`
        }

        return formattedPhone
    }

    const addNewCustomer = () => {
        setCustomerLoading(true)

        async function getData() {
            const result = await axiosPrivate.post(
                "Customer",
                JSON.stringify({
                    customer: {
                        bitActive: true,
                        numCreatedBy: 0,
                        varCreatedBy: "",
                        dtCreatedDate: "2022-08-18T11:23:17.463Z",
                        numEditedBy: 0,
                        varEditedBy: "",
                        dtEditedDate: "2022-08-18T11:23:17.463Z",
                        numDeletedBy: 0,
                        varDeletedBy: "",
                        dtDeletedDate: "2022-08-18T11:23:17.463Z",
                        numCustomerID: 0,
                        varAltCustomerID: "",
                        numCustomerDefaultLocationID: currentLocation,
                        numCustomerLastLocationID: 0,
                        varCustomerBC: "",
                        varCustomerFirstName: cusFName,
                        varCustomerLastName: cusLName,
                        varCustomerSalutation: "",
                        varCustomerEmail: cusEmail,
                        bitCustomerNotificationEmail: true,
                        numCustomerNotificationEmailSequence: 1,
                        varCustomerHomePhone: formatPhoneNumber(cusHome),
                        bitCustomerNotificationHomePhone: false,
                        numCustomerNotificationHomePhoneSequence: 0,
                        varCustomerMobilePhone: formatPhoneNumber(cusMobile),
                        bitCustomerNotificationMobilePhoneCall: false,
                        numCustomerNotificationMobilePhoneCallSequence: 0,
                        bitCustomerNotificationMobilePhoneSMS: true,
                        numCustomerNotificationMobilePhoneSMSSequence: 2,
                        varCustomerWorkPhone: "",
                        bitCustomerNotificationWorkPhone: false,
                        numCustomerNotificationWorkPhoneSequence: 0,
                        varCustomerStatus: "",
                        varCustomerComments: null,
                        varCustomerWarningAlert: "",
                        varCustomerFinancialAlert: "",
                        numCustomerPreferredGroomerID: 0,
                        varCustomerUsername: "",
                        varCustomerPassword: "",
                        varCustomerUsernameResetQ1: "",
                        varCustomerUsernameResetA1: "",
                        varCustomerUsernameResetQ2: "",
                        varCustomerUsernameResetA2: "",
                        varCustomerUsernameResetQ3: "",
                        varCustomerUsernameResetA3: "",
                        varEmergContactFirstName: "",
                        varEmergContactLastName: "",
                        varEmergContactType: "",
                        varEmergContactNumber: "",
                        bitIsVip: false,
                        bitCustomerAlertNoShow: false,
                        bitCustomerAlertOutstandingBill: false,
                        bitCustomerAlertDiscountOnFile: false,
                        bitCustomerAlertAlwaysLate: false,
                        bitCustomerAlertVerbalConfirmationNeeded: false,
                        bitCustomerAlertDifficultToHandle: false,
                        varCustomerImagePath: "",
                        varCustomerCustomText1: "",
                        varCustomerCustomText2: "",
                        dtCustomerCustomDate1: "",
                        dtCustomerCustomDate2: "",
                        numCustomerCustomerList1ID: 0,
                        numCustomerCustomerList2ID: 0,
                        bitCustomerCustomBit1: false,
                        bitCustomerCustomBit2: false,
                    },
                    customerAddress: [
                        {
                            bitActive: true,
                            numCustomerAddressID: 0,
                            numAddressTypeID: 1,
                            varCustomerAddress1: "",
                            varCustomerAddress2: "",
                            varCustomerCity: cusCity,
                            varCustomerState: cusState,
                            varCustomerZip: cusZip,
                            varCustomerAddressNotes: "",
                            bitIsPrimaryDefault: true,
                            bitIsPrimary: true,
                            bitIsBillingDefault: true,
                            bitIsBilling: true,
                        },
                    ],
                }),
                buildHeaderObject(auth)
            )
            return result.data
        }

        var Form: any = document.getElementById("editForm")

        if (Form.checkValidity() == false) {
            console.log("invalid")
            setCustomerLoading(false)
        } else {
            getData()
                .then((res) => {
                    console.log("Customer Account created Res-", res)

                    createUserAccount(res?.numResponseID, cusEmail)
                        .then((resUser) => {
                            console.log("CUSTOMER USER ACCOUNT CREATED RES", resUser)
                            toast.success("Customer saved successfully!")
                            const encrypted = encrypt(resUser?.data?.numUserID.toString())
                            sendAccountActivateEmail(encrypted, res)

                            /* Reset Input Values*/
                            setCusState("")
                            setCusCity("")
                            setCusZip("")
                            setCusMobile("")
                            setCusHome("")
                            setCusEmail("")
                            setCusLName("")
                            setCusFName("")

                            setCustomerLoading(false)
                            setCustomerView(false)

                            setCurrentCustomer(res.numResponseID)
                        })
                        .catch((errUser) => {
                            console.log("CUSTOMER USER ACCOUNT Err-", errUser)
                            setCustomerLoading(false)
                            toast.error("Something went wrong when creating new customer!")
                        })
                })
                .catch((err) => {
                    if (err?.response?.data?.error?.errorMessage === "Customer email already exists.") {
                        toast.error(`Email address is already registered!`)
                        emailInputRef.current.focus()
                        setCustomerLoading(false)
                    } else {
                        console.log("Customer Account Err -", err)
                        setCustomerLoading(false)
                        toast.error("Something went wrong when saving new customer!")
                    }
                })
        }
    }
    const sendAccountActivateEmail = (encID, customerData) => {
        getCustomerInfo(customerData?.numResponseID, encID)
    }

    async function getCustomerInfo(id, encID) {
        await axiosPrivate
            .get(`Customer/GetByID/${id}`, buildHeaderObject(auth))
            .then((result) => {
                if (result.status === 200) {
                    console.log("customerDD", result?.data?.customer)

                    let emailBody = `<!DOCTYPE html>
                          <html lang="en">
                          <head>
                              <meta charset="UTF-8">
                              <title>Activate Your Account</title>
                              <style>
                                  body {
                                      font-family: Arial, sans-serif;
                                      margin: 0;
                                      padding: 20px;
                                      background-color: #f4f4f4;
                                  }
                                  .container {
                                      background-color: #ffffff;
                                      padding: 20px;
                                      border-radius: 8px;
                                      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                                      max-width: 600px;
                                      margin: 0 auto;
                                  }
                                  .header {
                                      display: flex;
                                      align-items: center;
                                      justify-content: space-between;
                                  }
                                  .logo {
                                      max-width: 100px;
                                      height: auto;
                                  }
                                  .content {
                                      margin-top: 20px;
                                  }
                                  .button {
                                      background-color: #007bff;
                                      color: white;
                                      padding: 10px 15px;
                                      text-decoration: none;
                                      border-radius: 5px;
                                      display: inline-block;
                                      margin-top: 20px;
                                  }
                                  .button:hover {
                                      background-color: #0056b3;
                                  }
                              </style>
                          </head>
                          <body>
                              <div class="container">
                                  <div class="header">
                                      <img src="https://test.esypet.net/static/media/esypet-logo.5f7c03070095ad942f20.png" alt="EsyPet Logo" class="logo" />
                                  </div>
                                  <div class="content">
                                      <p><strong>Dear Valued Customer,</strong></p>
                                      <p>Welcome to EsyPet! We are excited to have you on board. Your account has been successfully created, and you are just one step away from exploring our pet grooming services.</p>
                                      <p>To activate your account and start scheduling appointments for your pet, please click the button below:</p>
                                      <a href="http://localhost:3000/reset-security-questions-password/${encID}" class="button">Activate Your Account</a>
                                      <p>If you did not sign up for an account, please ignore this email.</p>
                                      <p>Thank you for choosing EsyPet. We look forward to providing the best grooming care for your pets!</p>
                                  </div>
                              </div>
                          </body>
                          </html>`

                    let payload = {
                        messageBody: [
                            {
                                messageID: 1,
                                recipientID: result?.data?.customer?.numCustomerID,
                                recipientType: "C",
                                senderID: result?.data?.customer?.numCustomerID,
                                senderLocationID: result?.data?.customer?.numCustomerDefaultLocationID,
                                subject: `Account activation`,
                                smsBody: emailBody,
                                emailBody: emailBody,
                                communicationMethod: "E",
                                sender: result?.data?.customer?.varCustomerFirstName + "" + result?.data?.customer?.varCustomerLastName,
                                senderLocation: result?.data?.customer?.varCustomerDefaultLocation,
                                contactNumber: result?.data?.customer?.varCustomerMobilePhone,
                                emailAddress: result?.data?.customer?.varCustomerEmail,
                                appointmentID: null,
                                option: result?.data?.customer?.varCustomerEmail === null ? 1 : 2,
                                sendDate: new Date().toISOString().split("T")[0],
                                messageType: "I",
                                workPhoneNumber: result?.data?.customer?.varCustomerWorkPhone,
                                homePhoneNumber: result?.data?.customer?.varCustomerHomePhone,
                                bitCustomerNotificationEmail: true,
                                numCustomerNotificationEmailSequence: 1,
                                bitCustomerNotificationHomePhone: false,
                                numCustomerNotificationHomePhoneSequence: 0,
                                bitCustomerNotificationMobilePhoneCall: false,
                                numCustomerNotificationMobilePhoneCallSequence: 0,
                                bitCustomerNotificationMobilePhoneSMS: false,
                                numCustomerNotificationMobilePhoneSMSSequence: 0,
                                bitCustomerNotificationWorkPhone: false,
                                numCustomerNotificationWorkPhoneSequence: 0,
                                messageTemplateID: null,
                                attachmentModel: [],
                            },
                        ],
                    }
                    console.log("payloadEMail", payload)

                    sendMessagePost(payload)
                }
            })
            .catch((err) => {
                console.log(err)
                console.log("Something went wrong!")
            })
    }

    async function sendMessagePost(data) {
        await axiosText
            .post(`UpdateMessages/UpdateMessagesPool`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                if (result.status === 200) {
                    console.log("Message sent to pool")
                    sendWelcomeEmail(data)
                    console.log("SATESET", data)
                }
            })
            .catch((err) => {
                console.log("Messaging -Err-", err)
            })
    }
    async function sendWelcomeMessagePost(data) {
        await axiosText
            .post(`UpdateMessages/UpdateMessagesPool`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                if (result.status === 200) {
                    console.log("Message sent to pool")
                    console.log("SATESET", data)
                }
            })
            .catch((err) => {
                console.log("Messaging -Err-", err)
            })
    }

    const getTemplateByID = async (templateID) => {
        try {
            const response = await axiosText.get(`Template?TemplateID=${templateID}`, buildHeaderObject(auth))
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }
    const getAppointmentDynamicValues = async (entity, id) => {
        try {
            const response = await axiosText.post(`${process.env.REACT_APP_TEXT_API_URL}Template/GetDynamicParameterDataByEntityAndID?EntityName=${entity}&ID=${id}`, buildHeaderObject(auth))
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    const sendWelcomeEmail = (data) => {
        getTemplateByID(1003)
            .then((templateData) => {
                if (templateData[0].isActive) {
                    getAppointmentDynamicValues("AppLink", 0)
                        .then((DynamicData) => {
                            console.log("enditty data", DynamicData)

                            let dynamicD = DynamicData
                            let template = templateData[0]

                            const templateDDD = template.emailMessage
                            const templateSMS = template.smsMessage
                            const formattedEmail = formatTemplate(templateDDD, dynamicD)
                            const formattedSms = formatTemplate(templateSMS, dynamicD)
                            console.log("Femail", formattedEmail, DynamicData)
                            console.log("FSMS", formattedSms)

                            let payload = {
                                messageBody: [
                                    {
                                        messageID: data?.messageBody[0]?.messageID,
                                        recipientID: data?.messageBody[0]?.recipientID,
                                        recipientType: data?.messageBody[0]?.recipientType,
                                        senderID: data?.messageBody[0]?.senderID,
                                        senderLocationID: data?.messageBody[0]?.senderLocationID,
                                        subject: template?.subject,
                                        smsBody: formattedSms,
                                        emailBody: formattedEmail,
                                        communicationMethod: data?.messageBody[0]?.communicationMethod,
                                        sender: data?.messageBody[0]?.sender,
                                        senderLocation: data?.messageBody[0]?.senderLocation,
                                        contactNumber: data?.messageBody[0]?.contactNumber,
                                        emailAddress: data?.messageBody[0]?.emailAddress,
                                        appointmentID: data?.messageBody[0]?.appointmentID,
                                        option: data?.messageBody[0]?.option,
                                        sendDate: data?.messageBody[0]?.sendDate,
                                        messageType: data?.messageBody[0]?.messageType,
                                        workPhoneNumber: data?.messageBody[0]?.workPhoneNumber,
                                        homePhoneNumber: data?.messageBody[0]?.homePhoneNumber,
                                        bitCustomerNotificationEmail: data?.messageBody[0]?.bitCustomerNotificationEmail,
                                        numCustomerNotificationEmailSequence: data?.messageBody[0]?.numCustomerNotificationEmailSequence,
                                        bitCustomerNotificationHomePhone: data?.messageBody[0]?.bitCustomerNotificationHomePhone,
                                        numCustomerNotificationHomePhoneSequence: data?.messageBody[0]?.numCustomerNotificationHomePhoneSequence,
                                        bitCustomerNotificationMobilePhoneCall: data?.messageBody[0]?.bitCustomerNotificationMobilePhoneCall,
                                        numCustomerNotificationMobilePhoneCallSequence: data?.messageBody[0]?.numCustomerNotificationMobilePhoneCallSequence,
                                        bitCustomerNotificationMobilePhoneSMS: data?.messageBody[0]?.bitCustomerNotificationMobilePhoneSMS,
                                        numCustomerNotificationMobilePhoneSMSSequence: data?.messageBody[0]?.numCustomerNotificationMobilePhoneSMSSequence,
                                        bitCustomerNotificationWorkPhone: data?.messageBody[0]?.bitCustomerNotificationWorkPhone,
                                        numCustomerNotificationWorkPhoneSequence: data?.messageBody[0]?.numCustomerNotificationWorkPhoneSequence,
                                        messageTemplateID: data?.messageBody[0]?.messageTemplateID,
                                        attachmentModel: data?.messageBody[0]?.attachmentModel,
                                    },
                                ],
                            }
                            sendWelcomeMessagePost(payload)
                            console.log("WELCOME EMAIL - sent", payload)
                        })
                        .catch((error) => {
                            console.log("error", error)
                        })
                } else {
                    toast.warning(`Message did not sent, template ${templateData[0]?.templateReference} have been deactivated.`)
                }
            })
            .catch((error) => {
                console.log("error", error)
            })
    }

    async function createUserAccount(customerID, email) {
        let payload = {
            varCustomerFirstName: "",
            varCustomerLastName: "",
            varCustomerEmail: "",
            varCustomerMobilePhone: "",
            varCustomerZip: "",
            varUserName: email,
            varUserPassword: "x#gyUoILzsD2",
            numEmployeeID: null,
            numCustomerID: customerID,
        }

        const result = await axiosPrivate.post(`Auth/Register`, payload, buildHeaderObject(auth))
        return result
    }

    const resetFilters = () => {
        setSeContact("")
        setSeFName("")
        setSeLName("")
        setSeLocation("")
        setSePetName("")
        setSearchData([])
        myRef.current?.handleClear()
    }

    const createPet = () => {
        setPetLoading(true)

        async function saveMixBreed(data) {
            await axiosPrivate
                .post(`PetBreed`, JSON.stringify(data), buildHeaderObject(auth))
                .then((result) => {
                    if (result.status === 200) {
                        setNewMixId(result.data.numResponseID)
                        console.log("petssssssssssss", data)

                        toast.success(`New Mixed Breed successfully saved!`)
                        console.log("result saved", result)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    toast.error("Something went wrong!")
                })
        }

        async function getData() {
            const result = await axiosPrivate.post(
                "Pet",
                JSON.stringify({
                    pet: {
                        numPetID: 0,
                        varPetNumber: "",
                        varPetBC: "",
                        varPetRFID: "",
                        numCustomerID: currentCustomer,
                        numPetDefaultGroomLocationID: 0,
                        numPetDefaultBoardLocationID: 0,
                        numPetDefaultDaycareLocationID: 0,
                        numPetDefaultSittingLocationID: 0,
                        numPetDefaultTrainingLocationID: 0,
                        numPetPreferredGroomerID: null,
                        numPetPreferredBatherID: null,
                        varPetName: petName,
                        varPetDescription: "",
                        numPetTypeID: parseInt(petType),
                        numPetBreedID: parseInt(petBreed),
                        numPetColorID: 0,
                        bitIsFilledByCatalog: true,
                        numPetCatalogID: 0,
                        numPetWeight: parseInt(petWeight),
                        numPetSizeID: parseInt(petSize),
                        varPetSex: petGender,
                        varPetBirthDate: "",
                        varPetAge: "",
                        varPetSpay: "",
                        varPetImagePath: "",
                        varPetLicenseNo: "",
                        varPetChipID: "",
                        varPetChipMaker: "",
                        varPetWarningAlert: "",
                        varPetWarningAlertGroom: "",
                        varPetWarningAlertBoard: "",
                        varPetWarningAlertDaycare: "",
                        varPetWarningAlertSitting: "",
                        varPetWarningAlertTraining: "",
                        varPetWarningAlertDriver: "",
                        numActiveLocationID: 0,
                        varPetLocationStatus: "",
                        dtPetLocationLastScanDateTime: "2022-09-15T13:17:07.693Z",
                        numPetVetID: 0,
                        varPetComments: "",
                        dtPurgeDate: "2022-09-15T13:17:07.693Z",
                        varTemperamentTestStatus: "",
                        varTemperamentTestedLocation: "",
                        dtTemperamentTestedDate: "2022-09-15T13:17:07.693Z",
                        bitDaycareApproved: true,
                        dtDayCareApprovedDate: "2022-09-15T13:17:07.693Z",
                        numDayCareApprovedBy: 0,
                        varPetCustomText1: "",
                        varPetCustomText2: "",
                        varPetCustomText3: "",
                        varPetCustomText4: "",
                        varPetCustomText5: "",
                        dtPetCustomDate1: "2022-09-15T13:17:07.693Z",
                        dtPetCustomDate2: "2022-09-15T13:17:07.693Z",
                        dtPetCustomDate3: "2022-09-15T13:17:07.693Z",
                        dtPetCustomDate4: "2022-09-15T13:17:07.693Z",
                        dtPetCustomDate5: "2022-09-15T13:17:07.693Z",
                        numPetCustomNumber1: 0,
                        numPetCustomNumber2: 0,
                        numPetCustomNumber3: 0,
                        numPetCustomNumber4: 0,
                        numPetCustomNumber5: 0,
                        numPetCustomFloatNumber1: 0,
                        numPetCustomFloatNumber2: 0,
                        numPetCustomFloatNumber3: 0,
                        numPetCustomFloatNumber4: 0,
                        numPetCustomFloatNumber5: 0,
                        numPetCustomList1ID: 0,
                        numPetCustomList2ID: 0,
                        numPetCustomList3ID: 0,
                        numPetCustomList4ID: 0,
                        numPetCustomList5ID: 0,
                        numPetCustomList6ID: 0,
                        numPetCustomList7ID: 0,
                        numPetCustomList8ID: 0,
                        numPetCustomList9ID: 0,
                        numPetCustomList10ID: 0,
                        bitPetCustomBit1: true,
                        bitPetCustomBit2: true,
                        bitPetCustomBit3: true,
                        bitPetCustomBit4: true,
                        bitPetCustomBit5: true,
                        bitActive: true,
                        numCreatedBy: 0,
                        varCreatedBy: "",
                        dtCreatedDate: "2022-09-15T13:17:07.693Z",
                        numEditedBy: 0,
                        varEditedBy: "",
                        dtEditedDate: "2022-09-15T13:17:07.693Z",
                        numDeletedBy: 0,
                        varDeletedBy: "",
                        dtDeletedDate: "2022-09-15T13:17:07.693Z",
                        varPetDeletedReason: "",
                    },
                }),
                buildHeaderObject(auth)
            )
            return result.data
        }

        var FormPet: any = document.getElementById("editFormPet")

        if (FormPet.checkValidity() === false) {
            console.log("invalid")
            setCustomerLoading(false)
        } else {
            if (noBreedData) {
                let objectBreed = {
                    petBreed: newMissingBreed,
                }

                saveMixBreed(objectBreed)
            } else {
                //setIsLoading(true)
                getData()
                    .then((res) => {
                        toast.success("Pet saved successfully!")

                        getNewCustomer(currentCustomer)
                            .then((res) => {
                                console.log(res.data.customer)
                                setCreatedCustomer(res.data.customer)
                                setIsLoading(false)
                                setPetName("")
                                setPetType("")
                                setPetBreed("")
                                setPetWeight("")
                                setPetSize("")
                                setPetGender("")
                                setPetIsMix(false)

                                setAddPetView(false)
                            })
                            .catch((err) => {
                                console.log("cannot get customer", err)
                            })

                        setPetLoading(false)
                    })
                    .catch((err) => {
                        console.log(err)
                        setPetLoading(false)

                        toast.error("Something went wrong when saving new pet!")
                    })
            }
        }
    }

    useEffect(() => {
        if (addPetView) {
            getTypesData()
        }
    }, [addPetView])

    useEffect(() => {
        console.log("change")
        if (petType != "") getBreedData()
    }, [petType])

    useEffect(() => {
        console.log(addCustomerView)
    }, [addCustomerView])

    const handleInput = (event) => {
        // Remove numbers and special characters from the input value
        const newValue = event.target.value.replace(/[^A-Za-z]/g, "")
        // Capitalize the value and restrict to 2 characters
        const capitalizedValue = newValue.slice(0, 2).toUpperCase()
        event.target.value = capitalizedValue
    }

    const handleCityInput = (event) => {
        // Remove numbers and special characters from the input value
        const newValue = event.target.value.replace(/[^A-Za-z]/g, "")
        event.target.value = newValue
    }

    function checkCustomerAlerts(createdCustomer) {
        const data = createdCustomer
        const {
            bitCustomerAlertAlwaysLate,
            bitCustomerAlertDifficultToHandle,
            bitCustomerAlertDiscountOnFile,
            bitCustomerAlertNoShow,
            bitCustomerAlertOutstandingBill,
            bitCustomerAlertVerbalConfirmationNeeded,
            varCustomerComments,
        } = data

        const anyAlertsTrue =
            bitCustomerAlertAlwaysLate ||
            bitCustomerAlertDifficultToHandle ||
            bitCustomerAlertDiscountOnFile ||
            bitCustomerAlertNoShow ||
            bitCustomerAlertOutstandingBill ||
            bitCustomerAlertVerbalConfirmationNeeded

        const commentsNotNull = varCustomerComments !== null

        // Simplified condition logic
        if (anyAlertsTrue || commentsNotNull) {
            return true
        }

        return false
    }

    const showAlert = checkCustomerAlerts(createdCustomer)

    const showPetAlert = (per) => {
        if (per.length > 0) {
            setPetPer(per)
            setIsOpenPetAlertsModal(true)
        }
    }

    if (selectedPastAppointment !== "") {
        console.log(pastAppointmentList[selectedPastAppointment])
    }

    const openGroomerNote = (notes) => {
        if (notes.length > 0) {
            setGroomerNotes(notes)
            setIsOpenGroomerNote(true)
        } else {
            toast.info("No groomer note added for the selected appointment")
        }
    }
    return (
        <>
            <ToastContainer />
            <PetAlerts isOpenPetAlertsModal={isOpenPetAlertsModal} setIsOpenPetAlertsModal={(value) => setIsOpenPetAlertsModal(value)} data={petPer} />
            <TopBar
                locationOnChange={(e) => {
                    setCurrentLocation(e)
                }}
            />
            <ViewGroomerNote isOpenViewModal={isOpenGroomerNote} setIsOpenViewModal={(value) => setIsOpenGroomerNote(value)} notes={groomerNotes} />
            <div style={{ display: "flex" }}>
                {currentCustomer === "" ? (
                    <>
                        <div style={{ width: "314px" }}>
                            <div style={{ background: "#F9FAFB" }}>
                                {/* <SearchBox onChange={(e) => debouceSearch(e)}  isButton={true} onAddClick={()=>setCustomerView(true)}  /> */}
                                <ValidatePermission allowedModules={["02 - Schedule"]} allowedSubRoutes={["Add New Customer"]}>
                                    <div style={{ height: "48px", borderBottom: "1px solid #F3F4F6", backgroundColor: "#fff", display: "flex", alignItems: "center", padding: "0 .5rem" }}>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <p style={{ margin: "0", color: "#002867", fontSize: "14px", fontWeight: "600" }}>Add New Customer</p>
                                            </div>

                                            {customerReady ? (
                                                !addCustomerView ? (
                                                    <Button color="primary" variant="contained" onClick={() => setCustomerView(true)}>
                                                        <Add />
                                                    </Button>
                                                ) : null
                                            ) : (
                                                <Button loading={true} color="primary" variant="contained" onClick={() => console.log("")}></Button>
                                            )}
                                        </div>
                                    </div>
                                </ValidatePermission>
                                {addCustomerView ? null : addPetView ? null : (
                                    <div style={{ height: "100%", display: "flex", alignItems: "center", gap: "0.3rem", padding: "2px .5rem", background: "#fff" }}>
                                        <Search color={"disabled"} />
                                        <input
                                            className={styles.searchInput}
                                            onChange={(e) => {
                                                debouceSearch(e.target.value)
                                            }}
                                            placeholder={"Search"}
                                            style={{ width: "100%" }}
                                            ref={searchBoxRef}
                                        />
                                    </div>
                                )}

                                {addCustomerView ? null : addPetView ? null : (
                                    <div style={{ display: "flex", justifyContent: "space-between", margin: ".2rem .6rem" }}>
                                        <p style={{ color: "#002867", fontSize: "14px", fontWeight: "600" }}>Advanced Search</p>
                                        <a
                                            onClick={() => {
                                                setShowAdvanceSearch(!showAdvanceSearch)
                                            }}
                                        >
                                            <p style={{ textDecoration: "underline", fontSize: "14px", fontWeight: "400", color: "#2076FF", cursor: "pointer" }}>
                                                {showAdvanceSearch ? "Hide" : "Show"}
                                            </p>
                                        </a>
                                    </div>
                                )}

                                <div>
                                    {addCustomerView ? (
                                        <div style={{ backgroundColor: "#fff", padding: "0 1rem 1rem 1rem" }}>
                                            <form id="editForm" onSubmit={(e) => e.preventDefault()}>
                                                <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                                    <TextField
                                                        inputProps={{
                                                            pattern: "^[a-zA-Z ]*$",
                                                        }}
                                                        required={customerLabels[5].bitRequired === undefined ? false : customerLabels[5].bitRequired}
                                                        label={
                                                            <p>
                                                                {customerLabels[5].varLabelValue === undefined ? "" : customerLabels[5].varLabelValue}
                                                                {(customerLabels[5].bitRequired === undefined ? false : customerLabels[5].bitRequired) ? (
                                                                    <span
                                                                        style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}
                                                                    >
                                                                        Required
                                                                    </span>
                                                                ) : null}
                                                            </p>
                                                        }
                                                        value={cusFName}
                                                        onChange={(e) => setCusFName(e.target.value)}
                                                        variant="standard"
                                                        inputRef={addNewCustomerRef}
                                                    />

                                                    <TextField
                                                        inputProps={{
                                                            pattern: "^[a-zA-Z ]*$",
                                                        }}
                                                        required={customerLabels[6].bitRequired === undefined ? false : customerLabels[6].bitRequired}
                                                        label={
                                                            <p>
                                                                {customerLabels[6].varLabelValue === undefined ? "" : customerLabels[6].varLabelValue}
                                                                {(customerLabels[6].bitRequired === undefined ? false : customerLabels[6].bitRequired) ? (
                                                                    <span
                                                                        style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}
                                                                    >
                                                                        Required
                                                                    </span>
                                                                ) : null}
                                                            </p>
                                                        }
                                                        value={cusLName}
                                                        onChange={(e) => setCusLName(e.target.value)}
                                                        variant="standard"
                                                    />

                                                    {/* <InputMask
                                                        alwaysShowMask="true"
                                                        mask="+99 (999) 999-9999"
                                                        maskChar="_"
                                                        value={cusMobile}
                                                        onChange={(e) => setCusMobile(e.target.value)}
                                                        >
                                                        {(inputProps) => (
                                                            <TextField
                                                            inputProps={{
                                                                pattern: customerLabels[14].bitRequired === undefined ? false : customerLabels[14].bitRequired
                                                                ? "^[0-9()+-]+$"
                                                                : cusMobile === "" || cusMobile === "+__ (___) ___-____"
                                                                ? null
                                                                : "^[0-9()+-]+$",
                                                            }}
                                                            {...inputProps}
                                                            type="tel"
                                                            required={customerLabels[14].bitRequired === undefined ? false : customerLabels[14].bitRequired}
                                                            label={
                                                                <p>
                                                                {customerLabels[14].varLabelValue === undefined ? "" : customerLabels[14].varLabelValue}
                                                                {customerLabels[14].bitRequired === undefined ? false : customerLabels[14].bitRequired ? (
                                                                    <span
                                                                    style={{
                                                                        fontWeight: 500,
                                                                        backgroundColor: "#FDEDED",
                                                                        color: "#EF4444",
                                                                        fontSize: "12px",
                                                                        padding: "0 5px",
                                                                        marginLeft: "5px",
                                                                    }}
                                                                    >
                                                                    Required
                                                                    </span>
                                                                ) : null}
                                                                </p>
                                                            }
                                                            variant="standard"
                                                            />
                                                        )}
                                                        </InputMask>

                                                        <InputMask
                                                        alwaysShowMask="true"
                                                        mask="+99 (999) 999-9999"
                                                        maskChar="_"
                                                        value={cusHome}
                                                        onChange={(e) => setCusHome(e.target.value)}
                                                        >
                                                        {(inputProps) => (
                                                            <TextField
                                                            inputProps={{
                                                                pattern: customerLabels[11].bitRequired === undefined ? false : customerLabels[11].bitRequired
                                                                ? "^[0-9()+-]+$"
                                                                : cusHome === "" || cusHome === "+__ (___) ___-____"
                                                                ? null
                                                                : "^[0-9()+-]+$",
                                                            }}
                                                            {...inputProps}
                                                            type="tel"
                                                            required={customerLabels[11].bitRequired === undefined ? false : customerLabels[11].bitRequired}
                                                            label={
                                                                <p>
                                                                {customerLabels[11].varLabelValue === undefined ? "" : customerLabels[11].varLabelValue}
                                                                {customerLabels[11].bitRequired === undefined ? false : customerLabels[11].bitRequired ? (
                                                                    <span
                                                                    style={{
                                                                        fontWeight: 500,
                                                                        backgroundColor: "#FDEDED",
                                                                        color: "#EF4444",
                                                                        fontSize: "12px",
                                                                        padding: "0 5px",
                                                                        marginLeft: "5px",
                                                                    }}
                                                                    >
                                                                    Required
                                                                    </span>
                                                                ) : null}
                                                                </p>
                                                            }
                                                            variant="standard"
                                                            />
                                                        )}
                                                        </InputMask> */}

                                                    {/* Mobile Phone Input */}
                                                    <PhoneInput
                                                        country={"us"} // Default country code, can be dynamic if needed
                                                        value={cusMobile}
                                                        onChange={(phone) => setCusMobile(phone)} // Update the state with the phone number
                                                        inputStyle={{
                                                            width: "100%",
                                                            marginBottom: "16px",
                                                            height: "48px",
                                                        }}
                                                        inputProps={{
                                                            name: "mobile",
                                                            required: customerLabels[14]?.bitRequired ?? false, // Check if it's required based on the bitRequired field
                                                            autoFocus: true,
                                                        }}
                                                        // Additional props can be added here if needed
                                                    />

                                                    {/* Home Phone Input */}
                                                    <PhoneInput
                                                        country={"us"} // Default country code, can be dynamic if needed
                                                        value={cusHome}
                                                        onChange={(phone) => setCusHome(phone)} // Update the state with the phone number
                                                        inputStyle={{
                                                            width: "100%",
                                                            marginBottom: "16px",
                                                            height: "48px",
                                                        }}
                                                        inputProps={{
                                                            name: "home",
                                                            required: customerLabels[11]?.bitRequired ?? false, // Check if it's required based on the bitRequired field
                                                            autoFocus: true,
                                                        }}
                                                        // Additional props can be added here if needed
                                                    />

                                                    <TextField
                                                        type="email"
                                                        required={customerLabels[8].bitRequired === undefined ? false : customerLabels[8].bitRequired}
                                                        label={
                                                            <p>
                                                                {customerLabels[8].varLabelValue === undefined ? "" : customerLabels[8].varLabelValue}
                                                                {(customerLabels[8].bitRequired === undefined ? false : customerLabels[8].bitRequired) ? (
                                                                    <span
                                                                        style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}
                                                                    >
                                                                        Required
                                                                    </span>
                                                                ) : null}
                                                            </p>
                                                        }
                                                        value={cusEmail}
                                                        onChange={(e) => setCusEmail(e.target.value)}
                                                        variant="standard"
                                                        inputRef={emailInputRef}
                                                    />

                                                    <InputMask
                                                        alwaysShowMask="true"
                                                        mask="99999"
                                                        maskChar="_"
                                                        value={cusZip}
                                                        onChange={(e) => {
                                                            debouceZipSearch(e.target.value)
                                                            setCusZip(e.target.value)
                                                        }}
                                                    >
                                                        {(inputProps) => (
                                                            <TextField
                                                                inputProps={{
                                                                    pattern: "^[0-9]+$",
                                                                }}
                                                                {...inputProps}
                                                                type="tel"
                                                                required={true}
                                                                label={
                                                                    <p>
                                                                        Zip Code
                                                                        <span
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                backgroundColor: "#FDEDED",
                                                                                color: "#EF4444",
                                                                                fontSize: "12px",
                                                                                padding: "0 5px",
                                                                                marginLeft: "5px",
                                                                            }}
                                                                        >
                                                                            Required
                                                                        </span>
                                                                    </p>
                                                                }
                                                                variant="standard"
                                                            />
                                                        )}
                                                    </InputMask>

                                                    <TextField
                                                        inputProps={{
                                                            pattern: "^[a-zA-Z ]*$",
                                                        }}
                                                        required={true}
                                                        variant="standard"
                                                        onInput={handleCityInput}
                                                        label={
                                                            <p>
                                                                City
                                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                    Required
                                                                </span>
                                                            </p>
                                                        }
                                                        value={cusCity}
                                                        onChange={(e) => setCusCity(e.target.value)}
                                                    />

                                                    <TextField
                                                        type={"text"}
                                                        required={true}
                                                        onInput={handleInput}
                                                        variant="standard"
                                                        label={
                                                            <p>
                                                                State
                                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                    Required
                                                                </span>
                                                            </p>
                                                        }
                                                        value={cusState}
                                                        onChange={(e) => setCusState(e.target.value)}
                                                    />
                                                </div>

                                                <div style={{ paddingTop: "2rem", display: "flex", gap: "5px", flexDirection: "column" }}>
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        text="Create Customer Profile"
                                                        loading={customerLoading}
                                                        onClick={() => addNewCustomer()}
                                                        type={"submit"}
                                                    />
                                                    <Button color="default" variant="text" text="Cancel" onClick={() => cancelForm()} />
                                                </div>
                                            </form>
                                        </div>
                                    ) : null}
                                </div>

                                <>
                                    {addCustomerView ? null : showAdvanceSearch ? (
                                        <div style={{ backgroundColor: "#fff", padding: "1rem 1rem 2rem 1rem", display: "flex", gap: ".5rem", flexDirection: "column" }}>
                                            <TextField label={<p>First Name</p>} value={seFName} onChange={(e) => setSeFName(e.target.value)} variant="standard" />

                                            <TextField label={<p>Last Name</p>} value={seLName} onChange={(e) => setSeLName(e.target.value)} variant="standard" />

                                            <TextField label={<p>Pet's Name</p>} value={sePetName} onChange={(e) => setSePetName(e.target.value)} variant="standard" />

                                            {allLocations !== undefined && allLocations !== null ? (
                                                <AdvancedDropDown
                                                    data={allLocations.map((i) => ({ label: i.varLocationName, value: i.numLocationID }))}
                                                    onChange={(e) => {
                                                        setSeLocation(e?.label)
                                                    }}
                                                    ref={myRef}
                                                    placeHolder={"Locations"}
                                                />
                                            ) : null}

                                            <InputMask alwaysShowMask="true" mask="\(999\)999\-9999" maskChar="_" value={seContact} onChange={(e) => setSeContact(e.target.value)}>
                                                {(inputProps) => <TextField {...inputProps} type="tel" required={false} label={<p>Mobile</p>} variant="standard" />}
                                            </InputMask>
                                            <div style={{ paddingTop: "1rem", display: "flex", gap: "5px", flexDirection: "column" }}>
                                                <Button color="primary" variant="contained" text="Reset" loading={customerLoading} onClick={() => resetFilters()} type={"submit"} />
                                            </div>
                                        </div>
                                    ) : null}
                                </>

                                <div>
                                    {isLoading ? (
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: ".5rem", gap: ".5rem" }}>
                                            <CircularProgress style={{ width: "15px", height: "15px" }} />
                                            <p style={{ fontWeight: 600 }}>Loading</p>
                                        </div>
                                    ) : (
                                        <>
                                            {searchData.length > 0 ? (
                                                <div style={{ display: "flex", justifyContent: "space-between", margin: ".5rem .5rem 0 .5rem" }}>
                                                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                        <StickyNote2Outlined style={{ color: "#002867", fontSize: "15px" }} />
                                                        <p style={{ fontWeight: "600", fontSize: "14px", color: "#000000" }}>Search Results</p>
                                                    </div>

                                                    <div>
                                                        <p style={{ color: "#6B7280", fontWeight: "400", fontSize: "14px" }}>
                                                            <span style={{ color: "#002867", fontWeight: "600", fontSize: "14px" }}>{searchData.length}</span>{" "}
                                                            {searchData.length > 1 ? "results found" : "result found"}
                                                        </p>
                                                    </div>
                                                </div>
                                            ) : null}

                                            <div style={{ overflow: "scroll", height: "calc(100vh - 210px)", padding: "0 0.2rem" }}>
                                                {searchData.length > 0 ? (
                                                    searchData.map((customer, index) => {
                                                        return (
                                                            <div key={index} onClick={() => selectCustomer(customer?.numCustomerID, index)} className={styles.card}>
                                                                <div
                                                                    style={{
                                                                        padding: "0 0 .5rem 0",
                                                                        borderBottom: "1px solid #F3F4F6",
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        alignItems: "center",
                                                                        gap: "1rem",
                                                                    }}
                                                                >
                                                                    <Avatar
                                                                        className={"medium"}
                                                                        img={
                                                                            customer?.varCustomerImagePath === null
                                                                                ? ""
                                                                                : `${process.env.REACT_APP_MEDIA_URL}directories/customers/${customer?.varCustomerImagePath}`
                                                                        }
                                                                        isVip={customer?.bitIsVip}
                                                                    />
                                                                    <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "0.2rem" }}>
                                                                        <div>
                                                                            <p
                                                                                style={{ margin: "0px", fontWeight: "600", fontSize: "14px", textTransform: "capitalize" }}
                                                                            >{`${customer?.varCustomerFirstName} ${customer?.varCustomerLastName}`}</p>
                                                                        </div>

                                                                        <div>
                                                                            <LocationChip
                                                                                label={customer?.varCustomerDefaultLocation === undefined ? "" : Truncate(customer?.varCustomerDefaultLocation, 19)}
                                                                                shape="square"
                                                                                icon={<Place style={{ fontSize: "13px" }} />}
                                                                                customStyle="BlueFilled"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div style={{ borderBottom: "1px solid #F3F4F6", display: "flex", justifyContent: "space-between", padding: "0.5rem 0" }}>
                                                                    <div style={{ width: "100%" }}>
                                                                        <p style={{ color: "gray", margin: "0", fontSize: "12px", fontWeight: "600" }}>Mobile</p>
                                                                        <p style={{ margin: "0", color: "#002867", fontWeight: "600" }}>{formatPhone(customer?.varCustomerMobilePhone)} </p>
                                                                    </div>
                                                                    <div style={{ width: "100%" }}>
                                                                        <p style={{ color: "gray", margin: "0", fontSize: "12px", fontWeight: "600" }}>Home</p>
                                                                        <p style={{ margin: "0", color: "#002867", fontWeight: "600" }}>{formatPhone(customer?.varCustomerHomePhone)} </p>
                                                                    </div>
                                                                </div>

                                                                {customer?.customerPet.length > 0
                                                                    ? customer?.customerPet.map((pet) => {
                                                                          return (
                                                                              <div>
                                                                                  <div>
                                                                                      <div style={{ display: "flex", alignItems: "center", gap: "1rem", padding: "0.5rem 0" }}>
                                                                                          <div style={{ padding: "0.5rem 0.8rem", backgroundColor: "#EFF5FF", borderRadius: "5px", fontWeight: "600" }}>
                                                                                              S
                                                                                          </div>
                                                                                          <div>
                                                                                              <p style={{ margin: "0", fontWeight: "600" }}>{pet?.varPetName}</p>
                                                                                              <p style={{ color: "gray", margin: "0", fontSize: "11px" }}>{pet?.varPetBreed}</p>
                                                                                          </div>
                                                                                      </div>
                                                                                  </div>
                                                                              </div>
                                                                          )
                                                                      })
                                                                    : null}
                                                            </div>
                                                        )
                                                    })
                                                ) : searchValue != "" ? (
                                                    <div style={{ width: "100%", textAlign: "center" }}>
                                                        <p>No results found</p>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div style={{ width: "314px" }}>
                        <div style={{ width: "100%", backgroundColor: "#fff", height: "48px", display: "flex", alignItems: "center", borderBottom: "1px solid #F3F4F6" }}>
                            <Button variant="text" color="default" className={styles.backButton} onClick={() => goBack()}>
                                <ArrowBack style={{ color: "#000", fontSize: "20px" }} />
                                Back
                            </Button>
                        </div>

                        <ValidatePermission allowedModules={["02 - Schedule"]} allowedSubRoutes={["Add New Pet"]}>
                            {addPetView ? (
                                <div style={{ backgroundColor: "#fff", padding: "1rem" }}>
                                    <form id="editFormPet" onSubmit={(e) => e.preventDefault()}>
                                        <p style={{ color: "#002867", fontSize: "14px", fontWeight: "600" }}>Add New Pet</p>
                                        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                            <TextField
                                                variant="standard"
                                                required={petLabels[12].bitRequired}
                                                label={
                                                    <p>
                                                        {petLabels[12].varLabelValue}
                                                        {petLabels[12].bitRequired ? (
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        ) : null}
                                                    </p>
                                                }
                                                value={petName}
                                                onChange={(e) => setPetName(e.target.value)}
                                                inputRef={addNewPetRef}
                                            />

                                            <TextField
                                                fullWidth
                                                variant="standard"
                                                select={!!typeData}
                                                required={petLabels[14].bitRequired}
                                                label={
                                                    <p>
                                                        {petLabels[14].varLabelValue}
                                                        {petLabels[14].bitRequired ? (
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        ) : null}
                                                    </p>
                                                }
                                                value={petType}
                                                onChange={(e) => {
                                                    setPetType(e.target.value)
                                                    getSizeData(e.target.value)
                                                }}
                                            >
                                                {typeData?.map((item, i) => {
                                                    return (
                                                        <MenuItem key={i} value={item.numPetTypeID} style={{ fontSize: `medium` }}>
                                                            {item.varPetTypeName}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </TextField>

                                            <div>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                    <p>Mixed Breed</p>
                                                    <Switch
                                                        onChange={(e) => {
                                                            setPetIsMix(!petIsMix)
                                                        }}
                                                        checked={petIsMix}
                                                        inputProps={{ "aria-label": "controlled" }}
                                                    />
                                                </div>

                                                {petIsMix ? (
                                                    <>
                                                        {noBreedData ? (
                                                            <>
                                                                <TextField
                                                                    fullWidth
                                                                    disabled={true}
                                                                    variant="standard"
                                                                    required={petLabels[15].bitRequired}
                                                                    label={
                                                                        <p>
                                                                            {petLabels[15].varLabelValue}
                                                                            {petLabels[15].bitRequired ? (
                                                                                <span
                                                                                    style={{
                                                                                        fontWeight: 500,
                                                                                        backgroundColor: "#FDEDED",
                                                                                        color: "#EF4444",
                                                                                        fontSize: "12px",
                                                                                        padding: "0 5px",
                                                                                        marginLeft: "5px",
                                                                                    }}
                                                                                >
                                                                                    Required
                                                                                </span>
                                                                            ) : null}
                                                                        </p>
                                                                    }
                                                                    value={primaryName}
                                                                    onChange={(e) => setPetBreed(e.target.value)}
                                                                ></TextField>
                                                            </>
                                                        ) : (
                                                            // <TextField
                                                            //     fullWidth
                                                            //     variant='standard'
                                                            //     select={!!MixBreedData}
                                                            //     required={petLabels[15].bitRequired}
                                                            //     label={
                                                            //         <p>
                                                            //             {petLabels[15].varLabelValue}
                                                            //             {
                                                            //                 (petLabels[15].bitRequired) ?
                                                            //                     <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                            //                 : null
                                                            //             }
                                                            //         </p>
                                                            //     }
                                                            //     value={ petBreed }
                                                            //     onChange={(e) => setPetBreed(e.target.value) }
                                                            // >
                                                            //     {MixBreedData?.map((item, i) => {
                                                            //         return (
                                                            //             <MenuItem key={i} value={item.numPetBreedID} style={{fontSize:  `medium`}} >
                                                            //                 {item.varPetBreedName}
                                                            //             </MenuItem>
                                                            //         );
                                                            //     })}
                                                            // </TextField>

                                                            <div style={{ padding: ".5rem 0 0 0", display: "flex", gap: "5px", alignItems: "center", width: "100%" }}>
                                                                <Tooltip
                                                                    title={
                                                                        <div style={{ backgroundColor: "#fff4c2", padding: "0px" }}>
                                                                            <p style={{ fontSize: "14px", color: "#000", fontWeight: "600" }}>Description</p>
                                                                            <p style={{ fontSize: "12px", color: "#000" }}>{primaryNameDesc}</p>
                                                                        </div>
                                                                    }
                                                                >
                                                                    <InfoOutlined style={{ fontSize: "17px", color: "#979797" }} />
                                                                </Tooltip>
                                                                <div style={{ width: "100%" }}>
                                                                    <AdvancedDropDown
                                                                        data={MixBreedData.map((i) => ({ label: `${i.varPetBreedName} (${i.varPetBreedDescription})`, value: i.numPetBreedID }))}
                                                                        onChange={(e) => {
                                                                            setPetBreed(e?.value)
                                                                        }}
                                                                        passByID={true}
                                                                        value={petBreed}
                                                                        ref={BreedRef}
                                                                        placeHolder={petLabels[15].varLabelValue}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}

                                                        <div style={{ padding: "1rem 0 0 0" }}>
                                                            <p style={{ margin: "0" }}>Please select Primary Breed</p>
                                                            <div style={{ padding: ".5rem 0 0 0", display: "flex", gap: "5px", alignItems: "center", width: "100%" }}>
                                                                <Tooltip
                                                                    title={
                                                                        <div style={{ backgroundColor: "#fff4c2", padding: "0px" }}>
                                                                            <p style={{ fontSize: "14px", color: "#000", fontWeight: "600" }}>Primary Breed</p>
                                                                        </div>
                                                                    }
                                                                >
                                                                    <InfoOutlined style={{ fontSize: "17px", color: "#979797" }} />
                                                                </Tooltip>
                                                                <div style={{ width: "100%" }}>
                                                                    <AdvancedDropDown
                                                                        data={breedMainData.map((i) => ({ label: i.varBreedName, value: i.numBreedID }))}
                                                                        onChange={(e) => {
                                                                            setPrimaryBreed(e?.value)
                                                                            setPrimaryBreedNameX(e?.label)
                                                                        }}
                                                                        passByID={true}
                                                                        value={primaryBreed}
                                                                        ref={primaryNameRef}
                                                                        placeHolder={<p>Primary Breed</p>}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <p style={{ margin: "10px 0 0 0" }}>Please select Secondary Breed</p>
                                                            <div style={{ padding: ".5rem 0 0 0", display: "flex", gap: "5px", alignItems: "center", width: "100%" }}>
                                                                <Tooltip
                                                                    title={
                                                                        <div style={{ backgroundColor: "#fff4c2", padding: "0px" }}>
                                                                            <p style={{ fontSize: "14px", color: "#000", fontWeight: "600" }}>Secondary Breed</p>
                                                                        </div>
                                                                    }
                                                                >
                                                                    <InfoOutlined style={{ fontSize: "17px", color: "#979797" }} />
                                                                </Tooltip>
                                                                <div style={{ width: "100%" }}>
                                                                    <AdvancedDropDown
                                                                        data={breedMainData.map((i) => ({ label: i.varBreedName, value: i.numBreedID }))}
                                                                        onChange={(e) => {
                                                                            setSecondaryBreed(e?.value)
                                                                            setSecondaryBreedNameX(e?.label)
                                                                            setSecondName(e?.label)
                                                                        }}
                                                                        passByID={true}
                                                                        value={secondaryBreed}
                                                                        ref={secondaryNameRef}
                                                                        placeHolder={<p>Secondary Breed</p>}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div style={{ padding: ".5rem 0 0 0" }}></div>

                                                        {/* 
                                                                        <TextField                                                                            
                                                                            variant={'standard'} 
                                                                            select={!!breedMainData}    
                                                                            fullWidth
                                                                            multiline={true}     
                                                                            InputLabelProps={{ shrink: true }}                                                                                        
                                                                            label={<p>Secondary Breed</p>} 
                                                                            value={ secondaryBreed } 
                                                                            onChange={(e) => {
                                                                                setSecondaryBreed(e.target.value)                                             
                                                                            }} 
                                                                        >
                                                                            {
                                                                                breedMainData?.map((item, i) => {
                                                                                    return (
                                                                                        <MenuItem onClick={() => setSecondName(item.varBreedName)} key={item.numBreedID} value={item.numBreedID}>
                                                                                            {item.varBreedName}
                                                                                        </MenuItem>
                                                                                    );
                                                                                })
                                                                            }                                                            
                                                                        </TextField> 
                                                                    */}
                                                    </>
                                                ) : (
                                                    // <TextField
                                                    //     fullWidth
                                                    //     variant='standard'
                                                    //     select={!!PureBreedData}
                                                    //     required={petLabels[15].bitRequired}
                                                    //     label={
                                                    //         <p>
                                                    //             {petLabels[15].varLabelValue}
                                                    //             {
                                                    //                 (petLabels[15].bitRequired) ?
                                                    //                     <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                    //                 : null
                                                    //             }
                                                    //         </p>
                                                    //     }
                                                    //     value={ petBreed }
                                                    //     onChange={(e) => setPetBreed(e.target.value) }
                                                    // >
                                                    //     {PureBreedData?.map((item, i) => {
                                                    //         return (
                                                    //             <MenuItem key={i} value={item.numPetBreedID} style={{fontSize:  `medium`}} >
                                                    //                 {item.varPetBreedName}
                                                    //             </MenuItem>
                                                    //         );
                                                    //     })}
                                                    // </TextField>

                                                    <div style={{ padding: ".5rem 0 0 0" }}>
                                                        <AdvancedDropDown
                                                            data={PureBreedData.map((i) => ({ label: `${i.varPetBreedName} (${i.varPetBreedDescription})`, value: i.numPetBreedID }))}
                                                            onChange={(e) => {
                                                                setPetBreed(e?.value)
                                                            }}
                                                            passByID={true}
                                                            value={petBreed}
                                                            ref={BreedRef}
                                                            placeHolder={petLabels[15].varLabelValue}
                                                        />
                                                    </div>
                                                )}
                                            </div>

                                            <InputMask
                                                alwaysShowMask="true"
                                                mask="99\lbs"
                                                maskChar="_"
                                                value={petWeight}
                                                onChange={(e) => {
                                                    setPetWeight(e.target.value)
                                                }}
                                            >
                                                {(inputProps) => (
                                                    <TextField
                                                        {...inputProps}
                                                        type="tel"
                                                        required={petLabels[19].bitRequired}
                                                        label={
                                                            <p>
                                                                {petLabels[19].varLabelValue}
                                                                {petLabels[19].bitRequired ? (
                                                                    <span
                                                                        style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}
                                                                    >
                                                                        Required
                                                                    </span>
                                                                ) : null}
                                                            </p>
                                                        }
                                                        variant="standard"
                                                    />
                                                )}
                                            </InputMask>

                                            <TextField
                                                fullWidth
                                                variant="standard"
                                                select={!!sizeData}
                                                required={petLabels[20].bitRequired}
                                                label={
                                                    <p>
                                                        {petLabels[20].varLabelValue}
                                                        {petLabels[20].bitRequired ? (
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        ) : null}
                                                    </p>
                                                }
                                                value={petSize}
                                                onChange={(e) => setPetSize(e.target.value)}
                                            >
                                                {sizeData?.map((item, i) => {
                                                    return (
                                                        <MenuItem key={i} value={item.numPetSizeID} style={{ fontSize: `medium` }}>
                                                            {item.varPetSizeName}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </TextField>

                                            <TextField
                                                fullWidth
                                                variant="standard"
                                                select={!!true}
                                                required={petLabels[21].bitRequired}
                                                label={
                                                    <p>
                                                        {petLabels[21].varLabelValue}
                                                        {petLabels[21].bitRequired ? (
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        ) : null}
                                                    </p>
                                                }
                                                value={petGender}
                                                onChange={(e) => setPetGender(e.target.value)}
                                            >
                                                <MenuItem key={1} value={"M"} style={{ fontSize: `medium` }}>
                                                    Male
                                                </MenuItem>

                                                <MenuItem key={2} value={"F"} style={{ fontSize: `medium` }}>
                                                    Female
                                                </MenuItem>
                                            </TextField>
                                        </div>

                                        <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: ".5rem", marginTop: "2rem" }}>
                                            <Button type={"submit"} onClick={() => createPet()} color="primary" variant="contained" text={"Create pet profile"} />
                                            <Button onClick={() => setAddPetView(false)} color="default" variant="text" text={"Cancel"} />
                                        </div>
                                    </form>
                                </div>
                            ) : null}
                        </ValidatePermission>

                        <div style={{ padding: ".8rem" }}>
                            {newCustomer ? (
                                <div>
                                    <p style={{ color: "#002867", fontSize: "14px", fontWeight: "600" }}>Selected Customer</p>
                                    <div className={styles.card}>
                                        <div style={{ padding: "0 0 .5rem 0", borderBottom: "1px solid #F3F4F6", display: "flex", justifyContent: "space-between", alignItems: "center", gap: "1rem" }}>
                                            <Avatar
                                                className={"medium"}
                                                img={
                                                    createdCustomer?.varCustomerImagePath === null
                                                        ? ""
                                                        : `${process.env.REACT_APP_MEDIA_URL}directories/customers/${createdCustomer?.varCustomerImagePath}`
                                                }
                                                isVip={createdCustomer?.bitIsVip}
                                            />
                                            <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "0.2rem" }}>
                                                <div>
                                                    <p
                                                        style={{ margin: "0px", fontWeight: "600", fontSize: "14px", textTransform: "capitalize" }}
                                                    >{`${searchData[currentCustomerIndex]?.varCustomerFirstName} ${searchData[currentCustomerIndex]?.varCustomerLastName}`}</p>
                                                </div>

                                                <div>
                                                    <LocationChip
                                                        label={createdCustomer?.varCustomerDefaultLocation === undefined ? "" : Truncate(createdCustomer?.varCustomerDefaultLocation, 19)}
                                                        shape="square"
                                                        icon={<Place style={{ fontSize: "13px" }} />}
                                                        customStyle="BlueFilled"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ borderBottom: "1px solid #F3F4F6", display: "flex", justifyContent: "space-between", padding: "0.5rem 0" }}>
                                            <div style={{ width: "100%" }}>
                                                <p style={{ color: "gray", margin: "0", fontSize: "12px", fontWeight: "600" }}>Mobile</p>
                                                <p style={{ margin: "0", color: "#002867", fontWeight: "600" }}>{formatPhone(createdCustomer?.varCustomerMobilePhone)} </p>
                                            </div>
                                            <div style={{ width: "100%" }}>
                                                <p style={{ color: "gray", margin: "0", fontSize: "12px", fontWeight: "600" }}>Home</p>
                                                <p style={{ margin: "0", color: "#002867", fontWeight: "600" }}>{formatPhone(createdCustomer?.varCustomerHomePhone)} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <p style={{ color: "#002867", fontSize: "14px", fontWeight: "600" }}>Selected Customer</p>
                                    <div className={styles.card}>
                                        <div style={{ padding: "0 0 .5rem 0", borderBottom: "1px solid #F3F4F6", display: "flex", justifyContent: "space-between", alignItems: "center", gap: "1rem" }}>
                                            <Avatar
                                                className={"medium"}
                                                img={
                                                    createdCustomer?.varCustomerImagePath === null
                                                        ? ""
                                                        : `${process.env.REACT_APP_MEDIA_URL}directories/customers/${createdCustomer?.varCustomerImagePath}`
                                                }
                                                isVip={createdCustomer?.bitIsVip}
                                            />
                                            <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "0.2rem" }}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <p
                                                        style={{ margin: "0px", fontWeight: "600", fontSize: "14px", textTransform: "capitalize" }}
                                                    >{`${createdCustomer?.varCustomerFirstName} ${createdCustomer?.varCustomerLastName}`}</p>
                                                    {showAlert ? (
                                                        <IconButton
                                                            onClick={() => {
                                                                setIsAlertsModal(true)
                                                            }}
                                                            style={{ margin: "0" }}
                                                        >
                                                            <WarningAmber style={{ color: "red", fontSize: "20px" }} />
                                                        </IconButton>
                                                    ) : null}
                                                </div>
                                                <CustomerAlerts isOpenAlertsModal={isOpenAlertsModal} setIsAlertsModal={(value) => setIsAlertsModal(value)} data={createdCustomer} />
                                                <div>
                                                    <LocationChip
                                                        label={createdCustomer?.varCustomerDefaultLocation === undefined ? "" : Truncate(createdCustomer?.varCustomerDefaultLocation, 19)}
                                                        shape="square"
                                                        icon={<Place style={{ fontSize: "13px" }} />}
                                                        customStyle="BlueFilled"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ borderBottom: "1px solid #F3F4F6", display: "flex", justifyContent: "space-between", padding: "0.5rem 0" }}>
                                            <div style={{ width: "100%" }}>
                                                <p style={{ color: "gray", margin: "0", fontSize: "12px", fontWeight: "600" }}>Mobile</p>
                                                <p style={{ margin: "0", color: "#002867", fontWeight: "600" }}>{formatPhone(createdCustomer?.varCustomerMobilePhone)} </p>
                                            </div>
                                            <div style={{ width: "100%" }}>
                                                <p style={{ color: "gray", margin: "0", fontSize: "12px", fontWeight: "600" }}>Home</p>
                                                <p style={{ margin: "0", color: "#002867", fontWeight: "600" }}>{formatPhone(createdCustomer?.varCustomerHomePhone)} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: ".5rem", margin: ".5rem 0 .8rem 0" }}>
                                <ValidatePermission allowedModules={["02 - Schedule"]} allowedSubRoutes={["Add New Pet"]}>
                                    <Button onClick={() => setAddPetView(true)} color="primary" variant="contained" text={"Add New Pet +"} />
                                </ValidatePermission>
                            </div>

                            <div style={{ overflowY: "scroll", height: "calc(100vh - 400px)" }}>
                                {isLoading ? (
                                    <p>Loading...</p>
                                ) : createdCustomer?.customerPet != undefined && createdCustomer?.customerPet.length > 0 ? (
                                    createdCustomer?.customerPet.map((pet) => {
                                        return (
                                            <div className={styles.card}>
                                                <div>
                                                    <div style={{ display: "flex", alignItems: "center", gap: "1rem", padding: "0.2rem 0", justifyContent: "space-between" }}>
                                                        <div>
                                                            <p style={{ fontSize: "16px", margin: "0", fontWeight: "600", textTransform: "capitalize" }}>{pet?.varPetName}</p>
                                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                <p style={{ color: "gray", margin: "0", fontSize: "11px" }}>{pet?.varPetBreed === null ? "N/A" : pet?.varPetBreed}</p>
                                                                {pet?.isBathOnlyPet ? (
                                                                    <Tooltip
                                                                        title={
                                                                            <>
                                                                                <p style={{ fontSize: "12px", color: "#fff" }}>{"Bath type dog"}</p>
                                                                            </>
                                                                        }
                                                                    >
                                                                        <ShowerOutlinedIcon style={{ fontSize: "20px" }} />
                                                                    </Tooltip>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        {pet?.varPetImagePath == null ? (
                                                            <div
                                                                style={{
                                                                    padding: ".8rem",
                                                                    backgroundColor: "#005df1db",
                                                                    display: "flex",
                                                                    borderRadius: "5px",
                                                                    color: "#fff",
                                                                }}
                                                            >
                                                                <Pets />
                                                            </div>
                                                        ) : (
                                                            <Avatar
                                                                className={"medium"}
                                                                shape="rounded"
                                                                img={
                                                                    pet?.varPetImagePath == "" || pet?.varPetImagePath == null
                                                                        ? placeholderImage
                                                                        : `${process.env.REACT_APP_MEDIA_URL}directories/pets/` + pet?.varPetImagePath
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: ".5rem" }}>
                                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", gap: ".8rem" }}>
                                                        {/* <MaterialButton
                                                                style={{
                                                                    width: '100%',
                                                                    textTransform: 'initial',
                                                                    fontSize: '12px'
                                                                }}
                                                                onClick={() => null}
                                                                variant='outlined'
                                                                fullWidth={true}
                                                            >
                                                                Pet Profile
                                                            </MaterialButton> */}
                                                        {/* <div  style={{
                                                                    width: '100%'
                                                                }}></div> */}

                                                        <MaterialButton
                                                            style={{
                                                                width: "100%",
                                                                textTransform: "initial",
                                                                fontSize: "12px",
                                                            }}
                                                            fullWidth={true}
                                                            onClick={() => {
                                                                const list: any = [...petList]
                                                                let isAlreadyAdded = list.some((petA) => {
                                                                    return petA.id === pet?.numPetID
                                                                })
                                                                if (!isAlreadyAdded) {
                                                                    console.log("pettt", pet)
                                                                    list.push({
                                                                        id: pet?.numPetID,
                                                                        name: pet?.varPetName,
                                                                        breed: pet?.varPetBreed,
                                                                        size: pet?.varPetSize,
                                                                        img: pet?.varPetImagePath,
                                                                        preferredGroomer: pet?.numPetPreferredGroomerID,
                                                                        mainService: {
                                                                            id: null,
                                                                            name: null,
                                                                            price: null,
                                                                            varServiceProductDescription: null,
                                                                            decUnit_Price: null,
                                                                            taxAmount: null,
                                                                            orderID: null,
                                                                            bitActive: null,
                                                                            numLocationServiceProductItemID: null,
                                                                            numSalesTaxPercentage: null,
                                                                        },
                                                                        additionalService: [],
                                                                        selectedProducts: [],
                                                                        petType: pet?.numPetTypeID,
                                                                        customerID: currentCustomer,
                                                                        weight: pet?.numPetWeight,
                                                                        breedID: pet?.numPetBreedID,
                                                                        sizeID: pet?.numPetSizeID,
                                                                        customer: createdCustomer,
                                                                        petPersonality: pet?.petPersonality,
                                                                    })
                                                                }
                                                                setPetList(list)
                                                                showPetAlert(pet?.petPersonality)
                                                            }}
                                                            variant="outlined"
                                                        >
                                                            Add
                                                        </MaterialButton>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                ) : (
                                    <p>No pets found!</p>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                <div style={{ width: "calc(100% - 710px)", backgroundColor: "#f9fafb" }}>
                    <div>
                        {currentCustomer === "" ? (
                            <Profile id={createdCustomer.numCustomerID} petList={petList} type={"bookingBlank"} />
                        ) : (
                            <Profile
                                id={createdCustomer.numCustomerID}
                                customerBooking={createdCustomer}
                                petList={petList}
                                removeFromList={(petID) => removePetFromList(petID)}
                                type={"booking"}
                                currentLoc={currentLocation}
                                pastSelectedAppointment={(e) => {
                                    setSelectedPastAppointment(e)
                                }}
                                pastAppList={(e) => {
                                    setPastAppointmentList(e)
                                }}
                            />
                        )}
                    </div>
                </div>

                {selectedPastAppointment === "" ? null : pastAppointmentList[selectedPastAppointment].BeforeImgPath === null && pastAppointmentList[selectedPastAppointment].AfterImagePath === null ? (
                    <p style={{ color: "gray", textAlign: "center" }}>No images from past appointments</p>
                ) : (
                    <div style={{ backgroundColor: "#fff" }}>
                        <div style={{ width: "100%", borderRadius: ".5rem" }}>
                            {pastAppointmentList.length > 0 ? (
                                <div style={{ marginTop: "20px", display: "flex", flexDirection: "column", gap: "10px" }}>
                                    <ImgComparisonSlider style={{ width: "90%", height: "auto" }}>
                                        <img
                                            style={{ height: "100%", width: "100%", objectFit: "cover" }}
                                            alt="404"
                                            slot="first"
                                            src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${pastAppointmentList[selectedPastAppointment]?.BeforeImgPath}`}
                                        />
                                        <img
                                            style={{ height: "100%", width: "100%", objectFit: "cover" }}
                                            alt="404"
                                            slot="second"
                                            src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${pastAppointmentList[selectedPastAppointment]?.AfterImagePath}`}
                                        />
                                    </ImgComparisonSlider>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <Button
                                            color="default"
                                            variant="outlined"
                                            text="View groomer note"
                                            onClick={() => {
                                                openGroomerNote(pastAppointmentList[selectedPastAppointment]?.GroomerNoteSummary)
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div style={{ display: "flex", alignItems: "center", padding: "2rem", gap: "1rem", justifyContent: "center" }}>
                                    <p>--</p>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {/* <div style={{ width: '392px', backgroundColor:"white" }}>
                    <div style={{ padding: '1.5rem' }}></div>
                    <div style={{display:"flex", alignItems:'center', justifyContent:"center", borderBottom:"2px solid #C3DAFF", borderRadius: "4px 4px 0px 0px", backgroundColor:"#F9FAFB", marginBottom:"20px", padding:"10px"}}>
                        <p style={{fontSize:"16", fontWeight:"600", color:"#000000"}}>Booking Summary</p>
                    </div>
                    <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between", padding:"0 20px 20px 20px"}}>
                        <div>
                            <div>
                                <div style={{display:"flex", gap:"10px", backgroundColor:"#EFF5FF", borderRadius: "8px 8px 0px 0px", padding:"10px", borderTop:"3px solid #2076FF"}}>
                                <Avatar className={'medium'} shape='rounded' img={"http://esypet.media.esyntaxis.com/directories/pets/24.jpg"} /> 
                                <div>
                                    <p style={{fontSize:"16px", fontWeight:"600", color:"#002867"}}>Pinky</p>
                                    <p style={{fontSize:"14px", fontWeight:"400", color:"#002867"}}>Pomeranian</p>
                                </div> 
                                </div>
                            </div>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <div>Services</div>
                                </AccordionSummary>
                                <div style={{padding:" 0 10px"}}>
                                    <div style={{display:"flex", justifyContent:"space-between"}}>
                                        <p>Full Groom</p>
                                        <p>$12.5</p>
                                    </div>
                                    <div style={{display:"flex", justifyContent:"space-between"}}>
                                        <p>Blueberry facial</p>
                                        <p>$19.5</p>
                                    </div>
                                </div>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <div>Products</div>
                                </AccordionSummary>
                                <div style={{padding:"0 10px"}}>
                                    <div style={{display:"flex", justifyContent:"space-between"}}>
                                        <p>Red neck belt 16'</p>
                                        <p>$10</p>
                                    </div>
                                    <div style={{display:"flex", justifyContent:"space-between"}}>
                                        <p>Nail Cutter</p>
                                        <p>$5</p>
                                    </div>
                                </div>
                            </Accordion>
                        </div>
                        <div>
                            <div>
                                <div style={{display:"flex", gap:"10px", backgroundColor:"#EFF5FF", borderRadius: "8px 8px 0px 0px", padding:"10px", borderTop:"3px solid #2076FF"}}>
                                    <Avatar className={'medium'} shape='rounded' img={"http://esypet.media.esyntaxis.com/directories/pets/24.jpg"} /> 
                                    <div>
                                        <p style={{fontSize:"16px", fontWeight:"600", color:"#002867"}}>Pinky</p>
                                        <p style={{fontSize:"14px", fontWeight:"400", color:"#002867"}}>Pomeranian</p>
                                    </div> 
                                </div>
                            </div>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <div>Services</div>
                                </AccordionSummary>
                                <div style={{padding:" 0 10px"}}>
                                    <div style={{display:"flex", justifyContent:"space-between"}}>
                                        <p>Full Groom</p>
                                        <p>$12.5</p>
                                    </div>
                                    <div style={{display:"flex", justifyContent:"space-between"}}>
                                        <p>Blueberry facial</p>
                                        <p>$19.5</p>
                                    </div>
                                </div>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <div>Products</div>
                                </AccordionSummary>
                                <div style={{padding:"0 10px"}}>
                                    <div style={{display:"flex", justifyContent:"space-between"}}>
                                        <p>Red neck belt 16'</p>
                                        <p>$10</p>
                                    </div>
                                    <div style={{display:"flex", justifyContent:"space-between"}}>
                                        <p>Nail Cutter</p>
                                        <p>$5</p>
                                    </div>
                                </div>
                            </Accordion>
                        </div>
                        <div>summery</div>
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default Booking
