import { useEffect, useMemo, useRef, useState, useCallback } from "react"
import AdvancedDropDown from "../../../../../components/AdvancedDropDown/AdvancedDropDown"
import { axiosPrivate, buildHeaderObject } from "../../../../../apis/axios"

import { toast } from "react-toastify"
import styles from "./ScheduleTab.module.scss"
import { Chip, CircularProgress, IconButton, LinearProgress, TextField, Tooltip } from "@mui/material"
import { Button, CloseButton } from "../../../../../components"
import { CalendarToday, Cancel, Close, DarkMode, Edit, Fullscreen, FullscreenExit, InfoOutlined, LightMode } from "@mui/icons-material"
import { CalendarNav, CalendarNext, CalendarPrev, Eventcalendar, MbscCalendarEvent, SegmentedGroup, SegmentedItem, momentTimezone, Button as MobiButton, Popup } from "@mobiscroll/react"
import moment from "moment-timezone"
import { Modal } from "../../../../../components/components"
import wait from "../../../../../images/cal-wait.png"
import { formatDateTimeScheduler } from "../../../../../utils/TimeHandler"
import Tree from "react-d3-tree"
import { Truncate } from "../../../../../utils/Validation"
import useAuth from "../../../../../hooks/useAuth"
momentTimezone.moment = moment

const ScheduleTab = (props) => {
    const locationRef = useRef()
    const employeeRef = useRef()

    const [location, setLocation] = useState(0)
    const [employee, setEmployee] = useState(0)

    const [isLoadingList, setIsLoadingList] = useState(false)
    const [templateLoading, setTemplateLoading] = useState(false)
    const [eventsListLoading, setEventsListLoading] = useState(false)

    const [isOpenNewTemplate, setIsOpenNewTemplate] = useState(false)
    const [templateName, setTemplateName] = useState("")
    const [templateTimeInterval, setTemplateTimeInterval] = useState(0)
    const [templateLocation, setTemplateLocation] = useState(0)
    const [templateEmployee, setTemplateEmployee] = useState(0)
    const templateLocationRef = useRef()
    const templateEmployeeRef = useRef()

    const { auth } = useAuth() as any

    const [eventDetails, setEventDetails] = useState({}) as any

    const [employeeLoading, setEmployeeLoading] = useState(false)

    const [isOpenNewTemplateEdit, setIsOpenNewTemplateEdit] = useState(false)
    const [editTemplateID, setEditTemplateID] = useState(0)
    const [templateNameEdit, setTemplateNameEdit] = useState("")
    const [templateTimeIntervalEdit, setTemplateTimeIntervalEdit] = useState(0)
    const [templateLocationEdit, setTemplateLocationEdit] = useState(0)
    const [templateEmployeeEdit, setTemplateEmployeeEdit] = useState(0)
    const templateLocationRefEdit = useRef()
    const templateEmployeeRefEdit = useRef()

    const [locationList, setLocationList] = useState([]) as any
    const [employeeList, setEmployeeList] = useState([]) as any
    const [employeeListEdit, setEmployeeListEdit] = useState([]) as any

    const [isOpenEventInfo, setIsOpenEventInfo] = useState(false)

    const [selectedTemplate, setSelectedTemplate] = useState({
        varEmployeeFirstName: "",
        varEmployeeLastName: "",
        varLocationName: "",
        TemplateEmployeeID: 0,
        TemplateLocationID: 0,
        TemplateID: 0,
        selectedName: "",
        timeInterval: 0,
        EmployeeType: "",
    })

    async function getLocations() {
        setIsLoadingList(true)
        const result = await axiosPrivate.get(`Location/GetAll`, buildHeaderObject(auth))
        return result.data.location
    }

    async function getEmployeesByLocation() {
        const result = await axiosPrivate.get(`Schedule/GetEmployeesByLocationID?LocationID=${location === 0 ? "" : location}`, buildHeaderObject(auth))
        return result.data
    }

    async function getEmployeesByLocationEdit() {
        const result = await axiosPrivate.get(`Schedule/GetEmployeesByLocationID?LocationID=${templateLocation === 0 ? "" : templateLocation}`, buildHeaderObject(auth))
        return result.data
    }

    async function getTemplateList() {
        setTemplateLoading(true)
        const result = await axiosPrivate.get(`Schedule/GetTemplatesByEmpID?LocationID=${location === 0 ? "" : location}&EmployeeID=${employee === 0 ? "" : employee}`, buildHeaderObject(auth))
        console.log("tempaltes list", result.data)
        return result.data
    }

    async function getEventsByTemplate() {
        setEventsListLoading(true)
        const result = await axiosPrivate.get(`Schedule/GetTimeSlotsByTemplateID?TemplateID=${selectedTemplate?.TemplateID === 0 ? "" : selectedTemplate?.TemplateID}`, buildHeaderObject(auth))
        return result.data
    }

    async function getTemplateListCustom(cLocation, cEmployee) {
        const result = await axiosPrivate.get(`Schedule/GetTemplatesByEmpID?LocationID=${cLocation === 0 ? "" : cLocation}&EmployeeID=${cEmployee === 0 ? "" : cEmployee}`, buildHeaderObject(auth))
        console.log("tempaltes list", result.data)
        return result.data
    }

    async function getEventsByTemplateCustom(cSelectedTemplate) {
        const result = await axiosPrivate.get(`Schedule/GetTimeSlotsByTemplateID?TemplateID=${cSelectedTemplate === 0 ? "" : cSelectedTemplate}`, buildHeaderObject(auth))
        return result.data
    }

    async function getEventDetails(eventID) {
        const result = await axiosPrivate.get(`Schedule/GetEventDetailsByEventID?EventID=${eventID}`, buildHeaderObject(auth))
        return result.data
    }

    async function getAvailableEvent(employeeID, locationID) {
        console.log("AAAAA", employeeID, locationID)

        const result = await axiosPrivate.get(`Schedule/GetAllEventsByLocationAndEmployee?LocationID=${locationID === 0 ? "" : locationID}&EmployeeID=${employeeID}`, buildHeaderObject(auth))
        return result.data
    }

    async function getAvailableDealEmployees(dealSlotStartTimestamp, dealSlotEndTimestamp, dealSlotLocationID) {
        const result = await axiosPrivate.get(`Schedule/GetBathersByTime?StartTime=${dealSlotStartTimestamp}&EndTime=${dealSlotEndTimestamp}&LocationID=${dealSlotLocationID}`, buildHeaderObject(auth))
        return result.data
    }

    async function deleteSlot(slotID) {
        const result = await axiosPrivate.delete(`Schedule/DeleteTimeSlotByID?TimeSlotID=${slotID}`, buildHeaderObject(auth))
        return result.data
    }

    /* TEMPLATES LIST */
    const [templateList, setTemplateList] = useState([]) as any

    const [filterTextTemplates, setFilterTextTemplates] = useState("")
    const [filteredItemsTemplates, setFilteredItemsTemplates] = useState([]) as any

    const resetTemplates = () => {
        setFilterTextTemplates("")
        setFilteredItemsTemplates(templateList)
    }

    const filterItemsTemplates = (filterText) => {
        const filteredItems = templateList.filter((item) => item.TemplateName.toLowerCase().includes(filterText.toLowerCase()))
        setFilteredItemsTemplates(filteredItems)
    }

    const handleFilterChangeTemplates = (event) => {
        const filterText = event.target.value
        setFilterTextTemplates(filterText)
        filterItemsTemplates(filterText)
    }

    const getAllTemplates = () => {
        getTemplateList()
            .then((response) => {
                let res = response

                setTemplateList(res)
                setFilteredItemsTemplates(res)

                setTemplateLoading(false)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
                setTemplateLoading(false)
            })
    }

    useEffect(() => {
        getLocations()
            .then((response) => {
                let res = response
                res.unshift({
                    varLocationName: "All Locations",
                    numLocationID: 0,
                })
                setLocationList(response)

                console.info(response)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
                setIsLoadingList(false)
            })

        const preventContextMenu = (e) => {
            e.preventDefault()
        }

        document.addEventListener("contextmenu", preventContextMenu)

        return () => {
            document.removeEventListener("contextmenu", preventContextMenu)
        }
    }, [])

    useEffect(() => {
        setEmployeeLoading(true)
        getEmployeesByLocation()
            .then((response) => {
                let res = response

                res.unshift({
                    varEmployeeFirstName: "All Employees",
                    varEmployeeLastName: "",
                    numEmployeeID: 0,
                })

                setEmployeeList(res)
                setEmployeeLoading(false)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
                setEmployeeLoading(false)
            })
    }, [location])

    useEffect(() => {
        getEmployeesByLocationEdit()
            .then((response) => {
                let resEdit = response
                setEmployeeListEdit(resEdit)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }, [templateLocation])

    useEffect(() => {
        getAllTemplates()
    }, [location, employee])

    useEffect(() => {
        setEmployee(0)
    }, [employeeList])

    /* CALENDAR */
    const [myEvents, setEvents] = useState<MbscCalendarEvent[]>([]) as any

    const [calView, setCalView] = useState({
        calendar: { labels: true },
    })

    const [view, setView] = useState("week")
    const [timeInterval, setTimeInterval] = useState(10)

    const [eventSelectionList, setEventSelectionList] = useState([]) as any
    const [dealEmployeeSelectionList, setDealEmployeeSelectionList] = useState([]) as any

    const [currentEventHasBath, setCurrentEventHasBath] = useState(false)

    const onEventChange = (e) => {
        dealRef.current?.handleClear()
        setCurrentEventHasBath(e?.isBath)
        setSelectedEvent(e)
        if (e?.isBath) {
            let startDealTime = formatDateTimeScheduler(tempEvent?.start)
            let endDealTime = formatDateTimeScheduler(tempEvent?.end)

            getAvailableDealEmployees(startDealTime, endDealTime, selectedTemplate?.TemplateLocationID)
                .then((response) => {
                    let resDealList = response

                    if (resDealList?.length > 0) {
                        setDealEmployeeSelectionList(resDealList)
                    } else {
                        setDealEmployeeSelectionList([])
                    }
                    console.log("deal list", resDealList)
                })
                .catch((err) => {
                    console.log(err)
                    toast.error("Something went wrong!")
                })
        } else {
            setDealEmployeeSelectionList([])
        }
    }

    const getSelectedTemplateEvents = () => {
        if (selectedTemplate?.TemplateID !== 0) {
            getEventsByTemplate()
                .then((response) => {
                    let res = response

                    setTimeInterval(selectedTemplate?.timeInterval)

                    const createEvents = res.map((event) => {
                        return {
                            start: event?.StartTime,
                            end: event?.EndTime,
                            title: event?.EventName,
                            color: event?.EventColor,
                            resourceData: { id: selectedTemplate?.TemplateEmployeeID, value: selectedTemplate?.selectedName, event_id: event?.EventID, slot_id: event?.TimeSlotID },
                            resource: selectedTemplate?.TemplateEmployeeID,
                        }
                    })
                    setEvents(createEvents)

                    setEventsListLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    toast.error("Something went wrong!")
                    setEventsListLoading(false)
                })

            getAvailableEvent(selectedTemplate?.TemplateEmployeeID, selectedTemplate?.TemplateLocationID)
                .then((response) => {
                    let res = response
                    console.log("response events", response)

                    setEventSelectionList(res)
                })
                .catch((err) => {
                    console.log(err)
                    toast.error("Something went wrong!")
                })
        }
    }

    useEffect(() => {
        changeView(view)
    }, [myEvents])

    const changeView = (event) => {
        let calView

        switch (event) {
            case "month":
                calView = {
                    calendar: {
                        type: "month",
                        labels: true,
                        timeCellStep: timeInterval,
                        timeLabelStep: timeInterval,
                    },
                    schedule: {
                        allDay: false,
                        startTime: "06:00",
                        endTime: "19:00",
                    },
                }
                break
            case "week":
                calView = {
                    schedule: {
                        labels: true,
                        type: "week",
                        timeCellStep: timeInterval,
                        timeLabelStep: timeInterval,
                        startTime: "06:00",
                        endTime: "19:00",
                    },
                }
                break
            case "day":
                calView = {
                    schedule: {
                        allDay: false,
                        labels: true,
                        type: "day",
                        timeCellStep: timeInterval,
                        timeLabelStep: timeInterval,
                        startTime: "06:00",
                        endTime: "19:00",
                    },
                }
                break
        }

        setView(event)
        setCalView(calView)
    }

    const [fullScreen, setFullScreen] = useState(false)
    const [darkMode, setDarkMode] = useState(false)
    const [fullScreenNet, setFullScreenNet] = useState(false)

    const customWithNavButtons = () => {
        return (
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <CalendarNav className="cal-header-nav" />
                    <div className="cal-header-picker">
                        <SegmentedGroup value={view} onChange={(e) => changeView(e.target.value)}>
                            <SegmentedItem value="week">Week</SegmentedItem>
                            <SegmentedItem value="day">Day</SegmentedItem>
                            <SegmentedItem value="month">Month</SegmentedItem>
                        </SegmentedGroup>
                    </div>
                    <CalendarPrev className="cal-header-prev" />
                    <CalendarToday className="cal-header-today" />
                    <CalendarNext className="cal-header-next" />
                </div>
                <div style={{ marginRight: "10px" }}>
                    <IconButton color="primary" onClick={() => setDarkMode(!darkMode)}>
                        {darkMode ? <DarkMode style={{ fontSize: "20px" }} /> : <LightMode style={{ fontSize: "20px" }} />}
                    </IconButton>
                    <IconButton color="primary" onClick={() => setFullScreen(!fullScreen)}>
                        {fullScreen ? <FullscreenExit /> : <Fullscreen />}
                    </IconButton>
                </div>
            </div>
        )
    }

    const memoizedEvents = useMemo(() => {
        return myEvents.map((event) => {
            return {
                ...event,
            }
        })
    }, [myEvents])

    async function newTemplate(data, type) {
        await axiosPrivate
            .post(`Schedule/PopulateTemplate`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                if (result.status === 200) {
                    console.warn(`res`, result)
                    if (type === "new") {
                        toast.success("Template creation successful!")
                        setIsOpenNewTemplate(false)
                    } else {
                        toast.success("Template updated successfully!")
                        setIsOpenNewTemplate(false)
                    }
                    getAllTemplates()
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const createTemplate = () => {
        if (templateName === "" || templateName === null) {
            toast.info("Please enter a valid template Name")
        } else if (templateEmployee === 0 || templateEmployee === null) {
            toast.info("Please select an Employee")
        } else if (templateTimeInterval < 5 || isNaN(templateTimeInterval)) {
            toast.info("Please enter a valid time interval (>=5)")
        } else {
            let data = {
                templateID: 0,
                templateName: templateName,
                templateEmployeeID: templateEmployee,
                templateLocationID: templateLocation,
                templateTimeInterval: templateTimeInterval,
                userID: 1,
            }

            newTemplate(data, "new")
            console.log("createTemplate", data)
        }
    }

    useEffect(() => {
        if (isOpenNewTemplateEdit) {
            const filteredTemplate = templateList.filter((template) => template.TemplateID === editTemplateID)
            setTemplateNameEdit(filteredTemplate[0]?.TemplateName)
            setTemplateTimeIntervalEdit(filteredTemplate[0]?.TemplateTimeInterval)
            setTemplateLocationEdit(filteredTemplate[0]?.TemplateLocationID)
            setTemplateEmployeeEdit(filteredTemplate[0]?.TemplateEmployeeID)
        }
    }, [isOpenNewTemplateEdit])

    const updateTemplate = () => {
        if (templateNameEdit === "" || templateNameEdit === null) {
            toast.info("Please enter a valid template Name")
        } else if (templateEmployeeEdit === 0 || templateEmployeeEdit === null) {
            toast.info("Please select an Employee")
        } else if (templateTimeIntervalEdit < 5 || isNaN(templateTimeIntervalEdit)) {
            toast.info("Please enter a valid time interval (>=5)")
        } else {
            let data = {
                templateID: editTemplateID,
                templateName: templateNameEdit,
                templateEmployeeID: templateEmployeeEdit,
                templateLocationID: templateLocationEdit,
                templateTimeInterval: templateTimeIntervalEdit,
                userID: 1,
            }

            newTemplate(data, "edit")
            console.log("updateTemplate", data)
        }
    }

    /* EVENTS */
    const [isOpenNewEvent, setIsOpenNewEvent] = useState(false)
    const eventRef = useRef()
    const dealRef = useRef() as any
    const dealRefCal = useRef() as any
    const eventRefEdit = useRef()

    const [resources, setResources] = useState([]) as any

    const [isOpenEditEvent, setIsOpenEditEvent] = useState(false)
    const [selectedEditEvent, setSelectedEditEvent] = useState({ value: 0, label: "" })

    const [selectedEvent, setSelectedEvent] = useState({ value: 0, label: "" })
    const [selectedDealEmployee, setSelectedDealEmployee] = useState({ value: "", label: "", numEmployeeID: 0, TimeSlotID: 0, StartTime: "", EndTime: "" })
    const [selectedCalendarDealEmployee, setSelectedCalendarDealEmployee] = useState([]) as any

    const [newIsLoading, setNewIsLoading] = useState(false)
    const [editIsLoading, setEditIsLoading] = useState(false)

    const [tempEvent, setTempEvent] = useState({
        allDay: false,
        end: "",
        id: "mbsc_19",
        resourceData: { event_id: 0, slot_id: 0 },
        slot: undefined,
        start: "",
        title: "New event",
        color: "#232323",
    })

    async function addNewSlot(data, type) {
        await axiosPrivate
            .post(`Schedule/PopulateTimeSLots`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                if (result.status === 200) {
                    console.warn(`res`, result)
                    if (type === "new") {
                        toast.success("Slot created!")
                        const tmpTempEvent = { ...tempEvent }
                        const tmpMyEvents = [...memoizedEvents]
                        tmpTempEvent.resourceData.slot_id = result.data
                        tmpMyEvents.push(tmpTempEvent)
                        setEvents(tmpMyEvents)
                        setIsOpenNewEvent(false)
                    } else {
                        console.log("updated memo", memoizedEvents)
                        toast.success("Slot Updated!")
                    }
                    setNewIsLoading(false)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")

                setNewIsLoading(false)
            })
    }

    async function addNewSlotDeal(data, type) {
        await axiosPrivate
            .post(`Schedule/PopulateTimeSLots`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                if (result.status === 200) {
                    console.warn(`res`, result)
                    toast.success("Deal created!")

                    setNewIsLoading(false)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")

                setNewIsLoading(false)
            })
    }

    async function addNewSlotBulk(data, type) {
        await axiosPrivate
            .post(`Schedule/PopulateTimeSLots`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                if (result.status === 200) {
                    console.warn(`res`, result)

                    getSelectedTemplateEvents()
                    setSelectedEvents([])
                    setCopiedEvents([])
                    closeToast()

                    setNewIsLoading(false)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")

                setNewIsLoading(false)
            })
    }

    useEffect(() => {
        setResources([{ id: selectedTemplate?.TemplateEmployeeID, value: selectedTemplate.selectedName }])

        getSelectedTemplateEvents()
        dealRefCal.current?.handleClear()
        setDealCalendarEmployees([])
        setSelectedCalendarDealEmployee([])
    }, [selectedTemplate])

    useEffect(() => {
        console.log("Events State", myEvents)
    }, [myEvents])

    async function editExistingSlot(data) {
        await axiosPrivate
            .post(`Schedule/PopulateTimeSLots`, JSON.stringify(data), buildHeaderObject(auth))
            .then((result) => {
                if (result.status === 200) {
                    console.warn(`res`, result)
                    toast.success("Slot Updated!")

                    setEvents(() => {
                        let eventPosition = memoizedEvents.indexOf(...memoizedEvents.filter((item) => item.resourceData.slot_id === tempEvent?.resourceData.slot_id))

                        memoizedEvents[eventPosition].title = tempEvent?.title
                        memoizedEvents[eventPosition].color = tempEvent?.color
                        memoizedEvents[eventPosition].resourceData.event_id = tempEvent?.resourceData.event_id

                        return memoizedEvents
                    })

                    setEditIsLoading(false)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")

                setEditIsLoading(false)
            })
    }

    const addEvent = () => {
        setNewIsLoading(true)

        let payload = {
            timeSlotID: 0,
            templateID: selectedTemplate?.TemplateID,
            eventID: selectedEvent?.value,
            startTime: formatDateTimeScheduler(tempEvent?.start),
            endTime: formatDateTimeScheduler(tempEvent?.end),
            userID: 1,
            dealEmpID: selectedDealEmployee?.numEmployeeID,
            dealTimeSlotID: selectedDealEmployee?.TimeSlotID,
        }

        console.log("addEvent", payload)
        addNewSlot([payload], "new")
    }

    useEffect(() => {
        const currEvent = eventSelectionList.filter((e) => e?.EventID === selectedEvent?.value)[0]
        const tmpEvent = { ...tempEvent }

        tmpEvent.title = currEvent?.EventName
        tmpEvent.color = currEvent?.EventColor
        tmpEvent.resourceData.event_id = currEvent?.EventID

        setTempEvent(tmpEvent)
    }, [selectedEvent])

    useEffect(() => {
        const currEvent = eventSelectionList.filter((e) => e?.EventID === selectedEditEvent?.value)[0]
        const tmpEvent = { ...tempEvent }

        tmpEvent.title = currEvent?.EventName
        tmpEvent.color = currEvent?.EventColor
        tmpEvent.resourceData.event_id = currEvent?.EventID

        setTempEvent(tmpEvent)
    }, [selectedEditEvent])

    const onEventCreate = (eventInfo) => {
        setIsOpenNewEvent(true)
        setSelectedEvent({ value: 0, label: "Select Event" })

        const tmpEvent = { ...tempEvent }
        tmpEvent.end = eventInfo?.event?.end
        tmpEvent.start = eventInfo?.event?.start
        tmpEvent.id = eventInfo?.event?.id
        tmpEvent.resourceData.event_id = 55555
        setTempEvent(tmpEvent)

        console.log("evnt", eventInfo)

        dealRef.current?.handleClear()

        setDealEmployeeSelectionList([])

        // return false
    }

    const onEventResize = (eventInfo) => {
        if (eventInfo.event.resourceData != undefined) {
            let payload = {
                timeSlotID: eventInfo?.event?.resourceData?.slot_id,
                templateID: selectedTemplate?.TemplateID,
                eventID: eventInfo?.event?.resourceData?.event_id,
                startTime: formatDateTimeScheduler(eventInfo?.event?.start),
                endTime: formatDateTimeScheduler(eventInfo?.event?.end),
                userID: 1,
            }

            console.log("onEventResize", payload)

            addNewSlot([payload], "edit")
        }
    }

    const editEvent = () => {
        let payload = {
            timeSlotID: tempEvent?.resourceData?.slot_id,
            templateID: selectedTemplate?.TemplateID,
            eventID: tempEvent?.resourceData?.event_id,
            startTime: tempEvent?.start,
            endTime: tempEvent?.end,
            userID: 1,
        }

        console.log("editEvent", payload)

        editExistingSlot([payload])
    }

    const invalids = [
        {
            start: "19:00",
            end: "22:00",
            title: "OFF",
            recurring: {
                repeat: "weekly",
                weekDays: "MO,TU,WE,TH,FR,SA,SU",
            },
        },
        {
            start: "04:00",
            end: "06:00",
            title: "OFF",
            recurring: {
                repeat: "weekly",
                weekDays: "MO,TU,WE,TH,FR,SA,SU",
            },
        },
    ]

    const onEventRightClick = (e) => {
        setSelectedEditEvent({ value: 0, label: "Select Event" })

        const tmpEvent = { ...tempEvent }

        tmpEvent.resourceData.slot_id = e?.event?.resourceData?.slot_id
        tmpEvent.start = e?.event?.start
        tmpEvent.end = e?.event?.end
        tmpEvent.id = e?.event?.id

        setTempEvent(tmpEvent)
        setIsOpenEditEvent(true)
    }

    const hexToRGBA = (hex, alpha) => {
        const r = parseInt(hex.slice(1, 3), 16)
        const g = parseInt(hex.slice(3, 5), 16)
        const b = parseInt(hex.slice(5, 7), 16)
        return `rgba(${r}, ${g}, ${b}, ${alpha})`
    }

    const [eventInfoColor, setEventInfoColor] = useState("#f5f5f5")

    const onEventDoubleClick = (e) => {
        setEventInfoColor(e.event.color)

        getEventDetails(e?.event?.resourceData?.event_id)
            .then((response) => {
                let res = response
                console.log("getEventDetails", res?.Event)

                let finalArray = {
                    name: res.Event[0].EventName,
                    children: res.Event[0].PetTypeList.map((petType) => {
                        return {
                            name: petType.varPetTypeName,
                            children: petType.ServicesList.map((service) => {
                                return {
                                    name: service.varServiceItemName,
                                    children: service.PetSizeList.map((size) => {
                                        return {
                                            name: size.varPetSizeName,
                                            children: size.PetBreedList.map((breed) => {
                                                return {
                                                    name: breed.varPetBreedName,
                                                }
                                            }),
                                        }
                                    }),
                                }
                            }),
                        }
                    }),
                }
                setEventDetails(finalArray)
                console.log("finalArrDetails-sent to chart", finalArray)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })

        setIsOpenEventInfo(true)
    }

    function expandAllNodes(data) {
        data.collapsed = false
        if (data.children) {
            data.children.forEach((child) => {
                expandAllNodes(child)
            })
        }
    }

    const onEventDeleted = (e) => {
        let slotID = e?.event?.resourceData?.slot_id

        if (slotID > 0) {
            deleteSlot(slotID)
                .then((response) => {
                    let res = response

                    setEvents(memoizedEvents.filter((event) => event.resourceData.slot_id !== slotID))

                    toast.success("Slot Deleted!")
                })
                .catch((err) => {
                    console.log(err)
                    toast.error("Something went wrong!")
                })
        }
    }

    const renderRectSvgNode = ({ nodeDatum, toggleNode }) => (
        <g>
            <circle className={styles.circle} r="15" onClick={toggleNode}></circle>
            <text className={styles.textC} fill="black" text-anchor="middle" x="12" y="-22">
                {nodeDatum.name}
            </text>
        </g>
    )

    useEffect(() => {
        if (selectedCalendarDealEmployee?.length > 0) {
            const tempEmp = [] as any
            tempEmp.push({ id: selectedTemplate?.TemplateEmployeeID, value: selectedTemplate.selectedName })

            selectedCalendarDealEmployee.map((emp) => {
                getTemplateListCustom(location, emp.id)
                    .then((responseTemplates) => {
                        if (responseTemplates.length > 0) {
                            console.log("responseTemplates", responseTemplates)
                            getEventsByTemplateCustom(responseTemplates[0]?.TemplateID)
                                .then((responseEvents) => {
                                    console.log("responseEvents", responseEvents)

                                    let tempAllEvents = [...myEvents]

                                    console.log("selected eventsss", selectedEvents)

                                    let startTime = selectedEvents[0]?.start
                                    let endTime = selectedEvents[0]?.end

                                    // Filter events based on the range
                                    responseEvents
                                        .filter((event) => {
                                            return event.StartTime >= startTime && event.StartTime < endTime
                                        })

                                        .map((event) => {
                                            tempAllEvents.push({
                                                start: event?.StartTime,
                                                end: event?.EndTime,
                                                title: event?.EventName,
                                                color: event?.EventColor,
                                                resourceData: { id: emp.id, value: event?.EventName, event_id: event?.EventID, slot_id: event?.TimeSlotID },
                                                resource: emp.id,
                                            })
                                        })

                                    setEvents(tempAllEvents)
                                })
                                .catch((err) => {
                                    console.log(err)
                                    toast.error("Something went wrong!")
                                })
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        toast.error("Something went wrong!")
                    })

                tempEmp.push({ id: emp.id, value: emp.value })
            })
            setResources(tempEmp)
        } else {
            setResources([{ id: selectedTemplate?.TemplateEmployeeID, value: selectedTemplate.selectedName }])
        }
    }, [selectedCalendarDealEmployee])

    const [depth, setDepth] = useState(400)
    const [copiedEvents, setCopiedEvents] = useState([]) as any
    const [selectedDate, setSelectedDate] = useState(new Date()) as any
    const [selectedEvents, setSelectedEvents] = useState([]) as any
    const [dealCalendarEmployees, setDealCalendarEmployees] = useState([]) as any

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === "c") {
                handleCopy()
            } else if (event.ctrlKey && event.key === "v") {
                handlePaste()
            }
        }

        document.addEventListener("keydown", handleKeyDown)

        return () => {
            document.removeEventListener("keydown", handleKeyDown)
        }
    }, [copiedEvents, selectedEvents])

    const handleCopy = () => {
        setCopiedEvents([...selectedEvents])
    }

    useEffect(() => {
        console.log("selected", selectedEvents)
        dealRefCal.current?.handleClear()
        setDealCalendarEmployees([])
        setSelectedCalendarDealEmployee([])
    }, [selectedEvents])

    const confirmDeal = () => {
        // TODO: Gets the first deal employee in the deal dropdown for now
        if (selectedEvents?.length > 0) {
            let payload = {
                timeSlotID: selectedEvents[0]?.resourceData?.slot_id,
                templateID: selectedTemplate?.TemplateID,
                eventID: selectedEvents[0]?.resourceData?.event_id,
                startTime: selectedEvents[0]?.start,
                endTime: selectedEvents[0]?.end,
                userID: 1,
                dealEmpID: selectedCalendarDealEmployee[0]?.numEmployeeID,
                dealTimeSlotID: selectedCalendarDealEmployee[0]?.TimeSlotID,
            }

            addNewSlotDeal([payload], "new")

            console.log("selectedCalendarDealEmployee", selectedCalendarDealEmployee)
        }
    }

    const setupDeal = () => {
        if (selectedEvents?.length === 1) {
            console.log("selectedevent", selectedEvents)
            let selectedEventForDeal = selectedEvents

            getAvailableEvent(selectedTemplate?.TemplateEmployeeID, selectedTemplate?.TemplateLocationID)
                .then((response) => {
                    console.log("get event for isbath", response)
                    if (response.length > 0) {
                        let e = response.find((event) => event.EventID === selectedEventForDeal[0]?.resourceData?.event_id)
                        console.log("test event ", selectedEventForDeal)
                        if (e?.IsBath) {
                            let startDealTime = selectedEventForDeal[0]?.start
                            let endDealTime = selectedEventForDeal[0]?.end

                            getAvailableDealEmployees(startDealTime, endDealTime, selectedTemplate?.TemplateLocationID)
                                .then((response) => {
                                    let resDealList = response

                                    if (resDealList?.length > 0) {
                                        setDealCalendarEmployees(resDealList)
                                    } else {
                                        setDealCalendarEmployees([])
                                        toast.info("No bathers found for the selected Events' time range!")
                                    }
                                    console.log("deal calendar list", resDealList)
                                })
                                .catch((err) => {
                                    console.log(err)
                                    toast.error("Something went wrong!")
                                })
                        } else {
                            toast.error("The selected event does not allow bathers to be setup!")
                        }
                    }
                })
                .catch((err) => {
                    console.log(err)
                    toast.error("Something went wrong!")
                })
        } else {
            toast.error("Please select any event to setup deals!")
        }
    }

    function formatDateToDesiredFormat(dateString, selectedDate) {
        const originalDate = new Date(dateString)

        const hours = originalDate.getHours()
        const minutes = originalDate.getMinutes()
        const seconds = originalDate.getSeconds()
        const newDate = new Date(selectedDate)
        newDate.setHours(hours)
        newDate.setMinutes(minutes)
        newDate.setSeconds(seconds)

        const formattedDate = `${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}T${String(newDate.getHours()).padStart(
            2,
            "0"
        )}:${String(newDate.getMinutes()).padStart(2, "0")}:${String(newDate.getSeconds()).padStart(2, "0")}`

        return formattedDate
    }

    const handlePaste = () => {
        console.log("On paste", copiedEvents)
        const updatedEvents = copiedEvents.map((event) => {
            if (typeof event.start === "string" && typeof event.end === "string") {
                const formattedStartDate = formatDateToDesiredFormat(event.start, selectedDate)
                const formattedEndDate = formatDateToDesiredFormat(event.end, selectedDate)

                return {
                    ...event,
                    start: formattedStartDate,
                    end: formattedEndDate,
                    id: `mbsc_${Math.random().toString(36).substr(2, 9)}`,
                }
            }
            console.error("Invalid event format:", event)
            return null
        })

        const validEvents = updatedEvents.filter((event) => event !== null)

        if (validEvents.length > 0) {
            let arrPayload: any = []
            validEvents.map((evt) => {
                let event = {
                    timeSlotID: 0,
                    templateID: selectedTemplate?.TemplateID,
                    eventID: evt.resourceData.event_id,
                    startTime: evt.start,
                    endTime: evt.end,
                    userID: 1,
                }
                arrPayload.push(event)
            })
            console.log("payload on paste", arrPayload)
            addNewSlotBulk(arrPayload, "new")
        } else {
            toast.info("No events copied")
        }
    }

    const handleSelectedEventsChange = (selected) => {
        setSelectedEvents(selected.events)
    }

    useEffect(() => {
        console.log("resources", resources)
    }, [resources])

    const toastId = useRef(null) as any

    const CustomToast: any = ({ message, closeButton }) => {
        return (
            <div>
                <p style={{ fontWeight: "600" }}>
                    {copiedEvents.length} {copiedEvents.length > 1 ? "Events" : "Event"} copied
                </p>
                {closeButton}
            </div>
        )
    }

    const showToast = () => {
        if (toastId.current === null) {
            toastId.current = toast(<CustomToast message="This is a non-closing notification" />, {
                closeButton: (
                    <Button color="discard" variant="text" onClick={closeToast}>
                        <Cancel />
                    </Button>
                ),
                autoClose: false,
            })
        }
    }

    const closeToast = () => {
        if (toastId.current !== null) {
            toast.dismiss(toastId.current)
            toastId.current = null
        }
    }

    useEffect(() => {
        if (copiedEvents.length > 0) {
            closeToast()
            showToast()
        }
    }, [copiedEvents])

    //   const onEventClick = (event) => {
    //     const eventData = event.event;

    //     if (!eventData) {
    //       return;
    //     }

    //     const { isShiftKey } = event;

    //     if (isShiftKey) {
    //       if (selectedEvents.some((e) => e.id === eventData.id)) {
    //         setSelectedEvents(selectedEvents.filter((e) => e.id !== eventData.id));
    //       } else {
    //         setSelectedEvents([...selectedEvents, eventData]);
    //       }
    //     } else {
    //       setSelectedEvents([eventData]);
    //     }
    //   };

    return (
        <div style={{ width: "100%", paddingBottom: "10px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <p style={{ fontWeight: "600", fontSize: "20px", color: "#5e5e5e" }}>Schedule</p>
                <div style={{ display: "flex", alignItems: "center", gap: "15px", padding: "8px", backgroundColor: "#005df114", borderRadius: "8px" }}>
                    <div style={{ width: "400px" }}>
                        <AdvancedDropDown
                            data={locationList.map((i) => ({
                                label: i.varLocationName,
                                value: i.numLocationID,
                            }))}
                            onChange={(e) => {
                                setLocation(e.value)
                            }}
                            passByID={true}
                            value={location}
                            ref={locationRef}
                            placeHolder={"Select Location"}
                        />
                    </div>

                    <div style={{ width: "400px" }}>
                        <AdvancedDropDown
                            data={employeeList.map((i) => ({
                                label: i.varEmployeeFirstName + " " + i.varEmployeeLastName,
                                value: i.numEmployeeID,
                            }))}
                            onChange={(e) => {
                                setEmployee(e.value)
                            }}
                            passByID={true}
                            value={employee}
                            ref={employeeRef}
                            placeHolder={"Select Employee"}
                        />
                    </div>
                </div>

                <div style={{ padding: "8px 15px", backgroundColor: "#00af5c14", borderRadius: "8px", border: "1px solid #9ed3ba" }}>
                    <table style={{ borderCollapse: "collapse" }}>
                        <tr>
                            <td style={{ width: "80px" }}>
                                <p style={{ margin: "0", fontWeight: 500 }}>Employee</p>
                            </td>
                            <td>
                                {selectedTemplate?.varEmployeeFirstName} {selectedTemplate?.varEmployeeLastName}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p style={{ margin: "0", fontWeight: 500 }}>Location</p>
                            </td>
                            <td>{Truncate(selectedTemplate?.varLocationName, 20)}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div>
                <div style={{ width: "100%", marginTop: "10px", display: "flex", justifyContent: "end" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                        <Button color="primary" variant="contained" onClick={() => setupDeal()}>
                            Setup Deal
                        </Button>
                        <AdvancedDropDown
                            isMultiple={true}
                            data={dealCalendarEmployees.map((i) => ({
                                label: i.varEmployeeFirstName + " " + i.varEmployeeLastName,
                                value: i.numEmployeeID,
                                EndTime: i?.EndTime,
                                StartTime: i?.StartTime,
                                TimeSlotID: i?.TimeSlotID,
                                numEmployeeID: i?.numEmployeeID,
                            }))}
                            onChange={(e) => {
                                console.log("selected calendar deal", e)
                                dealRefCal.current?.handleClear()
                                let tempLocaArr = [] as any

                                e.map((emp) => {
                                    tempLocaArr.push({ id: emp.value, value: emp.label, EndTime: emp.EndTime, StartTime: emp.StartTime, TimeSlotID: emp.TimeSlotID, numEmployeeID: emp.numEmployeeID })
                                })

                                setSelectedCalendarDealEmployee(tempLocaArr)
                            }}
                            passByID={true}
                            ref={dealRefCal}
                            placeHolder={"Select Bather(s)"}
                        />
                        <Button color="primary" variant="contained" onClick={() => confirmDeal()}>
                            Confirm Deal
                        </Button>
                    </div>
                </div>
            </div>

            {employeeLoading ? (
                <p>Loading..</p>
            ) : (
                <div style={{ display: "flex", justifyContent: "space-between", gap: "15px", marginTop: "20px", height: "564px" }}>
                    <div style={{ flex: 1, border: "1px solid #8eb4f1", borderRadius: "8px", padding: "8px 15px", width: "100%", height: "100%", boxShadow: "rgb(0 99 255 / 21%) 0px 0 8px 0px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #d5d5d5", paddingBottom: "8px", marginBottom: "4px" }}>
                            <p style={{ fontSize: "16px", fontWeight: "500" }}>Templates</p>
                            <Button
                                variant="contained"
                                color="primary"
                                text="New"
                                onClick={() => {
                                    setIsOpenNewTemplate(true)
                                }}
                            />
                        </div>

                        <div>
                            <div style={{ width: "100%", marginBottom: "8px" }}>
                                <TextField
                                    InputProps={{
                                        endAdornment:
                                            filterTextTemplates.length > 0 ? (
                                                <IconButton
                                                    style={{ borderRadius: "5px" }}
                                                    onClick={(e) => {
                                                        resetTemplates()
                                                    }}
                                                >
                                                    <Close style={{ fontSize: "16px", color: "#ff6464" }} />
                                                </IconButton>
                                            ) : (
                                                <IconButton disabled style={{ borderRadius: "5px" }}>
                                                    <Close style={{ fontSize: "16px", color: "#fff" }} />
                                                </IconButton>
                                            ),
                                    }}
                                    value={filterTextTemplates}
                                    onChange={handleFilterChangeTemplates}
                                    inputProps={{ style: { fontSize: 14 } }}
                                    placeholder="Filter here"
                                    size="small"
                                    variant="standard"
                                    style={{ width: "100%", fontSize: "14px" }}
                                />
                            </div>

                            <div style={{ overflowY: "auto", height: "440px" }}>
                                {templateLoading ? (
                                    <p className={styles.loading}>Loading Tempaltes..</p>
                                ) : (
                                    filteredItemsTemplates.map((template) => {
                                        return (
                                            <div
                                                onClick={() => {
                                                    setSelectedTemplate({
                                                        TemplateLocationID: template?.TemplateLocationID,
                                                        TemplateEmployeeID: template?.TemplateEmployeeID,
                                                        TemplateID: template?.TemplateID,
                                                        selectedName: template?.TemplateName,
                                                        timeInterval: template?.TemplateTimeInterval,
                                                        varEmployeeFirstName: template?.varEmployeeFirstName,
                                                        varEmployeeLastName: template?.varEmployeeLastName,
                                                        varLocationName: template?.varLocationName,
                                                        EmployeeType: template?.EmployeeType,
                                                    })
                                                }}
                                                className={`styles.listItem ${selectedTemplate?.TemplateID === template?.TemplateID ? styles.selectedHighlight : styles.listItem}`}
                                            >
                                                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                    <Tooltip
                                                        title={
                                                            <div>
                                                                <p style={{ color: "#fff", margin: 0 }}>
                                                                    <b>Location: </b>
                                                                    {!!template?.varLocationName ? template?.varLocationName : "Not Set"}
                                                                </p>
                                                                <p style={{ color: "#fff", margin: 0 }}>
                                                                    <b>Employee: </b>
                                                                    {!!template?.varEmployeeFirstName ? template?.varEmployeeFirstName + " " + template?.varEmployeeLastName : "Not Set"}
                                                                </p>
                                                                <p style={{ color: "#fff", margin: 0 }}>
                                                                    <b>Time Interval: </b>
                                                                    {template?.TemplateTimeInterval} minutes
                                                                </p>
                                                            </div>
                                                        }
                                                    >
                                                        <InfoOutlined style={{ fontSize: "20px", color: "gray" }} />
                                                    </Tooltip>
                                                    <p style={{ display: "flex", alignItems: "center", userSelect: "none" }}>{template?.TemplateName}</p>
                                                </div>

                                                <IconButton
                                                    style={{ borderRadius: "5px" }}
                                                    onClick={(e) => {
                                                        // TODO:
                                                        setEditTemplateID(template?.TemplateID)
                                                        setIsOpenNewTemplateEdit(true)
                                                    }}
                                                >
                                                    <Edit style={{ fontSize: "16px", color: "#6B7280" }} />
                                                </IconButton>
                                            </div>
                                        )
                                    })
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={fullScreen ? styles.fullScreen : styles.notfullScreen}>
                        {selectedTemplate?.TemplateID !== 0 ? (
                            <>
                                <div className={eventsListLoading ? styles.showOverlay : ""}>
                                    {eventsListLoading ? (
                                        <div style={{ width: "100%" }}>
                                            <LinearProgress style={{ height: "5px", borderTopLeftRadius: "6px", borderTopRightRadius: "6px" }} />
                                        </div>
                                    ) : null}
                                </div>
                                <Eventcalendar
                                    resources={resources}
                                    // min={new Date(new Date().getFullYear(), new Date().getMonth(), 1)}
                                    // max={new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate())}
                                    className={styles.calendar}
                                    theme="ios"
                                    themeVariant={darkMode ? "dark" : "light"}
                                    clickToCreate={true}
                                    dragToCreate={true}
                                    dragTimeStep={5}
                                    dragToResize={true}
                                    dragToMove={true}
                                    eventDelete={true}
                                    onEventCreate={onEventCreate}
                                    onEventRightClick={onEventRightClick}
                                    onEventDelete={onEventDeleted}
                                    onEventDragEnd={onEventResize}
                                    invalid={invalids}
                                    data={memoizedEvents}
                                    dataTimezone="America/Chicago"
                                    displayTimezone="America/Chicago"
                                    timezonePlugin={momentTimezone}
                                    renderHeader={customWithNavButtons}
                                    view={calView}
                                    cssClass="md-switching-view-cont"
                                    selectMultipleEvents={true}
                                    onSelectedEventsChange={handleSelectedEventsChange}
                                    onEventDoubleClick={onEventDoubleClick}
                                    onCellClick={(e) => {
                                        setSelectedDate(e.date)
                                    }}
                                    //onEventClick={onEventClick}
                                />
                            </>
                        ) : (
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", flexDirection: "column" }}>
                                <img alt="placeholder" src={wait} />
                                <p style={{ fontSize: "20px" }}>Select a template to continue</p>
                            </div>
                        )}
                    </div>
                </div>
            )}

            <Modal open={isOpenNewTemplate}>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                            backgroundColor: "#f5f5f5",
                            padding: "16px",
                            marginTop: "-16px",
                            borderTopRightRadius: "12px",
                            borderTopLeftRadius: "12px",
                        }}
                    >
                        <p style={{ color: "#002867", fontSize: "18px", fontWeight: "600", width: "100%" }}>New Template</p>
                        <CloseButton onClick={() => setIsOpenNewTemplate(false)} />
                    </div>
                    <div>
                        <p style={{ fontWeight: "500", color: "#898989" }}>Template Name</p>
                        <form>
                            <TextField fullWidth size="small" onChange={(e) => setTemplateName(e.target.value)} value={templateName} type="text" placeholder="Template Name" />
                            <p style={{ fontWeight: "500", color: "#898989" }}>Time Interval</p>
                            <TextField
                                fullWidth
                                size="small"
                                onChange={(e) => setTemplateTimeInterval(parseInt(e.target.value))}
                                value={templateTimeInterval}
                                type="number"
                                placeholder="Time Interval"
                            />
                            <p style={{ fontWeight: "500", color: "#898989" }}>Location</p>
                            <div style={{ width: "400px" }}>
                                <AdvancedDropDown
                                    data={locationList.map((i) => ({
                                        label: i.varLocationName,
                                        value: i.numLocationID,
                                    }))}
                                    onChange={(e) => {
                                        setTemplateLocation(e.value)
                                    }}
                                    passByID={true}
                                    value={templateLocation}
                                    ref={templateLocationRef}
                                    placeHolder={"Select Location"}
                                />
                            </div>
                            <p style={{ fontWeight: "500", color: "#898989" }}>Employee</p>
                            <div style={{ width: "400px" }}>
                                <AdvancedDropDown
                                    data={employeeListEdit.map((i) => ({
                                        label: i.varEmployeeFirstName + " " + i.varEmployeeLastName,
                                        value: i.numEmployeeID,
                                    }))}
                                    onChange={(e) => {
                                        setTemplateEmployee(e.value)
                                    }}
                                    passByID={true}
                                    value={templateEmployee}
                                    ref={templateEmployeeRef}
                                    placeHolder={"Select Employee"}
                                />
                            </div>
                            <div style={{ width: "100%", textAlign: "end", marginTop: "15px" }}>
                                <Button color="primary" variant="contained" onClick={() => createTemplate()}>
                                    Create Template
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>

            <Modal open={isOpenNewTemplateEdit}>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                            backgroundColor: "#f5f5f5",
                            padding: "16px",
                            marginTop: "-16px",
                            borderTopRightRadius: "12px",
                            borderTopLeftRadius: "12px",
                        }}
                    >
                        <p style={{ color: "#002867", fontSize: "18px", fontWeight: "600", width: "100%" }}>Edit Template</p>
                        <CloseButton onClick={() => setIsOpenNewTemplateEdit(false)} />
                    </div>
                    <div>
                        <p style={{ fontWeight: "500", color: "#898989" }}>Template Name</p>
                        <form>
                            <TextField fullWidth size="small" onChange={(e) => setTemplateNameEdit(e.target.value)} value={templateNameEdit} type="text" placeholder="Template Name" />
                            <p style={{ fontWeight: "500", color: "#898989" }}>Time Interval</p>
                            <TextField
                                fullWidth
                                size="small"
                                onChange={(e) => setTemplateTimeIntervalEdit(parseInt(e.target.value))}
                                value={templateTimeIntervalEdit}
                                type="number"
                                placeholder="Time Interval"
                            />
                            <p style={{ fontWeight: "500", color: "#898989" }}>Location</p>
                            <div style={{ width: "400px" }}>
                                <AdvancedDropDown
                                    data={locationList.map((i) => ({
                                        label: i.varLocationName,
                                        value: i.numLocationID,
                                    }))}
                                    onChange={(e) => {
                                        setTemplateLocationEdit(e.value)
                                    }}
                                    passByID={true}
                                    value={templateLocationEdit}
                                    ref={templateLocationRefEdit}
                                    placeHolder={"Select Location"}
                                />
                            </div>
                            <p style={{ fontWeight: "500", color: "#898989" }}>Employee</p>
                            <div style={{ width: "400px" }}>
                                <AdvancedDropDown
                                    data={employeeListEdit.map((i) => ({
                                        label: i.varEmployeeFirstName + " " + i.varEmployeeLastName,
                                        value: i.numEmployeeID,
                                    }))}
                                    onChange={(e) => {
                                        setTemplateEmployeeEdit(e.value)
                                    }}
                                    passByID={true}
                                    value={templateEmployeeEdit}
                                    ref={templateEmployeeRefEdit}
                                    placeHolder={"Select Employee"}
                                />
                            </div>
                            <div style={{ width: "100%", textAlign: "end", marginTop: "15px" }}>
                                <Button color="primary" variant="contained" onClick={() => updateTemplate()}>
                                    Update Template
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>

            <Modal open={isOpenNewEvent}>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                            backgroundColor: "#f5f5f5",
                            padding: "16px",
                            marginTop: "-16px",
                            borderTopRightRadius: "12px",
                            borderTopLeftRadius: "12px",
                        }}
                    >
                        <p style={{ color: "#002867", fontSize: "18px", fontWeight: "600", width: "100%" }}>New Event</p>
                        <CloseButton onClick={() => setIsOpenNewEvent(false)} />
                    </div>
                    <div>
                        <form>
                            <p style={{ fontWeight: "500", color: "#898989" }}>Select Event</p>
                            <div style={{ width: "400px" }}>
                                <AdvancedDropDown
                                    data={eventSelectionList.map((i) => ({
                                        label: i.EventName,
                                        value: i.EventID,
                                        isBath: i.IsBath,
                                    }))}
                                    onChange={(e) => {
                                        console.log("selected event", e)

                                        onEventChange(e)
                                    }}
                                    passByID={true}
                                    value={selectedEvent.value}
                                    ref={eventRef}
                                    placeHolder={"Select Event"}
                                />

                                {selectedTemplate?.EmployeeType === "Groomer" ? (
                                    <div style={{ marginTop: "10px" }}>
                                        <p style={{ fontWeight: "500", color: "#898989" }}>Select Deal Employee</p>
                                        <AdvancedDropDown
                                            data={dealEmployeeSelectionList.map((i) => ({
                                                label: i.varEmployeeFirstName + " " + i.varEmployeeLastName,
                                                value: i.numEmployeeID,
                                                EndTime: i?.EndTime,
                                                StartTime: i?.StartTime,
                                                TimeSlotID: i?.TimeSlotID,
                                                numEmployeeID: i?.numEmployeeID,
                                            }))}
                                            onChange={(e) => {
                                                console.log("selected deal", e)
                                                dealRef.current?.handleClear()
                                                setSelectedDealEmployee(e)
                                            }}
                                            passByID={true}
                                            value={selectedDealEmployee.value}
                                            ref={dealRef}
                                            placeHolder={"Select Deal Employee"}
                                        />
                                    </div>
                                ) : null}
                            </div>
                            <div style={{ width: "100%", textAlign: "end", marginTop: "15px" }}>
                                <Button disabled={newIsLoading} loading={newIsLoading} color="primary" variant="contained" onClick={() => addEvent()}>
                                    Add Event
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>

            <Modal open={isOpenEditEvent}>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                            backgroundColor: "#f5f5f5",
                            padding: "16px",
                            marginTop: "-16px",
                            borderTopRightRadius: "12px",
                            borderTopLeftRadius: "12px",
                        }}
                    >
                        <p style={{ color: "#002867", fontSize: "18px", fontWeight: "600", width: "100%" }}>Edit Event</p>
                        <CloseButton onClick={() => setIsOpenEditEvent(false)} />
                    </div>
                    <div>
                        <form>
                            <p style={{ fontWeight: "500", color: "#898989" }}>Select Event</p>
                            <div style={{ width: "400px" }}>
                                <AdvancedDropDown
                                    data={eventSelectionList.map((i) => ({
                                        label: i.EventName,
                                        value: i.EventID,
                                        isBath: i.IsBath,
                                    }))}
                                    onChange={(e) => {
                                        console.log("selected event", e)
                                        setCurrentEventHasBath(e?.isBath)
                                        setSelectedEditEvent(e)
                                    }}
                                    passByID={true}
                                    value={selectedEditEvent.value}
                                    ref={eventRefEdit}
                                    placeHolder={"Select Event"}
                                />
                            </div>
                            <div style={{ width: "100%", textAlign: "end", marginTop: "15px" }}>
                                <Button disabled={editIsLoading} loading={editIsLoading} color="primary" variant="contained" onClick={() => editEvent()}>
                                    Edit Event
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>

            <Modal open={isOpenEventInfo}>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                            backgroundColor: hexToRGBA(eventInfoColor, 0.2),
                            padding: "16px",
                            marginTop: "-16px",
                            borderTopRightRadius: "12px",
                            borderTopLeftRadius: "12px",
                        }}
                    >
                        <p style={{ color: "#002867", fontSize: "18px", fontWeight: "600", width: "100%" }}>Event Info</p>
                        <CloseButton onClick={() => setIsOpenEventInfo(false)} />
                    </div>
                    <div className={fullScreenNet ? styles.fullScreenNet : styles.notfullScreen}>
                        <div style={{ display: "flex", gap: "10px" }}>
                            <div
                                style={
                                    fullScreenNet
                                        ? { flex: 1, border: "1px solid rgb(213, 213, 213)", borderRadius: "8px", padding: "6px 12px", width: "100%", height: "960px" }
                                        : { flex: 1, border: "1px solid rgb(213, 213, 213)", borderRadius: "8px", padding: "6px 12px", width: "1000px", height: "100%" }
                                }
                            >
                                <p style={{ fontSize: "16px", fontWeight: 500, borderBottom: "1px solid rgb(213, 213, 213)", paddingBottom: "8px" }}>Click and Drag to interact</p>
                                <IconButton color="primary" onClick={() => setFullScreenNet(!fullScreenNet)}>
                                    {fullScreenNet ? <FullscreenExit /> : <Fullscreen />}
                                </IconButton>
                                <div style={fullScreenNet ? { height: "883px" } : { height: "500px" }}>
                                    <div id="treeWrapper" style={fullScreenNet ? { height: "883px", width: "100%" } : { height: "500px", width: "100%" }}>
                                        {Object.keys(eventDetails).length > 0 ? (
                                            <Tree
                                                initialDepth={depth}
                                                translate={{ x: 550, y: 250 }}
                                                scaleExtent={{ max: 2, min: 1 }}
                                                nodeSize={{ x: 200, y: 80 }}
                                                data={eventDetails}
                                                dimensions={{ width: 1100, height: 500 }}
                                                renderCustomNodeElement={renderRectSvgNode}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ScheduleTab
