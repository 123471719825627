import { ArrowBack, Warning } from "@mui/icons-material"
import { Avatar, Chip } from "../../../components"
import styles from "./TabDashboard.module.scss"

import { Link, useNavigate, useLocation } from "react-router-dom"
import { axiosPrivate, buildHeaderObject } from "../../../apis/axios"
import { toast } from "react-toastify"
import { useEffect, useState } from "react"
import { Box, CircularProgress, Grid, Tab, Tabs } from "@mui/material"
import Steps from "../Components/Steps/Steps"
import { Button } from "../../../components"
import { AvatarLabel } from "../../../components/Table/components/AvatarLabel/AvatarLabel"
import CheckInModal from "../../../components/ExtModals/CheckInModal"
import InquiryTab from "../Components/InquiryTab/InquiryTab"
import FileUpload from "../Components/FileUpload/FileUpload"
import WorkingTab from "../Components/WorkingTab/WorkingTab"
import useAuth from "../../../hooks/useAuth"
import { formatDateDisplay } from "../../../utils/TimeHandler"
import AllCalendarGroomer from "../../../pages/AdminPanel/Scheduler/NewCalendar/AllCalendarBooking/AllCalendarGroomer"

const TabDashboard = (props: { children? }) => {
    const { auth } = useAuth() as any

    const [dataLoading, setDataLoading] = useState(false)
    const [viewWorking, setViewWorking] = useState(false)
    const [appointmentList, setAppointmentList] = useState([]) as any
    const [viewAppointmentDetails, setViewAppointmentDetails] = useState(false)
    const [selectedAppointmentID, setSelectedAppointmentID] = useState("")

    const getMonthStartAndEnd = () => {
        const now = new Date()

        const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1)
        const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0)

        return {
            fromDate: startOfMonth.toISOString().split(".")[0],
            toDate: endOfMonth.toISOString().split(".")[0],
        }
    }

    const { fromDate, toDate } = getMonthStartAndEnd()

    async function getAppointments() {
        setDataLoading(true)

        await axiosPrivate
            .get(`Appointment/GetAppointmentsByEmployeeIDAndLocID?EmployeeID=${auth?.employee?.numEmployeeID}&LocationID=${auth?.currentLocation?.value}`, buildHeaderObject(auth))
            .then((result) => {
                if (result.status === 200) {
                    console.log("allAP", result?.data)
                    setAppointmentList(result?.data)
                    setDataLoading(false)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")

                setDataLoading(false)
            })
    }

    useEffect(() => {
        getAppointments()
    }, [])

    const navigate = useNavigate()
    const location = useLocation() as any

    const handleLink = (id) => {
        const from = location.state?.from?.pathname || `working`
        navigate(from, { state: { appData: id }, replace: true })
    }

    const showDetails = (appointmentID) => {
        setViewAppointmentDetails(true)
        setSelectedAppointmentID(appointmentID)
    }

    const showWorkingTab = () => {
        setViewWorking(true)
    }

    return (
        <>
            <h1 style={{ margin: "20px 0 15px 20px ", padding: "0", fontSize: "24px" }}>Dashboard</h1>
            <div style={{ flex: 1, overflowY: "scroll", margin: "0 0 20px 0", scrollbarGutter: "stable both-ends" }}>
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "100%", margin: "0 20px 20px 20px" }}>
                        {/* <FileUpload/> */}
                        {viewAppointmentDetails ? (
                            <InquiryTab bookingID={selectedAppointmentID} setViewAppointmentDetails={(value) => setViewAppointmentDetails(value)} setWorking={() => showWorkingTab()} />
                        ) : dataLoading ? (
                            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", margin: "50px 0", gap: "20px" }}>
                                <CircularProgress style={{ fontSize: "50px" }} />
                                <p style={{ fontWeight: "600", fontSize: "18px" }}>Loading Appointments...</p>
                            </div>
                        ) : (
                            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                <AllCalendarGroomer
                                    fromDate={fromDate}
                                    toDate={toDate}
                                    intervals={30}
                                    location={auth?.currentLocation?.value}
                                    employee={auth?.employee?.numEmployeeID}
                                    viewSummary={(e) => showDetails(e)}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default TabDashboard
