import { useEffect, useRef, useState } from "react"
import { CloseButton } from "../CloseButton/CloseButton"
import { Modal } from "../components"
import { axiosPrivate, buildHeaderObject } from "../../apis/axios"
import styles from "../Profile/Profile.module.scss"
import { TextField, Tooltip } from "@mui/material"
import { ArrowDownward, ArrowUpward, Email, InfoOutlined, LocalOffer, MonetizationOn, Payments } from "@mui/icons-material"
import AdvancedDropDown from "../AdvancedDropDown/AdvancedDropDown"
import moment from "moment"
import useAuth from "../../hooks/useAuth"

const DisplayPetServices = (props: { isOpenPetServicesModal; setIsOpenPetServicesModal: CallableFunction; data }) => {
    const { auth } = useAuth() as any

    const [isLoading, setIsLoading] = useState(false)
    const [additionalServiceList, setAdditionalServiceList] = useState<any>([])
    const [allLocations, setAllLocations] = useState<any>([])
    const [filterLocation, setFilterLocation] = useState<any>(1)
    const filterLocationRef = useRef<any>()
    const [searchTerm, setSearchTerm] = useState("")

    const getLocationServiceItemsPriceList = async (locationID, dateOfService, petBreedID, petSizeID, employeeID, employeeName, petID, petTypeID) => {
        const apiUrl = `Payment/GetLocationServiceItemsPriceList`

        try {
            const response = await axiosPrivate.get(
                apiUrl,
                buildHeaderObject(auth, false, false, false, {
                    numLocationID: locationID,
                    dtDate_of_service: dateOfService,
                    numPetBreedID: petBreedID,
                    numPetSizeID: petSizeID,
                    numEmployeeID: employeeID,
                    varEmployeeName: employeeName,
                    numPetID: petID,
                    numPetTypeID: petTypeID,
                })
            )
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    async function getLocations(signal) {
        const result = await axiosPrivate.get(`Location/GetAll`, buildHeaderObject(auth, signal))
        return result.data.location
    }

    const fetchLocationData = (signal) => {
        getLocations(signal)
            .then((res) => {
                setAllLocations(res)
            })
            .catch((err) => {
                if (err.message != "canceled") {
                    console.log(err)
                }
            })
    }

    useEffect(() => {
        if (props.isOpenPetServicesModal === true) {
            setIsLoading(true)
            getLocationServiceItemsPriceList(
                filterLocation,
                moment().format("MM/DD/YYYY"),
                props?.data?.petBreedID,
                props?.data?.petSizeID,
                props?.data?.employeeID,
                props?.data?.employeeName,
                props?.data?.petID,
                props?.data?.petTypeID
            )
                .then((data) => {
                    if (data.Services !== undefined) {
                        console.log("res - All services with SP-selected services", data)

                        const { additionalServiceList } = data.Services.reduce(
                            (lists, service) => {
                                const existingIndex = lists.additionalServiceList.findIndex(
                                    (existingService) => existingService.id === service.numServiceItemID && existingService.orderID === service.numOrderID
                                )

                                if (existingIndex === -1) {
                                    lists.additionalServiceList.push({
                                        ...service,
                                        desc: service.varServiceItemDescription,
                                    })
                                }

                                return lists
                            },
                            { additionalServiceList: [] }
                        )

                        setAdditionalServiceList(additionalServiceList)
                        setIsLoading(false)
                    } else {
                        setAdditionalServiceList([])
                        setIsLoading(false)
                    }
                })
                .catch((error) => {
                    console.log("error", error)
                })
            const controller = new AbortController()
            fetchLocationData(controller.signal)
        }
    }, [props.isOpenPetServicesModal])

    const getServices = (id) => {
        setIsLoading(true)
        getLocationServiceItemsPriceList(
            id,
            moment().format("MM/DD/YYYY"),
            props?.data?.petBreedID,
            props?.data?.petSizeID,
            props?.data?.employeeID,
            props?.data?.employeeName,
            props?.data?.petID,
            props?.data?.petTypeID
        )
            .then((data) => {
                if (data.Services !== undefined) {
                    console.log("res - All services with SP-selected services", data)

                    const { additionalServiceList } = data.Services.reduce(
                        (lists, service) => {
                            const existingIndex = lists.additionalServiceList.findIndex(
                                (existingService) => existingService.id === service.numServiceItemID && existingService.orderID === service.numOrderID
                            )

                            if (existingIndex === -1) {
                                lists.additionalServiceList.push({
                                    ...service,
                                    desc: service.varServiceItemDescription,
                                })
                            }

                            return lists
                        },
                        { additionalServiceList: [] }
                    )

                    setAdditionalServiceList(additionalServiceList)
                    setIsLoading(false)
                } else {
                    setAdditionalServiceList([])
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                console.log("error", error)
            })
    }

    const filteredServices = additionalServiceList.filter((service) => service.varServiceItemName.toLowerCase().includes(searchTerm.toLowerCase()))

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value)
    }

    return (
        <Modal open={props.isOpenPetServicesModal}>
            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "1rem", padding: "0 1rem 0 1rem" }}>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                    <p style={{ fontSize: "20px", fontWeight: "600", color: "#192252" }}>Services</p>
                    <CloseButton onClick={() => props.setIsOpenPetServicesModal(false)} />
                </div>

                <div style={{ padding: "10px", borderRadius: "8px", background: "#f3f3f3", boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 4px", display: "flex", justifyContent: "center" }}>
                    {allLocations !== undefined && allLocations !== null ? (
                        <div style={{ width: "60%" }}>
                            <AdvancedDropDown
                                data={allLocations.sort((a, b) => a.numLocationID - b.numLocationID).map((i) => ({ label: i.varLocationName, value: i.numLocationID }))}
                                onChange={(e) => {
                                    setFilterLocation(e?.value)
                                    getServices(e?.value)
                                }}
                                ref={filterLocationRef}
                                placeHolder={"Location"}
                                passByID={true}
                                value={filterLocation}
                            />
                        </div>
                    ) : null}
                </div>
                <TextField label="Search service" variant="outlined" value={searchTerm} onChange={handleSearchChange} size="small" fullWidth />

                <div style={{ overflowY: "scroll", height: "400px", paddingRight: "5px", width: "800px" }}>
                    {isLoading ? (
                        <p>Loading...</p>
                    ) : filteredServices.length > 0 ? (
                        filteredServices
                            .sort((a, b) => {
                                if (a.bitIsAdditional === false && b.bitIsAdditional === true) {
                                    return -1
                                } else if (a.bitIsAdditional === true && b.bitIsAdditional === false) {
                                    return 1
                                } else {
                                    return 0
                                }
                            })
                            .map((service, index) => {
                                return (
                                    <>
                                        {service.numServiceItemPreviousSalesPrice === 0 ? (
                                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                <div style={{ width: "100%" }} className={styles.main_service_accordion}>
                                                    <div>
                                                        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                            {service.bitIsAdditional === false ? (
                                                                <Tooltip
                                                                    title={
                                                                        <>
                                                                            <div style={{ padding: "5px" }}>
                                                                                <p
                                                                                    style={{
                                                                                        fontSize: "14px",
                                                                                        color: "white",
                                                                                        fontWeight: "600",
                                                                                        marginBottom: "5px",
                                                                                    }}
                                                                                ></p>
                                                                                <p style={{ fontSize: "14px", color: "white", fontWeight: "600" }}>{service.varServiceItemName}</p>
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        flexWrap: "wrap",
                                                                                    }}
                                                                                >
                                                                                    {service.SubServices.length > 0
                                                                                        ? service.SubServices.map((sub, index) => {
                                                                                              return (
                                                                                                  <p
                                                                                                      key={index}
                                                                                                      style={{
                                                                                                          whiteSpace: "pre-wrap",
                                                                                                          fontWeight: "500",
                                                                                                          color: "white",
                                                                                                      }}
                                                                                                  >
                                                                                                      - {sub.SubServiceName}
                                                                                                  </p>
                                                                                              )
                                                                                          })
                                                                                        : null}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                >
                                                                    <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>(M)</p>
                                                                </Tooltip>
                                                            ) : (
                                                                <Tooltip
                                                                    style={{ paddingLeft: "5px" }}
                                                                    title={
                                                                        <>
                                                                            <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Additional Service</p>
                                                                            <p style={{ fontSize: "12px", color: "#fff", fontWeight: "500" }}>{service.varServiceItemName}</p>
                                                                        </>
                                                                    }
                                                                >
                                                                    <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>(A)</p>
                                                                </Tooltip>
                                                            )}
                                                            <Tooltip
                                                                title={
                                                                    <>
                                                                        <div style={{ padding: "5px" }}>
                                                                            <p
                                                                                style={{
                                                                                    fontSize: "14px",
                                                                                    color: "white",
                                                                                    fontWeight: "600",
                                                                                    marginBottom: "5px",
                                                                                }}
                                                                            ></p>
                                                                            <p style={{ fontSize: "14px", color: "white", fontWeight: "600" }}>{service.varServiceItemName}</p>
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    flexWrap: "wrap",
                                                                                }}
                                                                            >
                                                                                {service.SubServices.length > 0
                                                                                    ? service.SubServices.map((sub, index) => {
                                                                                          return (
                                                                                              <p
                                                                                                  key={index}
                                                                                                  style={{
                                                                                                      whiteSpace: "pre-wrap",
                                                                                                      fontWeight: "500",
                                                                                                      color: "white",
                                                                                                  }}
                                                                                              >
                                                                                                  - {sub.SubServiceName}
                                                                                              </p>
                                                                                          )
                                                                                      })
                                                                                    : null}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }
                                                            >
                                                                <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>{service.varServiceItemName}</p>
                                                            </Tooltip>
                                                            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                                {service.varMessageToEmployee !== null ? (
                                                                    <Tooltip
                                                                        title={
                                                                            <>
                                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Message to the Employee</p>
                                                                                <p style={{ fontSize: "12px", color: "#fff" }}>{service.varMessageToEmployee}</p>
                                                                            </>
                                                                        }
                                                                    >
                                                                        <Email style={{ fontSize: "17px", color: "#979797" }} />
                                                                    </Tooltip>
                                                                ) : null}

                                                                {service.varSalesCommissionMessage !== null ? (
                                                                    <Tooltip
                                                                        title={
                                                                            <>
                                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Sales Commission Message</p>
                                                                                <p style={{ fontSize: "12px", color: "#fff" }}>{service.varSalesCommissionMessage}</p>
                                                                            </>
                                                                        }
                                                                    >
                                                                        <MonetizationOn style={{ fontSize: "17px", color: "#979797" }} />
                                                                    </Tooltip>
                                                                ) : null}

                                                                {service.ChargeEntered !== null ? (
                                                                    <Tooltip
                                                                        title={
                                                                            <>
                                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Charge Entered</p>
                                                                            </>
                                                                        }
                                                                    >
                                                                        <Payments style={{ fontSize: "18px", color: "#979797" }} />
                                                                    </Tooltip>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <p
                                                            style={{
                                                                color: "#4A5163",
                                                                fontWeight: "400",
                                                                fontSize: "12px",
                                                                margin: "0",
                                                                lineHeight: "1.25em",
                                                            }}
                                                        >
                                                            {service.varServiceItemDescription}

                                                            {service.varPriceChangeComment !== null ? <span> | {service.varPriceChangeComment}</span> : null}
                                                        </p>
                                                    </div>

                                                    <div style={{ borderRadius: "4px", padding: "0 5px 0 5px", backgroundColor: "#EFF5FF" }}>
                                                        <p
                                                            style={{
                                                                color: "#2630B9",
                                                                fontSize: "16px",
                                                                fontWeight: "400",
                                                                margin: "0",
                                                                padding: "0 5px",
                                                                height: "100%",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                whiteSpace: "nowrap",
                                                            }}
                                                        >
                                                            <b>${service.numServiceItemSalesPrice}</b>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : service.numServiceItemPreviousSalesPrice === service.numServiceItemSalesPrice ? (
                                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                <div style={{ width: "100%" }} className={styles.main_service_accordion}>
                                                    <div>
                                                        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                            {service.bitIsAdditional === false ? (
                                                                <Tooltip
                                                                    title={
                                                                        <>
                                                                            <div style={{ padding: "5px" }}>
                                                                                <p
                                                                                    style={{
                                                                                        fontSize: "14px",
                                                                                        color: "white",
                                                                                        fontWeight: "600",
                                                                                        marginBottom: "5px",
                                                                                    }}
                                                                                ></p>
                                                                                <p style={{ fontSize: "14px", color: "white", fontWeight: "600" }}>{service.varServiceItemName}</p>
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        flexWrap: "wrap",
                                                                                    }}
                                                                                >
                                                                                    {service.SubServices.length > 0
                                                                                        ? service.SubServices.map((sub, index) => {
                                                                                              return (
                                                                                                  <p
                                                                                                      key={index}
                                                                                                      style={{
                                                                                                          whiteSpace: "pre-wrap",
                                                                                                          fontWeight: "500",
                                                                                                          color: "white",
                                                                                                      }}
                                                                                                  >
                                                                                                      - {sub.SubServiceName}
                                                                                                  </p>
                                                                                              )
                                                                                          })
                                                                                        : null}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                >
                                                                    <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>(M)</p>
                                                                </Tooltip>
                                                            ) : (
                                                                <Tooltip
                                                                    style={{ paddingLeft: "5px" }}
                                                                    title={
                                                                        <>
                                                                            <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Additional Service</p>
                                                                            <p style={{ fontSize: "12px", color: "#fff", fontWeight: "500" }}>{service.varServiceItemName}</p>
                                                                        </>
                                                                    }
                                                                >
                                                                    <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>(A)</p>
                                                                </Tooltip>
                                                            )}
                                                            <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>{service.varServiceItemName}</p>
                                                            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                                {service.varMessageToEmployee !== null ? (
                                                                    <Tooltip
                                                                        title={
                                                                            <>
                                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Message to the Employee</p>
                                                                                <p style={{ fontSize: "12px", color: "#fff" }}>{service.varMessageToEmployee}</p>
                                                                            </>
                                                                        }
                                                                    >
                                                                        <Email style={{ fontSize: "17px", color: "#979797" }} />
                                                                    </Tooltip>
                                                                ) : null}

                                                                {service.varSalesCommissionMessage !== null ? (
                                                                    <Tooltip
                                                                        title={
                                                                            <>
                                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Sales Commission Message</p>
                                                                                <p style={{ fontSize: "12px", color: "#fff" }}>{service.varSalesCommissionMessage}</p>
                                                                            </>
                                                                        }
                                                                    >
                                                                        <MonetizationOn style={{ fontSize: "17px", color: "#979797" }} />
                                                                    </Tooltip>
                                                                ) : null}

                                                                {service.ChargeEntered !== null ? (
                                                                    <Tooltip
                                                                        title={
                                                                            <>
                                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Charge Entered</p>
                                                                            </>
                                                                        }
                                                                    >
                                                                        <Payments style={{ fontSize: "18px", color: "#979797" }} />
                                                                    </Tooltip>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <p
                                                            style={{
                                                                color: "#4A5163",
                                                                fontWeight: "400",
                                                                fontSize: "12px",
                                                                margin: "0",
                                                                lineHeight: "1.25em",
                                                            }}
                                                        >
                                                            {service.varServiceItemDescription}
                                                            {service.varPriceChangeComment !== null ? <span> | {service.varPriceChangeComment}</span> : null}
                                                        </p>
                                                    </div>

                                                    <div style={{ borderRadius: "4px", padding: "0 5px 0 5px", backgroundColor: "#EFF5FF" }}>
                                                        <p
                                                            style={{
                                                                color: "#2630B9",
                                                                fontSize: "16px",
                                                                fontWeight: "400",
                                                                margin: "0",
                                                                padding: "0 5px",
                                                                height: "100%",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                whiteSpace: "nowrap",
                                                            }}
                                                        >
                                                            <b>${service.numServiceItemSalesPrice}</b>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : service.numServiceItemSalesPrice > service.numServiceItemPreviousSalesPrice ? (
                                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                <div style={{ backgroundColor: "#FEE8E7", width: "100%", display: "flex", flexDirection: "column" }} className={styles.main_service_accordion}>
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <div>
                                                            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                                {service.bitIsAdditional === false ? (
                                                                    <Tooltip
                                                                        title={
                                                                            <>
                                                                                <div style={{ padding: "5px" }}>
                                                                                    <p
                                                                                        style={{
                                                                                            fontSize: "14px",
                                                                                            color: "white",
                                                                                            fontWeight: "600",
                                                                                            marginBottom: "5px",
                                                                                        }}
                                                                                    ></p>
                                                                                    <p style={{ fontSize: "14px", color: "white", fontWeight: "600" }}>{service.varServiceItemName}</p>
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            flexWrap: "wrap",
                                                                                        }}
                                                                                    >
                                                                                        {service.SubServices.length > 0
                                                                                            ? service.SubServices.map((sub, index) => {
                                                                                                  return (
                                                                                                      <p
                                                                                                          key={index}
                                                                                                          style={{
                                                                                                              whiteSpace: "pre-wrap",
                                                                                                              fontWeight: "500",
                                                                                                              color: "white",
                                                                                                              margin: "0",
                                                                                                          }}
                                                                                                      >
                                                                                                          {sub.SubServiceName}
                                                                                                      </p>
                                                                                                  )
                                                                                              })
                                                                                            : null}
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    >
                                                                        <p
                                                                            style={{
                                                                                margin: "0",
                                                                                fontWeight: "600",
                                                                                color: "#002867",
                                                                                fontSize: "14px",
                                                                            }}
                                                                        >
                                                                            (M)
                                                                        </p>
                                                                    </Tooltip>
                                                                ) : (
                                                                    <Tooltip
                                                                        style={{ paddingLeft: "5px" }}
                                                                        title={
                                                                            <>
                                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Additional Service</p>
                                                                                <p style={{ fontSize: "12px", color: "#fff", fontWeight: "500" }}>{service.varServiceItemName}</p>
                                                                            </>
                                                                        }
                                                                    >
                                                                        <p
                                                                            style={{
                                                                                margin: "0",
                                                                                fontWeight: "600",
                                                                                color: "#002867",
                                                                                fontSize: "14px",
                                                                            }}
                                                                        >
                                                                            (A)
                                                                        </p>
                                                                    </Tooltip>
                                                                )}
                                                                <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>{service.varServiceItemName}</p>
                                                                <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                                    {service.varMessageToEmployee !== null ? (
                                                                        <Tooltip
                                                                            title={
                                                                                <>
                                                                                    <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Message to the Employee</p>
                                                                                    <p style={{ fontSize: "12px", color: "#fff" }}>{service.varMessageToEmployee}</p>
                                                                                </>
                                                                            }
                                                                        >
                                                                            <Email style={{ fontSize: "17px", color: "#979797" }} />
                                                                        </Tooltip>
                                                                    ) : null}

                                                                    {service.varSalesCommissionMessage !== null ? (
                                                                        <Tooltip
                                                                            title={
                                                                                <>
                                                                                    <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Sales Commission Message</p>
                                                                                    <p style={{ fontSize: "12px", color: "#fff" }}>{service.varSalesCommissionMessage}</p>
                                                                                </>
                                                                            }
                                                                        >
                                                                            <MonetizationOn style={{ fontSize: "17px", color: "#979797" }} />
                                                                        </Tooltip>
                                                                    ) : null}

                                                                    {service.ChargeEntered !== null ? (
                                                                        <Tooltip
                                                                            title={
                                                                                <>
                                                                                    <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Charge Entered</p>
                                                                                </>
                                                                            }
                                                                        >
                                                                            <Payments style={{ fontSize: "18px", color: "#979797" }} />
                                                                        </Tooltip>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            <p
                                                                style={{
                                                                    color: "#4A5163",
                                                                    fontWeight: "400",
                                                                    fontSize: "12px",
                                                                    margin: "0",
                                                                    lineHeight: "1.25em",
                                                                }}
                                                            >
                                                                {service.varServiceItemDescription}
                                                                {service.varPriceChangeComment !== null ? <span> | {service.varPriceChangeComment}</span> : null}
                                                            </p>
                                                        </div>

                                                        <div style={{ display: "flex", gap: "5px" }}>
                                                            <div style={{ borderRadius: "4px", padding: "0 5px 0 5px", backgroundColor: "#FCDADA" }}>
                                                                <p
                                                                    style={{
                                                                        color: "#802B2B",
                                                                        fontSize: "16px",
                                                                        fontWeight: "400",
                                                                        margin: "0",
                                                                        padding: "0 5px",
                                                                        height: "100%",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        whiteSpace: "nowrap",
                                                                    }}
                                                                >
                                                                    <ArrowUpward style={{ color: "#EB1A1A", fontSize: "18px" }} />
                                                                </p>
                                                            </div>

                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    minWidth: "110px",
                                                                    border: "1px solid #F9B5B5",
                                                                    borderRadius: "4px",
                                                                    padding: "0 5px 0 5px",
                                                                    backgroundColor: "#FCDADA",
                                                                }}
                                                            >
                                                                <Tooltip
                                                                    style={{ paddingLeft: "5px" }}
                                                                    title={
                                                                        <>
                                                                            <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Price Change Comment</p>
                                                                            <p style={{ fontSize: "12px", color: "#fff" }}>{service.varPriceChangeComment}</p>
                                                                        </>
                                                                    }
                                                                >
                                                                    <InfoOutlined style={{ fontSize: "18px", color: "#802B2B" }} />
                                                                </Tooltip>

                                                                <p
                                                                    style={{
                                                                        color: "#802B2B",
                                                                        fontSize: "16px",
                                                                        fontWeight: "400",
                                                                        margin: "0",
                                                                        padding: "0 5px",
                                                                        height: "100%",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        whiteSpace: "nowrap",
                                                                        justifyContent: "center",
                                                                    }}
                                                                >
                                                                    <del>${service.numServiceItemPreviousSalesPrice}</del>&nbsp;/&nbsp;
                                                                    <b>${service.numServiceItemSalesPrice}</b>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {service.varSeasonalPromotionMsg !== "" ? (
                                                        <p
                                                            style={{
                                                                display: "flex",
                                                                gap: "5px",
                                                                alignItems: "center",
                                                                padding: "4px",
                                                                borderRadius: "5px",
                                                                backgroundColor: "#fff",
                                                                color: "#16A34A",
                                                                fontWeight: 500,
                                                                fontSize: "12px",
                                                                margin: "0px",
                                                                lineHeight: "1.25em",
                                                            }}
                                                        >
                                                            <LocalOffer style={{ fontSize: "18px" }} /> {service.varSeasonalPromotionMsg}
                                                        </p>
                                                    ) : null}
                                                </div>
                                            </div>
                                        ) : (
                                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                <div style={{ backgroundColor: "#F0FDF4", width: "100%" }} className={styles.main_service_accordion}>
                                                    <div>
                                                        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                            {service.bitIsAdditional === false ? (
                                                                <Tooltip
                                                                    title={
                                                                        <>
                                                                            <div style={{ padding: "5px" }}>
                                                                                <p
                                                                                    style={{
                                                                                        fontSize: "14px",
                                                                                        color: "white",
                                                                                        fontWeight: "600",
                                                                                        marginBottom: "5px",
                                                                                    }}
                                                                                ></p>
                                                                                <p style={{ fontSize: "14px", color: "white", fontWeight: "600" }}>{service.varServiceItemName}</p>
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        flexWrap: "wrap",
                                                                                    }}
                                                                                >
                                                                                    {service.SubServices.length > 0
                                                                                        ? service.SubServices.map((sub, index) => {
                                                                                              return (
                                                                                                  <p
                                                                                                      key={index}
                                                                                                      style={{
                                                                                                          whiteSpace: "pre-wrap",
                                                                                                          fontWeight: "500",
                                                                                                          color: "white",
                                                                                                      }}
                                                                                                  >
                                                                                                      - {sub.SubServiceName}
                                                                                                  </p>
                                                                                              )
                                                                                          })
                                                                                        : null}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                >
                                                                    <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>(M)</p>
                                                                </Tooltip>
                                                            ) : (
                                                                <Tooltip
                                                                    style={{ paddingLeft: "5px" }}
                                                                    title={
                                                                        <>
                                                                            <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Additional Service</p>
                                                                            <p style={{ fontSize: "12px", color: "#fff", fontWeight: "500" }}>{service.varServiceItemName}</p>
                                                                        </>
                                                                    }
                                                                >
                                                                    <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>(A)</p>
                                                                </Tooltip>
                                                            )}
                                                            <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>{service.varServiceItemName}</p>
                                                            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                                {service.varMessageToEmployee !== null ? (
                                                                    <Tooltip
                                                                        title={
                                                                            <>
                                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Message to the Employee</p>
                                                                                <p style={{ fontSize: "12px", color: "#fff" }}>{service.varMessageToEmployee}</p>
                                                                            </>
                                                                        }
                                                                    >
                                                                        <Email style={{ fontSize: "17px", color: "#979797" }} />
                                                                    </Tooltip>
                                                                ) : null}

                                                                {service.varSalesCommissionMessage !== null ? (
                                                                    <Tooltip
                                                                        title={
                                                                            <>
                                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Sales Commission Message</p>
                                                                                <p style={{ fontSize: "12px", color: "#fff" }}>{service.varSalesCommissionMessage}</p>
                                                                            </>
                                                                        }
                                                                    >
                                                                        <MonetizationOn style={{ fontSize: "17px", color: "#979797" }} />
                                                                    </Tooltip>
                                                                ) : null}

                                                                {service.ChargeEntered !== null ? (
                                                                    <Tooltip
                                                                        title={
                                                                            <>
                                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Charge Entered</p>
                                                                            </>
                                                                        }
                                                                    >
                                                                        <Payments style={{ fontSize: "18px", color: "#979797" }} />
                                                                    </Tooltip>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <p
                                                            style={{
                                                                color: "#4A5163",
                                                                fontWeight: "400",
                                                                fontSize: "12px",
                                                                margin: "0",
                                                                lineHeight: "1.25em",
                                                            }}
                                                        >
                                                            {service.varServiceItemDescription}
                                                            {service.varPriceChangeComment !== null ? <span> | {service.varPriceChangeComment}</span> : null}
                                                        </p>
                                                    </div>

                                                    <div style={{ display: "flex", gap: "5px" }}>
                                                        <div style={{ borderRadius: "4px", padding: "0 5px 0 5px", backgroundColor: "#DCFCE7" }}>
                                                            <p
                                                                style={{
                                                                    color: "#DCFCE7",
                                                                    fontSize: "16px",
                                                                    fontWeight: "400",
                                                                    margin: "0",
                                                                    padding: "0 5px",
                                                                    height: "100%",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    whiteSpace: "nowrap",
                                                                }}
                                                            >
                                                                <ArrowDownward style={{ color: "#16A34A", fontSize: "18px" }} />
                                                            </p>
                                                        </div>

                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                minWidth: "120px",
                                                                border: "1px solid #BBF7D0",
                                                                borderRadius: "4px",
                                                                padding: "0 5px 0 5px",
                                                                backgroundColor: "#DCFCE7",
                                                            }}
                                                        >
                                                            <Tooltip
                                                                style={{ paddingLeft: "5px" }}
                                                                title={
                                                                    <>
                                                                        <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Price Change Comment</p>
                                                                        <p style={{ fontSize: "12px", color: "#fff" }}>{service.varPriceChangeComment}</p>
                                                                    </>
                                                                }
                                                            >
                                                                <InfoOutlined style={{ fontSize: "18px", color: "#15803D" }} />
                                                            </Tooltip>
                                                            <p
                                                                style={{
                                                                    color: "#15803D",
                                                                    fontSize: "16px",
                                                                    fontWeight: "400",
                                                                    margin: "0",
                                                                    padding: "0 5px",
                                                                    height: "100%",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    whiteSpace: "nowrap",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                <del>${service.numServiceItemPreviousSalesPrice}</del>&nbsp;/&nbsp;
                                                                <b>${service.numServiceItemSalesPrice}</b>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )
                            })
                    ) : (
                        <p style={{ color: "#777E90", fontWeight: "400", fontSize: "13px" }}>No services found</p>
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default DisplayPetServices
