import { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import { Menu } from "../../components/components"

import { useNavigate, useLocation } from "react-router-dom"

const AdminPanel = (props: { children? }) => {
    const navigate = useNavigate()
    const location = useLocation()

    const linkList = [
        {
            name: "Label Configuration",
            to: "label-config",
            permissionModule: "07.01 - Settings / Label Configuration",
            subRoutes: [
                {
                    subName: "Salon",
                    subTo: "Location",
                    permissionModule: "Salon",
                    isMain: false,
                },
                {
                    subName: "Pet",
                    subTo: "Pet",
                    permissionModule: "Pet",
                    isMain: false,
                },
                {
                    subName: "Customer",
                    subTo: "Customer",
                    permissionModule: "Customer",
                    isMain: false,
                },
                {
                    subName: "Employee",
                    subTo: "Employee",
                    permissionModule: "Employee",
                    isMain: false,
                },
            ],
        },
        {
            name: "Import / Export Data",
            to: "import-export",
            permissionModule: "07.02 - Settings / Import - Export Data",
        },
        {
            name: "Dir / Lib Data Reset",
            to: "import-export-reset",
            permissionModule: "07.03 - Settings / Dir - Lib Data Reset",
        },
        {
            name: "Library",
            to: "library",
            permissionModule: "07.04 - Settings / Library",
            subRoutes: [
                {
                    subName: "1.1 - Pet Types",
                    subTo: "PetType",
                    permissionModule: "07.04.01 - Settings / Library / 1.1 - Pet Types",
                    isMain: true,
                },
                {
                    subName: "1.2 - Pet Sizes",
                    subTo: "PetSize",
                    permissionModule: "07.04.02 - Settings / Library / 1.2 - Pet Sizes",
                    isMain: true,
                },
                {
                    subName: "1.3 - Pet Behaviors",
                    subTo: "Personality",
                    permissionModule: "07.04.03 - Settings / Library / 1.3 - Pet Behaviors",
                    isMain: true,
                },
                {
                    subName: "1.4 - Pet Colors",
                    subTo: "PetColor",
                    permissionModule: "07.04.04 - Settings / Library / 1.4 - Pet Colors",
                    isMain: true,
                },
                {
                    subName: "1.5 - Pet Health Concerns",
                    subTo: "MedicalCondition",
                    permissionModule: "07.04.05 - Settings / Library / 1.5 - Pet Health Concerns",
                    isMain: true,
                },
                {
                    subName: "1.6 - Pet Grooming Styles",
                    subTo: "Style",
                    permissionModule: "07.04.06 - Settings / Library / 1.6 - Pet Grooming Styles",
                    isMain: true,
                },
                {
                    subName: "1.7 - Pure Breeds",
                    subTo: "Breed",
                    permissionModule: "07.04.07 - Settings / Library / 1.7 - Pure Breeds",
                    isMain: true,
                },
                {
                    subName: "1.8 - All Breeds (Pure + Mixed)",
                    subTo: "PetBreed",
                    permissionModule: "07.04.08 - Settings / Library / 1.8 - All Breeds (Pure + Mixed)",
                    isMain: true,
                },
                {
                    subName: "divider",
                    subTo: "divider",
                    permissionModule: "07.04.09 - Settings / Library / 2.1 - Address Types",
                    isMain: true,
                },
                {
                    subName: "2.1 - Address Types",
                    subTo: "AddressType",
                    permissionModule: "07.04.09 - Settings / Library / 2.1 - Address Types",
                    isMain: true,
                },
                {
                    subName: "2.2 - Contact Types",
                    subTo: "ContactType",
                    permissionModule: "07.04.10 - Settings / Library / 2.2 - Contact Types",
                    isMain: true,
                },
                {
                    subName: "2.3 - Salutations",
                    subTo: "Salutation",
                    permissionModule: "07.04.11 - Settings / Library / 2.3 - Salutations",
                    isMain: true,
                },
                {
                    subName: "2.4 - Contact Person Type",
                    subTo: "ContactPerson",
                    permissionModule: "07.04.12 - Settings / Library / 2.4 - Contact Person Type",
                    isMain: true,
                },
                {
                    subName: "2.5 - Security Questions",
                    subTo: "UserQuestion",
                    permissionModule: "07.04.13 - Settings / Library / 2.5 - Security Questions",
                    isMain: true,
                },
                {
                    subName: "2.6 - Zip Codes",
                    subTo: "ZipCode",
                    permissionModule: "07.04.14 - Settings / Library / 2.6 - Zip Codes",
                    isMain: true,
                },
                {
                    subName: "divider",
                    subTo: "divider",
                    permissionModule: "07.04.15 - Settings / Library / 3.1 - Departments",
                    isMain: true,
                },
                {
                    subName: "3.1 - Departments",
                    subTo: "Department",
                    permissionModule: "07.04.15 - Settings / Library / 3.1 - Departments",
                    isMain: true,
                },
                {
                    subName: "3.2 - Job Roles",
                    subTo: "Occupation",
                    permissionModule: "07.04.16 - Settings / Library / 3.2 - Job Roles",
                    isMain: true,
                },
                {
                    subName: "3.3 - Groomer Specializations",
                    subTo: "Specialization",
                    permissionModule: "07.04.17 - Settings / Library / 3.3 - Groomer Specializations",
                    isMain: true,
                },
                {
                    subName: "divider",
                    subTo: "divider",
                    permissionModule: "07.04.18 - Settings / Library / 4.1 - Brush Types",
                    isMain: true,
                },
                {
                    subName: "4.1 - Brush Types",
                    subTo: "Brush",
                    permissionModule: "07.04.18 - Settings / Library / 4.1 - Brush Types",
                    isMain: true,
                },
                {
                    subName: "4.2 - Blade Sizes",
                    subTo: "Blade",
                    permissionModule: "07.04.19 - Settings / Library / 4.2 - Blade Sizes",
                    isMain: true,
                },
                {
                    subName: "4.3 - Comb Attachment Sizes",
                    subTo: "Comb",
                    permissionModule: "07.04.20 - Settings / Library / 4.3 - Comb Attachment Sizes",
                    isMain: true,
                },
                {
                    subName: "4.4 - Shampoo Types",
                    subTo: "Shampoo",
                    permissionModule: "07.04.21 - Settings / Library / 4.4 - Shampoo Types",
                    isMain: true,
                },
                {
                    subName: "divider",
                    subTo: "divider",
                    permissionModule: "07.04.22 - Settings / Library / 5.1 - Sales Items Library",
                    isMain: true,
                },
                {
                    subName: "5.1 - Sales Items Library",
                    subTo: "SalesItemsLibrary",
                    permissionModule: "07.04.22 - Settings / Library / 5.1 - Sales Items Library",
                    isMain: true,
                },
                {
                    subName: "5.2 - Sales Items",
                    subTo: "SalesItems",
                    permissionModule: "07.04.23 - Settings / Library / 5.2 - Sales Items",
                    isMain: true,
                },

                {
                    subName: "5.3 - Service Items Library",
                    subTo: "ServiceItemsLibrary",
                    permissionModule: "07.04.24 - Settings / Library / 5.3 - Service Items Library",
                    isMain: true,
                },
                {
                    subName: "5.4 - Time of Day",
                    subTo: "TimeOfDay",
                    permissionModule: "07.04.25 - Settings / Library / 5.4 - Time of Day",
                    isMain: true,
                },
                {
                    subName: "5.5 - General Price List",
                    subTo: "GeneralPriceList",
                    permissionModule: "07.04.26 - Settings / Library / 5.5 - General Price List",
                    isCustom: "",
                    isMain: true,
                },
                {
                    subName: "5.6 - Special Price List",
                    subTo: "SpecialPriceList",
                    permissionModule: "07.04.30 - Settings / Library / 5.6 - Special Price List",
                    isCustom: "",
                    isMain: true,
                },
                {
                    subName: "5.7 - Packages",
                    subTo: "Packages",
                    permissionModule: "07.04.31 - Settings / Library / 5.7 - Packages",
                    isCustom: "",
                    isMain: true,
                },
                {
                    subName: "divider",
                    subTo: "divider",
                    permissionModule: "07.04.27 - Settings / Library / 6.1 - Vaccinations",
                    isMain: true,
                },
                {
                    subName: "6.1 - Vaccinations",
                    subTo: "Vaccine",
                    permissionModule: "07.04.27 - Settings / Library / 6.1 - Vaccinations",
                    isMain: true,
                },
                {
                    subName: "6.2 - Vets",
                    subTo: "PetVet",
                    permissionModule: "07.04.28 - Settings / Library / 6.2 - Vets",
                    isMain: true,
                },
                {
                    subName: "6.3 - Waiver Documents",
                    subTo: "WaiverDocument",
                    permissionModule: "07.04.29 - Settings / Library / 6.3 - Waiver Documents",
                    isMain: true,
                },
            ],
        },
        {
            name: "User Roles",
            to: "user-roles",
            permissionModule: "07.05 - Settings / User Roles",
        },
        {
            name: "Scheduling",
            to: "scheduler",
            permissionModule: "07.06 - Settings / Scheduling",
        },
        {
            name: "Messaging",
            to: "messaging",
            permissionModule: "07.07 - Settings / Messaging",
        },
        {
            name: "MessagingTemplates",
            to: "messaging-templates",
            permissionModule: "07.07 - Settings / Messaging",
        },
        {
            name: "Active User Sessions",
            to: "Active-user-sessions",
            permissionModule: "07.07 - Settings / Messaging",
        },
    ]

    // useEffect(() => {
    //     if (location.pathname === "/admin/") {
    //         navigate(linkList?.[0]?.to)
    //     }
    // }, [location])

    return (
        <div>
            <div style={{ display: "flex" }}>
                <Menu
                    scrollToTop={(t) => {
                        var Win: any = document.getElementById("scroller")
                        Win.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                    }}
                    linkList={linkList}
                />

                <div id="scroller" style={{ width: "100%", height: "calc(100vh - 90px)", overflowY: "scroll" }}>
                    <Outlet />
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default AdminPanel
