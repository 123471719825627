import axios from "axios"
import { createContext, useState } from "react"
import { axiosOpen, axiosPrivate } from "../apis/axios"

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({})

    const refreshAccessToken = async () => {
        try {
            const response = await axiosOpen.post("/Auth/refresh-token", {})
            console.log("new refresh", response?.data?.token)

            const { token } = response.data
            setAuth((prev) => ({
                ...prev,
                accessToken: token,
            }))
            return token
        } catch (err) {
            console.error("Failed to refresh token:", err)
            logout()
        }
    }

    axiosPrivate.interceptors.response.use(
        (response) => {
            return response // Simply return the response if successful
        },
        async (error) => {
            const originalRequest = error.config
            console.log("error_token", error)

            if (error.response && error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true // Prevent infinite retry loops

                try {
                    const newAccessToken = await refreshAccessToken()
                    originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`

                    return axiosPrivate(originalRequest)
                } catch (refreshError) {
                    logout()
                    console.error("Refresh token failed", refreshError)
                    return Promise.reject(refreshError)
                }
            }

            return Promise.reject(error)
        }
    )

    const logout = () => {
        setAuth({ accessToken: null, user: null })
    }

    return <AuthContext.Provider value={{ auth, setAuth, refreshAccessToken, logout }}>{children}</AuthContext.Provider>
}

export default AuthContext
