import { TextField } from "@mui/material"
import { Modal } from "../../../components/components"
import CloseButton from "../../../components/Modal/CloseButton/CloseButton"
import { Avatar, Button } from "../../../components"
import { FileUploader } from "react-drag-drop-files"
import { FileUploadOutlined } from "@mui/icons-material"
import { Cropper } from "react-cropper"
import { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import imageCompression from "browser-image-compression"
import { AdvancedInput } from "../../../components/AdvancedInput/AdvancedInput"
import { axiosPrivate, buildHeaderObject } from "../../../apis/axios"
import { shortDate } from "../../../utils/DateFormatter"
import InputMask from "react-input-mask"
import { Map } from "../../../components/Map/Map"

import useAuth from "../../../hooks/useAuth"

const NewSalon = (props) => {
    const fileTypes = ["JPEG", "jpg", "WEBP", "PNG", "png"]
    const [loading, setLoading] = useState(false)
    const [fileSelected, setFileSelected] = useState(false)
    const [importedData, setImportedData] = useState<any>("")
    const [showCropper, setShowCropper] = useState(false)
    const cropperRef = useRef<HTMLImageElement>(null)
    const [cropper, setCropper] = useState<any>()
    const [imageName, setImageName] = useState<any>("")
    const [newHeaders, setNewHeaders] = useState<any>([])
    const [salonLoading, setSalonLoading] = useState(false)

    const { auth } = useAuth() as any

    const [lng, setLng] = useState(-74.412497)
    const [lat, setLat] = useState(40.554626)
    const [locName, setLocName] = useState("🏡 Somewhere on planet earth")

    const center = {
        lat: lat,
        lng: lng,
        name: locName,
    }

    const [newSalonObject, setNewSalonObject] = useState([
        {
            location: {
                bitActive: true,
                numCreatedBy: 1,
                varCreatedBy: "Admin",
                dtCreatedDate: "",
                numEditedBy: 0,
                varEditedBy: "",
                dtEditedDate: "",
                numDeletedBy: 0,
                varDeletedBy: "",
                dtDeletedDate: "",
                numLocationID: 0,
                varAltLocationID: "",
                varLocationBC: "",
                varLocationRFID: "",
                varLocationName: "",
                varLocationNeighborhood: "",
                varLocationEmail: "",
                varLocationPhone: "",
                varLocationFax: "",
                varLocationAddress1: "",
                varLocationAddress2: "",
                varLocationCity: "",
                varLocationState: "",
                varLocationZip: "",
                varLocationGoogleMapAddress: "",
                varLocationCounty: "",
                varLocationTimezone: "string",
                varLocationCountry: "",
                varLocationLatitude: "",
                varLocationLongitude: "",
                varLocationAltitude: "",
                numDayCareSlots: 0,
                numBoardingCages: 0,
                numBoardingRuns: 0,
                bitGroomingService: true,
                bitBoardingService: false,
                bitDaycareService: false,
                bitSittingService: false,
                bitTrainingService: false,
                bitMobileGroomingService: false,
                bitStoreProductService: true,
                varLocationStatus: "",
                varLocationImagePath: "",
                varLocationCustomText1: "",
                varLocationCustomText2: "",
                dtLocationCustomDate1: "",
                dtLocationCustomDate2: "",
                numLocationCustomList1ID: 0,
                numLocationCustomList2ID: 0,
                bitLocationCustomBit1: false,
                bitLocationCustomBit2: false,
            },
            locationPetType: [0],
        },
    ])

    async function getUpdatedHeaders(objectName) {
        const result = await axiosPrivate.get(`Label/${objectName}`, buildHeaderObject(auth))
        return result
    }

    async function saveNewImage(salonID, imgName) {
        let uploadPayload = {
            locationImage: [
                {
                    numLocationID: salonID,
                    varLocationImagePath: imgName,
                },
            ],
        }
        const result = await axiosPrivate.put(`/Location/UploadImages`, uploadPayload, buildHeaderObject(auth))
        return result
    }

    async function saveData() {
        let sendObj = newSalonObject[0]
        console.log("salon obj", sendObj)

        const result = await axiosPrivate.post("Location", sendObj, buildHeaderObject(auth))
        return result
    }

    const saveChanges = () => {
        setSalonLoading(true)

        var Form: any = document.getElementById("editForm")

        if (Form.checkValidity() == false) {
            console.log("invalid")
            setSalonLoading(false)
        } else {
            upload()
            setSalonLoading(false)
        }
    }

    const readFile = (files) => {
        if (files.size > 1000000) {
            toast.error(files.name + " File too large")
        } else {
            setImportedData(files)
            setShowCropper(true)
            setFileSelected(true)
            setImageName(files.name)
        }
    }

    const upload = () => {
        saveData()
            .then((res) => {
                console.log("adad", res?.data)
                let salonLast = res?.data?.numResponseID

                if (salonLast > 0) {
                    if (!fileSelected) {
                        toast.success("Salon was updated successfully!")
                        setSalonLoading(false)
                        props.isSuccess(true)
                    } else {
                        const formData = new FormData()
                        formData.append("folderpath", `directories/locations`)
                        formData.append("method", "N")
                        formData.append("deletingFileName", "-")
                        formData.append("renameFileNames", "0")

                        const file = importedData
                        const fileExtension = file.name.split(".").pop()
                        const renamedFile = new File([file], `${salonLast}.${fileExtension}`, { type: file.type })
                        formData.append("files", renamedFile)
                        console.log("last id form ", formData)
                        axiosPrivate
                            .post(`FileUpload/UploadFile`, formData, buildHeaderObject(auth))
                            .then((response) => {
                                console.log(response)

                                if (response.data.files.length > 0) {
                                    saveNewImage(salonLast, salonLast + "." + imageName.split(".").pop())
                                        .then((res) => {
                                            toast.success("Salon was updated successfully!")
                                            console.log("last id ", salonLast, salonLast + "." + imageName.split(".").pop())
                                            setSalonLoading(false)
                                            props.isSuccess(true)
                                        })
                                        .catch((error) => {
                                            console.error(error)
                                            toast.error("Something went wrong when saving new Salon!")
                                            setSalonLoading(false)
                                        })
                                }
                            })
                            .catch((error) => {
                                console.error(error)
                                toast.error("Something went wrong when saving new Salon!")
                                setSalonLoading(false)
                            })
                    }
                } else {
                    toast.error("Something went wrong when saving new Salon!")
                }
            })
            .catch((err) => {
                console.log("errr", err?.response?.data)
                setSalonLoading(false)
            })
    }

    const blobToFile = (theBlob: Blob, fileName: string) => {
        var b: any = theBlob
        b.lastModifiedDate = new Date()
        b.name = fileName
        return theBlob
    }

    const getCropData = async () => {
        if (typeof cropper !== "undefined") {
            cropper
                .getCroppedCanvas({
                    imageSmoothingEnabled: false,
                })
                .toBlob((blob) => {
                    let toFile = blobToFile(blob, imageName)
                    let imageFile: any = toFile
                    let options = {
                        maxSizeMB: 0.5,
                        fileType: "image/jpeg",
                        maxWidthOrHeight: 200,
                        useWebWorker: true,
                        onProgress: (progress) => {
                            setLoading(true)
                            if (progress == 100) {
                                setLoading(false)
                            }
                        },
                    }
                    imageCompression(imageFile, options)
                        .then(function (compressedFile) {
                            setImportedData(compressedFile)
                            setShowCropper(false)
                        })
                        .catch(function (error) {
                            console.log(error.message)
                        })
                })
        }
    }

    useEffect(() => {
        getUpdatedHeaders("Location")
            .then((response) => {
                const header = {
                    location: response?.data,
                }

                setNewHeaders(header)
                console.log("hello", response?.data)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }, [])

    const headingMapper = (dataValueIndex: string, type: string, objectType: string, valueType?: string) => {
        let labelName: string = ""
        let labelIsRequired: boolean = false

        //   {headingMapper("varLocationName", "standard", "location")}

        if (newHeaders != undefined) {
            // console.log("heading mapper")
            if (Object.keys(newHeaders).length > 0) {
                let labelIndex = newHeaders[objectType]?.[type][dataValueIndex]?.varLabelValue

                let findIndex = newHeaders[objectType]?.[type].findIndex((value) => {
                    return value.varTblLabelName === dataValueIndex
                })

                labelName = newHeaders[objectType]?.[type][findIndex]?.varLabelValue
                labelIsRequired = newHeaders[objectType]?.[type][findIndex]?.bitRequired
            }
        }

        if (valueType === "isRequired") {
            return labelIsRequired
        } else {
            return labelName
        }
    }

    return (
        <div>
            <Modal open={true}>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem", width: "100%" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                        <p style={{ fontSize: "20px", fontWeight: "600", color: "#192252" }}>New Salon</p>
                        <CloseButton onClick={() => props?.setShowNewSalonModal(false)} />
                    </div>

                    <div style={{ backgroundColor: "#fff", width: "700px" }}>
                        <div style={{ display: "flex", gap: "1.5rem", flexDirection: "column", padding: "0 0 .8rem 0" }}>
                            <>
                                <div style={{ backgroundColor: "#F9FAFB" }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", color: "#F9FAFB", padding: "16px 22px 16px 22px" }}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "column" }}>
                                            <Avatar
                                                shape={"rounded"}
                                                img={fileSelected ? URL.createObjectURL(importedData) : `${process.env.REACT_APP_MEDIA_URL}directories/locations/${importedData}`}
                                                isVip={false}
                                                className={"large"}
                                                labelType={"largeLabel"}
                                            />

                                            {newSalonObject[0]?.location?.varLocationImagePath !== importedData ? (
                                                <p
                                                    style={{ color: "#005DF1", fontSize: "14px", fontWeight: "500", textAlign: "center", cursor: "pointer" }}
                                                    onClick={() => {
                                                        {
                                                            setImportedData(newSalonObject[0]?.location?.varLocationImagePath)
                                                        }
                                                        {
                                                            setFileSelected(false)
                                                        }
                                                    }}
                                                >
                                                    Reset photo
                                                </p>
                                            ) : null}
                                        </div>
                                        <div style={{ border: "2px dashed #5f9cfe", borderRadius: "8px", padding: "2rem", backgroundColor: "#fff" }}>
                                            <FileUploader
                                                handleChange={readFile}
                                                onDrop={readFile}
                                                disabled={loading ? true : false}
                                                name="file"
                                                types={fileTypes}
                                                multiple={false}
                                                label=""
                                                children={
                                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                                        <FileUploadOutlined style={{ color: "#2076FF" }} />
                                                        <p>Drag and drop or browse to choose a image</p>
                                                    </div>
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                <Modal open={showCropper}>
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                                        <div style={{ textAlign: "end", width: "100%" }}>
                                            <CloseButton
                                                onClick={() => {
                                                    {
                                                        setShowCropper(false)
                                                    }
                                                    {
                                                        setFileSelected(false)
                                                    }
                                                    setImportedData(newSalonObject[0]?.location?.varLocationImagePath)
                                                }}
                                            />
                                        </div>

                                        <div style={{ backgroundColor: "#fff" }}>
                                            <Cropper
                                                style={{ maxWidth: "500px", maxHeight: "500px" }}
                                                modal={true}
                                                src={fileSelected ? URL.createObjectURL(importedData) : ""}
                                                cropBoxResizable={true}
                                                initialAspectRatio={1}
                                                aspectRatio={1}
                                                guides={true}
                                                minCropBoxHeight={200}
                                                scalable={false}
                                                minCropBoxWidth={200}
                                                highlight={true}
                                                zoomable={true}
                                                movable={true}
                                                onInitialized={(instance) => {
                                                    setCropper(instance)
                                                }}
                                                ref={cropperRef}
                                            />
                                        </div>
                                        <div style={{ textAlign: "end", width: "100%" }}>
                                            <Button variant="contained" color="primary" onClick={() => getCropData()}>
                                                Crop & Fix
                                            </Button>
                                        </div>
                                    </div>
                                </Modal>
                            </>

                            <div style={{ overflowY: "scroll", height: "400px" }}>
                                <form id="editForm" onSubmit={(e) => e.preventDefault()}>
                                    <p style={{ fontSize: "14px", fontWeight: 600, marginBottom: ".6rem" }}>Basic details</p>
                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "2rem", rowGap: "2ch", marginBottom: "15px" }}>
                                        <div>
                                            <TextField
                                                variant="filled"
                                                style={{ width: "100%" }}
                                                label={
                                                    <div style={{ display: "flex", fontSize: "14px" }}>
                                                        {headingMapper("varLocationName", "standard", "location")}
                                                        {!!headingMapper("varLocationName", "standard", "location", "isRequired") ? (
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                }
                                                required={!!headingMapper("varLocationName", "standard", "location", "isRequired")}
                                                onChange={(e) => {
                                                    const newValue = { ...newSalonObject }
                                                    newValue[0].location.varLocationName = e.target.value
                                                    setNewSalonObject(newValue)
                                                }}
                                                type={"advanced"}
                                                value={newSalonObject[0]?.location.varLocationName}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                variant="filled"
                                                style={{ width: "100%" }}
                                                label={
                                                    <div style={{ display: "flex", fontSize: "14px" }}>
                                                        {headingMapper("varLocationNeighborhood", "standard", "location")}
                                                        {!!headingMapper("varLocationNeighborhood", "standard", "location", "isRequired") ? (
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                }
                                                required={!!headingMapper("varLocationNeighborhood", "standard", "location", "isRequired")}
                                                onChange={(e) => {
                                                    const newValue = { ...newSalonObject }
                                                    newValue[0].location.varLocationNeighborhood = e.target.value
                                                    setNewSalonObject(newValue)
                                                }}
                                                type={"advanced"}
                                                value={newSalonObject[0]?.location.varLocationNeighborhood}
                                            />
                                        </div>
                                    </div>

                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "2rem", rowGap: "2ch", marginBottom: "15px" }}>
                                        <InputMask
                                            alwaysShowMask="true"
                                            mask="\(999\)999\-9999"
                                            maskChar="_"
                                            value={newSalonObject[0].location.varLocationPhone}
                                            onChange={(e) => {
                                                const newValue = { ...newSalonObject }
                                                newValue[0].location.varLocationPhone = e.target.value
                                                setNewSalonObject(newValue)
                                            }}
                                        >
                                            {(inputProps) => (
                                                <TextField
                                                    inputProps={{
                                                        pattern: !!headingMapper("varLocationPhone", "standard", "location", "isRequired")
                                                            ? "^[0-9()-]+$"
                                                            : newSalonObject[0].location.varLocationPhone === "" || newSalonObject[0].location.varLocationPhone === "(___)___-____"
                                                            ? null
                                                            : "^[0-9()-]+$",
                                                    }}
                                                    {...inputProps}
                                                    type="tel"
                                                    required={!!headingMapper("varLocationPhone", "standard", "location", "isRequired")}
                                                    style={{ width: "100%" }}
                                                    label={
                                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                                            {headingMapper("varLocationPhone", "standard", "location")}
                                                            {!!headingMapper("varLocationPhone", "standard", "location", "isRequired") ? (
                                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                    Required
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    }
                                                    variant="standard"
                                                />
                                            )}
                                        </InputMask>

                                        <InputMask
                                            alwaysShowMask="true"
                                            mask="\(999\)999\-9999"
                                            maskChar="_"
                                            value={newSalonObject[0].location.varLocationFax}
                                            onChange={(e) => {
                                                const newValue = { ...newSalonObject }
                                                newValue[0].location.varLocationFax = e.target.value
                                                setNewSalonObject(newValue)
                                            }}
                                        >
                                            {(inputProps) => (
                                                <TextField
                                                    inputProps={{
                                                        pattern: !!headingMapper("varLocationFax", "standard", "location", "isRequired")
                                                            ? "^[0-9()-]+$"
                                                            : newSalonObject[0].location.varLocationFax === "" || newSalonObject[0].location.varLocationFax === "(___)___-____"
                                                            ? null
                                                            : "^[0-9()-]+$",
                                                    }}
                                                    {...inputProps}
                                                    type="tel"
                                                    required={!!headingMapper("varLocationFax", "standard", "location", "isRequired")}
                                                    style={{ width: "100%" }}
                                                    label={
                                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                                            {headingMapper("varLocationFax", "standard", "location")}
                                                            {!!headingMapper("varLocationFax", "standard", "location", "isRequired") ? (
                                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                    Required
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    }
                                                    variant="standard"
                                                />
                                            )}
                                        </InputMask>
                                    </div>

                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "2rem", rowGap: "2ch", marginBottom: "15px" }}>
                                        <TextField
                                            variant="filled"
                                            style={{ width: "100%" }}
                                            label={
                                                <div style={{ display: "flex", fontSize: "14px" }}>
                                                    {headingMapper("varLocationEmail", "standard", "location")}
                                                    {!!headingMapper("varLocationEmail", "standard", "emplolocationyee", "isRequired") ? (
                                                        <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                            Required
                                                        </span>
                                                    ) : null}
                                                </div>
                                            }
                                            InputLabelProps={{ shrink: true }}
                                            required={!!headingMapper("varLocationEmail", "standard", "location", "isRequired")}
                                            onChange={(e) => {
                                                const newValue = { ...newSalonObject }
                                                newValue[0].location.varLocationEmail = e.target.value
                                                setNewSalonObject(newValue)
                                            }}
                                            type={"email"}
                                            value={newSalonObject[0].location.varLocationEmail}
                                        />
                                    </div>

                                    <p style={{ fontSize: "14px", fontWeight: 600, marginBottom: ".6rem", marginTop: "40px" }}>Location details</p>
                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "2rem", rowGap: "2ch", marginBottom: "15px" }}>
                                        <div>
                                            <TextField
                                                variant="filled"
                                                style={{ width: "100%" }}
                                                label={
                                                    <div style={{ display: "flex", fontSize: "14px" }}>
                                                        {headingMapper("varLocationAddress1", "standard", "location")}
                                                        {!!headingMapper("varLocationAddress1", "standard", "location", "isRequired") ? (
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                }
                                                required={!!headingMapper("varLocationAddress1", "standard", "location", "isRequired")}
                                                onChange={(e) => {
                                                    const newValue = { ...newSalonObject }
                                                    newValue[0].location.varLocationAddress1 = e.target.value
                                                    setNewSalonObject(newValue)
                                                }}
                                                type={"advanced"}
                                                value={newSalonObject[0]?.location.varLocationAddress1}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                variant="filled"
                                                style={{ width: "100%" }}
                                                label={
                                                    <div style={{ display: "flex", fontSize: "14px" }}>
                                                        {headingMapper("varLocationAddress2", "standard", "location")}
                                                        {!!headingMapper("varLocationAddress2", "standard", "location", "isRequired") ? (
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                }
                                                required={!!headingMapper("varLocationAddress2", "standard", "location", "isRequired")}
                                                onChange={(e) => {
                                                    const newValue = { ...newSalonObject }
                                                    newValue[0].location.varLocationAddress2 = e.target.value
                                                    setNewSalonObject(newValue)
                                                }}
                                                type={"advanced"}
                                                value={newSalonObject[0]?.location.varLocationAddress2}
                                            />
                                        </div>
                                    </div>

                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "2rem", rowGap: "2ch", marginBottom: "15px" }}>
                                        <div>
                                            <TextField
                                                variant="filled"
                                                style={{ width: "100%" }}
                                                label={
                                                    <div style={{ display: "flex", fontSize: "14px" }}>
                                                        {headingMapper("varLocationCity", "standard", "location")}
                                                        {!!headingMapper("varLocationCity", "standard", "location", "isRequired") ? (
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                }
                                                required={!!headingMapper("varLocationCity", "standard", "location", "isRequired")}
                                                onChange={(e) => {
                                                    const newValue = { ...newSalonObject }
                                                    newValue[0].location.varLocationCity = e.target.value
                                                    setNewSalonObject(newValue)
                                                }}
                                                type={"advanced"}
                                                value={newSalonObject[0]?.location.varLocationCity}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                variant="filled"
                                                style={{ width: "100%" }}
                                                label={
                                                    <div style={{ display: "flex", fontSize: "14px" }}>
                                                        {headingMapper("varLocationState", "standard", "location")}
                                                        {!!headingMapper("varLocationState", "standard", "location", "isRequired") ? (
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                }
                                                required={!!headingMapper("varLocationState", "standard", "location", "isRequired")}
                                                onChange={(e) => {
                                                    const newValue = { ...newSalonObject }
                                                    newValue[0].location.varLocationState = e.target.value
                                                    setNewSalonObject(newValue)
                                                }}
                                                type={"advanced"}
                                                value={newSalonObject[0]?.location.varLocationState}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "2rem", rowGap: "2ch", marginBottom: "15px" }}>
                                        <div>
                                            <TextField
                                                variant="filled"
                                                style={{ width: "100%" }}
                                                label={
                                                    <div style={{ display: "flex", fontSize: "14px" }}>
                                                        {headingMapper("varLocationCounty", "standard", "location")}
                                                        {!!headingMapper("varLocationCounty", "standard", "location", "isRequired") ? (
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                }
                                                required={!!headingMapper("varLocationCounty", "standard", "location", "isRequired")}
                                                onChange={(e) => {
                                                    const newValue = { ...newSalonObject }
                                                    newValue[0].location.varLocationCounty = e.target.value
                                                    setNewSalonObject(newValue)
                                                }}
                                                type={"advanced"}
                                                value={newSalonObject[0]?.location.varLocationCounty}
                                            />
                                        </div>

                                        <div>
                                            <TextField
                                                variant="filled"
                                                style={{ width: "100%" }}
                                                label={
                                                    <div style={{ display: "flex", fontSize: "14px" }}>
                                                        {headingMapper("varLocationCountry", "standard", "location")}
                                                        {!!headingMapper("varLocationCountry", "standard", "location", "isRequired") ? (
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                }
                                                required={!!headingMapper("varLocationCountry", "standard", "location", "isRequired")}
                                                onChange={(e) => {
                                                    const newValue = { ...newSalonObject }
                                                    newValue[0].location.varLocationCountry = e.target.value
                                                    setNewSalonObject(newValue)
                                                }}
                                                type={"advanced"}
                                                value={newSalonObject[0]?.location.varLocationCountry}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "2rem", rowGap: "2ch", marginBottom: "15px" }}>
                                        <div>
                                            <InputMask
                                                alwaysShowMask="true"
                                                mask="99999"
                                                maskChar="_"
                                                value={newSalonObject[0].location.varLocationZip}
                                                onChange={(e) => {
                                                    const newValue = { ...newSalonObject }
                                                    newValue[0].location.varLocationZip = e.target.value
                                                    setNewSalonObject(newValue)
                                                }}
                                            >
                                                {(inputProps) => (
                                                    <TextField
                                                        {...inputProps}
                                                        type="tel"
                                                        required={true}
                                                        style={{ width: "100%" }}
                                                        label={
                                                            <div style={{ display: "flex", fontSize: "14px" }}>
                                                                {"Postal/Zip Code"}
                                                                {true ? (
                                                                    <span
                                                                        style={{
                                                                            fontWeight: 500,
                                                                            backgroundColor: "#FDEDED",
                                                                            color: "#EF4444",
                                                                            fontSize: "12px",
                                                                            padding: "0 5px",
                                                                            marginLeft: "5px",
                                                                        }}
                                                                    >
                                                                        Required
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        }
                                                        variant="standard"
                                                    />
                                                )}
                                            </InputMask>
                                        </div>
                                    </div>

                                    <p style={{ fontSize: "14px", fontWeight: 600, marginBottom: ".6rem", marginTop: "40px" }}>Geo Tagging</p>
                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "2rem", rowGap: "2ch", marginBottom: "40px" }}>
                                        <div>
                                            <TextField
                                                variant="filled"
                                                style={{ width: "100%", marginBottom: "15px" }}
                                                label={
                                                    <div style={{ display: "flex", fontSize: "14px" }}>
                                                        {headingMapper("varLocationGoogleMapAddress", "standard", "location")}
                                                        {!!headingMapper("varLocationGoogleMapAddress", "standard", "location", "isRequired") ? (
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                }
                                                required={!!headingMapper("varLocationGoogleMapAddress", "standard", "location", "isRequired")}
                                                onChange={(e) => {
                                                    const newValue = { ...newSalonObject }
                                                    newValue[0].location.varLocationGoogleMapAddress = e.target.value
                                                    setNewSalonObject(newValue)
                                                }}
                                                type={"advanced"}
                                                value={newSalonObject[0]?.location.varLocationGoogleMapAddress}
                                            />

                                            <TextField
                                                variant="filled"
                                                style={{ width: "100%", marginBottom: "15px" }}
                                                label={
                                                    <div style={{ display: "flex", fontSize: "14px" }}>
                                                        {headingMapper("varLocationLatitude", "standard", "location")}
                                                        {!!headingMapper("varLocationLatitude", "standard", "location", "isRequired") ? (
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                }
                                                required={!!headingMapper("varLocationLatitude", "standard", "location", "isRequired")}
                                                onChange={(e) => {
                                                    const newValue = { ...newSalonObject }
                                                    newValue[0].location.varLocationLatitude = e.target.value
                                                    setNewSalonObject(newValue)
                                                }}
                                                type={"advanced"}
                                                value={newSalonObject[0]?.location.varLocationLatitude}
                                            />

                                            <TextField
                                                variant="filled"
                                                style={{ width: "100%", marginBottom: "15px" }}
                                                label={
                                                    <div style={{ display: "flex", fontSize: "14px" }}>
                                                        {headingMapper("varLocationLongitude", "standard", "location")}
                                                        {!!headingMapper("varLocationLongitude", "standard", "location", "isRequired") ? (
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                }
                                                required={!!headingMapper("varLocationLongitude", "standard", "location", "isRequired")}
                                                onChange={(e) => {
                                                    const newValue = { ...newSalonObject }
                                                    newValue[0].location.varLocationLongitude = e.target.value
                                                    setNewSalonObject(newValue)
                                                }}
                                                type={"advanced"}
                                                value={newSalonObject[0]?.location.varLocationLongitude}
                                            />

                                            <TextField
                                                variant="filled"
                                                style={{ width: "100%", marginBottom: "15px" }}
                                                label={
                                                    <div style={{ display: "flex", fontSize: "14px" }}>
                                                        {headingMapper("varLocationAltitude", "standard", "location")}
                                                        {!!headingMapper("varLocationAltitude", "standard", "location", "isRequired") ? (
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                }
                                                required={!!headingMapper("varLocationAltitude", "standard", "location", "isRequired")}
                                                onChange={(e) => {
                                                    const newValue = { ...newSalonObject }
                                                    newValue[0].location.varLocationAltitude = e.target.value
                                                    setNewSalonObject(newValue)
                                                }}
                                                type={"advanced"}
                                                value={newSalonObject[0]?.location.varLocationAltitude}
                                            />
                                        </div>
                                        <div>
                                            <Map
                                                location={center}
                                                directions={false}
                                                isPickLocation={true}
                                                zoom={20}
                                                onPickLocation={(e) => {
                                                    const triggerCheck = { ...newSalonObject }
                                                    triggerCheck[0].location.varLocationLatitude = e.latLng.lat()
                                                    triggerCheck[0].location.varLocationLongitude = e.latLng.lng()

                                                    let latlng: any = e.latLng
                                                    let geocoder: any = new google.maps.Geocoder()

                                                    geocoder.geocode({ latLng: latlng }, (results, status) => {
                                                        if (status !== google.maps.GeocoderStatus.OK) {
                                                            alert(status)
                                                        }

                                                        if (status == google.maps.GeocoderStatus.OK) {
                                                            triggerCheck[0].location.varLocationGoogleMapAddress = results[0].formatted_address
                                                        }
                                                    })

                                                    setNewSalonObject(triggerCheck)
                                                }}
                                                isSub={false}
                                            ></Map>
                                        </div>
                                    </div>
                                </form>

                                <div style={{ textAlign: "end", width: "100%" }}>
                                    <Button onClick={() => saveChanges()} loading={salonLoading} variant="contained" color="primary" text="Create Salon" type={"submit"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default NewSalon
